import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Column, ColumnType } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { takeUntil } from 'rxjs/operators';
import * as fromReducer from '@ptg-reducers';
import * as fromMember from '../../store/reducers';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { showBanner } from '@ptg-shared/utils/common.util';
import { GetListAddressHistoryQuery, History, MemberNavigationResponse } from '../../types/models';
import { EditMemberAddressHistoryComponent } from '@ptg-member/components/edit-member-address-history/edit-member-address-history.component';
import { OverviewHeaderEntityComponent } from '@ptg-member/components/overview-header-entity/overview-header-entity.component';
import { ACTION, STATE } from '@ptg-shared/constance';
import { AddressHistoryAction } from '../../store/actions';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { FormControl } from '@angular/forms';
import { Option } from '@ptg-shared/controls/select/select.component';
import { InitiationProperty } from '@ptg-entity-management/services/models';

@Component({
  selector: 'ptg-address-history',
  templateUrl: './address-history.component.html',
  styleUrls: ['./address-history.component.scss']
})
export class AddressHistoryComponent extends BaseComponent {
  bannerType: BannerType = BannerType.Hidden;  
  listBreadcrumbs: Breadcrumb[] = [{ name: '' }];
  message: string = '';
  isLoading = false;
  currentFund: any = {};
  columns: Column[] = [
    {
      name: 'entityPropertyName',
      header: {
        title: 'Address Type',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'effectFrom',
      header: {
        title: 'Effective From',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy' },
    },
    {
      name: 'effectTo',
      header: {
        title: 'Effective To',
      },
      sortable: true,
    },
    {
      name: 'address',
      header: {
        title: 'Address',
      },
      sortable: true,
      truncate: true,
    },
    {
      name: 'dateUpdated',
      header: {
        title: 'Last Modified At',
      },
      sortable: true,
      type: ColumnType.DateTime,
      templateArgs: { format: 'MM/dd/yyyy h:mm a' },
    },
    {
      name: 'updatedBy',
      header: {
        title: 'Modified By',
      },
      sortable: true,
      truncate: true,
    },
  ];
  addressHistoriesData: any[] = [];
  memberId: string = '';
  viewId: string = '';
  sortInfo: {} | any = {};
  lengthPg: number | any;
  pageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  memberStatusHistoryId?: string;
  addressType = new FormControl('');
  listOptionAddressType: Option[] = [];
  addressTypeDisplay = '';
  properties: InitiationProperty[] = [];
  selectedProperty = '';
  viewName!: string;
  viewUrl!: string;

  @ViewChild(OverviewHeaderEntityComponent) overViewHeaderEntity!: OverviewHeaderEntityComponent; 
  
  constructor(
    public route: ActivatedRoute,
    private memberStore: Store<fromMember.MemberState>,
    private store: Store<fromReducer.State>,
    public dialog: MatDialog,
  ) { 
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.store
      .pipe(
        select(fromReducer.selectCurrentFundState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((el) => {
        this.pageSize = el.defaultPageSize ?? 50;
        this.currentFund = el;
      });

    this.pageSize =
      Number(
        sessionStorage.getItem(
          this.currentFund.key + '-ptg-address-history-pageSize'
        )
      ) === 0
        ? this.pageSize
        : Number(
            sessionStorage.getItem(
              this.currentFund.key + '-ptg-address-history-pageSize'
            )
          );

    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.memberId = params.id;
      this.viewId = params.viewId;
      this.getViewName();
    });

    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        if (params?.currentRowIndex) {
          this.currentRowIndex = +params.currentRowIndex;
        }
        if (params?.prevName) {
          this.viewName = params.prevName;
          this.viewUrl = params.prevUrl;
        }
      });

    this.memberStore
      .pipe(
        select(fromMember.selectAddressHistoryState),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((state) => {
        this.lengthPg = state?.addressHistoryResult?.total;
        this.isLoading = state?.isLoading;
        this.properties = state.properties ?? [];
        this.listOptionAddressType = state.properties?.map(el => ({
          displayValue: el.entityPropertyName ?? '', value: el.entityPropertyId
        })) ?? [];
        this.listOptionAddressType.unshift({displayValue: 'All', value: ''})
        this.addressHistoriesData =
          state?.addressHistoryResult?.listAddressHistory.map(el => {
            const address = {
              street1: el.street1,
              street2: el.street2,
              city: el.city,
              state: (state.properties ?? [])[0]?.options.find(item => item.id === el.state)?.text,
              zipCode: el.zipCode,
              country: (state.properties ?? [])[0]?.options.find(item => item.id === el.country)?.text
            };
            const addressBlank = Object.values(address).some(item => item);
            const addressType = state.properties?.find(item => item.entityPropertyId === el.code)?.entityPropertyName;
            const dateUpdated = el.dateUpdated ? el.dateUpdated + 'Z' : '';
            return {...el, address, addressBlank, addressType, dateUpdated, options: (state.properties ?? [])[0]?.options};
          }) ?? [];
        if (state.createAddress) {
          showBanner.call(
            this,
            state.createAddress || '',
            'Address',
            ACTION.ADD
          );
        }
        if (state.updateAddress) {
          showBanner.call(
            this,
            state.updateAddress || '',
            'Address',
            ACTION.EDIT
          );
        }
        if (
          state.createAddress == STATE.SUCCESS ||
          state.updateAddress == STATE.SUCCESS
        ) {
          // this.reloadOverviewHeader();
          this.getData();
          this.memberStore.dispatch(
            AddressHistoryAction.getAddressProperties({ entityId: this.overViewHeaderEntity?.entityId })
          );
        }
        if (state.createAddress || state.updateAddress) {
          this.memberStore.dispatch(
            AddressHistoryAction.clearAddressHistoryState()
          );
        }
      });
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    setTimeout(() => {
      this.overViewHeaderEntity?.entityId$.subscribe(el => {
        if (el) {
          this.getData();
          this.memberStore.dispatch(
            AddressHistoryAction.getAddressProperties({ entityId: this.overViewHeaderEntity?.entityId })
          );
        }
      })
    })
  }

  getData() {
    let sortType = 1;
    let sortNames = ['EffectFrom', 'EffectTo', 'EntityPropertyName'];
    if (this.sortInfo?.active && this.sortInfo?.direction) {
      if (this.sortInfo.active === 'address') {
        sortNames = ['Street1', 'Street2', 'City', 'State', 'ZipCode', 'Country'];
      } else {
        sortNames = [capitalizeFirstLetter(this.sortInfo.active)];
      }
      
      sortType = this.sortInfo.direction === 'desc' ? 1 : 0;
    }
    const query: GetListAddressHistoryQuery = {
      memberId: this.memberId,
      type: this.selectedProperty,
      pageIndex: this.pageNumber,
      pageSize: this.pageSize,
      sortNames: sortNames,
      sortType: sortType,
    };
    this.memberStore.dispatch(
      AddressHistoryAction.getAddressHistoryList({ query, entityId: this.overViewHeaderEntity?.entityId })
    );
  }

  getViewName() {    
    this.store.select(fromLayoutReducer.selectMemberNavigationListState).pipe(takeUntil(this.unsubscribe$))
      .subscribe(menu => {
        if (menu.memberNavigationMenu) {
          const memberNavigationMenu: MemberNavigationResponse[] =
          menu?.memberNavigationMenu;
          const menuItems = memberNavigationMenu
            ?.map((item) => {
              return item.menuItems;
            })
            ?.reduce((a, b) => {
              return a.concat(b);
            });

          const menuName = menuItems?.find((item: any) => item?.entityViewId === this.viewId)?.name;

          if (this.viewName) {
            this.listBreadcrumbs = [{
              url: this.viewUrl,
              name: this.viewName,
            }, {
              name: menuName || 'Address History',
            }];
          } else
          this.listBreadcrumbs[0] = {
            name: menuName || 'Address History',
          };
        }
      });
  }

  onEditMemberAddress(addressEditValue?: any) {
    let dialogRef = this.dialog.open(EditMemberAddressHistoryComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        entityId: this.overViewHeaderEntity.entityId,
        memberId: this.memberId,
        viewName: 'Address',
        address: addressEditValue,
        properties: this.properties
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && !addressEditValue) {
        this.memberStore.dispatch(
          AddressHistoryAction.createAddress({body: result})
        );
      } else if (result && addressEditValue) {
        this.memberStore.dispatch(
          AddressHistoryAction.updateAddress({body: result})
        );
      }
    });
  }

  sortChange(event: any) {
    this.sortInfo = event;
    this.currentRowIndex = 0;
    this.getData();
  }

  changePage(event: PageEvent) {
    this.currentRowIndex = 0;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + '-ptg-address-history-pageNumber',
      this.pageNumber.toString()
    );
    sessionStorage.setItem(
      this.currentFund.key + '-ptg-address-history-pageSize',
      this.pageSize.toString()
    );
    this.getData();
  }

  selectRow(event: any) {
    this.currentRowIndex = this.addressHistoriesData.findIndex(
      (item) => item.id === event.id
    );
  }

  onChangeMemberDetail() {
    this.currentRowIndex = 0;
  }

  onChangeAddressProperty(obj: Option) {
    this.selectedProperty = obj.value;
    this.getData();
    this.currentRowIndex = 0;
  }

  closeBanner() {
    this.message = '';
  }
}
