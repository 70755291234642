export enum LookupTableType {
  State = 1,
  Country = 2,
  None = 3,
  YesValue = 4,
  NoValue = 5,
  PaymentPreference = 9,
  UseCodeValue = 10,
  OtherState = 11
}
