import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PayeeDetailEffects, payeeDetailReducers } from '@ptg-member/features/payee-detail/store';
import { MODULE_KEY } from '@ptg-member/features/payee-detail/types/constants';
import { ControlsModule } from '@ptg-shared/controls/controls.module';
import { SharedModule } from '@ptg-shared/shared.module';

import { PayeeDetailRoutingModule } from './payee-detail-routing.module';
import { PaymentInstructionDetailComponent } from './pages/payment-instruction-detail/payment-instruction-detail.component';
import { PaymentEarningSectionComponent } from './components/payment-earnings-section/payment-earnings-section.component';
import { PaymentDeductionsSectionComponent } from './components/payment-deductions-section/payment-deductions-section.component';
import {
  InitialPaymentConfigurationService,
  FinalPaymentConfigurationService,
  PaymentHeaderConfigurationService,
  PaymentInfoTabService,
  PayrollSettingsService,
  EditPayrollSettingsService,
  EditDeductionsService,
  EditPaymentStatusService,
  GetPaymentInfoService,
  NewAdjustmentDetailedService,
  CreateReversalAdjustmentService,
} from './services';
import { InitialPaymentConfiguration } from './pages/initial-payment-configuration/initial-payment-configuration.component';
import { FinalPaymentConfiguration } from './pages/final-payment-configuration/final-payment-configuration.component';
import { PaymentHeaderConfigurationComponent } from './pages/payment-header-configuration/payment-header-configuration.component';
import { AddPaymentHeaderConfigurationComponent } from './components/add-payment-header-configuration/add-payment-header-configuration.component';
import { PaymentInfoTabComponent } from './components/payment-info-tab/payment-info-tab.component';
import { PaymentHeaderConfigurationDetailComponent } from './pages/payment-header-configuration-detail/payment-header-configuration-detail.component';
import { EditFundingSourcesComponent } from './components/edit-funding-sources/edit-funding-sources.component';
import { PaymentHeaderComponent } from './components/payment-header/payment-header.component';
import { PayrollSettingsComponent } from './components/payroll-settings/payroll-settings-detail.component';
import { EarningDetailComponent } from './components/earning-detail/earning-detail.component';
import { SplitPaymentSectionComponent } from './components/split-payment-section/split-payment-section.component';
import { EditPayeePaymentComponent } from './components/edit-payee-payment/edit-payee-payment.component';
import { PayeePaymentService } from './services/payee-payment.service';
import { EditFundingSourceService } from './services/edit-funding-source.service';
import { EditPayrollSettingsComponent } from './components/edit-payroll-settings/edit-payroll-settings.component';
import { EditDeductionsComponent } from './components/edit-deductions/edit-deductions.component';
import { PaymentStatusComponent } from './components/payment-status/payment-status.component';
import { AddNoteComponent } from './components/add-note/add-note.component';
import { NoteMemoDetailComponent } from './components/note-memo-detail/note-memo-detail.component';
import { NoteMemoService } from './services/note-memo-detail.service';
import { CheckPermissionModule } from '@ptg-shared/layout/directive/check-permission/check-permission.module';
import { EditPaymentStatusComponent } from './components/edit-payment-status/edit-payment-status.component';
import { EditPayableDateComponent } from './components/edit-payable-date/edit-payable-date.component';
import { NewDetailedAdjustmentComponent } from './components/new-detailed-adjustment/new-detailed-adjustment.component';
import { PaymentDetailsService } from './services/payment-details.service';
import { CreateGeneralAdjustmentComponent } from './components/create-general-adjustment/create-general-adjustment.component';
import { CreateGeneralAdjustmentService } from './services/create-general-adjustment.service';
import { ViewAdjustmentDetailsComponent } from './components/view-adjustment-details/view-adjustment-details.component';
import { ViewAdjustmentDetailsService } from './services/view-adjustment-details.service';
import { OverviewHeaderEntityModule } from '@ptg-member/components/overview-header-entity/overview-header-entity.module';
import { EditDeductionFundingSourceComponent } from './components/edit-deduction-funding-source/edit-deduction-funding-source.component';
import { PaymentAnnualTotalsComponent } from './components/payment-annual-totals/payment-annual-totals.component';
import { CreateOffsetDeductionComponent } from './components/create-offset-deduction/create-offset-deduction.component';
import { MarkRemoveOverpaidComponent } from './components/mark-remove-overpaid/mark-remove-overpaid.component';
import { LinkRelatedMemberComponent } from './components/link-related-member/link-related-member.component';

const COMPONENTS = [
  PaymentInstructionDetailComponent,
  PaymentEarningSectionComponent,
  PaymentDeductionsSectionComponent,
  InitialPaymentConfiguration,
  FinalPaymentConfiguration,
  PaymentHeaderConfigurationComponent,
  AddPaymentHeaderConfigurationComponent,
  PaymentHeaderConfigurationDetailComponent,
  PaymentInfoTabComponent,
  EditFundingSourcesComponent,
  PaymentHeaderComponent,
  PayrollSettingsComponent,
  EarningDetailComponent,
  SplitPaymentSectionComponent,
  EditPayeePaymentComponent,
  EditPayrollSettingsComponent,
  EditDeductionsComponent,
  PaymentStatusComponent,
  AddNoteComponent,
  NoteMemoDetailComponent,
  EditPaymentStatusComponent,
  EditPayableDateComponent,
  NewDetailedAdjustmentComponent,
  CreateGeneralAdjustmentComponent,
  ViewAdjustmentDetailsComponent,
  EditDeductionFundingSourceComponent,
  PaymentAnnualTotalsComponent,
  CreateOffsetDeductionComponent,
  MarkRemoveOverpaidComponent,
  LinkRelatedMemberComponent,
];

const SERVICES = [
  InitialPaymentConfigurationService,
  FinalPaymentConfigurationService,
  PaymentHeaderConfigurationService,
  PaymentInfoTabService,
  PayrollSettingsService,
  PayeePaymentService,
  EditFundingSourceService,
  EditPayrollSettingsService,
  GetPaymentInfoService,
  EditDeductionsService,
  NoteMemoService,
  EditPaymentStatusService,
  PaymentDetailsService,
  NewAdjustmentDetailedService,
  CreateGeneralAdjustmentService,
  ViewAdjustmentDetailsService,
  CreateReversalAdjustmentService,
];

@NgModule({
  declarations: [...COMPONENTS],
  exports: [...COMPONENTS],
  providers: [...SERVICES],
  imports: [
    SharedModule,
    ControlsModule,
    StoreModule.forFeature(MODULE_KEY, payeeDetailReducers),
    EffectsModule.forFeature(PayeeDetailEffects),
    PayeeDetailRoutingModule,
    CheckPermissionModule,
    OverviewHeaderEntityModule,
  ],
})
export class PayeeDetailModule {}
