<div class="expanded-container">
  <div class="data-container" *ngIf="!isLoading">
    <ng-container *ngFor="let item of registerData">
      <div class="transaction-item" [ngStyle]="{ backgroundColor: item.backgroundColor }">
        <div class="error-message" *ngIf="item.errorMessage">
          <p class="text-red-500">{{ item.errorMessage }}</p>
        </div>
        <div class="toolbar transaction-container">
          <div class="column">
            <div
              *ngIf="item.statusColumnStyle.iconName"
              #tooltip="matTooltip"
              [matTooltip]="item.statusColumnStyle?.tooltipMessage"
              [matTooltipDisabled]="!item.statusColumnStyle?.tooltipMessage"
              [ngClass]="item.statusColumnStyle?.tooltipMessage ? 'cursor-pointer' : ''"
              (click)="item.statusColumnStyle?.hasClickEvent ? showErrorPopup!(item) : null"
              matTooltipPosition="below"
              class="icon-status-container w-fit"
              [matTooltipClass]="
                'custom-tooltip with-arrow !max-w-[40vw]' + (sidebarOpened ? '' : ' before:!left-[24%]')
              "
            >
              <mat-icon [style]="{ color: item.statusColumnStyle.color }">{{
                item.statusColumnStyle.iconName
              }}</mat-icon>
              <div *ngIf="item.statusColumnStyle?.hasError" class="status-error-icon">
                <span><strong>&#33;</strong></span>
              </div>
            </div>
          </div>
          <div class="column">
            <ng-container *ngIf="!isFromSpecificMember && item.transactionType !== TransactionType['Deduction Payee'];else other">
              <a class="navigation" (click)="navigateToProfile(item)">
                <span class="recipient-name">{{ item.recipient }}</span>
              </a>
            </ng-container>
            <ng-template #other>
                <span class="recipient-name">{{ item.recipient }}</span>
            </ng-template>
            <mat-chip-list class="list-chip" *ngIf="item.chipType !== ChipType.Other">
              <mat-chip
                [ngClass]="{
                  'adjustment-chip': item.chipType === ChipType.Adjustment,
                  'onetime-chip': item.chipType === ChipType['One-Time'],
                  'munirefund-chip': item.chipType === ChipType.MuniRefund
                }"
              >
                {{ item.chipType !== ChipType.MuniRefund ? item.transactionTypeText : item.transactionTypeMuni }}
              </mat-chip>
            </mat-chip-list>

            <mat-chip-list *ngIf="item.isShowReissueChip">
              <mat-chip class="reissued-chip">{{ item.reissueState === ReissueState.Reissued ? ' Reissued ' : item.reissueState === ReissueState.ProcessingReissue ? ' Processing Reissue ' : ' Pending Reissue '}}</mat-chip>
            </mat-chip-list>

            <mat-chip-list *ngIf="item.isShowOverpaidChip">
              <mat-chip class="overpaid-chip">Overpaid</mat-chip>
            </mat-chip-list>

            <mat-chip-list *ngIf="item.isShowRecoveredChip">
              <mat-chip class="recovered-chip">Recovered</mat-chip>
            </mat-chip-list>
          </div>
          <div class="column">
            <button
              *ngIf="
                item.status === PaymentTransactionStatus.Posted &&
                !item.reissuedOf &&
                !item.dateReconciled &&
                (item.paymentMethod === PaymentType['Check'] || item.paymentMethod === PaymentType['Direct Deposit']) &&
                ((item.isDeductionPayee && (item?.payment ?? 0) > 0) || (!item.isDeductionPayee && (item.netAmount ?? 0) > 0))
              "
              (click)="onClickReturnReject(item)"
              mat-stroked-button
              type="button"
            >
              <mat-icon>restore_page</mat-icon> Returned/Rejected
            </button>
            <button
              *ngIf="item.isAbleToReissue && !item.dateReconciled"
              (click)="onClickReissue(item)"
              mat-stroked-button
              color="success"
              type="button"
            >
              <mat-icon>settings_backup_restore</mat-icon> Reissue
            </button>
          </div>
        </div>
        <div class="transaction-details transaction-container">
          <div class="column">
            <p class="transaction-id">
              {{ item.transactionId }}
            </p>
            <div class="value" *ngIf="item.replacedBy">Replaced By: 
              <a [ngClass]="item.isDeductionPayee ? 'non-navigation' : 'navigation'" (click)="onRelatedTransactionClick(item)">
                <span>{{ item.replacedBy }}</span>
              </a>
            </div>
            <div class="value" *ngIf="item.adjustedBy">Adjusted By: {{ item.adjustedBy }}</div>
            <ng-container *ngIf="item.originalTransaction">
              <ng-template #normalText>
                <div class="value">Original Transaction: 
                  <a [ngClass]="item.isDeductionPayee ? 'non-navigation' : 'navigation'" (click)="onRelatedTransactionClick(item)">
                    <span>{{ item.originalTransaction }}</span>
                  </a>
                </div>
              </ng-template>
              <div
                *ngIf="item.originalTransaction.length > 10; else normalText"
                #tooltip="matTooltip"
                [matTooltip]="item.originalTransaction"
                [ngClass]="'cursor-pointer'"
                matTooltipPosition="below"
                class="icon-status-container w-fit value"
                [matTooltipClass]="
                  'custom-tooltip with-arrow !max-w-[40vw]' + (sidebarOpened ? '' : ' before:!left-[24%]')
                "
              >
                Original Transaction: 
                <a [ngClass]="item.isDeductionPayee ? 'non-navigation' : 'navigation'" (click)="onRelatedTransactionClick(item)">
                  <span>{{ item.originalTransaction.substring(0, 10) }}...</span>
                </a>
              </div>
            </ng-container>
          </div>
          <div class="column">
            <div class="row">
              <div class="key">Transaction Type</div>
              <div class="value">{{ item.transactionTypeText }}</div>
            </div>
            <div class="row" *ngIf="item.transactionTypeText === 'One-Time'">
              <div class="key">One time Type</div>
              <div class="value">{{ item.oneTimeTypeText }}</div>
            </div>
            <div class="row" *ngIf="item.adjustmentType !== null">
              <div class="key">Adjustment Type</div>
              <div class="value">{{ AdjustmentType[item.adjustmentType] }}</div>
            </div>
            <ng-container *ngIf="!item.isDeductionPayee">
              <div class="row" *ngIf="item.configuredID">
                <div class="key">ID</div>
                <div class="value">
                  <ng-container
                    *ngIf="item.configuredID.hasPropertySSN && item.configuredID.isMasked; else unmaskedTemplate"
                  >
                    <div class="masked-value">
                      <span>{{ item.configuredID.id | ssnNumber: !!item.configuredIDMasked }}</span>
                      <ng-container *ngIf="item.configuredID.id?.length === 9">
                        &nbsp;
                        <mat-icon matSuffix (click)="item.configuredIDMasked = !item.configuredIDMasked">
                          {{ item.configuredIDMasked ? 'visibility_off' : 'visibility' }}
                        </mat-icon>
                      </ng-container>
                    </div>
                  </ng-container>
                  <ng-template #unmaskedTemplate>
                    {{ item.configuredID.id }}
                  </ng-template>
                </div>
              </div>
              <div class="row">
                <div class="key">Benefit ID</div>
                <div class="value">{{ item.benefitCode }}</div>
              </div>
              <div class="row">
                <div class="key">Benefit Type</div>
                <div class="value">{{ item.benefitType }}</div>
              </div>
            </ng-container>
            <div class="row" *ngIf="item.representativePayeeName">
              <div class="key">Representative</div>
              <div class="value">{{ item.representativePayeeName }}</div>
            </div>
            <div class="row" *ngIf="item.addressDto && item.paymentMethod === PaymentType.Check">
              <div class="key">{{ item.representativePayeeName ? 'Representative Address' : 'Address' }}</div>
              <div class="value address-value">{{ item.addressDto | address }}</div>
            </div>
            <div class="row" *ngIf="item.mainPayee">
              <div class="key">Main Payee</div>
              <div class="value">{{ item.mainPayee }}</div>
            </div>
            <div class="row" *ngIf="item.alternatePayee">
              <div class="key">Alternate Payee</div>
              <div class="value whitespace-pre-wrap">{{ item.alternatePayee }}</div>
            </div>
          </div>
          <div class="column">
            <div class="row" *ngIf="item.adjustmentType === null">
              <div class="key">Payment Method</div>
              <div class="value">{{ PaymentType[item.paymentMethod!] }}</div>
            </div>
            <ng-container *ngIf="item.paymentMethod === PaymentType['Direct Deposit'] && item.bankInformation">
              <div class="row">
                <div class="key">Bank Name</div>
                <div class="value">{{ item.bankInformation.bankName }}</div>
              </div>
              <div class="row">
                <div class="key">Account #</div>
                <div class="value">
                  {{ item.bankInformation.accountNumber }} - {{ item.bankInformation.accountType }}
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="item.paymentMethod === PaymentType.Check">
              <div class="row">
                <ng-container *ngIf="item.checkNumber">
                  <div class="key">Check Number</div>
                  <div class="value">{{ item.checkNumber !== null && isConfigOST ? formatStringForCheckNumber(item.checkNumber, item.endingChar) : item.checkNumber }}</div>
                </ng-container>
              </div>
              <ng-container
                [ngTemplateOutlet]="truncateText"
                [ngTemplateOutletContext]="{
                  value: item.memoValue,
                  seeMore: item.memoSeeMore,
                  item: item,
                  label: 'Memo'
                }"
              ></ng-container>
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="truncateText"
              [ngTemplateOutletContext]="{
                value: item.justification?.substring(0, 10),
                seeMore: (item.justification?.length || 0) >= 10,
                item: item,
                label: 'Justification'
              }"
            ></ng-container>
            <ng-container
              [ngTemplateOutlet]="truncateText"
              [ngTemplateOutletContext]="{
                value: item.addendaValue,
                seeMore: item.addendaSeeMore,
                item: item,
                label: 'Addenda'
              }"
            ></ng-container>
          </div>
          <div class="column">
            <div class="row" *ngIf="!item.isDeductionPayee">
              <div class="key">Benefit Period</div>
              <div class="value">
                <ng-container
                  *ngIf="
                    item.startDate &&
                      item.endDate &&
                      item.paymentInstructionType !== PaymentInstructionType.PeriodicLumpsumPayment;
                    else benefitPeriodEmpty
                  "
                >
                  {{ item.startDate | date: 'MM/dd/yyyy' }} - {{ item.endDate | date: 'MM/dd/yyyy' }}
                </ng-container>
                <ng-template #benefitPeriodEmpty>-</ng-template>
              </div>
            </div>
            <div class="row" *ngIf="item.accountingPostDate">
              <div class="key">Accounting Post Date</div>
              <div class="value">{{ item.accountingPostDate | date: 'MM/dd/yyyy' }}</div>
            </div>
            <div class="row">
              <div class="key">Register Post Date</div>
              <div class="value">{{ item.datePosted === null ? '-' : (item.datePosted | dateFormat) }}</div>
            </div>
            <div class="row" *ngIf="item.payableDate">
              <div class="key">Payable Date</div>
              <div class="value">{{ item.payableDate | date: 'MM/dd/yyyy' }}</div>
            </div>
            <div class="row" *ngIf="item.paymentMethod === PaymentType['Direct Deposit'] && item.depositDate">
              <div class="key">Deposit Date</div>
              <div class="value">{{ item.depositDate | date: 'MM/dd/yyyy' }}</div>
            </div>
            <div class="row" *ngIf="item.paymentMethod === PaymentType['Check'] && item.checkDate">
              <div class="key">Check Date</div>
              <div class="value">{{ item.checkDate | date: 'MM/dd/yyyy' }}</div>
            </div>
            <div
              class="row"
              *ngIf="
                ( item.transactionDate &&
                  ( item.transactionType === TransactionType['Adjustment'] && (
                      item.adjustmentType === AdjustmentType['General']
                        || item.adjustmentType === AdjustmentType['Detailed']
                    )
                  )
                ) ||
                ( item.transactionType === TransactionType['Deduction Payee'] && (
                    item.paymentMethod === PaymentType['Wire Transfer']
                      || item.paymentMethod === PaymentType['Internal Payment']
                  )
                )
              "
            >
              <div class="key">Transaction Date</div>
              <div class="value">{{ item.transactionDate ? (item.transactionDate | date: DATE_FORMAT) : '-' }}</div>
            </div>
            <div class="row" *ngIf="item.dateReconciled && item.paymentMethod === PaymentType['Check']">
              <div class="key">Date Reconciled</div>
              <div class="value">{{ item.dateReconciled | date: 'MM/dd/yyyy' }}</div>
            </div>
            <div class="row" *ngIf="item.returnDate">
              <div class="key">Returned Date</div>
              <div class="value">{{ item.returnDate | date: 'MM/dd/yyyy' }}</div>
            </div>
          </div>
          <div class="column">
            <ng-container *ngIf="!item.isDeductionPayee">
              <div class="row">
                <div class="key">Gross Payment</div>
                <div class="value">
                  {{ item.grossAmount || 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}
                </div>
              </div>
              <div class="row">
                <div class="key">Total Deductions</div>
                <div class="value">
                  {{ item.totalDeductionAmount || 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}
                </div>
              </div>
              <div class="row">
                <div class="key">Net Payment</div>
                <div class="value">
                  {{ item.netAmount || 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}
                </div>
              </div>
            </ng-container>
            <div class="row" *ngIf="item.isDeductionPayee">
              <div class="key">Payment</div>
              <div class="value">
                {{ item.payment || 0 | numberLocalDecimal: '$' : { accountingFormat: true } }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </ng-container>
  </div>
  <div class="flex-grow flex">
    <div *ngIf="isLoading; else notLoading" class="spinner m-auto">
      <mat-progress-spinner diameter="50" mode="indeterminate"> </mat-progress-spinner>
      <p>Loading...</p>
    </div>
    <ng-template #notLoading>
      <div *ngIf="!registerData?.length && notFoundMessage" class="not-found m-auto flex flex-col">
        <ng-container *ngIf="errorMessage; else notError">
          <div class="warning-icon">
            <mat-icon class="material-icons-round">warning</mat-icon>
          </div>
          <div>
            <span class="error-msg">{{ errorMessage }} error</span>
            <span>occurred, please try again later.</span>
          </div>
        </ng-container>

        <ng-template #notError>
          {{ notFoundMessage }}
        </ng-template>
      </div>
    </ng-template>
  </div>
  <ptg-pagination
    #paginator
    [totalRecords]="totalRecords"
    [pageSizeOptions]="pageSizeOptions"
    [pageSize]="pageSize"
    [pageNumber]="pageNumber"
    [maxPages]="maxPages"
    [hiddenPageSizeOptions]="hiddenPageSizeOptions"
    (pageChange)="onChangePage($event)"
  ></ptg-pagination>
  <ng-template #truncateText let-value="value" let-label="label" let-item="item" let-seeMore="seeMore">
    <div class="row" *ngIf="value">
      <div class="key">{{ label }}</div>
      <div class="value whitespace-pre-wrap">
        <ng-container *ngIf="seeMore else rawValue"
          >{{ value | slice: 0: 10 }}...<span class="see-more" (click)="openMemoPopup(item, label)">See more</span></ng-container
        >
        <ng-template #rawValue>{{ value }}</ng-template>
      </div>
    </div>
  </ng-template>
</div>
