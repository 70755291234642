<ptg-dialog [title]="'Link Related Member'" [isFullScreen]="true">
  <ng-template dialogContentHeader>
    <ptg-payment-header [targetIdFromDialog]="data.memberId"></ptg-payment-header>

    <div *ngIf="bannerType" class="pt-6 px-6">
      <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
    </div>

    <div class="flex gap-2 pt-6 px-6">
      <ptg-button
        (clickButton)="formSubmit$.next()"
        [buttonName]="'Save'"
        [isDisabled]="isSaving"
        classInput="primary"
      >
      </ptg-button>
      <ptg-button
        (clickButton)="onCancel()"
        [buttonName]="'Cancel'"
        classInput="tertiary"
      >
      </ptg-button>
    </div>
  </ng-template>

  <ng-template dialogBody>
    <ng-container *ngIf="!isLoadingRelatedMembers; else loadingTemplate">
      <ptg-select
        placeholder="Related Member"
        [controlField]="form.controls['relatedMember']"
        [isOptionObj]="true"
        [listData]="relatedMemberOptions"
        class="w-80"
      ></ptg-select>
    </ng-container>
  </ng-template>
</ptg-dialog>

<ng-template #loadingTemplate>
  <ptg-spinner [isLoading]="true"></ptg-spinner>
</ng-template>
