import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SHOW_LOADING, SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';
import {
  ApproveCalculationBenefitRequest,
  BenefitCalculationConfigurationsByType,
  CheckStepConfigurationExistsResponse,
  ModifiedDisplayOutputsConfiguration,
  ParameterMappings,
} from '@ptg-member/features/calculation/services/models';
import {
  CalculationBenefitHistoryStatus,
  CalculationType,
  GetParameterMappingType,
} from '@ptg-member/features/calculation/types/enums';
import { getLocalDate } from '@ptg-shared/utils/string.util';

@Injectable({
  providedIn: 'root',
})
export class BenefitCalculationConfigurationService {
  constructor(private readonly httpClient: HttpClient) {}

  getCalculationParameterMapping(parameterType?: GetParameterMappingType): Observable<ParameterMappings> {
    return this.httpClient.get<ParameterMappings>(
      `${environment.apiUrl}/calculations/calculation-options/parameter-mapping/${parameterType ?? ''}`,
    );
  }

  getCalculationDisplayConfigurationByType(
    calculationType: CalculationType,
  ): Observable<BenefitCalculationConfigurationsByType> {
    return this.httpClient.get<BenefitCalculationConfigurationsByType>(
      `${environment.apiUrl}/calculations/calculation-options/calculation-display-configuration/${calculationType}`,
    );
  }

  setModifyCalculationDisplayConfiguration(id: string, request: ModifiedDisplayOutputsConfiguration): Observable<void> {
    return this.httpClient.post<void>(
      `${environment.apiUrl}/calculations/calculation-options/calculation-display-configuration/${id}`,
      request,
    );
  }

  validateBeforeComputeBenefitWithJointSurvivor(
    memberId: string,
    calculationType: CalculationType,
    calculationBenefitId: string,
  ) {
    const context = new HttpContext().set(SKIP_ERRORS, '500');
    context.set(SHOW_LOADING, true);
    return this.httpClient.get<CheckStepConfigurationExistsResponse>(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit/${calculationType}/${calculationBenefitId}/validation-before-computing`,
    );
  }

  computeCalculationBenefit(memberId: string, calculationBenefitId: string, calculationType: CalculationType) {
    const context = new HttpContext().set(SKIP_ERRORS, '500');
    context.set(SHOW_LOADING, true);
    return this.httpClient.put(
      `${environment.apiUrl}/calculations/${memberId}/calculation/${calculationType}/${calculationBenefitId}/computation`,
      null,
      { context },
    );
  }

  completeCalculationBenefit(memberId: string, calculationBenefitId: string, calculationType: CalculationType) {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.put(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-histories/${calculationType}/${calculationBenefitId}/complete`,
      {},
      { context },
    );
  }

  approveCalculationBenefit(
    memberId: string,
    calculationBenefitId: string,
    calculationType: CalculationType,
    body?: ApproveCalculationBenefitRequest,
  ) {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.put(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-histories/${calculationType}/${calculationBenefitId}/approve`,
      { ...body, localDate: getLocalDate() },
      { context },
    );
  }

  reopenCalculationBenefit(memberId: string, calculationBenefitId: string, calculationType: CalculationType) {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.put(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-histories/${calculationType}/${calculationBenefitId}/reopen`,
      {},
      { context },
    );
  }

  validateCalculationBenefit(
    memberId: string,
    calculationBenefitId: string,
    calculationType: CalculationType,
    processToStatus: CalculationBenefitHistoryStatus,
    calculationRecordId?: string,
    payeeQdroIds?: (string|undefined)[],
  ) {
    const context = new HttpContext().set(SHOW_LOADING, true);
    context.set(SKIP_ERRORS, '500'); // do not display common Unexpected error popup
    let params = new HttpParams();
    if (calculationRecordId) params = params.append('calculationRecordId', calculationRecordId);
    if (payeeQdroIds) {
      params = params.append('payeeQdroIds', payeeQdroIds.filter(id => !!id).join(','));
    }
    return this.httpClient.get(
      `${environment.apiUrl}/calculations/${memberId}/benefit-processing/calculation-benefit-histories/${calculationType}/${calculationBenefitId}/check-condition-to-process/${processToStatus}`,
      { params, context },
    );
  }
}
