import { Component, Inject, OnInit, AfterViewChecked } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { ACTION, CANCEL_CONFIRM_MESSAGE, SortType, STATE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { Option } from '@ptg-shared/controls/select/select.component';
import { deepClone } from '@ptg-shared/utils/common.util';
import { Observable, Subject } from 'rxjs';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { TagsState } from '../../store/reducers';
import {
  clearTagDetailStateAction,
  generateParticipantDocumentsAction,
  getListTemplateAction,
  getListTemplateTagsAction,
  getTagListAction,
} from '../../store/actions';
import {
  addTagListSelector,
  getListTemplateSelector,
  getListTemplateTagSelector,
  getTagListSelector,
} from '../../store/selectors';
import { BaseComponent } from '@ptg-shared/components';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination';
import { DocumentState } from '@ptg-employer/reducers/employer-document.reducer';
import { DocumentTemplateState } from '../../../template/store/reducers';
import { EntityType } from '../../types/enums/entity-type.enum';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { AddTagComponent } from 'src/app/admin/features/file/components/add-tag/add-tag.component';
import { FundType } from '@ptg-shared/types/enums';
import { CalculationType } from '@ptg-member/features/calculation/types/enums';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { GenerateDocument } from '@ptg-member/types/models/generate-document.models';
import { DOCUMENT_LOCATION } from '@ptg-shared/constance/document-location.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { GenerateDocumentComponentService } from './generate-document.component.service';

@Component({
  selector: 'ptg-generate-document',
  templateUrl: './generate-document.component.html',
  styleUrls: ['./generate-document.component.scss'],
})
export class GenerateDocumentComponent extends BaseComponent implements OnInit, AfterViewChecked {
  title: string = 'Generate Document';
  readonly EntityType = EntityType;
  listBreadcrumbs: Breadcrumb[] = [];
  entityType!: EntityType;
  targetId?: string;
  generateDocForm!: FormGroup;
  formSubmit$ = new Subject<boolean>();
  isShowError: boolean = false;
  currentTagsChipValue!: Option;
  filteredByTypingTagOptions!: Observable<Option[]>;
  listOptionTag: Option[] = [];
  listTemplate: any[] = [];
  pageNumber: number = FIRST_PAGE;
  isLoading: boolean = true;
  pageSize: number = 50;
  listType = [
    { displayValue: 'PDF', value: 'pdf' },
    { displayValue: 'Word', value: 'docx' },
  ];

  currentTagsChipValueList: string[] = [];
  pensionConfirmationLetter: string = 'pension confirmation letter';
  isNewTagAddedSuccess = false;
  parkAwardLetter: string = 'retiree award letter';
  parkChildAwardLetter: string = 'surviving child award letter';
  parkSpouseAwardLetter: string = 'surviving spouse award letter';

  message!: string;
  bannerType: BannerType = BannerType.Hidden;
  isLoadingInfoBanner!: boolean;
  isHideCloseButton!: boolean;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GenerateDocumentComponent>,
    public tagStore: Store<TagsState>,
    private documentStore: Store<DocumentState>,
    private documentTemplateStore: Store<DocumentTemplateState>,
    private generateDocumentService: GenerateDocumentComponentService,
    public layoutService: LayoutService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      isPendingPayments?: boolean;
      currentEntity: any;
      documentForm: any;
      isCustomParentUrl?: boolean;
      isHideParentItem?: boolean;
      calculationType?: CalculationType;
      currentFund?: any;
      memberId?: string;
      isPreview?: boolean;
      documentLocationTitle?: string;
      documentLocationRouter?: string;
      paymentInstructionId?: string;
    },
  ) {
    super();
  }

  get templateCtrl(): FormControl {
    return this.generateDocForm?.get('template') as FormControl;
  }

  ngOnInit(): void {
    this.initFormGroup(null);
    this.selectDataTagList();
    this.getTagList();
    this.getDataTemplateList();
    this.registerGetListDocumentTemplateSelector();
    this.registerAddNewTagSelector();
    this.submitForm();
  }

  ngAfterViewChecked(): void {
    let inputWithfilterColumnId = document.querySelectorAll("[id='filterColumn']");
    let Label = document.querySelectorAll('.mat-form-field-label');
    let matLabel = document.querySelectorAll('mat-label');
    let tagsSelect = document.querySelectorAll("[id='mat-input-1']");

    if (inputWithfilterColumnId.length > 0) {
      (function () {
        for (let i = 0; i < inputWithfilterColumnId.length; i++) {
          let val = i + 1;
          inputWithfilterColumnId[i].setAttribute('id', 'filterColumn' + val);
          matLabel[val].setAttribute('htmlFor', 'filter-column-' + val);
        }
        if (matLabel[3]) {
          matLabel[3].setAttribute('htmlFor', 'mat-input-1');
        }
        for (let i = 1; i < Label.length; i++) {
          Label[i].removeAttribute('aria-owns');
          if (i > 0) {
            Label[i].setAttribute('for', 'filter-column-' + i);
          }
          if (i == 3) {
            Label[i].setAttribute('for', 'mat-input-1');
          }
        }
      })();
    }
    if (tagsSelect.length > 0) {
      tagsSelect[0].setAttribute('floatlabel', 'always');
    }
  }

  initFormGroup(formData: any | null) {
    const { currentEntity } = this.data;
    this.entityType = currentEntity?.entityType;
    this.targetId = currentEntity.entityId;
    this.listBreadcrumbs = this.getBreadcrumbs(currentEntity.entityType, currentEntity.entityId);
    this.generateDocForm = this.fb.group({
      template: this.fb.control(formData?.templateId || [], [Validators.required]) ?? '',
      fileType: this.fb.control([], [Validators.required]) ?? '',
      isShowPreview: this.fb.control(false),
      tags: this.fb.control(''),
      tagsChipList: this.fb.array([]),
    });
  }

  getBreadcrumbs(entityType: EntityType, entityId: string): Breadcrumb[] {
    const url =
      entityType == EntityType.Employer
        ? `/employer/individual-document/${entityId}`
        : `/member/individual-document/${entityId}`;
    const dataList = [];
    if (!this.data.isHideParentItem) {
      dataList.push({
        name: this.data.isCustomParentUrl ? this.data.documentForm?.documentLocationTitle : 'Document List',
        url: this.data.isCustomParentUrl ? this.data.documentForm?.documentLocationRouter : url,
      });
    }
    dataList.push({ name: 'Generate Document' });
    return dataList;
  }

  registerAddNewTagSelector() {
    this.tagStore.pipe(select(addTagListSelector), takeUntil(this.unsubscribe$)).subscribe((response) => {
      if (response?.state === STATE.SUCCESS && response?.action === ACTION.ADD) {
        this.isNewTagAddedSuccess = true;
      }
      this.tagStore.dispatch(clearTagDetailStateAction());
    });
  }

  submitForm() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.generateDocForm.markAllAsTouched();
        }),
        switchMap(() =>
          this.generateDocForm.statusChanges.pipe(
            startWith(this.generateDocForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => status === AbstractControlStatus.VALID),
      )
      .subscribe(() => {
        this.onSubmit();
      });
  }

  onSubmit() {
    const templateName = this.listTemplate.find(
      (temp) => temp.value === this.generateDocForm.get('template')?.value,
    )?.displayValue;
    const fileTypeName = this.listType.find(
      (item: any) => item.value === this.generateDocForm.get('fileType')?.value,
    )?.displayValue;
    const objGenerate = this.generateDocForm.value;
    objGenerate['tags'] = this.currentTagsChipValueList;
    objGenerate['templateName'] = templateName;
    objGenerate['fileTypeName'] = fileTypeName;
    if (this.data?.isPendingPayments) {
      objGenerate['template'] = this.templateCtrl?.value;
    }
    this.layoutService.showLoading = true;
    const body: GenerateDocument = {
      templateId: objGenerate.template,
      memberId: this.data.memberId,
      exportType: objGenerate.fileType,
      tagsId: objGenerate.tags,
      currentDate: new Date().toISOString(),
      toDate: new Date().toISOString(),
      timeZoneOffset: new Date().getTimezoneOffset(),
      documentLocationTitle: this.data.documentLocationTitle ?? DOCUMENT_LOCATION.DOCUMENTLIST,
      documentLocationRouter: this.data.documentLocationRouter ?? `/member/individual-document/${this.data.memberId}`,
      isPreview: objGenerate.isShowPreview,
      paymentInstructionId: this.data.paymentInstructionId,
    };
    this.generateDocumentService.selectedGenerateDocumentDataFromService = body;
    this.generateDocumentService.selectedGenerateDocumentInfoFromService = objGenerate;
    this.documentStore.dispatch(generateParticipantDocumentsAction({ body }));
    this.handleShowBannerGenerating();
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: { text: CANCEL_CONFIRM_MESSAGE, type: ConfirmType.Cancel },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  private handleShowBannerGenerating() {
    this.isLoadingInfoBanner = true;
    this.bannerType = BannerType.Info;
    this.isHideCloseButton = true;
    this.message = 'Document is being generated, this might take a few minutes for everything fully generated.';
  }

  getTagList() {
    let sortType = SortType.ASC;
    let sortNames = 'Name';
    this.tagStore.dispatch(
      getTagListAction({
        request: {
          sortNames,
          sortType,
        },
      }),
    );
  }

  selectDataTagList() {
    this.tagStore.pipe(select(getTagListSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        if (data?.success && data?.payload?.length) {
          const previousList = deepClone(this.listOptionTag);

          this.listOptionTag = data?.payload.map(
            (item) =>
              ({
                value: item.id,
                displayValue: item.name,
              }) as Option,
          );

          if (this.isNewTagAddedSuccess) {
            const newTag = this.listOptionTag.find((item) => !previousList.some((tag) => tag.value === item.value));
            if (newTag) {
              this.tagsChipListControl.push(new FormControl(newTag));
              this.currentTagsChipValueList.push(newTag?.value);
            }
            this.isNewTagAddedSuccess = false;
          }
        }
      }
    });
  }

  getDataTemplateList() {
    this.documentStore.dispatch(
      getListTemplateAction({
        request: {
          sortType: SortType.ASC,
          sortNames: 'TemplateName',
        },
      }),
    );
  }

  registerGetListDocumentTemplateSelector() {
    const templateUsedFor = this.entityType === EntityType.Employer ? 'Employer' : 'Member';
    this.documentStore.pipe(select(getListTemplateSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success) {
        this.listTemplate =
          data?.payload
            ?.filter((item) => item.usedFor === templateUsedFor)
            ?.map((temp) => {
              return {
                value: temp.id,
                displayValue: temp.templateName,
                fileId: temp.fileId,
              };
            }) ?? [];
        if (this.listTemplate.length === 0) {
          this.templateControl.setErrors({ errMsgTemplate: 'No available templates' });
          this.templateControl.markAsTouched();
        }
        if (this.data?.isPendingPayments) {
          if (this.data?.currentFund.fundType === FundType.BVFF) {
            this.listTemplate = this.listTemplate.filter(
              (item) => item.displayValue.toLowerCase() === this.pensionConfirmationLetter,
            );
          }
          if (this.data?.currentFund.fundType === FundType.ChicagoParks) {
            let templateName = this.parkAwardLetter;
            if (this.data?.calculationType === CalculationType.ChildSurvivor) {
              templateName = this.parkChildAwardLetter;
            }
            if (this.data?.calculationType === CalculationType.Survivor) {
              templateName = this.parkSpouseAwardLetter;
            }
            this.listTemplate = this.listTemplate.filter((item) => item.displayValue.toLowerCase() === templateName);
          }

          const formData = {
            templateId: this.listTemplate[0]?.value,
          };
          this.initFormGroup(formData);
          this.templateCtrl.disable();
        }
      }
    });
  }

  validateTags(): void {
    if (this.tagsAutoCompleteControl.value) {
      if (
        (this.tagsAutoCompleteControl.enabled && typeof this.tagsAutoCompleteControl.value === 'string') ||
        this.currentTagsChipValueList?.includes(this.tagsAutoCompleteControl.value.value)
      ) {
        this.tagsAutoCompleteControl.setErrors({ inValidAsync: true });
        return;
      }
    }
  }

  tagsFocus(event: any) {
    const addClass = document.querySelectorAll('.cdk-overlay-connected-position-bounding-box');
    addClass[0]?.classList.add('list-tags-style');
    event.stopPropagation();
    this.currentTagsChipValue = deepClone(this.tagsAutoCompleteControl?.value);
    this.filteredByTypingTagOptions = this.tagsAutoCompleteControl.valueChanges.pipe(
      startWith(''),
      map(() => {
        return this.listOptionTag.filter(
          (item) =>
            item?.displayValue?.toLowerCase()?.includes((event.target.value as string).toLowerCase()) &&
            !this.currentTagsChipValueList?.includes(item?.value),
        );
      }),
    );
  }

  onAddNewChip() {
    this.removeChipAttr();
    if (!this.tagsAutoCompleteControl.value || this.tagsAutoCompleteControl.invalid) {
      this.tagsAutoCompleteControl.markAsTouched();
      return;
    }

    const chip = this.currentTagsChipValue;
    this.tagsAutoCompleteControl.reset('');
    const chipControl = new FormControl(chip);

    this.tagsChipListControl.push(chipControl);
    if (!this.currentTagsChipValueList.includes(chip.value)) {
      this.currentTagsChipValueList.push(chip.value);
    }

    if (this.currentTagsChipValueList.length == this.listOptionTag.length) {
      this.tagsAutoCompleteControl.disable();
    }
  }
  removeChipAttr() {}
  onRemoveChip(value: any, index: number) {
    if (!value) return;
    this.currentTagsChipValueList.splice(index, 1);
    this.tagsChipListControl.removeAt(index);
    if (this.currentTagsChipValueList.length == this.listOptionTag.length - 1) {
      this.tagsAutoCompleteControl.enable();
    }
  }

  displayTagFn(value: Option): string {
    return value ? value.displayValue : '';
  }

  onSelectTemplate(event: any) {
    this.documentTemplateStore.dispatch(
      getListTemplateTagsAction({
        fileId: event.fileId,
      }),
    );
    this.documentStore.pipe(select(getListTemplateTagSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data?.success) {
        if (data.payload?.documentDetail?.tags.length) {
          this.getTagsChipList(data.payload?.documentDetail?.tags);
        }
      }
    });
  }

  getTagsChipList(list: any) {
    this.tagsAutoCompleteControl.reset('');
    list
      .map((item: any) => {
        return {
          displayValue: item.name,
          value: item.id,
        };
      })
      .forEach((tag: any) => {
        const exist = this.tagsChipListControl.value.find((item: any) => item.value === tag.value);
        const tagDetail = new FormControl(tag);
        if (!exist || !this.tagsChipListControl.value.length) {
          this.tagsChipListControl.push(tagDetail);
          this.currentTagsChipValueList.push(tag.value);
        }
      });
  }

  displayValue(value: string) {
    return value.length > 20 ? value.substring(0, 20) + '...' : value;
  }

  onClickBreadcrumb() {
    this.dialogRef.close();
  }

  addNewTags() {
    // call add tag component
    const dialogRef = this.dialog.open(AddTagComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: true,
      data: {
        tag: null,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result?.isSuccess) {
        this.getTagList();
      }
    });
  }

  get templateControl(): FormControl {
    return this.generateDocForm?.get('template') as FormControl;
  }

  get fileTypeControl(): FormControl {
    return this.generateDocForm?.get('fileType') as FormControl;
  }

  get isShowPreviewControl(): FormControl {
    return this.generateDocForm?.get('isShowPreview') as FormControl;
  }

  get tagsAutoCompleteControl(): FormControl {
    return this.generateDocForm?.get('tags') as FormControl;
  }

  get tagsChipListControl(): FormArray {
    return this.generateDocForm?.get('tagsChipList') as FormArray;
  }
}
