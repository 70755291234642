import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { filter, takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { CANCEL_CONFIRM_MESSAGE, GUID_EMPTY, SortType } from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { BaseComponent } from '@ptg-shared/components';
import { deepClone } from '@ptg-shared/utils/common.util';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';

import { clearCheckIfRecordExistedStateAction, clearCreateEntityDataStateAction, clearUpsertComponentListDataStateAction, createEntityDataAction, upsertComponentListDataAction } from '@ptg-entity-management/store/actions';
import { MemberState } from '@ptg-member/store/reducers';
import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import { EntityInitiationPropertyValue, GetInitiationPropertiesResponse } from '@ptg-entity-management/services/models';
import { EntityDataState, EntityManagementState } from '@ptg-entity-management/store/reducers';
import { createEntityDataSelector, upsertComponentListDataSelector } from '@ptg-entity-management/store/selectors';
import { getInitiationPropertiesSelector } from '@ptg-entity-management/store/selectors';
import {
  clearGetInitiationPropertiesStateAction,
  getInitiationPropertiesAction,
} from '@ptg-entity-management/store/actions';
import { clearUpdateMemberCardStateAction, updateMemberCardAction } from '@ptg-member/store/actions';
import { updateMemberCardSelector } from '@ptg-member/store/selectors/member.selector';
import { GetListRelatedPersonQuery } from '@ptg-member/types/models';
import * as RelatedPersonActions from '@ptg-member/store/actions/related-person.action';
import { ENTITY_MAPPING } from '@ptg-entity-management/constants/entity-mapping.constant';
import { CalculationState, getCalculationQDROSelector } from '@ptg-member/features/calculation/store';
import { CalculationType } from '@ptg-member/features/calculation/types/enums';

@Component({
  selector: 'ptg-create-entity-data',
  templateUrl: './create-entity-data.component.html',
  styleUrls: ['./create-entity-data.component.scss'],
})
export class CreateEntityDataComponent extends BaseComponent {
  readonly EntityPropertyType = EntityPropertyType;
  entityProperties!: GetInitiationPropertiesResponse;
  cardData: any;
  cardInfor = {
    id: '',
    isSummaryView: false,
    isList: false,
  };
  title: string = '';
  entityId: string = '';

  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isEditCard: boolean = false;
  // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
  qdroLabelName: string = '';
  constructor(
    private memberStore: Store<MemberState>,
    private entityManagementStore: Store<EntityManagementState>,
    // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
    private calculationStore: Store<CalculationState>,
    private entityDateStore: Store<EntityDataState>,
    public dialogRef: MatDialogRef<CreateEntityDataComponent>,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    super();
  }

// FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
  private getBenefitEntityId() {
    this.calculationStore
      .select(getCalculationQDROSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((getCalculationQDRO) => {
        if (!getCalculationQDRO?.isLoading && getCalculationQDRO?.success) {
          this.qdroLabelName = getCalculationQDRO?.payload?.labelName ?? '';
        }
      });
  }

  private _getInitiationProperties() {
    const {
      section: { cardId, entityId, entityComponentId },
      isSummaryView,
      cardData,
      isAddRecord,
      isList,
      screenId,
    } = this.data;
    let cardName = this.data.detailViewLabel ? this.data.detailViewLabel : this.data?.section?.cardName;
    this.isEditCard = !isAddRecord;
    this.cardInfor = {
      id: cardId,
      isSummaryView: isSummaryView,
      isList: isList ? true : false,
    };
    if (cardData) {
      this.cardData = deepClone(cardData);
    }
    if (this.data.propertiesConfig) {
      this.entityProperties = {
        entityId,
        entityLabel: '',
        entityComponentId,
        properties: deepClone(this.data.propertiesConfig),
      };
      this.title = `${isAddRecord ? 'Add ' + cardName + ' Record' : 'Edit ' + cardName}`;
      this.entityId = entityId;
    } else {
      this.memberStore.dispatch(
        getInitiationPropertiesAction({
          entityId,
          cardId,
          isList: isList ? true : false,
          isSummaryView,
          screenId,
          includeEntitySystem: this.data?.section?.isCourtOrder ? true : false,
        }),
      );
      // Get the list of initiation properties
      this.memberStore
        .pipe(
          select(getInitiationPropertiesSelector),
          filter((state) => !!state),
          takeUntil(this.unsubscribe$),
        )
        .subscribe((state) => {
          if (!state?.isLoading && state?.success && state?.payload && !this.entityProperties) {
            // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
            let _entityProperties: GetInitiationPropertiesResponse = deepClone(state.payload);
            _entityProperties.properties = _entityProperties.properties?.map((property) => ({
              ...property,
              options: property.options.map((option) => ({
                ...option,
                text: option.text?.includes(CalculationType[CalculationType.QDRO])
                  ? option.text?.replaceAll(CalculationType[CalculationType.QDRO], this.qdroLabelName)
                  : option.text
              })),
            }));
            this.entityProperties = _entityProperties;
            // this.entityProperties = deepClone(state.payload);

            this.title = `${isAddRecord ? 'Add ' + cardName + ' Record' : 'Edit ' + cardName}`;
            this.entityId = entityId;
          }
          this.memberStore.dispatch(clearGetInitiationPropertiesStateAction());
        });
    }
  }

  ngOnInit(): void {
    super.ngOnInit();
    // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
    this.getBenefitEntityId();
    this.getRelatedPersonList();
    this._upsertComponentListDataSelector();
    this._updateMemberCardSelector();
    this._updateEntityDataSelector();
    this._getInitiationProperties();
    this._clearStateAfterDialogClose();
  }

  private _upsertComponentListDataSelector(): void {
    this.entityManagementStore
      .pipe(select(upsertComponentListDataSelector), takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        if (el) {
          if (el?.success) {
            this.dialogRef.close('submit');
          } else if (el.error) {
            this.dialogRef.close('submitFail');
          }

          this.memberStore.dispatch(clearUpsertComponentListDataStateAction());
        }
      });
  }

  private _updateMemberCardSelector(): void {
    this.memberStore.pipe(select(updateMemberCardSelector), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this._handleAfterUpdateEntityData(el, true);
    });
  }

  private _updateEntityDataSelector(): void {
    this.memberStore.pipe(select(createEntityDataSelector), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this._handleAfterUpdateEntityData(el, false);
    });
  }

  private _handleAfterUpdateEntityData(state: any, isMember: boolean) {
    if (state) {
      if (state?.success) {
        this.dialogRef.close('submit');
      } else if (state.success === false) {
        if(state?.error && state?.error.error.errorMessage){
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              text: state?.error.error.errorMessage[1],
              type: ConfirmType.Warning,
              title: 'Error',
              cancelButtonTitle: 'Close',
              hideConfirmButton: true,
            }
          });
        } else {
          this.bannerType = BannerType.Fail;
          this.message = `Error occurred updating ${state.payload?.cardName}. Please try again.`;
        }
      }

      if (isMember) {
        this.memberStore.dispatch(clearUpdateMemberCardStateAction());
      } else {
        this.memberStore.dispatch(clearCreateEntityDataStateAction());
      }
    }
  }

  private getRelatedPersonList() {
    const query: GetListRelatedPersonQuery = {
      screenId: this.data.screenId,
      memberId: this.data.memberId,
      pageIndex: 1,
      pageSize: 100,
      sortNames: ['Name'],
      sortType: SortType.ASC,
    };
    this.memberStore.dispatch(
      RelatedPersonActions.getRelatedPersonList({ query })
    );
  }

  private _remapPayloadReference(newRequest: { entityId: string; entityPropertyValues: EntityInitiationPropertyValue[]}) {
    newRequest.entityPropertyValues?.forEach(el => {
      if (el.entityPropertyReferenceValue?.entityPropertyValues.length > 0 && typeof el.entityPropertyReferenceValue?.entityPropertyValues === 'object') {
        el.entityPropertyReferenceValue?.entityPropertyValues.forEach((item: any) => {
          item.recordId = el.entityPropertyReferenceValue?.recordId;
        });
      }
    });
  }

  onSubmit(newRequest: { entityId: string; entityPropertyValues: EntityInitiationPropertyValue[] }): void {
    newRequest.entityPropertyValues = newRequest.entityPropertyValues.filter(
      (x) => x.recordId !== null || x.entityComponentId !== GUID_EMPTY || x.entityPropertyId !== GUID_EMPTY,
    );
    const {
      section: { entityId, cardId, cardName },
      isAddRecord,
      recordId: id,
      memberId,
      addToTop,
      isList,
    } = this.data;
    if (isList) {
      const request = {
        ...newRequest,
        recordId: id,
        entityId,
        entityComponentId: this.entityProperties.entityComponentId,
        targetId: memberId,
        addToTop,
      };

      if (!isAddRecord) {
        request.entityPropertyValues = request.entityPropertyValues.reduce(
          (acc: EntityInitiationPropertyValue[], entityPropertyValue: EntityInitiationPropertyValue) => {
            let newEntityPropertyValue = entityPropertyValue;

            if (entityPropertyValue?.entityPropertyReferenceValue) {
              const currentEntityPropertyValue =
                entityPropertyValue?.entityPropertyReferenceValue.entityPropertyValues?.filter(
                  (item: any) => item.value || item.value === false || item.value === 0 || item.value === null || item.value === '',
                );

              if (currentEntityPropertyValue?.length) {
                newEntityPropertyValue.entityPropertyReferenceValue.entityPropertyValues = currentEntityPropertyValue;
              } else {
                return acc;
              }
            }
            return [...acc, newEntityPropertyValue];
          },
          [],
        );
        this._remapPayloadReference(request);
      }
      
      this.memberStore.dispatch(upsertComponentListDataAction({ request }));
    } else {
      if (this.entityId === ENTITY_MAPPING.Member) {
        this.memberStore.dispatch(
          updateMemberCardAction({
            memberId: memberId,
            cardName: cardName,
            request: {
              ...newRequest,
              entityId,
            },
          }),
        );
      } else {
        this.memberStore.dispatch(
          createEntityDataAction({
            recordId: memberId,
            cardName: cardName,
            request: {
              ...newRequest,
              entityId,
            },
          }),
        );
      }
    }
  }

  onCancel(): void {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.CancelPopup,
        cancelButtonTitle: 'No',
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  _clearStateAfterDialogClose() {
    this.dialogRef.afterClosed().subscribe(() => {
      this.entityDateStore.dispatch(clearCheckIfRecordExistedStateAction())
    })
  }

}
