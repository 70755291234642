import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'einNumber'
})
export class EINNumberPipe implements PipeTransform {

  transform(value: any, mask?: boolean) {
    if (value && (/^([0-9]{9})$/).test(value.trim())) {
      let match = value.match(/^(\d{7})(\d{2})$/);
      if (match) {
        if (mask) {
          return match[1].replace(/[0-9]/g, 'X') + '-' + match[2];
        }
        value = match[1] + '-' + match[2];
      }
    }
    return value;
  }

}
