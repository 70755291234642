import { Component, Inject, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store, select } from '@ngrx/store';

import { BaseComponent } from '@ptg-shared/components';
import { ACTION, CANCEL_CONFIRM_MESSAGE, STATE } from '@ptg-shared/constance';
import { InputType, PropertyType } from '@ptg-shared/controls/dynamic-input/types/enums/dynamic-input.enum';
import { PersonNamePipe } from '@ptg-shared/pipes/person-name.pipe';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { filter, takeUntil } from 'rxjs/operators';

import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import {
  Properties,
  SetBenefitDetailProperties,
  UpdateCalculationBenefitDetailRequest,
} from '@ptg-member/features/calculation/services/models';
import { RetirementBenefitDataInput } from '@ptg-member/features/calculation/services/models/retirement-benefit.model';
import {
  clearGetStepConfigurationsStateAction,
  clearGetValidationExceptionConfigurationStateAction,
  clearSetCalculationParameterStateAction,
  clearUpdateCalculationBenefitDetailByTypeStateAction,
  getValidationExceptionConfigurationAction,
  updateCalculationBenefitDetailByTypeAction,
} from '@ptg-member/features/calculation/store/actions';
import { CalculationState } from '@ptg-member/features/calculation/store/reducers';
import {
  getValidationExceptionConfigurationSelector,
  setCalculationParameterSelector,
} from '@ptg-member/features/calculation/store/selectors';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { FundType } from '@ptg-shared/types/enums';
import { FormControlDetail } from '@ptg-shared/types/models';
import { formatUtcDateString, showBanner, showCancelDialog } from '@ptg-shared/utils/common.util';
import { isEmpty, stringToBoolean } from '@ptg-shared/utils/string.util';
import { DateTime } from 'luxon';
import { BenefitDetail, GetValidationExceptionRequest } from '../../services/models/exception-configuration.model';
import { CalculationBenefitDetailType } from '../../services/models/retirement-benefit-detail.model';
import { CalculationType, ExceptionType } from '../../types/enums';
import { validationData } from '../component.util';

@Component({
  selector: 'ptg-edit-calculation-parameter',
  templateUrl: './edit-calculation-parameter.component.html',
  styleUrls: ['./edit-calculation-parameter.component.scss'],
  providers: [PersonNamePipe],
})
export class EditCalculationParameterComponent extends BaseComponent {
  @ViewChild('fileDocument')
  DEFAULT_BENEFIT_TYPE = 'Retirement';
  listBreadcrumbs: Breadcrumb[] = [{ name: 'Edit Calculation Parameter' }];

  message = '';
  bannerType: BannerType = BannerType.Hidden;

  formSubmit$: any;
  formData!: FormGroup;
  memberId = '';
  checkPattern = new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i');

  listSection: any[] = [];
  dialogTitle: string = 'Edit Calculation Parameter';
  parametersSysConfigs: FormControlDetail[] = [];
  parametersOverriddenConfigs: FormControlDetail[] = [];

  isShowHeader: boolean = false;
  isCustomRequired: boolean = false;
  earnedServiceField: string = 'Earned Service';
  requiredFields: string[] = [
    'Benefit Begin Date',
    'Monthly Benefit Amount',
    this.earnedServiceField,
  ];

  eligibleForAnnualIncrease: string = 'Eligible for Annual Increase';
  refundOfAnnualIncrease: string = 'Refund Of Annual Increase';
  annualIncreaseEffectiveDate: string = 'Annual Increase Effective Date';
  requiredFieldsByEligible: string[] = ['Annual Increase Effective Date', 'Annual Increase Amount'];
  validationExceptionInfo: BenefitDetail[] | undefined;

  isJointSurvivor: boolean | null = null;
  enableFieldsInJointSurvivorCase: string[] = ['benefitbegindate'];

  exclusionEligible: string = 'Exclusion Eligible';
  survivingSpouseEligibleForAnnuity = 'Is Surviving Spouse Annuity Eligible'
  investmentInContractField: string = 'Investment in Contract';
  spouseAnnuityField: string = 'Spouse Annuity';
  survivorRefundAmountField = 'Survivor Refund Amount';
  requiredFieldsByExclusionEligible: string[] = [this.investmentInContractField];
  customErrorMessage: string = 'Exclusion Eligible must be checked to be able to edit Investment in Contract.';
  // validateWithZeroFields: string[] = ['Investment in Contract', 'Survivor Refund Amount', 'Refund Of Annual Increase'];
  get isChicagoParks() {
    return this.data.currentFund.fundType === FundType.ChicagoParks;
  }

  get isQdro() {
    return this.data.calculationType === CalculationType.QDRO;
  }

  get isChildSurvivor() {
    return this.data.calculationType === CalculationType.ChildSurvivor;
  }

  constructor(
    public dialogRef: MatDialogRef<EditCalculationParameterComponent>,
    public dialog: MatDialog,
    public switchConfirmPopupService: SwitchConfirmPopupService,
    @Inject(MAT_DIALOG_DATA) public data: RetirementBenefitDataInput,
    private fb: FormBuilder,
    public calculationStore: Store<CalculationState>,
  ) {
    super();
  }

  get parameterSystemArray(): FormArray {
    return this.formData?.get('systemParameters') as FormArray;
  }
  get parameterOverriddenArray(): FormArray {
    return this.formData?.get('overriddenParameters') as FormArray;
  }

  ngOnInit(): void {
    this.memberId = this.data.memberId;
    this.formData = this.initForm;
    this.isJointSurvivor = this.data.calculationType === CalculationType.JointSurvivor;
    this.updateRequireFieldsCondition();
    this.getValidationExceptionConfigurationData();
    this.selectValidationExceptionState();

    this.otherComponentMapping();

    this.updateCalculationBenefitDetailByTypeListener();
  }

  updateRequireFieldsCondition() {
    if (this.isChicagoParks && this.isChildSurvivor) {
      this.requiredFields.push('Benefit End Date');
    }
    if (this.isChicagoParks && this.isQdro) {
      this.requiredFields.push('Payable Date', 'Number of Checks 1st Payment', '1st Check Amount');
    }
    if (this.isChicagoParks && this.data.calculationType === CalculationType.RetirementBenefit) {
      this.requiredFields.push('Spouse Annuity', 'Survivor Refund Amount');
    }
    const eligibleForAnnualIncreaseField = this.data?.properties?.find(
      (item) => item.label === this.eligibleForAnnualIncrease,
    );

    // Implement us 153949: Enhance logic validate for calculation parameter in Benefit Calculation
    if (
      this.isChicagoParks &&
      !this.initFieldsValue(eligibleForAnnualIncreaseField)
    ) {
      this.requiredFields.push(this.refundOfAnnualIncrease);
    } else if (this.isChicagoParks && this.initFieldsValue(eligibleForAnnualIncreaseField)) {
        this.requiredFields.push('Annual Increase Effective Date', 'Annual Increase Amount');
      }
  }

  otherComponentMapping() {
    this.isShowHeader = !this.isChicagoParks && !this.isQdro;
    const excludsionEligibleField = this.data?.properties?.find((item) => item.label === this.exclusionEligible);
    const survivingSpouseEligibleForAnnuityField = this.data.properties?.find((item) => item.label === this.survivingSpouseEligibleForAnnuity);

    this.data?.properties?.forEach((property) => {
      const lsOption = this.handleListOptions(property);

      const item = {
        ...property,
        id: property.benefitDetailKey,
        config: { ...property.config, fundType: this.data.currentFund.fundType },
        configs: property.config,
        type: EntityPropertyType[property.type],
        label: property.label,
        property: property,
        isRequired: false,
        lstOption: lsOption,
      } as any;
      let valueEdit = property.value;
      let overridenValue: any = property.userOverridenValue;

      if (property.type === EntityPropertyType.Date) {
        valueEdit = property.value ? this.processDateValue(property.value) : property.value;
        overridenValue = property.userOverridenValue
          ? this.processDateValue(property.userOverridenValue)
          : property.userOverridenValue;
      }
      const itemSys = { ...item, isDisabled: this.isShowHeader, value: valueEdit };

      const requiredField = this.isChicagoParks && this.requiredFields.includes(property.label);
      const itemOverridden = {
        ...item,
        isRequired: requiredField,
        isDisabled: this.initDisableField(item, excludsionEligibleField, survivingSpouseEligibleForAnnuityField),
        value: overridenValue || '',
        isShowCustomMessage: item.label === this.investmentInContractField,
        minMessage: `${item.label} must be equal or greater than 0`,
        allowNegativeNumbers: true, // Implement us 153949: Enhance logic validate for calculation parameter in Benefit Calculation
      };

      if (this.isJointSurvivor === true && this.checkEnableJointSurvivorFields(property?.label) === false) {
        itemOverridden['isDisabled'] = true;
      }
      if (this.isShowHeader) {
        itemOverridden.config = { ...itemOverridden.config, required: 'false' };
        itemOverridden.configs = { ...itemOverridden.configs, required: 'false' };
      }
      this.parametersSysConfigs.push(itemSys);

      this.parametersOverriddenConfigs.push(itemOverridden);
    });

    this.initFormGroup();
  }

  processDateValue(date: string) {
    return !DateTime.fromJSDate(new Date(date)).isValid
      ? null
      : formatUtcDateString(new Date(date).toISOString(), "yyyy-MM-dd'T'HH:mm:ss.000'Z'");
  }

  handleListOptions(property: any) {
    if (property.type === EntityPropertyType.Lookup) {
      return (property.options as any[]).map((option) => {
        return {
          value: option.id,
          displayValue: option.text,
        };
      });
    }
    if (property?.type === EntityPropertyType.Employer || property?.type === EntityPropertyType.Department) {
      return property.options;
    }
    return [];
  }

  onCancel(): void {
    showCancelDialog(this.dialog, this.dialogRef, CANCEL_CONFIRM_MESSAGE, () => {
      this.calculationStore.dispatch(clearGetStepConfigurationsStateAction());
    });
  }

  onSave(): void {
    if (this.isChicagoParks || this.isQdro) {
      this.formData.markAllAsTouched();
    }
    if (!this.formData.valid) {
      if (!this.isQdro) {
        const listControl = this.formData.controls.overriddenParameters as FormArray;
        let errorField = listControl.controls.map((item: any) => item?.controls).filter((x: any) => x?.value?.errors);
        let invalidF = errorField.filter((item: any) => item?.value?.errors?.hasOwnProperty('matDatepickerParse') || item?.value?.errors?.hasOwnProperty('matDatepickerMin') || item?.value?.errors?.hasOwnProperty('matDatepickerMax'));
        let invalidLabel = invalidF.map((item: any) => item?.label?.value);
        let requiredF =  errorField.filter((item: any) =>  (item?.value?.errors?.hasOwnProperty('required') || item?.value?.errors?.hasOwnProperty('customError')) && (!item?.value?.errors?.hasOwnProperty('matDatepickerParse') && !item?.value?.errors?.hasOwnProperty('matDatepickerMin') && !item?.value?.errors?.hasOwnProperty('matDatepickerMax')));
        let requiredLabel = requiredF.map((item: any) => item?.label?.value);
        let requiredLabelString  = '';
        if (requiredLabel?.length === 1) {
          requiredLabelString = requiredLabel?.[0];
        } else {
          requiredLabel.forEach((item, index) => {
            requiredLabelString += index <= requiredLabel.length - 2 ? item + ', ' :  ' and ' + item;
          })
        }
        if (invalidF?.length || requiredF?.length) {
          let beVerb = requiredF?.length === 1 ? 'is' : 'are';
          let requiredMessage = requiredF?.length ? `${requiredLabelString} ${beVerb} required. <br/>` : '';
          let invalidMessage = invalidF?.length ? `Value in ${invalidLabel.join(", ")}  is invalid.` : ''
          let customMessage = `${requiredMessage}` +  `${invalidMessage}`
          this.message = customMessage;
          showBanner.call(this, STATE.FAIL, '', '', {
            customMessage: customMessage
          });
        }
        return;
      }

      return;
    }

    const properties: Properties[] = (this.data.properties ?? []).map((property) => {
      const overriddenItem = this.formData
        .getRawValue()
        .overriddenParameters.filter((item: any) => item.id === property.benefitDetailKey);
      const isDatePropertyType = property.type === EntityPropertyType.Date;
      let value: any;
      let userOverridden: any;
      if (!isDatePropertyType) {
        value = property.value ?? '';
        userOverridden = overriddenItem[0].value?.toString() === 'NaN' ? '' : overriddenItem[0].value?.toString();
      } else {
        value = this.handleDateValue(
          property.value,
          new Date(property.value === '' ? null : property.value).toISOString(),
        );
        userOverridden = this.handleDateValue(overriddenItem[0].value, overriddenItem[0].value as string);
      }

      if (this.isChicagoParks || this.isQdro) {
        value = userOverridden === 'NaN' ? '' : userOverridden;
      }

      return {
        benefitDetailKey: property.benefitDetailKey,
        type: property.type,
        value,
        userOverridden,
      };
    });
    this.bannerType = BannerType.Hidden;
    this.dispatchUpdateCalculationBenefitDetailByType(properties);
  }

  handleDateValue(input: any, isoStringData: string): string {
    const result =
      input && DateTime.fromJSDate(new Date(input)).isValid
        ? DateTime.fromISO(isoStringData).toFormat('MM/dd/yyyy')
        : '';
    return result;
  }

  private dispatchUpdateCalculationBenefitDetailByType(properties: Properties[] = []): void {
    const request: UpdateCalculationBenefitDetailRequest = {
      benefitEntityId: this.data?.benefitEntityId ?? '',
      targetType: this.data?.calculationType ?? CalculationType.RetirementBenefit,
      targetId: this.data?.calculationBenefitId ?? '',
      properties,
    };
    const payload = {
      memberId: this.data.memberId,
      calculationBenefitId: this.data?.calculationBenefitId ?? '',
      detailType: CalculationBenefitDetailType.CalculationParameter,
      request,
    };
    this.calculationStore.dispatch(updateCalculationBenefitDetailByTypeAction(payload));
  }

  private updateCalculationBenefitDetailByTypeListener(): void {
    this.calculationStore
      .select(setCalculationParameterSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        const isSuccess = response?.success;
        this.calculationStore.dispatch(clearUpdateCalculationBenefitDetailByTypeStateAction());
        this.calculationStore.dispatch(clearSetCalculationParameterStateAction());
        showBanner.call(this, isSuccess ? STATE.SUCCESS : STATE.FAIL, this.data?.sectionLabel ?? '', ACTION.EDIT);

        if (isSuccess) {
          this.dialogRef.close(response);
        }
      });
  }

  initFormGroup(): void {
    this.parameterSystemArray.clear();
    this.parameterOverriddenArray.clear();

    this.parametersSysConfigs.forEach((property: any) => {
      const objSysItem = this.fb.group({
        id: property.id,
        options: property.options,
        order: property.order,
        orderColumn: property.orderColumn,
        sectionKey: property.key,
        value: this.fb.control(property.value ?? ''),
        isDisabled: property.isDisabled,
        option: property.option,
      });
      this.parameterSystemArray.push(objSysItem);
    });

    this.parametersOverriddenConfigs.forEach((property: any) => {
      const objOverriddenItem = this.fb.group({
        id: property.id,
        options: property.options,
        order: property.order,
        orderColumn: property.orderColumn,
        sectionKey: property.key,
        isRequired: property.isRequired,
        option: property.option,
        label: property.label,
        value: this.fb.control(this.initFieldsValue(property), [this.customValidation(property)]),
        calculationParamMappingId: property.calculationParamMappingId,
        isDisabled: property.isDisabled,
      });
      this.parameterOverriddenArray.push(objOverriddenItem);
    });
  }

  initFieldsValue(property: any): any {
    if (isEmpty(property?.value) || property?.value === '') {
      return null;
    }
    if (property?.type === InputType.Binary || property.type === PropertyType.Binary) {
      return stringToBoolean(property?.value);
    }
    return property?.value;
  }

  // Implement us 153949: Enhance logic validate for calculation parameter in Benefit Calculation
  initDisableField(property: any, excludsionEligibleField: any, survivingSpouseEligibleForAnnuityField: any): any {
    if (property?.label === this.investmentInContractField && !this.initFieldsValue(excludsionEligibleField)) {
      return true;
    }
    // Implement us 153949: Enhance logic validate for calculation parameter in Benefit Calculation
    if (this.isChicagoParks && property?.label === this.survivorRefundAmountField && this.initFieldsValue(survivingSpouseEligibleForAnnuityField)) {
      return true;
    }
    if (this.isChicagoParks && property?.label === this.spouseAnnuityField && !this.initFieldsValue(survivingSpouseEligibleForAnnuityField)) {
      return true;
    }
    return property?.isDisabled;
  }

  get initForm(): FormGroup {
    return this.fb.group({
      systemParameters: this.fb.array([]),
      overriddenParameters: this.fb.array([]),
    });
  }
  changeValue(event: any, property: any) {
    const listData = this.formData.getRawValue().overriddenParameters;
    let listIndex: number[] = [];
    let indexFields: number[] = [];
    listData.forEach((item: any, index: number) => {
      if (this.requiredFieldsByEligible.includes(item.label)) {
          listIndex.push(index);
      }
      if (this.requiredFieldsByExclusionEligible.includes(item.label)) {
        indexFields.push(index);
      }
    });

    this.handleValidateRequiredField(event, property, listIndex, this.eligibleForAnnualIncrease);
    //handle required fields depend on Exclusion Eligible
    this.handleValidateRequiredField(event, property, indexFields, this.exclusionEligible);

    // Trigger exception validate for other related fields used to compare with current field
    const validateExceptionInfoUsedForCurrentField = this.validationExceptionInfo?.filter(
      (item) =>
        item?.benefitId === this.data?.benefitEntityId &&
        item?.additionalInfos?.length > 0 &&
        item?.additionalInfos?.[0]?.value === property?.value?.calculationParamMappingId,
    );

    // Current Overridden Parameter form controls
    const ovrdCtrls = (this.formData?.controls?.overriddenParameters as any)?.controls ?? [];
    this.handleDisableFields(event, property, ovrdCtrls);
    //handle validate field must >=0
    this.handleValidateMinValueRefundOfAnnualIncreaseFields(event, property, ovrdCtrls);
    // Update validity for related other fields, only check in case other fields have value
    validateExceptionInfoUsedForCurrentField?.forEach((item) => {
      ovrdCtrls?.forEach((relatedField: any, index: number) => {
        if (
          relatedField?.controls?.calculationParamMappingId?.value === item?.additionalInfos?.[2]?.value &&
          relatedField?.controls?.value?.value
        ) {
          ovrdCtrls?.[index]?.controls?.value?.updateValueAndValidity();
          ovrdCtrls?.[index]?.controls?.value?.markAsTouched();
        }
      });
    });
  }

  handleOutFocus(event: any, property: any) {
    if (property.value.label.toLowerCase() === this.earnedServiceField.toLowerCase()) {
      const value = parseFloat(property.value.value);
      const ovrdCtrls = (this.formData?.controls?.overriddenParameters as any)?.controls ?? [];
      if (!isNaN(value)) {
        property.value.value = value.toFixed(2);
        ovrdCtrls?.forEach((relatedField: any, index: number) => {
          if (relatedField?.controls?.label?.value?.toLowerCase() === this.earnedServiceField.toLowerCase()) {
            ovrdCtrls?.[index]?.controls?.value?.setValue(value.toFixed(2));
          }
        });
      }
    }
  }

  handleValidateRequiredField(event: any, property: any, listIndex: number[], dependField: string) {
    const listControl = this.formData.controls.overriddenParameters as FormArray;
    if (event && property.value.label === dependField) {
      listControl.controls.forEach((element, index) => {
        if (listIndex.includes(index)) {
          (element as FormGroup).controls.value.addValidators([Validators.required]);
          (element as FormGroup).controls.value.updateValueAndValidity();
        }
      });
    }
    if (!event && property.value.label === dependField) {
      listControl.controls.forEach((element, index) => {
        if (listIndex.includes(index)) {
          (element as FormGroup).controls.value.removeValidators([Validators.required]);
          (element as FormGroup).controls.value.updateValueAndValidity();
        }
        // Validate required for refund of annual increase field with label is refund Of Annual Increase
        if (element?.value?.label === this.eligibleForAnnualIncrease && !element?.value?.value && element?.value?.label === this.refundOfAnnualIncrease) {
          (element as FormGroup).controls.value.addValidators([Validators.required]);
          (element as FormGroup).controls.value.updateValueAndValidity();
        }
      });
    }
  }

  handleValidateMinValueRefundOfAnnualIncreaseFields(event: any, property: any, ovrdCtrls: any) {
    if (!event && property.value.label === this.eligibleForAnnualIncrease) {
      ovrdCtrls?.forEach((relatedField: any, index: number) => {
        if (relatedField?.controls?.label?.value?.toLowerCase() === this.refundOfAnnualIncrease.toLowerCase()) {
          if (this.isChicagoParks && this.data.calculationType === CalculationType.RetirementBenefit) {
            ovrdCtrls?.[index]?.controls?.value?.addValidators(Validators.required);
          }
          ovrdCtrls?.[index]?.controls?.value?.markAsTouched();
          ovrdCtrls?.[index]?.controls?.value?.updateValueAndValidity();
        }
      });
    }
    if (event && property.value.label === this.eligibleForAnnualIncrease) {
      ovrdCtrls?.forEach((relatedField: any, index: number) => {
        if (relatedField?.controls?.label?.value?.toLowerCase() === this.refundOfAnnualIncrease.toLowerCase()) {
          ovrdCtrls?.[index]?.controls?.value?.clearValidators();
          ovrdCtrls?.[index]?.controls?.value?.updateValueAndValidity();
        }
      });
    }
  }

  handleDisableFields(event: any, property: any, ovrdCtrls: any) {
    if (property.value.label === this.exclusionEligible && event) {
      ovrdCtrls?.forEach((relatedField: any, index: number) => {
        if (relatedField?.controls?.label?.value === this.investmentInContractField) {
          ovrdCtrls?.[index]?.controls?.value?.enable();
          ovrdCtrls?.[index]?.controls?.value?.updateValueAndValidity();
          this.handleSetCustomError('');
        }
      });
    }
    if (property.value.label === this.exclusionEligible && !event) {
      ovrdCtrls?.forEach((relatedField: any, index: number) => {
        if (relatedField?.controls?.label?.value === this.investmentInContractField) {
          ovrdCtrls?.[index]?.controls?.value?.setValue();
          ovrdCtrls?.[index]?.controls?.value?.disable();
          ovrdCtrls?.[index]?.controls?.value?.updateValueAndValidity();
          this.handleSetCustomError(this.customErrorMessage);
        }
      });
    }

    // Implement us 153949: Enhance logic validate for calculation parameter in Benefit Calculation
    if (this.isChicagoParks && this.data.calculationType === CalculationType.RetirementBenefit && property.value.label === this.survivingSpouseEligibleForAnnuity && event) {
      ovrdCtrls?.forEach((relatedField: any, index: number) => {
        if (relatedField?.controls?.label?.value === this.survivorRefundAmountField) {
          ovrdCtrls?.[index]?.controls?.value?.disable();
          ovrdCtrls?.[index]?.controls?.value?.setValue();
          ovrdCtrls?.[index]?.controls?.value?.updateValueAndValidity();
          this.handleSetCustomError('');
        }
        if (relatedField?.controls?.label?.value === this.spouseAnnuityField) {
          ovrdCtrls?.[index]?.controls?.value?.enable();
          ovrdCtrls?.[index]?.controls?.value?.updateValueAndValidity();
          this.handleSetCustomError('');
        }
      });
    }
    if (this.isChicagoParks && this.data.calculationType === CalculationType.RetirementBenefit && property.value.label === this.survivingSpouseEligibleForAnnuity && !event) {
      ovrdCtrls?.forEach((relatedField: any, index: number) => {
        if (relatedField?.controls?.label?.value === this.spouseAnnuityField) {
          ovrdCtrls?.[index]?.controls?.value?.disable();
          ovrdCtrls?.[index]?.controls?.value?.setValue();
          ovrdCtrls?.[index]?.controls?.value?.updateValueAndValidity();
          this.handleSetCustomError('');
        }

        if (relatedField?.controls?.label?.value === this.survivorRefundAmountField) {
          ovrdCtrls?.[index]?.controls?.value?.enable();
          ovrdCtrls?.[index]?.controls?.value?.updateValueAndValidity();
          this.handleSetCustomError('');
        }
      });
    }
  }

  validatorInvestmentInContract(field: any, excludsionEligibleField: any): string | null {
    if (field.label !== this.investmentInContractField || this.initFieldsValue(excludsionEligibleField)) {
      return null;
    }

    return this.customErrorMessage;
  }

  handleSetCustomError(erroMessage: string) {
    const data = this.parametersOverriddenConfigs?.map((item) => {
      if (item.label === this.investmentInContractField) {
        return { ...item, customError: erroMessage };
      }
      return { ...item };
    });
    this.parametersOverriddenConfigs = data;
  }

  customValidation(field: any): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const properties: SetBenefitDetailProperties[] = this.formData.getRawValue().overriddenParameters;

      const data = this.validationExceptionInfo?.filter(
        (item) =>
          item.benefitId === this.data.benefitEntityId &&
          item.additionalInfos.length > 0 &&
          item.additionalInfos[0].value === field.calculationParamMappingId,
      );
      if (this.isChicagoParks && field.label === 'Earned Service' && field.type === InputType.Decimal) {
        const fieldDatas = this.formData
          .getRawValue()
          .overriddenParameters.find((item: any) => item.label === 'Earned Service');
        if (fieldDatas?.value === 'NaN') {
          return { customError: 'Earned Service is required.' };
        }
      }
      return validationData(data, properties);
    };
  }

  private getValidationExceptionConfigurationData(): void {
    this.calculationStore.dispatch(clearGetValidationExceptionConfigurationStateAction());
    const request: GetValidationExceptionRequest = {
      memberId: this.data.memberId,
      exceptionType: ExceptionType.DateValueValidation,
      calculationBenefitId: this.data?.calculationBenefitId === '' ? undefined : this.data?.calculationBenefitId,
    };
    this.calculationStore.dispatch(getValidationExceptionConfigurationAction({ request }));
  }

  private selectValidationExceptionState(): void {
    this.calculationStore
      .pipe(
        select(getValidationExceptionConfigurationSelector),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetValidationExceptionConfigurationStateAction());
        if (response?.payload) {
          this.validationExceptionInfo = response?.payload?.benefitDetails;
        }
      });
  }

  checkEnableJointSurvivorFields(label: string) {
    if (!label) return true;
    const rawLabel = label
      .trim()
      .replace(/[^A-Za-z0-9]/g, '')
      .toLowerCase();
    return this.enableFieldsInJointSurvivorCase.includes(rawLabel);
  }
}
