import { createAction, props } from '@ngrx/store';

import {
  UpsertEntityDataRequest,
  DetailsEntityData,
  SearchResult,
  GetEntityMetaDataResponse,
  CheckIsRecordExistedRequest,
  CheckIsRecordExistedResponse,
} from '../../services/models';

export const createEntityDataAction = createAction(
  '[CreateEntityData/API] Send Request',
  props<{ recordId: string, cardName: string, request: UpsertEntityDataRequest }>()
);

export const createEntityDataSuccessAction = createAction(
  '[CreateEntityData/API] Success',
  props<{ recordId: string, cardName: string }>()
);

export const createEntityDataFailureAction = createAction(
  '[CreateEntityData/API] Failure',
  props<{ error?: any }>()
);

export const clearCreateEntityDataStateAction = createAction(
  '[CreateEntityData] Clear'
);

export const searchEntityDatasAction = createAction(
  '[searchEntityDatas/API] Send Request',
  props<{
    searchValue: string;
    entityId: string;
    propertyId: string;
    exceptRecordIds?: string[];
  }>()
);
export const searchEntityDatasSuccessAction = createAction(
  '[searchEntityDatas/API] Success',
  props<{ propertyId: string, searchResult: SearchResult }>()
);
export const searchEntityDatasFailureAction = createAction(
  '[searchEntityDatas/API] Failure',
  props<{ error?: any }>()
);

export const clearSearchEntityDatasStateAction = createAction(
  '[searchEntityDatas/API] Clear'
);

export const getDetailsEntityDatasAction = createAction(
  '[getDetailsEntityDatas/API] Send Request',
  props<{
    entityId: string,
    entityComponentId: string,
    entityPropertyId: string,
    id: string,
    fields: string[]
  }>()
);
export const getDetailsEntityDatasSuccessAction = createAction(
  '[getDetailsEntityDatas/API] Success',
  props<{ detailsEntityData: DetailsEntityData }>()
);
export const getDetailsEntityDatasFailureAction = createAction(
  '[getDetailsEntityDatas/API] Failure',
  props<{ error?: any }>()
);

export const clearGetDetailsEntityDatasStateAction = createAction(
  '[getDetailsEntityDatas/API] Clear'
);

export const upsertComponentListDataAction = createAction(
  '[UpsertComponentListData/API] Send Request',
  props<{ request: UpsertEntityDataRequest }>()
);
export const upsertComponentListDataSuccessAction = createAction(
  '[UpsertComponentListData/API] Success',
  props<{ memberId: string }>()
);
export const upsertComponentListDataFailureAction = createAction(
  '[UpsertComponentListData/API] Failure',
  props<{ error?: any }>()
);
export const clearUpsertComponentListDataStateAction = createAction(
  '[UpsertComponentListData] Clear'
);

export const getEntityMetadataAction = createAction(
  '[GetEntityMetadata/API] Send Request',
);

export const getEntityMetadataSuccessAction = createAction(
  '[GetEntityMetadata/API] Success',
  props<{ entityMetadata: GetEntityMetaDataResponse }>()
);

export const getEntityMetadataFailureAction = createAction(
  '[GetEntityMetadata/API] Failure',
  props<{ error?: any }>()
);

// Check if Selected Related Entity/Beneficiary (Record) existed in list 
export const checkIfRecordExistedAction = createAction(
  '[CheckIfRecordExisted/API] Send Request',
  props<{request: CheckIsRecordExistedRequest}>()
);

export const checkIfRecordExistedSuccess = createAction(
  '[CheckIfRecordExisted/API] Success',
  props<{ response: CheckIsRecordExistedResponse }>()
);

export const checkIfRecordExistedFailure = createAction(
  '[CheckIfRecordExisted/API] Failure',
  props<{ error?: any }>()
);

export const clearCheckIfRecordExistedStateAction = createAction(
  '[CheckIfRecordExisted/API] Clear'
);

