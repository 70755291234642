import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { IS_SHOW_SMS_KEY, ItemMenuType, NAVIGATION_CONST, SidebarOpenedStatus } from '@ptg-shared/layout/constance/layout.const';
import { MenuGroup, MenuItem } from '@ptg-shared/layout/models/layout.model';
import { ActiveFundService } from 'src/app/fund-list/services/active-fund.service';
import { MemberDetailService } from 'src/app/member/services/member-detail.service';
import { Auth0Service } from 'src/app/shared/auth/services/auth0.service';
import { CheckPermissionService } from 'src/app/shared/services/check-permission.service';
import { HistoryService } from 'src/app/shared/services/history.service';
import { deepClone } from 'src/app/shared/utils/common.util';
import { MatDialog } from '@angular/material/dialog';
import { ReleaseVersionComponent } from '../release-version/release-version.component';
import { ADMIN_FUND } from '@ptg-shared/constance';
import { ReleaseVersionService } from '@ptg-shared/layout/services/release-version.service';
import { ReleaseVersionResponsive } from '@ptg-shared/layout/models/menu.model';
import { BaseComponent } from '@ptg-shared/components';
import { takeUntil } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import * as fromReducer from '@ptg-reducers/index';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { MEMBER_COMMUNICATION_OVERVIEW_MENU_GROUP } from '@ptg-shared/layout/constance/menu.const';
@Component({
  selector: 'ptg-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends BaseComponent implements OnChanges  {
  readonly SIDE_BAR_OPENED_STATUS_ENUM = SidebarOpenedStatus;
  readonly NAVIGATION_CONST = NAVIGATION_CONST;
  @Input() menuGroups: MenuGroup[] = [];
  @Input() isDisabled: boolean = false;

  @Input() sidebarOpenedStatus: SidebarOpenedStatus = SidebarOpenedStatus.Opened;
  @Output() sidebarOpenedStatusChange: EventEmitter<SidebarOpenedStatus> = new EventEmitter<SidebarOpenedStatus>();

  currentMenuGroups: MenuGroup[] = [];
  currentMenuGroupIndex: number = 0;
  isIncludeOptIn: boolean = false;
  dataVersion: ReleaseVersionResponsive = {} as ReleaseVersionResponsive;
  ADMIN_FUND = ADMIN_FUND;
  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    public router: Router,
    public authService: Auth0Service,
    private activeFundService: ActiveFundService,
    public checkPermissionService: CheckPermissionService,
    private historyService: HistoryService,
    public memberDetailService: MemberDetailService,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    private releaseVersionService: ReleaseVersionService,
    private store: Store<fromReducer.State>
  ) {
    super();
  }

  ngOnInit(): void {
    this.getReleaseVersion();

    this.store
    .pipe(
      select(fromLayoutReducer.selectProfileNavigationState),
      takeUntil(this.unsubscribe$)
    )
    .subscribe((state) => {
      if (Object.keys(state.memberNavigationList)?.includes(IS_SHOW_SMS_KEY)) {
        this.isIncludeOptIn = !!state.memberNavigationList?.isShowSms;
        this.getCurrentMenuState();
      }
    })
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menuGroups) {
      this.getCurrentMenuState();
    }
    if (changes.sidebarOpenedStatus && this.sidebarOpenedStatus === SidebarOpenedStatus.Closed) {
      this.getCurrentMenuState();
    }
  }

  getCurrentMenuState() {
    this.currentMenuGroups = deepClone(this.menuGroups).filter(
      menuGroup => this.isIncludeOptIn || menuGroup.name !== MEMBER_COMMUNICATION_OVERVIEW_MENU_GROUP.name
    );
    this.currentMenuGroups.forEach(menuGroup => {
      if (menuGroup.isSVG) {
        this.iconRegistry.addSvgIconLiteral(
          menuGroup.name,
          this.sanitizer.bypassSecurityTrustHtml(menuGroup.icon)
        );
      }
    });
    this.currentMenuGroupIndex = this.currentMenuGroups.findIndex(menuGroup => menuGroup.isSelected) > -1 ? this.currentMenuGroups.findIndex(menuGroup => menuGroup.isSelected) : 0;
  }

  setSidebarOpenedStatus(sidebarOpenedStatus: SidebarOpenedStatus) {
    this.sidebarOpenedStatusChange.emit(sidebarOpenedStatus);
  }

  selectMenuGroup(index: number) {
    const menuGroup = this.currentMenuGroups[index];

    if (!menuGroup?.menu?.length) {
      this.getCurrentMenuState();
      this.setSidebarOpenedStatus(SidebarOpenedStatus.Closed);
      if (menuGroup.routerLink) {
        this.router.navigateByUrl(menuGroup.routerLink);
      }
      return;
    }
    this.currentMenuGroupIndex = index;
    const isChangeMenuGroup = this.currentMenuGroups.findIndex(item => item.isSelected) !== index;
    if (isChangeMenuGroup) {
      this.currentMenuGroups = this.currentMenuGroups.map((item, itemIndex) => {
        item.isSelected = itemIndex === index;
        return item;
      });
      if (this.sidebarOpenedStatus === SidebarOpenedStatus.Closed) {
        this.setSidebarOpenedStatus(SidebarOpenedStatus.Opened);
      }
      return;
    }
    if (this.sidebarOpenedStatus === SidebarOpenedStatus.Opened) {
      this.setSidebarOpenedStatus(SidebarOpenedStatus.Closed);
      this.getCurrentMenuState();
    } else {
      this.setSidebarOpenedStatus(SidebarOpenedStatus.Opened);
    }
  }

  onClickMenuItem(menuItem: MenuItem) {
    if (menuItem?.type === ItemMenuType.NextPayment || menuItem?.type === ItemMenuType.PaymentHistory) {
      this.historyService.isBackNextPayroll = false;
      sessionStorage.setItem('isBackNextPayroll', this.historyService.isBackNextPayroll.toString())
    }
  }

  getReleaseVersion() {
    this.releaseVersionService.releaseVersion$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe( res => {
      this.dataVersion = res;
    });
  }

  onViewVersionDetail() {
    this.dialog.open(ReleaseVersionComponent, {
      panelClass: ['dialog-full-screen', 'dialog-default'],
      autoFocus: false,
      disableClose: true,
      data: {
        ...this.dataVersion,
      },
    });
  }

  checkSelectedMenuLinkExpand(routerLinkExpand: string[], routerLinkExcept?: string[], routerLinkRegex?: string[]) {
    let check = false;
    routerLinkExpand.forEach((routerLink) => {
      if (this.router.url.includes(routerLink)) {
        check = true;
      }
    });

    routerLinkExcept?.forEach((routerLink) => {
      if (this.router.url.includes(routerLink)) {
        check = false;
      }
    })

    routerLinkRegex?.forEach((routerLink) => {
      if (new RegExp(routerLink).test(this.router.url)) {
        check = true;
      }
    });

    return check;
  }
}
