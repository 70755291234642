import { Component, Inject, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

import { BaseComponent } from '@ptg-shared/components';
import { Option, SelectComponent } from '@ptg-shared/controls/select/select.component';
import { showCancelDialog, showConfirmDialog } from '@ptg-shared/utils/common.util';

import { Store, select } from '@ngrx/store';
import { getDepositAccountsAction } from '@ptg-member/store/actions/deposit-account.action';
import { getDepositAccountsState } from '@ptg-member/store/selectors';
import { DepositAccount, GetDepositAccountsRequest } from '@ptg-member/types/models/deposit-account.model';
import { PaymentType } from '@ptg-processing/features/payroll-calendar-container/types/enums';
import { filter, first, takeUntil } from 'rxjs/operators';
import { MaskCharacterPipe } from '../../pipes/mask-character.pipe';
import {
  CreateRejectedTransactionsRequest,
  GetVoidedReasonsRequest,
  TransactionRegister
} from '../../services/models/register.model';
import {
  clearCreateRejectedTransactionsStateAction,
  createRejectedTransactionsAction,
  getVoidedReasonsAction
} from '../../store/actions/register.action';
import { RegisterState } from '../../store/reducers/transaction-register.reducer';
import {
  createRejectedTransactionsSelector,
  getVoidedReasonsSelector,
  registerSelector
} from '../../store/selectors/register.selector';
import { DatePipe } from '@angular/common';
import { MY_DATE } from '@ptg-shared/controls/datepicker/datepicker.component';
import { DateTime } from 'luxon';

const OtherValue = 250;
const maskCharacter = new MaskCharacterPipe();

@Component({
  selector: 'ptg-view-mark-returned-rejected',
  templateUrl: './view-mark-returned-rejected.component.html',
  styleUrls: ['./view-mark-returned-rejected.component.scss'],
})
export class ViewMarkReturnedRejectedComponent extends BaseComponent {
  readonly PaymentType = PaymentType;
  @ViewChild('selectionElement') selectionElement!: SelectComponent;
  dialogTitle = '';
  accountNo = '';
  listItem: Option[] = [];
  listChip: Option[] = [];
  listReplaceBy: Option[] = [
    { value: PaymentType.Check, displayValue: 'Check' },
    { value: PaymentType['Direct Deposit'], displayValue: 'Direct Deposit' },
  ];
  listAccount: Option[] = [];
  paymentType!: PaymentType.Check;
  hasOther: boolean = false;

  editForm!: FormGroup;

  get otherCtrl(): FormControl {
    return this.editForm?.get('other') as FormControl;
  }

  get otherReasonCtrl(): FormControl {
    return this.editForm?.get('otherReason') as FormControl;
  }

  get suspendPayeeCtrl(): FormControl {
    return this.editForm?.get('suspendPayee') as FormControl;
  }

  get disableDepositAccountCtrl(): FormControl {
    return this.editForm?.get('disableDepositAccount') as FormControl;
  }

  get replaceByCtrl(): FormControl {
    return this.editForm?.get('replaceBy') as FormControl;
  }

  get accountCtrl(): FormControl {
    return this.editForm?.get('account') as FormControl;
  }

  get isDisabledSelection() {
    return this.listItem.every((el) => el.isHide);
  }

  get isDisabledAddButton() {
    return this.listItem.every((el) => el.isHide) || !this.otherCtrl?.value;
  }

  get returnRejectDateCtrl() {
    return this.editForm?.get('returnDate') as FormControl;
  }

  checkDisabledSaveButton: boolean = false;
  newCheckNumber: any = '';
  minDate = MY_DATE.validMinDate;
  maxDate = new Date();
  isHideSuspendToggle = false;

  constructor(
    private readonly dialog: MatDialog,
    private readonly dialogRef: MatDialogRef<ViewMarkReturnedRejectedComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { 
      registerData: TransactionRegister,
      payeeRecordId?: string,
      maxLengthCheckNumber?: number,
      isConfigOST?: boolean,
      isHideSuspendToggle: boolean,
    },
    private store: Store<RegisterState>
  ) {
    super();
    this.dialogTitle = 'Mark Returned/Rejected ' + (PaymentType[data.registerData.paymentMethod!] || '');
    this.isHideSuspendToggle = this.data?.isHideSuspendToggle;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.oninitForm();

    this.getVoidedReasonAction();

    this.getVoidedReasonSelector();
    this.handleCreateRejectedTransactionsSelector();
    this.handleGetDepositAccountsState();
    this.suspendPayeeCtrl?.valueChanges.subscribe((el) => {
      if (!this.suspendPayeeCtrl?.value && this.disableDepositAccountCtrl?.value) {
        this.replaceByCtrl.addValidators(Validators.required);
      } else {
        this.replaceByCtrl.removeValidators(Validators.required);
      }
      this.replaceByCtrl.updateValueAndValidity();

      this.otherReasonCtrl?.updateValueAndValidity();
    });

    this.disableDepositAccountCtrl?.valueChanges.subscribe((el) => {
      if (!this.suspendPayeeCtrl?.value && this.disableDepositAccountCtrl?.value) {
        this.replaceByCtrl.addValidators(Validators.required);
      } else {
        this.replaceByCtrl.removeValidators(Validators.required);
      }
      this.replaceByCtrl.updateValueAndValidity();

      this.otherReasonCtrl?.updateValueAndValidity();
    });

    this.listItem = this.listItem?.filter((el) => !el.isHide);

    //#region #158896: Do not allow to enter date on or before <saved Latest Closing Date>
    this.store.select(registerSelector).pipe(first()).subscribe(el => {
      if (el.summaryList.latestClosingDate) {
        this.minDate = DateTime.fromISO(el.summaryList.latestClosingDate).plus({days: 1}).toJSDate();
      }
    });
    //#endregion
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.store.dispatch(clearCreateRejectedTransactionsStateAction());
  }

  getVoidedReasonAction() {
    const request: GetVoidedReasonsRequest = {};

    this.store.dispatch(getVoidedReasonsAction({ request, query: {
      paymentMethod: this.data.registerData.paymentMethod as number
    }}));
  }

  formatCheckNumberOST(value: any){
    if(value){
      const endingChar = this.data?.registerData?.endingChar?.trim();
      if (!endingChar) {
        return value;
      }
      let numericPart = value?.toString()?.trim().replace(endingChar, '');
      let paddedNumericPart = numericPart?.padStart(this.data.maxLengthCheckNumber, '0');
      return paddedNumericPart + endingChar;
    }
    return '';
  }

  getVoidedReasonSelector() {
    this.store.pipe(select(getVoidedReasonsSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.success && !state?.isLoading) {
        this.listItem = (state?.payload ?? [])?.map((el) => {
          return {
            value: el.reasonOptionId,
            displayValue: el.reasonOptionDescription
          };
        });
        this.listItem.push({ value: OtherValue, displayValue: 'Other', isHide: false });
      }
    });
  }

  oninitForm(): void {
    this.editForm = new FormGroup({
      other: new FormControl('', { validators: this._requiredChip() }),
      otherReason: new FormControl(),
      suspendPayee: new FormControl(false),
      disableDepositAccount: new FormControl(false),
      replaceBy: new FormControl(this.listReplaceBy[0].value),
      account: new FormControl(),
      returnDate: new FormControl(this.data?.registerData?.returnDate),
    });
    const bankAccount = this.data.registerData?.bankInformation?.accountNumber;
    this.accountNo = (bankAccount || '') + '-' + this.data.registerData?.bankInformation?.bankName;
  }

  onSubmit() {
    const datePipe = new DatePipe('en-US');
    this.editForm.markAllAsTouched();
    if (this.editForm.invalid) {
      return;
    }
    const registerData = this.data.registerData as TransactionRegister;
    if (!registerData) {
      return;
    }
    const actions: string[] = [];
    if (!registerData.isDeductionPayee) {
      if (this.shouldShowDisableDepositAccount() && this.disableDepositAccountCtrl.value) {
        if (this.suspendPayeeCtrl.value) {
          actions.push(', disable the current deposit account and suspend the payee');
        } else {
          if (this.replaceByCtrl.value === PaymentType['Check']) {
            actions.push(', disable the current deposit account and use check instead');
          } else {
            actions.push(', disable the current deposit account and use another deposit account instead');
          }
        }
      } else if (this.suspendPayeeCtrl.value) {
        actions.push('and suspend the payee');
      }
    }
    if (actions.length) {
      actions.unshift(' ');
    }
    const paymentType = PaymentType[registerData.paymentMethod!];
    showConfirmDialog(this.dialog, `Are you sure you want to mark this ${paymentType} as returned/rejected${actions.join(' ')}? This action cannot be undone.`)
    .subscribe(result => {
      if (result) {
        const voidedReason = this.listChip.map(el => el.value).filter(value => value !== OtherValue);
        if (this.hasOther && this.otherReasonCtrl?.value) {
          voidedReason.push(this.otherReasonCtrl.value);
        }
        const replacePayment: PaymentType | null = (this.suspendPayeeCtrl?.value || !this.disableDepositAccountCtrl?.value) ? null : this.replaceByCtrl?.value;
        const selectedAccount = replacePayment === PaymentType['Direct Deposit'] ? (this.accountCtrl.value as DepositAccount) : undefined;
        const request: CreateRejectedTransactionsRequest = {
          replacePayment,
          transactionId: registerData.transactionId!,
          payeeRecordId: registerData.payeeRecordId,
          bankAccId: registerData.bankInformation?.bankAccId,
          bankId: registerData.bankInformation?.bankId,
          beginningOn: registerData.startDate!,
          endingOn: registerData.endDate!,
          paymentInstructionType: registerData.paymentInstructionType,

          voidedReason: voidedReason,
          suspendPayee: this.suspendPayeeCtrl?.value,
          disableDepositAccount: this.disableDepositAccountCtrl?.value,
          paymentType: registerData.paymentMethod!,
          depositId: selectedAccount?.id ?? null,
          currentDepositId: this.data?.registerData?.depositId,
          returnDate: datePipe.transform(this.returnRejectDateCtrl?.value, 'yyyy-MM-dd')
        }

        this.store.dispatch(createRejectedTransactionsAction ({
            request,
          })
        );
      }
    });
  }

  onCancel() {
    showCancelDialog(this.dialog, this.dialogRef);
  }

  private _requiredChip() {
    return (c: AbstractControl) => {
      return this.listChip?.length ? null : { requiredChip: `Please select at least one value.` };
    };
  }

  compareWithFunction(o1: any, o2: any[]) {
    return o2.includes(o1);
  }

  addOther() {
    const item = this.listItem.find((item) => item.value === this.otherCtrl.value) as Option;
    item.isHide = true;
    this.listChip.push(item);
    this.otherCtrl.setValue([]);

    this.hasOther = this.listChip.some((x) => x.value === OtherValue);
    if (this.hasOther) {
      this.otherReasonCtrl.addValidators(Validators.required);
    }
    this.otherReasonCtrl?.updateValueAndValidity();
  }

  removeChip(index: number) {
    const item = this.listChip.splice(index, 1)[0];
    item.isHide = false;

    if (this.listChip.length === 0) {
      this.otherCtrl?.markAsTouched();
      this.otherCtrl?.setErrors({
        requiredChip: `Please select at least one value.`,
      });
    }

    this.hasOther = this.listChip.some((x) => x.value === OtherValue);
    if (!this.hasOther) {
      this.otherReasonCtrl.removeValidators(Validators.required);
      this.otherReasonCtrl.reset();
    }
    this.otherReasonCtrl?.updateValueAndValidity();
  }

  onChangeReplaceBy() {
    if (this.replaceByCtrl.value === PaymentType['Direct Deposit']) {
      this.accountCtrl.addValidators(Validators.required);
    } else {
      this.accountCtrl.removeValidators(Validators.required);
      this.accountCtrl.reset();
    }
    this.accountCtrl?.updateValueAndValidity();
  }

  shouldShowDisableDepositAccount() {
    const { registerData } = this.data as { registerData: TransactionRegister };

    return registerData.paymentMethod === PaymentType['Direct Deposit'] && !registerData.isDeductionPayee;
  }

  handleCreateRejectedTransactionsSelector() {
    this.store
      .select(createRejectedTransactionsSelector)
      .pipe(
        filter((r) => !!r && !r.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (response?.success) {
          this.dialogRef.close({ success: true });
        }
        if (response?.error) {
          this.dialogRef.close({ error: true });
        }
      });
  }

  handleGetDepositAccountsState() {
    if (!this.data.registerData.payeeRecordId) {
      return;
    }
    const request: GetDepositAccountsRequest = {
      targetId: this.data.registerData.payeeRecordId,
    };

    this.store.dispatch(getDepositAccountsAction({ request }));
    this.store
      .select(getDepositAccountsState)
      .pipe(
        filter((r) => !!r && !r.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (state?.success) {
          const accountNumber = this.data.registerData?.bankInformation?.accountNumber;
          this.listAccount = (state?.payload?.depositAccounts ?? [])
            .filter((el) => {
              if (!el.isActive) {
                return false;
              }
              if (!accountNumber) {
                return true;
              }
              return el.accountNumber !== accountNumber;
            })
            .map((el) => {
              return {
                value: el,
                displayValue: (el.accountNumber || '') + '-' + el.bankName,
              } as Option;
            });
        }
      });
  }
}
