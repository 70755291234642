import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';

import { CheckVersionFlagService } from '@ptg-shared/services/check-version-flag.service';

import { ParticipantSearchConfigurationComponent } from 'src/app/member/containers/participant-search-configuration/participant-search-configuration.component';
import { ProfileHeaderConfigurationComponent } from 'src/app/member/containers/profile-header-configuration/profile-header-configuration.component';
import { ProfileOverviewConfigurationDetailComponent } from 'src/app/member/containers/profile-overview-configuration-detail/profile-overview-configuration-detail.component';
import { ProfileOverviewConfigurationComponent } from 'src/app/member/containers/profile-overview-configuration/profile-overview-configuration.component';
import { CheckFundService } from '../shared/services/check-fund.service';
import { CheckPermissionService } from '../shared/services/check-permission.service';
import { CheckRoleService } from '../shared/services/check-role.service';
import { OptInComponent } from './components/opt-in/opt-in.component';
import { GeneratedReportComponent } from './components/participant-report/generated-report/generated-report.component';
import { ManageReportComponent } from './components/participant-report/manage-report/manage-report.component';
import { ReportCategoriesComponent } from './components/participant-report/report-categories/report-categories.component';
import { ReportTemplateComponent } from './components/participant-report/report-view/report-view.component';
import { BulkContentComponent } from './containers/bulk-content/bulk-content.component';
import { DefinedBenefitsComponent } from './containers/defined-benefits/defined-benefits.component';
import { LookupTableDetailComponent } from './containers/lookup-table-detail/lookup-table-detail.component';
import { LookupTableComponent } from './containers/lookup-table/lookup-table.component';
import { MembersBenefitEligibleComponent } from './containers/members-benefit-eligible/members-benefit-eligible.component';
import { MembersListComponent } from './containers/members-list/members-list.component';
import { ProfileHeaderConfigurationListComponent } from './containers/profile-header-configuration-list/profile-header-configuration-list.component';
import { ProfileNavigationConfigurationDetailComponent } from './containers/profile-navigation-configuration-detail/profile-navigation-configuration-detail.component';
import { ProfileNavigationConfigurationComponent } from './containers/profile-navigation-configuration/profile-navigation-configuration.component';
import { RetirementBenefitDetailComponent } from './features/calculation/pages/retirement-benefit-detail/retirement-benefit-detail.component';
import { StatusDetailComponent } from './containers/status-detail/status-detail.component';
import { StatusComponent } from './containers/status/status.component';
import { TierConfigurationComponent } from './containers/tier-configuration/tier-configuration.component';
import { ParticipantNavigationConfigurationComponent } from './containers/participant-navigation-configuration/participant-navigation-configuration.component';
import { CardListComponent } from './containers/card-list/card-list.component';
import { ParticipantNavigationConfigurationDetailComponent } from './containers/participant-navigation-configuration-detail/participant-navigation-configuration-detail.component';
import { ViewListComponent } from './containers/view-list/view-list.component';
import { HeaderListComponent } from './containers/header-list/header-list.component';
import { EditViewComponent } from './containers/view-detail/view-detail.component';
import { HeaderDetailComponent } from './containers/header-detail/header-detail.component';
import { ProfileListComponent } from './containers/profile-configuration/profile-configuration.component';
import { ProfileConfigDetailComponent } from './containers/profile-detail/profile-detail.component';
import { EditCardComponent } from './containers/card-detail/card-detail.component';
import { MemberSummaryViewComponent } from './containers/member-summary-view/member-summary-view.component';
import { DatalessViewComponent } from './containers/view-dataless/view-dataless.component';
import { BenefitOverviewComponent } from './features/calculation/pages/benefit-overview/benefit-overview.component';
import { StatusHistoryComponent } from './containers/status-history/status-history.component';
import { ParticipantRelationshipComponent } from './containers/participant-relationship/participant-relationship.component';
import { MemberNavigationCardComponent } from './containers/member-navigation-card/member-navigation-card.component';
import { DropPlanComponent } from './containers/drop-plan/drop-plan.component';
import { DisabilityBenefitDetailComponent } from './features/calculation/pages/disability-benefit-detail/disability-benefit-detail.component';
import { BuyBackMakeUpComponent } from './features/purchase-services/pages/buyback-makeup/buyback-makeup.component';
import { MunicipalityServiceHistoryComponent } from './containers/municipality-service-history/municipality-service-history.component';
import { MunicipalityPaymentComponent } from './containers/municipality-payment-history/municipality-payment.component';
import { RefundsOverviewComponent } from './features/calculation/pages/refunds-overview/refunds-overview.component';
import { RefundsDetailComponent } from './features/calculation/pages/refunds-detail/refunds-detail.component';
import { RefundsCalculationDetailComponent } from './features/calculation/pages/refunds-calculation-detail/refunds-calculation-detail.component';
import { DistributionCodeMappingComponent } from './containers/distribution-code-mapping/distribution-code-mapping.component';
import { DisabilityCalculationDetailComponent } from './features/calculation/pages/disability-calculation-detail/disability-calculation-detail.component';
import { IndividualDocumentListComponent } from '../admin/features/file/pages/individual-document-list/individual-document-list.component';
import { MemberDocumentListComponent } from './containers/member-document-list/member-document-list.component';
import { MemberIndividualDocumentComponent } from './components/member-individual-document/member-individual-document.component';
import { AddressHistoryComponent } from './containers/address-history/address-history.component';
import { GenerateAge65LetterComponent } from '@ptg-member/containers/generate-age65-letter/generate-age65-letter.component';
import { ParticipantGeneratedAge65LetterComponent } from '@ptg-member/containers/participant-generated-age65-letter/participant-generated-age65-letter.component';
import { ManageStatusHistoryComponent } from './containers/manage-status-history/manage-status-history.component';
import { EntityDepositAccountDetailComponent } from './containers/entity-deposit-account-detail/entity-deposit-account-detail.component';
import { InitialPaymentConfiguration } from './features/payee-detail/pages/initial-payment-configuration/initial-payment-configuration.component';
import { FinalPaymentConfiguration } from './features/payee-detail/pages/final-payment-configuration/final-payment-configuration.component';
import { PaymentHeaderConfigurationComponent } from './features/payee-detail/pages/payment-header-configuration/payment-header-configuration.component';
import { PaymentHeaderConfigurationDetailComponent } from './features/payee-detail/pages/payment-header-configuration-detail/payment-header-configuration-detail.component';
import { RegisterComponent } from '@ptg-shared/transaction/pages/register/register.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: MembersListComponent,
  },
  {
    path: 'benefit',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: MembersBenefitEligibleComponent,
  },

  {
    path: 'document-list',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: MemberDocumentListComponent,
  },
  {
    path: 'generate-age65-letter',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: GenerateAge65LetterComponent,
  },
  {
    path: 'generate-age65-letter/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: ParticipantGeneratedAge65LetterComponent,
  },
  {
    path: 'summary-view/:isOverview/:menuId/:viewId/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: MemberSummaryViewComponent,
  },
  {
    path: 'system-view/status-history/:menuId/:viewId/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: StatusHistoryComponent,
  },
  {
    path: 'system-view/address-history/:menuId/:viewId/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: AddressHistoryComponent,
  },
  {
    path: 'system-view/participant-relationships/:menuId/:viewId/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: ParticipantRelationshipComponent,
  },
  {
    path: 'system-view/service-history/:menuId/:viewId/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: MunicipalityServiceHistoryComponent,
  },
  {
    path: 'system-view/deposit-account/:menuId/:viewId/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: EntityDepositAccountDetailComponent,
  },
  {
    path: 'system-view/deposit-account/:viewId/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: EntityDepositAccountDetailComponent,
  },
  {
    path: 'system-view/payments/:menuId/:viewId/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: MunicipalityPaymentComponent,
  },
  {
    path: 'detail-view/:isOverview/:menuId/:viewId/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: MemberNavigationCardComponent,
  },
  {
    path: 'detail-view/:isOverview/:viewId/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: MemberNavigationCardComponent,
  },
  {
    path: 'bulk/:key',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: BulkContentComponent,
  },
  {
    path: 'detail',
    redirectTo: '/member',
    pathMatch: 'full',
  },
  {
    path: 'retiree/next-payment',
    redirectTo: '/member',
    pathMatch: 'full',
  },
  {
    path: 'lookup-table',
    component: LookupTableComponent,
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
  },
  {
    path: 'lookup-table/:id',
    component: LookupTableDetailComponent,
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
  },
  {
    path: 'status',
    component: StatusComponent,
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
  },
  {
    path: 'status/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: StatusDetailComponent,
  },
  {
    path: 'profile-overview-configuration/:memberId/detail/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ProfileOverviewConfigurationDetailComponent,
  },
  {
    path: 'profile-overview-configuration/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ProfileOverviewConfigurationComponent,
  },
  {
    path: 'profile-header-configuration/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ProfileHeaderConfigurationListComponent,
  },
  {
    path: 'profile-header-configuration/:memberId/detail/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ProfileHeaderConfigurationComponent,
  },
  {
    path: 'profile-navigation-configuration/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ProfileNavigationConfigurationComponent,
  },
  {
    path: 'profile-navigation-configuration/:memberId/detail/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ProfileNavigationConfigurationDetailComponent,
  },
  {
    path: 'participant-search-configuration/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ParticipantSearchConfigurationComponent,
  },
  {
    path: 'opt-in/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: OptInComponent,
  },
  {
    path: 'benefit/defined-benefits',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: DefinedBenefitsComponent,
  },
  {
    path: 'member-list-tier',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: TierConfigurationComponent,
  },
  {
    path: 'drop-plan',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: DropPlanComponent,
  },
  {
    path: 'view',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ViewListComponent,
  },
  {
    path: 'view/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: EditViewComponent,
  },
  {
    path: 'view/:id/dataless-display',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: DatalessViewComponent,
  },
  {
    path: 'header',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: HeaderListComponent,
  },
  {
    path: 'header/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: HeaderDetailComponent,
  },
  {
    path: 'profile-configuration',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ProfileListComponent,
  },
  {
    path: 'profiles/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ProfileConfigDetailComponent,
  },
  {
    path: 'report-view',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ReportTemplateComponent,
  },
  {
    path: 'report',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: ManageReportComponent,
  },
  {
    path: 'report/generated',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: GeneratedReportComponent,
  },
  {
    path: 'report/category',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ReportCategoriesComponent,
  },
  {
    path: 'report/:reportId',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: ReportTemplateComponent,
  },

  {
    path: 'individual-document/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: MemberIndividualDocumentComponent,
  },
  {
    path: 'benefit-overview/:calculationType/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: BenefitOverviewComponent,
  },
  {
    path: 'benefit-overview/:calculationType/:memberId/detail/:retirementBenefitId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: RetirementBenefitDetailComponent,
  },
  {
    path: 'calculation',
    canActivate: [AuthGuard],
    loadChildren: () => import('./features/calculation/calculation.module').then((value) => value.CalculationModule),
  },
  {
    path: 'accident',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/accident-claims/accident-participants.module').then(
        (value) => value.AccidentParticipantsModule,
      ),
  },
  {
    path: 'buyback-makeup-overview/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: BuyBackMakeUpComponent,
  },
  {
    path: 'refunds-overview/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: RefundsOverviewComponent,
  },
  {
    path: 'refunds-overview/:memberId/detail/:calculationBenefitId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: RefundsDetailComponent,
  },
  {
    path: 'refunds-overview/:memberId/calculation-detail/:calculationBenefitId/:calculationRecordId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: RefundsCalculationDetailComponent,
  },
  {
    path: 'participant-navigation-configuration',
    canActivate: [AuthGuard, CheckFundService],
    component: ParticipantNavigationConfigurationComponent,
  },
  {
    path: 'participant-navigation-configuration/:id',
    canActivate: [AuthGuard, CheckFundService],
    component: ParticipantNavigationConfigurationDetailComponent,
  },
  {
    path: 'card-list',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: CardListComponent,
  },
  {
    path: 'card/:id',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: EditCardComponent,
  },
  {
    path: 'disability-overview/:calculationType/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: BenefitOverviewComponent,
  },
  {
    path: 'disability-overview/:calculationType/:memberId/detail/:calculationBenefitId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: DisabilityBenefitDetailComponent,
  },
  {
    path: 'disability-overview/:calculationType/:memberId/calculation-detail/:calculationBenefitId/:calculationRecordId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: DisabilityCalculationDetailComponent,
  },
  {
    path: 'distribution-code-mapping/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckRoleService],
    component: DistributionCodeMappingComponent,
  },
  {
    path: 'manage-status-history/:isOverview/:menuId/:viewId/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: ManageStatusHistoryComponent,
  },
  {
    path: 'system-view/payment-information/:menuId/:viewId/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    loadChildren: () => import('./features/payee-detail/payee-detail.module').then((value) => value.PayeeDetailModule),
  },
  {
    path: 'system-view/transaction-register/:menuId/:viewId/:memberId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: RegisterComponent,
  },
  {
    path: 'system-view/initial-payment-configuration/:menuId/:viewId/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: InitialPaymentConfiguration,
  },
  {
    path: 'system-view/final-payment-configuration/:menuId/:viewId/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: FinalPaymentConfiguration,
  },
  {
    path: 'system-view/payment-header-configuration/:menuId/:viewId/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: PaymentHeaderConfigurationComponent,
  },
  {
    path: 'system-view/payment-header-configuration/:menuId/:viewId/:id/:headerId',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    component: PaymentHeaderConfigurationDetailComponent,
  },
  {
    path: 'system-view/1099-r-information/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    loadChildren: () => import('./features/information-1099-r/information-1099-r.module').then((value) => value.Information1099RModule),
  },
  {
    path: 'system-view/tax-exclusion/:id',
    canActivate: [AuthGuard, CheckFundService, CheckPermissionService],
    loadChildren: () => import('./features/tax-exclusion/tax-exclusion.module').then((value) => value.TaxExclusionModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MemberRoutingModule {}
