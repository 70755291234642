import { createAction, props } from '@ngrx/store';
import {
  CancelPaymentInstructionRequest,
  CheckDisplayOffsetButtonResponse,
  GetInstructionHistoryRequest,
  GetInstructionHistoryResponse,
  GetPaymentInfoTabsRequest,
  GetPaymentInfoTabsResponse,
  GetRecalculateTaxesRequest,
  GetRecalculateTaxesResponse,
  MarkOverpaidFinalizedPaymentRequest,
  MarkOverpaidFinalizedPaymentResponse,
} from '../../services/models';
import { generateActionName } from '@ptg-shared/utils/common.util';

export const getPaymentInfoTabsAction = createAction(
  '[GetPaymentInfoTabs/API] Send Request',
  props<{ request: GetPaymentInfoTabsRequest }>(),
);

export const getPaymentInfoTabsSuccessAction = createAction(
  '[GetPaymentInfoTabs/API] Success',
  props<{ response: GetPaymentInfoTabsResponse }>(),
);

export const getPaymentInfoTabsFailureAction = createAction(
  '[GetPaymentInfoTabs/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetPaymentInfoTabsStateAction = createAction('[GetPaymentInfoTabs] Clear');

export const getInstructionHistoryAction = createAction(
  '[GetInstructionHistory/API] Send Request',
  props<{ request: GetInstructionHistoryRequest }>(),
);

export const getInstructionHistorySuccessAction = createAction(
  '[GetInstructionHistory/API] Success',
  props<{ response: GetInstructionHistoryResponse }>(),
);

export const getInstructionHistoryFailureAction = createAction(
  '[GetInstructionHistory/API] Failure',
  props<{ error?: any }>(),
);

export const clearGetInstructionHistoryStateAction = createAction('[GetInstructionHistory] Clear');

export const getRecalculateTaxesAction = createAction(
  '[GetRecalculateTaxes/API] Send Request',
  props<{ request: GetRecalculateTaxesRequest }>()
);

export const getRecalculateTaxesSuccessAction = createAction(
  '[GetRecalculateTaxes/API] Success',
  props<{ response: GetRecalculateTaxesResponse }>()
);

export const getRecalculateTaxesFailureAction = createAction(
  '[GetRecalculateTaxes/API] Failure',
  props<{ error?: any }>()
);

export const clearGetRecalculateTaxesStateAction = createAction(
  '[GetRecalculateTaxes] Clear'
);

export const cancelPaymentInstructionAction = createAction(
  '[CancelPaymentInstruction/API] Send Request',
  props<{ request: CancelPaymentInstructionRequest }>()
);

export const cancelPaymentInstructionSuccessAction = createAction(
  '[CancelPaymentInstruction/API] Success',
);

export const cancelPaymentInstructionFailureAction = createAction(
  '[CancelPaymentInstruction/API] Failure',
  props<{ error?: any }>()
);

export const clearCancelPaymentInstructionStateAction = createAction(
  '[CancelPaymentInstruction] Clear'
);

export const checkDisplayOffsetButtonAction = createAction(
  generateActionName('CheckDisplayOffsetButton/API').Send,
  props<{ request: GetPaymentInfoTabsRequest }>(),
);
export const checkDisplayOffsetButtonSuccessAction = createAction(
  generateActionName('CheckDisplayOffsetButton/API').Success,
  props<{ response: CheckDisplayOffsetButtonResponse }>(),
);
export const checkDisplayOffsetButtonFailureAction = createAction(
  generateActionName('CheckDisplayOffsetButton/API').Failure,
  props<{ error?: any }>(),
);

//#region #156182 AC2 [Integrate] Mark/Remove a payment as Overpaid
export const markRemoveOverpaidAction = createAction(
  generateActionName('MarkRemoveOverpaid/API').Send,
  props<{ request: MarkOverpaidFinalizedPaymentRequest }>()
);
export const markRemoveOverpaidSuccess = createAction(
  generateActionName('MarkRemoveOverpaid/API').Success,
  props<{ response: MarkOverpaidFinalizedPaymentResponse }>(),
);
export const markRemoveOverpaidFailure = createAction(
  generateActionName('MarkRemoveOverpaid/API').Failure,
  props<{ error?: any }>(),
);
//#endregion
