import { CourtOrderType, EntityPropertyType } from '@ptg-entity-management/types/enums';
import { EntityInitiationPropertyValue } from './entity-property.model';

export interface UpsertEntityDataRequest {
	entityId: string;
	entityPropertyValues: EntityInitiationPropertyValue[];
}

export interface SearchResult {
	members: Record<string, any>[];
	columns: Column[];
	total: number;
}

export interface Column {
	columnName: string;
	columnKey: string;
	type: EntityPropertyType;
	order?: number;
	viewType?: number;
	configValue?: any;
	listSubField?: SubField[];
}

export interface SubField {
	key: string;
	option: string;
}

export interface DetailsEntityData {
	propertyId: string;
	values: EntityPropertyValues[];
	record: Record<string, any>;
	isMember?: boolean;
}

export interface EntityPropertyValues {
	recordId: string;
	entityId: string;
	entityComponentId: string;
	entityPropertyId: string;
	entityReferenceId: string;
	entityReferenceComponentId: string;
	entityReferencePropertyId: string;
	value: string;
	valueObj: any;
}

export interface CreateEntityListDataRequest {
	recordId?: string;
	entityId: string;
	entityPropertyValues: EntityInitiationPropertyValue[];
	targetId?: string;
	entityComponentId?: string;
	addToTop?: boolean;
}

export interface CheckDenpendentRecordInUseResponse {
	isInUse: boolean;
}

export interface CreateEntityListData {
	addToTop?: boolean,
	data: Record<string, any>,
}

export interface GetValidateCourtOrderRequest {
  memberId: string;
  caseNumber: string;
  courtOrderType: CourtOrderType;
}

export interface GetValidateCourtOrderResponse {
  isChangedStatus: boolean;
  courtOrderType: CourtOrderType;
}

export interface ValidateReportedDeceasedResponse {
  infos: ReportedDeceasedInformation[];
}

export interface ReportedDeceasedInformation {
  id: string;
  dateOfDeath?: string;
  reportedDeceased: boolean;
}
