import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CheckExistsDocumentNameRequest, CheckExistsDocumentNameResponse, DocumentBodyRequest, GetDocumentListsRequest, GetDocumentListsResponse, GetOrganizationDocumentListsResponse, RemoveOrganizationItem, ValidateBeforeRemoveDocumentRequest, ValidateBeforeRemoveDocumentResponce } from '@ptg-member/types/models/member-document.model';
import { SortType } from '@ptg-shared/constance';
import { isEmpty } from '@ptg-shared/utils/string.util';
import { Observable } from 'rxjs';
import { GetValidateAge65LetterRequest, GetValidateAge65LetterResponse } from 'src/app/admin/features/template/services/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberDocumentService {
  documentFile?: File[];
  constructor(private httpClient: HttpClient) { }

  getOrganizationDocumentLists(
    request: GetDocumentListsRequest
  ): Observable<GetOrganizationDocumentListsResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    if (request?.memberId) {
      params = params.append('OrganizationId', request.memberId);
    }
    if (request.filters?.documentName) {
      params = params.append(`DocumentName`, request.filters?.documentName);
    }

    if (request.filters?.fileName) {
      params = params.append(`FileName`, request.filters?.fileName);
    }

    if (request.filters?.uploadedFrom) {
      params = params.append(`UploadedFrom`, request.filters?.uploadedFrom);
    }

    if (request.filters?.uploadedTo) {
      params = params.append(`UploadedTo`, request.filters?.uploadedTo);
    }

    if (request.filters?.tagsDisplay) {
      request.filters?.tagsDisplay.forEach((item: any, idx: any) => {
        params = params.append(`DocumentTag[${idx}]`, item);
      });
    }

    if (request.filters?.types) {
      request.filters?.types.forEach((item: any, idx: any) => {
        params = params.append(`FileType[${idx}]`, item);
      });
    }

    if (request.filters?.showOnOverview !== null && request.filters?.showOnOverview !== undefined) {
      params = params.append(`IsShowOnOverView`, request.filters?.showOnOverview);
    }
    params = params.append('TimeZoneOffset', new Date().getTimezoneOffset());
    return this.httpClient.get<GetOrganizationDocumentListsResponse>(
      `${environment.apiUrl}/Organization/documents`,
      { params }
    );
  }

  getDocumentLists(
    request: GetDocumentListsRequest
  ): Observable<GetDocumentListsResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    if (request?.memberId) {
      params = params.append('MemberId', request.memberId);
    }
    if (request.filters?.documentName) {
      params = params.append(`DocumentName`, request.filters?.documentName);
    }

    if (request.filters?.fileName) {
      params = params.append(`FileName`, request.filters?.fileName);
    }

    if (request.filters?.uploadedFrom) {
      params = params.append(`UploadedFrom`, request.filters?.uploadedFrom);
    }

    if (request.filters?.uploadedTo) {
      params = params.append(`UploadedTo`, request.filters?.uploadedTo);
    }

    if (request.filters?.tagsDisplay) {
      request.filters?.tagsDisplay.forEach((item: any, idx: any) => {
        params = params.append(`DocumentTag[${idx}]`, item);
      });
    }

    if (request.filters?.types) {
      request.filters?.types.forEach((item: any, idx: any) => {
        params = params.append(`FileType[${idx}]`, item);
      });
    }

    if (request.filters?.showOnOverview !== null && request.filters?.showOnOverview !== undefined) {
      params = params.append(`IsShowOnOverView`, request.filters?.showOnOverview);
    }
    params = params.append('TimeZoneOffset', new Date().getTimezoneOffset());
    return this.httpClient.get<GetDocumentListsResponse>(
      `${environment.apiUrl}/members/GetParticipantDocumentList`,
      { params }
    );
  }

  uploadDocuments(body: any): Observable<void> {
    const data = new FormData();
    data.append('ParticipantId', body.participantId || '');
    this.documentFile?.forEach((item: any) => {
      data.append('FileContents', item || '');
    })
    data.append('FileName', body.fileName || '');
    data.append('Description', body.description || '');
    if (body.tags.length) {
      body.tags.forEach((item: any, idx: any) => {
        data.append(`Tags[${idx}]`, item);
      });
    }
    data.append('ShowOnOverview', body.showOnOverview ?? false);
    data.append('DocumentName', body.documentName || '');
    data.append('DocumentLocationTitle', body.documentLocationTitle || '');
    data.append('DocumentLocationRouter', body.documentLocationRouter || '');
    if (!isEmpty(body?.participantDocumentType)) {
      data.append('ParticipantDocumentType', body.participantDocumentType);
    }

    return this.httpClient.post<void>(
      `${environment.apiUrl}/members/upload-document`, data
    );
  }

  uploadOrganizationDocuments(body: any): Observable<void> {
    const data = new FormData();
    data.append('OrganizationId', body.participantId || '');
    this.documentFile?.forEach((item: any) => {
      data.append('FileContents', item || '');
    })
    data.append('FileName', body.fileName || '');
    data.append('Description', body.description || '');
    if (body.tags.length) {
      body.tags.forEach((item: any, idx: any) => {
        data.append(`Tags[${idx}]`, item);
      });
    }
    data.append('ShowOnOverview', body.showOnOverview ?? false);
    data.append('DocumentName', body.documentName || '');
    data.append('DocumentLocationTitle', body.documentLocationTitle || '');
    data.append('DocumentLocationRouter', body.documentLocationRouter || '');
    if (!isEmpty(body?.participantDocumentType)) {
      data.append('DocumentType', body.participantDocumentType);
    }

    return this.httpClient.post<void>(
      `${environment.apiUrl}/organization/upload-document`, data
    );
  }

  editDocuments(memberId: string, fileId: string, body: any): Observable<void> {
    return this.httpClient.put<void>(
      `${environment.apiUrl}/members/${memberId}/document/${fileId}`, body
    );
  }

  removeDocument(body: DocumentBodyRequest): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/members/documents`, { body }
    );
  }

  removeOrganizationDocument(body: RemoveOrganizationItem): Observable<void> {
    return this.httpClient.delete<void>(
      `${environment.apiUrl}/organization/documents`, { body }
    );
  }

  validateBeforeRemoveDocument(body: ValidateBeforeRemoveDocumentRequest): Observable<ValidateBeforeRemoveDocumentResponce> {
    return this.httpClient.post<ValidateBeforeRemoveDocumentResponce>(
      `${environment.apiUrl}/members/documents/validate-before-remove`, body
    );
  }

  checkExistDocumentName = (checkExistDocumentNameRequest: CheckExistsDocumentNameRequest) => {
    let params = new HttpParams();
    if (checkExistDocumentNameRequest?.documentId) {
      params = params.append('fileId', checkExistDocumentNameRequest.documentId);
    }
    if (checkExistDocumentNameRequest?.documentName) {
      params = params.append('documentName', checkExistDocumentNameRequest.documentName);
    }
    return this.httpClient.get<CheckExistsDocumentNameResponse>(
      `${environment.apiUrl}/members/${checkExistDocumentNameRequest.memberId}/documents/exists`, { params }
    );
  }

  checkExistDocumentNameMultiple(memberId: string, body: string[]): Observable<any> {
    return this.httpClient.post<any>(
      `${environment.apiUrl}/members/${memberId}/multiple-documents/exists`, body
    );
  }

  getValidateGenerateAge65Letter = (request: GetValidateAge65LetterRequest) => {
    let params = new HttpParams();
    if (request?.fromDate) {
      params = params.append('fromDate', request.fromDate);
    }
    if (request?.toDate) {
      params = params.append('toDate', request.toDate);
    }
    if (request?.timeZoneOffset) {
      params = params.append('timeZoneOffset', request.timeZoneOffset);
    }
    return this.httpClient.get<any>(
      `${environment.apiUrl}/Template/age-65-letter/validate`, { params }
    )
  }
}
