<div class="header flex items-center">
  <ng-container>
    <div class="icon-div flex items-center">
      <ng-container [ngTemplateOutletContext]="{ iconConfig: data?.iconConfig ?? iconConfig }"
                    [ngTemplateOutlet]="iconTemplate"></ng-container>
    </div>
    <span class="title">{{ title }}</span>
  </ng-container>
</div>

<div class="content">
  <div *ngIf="data.listText" class="pt-2">
    <ul>
      <li *ngFor="let text of data.listText">{{text}}</li>
    </ul>
  </div>
  <p *ngIf="!data.listText" [innerHTML]="data.text" [ngClass]="{'no-truncate': data.noTruncate}"></p>
  <div>
    <ptg-checkbox
      *ngIf="data.haveDontShowAgainCheckbox"
      [controlField]="displayCheckBox"
      label="Do not show this again"
  ></ptg-checkbox>
  </div>

</div>

<div class="footer">
  <button mat-stroked-button class="btn-yes" *ngIf="data.type !== ConfirmType.Error && data.type !== ConfirmType.Confirm && data.type !== ConfirmType.ConfirmWithDontShowAgain && !data?.hideConfirmButton" [ngClass]="{'btn-destruct':data.type === ConfirmType.Destruct,'btn-delete':data.type === ConfirmType.Delete}" [mat-dialog-close]="data.confirm">Yes</button>
  <button mat-raised-button type="button" class="primary" *ngIf="data.type === ConfirmType.ConfirmSave && !data?.hideSaveButton" [mat-dialog-close]="data.confirm"> {{ saveButtonTitle }}</button>
  <button mat-stroked-button type="button" class="secondary" *ngIf="data.type === ConfirmType.ConfirmSave && !data.hideSaveAsButton" (click)="onSaveAsClick()">Save As</button>
  <button mat-stroked-button class="btn-yes" *ngIf="data.type === ConfirmType.ConfirmWithDontShowAgain" (click)="onConfirmWithDontShowAgain()">Yes</button>
  <button mat-stroked-button (click)="onNoClick()" 
    [ngClass]="{ 'btn-cancel': data.type === ConfirmType.Warning || data.type === ConfirmType.Error || data.type === ConfirmType.Success }"
  >{{ cancelButtonTitle }}</button>
</div>

<ng-template #iconTemplate let-iconConfig="iconConfig">
  <mat-icon
    class="svg-icon"
    [svgIcon]="iconConfig.icon"
    *ngIf="iconConfig?.isSvg; else normalIcon"
  ></mat-icon>
  <ng-template #normalIcon>
    <mat-icon class="svg-icon {{ iconConfig?.class }}" [style]="iconConfig?.style">{{ iconConfig?.icon }}</mat-icon>
  </ng-template>
</ng-template>
