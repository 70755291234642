import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, FormArray, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AddOneTimeComponent } from '@ptg-member/components/add-one-time/add-one-time.component';
import { InputType } from '@ptg-member/constance/metadataPropertyType.const';
import { OneTimePaymentType } from '@ptg-member/constance/paymentType.const';
import { BaseComponent } from '@ptg-shared/components';
import { MAX_VALUE_NUMBER } from '@ptg-shared/constance';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { showBanner, showCancelDialog, showConfirmDialog } from '@ptg-shared/utils/common.util';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { Subject, Observable, of, timer } from 'rxjs';
import { takeUntil, switchMap, map, tap, debounceTime, startWith, take, filter } from 'rxjs/operators';
import { AddOneTimeService } from '../../services';
import {
  GetDeductionFundingSourcesResponse,
  QueryParamsBenefit,
} from '../../services/models';
import {
  benefitTimeInfoSelector,
  deductionDataSelector,
  getBenefitTimeInfoAction,
  getDeductionDataAction,
  clearDeductionDataStateAction,
} from '../../store';
import { DeductionTypeRevert, DeductionType } from '../../types/enums';
import { HeaderBenefit } from '../../types/models';
import * as fromMember from '../../../../store/reducers';
import { Option } from '@ptg-shared/controls/select/select.component';
import { DatePipe } from '@angular/common';
import { DeductionItem, OffsetDeductionPayload } from '../../services/models/create-offset-deduction.model';
import {
  clearCreateOffsetDeductionStateAction,
  createOffsetDeductionAction,
} from '../../store/actions/create-offset-deduction.action';
import { createOffsetDeductionSateSelector } from '../../store/selectors/create-offset-deduction.selector';
import { Router } from '@angular/router';
import { PaymentType } from '../../types/constants/payment-info-tab.constant';
import { CONTROL_NAME_QDRO } from '../../types/constants';

const datePipe = new DatePipe('en-US');

@Component({
  selector: 'ptg-create-offset-deduction',
  templateUrl: './create-offset-deduction.component.html',
  styleUrls: ['./create-offset-deduction.component.scss'],
})
export class CreateOffsetDeductionComponent extends BaseComponent {
  readonly DeductionTypeRevert = DeductionTypeRevert;
  readonly DeductionType = DeductionType;
  DeductionLabels: Record<DeductionType, string> = DeductionTypeRevert;

  formSubmit$ = new Subject<boolean>();
  InputType = InputType;
  MAX_VALUE_NUMBER = MAX_VALUE_NUMBER;
  editForm = new FormGroup({
    reason: new FormControl('')
  });
  listDeductionType: Option[] = [];
  listChip: Option[] = [];
  listInsuranceDeduction: Option[] = [];
  listOthersDeduction: Option[] = [];
  listCourtOrder: Option[] = [];
  listCourtOrderQdro: Option[] = [];
  listDeductionPayee: Option[] = [];
  listTax: Option[] = [];

  bannerType: BannerType = BannerType.Hidden;
  message = '';
  maxDate = new Date();
  minDate = new Date();
  benefitBeginDate = new Date();
  benefitEndDate = new Date();
  benefitPeriodOverlap = false;
  errorMinDate = '';
  errorStartAsync = '';
  errorEndAsync = '';

  // Tax Deduction Amounts Calculation Error Banner
  errorOffsetBannerType = BannerType.Hidden;
  errorOffsetMessage = '';
  isGotoWireTransfer = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      memberId: string;
      viewName: string;
      payload: any;
      isAnnuityBenefitEntity?: boolean;
      isPeriodicLumpsumPaymentFinalized?: boolean;
      selectedHeaderBenefit?: HeaderBenefit;
      infoData: {
        payeeEntityId: string;
        payeeEntityRecordId: string;
      }
    },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddOneTimeComponent>,
    private memberStore: Store<fromMember.MemberState>,
    private addOneTimeService: AddOneTimeService,
    private router: Router
  ) {
    super();
    this.errorMinDate = `Start date must be after the Payee's benefit begin date for ${this.data.selectedHeaderBenefit?.benefitName}.`;
  }

  get benefitPeriodFormGroup() {
    return this.editForm.get('benefitPeriod') as FormGroup;
  }

  get deductionFormGroup() {
    return this.editForm.get('deduction') as FormGroup;
  }

  get taxFormGroup() {
    return this.deductionFormGroup.get('tax') as FormGroup;
  }

  get insuranceFormArray() {
    return this.deductionFormGroup?.get('insurance') as FormArray;
  }

  get othersFormArray() {
    return this.deductionFormGroup?.get('others') as FormArray;
  }

  get garnishmentsFormArray() {
    return this.deductionFormGroup?.get('garnishments') as FormArray;
  }

  get qdroFormArray() {
    return this.deductionFormGroup?.get(CONTROL_NAME_QDRO) as FormArray;
  }

  ngOnInit(): void {
    this.memberStore.dispatch(getBenefitTimeInfoAction({queryParams: this._getQueryParams(), paymentInstructionTypeId: OneTimePaymentType['Correction'] + ''}));
    if (this.data.isAnnuityBenefitEntity && !this.data.isPeriodicLumpsumPaymentFinalized) {
      this._createBenefitPeriodFormGroup();
      this.editForm.addControl('payableDate', new FormControl(new Date()));
      this.editForm.addControl('deduction', new FormGroup({
        deductionType: new FormControl('', {validators: this._requiredChip()})
      }));
      this.showBanner();
    } else {
      this.editForm.addControl('payableDate', new FormControl(new Date()));
      this.editForm.addControl('deduction', new FormGroup({
        deductionType: new FormControl('', {validators: this._requiredChip()})
      }));
    }

    this.memberStore.select(benefitTimeInfoSelector).subscribe(el => {
      if (el.payload) {
        this.benefitBeginDate = new Date(el.payload.startDate);
        this.benefitEndDate = new Date(el.payload.endDate);
        this.minDate = this.benefitBeginDate;
        if (!this.data.isAnnuityBenefitEntity || this.data.isPeriodicLumpsumPaymentFinalized) {
          this.memberStore.dispatch(getDeductionDataAction({
            benefitCode: this.data.selectedHeaderBenefit?.benefitId || '',
            memberId: this.data.memberId,
            queryParams: {
              benefitTypeName: this.data.selectedHeaderBenefit?.benefitTypeName
            } as any
          }));
        }
      }
    });

    this.memberStore.select(deductionDataSelector).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(res => {
      if (res?.payload) {
        this.setList(res.payload);
      }
    });

    this.editForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(el => {
      this.benefitPeriodOverlap = false;
    });

    this.onSubmit();
    this.selectCreateOffsetDeductionState();
  }

  selectCreateOffsetDeductionState() {
    this.memberStore
    .select(createOffsetDeductionSateSelector)
    .pipe(
      filter((state) => !!state),
      takeUntil(this.unsubscribe$),
    )
    .subscribe((state) => {
      if (state?.success) {
        this.dialogRef.close();
        this.router.navigateByUrl(this.isGotoWireTransfer ? '/processing/wire-transfer-and-internal-payment' : '/processing/dp-checks-and-direct-deposit');
      } else {
        this.errorOffsetBannerType = BannerType.Fail;
        this.errorOffsetMessage = 'Error occurred adding Offset Deduction. Please try again.';
        this.memberStore.dispatch(clearCreateOffsetDeductionStateAction());
      }
    });
  }

  setList(data: GetDeductionFundingSourcesResponse) {
    const result = this.addOneTimeService.mapDeductionTypesFromResponse(data, {
      sortFunctions: {
        [DeductionType.Others]: (a, b) => {
          if ((a.deductionCode || '') > (b.deductionCode || '')) {
            return 1;
          }
          return -1;
        }
      }
    });
    this.listDeductionType = result.listDeductionType;
    this.listTax = result.listTax;
    this.listInsuranceDeduction = result.listInsuranceDeduction;
    this.listOthersDeduction = result.listOthersDeduction;
    this.listCourtOrder = result.listCourtOrder;
    this.listCourtOrderQdro = result.listCourtOrderQdro;
    this.DeductionLabels = result.deductionLabels;
  }

  showBanner() {
    showBanner.call(
      this,
      BannerType.Info,
      '',
      '',
      {
        customMessage: 'Benefit Period Start Date and Benefit Period End Date must match Start Date and End Date of a payroll cycle.'
      }
    );
  }

  changeStartDate() {
    const tempValue = this.editForm?.get('payableDate')?.value;
    this.editForm?.get('payableDate')?.setValue(null);
    this.editForm?.get('payableDate')?.setValue(tempValue);
  }

  changeRepresentativePayee(event: boolean) {
    if (event) {
      this.editForm.addControl('representativePayeeName', new FormControl());
      this.editForm.addControl('representativePayeeNamePrint', new FormControl());
    } else {
      this.editForm.removeControl('representativePayeeName');
      this.editForm.removeControl('representativePayeeNamePrint');
    }
    this.editForm.get('paymentAddress')?.setValue(null);
  }

  private _getQueryParams(): QueryParamsBenefit {
    return {
      BenefitEntityDataId: this.data.selectedHeaderBenefit?.benefitEntityDataId ?? '',
      BenefitTypeOptionId: this.data.selectedHeaderBenefit?.benefitTypeOptionId ?? '',
      PayeeEntityRecordId: this.data.infoData.payeeEntityRecordId ?? '',
      BenefitTypeName: this.data.selectedHeaderBenefit?.benefitTypeName ?? '',
      BenefitRecordId: this.data.selectedHeaderBenefit?.benefitEntityDataId ?? '',
      BenefitCode: this.data.selectedHeaderBenefit?.benefitId ?? '',
    }
  }

  private _resetPayableDate() {
    const temp = this.editForm.get('payableDate')?.value;
    this.editForm.get('payableDate')?.reset();
    this.editForm.get('payableDate')?.setValue(temp);
    this.editForm.updateValueAndValidity();
  }

  private _checkBenefitPeriodDate(filedName: string) {
    return checkApiValidator(
      this.addOneTimeService.checkValidDate,
      filedName,
      undefined,
      {
        params: {
          queryParams: {
            benefitTypeOptionId: this.data.selectedHeaderBenefit?.benefitTypeOptionId,
            payrollBenefitCode: this.data.selectedHeaderBenefit?.benefitId
          },
          paymentInstructionTypeId: OneTimePaymentType.Correction,
        },
      },
      (result) => {
        if (filedName === 'benefitPeriodStart') {
          this.errorStartAsync =
            result?.existedPeriod === false
              ? 'Start Date must match start date of a payroll cycle.'
              : result?.isValid === false
                ? `Start date must be after the Payee's benefit begin date for ${this.data.selectedHeaderBenefit?.benefitName}.`
                : '';
        } else if (filedName === 'benefitPeriodEnd') {
          this.errorEndAsync =
            result?.existedPeriod === false
              ? 'End Date must match end date of a payroll cycle.'
              : result?.isValid === false
                ? result?.message
                : '';
        }
        this.benefitPeriodFormGroup
          .get(filedName)
          ?.setErrors(result?.existedPeriod === false || result?.isValid === false ? { inValidAsync: true } : null);        
      }
    )
  }

  private _addSyncValidatorBenefitPeriod() {
    this.benefitPeriodFormGroup?.get('benefitPeriodStart')?.addAsyncValidators(this._checkBenefitPeriodDate('benefitPeriodStart'));
    this.benefitPeriodFormGroup?.get('benefitPeriodEnd')?.addAsyncValidators(this._checkBenefitPeriodDate('benefitPeriodEnd'));
    this.editForm.updateValueAndValidity();
  }

  onFederalValueChange(event: string) {
    
  }

  checkValidateEndDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate = control.parent?.get('benefitPeriodStart');
      const endDate = control.parent?.get('benefitPeriodEnd');
      if (!endDate) {
        return null;
      }
      if (!startDate?.value || (startDate?.value > endDate?.value && endDate?.value !== null)) {
        return { errorMessageEndDate: 'End Date must be after Start Date.' };
      }
      return null;
    };
  }

  private _createBenefitPeriodFormGroup() {
    this.editForm.addControl('benefitPeriod', new FormGroup({
      benefitPeriodStart: new FormControl(),
      benefitPeriodEnd: new FormControl(null, this.checkValidateEndDate()),
    }));
    this._addSyncValidatorBenefitPeriod();
    this.benefitPeriodFormGroup?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      if (this.deductionFormGroup) {
        this.editForm.removeControl('deduction');
        this.editForm.addControl('deduction', new FormGroup({
          deductionType: new FormControl('', {validators: this._requiredChip()})
        }));
        this.listChip = [];
      }
    });
    this.benefitPeriodFormGroup?.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((el) => {
      if (this.benefitPeriodFormGroup.valid) {
        // Call api to get data for Deduction 
        this.memberStore.dispatch(getDeductionDataAction({
          benefitCode: this.data.selectedHeaderBenefit?.benefitId || '',
          memberId: this.data.memberId,
          queryParams: {
            startDate: datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodStart')?.value, 'yyyy-MM-dd') || '',
            endDate: datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodEnd')?.value, 'yyyy-MM-dd') || '',
            benefitTypeName: this.data.selectedHeaderBenefit?.benefitTypeName
          }
        }));
      } else {
        this.listDeductionType = [];
      }
    })
  }

  onRemoveRowDeduction(idx: number, formArray: FormArray, deductionType: DeductionType) {
    formArray.removeAt(idx);
    if (formArray.controls.length === 0) {
      const index = this.listChip.findIndex((el) => el.value === deductionType);
      this.removeChip(index);
    }
  }

  addNewRowDeduction(formArray: FormArray, deductionType: DeductionType) {
    const control = this._createFormGroupFollowType(deductionType);
    formArray.push(control);
  }

  private _checkAmount(sub: string, arr: Option[]) {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      let valueCheck = control.value?.toString()?.trim();
      const temp: Option | undefined = sub === 'federalTax' ? this.listTax[0] : arr.find(item => item?.value === control.parent?.get(sub)?.value);
      if (!temp) {
        return of(null);
      }
      let startDateCheck: Date | undefined;
      let endDateCheck: Date | undefined;
      if (!this.data.isAnnuityBenefitEntity || this.data.isPeriodicLumpsumPaymentFinalized) {
        startDateCheck = undefined;
        endDateCheck = undefined;
      } else {
        startDateCheck = this.benefitPeriodFormGroup?.get('benefitPeriodStart')?.value;
        endDateCheck = this.benefitPeriodFormGroup?.get('benefitPeriodEnd')?.value;
      }
      
      return timer(300).pipe(
        switchMap((): Observable<ValidationErrors | null> =>
          this.addOneTimeService.checkValidDateAmount({
            queryParams: {
              startDate: datePipe.transform(startDateCheck, 'yyyy-MM-dd') ?? '',
              endDate: datePipe.transform(endDateCheck, 'yyyy-MM-dd') ?? '',
              benefitCode: this.data.selectedHeaderBenefit?.benefitId || '',
              BenefitTypeName: this.data.selectedHeaderBenefit?.benefitTypeName || '',
              ...(temp?.extraData?.existPayrollBenefitDeductionId && {
                deductionId: temp?.extraData?.existPayrollBenefitDeductionId,
              }),
              amount: Number(valueCheck)
            },
            memberId: this.data.memberId
          }).pipe(
            map((response: any) => {
              if (response && (response.exists || response.isExisted || response.isExists || response.isExist || response.isValid === false || response.currentExists)) {
                const startDate = datePipe.transform(startDateCheck, 'MM/dd/yyyy') ?? '';
                const endDate = datePipe.transform(endDateCheck, 'MM/dd/yyyy') ?? '';
                const msgAnnuity = `Entered amount is greater than previously taken amounts of the ${temp.displayValue} in Recurring and One time payments for the benefit period ${startDate} - ${endDate}.`;
                const msgLumpSum = `Entered amount is greater than previously taken amounts of the ${temp.displayValue} in One time payments`;
                return { 
                  inValidAsync: (!this.data.isAnnuityBenefitEntity || this.data.isPeriodicLumpsumPaymentFinalized) ? msgLumpSum : msgAnnuity
                };
              }
              return null;
            })
          )
        )
      );
    };
  }

  private _createFormGroupFollowType(type: DeductionType) {
    switch (type) {
      case DeductionType.Garnishment:
        return new FormGroup({
          courtOrder: new FormControl(''),
          deductionPayee: new FormControl(''),
          amount: new FormControl(null, {
            validators: this._validateAmount(),
            asyncValidators: this._checkAmount('courtOrder', this.listCourtOrder)
          }),
        });

      case DeductionType.Others:
        return new FormGroup({
          othersDeduction: new FormControl(''),
          amount: new FormControl(null, {
            validators: this._validateAmount(),
            asyncValidators: this._checkAmount('othersDeduction', this.listOthersDeduction)
          }),
        });
      
      case DeductionType.Insurance:
        return new FormGroup({
          insuranceDeduction: new FormControl(''),
          amount: new FormControl(null, {
            validators: this._validateAmount(),
            asyncValidators: this._checkAmount('insuranceDeduction', this.listInsuranceDeduction)
          }),
        });

      case DeductionType.Qdro:
        const formGroup = new FormGroup({
          courtOrder: new FormControl(''),
          deductionPayee: new FormControl(''),
          amount: new FormControl(null, {
            validators: this._validateAmount(),
            asyncValidators: this._checkAmount('courtOrder', this.listCourtOrderQdro),
          }),
        });
        this.addOneTimeService.subscribeQdroForm(formGroup, this.unsubscribe$);
        return formGroup;
    
      default:
        return new FormGroup({});
    }
  }

  // private _dependentStartDate() {
  //   return (c: AbstractControl) => {
  //     const benefitPeriod = c?.parent?.get('benefitPeriod');
  //     if (!benefitPeriod || !benefitPeriod.get('benefitPeriodStart')?.value) {
  //       return null;
  //     }
  //     const start = benefitPeriod.get('benefitPeriodStart')?.value;
  //     const startTime = new Date(new Date(start).toLocaleDateString()).getTime();
  //     const currentValue = new Date(new Date(c?.value).toLocaleDateString()).getTime();
  //     return currentValue >= startTime ? null : { dependentStartDate: `Payable date cannot be before Benefit Period Start Date` };
  //   };
  // }

  private _requiredChip() {
    return (c: AbstractControl) => {
      if (this.benefitPeriodFormGroup?.invalid && this.data.isAnnuityBenefitEntity) {
        return{ requiredChip: `Please enter benefit period first` };
      }
      return this.listChip?.length ? null : { requiredChip: `At least one Deduction is required.` };
    };
  }

  private _validateAmount(isTax?: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value <= 0) {
        return {
          errorAmountMessage: isTax
            ? 'Tax amount must be positive. Otherwise, please remove taxes'
            : 'Amount must be positive value.',
        };
      }
      return null;
    };
  }

  addType() {
    const deductionType = this.deductionFormGroup?.get('deductionType');
    let selectedItem = this.listDeductionType.find((el, i) => {
      if (el.value === deductionType?.value) {
        return true;
      }
      return false;
    });

    // Add item to listChip, clear selector, remove option out of list
    if (selectedItem) {
      this.listChip.push(selectedItem);
      deductionType?.setValue('');
      selectedItem.isHide = true;
    }

    this.generateControlFollowDeductionType(selectedItem, true);
  }

  removeChip(index: number) {
    const deductionType = this.deductionFormGroup?.get('deductionType');
    let item = this.listChip.splice(index, 1);
    const idx = this.listDeductionType.findIndex(el => {
      return el.value === item[0].value;
    });
    this.listDeductionType[idx].isHide = false;
    if (this.listChip.length === 0) {
      deductionType?.markAsTouched();
      deductionType?.setErrors({
        requiredChip: `At least one Deduction is required.`
      });
    }

    this.generateControlFollowDeductionType(item[0], false);
  }

  generateControlFollowDeductionType(element: Option | undefined, isAdd: boolean) {
    if (isAdd) {
      switch (element?.value as DeductionType) {
        case DeductionType.Tax:
          this.deductionFormGroup.addControl('tax', new FormGroup({
            federalTax: new FormControl(null, {
              validators: this._validateAmount(true),
              asyncValidators: this._checkAmount('federalTax', [])
            }),
            // stateTax: new FormControl(null, {
            //   validators: this._validateAmount(true),
            //   asyncValidators: this._checkAmount('stateTax', [])
            // }),
          }));
          break;

        case DeductionType.Insurance:
          this.deductionFormGroup.addControl('insurance', new FormArray([
            this._createFormGroupFollowType(element?.value)
          ]));
          break;
      
        case DeductionType.Others:
          this.deductionFormGroup.addControl('others', new FormArray([
            this._createFormGroupFollowType(element?.value)
          ]));
          break;

        case DeductionType.Garnishment:
          this.deductionFormGroup.addControl('garnishments', new FormArray([
            this._createFormGroupFollowType(element?.value)
          ]));
          break;

        case DeductionType.Qdro:
          this.deductionFormGroup.addControl(CONTROL_NAME_QDRO, new FormArray([
            this._createFormGroupFollowType(element?.value)
          ]));
          break;
      }
      
    } else {
      switch (element?.value as DeductionType) {
        case DeductionType.Tax:
          this.deductionFormGroup.removeControl('tax');
          break;

        case DeductionType.Insurance:
          this.deductionFormGroup.removeControl('insurance');
          break;
        
        case DeductionType.Others:
          this.deductionFormGroup.removeControl('others');
          break;

        case DeductionType.Garnishment:
          this.deductionFormGroup.removeControl('garnishments');
          break;

        case DeductionType.Qdro:
          this.deductionFormGroup.removeControl(CONTROL_NAME_QDRO);
          break;
      }
    }
  }

  onChangeInsuranceDeductionValue(event: Option, control: AbstractControl) {
    this.resetAmountValue(control);
  }

  onChangeOthersDeductionValue(event: Option, control: AbstractControl) {
    this.resetAmountValue(control);
  }

  onSaveButton() {
    showConfirmDialog(
      this.dialog,
      `Do you want to create a new Offset Deduction?`,
    ).pipe(takeUntil(this.unsubscribe$)).subscribe((result) => {
        if (result) {
          this.formSubmit$.next();
        }
      })
  }

  onSubmit() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        debounceTime(500),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter(status => status !== AbstractControlStatus.PENDING),
            take(1)
          )
        ),
        filter(status => status === AbstractControlStatus.VALID),
        switchMap(() => {
          const benefitSubType = this.data.selectedHeaderBenefit?.benefitTypeOptionId ?? '';
          let startDateCheck: Date | undefined;
          let endDateCheck: Date | undefined;
          if (!this.data.isAnnuityBenefitEntity || this.data.isPeriodicLumpsumPaymentFinalized) {
            startDateCheck = undefined;
            endDateCheck = undefined;
          } else {
            startDateCheck = this.benefitPeriodFormGroup?.get('benefitPeriodStart')?.value;
            endDateCheck = this.benefitPeriodFormGroup?.get('benefitPeriodEnd')?.value;
          }
          const params = {
            payeeEntityRecordId: this.data.infoData.payeeEntityRecordId,
            paymentInstructionType: OneTimePaymentType.Correction,
            startDate: datePipe.transform(startDateCheck, 'yyyy-MM-dd') ?? '',
            endDate: datePipe.transform(endDateCheck, 'yyyy-MM-dd') ?? '',
            benefitCode: this.data.selectedHeaderBenefit?.benefitId
            // Todo: update param paymentInstructionId
          };
          return this.addOneTimeService.checkConditionBeforeSave(benefitSubType, params).pipe(
            filter(res => {
              if (res && !res.isValid) {
                this.benefitPeriodOverlap = true;
              }
              return res.isValid;
            }),
            take(1),
          )
        })
      )
      .subscribe(() => {
        this.saveValue();
      });
  }

  saveValue() {
    const createPayload = () => {
      const body: OffsetDeductionPayload = {
        beginningOn: this.benefitPeriodFormGroup?.get('benefitPeriodStart') ? datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodStart')?.value, 'yyyy-MM-dd') : '',
        endingOn: this.benefitPeriodFormGroup?.get('benefitPeriodEnd') ? datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodEnd')?.value, 'yyyy-MM-dd') : '',
        payableDate: datePipe.transform(this.editForm?.get('payableDate')?.value, 'yyyy-MM-dd') ?? '',
        reason: this.editForm.get('reason')?.value,
        benefitRecordId: this.data.selectedHeaderBenefit?.benefitEntityDataId ?? '',
        benefitSubType: this.data.selectedHeaderBenefit?.benefitTypeOptionId ?? '',
        benefitEntityId: this.data.selectedHeaderBenefit?.benefitEntityId ?? '',
        benefitCode: this.data.selectedHeaderBenefit?.benefitId ?? '',
        payeeEntityId: this.data.infoData.payeeEntityId,
        payeeEntityRecordId: this.data.infoData.payeeEntityRecordId,
        offsetDeductions: []
      };
      if (this.deductionFormGroup) {
        const offsetDeductions: DeductionItem[] = [];
        this.insuranceFormArray?.getRawValue()?.forEach(el => {
          const temp = this.listInsuranceDeduction.find(item => item.value === el.insuranceDeduction);
          offsetDeductions.push({
            id: temp?.extraData?.payrollBenefitDeductionId,
            amount: el.amount,
            deductionId: temp?.extraData?.payrollDeductionId,
            deductionPayeeId: temp?.extraData?.payeeId,
            deductionType: DeductionType.Insurance,
            deductionSubType: temp?.extraData?.subTypeId,
            isManualInput: true,
            paymentType: temp?.extraData?.paymentType
          } as DeductionItem)
        });
        this.othersFormArray?.getRawValue()?.forEach(el => {
          const temp = this.listOthersDeduction.find(item => item.value === el.othersDeduction);
          offsetDeductions.push({
            id: temp?.extraData?.payrollBenefitDeductionId,
            amount: el.amount,
            deductionId: temp?.extraData?.payrollDeductionId,
            deductionPayeeId: temp?.extraData?.payeeId,
            deductionType: DeductionType.Others,
            deductionSubType: temp?.extraData?.subTypeId,
            isManualInput: true,
            paymentType: temp?.extraData?.paymentType
          } as DeductionItem)
        });
        this.garnishmentsFormArray?.getRawValue()?.forEach(el => {
          const temp = this.listCourtOrder.find(item => item.value === el.courtOrder);
          offsetDeductions.push({
            id: temp?.extraData?.payrollBenefitDeductionId,
            amount: el.amount,
            deductionId: temp?.extraData?.payrollDeductionId,
            caseNumber: temp?.extraData?.caseNumber,
            deductionPayeeId: temp?.extraData?.payeeId,
            deductionType: DeductionType.Garnishment,
            deductionSubType: temp?.extraData?.subTypeId,
            isManualInput: true,
            paymentType: temp?.extraData?.paymentType
          } as DeductionItem)
        });
        //#region #158403 QDRO in OTP
        this.qdroFormArray?.getRawValue()?.forEach(el => {
          const temp = this.listCourtOrderQdro.find((item) => item.value === el.courtOrder);
          offsetDeductions.push({
            id: temp?.extraData?.payrollBenefitDeductionId,
            amount: el.amount,
            deductionId: temp?.extraData?.payrollDeductionId,
            caseNumber: temp?.extraData?.caseNumber,
            deductionPayeeId: temp?.extraData?.payeeId,
            deductionType: DeductionType.Qdro,
            deductionSubType: temp?.extraData?.subTypeId,
            isCreatingOffsetDp: el.createOffsetDeduction,
            isManualInput: true,
            paymentType: temp?.extraData?.paymentType
          });
        });
        //#endregion
        if (this.taxFormGroup) {
          offsetDeductions.push({
            id: this.listTax[0]?.extraData?.payrollBenefitDeductionId,
            amount: Number(this.taxFormGroup.get('federalTax')?.value) || 0,
            deductionId: this.listTax[0]?.extraData?.payrollDeductionId,
            deductionPayeeId: this.listTax[0]?.extraData?.payeeId,
            deductionType: DeductionType.Tax,
            deductionSubType: this.listTax[0]?.extraData?.subTypeId,
            isManualInput: true,
            paymentType: this.listTax[0]?.extraData?.paymentType
          } as DeductionItem)
        }
        body.offsetDeductions = offsetDeductions;
        this.checkDestination(body.offsetDeductions);
      }
      this.memberStore.dispatch(createOffsetDeductionAction({ body }));
    };

    if (this.editForm.valid) {
      createPayload();
    }
  }

  checkDestination(offsetDeductions: DeductionItem[]) {
    this.isGotoWireTransfer = offsetDeductions.every(el => {
      return el.paymentType === PaymentType['Internal Payment'] || el.paymentType === PaymentType['Wire Transfer'];
    });
  }

  onCancel() {
    showCancelDialog(this.dialog, this.dialogRef);
  }

  closeBanner() {
    this.message = '';
  }

  closeBannerOffset() {
    this.errorOffsetMessage = ''
  }

  checkConditionToShowAddButton(type: DeductionType) {
    switch (type) {
      case DeductionType.Insurance:
        const lastInsurance = this.insuranceFormArray?.get((this.insuranceFormArray.length - 1)?.toString());
        return this.insuranceFormArray.length < this.listInsuranceDeduction.length && lastInsurance?.valid;
    
      case DeductionType.Others:
        const lastOthers = this.othersFormArray?.get((this.othersFormArray.length - 1)?.toString());
        return this.othersFormArray.length < this.listOthersDeduction.length && lastOthers?.valid;

      case DeductionType.Garnishment:
        const lastGarnishment = this.garnishmentsFormArray?.get((this.garnishmentsFormArray.length - 1)?.toString());
        return this.garnishmentsFormArray.length < this.listCourtOrder.length && lastGarnishment?.valid;

      case DeductionType.Qdro:
        const lastQdro = this.qdroFormArray?.get((this.qdroFormArray.length - 1)?.toString());
        return this.qdroFormArray.length < this.listCourtOrderQdro.length && lastQdro?.valid;

      default:
        return false;
    }
  }

  _createListData(value: string, formArray: FormArray, arrayList: Option[], field: string, subType?: 'qdro') {
    const arr = formArray?.getRawValue()?.map(el => el[field]);
    if (subType === 'qdro') {
      arrayList = arrayList.slice().sort((a, b) => a.displayValue.localeCompare(b.displayValue ?? '') ?? 0);
    }
    return arrayList
      .filter(el => {
        if (value?.toString() && el.value === value || !arr.includes(el.value)) {
          return true;
        } else {
          return false;
        }
      });
  }

  onChangeGarnishmentValue(event: Option, control: AbstractControl) {
    control.get('deductionPayee')?.setValue(event?.extraData?.payee || '');
    this.resetAmountValue(control);
  }

  resetAmountValue(control: AbstractControl) {
    const temp = control.get('amount')?.value;
    control.get('amount')?.setValue('');
    control.get('amount')?.setValue(temp);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.memberStore.dispatch(clearDeductionDataStateAction());
  }
}
