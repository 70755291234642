import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { BaseComponent } from '@ptg-shared/components';
import {
  ACTION_COLUMN,
  Align,
  Column,
  GridComponent,
  ReorderInfo,
  Row,
  getValidatorsFromColumns,
} from '@ptg-shared/controls/grid';
import {
  VERTICAL_LINE_SEPARATOR,
} from '@ptg-shared/constance/common.const';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import {
  ADMIN_SYSTEM,
  CANCEL_CONFIRM_MESSAGE,
  SORT_TYPE,
} from '@ptg-shared/constance/value.const';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { Option } from '@ptg-shared/controls/select/select.component';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';

import { EntityPropertyType } from '@ptg-entity-management/types/enums';
import {
  EntityPropertyDisplayConfig,
  EntityPropertyDisplayConfiguration,
  EntitySectionConfig
} from '../../services/models';
import { FilterPropertyType } from '@ptg-member/constance/member-list.const';
import { Store, select } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';
import { EntityState } from '@ptg-entity-management/store/reducers';
import { selectEntityCardSelection, selectEntityPropertyByCardSelection } from '@ptg-entity-management/store/selectors';
import { getEntityCardSelectionRequest, getEntityPropertyByCardSelectionRequest } from '@ptg-entity-management/store/actions';
import { LayoutService } from '@ptg-shared/services/layout.service';


@Component({
  selector: 'ptg-entity-property-display-configuration',
  templateUrl: './entity-property-display-configuration.component.html',
  styleUrls: ['./entity-property-display-configuration.component.scss'],
})
export class EntityPropertyDisplayConfigurationComponent
  extends BaseComponent
  implements OnChanges
{
  readonly ACTION_COLUMN = ACTION_COLUMN;
  ADMIN_SYSTEM = ADMIN_SYSTEM;
  @ViewChild('sortPropertyTable')
  gridview!: GridComponent<EntityPropertyDisplayConfiguration>;
  @ViewChild('sortRowTable')
  gridviewRow!: GridComponent<EntityPropertyDisplayConfiguration>;

  @Input() propertyConfigs: EntityPropertyDisplayConfig[] = [];
  @Input() defaultPropertyList: EntityPropertyDisplayConfig[] = [];
  @Input() propertyDisplayConfigurations:EntityPropertyDisplayConfiguration[] = [];
  @Input() addPropertySection: EntitySectionConfig = {
    title: 'Add Property',
    columnName: 'Column Name',
    propertyName: 'Property Name',
  };
  @Input() entityId: string = '';
  @Input() sortPropertySection: EntitySectionConfig = { title: 'Sort Property' };
  @Input() sortRowSection!: EntitySectionConfig;
  @Input() columnNameMaxLength: number = 150;
  @Input() isMemberListConfiguration: boolean = false;
  @Input() sortable: boolean = true;
  @Input() dataDetail!: any;
  @Output() onSubmitEvent: EventEmitter<EntityPropertyDisplayConfiguration[]> = new EventEmitter();
  @Output() formValueChange: EventEmitter<void> = new EventEmitter();
  @Output() onSubmitEventColumnSet: EventEmitter<any> = new EventEmitter();

  orderColumns: Column[] = [
    {
      name: 'columnName',
      editable: true,
      truncate: true,
      validators: {
        required: {
          type: (obj: any) => Validators.required,
          message: (error: any, fieldName: string) =>
            `${fieldName} is required.`,
        },
        maxlength: {
          type: (obj: any) => Validators.maxLength(this.columnNameMaxLength),
          message: (error: any, fieldName: string) =>
            `Exceed the ${error.requiredLength} character limit.`,
        },
        existed: {
          type: (obj: any) => this.checkExits(obj),
          message: (error: any, fieldName: string) =>
            `${fieldName} already exists.`,
        },
      },
      controlArgs: {
        placeholder: 'Column Name'
      },
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  sortColumns: Column[] = [
    {
      name: 'columnName',
      truncate: true,
    },
    {
      name: ACTION_COLUMN,
      align: Align.Right,
      width: '50px',
    },
  ];

  ACTION = {
    ADD_SORT_ROW: 'addSortRow',
    EDIT_COLUMN_NAME: 'editColumnName',
    REMOVE: 'remove',
    SORT_CHANGE: 'sortChange',
  };
  sortPropertySectionDataTable: (EntityPropertyDisplayConfiguration & Row)[] = [];
  sortRowSectionDataTable: (EntityPropertyDisplayConfiguration & Row)[] = [];
  isLoading = true;
  formData: FormGroup = this.fb.group({
    entityPropertyId: this.fb.control('', [Validators.required]),
    option: '',
    columnName: this.fb.control('', [
      Validators.required,
      Validators.maxLength(this.columnNameMaxLength),
      this.checkDuplicated(),
    ]),
    cardId: this.fb.control(null),
    propertyName: '',
    type: null,
    entityNameRef: null,
    entityReferencePropertyId: null,
    description: null
  });
  availablePropertyConfigs!: Option[] | any[];
  propertyOptions: RadioOption[] = [];
  canSubmit: boolean = false;
  editColumnForm!: FormGroup;
  cardSeclectionOptions!: Option[] | any[];
  cardId!: string | null;

  constructor(
    private fb: FormBuilder,
    public dialog: MatDialog,
    public route: ActivatedRoute,
    public entityStore: Store<EntityState>,
    private layoutService: LayoutService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.propertyConfigs || changes.propertyDisplayConfigurations?.firstChange) {
      this.getDataTable();
      this.getAvailablePropertyConfigs();
    }
    if (changes.columnNameMaxLength) {
      this.formData
        .get('columnName')
        ?.addValidators(Validators.maxLength(this.columnNameMaxLength));
    }
    if (changes.listColumnConfig) {
      this.editColumnForm = this.fb.group({
        columnSetName: [
          this.dataDetail?.columSelected ? this.dataDetail?.columSelected.columnSetName : '',
          [Validators.required],
        ],
        startWith: [''],
      });
    }
  }

  ngOnInit(): void {
    this.isLoading = false;
    this.getCardSelection();
    this.entityStore
      .pipe(
        select(selectEntityCardSelection),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((res: any) => {
        this.cardSeclectionOptions = res.cards?.filter((element: any) => element?.includesList === false && element.totalProperties !== 0).map((item: any) => ({
          value: item?.id,
          displayValue: item?.cardName
        } as Option)).sort((a: any, b: any) => a.displayValue.localeCompare(b.displayValue));
      });
  }

  getCardSelection() {
    const query = {
      sortField: 'cardName',
      sortType: 0,
      filterByEntityIds: this.entityId ?? '',
      includesList: false,
      includeSystemCard: false,
    };
    this.entityStore.dispatch(getEntityCardSelectionRequest({ query }));
  }

  changeCardSelection(option: Option) {
    this.layoutService.showLoading = true;
    this.propertyOptions = [];
    this.cardId = option?.value;
    this.formData.get('entityPropertyId')?.setValue(null);
    this.formData.get('columnName')?.setValue(null);
    if(!this.cardId) {
      this.layoutService.showLoading = false;
      this.propertyConfigs = this.defaultPropertyList;
      this.getAvailablePropertyConfigs();
    }
    else if (this.cardId) {
      this.entityStore.dispatch(getEntityPropertyByCardSelectionRequest({ cardId: option?.value }));
      this.entityStore
      .pipe(
        select(selectEntityPropertyByCardSelection),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((res: any) => {
        this.layoutService.showLoading = !!res?.isLoading;
        if (res && !res?.isLoading) {
          const data = res.propertyByCardSelection?.components.filter((item: any) => item.detailViewColumnType === 0).map((element: any) => element?.entityProfileComponentItems);
          const listProperty = Array.prototype.concat.apply([], data).
                        filter((prop: any) => prop.type !== FilterPropertyType.RichText);
          this.propertyConfigs = listProperty;
          this.getAvailablePropertyConfigs();
        }
      });
    }
  }

  getDataTable() {
    if (!this.propertyConfigs?.length) {
      return;
    }
    this.propertyDisplayConfigurations =
      this.getPropertyDisplayConfigurations();
    this.sortPropertySectionDataTable = this.propertyDisplayConfigurations
      .map((config) => {
        const obj = {
          ...this.getPropertyDisplayConfig(config),
          order: config.orderColumn || config.order,
          isUsed: config.orderRow !== null || !this.sortRowSection,
        };
        return this.createInlineEditFormControls(obj);
      })
      .sort((a, b) => Number(a.order) - Number(b.order));
    this.sortRowSectionDataTable = this.propertyDisplayConfigurations
      .reduce((result, config) => {
        if (config.orderRow) {
          result.push({
            ...this.getPropertyDisplayConfig(config),
            order: config.orderRow,
            sortType: config.sortType,
          });
        }
        return result;
      }, [] as EntityPropertyDisplayConfiguration[])
      .sort((a, b) => Number(a.order) - Number(b.order));
  }

  getPropertyDisplayConfigurations() {
    return this.propertyDisplayConfigurations.map((config) => {
      const cloneConfig = Object.assign({}, config);
      const item = (this.propertyConfigs as EntityPropertyDisplayConfig[]).find(
        (property) => {
          return this.isExisted(
            config,
            {
              id: property?.id || property.entityPropertyId,
            } as any,
            false
          );
        }
      ) as EntityPropertyDisplayConfig;
      if (item) {
        cloneConfig.propertyName = item.propertyName;
        cloneConfig.optionName = cloneConfig.option
          ?.split('|')
          .map(
            (el: any) => item.option?.find((item) => item.key === el)?.value
          )
          .join(', ');
      }
      return cloneConfig;
    });
  }

  createPropertyDisplayDescription(formValue: any, optionName: string) {
    let subText =  !formValue?.entityReferencePropertyId
    ? (formValue?.propertyName ? (formValue?.propertyName + (formValue?.propertyItem ? ' / ' + formValue?.propertyItem : ''))  : '')
    : (`${(formValue?.propertyNameEntityRef || formValue?.entityNameRef) + (formValue?.propertyName ? ' / ' + formValue?.propertyName : '') + (formValue?.propertyItem ? ' / ' + formValue?.propertyItem : '')}`);

    let option = formValue.option ?  ` / ${optionName}` : '';
    subText = subText ? (subText + option) : '';
    return subText;
  }

  getPropertyDisplayConfig(config: EntityPropertyDisplayConfiguration) {
    const property = this.propertyConfigs.find(property => property.id === config.entityPropertyId) as any;
    let listOption = property?.option ? property?.option : property?.optionArr;
    const configs = {
      ...config,
      type: property?.type,
      entityNameRef: property?.entityReferencePropertyName,
      description: property?.description,
      optionName: listOption ? listOption[config.option as any] : '',
    };

    return {
      columnName: configs.columnName,
      columnNameDescription: this.createPropertyDisplayDescription(
        configs,
        configs.option as string
      ),
      id: configs.id,
      entityPropertyId: configs.entityPropertyId,
      entityReferencePropertyId: configs.entityReferencePropertyId,
      propertyName: configs.propertyName,
      option: configs.option,
      description: configs.description
    };
  }

  getAvailablePropertyConfigs() {
    this.propertyConfigs = this.propertyConfigs.map(item => {
      let option: any = [];
      if (item.options) {
        Object.entries(item.options).forEach(([key, value]) => {
          option.push({
            key: key,
            value: value
          });
        })
      }
      return {
        ...item,
        option: option
      }
    })
    this.availablePropertyConfigs = (this.propertyConfigs as any).reduce(
      (result: Option[] | any, propertyConfig: any) => {
        const propertyConfigId = propertyConfig.entityPropertyId || propertyConfig.id;
        const listSelectedProperty = this.sortPropertySectionDataTable.filter(item => item.entityPropertyId === propertyConfigId);
        let isExistedProperty = !!listSelectedProperty?.length;
        let propertyOptions = propertyConfig?.optionArr ? propertyConfig.optionArr : propertyConfig.options
        if (listSelectedProperty?.length && propertyOptions?.length) {
            isExistedProperty = propertyOptions.length === listSelectedProperty.length;
        }
        if (isExistedProperty) {
          return result;
        }
        result.push({
          value: propertyConfigId,
          displayValue: propertyConfig.propertyName || propertyConfig.name,
          valueDescription: !propertyConfig?.entityReferencePropertyId
                            ? (propertyConfig?.configs?.description ? propertyConfig?.configs?.description : '')
                            : (`${propertyConfig?.entityReferencePropertyName + (propertyConfig?.configs?.description ? ' /' + propertyConfig?.configs?.description : '') }`),
          type: propertyConfig?.type,
          options: propertyConfig?.options,
          option: propertyConfig.option
        });
        return result;
      },
      [] as Option[]
    ).sort((a: any, b: any) => a?.displayValue.localeCompare(b?.displayValue))
  }

  changeItem(event: ReorderInfo, isSortColumn = false) {
    this.canSubmit = true;
    this.formValueChange.emit();
    if (isSortColumn) {
      this.sortPropertySectionDataTable = this.gridview.dataSource;
      return;
    }
    this.sortRowSectionDataTable = this.gridviewRow.dataSource;
  }

  onSoftDeleteSectionConfig(row: EntityPropertyDisplayConfiguration & Row): void {
    row.deleted = true;
    const sortItem = this.sortPropertySectionDataTable.find(
      (item) =>
        item.entityPropertyId === row.entityPropertyId &&
        item.entityReferencePropertyId === row.entityReferencePropertyId &&
        (!item.option || item.option === row.option)
    );
    if (sortItem) {
      sortItem.deleted = true;
    }

    const index = this.sortRowSectionDataTable.findIndex(
      (item) =>
        item.entityPropertyId === row.entityPropertyId &&
        item.entityReferencePropertyId === row.entityReferencePropertyId &&
        (!item.option || item.option === row.option)
    );
    if (index > -1) {
      this.sortRowSectionDataTable[index].deleted = true;
      this.sortRowSectionDataTable = [...this.sortRowSectionDataTable];
    }
  }

  onRowActions(
    event: { row: EntityPropertyDisplayConfiguration; type: string },
    isSortColumn = false
  ) {
    this.canSubmit = true;
    this.formValueChange.emit();
    switch (event.type) {
      case this.ACTION.ADD_SORT_ROW: {
        event.row.isUsed = true;
        this.addSortRow(event.row);
        const index = this.sortPropertySectionDataTable.findIndex(
          (item) =>
            item.entityPropertyId === event.row.entityPropertyId &&
            item.entityReferencePropertyId === event.row.entityReferencePropertyId &&
            (!item.option || item.option === event.row.option)
        );
        if (index > -1) {
          this.sortPropertySectionDataTable[index].isUsed = true;
          this.sortPropertySectionDataTable = [...this.sortPropertySectionDataTable];
        }
        break;
      }
      case this.ACTION.SORT_CHANGE: {
        this.changeSortType(event.row);
        break;
      }
      case this.ACTION.REMOVE: {
        event.row.deleted = true;
        if (isSortColumn) {
          const sortItem = this.sortPropertySectionDataTable.find(
            (item) =>
              item.entityPropertyId === event.row.entityPropertyId &&
              item.entityReferencePropertyId === event.row.entityReferencePropertyId &&
              (!item.option || item.option === event.row.option)
          );
          if (sortItem) {
            sortItem.deleted = true;
          }
        }

        const index = this.sortRowSectionDataTable.findIndex(
          (item) =>
            item.entityPropertyId === event.row.entityPropertyId &&
            item.entityReferencePropertyId === event.row.entityReferencePropertyId &&
            (!item.option || item.option === event.row.option)
        );
        if (index > -1) {
          this.sortRowSectionDataTable[index].deleted = true;
          this.sortRowSectionDataTable = [...this.sortRowSectionDataTable];
        }
      }
    }
  }

  resetAddPropertyForm() {
    this.formData.reset();
    this.propertyConfigs = this.defaultPropertyList;
    this.getAvailablePropertyConfigs();
    this.propertyOptions = [];
  }

  onCancel() {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: CANCEL_CONFIRM_MESSAGE,
        type: ConfirmType.Cancel,
        title: 'Cancel Action',
      },
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.getDataTable();
        this.getAvailablePropertyConfigs();
        this.resetAddPropertyForm();
        this.canSubmit = false;
      }
    });
  }

  changeProperty() {
    // format entityPropertyId_entityReferencePropertyId
    const arrId = this.formData.value.entityPropertyId.split('_');
    const selectedConfig = (this.propertyConfigs as any).find(
      (config: EntityPropertyDisplayConfiguration | any) => {
        if (arrId.length > 1) {
          return (
            config.entityPropertyId === arrId[0] || config.id === arrId[0]
          );
        } else {
          return (
            config.entityPropertyId === this.formData.value.entityPropertyId || config.id === this.formData.value.entityPropertyId
          );
        }
      }
    );
    let formValue: any = {
      option: '',
      columnName: selectedConfig?.propertyName || '',
      type: selectedConfig?.type,
      entityNameRef: selectedConfig?.entityReferencePropertyName,
      entityPropertyId: this.formData.value.entityPropertyId,
      entityReferencePropertyId : selectedConfig?.entityReferencePropertyId,
      description: selectedConfig?.configs?.description
    };

    let options: any[] = selectedConfig?.optionArr ? selectedConfig?.optionArr : selectedConfig?.options;
    this.propertyOptions = (options || []).reduce(
      (result: any, currentValue: any) => {
        const selectedOptions = this.sortPropertySectionDataTable.reduce(
          (selectedOptionResult, currentItem) => {
            this.sortPropertySectionDataTable.forEach((property) => {
              if (
                property.entityPropertyId === selectedConfig.id ||
                property.entityPropertyId === selectedConfig.entityPropertyId
              ) {
                selectedOptionResult.push(
                  ...property.option.split(VERTICAL_LINE_SEPARATOR)
                );
              }
            });
            return selectedOptionResult;
          },
          [] as any[]
        );
        let selectedItem: any = {
          value: currentValue.key,
          label: currentValue.value,
        };
        const checked = !!selectedOptions.find(
          (selectedOption: any) => selectedOption === currentValue.key
        );
        if (options?.length > 1 && checked) {
          return result;
        }
        result.push(selectedItem);
        return result;
      },
      [] as any[]
    );
    const firstUncheckedItem = (this.propertyOptions as any[]).find(
      (propertyOption: any) =>
        !propertyOption.checked && !propertyOption.disabled
    );
    if (firstUncheckedItem) {
      firstUncheckedItem.checked = true;
    }
    formValue = {
      ...formValue,
      option: firstUncheckedItem?.value || '',
      columnName: selectedConfig.propertyName,
      propertyName: selectedConfig.propertyName,
      entityPropertyId: this.formData.value.entityPropertyId,
      entityNameRef: selectedConfig?.entityReferencePropertyName,
      entityReferencePropertyId: selectedConfig?.entityReferencePropertyId,
      description: selectedConfig?.configs?.description,
      type: selectedConfig?.type
    };
    this.formData.patchValue(formValue);
  }

  addProperty() {
    this.formData.markAllAsTouched();
    if (!this.formData.valid) {
      return;
    }
    const formValue = this.formData.value;
    const optionName = this.getOptionName(formValue.option);
    const addedIndex = this.availablePropertyConfigs.findIndex(
      (item) =>
        item.value === formValue.entityPropertyId
    );
    let itemAdd;
    if (addedIndex > -1) {
      itemAdd = this.availablePropertyConfigs[addedIndex];
    }
    let arrId = formValue.entityPropertyId.split('_');
    var newRecord: EntityPropertyDisplayConfiguration = {
      columnName: formValue.columnName,
      columnNameDescription: this.createPropertyDisplayDescription(formValue, optionName),
      order: this.sortPropertySectionDataTable?.length + 1,
      id: undefined,
      propertyName: this.availablePropertyConfigs[addedIndex]?.displayValue,
      type: formValue.type,
      option: formValue.option,
      entityPropertyId: arrId[0],
      entityReferencePropertyId: arrId[1] ? arrId[1] : null,
      optionName: this.availablePropertyConfigs[addedIndex]?.displayValue,
      isUsed: !this.sortRowSection,
      columnNameMaxLength: this.columnNameMaxLength,
      cardId: this.cardId ?? null
    };

    this.sortPropertySectionDataTable = [
      ...this.sortPropertySectionDataTable,
      this.createInlineEditFormControls(newRecord),
    ];
    this.resetAddPropertyForm();
    this.canSubmit = true;
    this.formValueChange.emit();
  }

  getOptionName(options: string) {
    return (this.propertyOptions as any).find(
      (item: RadioOption) => item.value === options
    )?.label;
  }

  addSortRow(config: EntityPropertyDisplayConfiguration) {
    if (
      this.sortRowSectionDataTable.find(
        (item) =>
          item.entityPropertyId === config.entityPropertyId &&
          item.entityReferencePropertyId === config.entityReferencePropertyId &&
          (!item.option || item.option === config.option)
      )
    ) {
      return;
    }
    this.canSubmit = true;
    this.formValueChange.emit();
    this.sortRowSectionDataTable = [
      ...this.sortRowSectionDataTable,
      {
        ...this.getPropertyDisplayConfig(config),
        columnNameDescription: config.columnNameDescription,
        order: this.sortRowSectionDataTable?.length + 1,
        sortType: this.sortable ? SORT_TYPE.ASC : undefined,
      },
    ];
  }

  onSubmit() {
    if (this.gridview.formStatus !== AbstractControlStatus.VALID) {
      return;
    }
    const result = this.getSubmitData();

    this.onSubmitEvent.emit(result);
    this.resetAddPropertyForm();
    this.canSubmit = false;
  }

  getSubmitData() {
    let order = 1;
    const result: (EntityPropertyDisplayConfiguration & Row)[] =
      this.sortPropertySectionDataTable.map(
        (item: EntityPropertyDisplayConfiguration & Row) => {
          const obj = {
            id: item.id,
            entityPropertyId: item.entityPropertyId,
            entityReferencePropertyId: item.entityReferencePropertyId,
            option: item.option ? item.option : '',
            columnName: item.form?.value?.columnName,
            orderColumn: item.deleted ? -1 : order,
            cardId: item?.cardId ?? null
          };
          order += item.deleted ? 0 : 1;
          return obj;
        }
      );
    order = 1;
    this.sortRowSectionDataTable.forEach((config) => {
      const index = result.findIndex(
        (item) =>
          item.entityPropertyId === config.entityPropertyId &&
          item.entityReferencePropertyId === config.entityReferencePropertyId &&
          (!item.option || item.option === config.option)
      );
      if (index < 0) {
        return;
      }
      result[index].orderRow = config.deleted ? -1 : order;
      order += 1;
      result[index].sortType = config.sortType;
    });
    return result;
  }

  changeSortType(row: EntityPropertyDisplayConfiguration) {
    const index = this.sortRowSectionDataTable.findIndex(
      (item) =>
        item.entityPropertyId === row.entityPropertyId &&
        item.entityReferencePropertyId === row.entityReferencePropertyId &&
        (!item.option || item.option === row.option)
    );
    if (index < 0) {
      return;
    }
    this.sortRowSectionDataTable[index].sortType =
      this.sortRowSectionDataTable[index].sortType === SORT_TYPE.ASC
        ? SORT_TYPE.DESC
        : SORT_TYPE.ASC;
    this.sortRowSectionDataTable = [...this.sortRowSectionDataTable];
  }

  checkDuplicated(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.sortPropertySectionDataTable?.length) {
        return null;
      }
      if (
        this.sortPropertySectionDataTable?.find(
          (item: { columnName: string }) =>
            item.columnName?.trim()?.toLowerCase() ===
            control.value?.trim()?.toLowerCase()
        )
      ) {
        return {
          duplicatedValue: 'Column Name already exists.',
        };
      }
      return null;
    };
  }

  isExisted(
    item:
      | EntityPropertyDisplayConfiguration
      | EntityPropertyDisplayConfig,
    config:
      | EntityPropertyDisplayConfiguration
      | EntityPropertyDisplayConfig,
    checkSelectMultipleTimes: boolean = true
  ) {
    const hasOptions =
    (config.options instanceof Array &&
      config.options &&
      config.options.length > 1) ||  (config.optionArr instanceof Array &&
        config.optionArr &&
        config.optionArr.length > 1);
    let optionConfig = config.optionArr ? config.optionArr : config.options;
    const hasUnselectedOptions = !((optionConfig || []) as any[]).some(
      (option: any) =>
        !this.propertyDisplayConfigurations.some(
          (property) => {
            ( property.entityPropertyId === (config as any).id ) &&
            property.option
              ?.split(VERTICAL_LINE_SEPARATOR)
              ?.includes(option.key)
          }
        )
    );
    const isExistedProperty = item.entityPropertyId === config.id || item.entityPropertyId === config.entityPropertyId;
    const isAllOptionSelected = hasOptions
      ? hasUnselectedOptions
      : isExistedProperty;
    if (checkSelectMultipleTimes) {
      return (isAllOptionSelected);
    }
    return isAllOptionSelected;
  }

  onChangeOrderColumns() {
    this.canSubmit = true;
    this.formValueChange.emit();
  }

  private checkExits(obj: EntityPropertyDisplayConfiguration): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const allRows = [...this.sortPropertySectionDataTable];
      const existed = allRows.some(
        (item) =>
          !(
            item.entityPropertyId === obj.entityPropertyId &&
            item.entityReferencePropertyId === obj.entityReferencePropertyId &&
            (!item.option || item.option === obj.option)
          ) &&
          item.form?.value?.columnName?.toLowerCase()?.trim() ===
            control.value.toLowerCase().trim()
      );
      return existed ? { existed: true } : null;
    };
  }

  getType(entityPropertyType: EntityPropertyType): string {
    if (
      entityPropertyType === EntityPropertyType.Calculation ||
      entityPropertyType === EntityPropertyType.System ||
      entityPropertyType === EntityPropertyType.Aggregation ||
      entityPropertyType === EntityPropertyType['Entity Reference'] ||
      entityPropertyType === EntityPropertyType.Identifier
    ) {
      return EntityPropertyType[entityPropertyType];
    } else {
      return 'Data';
    }
  }

  private createInlineEditFormControls(
    obj: EntityPropertyDisplayConfiguration
  ): EntityPropertyDisplayConfiguration {
    var result = {
      ...obj,
      form: this.fb.group({
        columnName: new FormControl(
          obj.columnName,
          getValidatorsFromColumns(
            this.orderColumns[0].name,
            this.orderColumns,
            obj
          )
        ),
      }),
    };
    return result;
  }
}
