import { ENTITY_ID_GUID } from '@ptg-shared/constance';
import {
  CreateEntityListDataRequest,
  CreateEntityListData,
  EntityInitiationPropertyValue,
} from './services/models';
import { getLocalDate } from '@ptg-shared/utils/string.util';

function transformEntityProperty(entityPropertyValues: EntityInitiationPropertyValue[], data: Record<string, any>) {
  entityPropertyValues.forEach((i) => {
    if (i.isComputedProperty) {
      return;
    }
    
    if (i.entityPropertyReferenceValue) {
      data[i.entityPropertyId] = {
        entityId: i.entityPropertyReferenceValue.entityId,
        data: {
          [ENTITY_ID_GUID]: i.entityPropertyReferenceValue.recordId,
          ...transformEntityProperty(i.entityPropertyReferenceValue.entityPropertyValues, {})
        }
      };
    } else {
      data[i.entityPropertyId] = i.value;
    }
    if (i.recordId) {
      data[ENTITY_ID_GUID] = i.recordId;
    }
  });
  return data;
}

export function transformEntityListData({ addToTop, recordId, entityPropertyValues }: CreateEntityListDataRequest) {
  const newData: CreateEntityListData = {
    addToTop,
    data:  {
      ...transformEntityProperty(entityPropertyValues, {}),
      [ENTITY_ID_GUID]: recordId || null
    }
  };
  return newData;
}

export function transformEntityToObject(request: any) {
  const newData: any = {
    entityId: request?.entityId,
    entityData: transformEntityProperty(request?.entityPropertyValues, {}),
    localDate: getLocalDate(),
  };
  return newData;
}
