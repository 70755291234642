import { Action, combineReducers, createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromMemberNavigation from './member-navigation.reducer';
import * as fromProfileHeaderConfiguration from './profile-header-configuration.reducer';
import * as fromAggregationConfig from './aggregation-config.reducer';
import * as fromAggregationGroupFeatureKey from './aggregation-group.reducer';
import * as fromDefinedBenefits from './defined-benefits.reducer';
import * as fromLookupTable from './lookup-table.reducer';
import * as fromMemberBenefitList from './member-benefit.reducer';
import * as fromMemberDocument from './member-document.reducer';
import * as fromMemberDetail from './member-detail.reducer';
import * as fromMemberListConfiguration from './member-list-configuration.reducer';
import * as fromMemberList from './member-list.reducer';
import * as fromMemberNotificationOptIn from './member-notification-opt-in.reducer';
import * as fromMemberPropertyFeatureKey from './member-property.reducer';
import * as fromMetadataSection from './metadata-detail.reducer';
import * as fromMetadata from './metadata.reducer';
import * as fromMunicipalityPayment from './municipality-payment.reducer';
import * as fromMunicipalityServiceRecord from './municipality-service-history.reducer';
import * as fromNexPayment from './next-payment.reducer';
import * as fromNonListConfigurations from './non-list-configuration.reducer';
import * as fromParticipantRelationship from './participant-relationship.reducer';
import * as fromParticipantSearchConfiguration from './participant-search-configuration.reducer';
import * as fromReport from './report.reducer';
import * as fromPaymentListConfigurations from './payment-list-configuration.reducer';
import * as fromProfileNavigationConfiguration from './profile-navigation-configuration.reducer';
import * as fromProfileOverviewConfiguration from './profile-overview-configuration.reducer';
import * as fromNewProperty from './property.reducer';
import * as fromStatusHistory from './status-history.reducer';
import * as fromAddressHistory from './address-history.reducer';
import * as fromRelatedPerson from './related-person.reducer';
import * as fromTotalServices from './total-services.reducer';
import * as fromStatus from './status.reducer';
import * as fromTierConfigurations from './tier-configuration.reducer';
import * as fromCalculations from './calculation.reducer';
import * as fromParticipantReport from './participant-report.reducer';
import * as fromDropPlan from './drop-plan.reducer';
import * as fromCart from './cart.reducer';
import * as fromParticipantNavigationConfiguration from './participant-navigation-configuration.reducer';
import * as fromHeader from './header.reducer';
import * as fromDistributionMapping from './distributionMapping.reducer';
import * as fromParticipantDocuments from './participant-documents.reducer';

import { MODULE_KEY } from '../../constants';
import { ProfileConfigState, profileConfigReducer } from './profile-configuration.reducer';
import { ViewState, viewReducer } from './view.reducer';
import { HeaderState, headerReducer } from './header.reducer';
import { MemberDataState, memberDataReducer } from './member.reducer';
import { DepositAccountState, depositAccountReducer } from './deposit-account.reducer';

export interface MemberState {
  [fromMemberList.memberListFeatureKey]: fromMemberList.State;
  [fromMemberBenefitList.memberBenefitListFeatureKey]: fromMemberBenefitList.State;
  [fromMemberDocument.memberDocumentFeatureKey]: fromMemberDocument.DocumentListState;
  [fromDefinedBenefits.definedBenefitsFeatureKey]: fromDefinedBenefits.State;
  [fromReport.reportFeatureKey]: fromReport.State;
  [fromMemberDetail.memberDetailFeatureKey]: fromMemberDetail.State;
  [fromMetadata.metadataFeatureKey]: fromMetadata.State;
  [fromMetadataSection.metadataSectionFeatureKey]: fromMetadataSection.State;
  [fromNewProperty.AddPropertyFeatureKey]: fromNewProperty.State;
  [fromNexPayment.nextPaymentFeatureKey]: fromNexPayment.State;
  [fromLookupTable.lookupTableFeatureKey]: fromLookupTable.State;
  [fromStatus.memberStatusFeatureKey]: fromStatus.State;
  [fromProfileOverviewConfiguration.profileOverviewConfigurationFeatureKey]: fromProfileOverviewConfiguration.State;
  [fromProfileHeaderConfiguration.profileHeaderConfigurationFeatureKey]: fromProfileHeaderConfiguration.State;
  [fromMemberNavigation.memberNavigationFeatureKey]: fromMemberNavigation.State;
  [fromMemberListConfiguration.memberListConfigurationFeatureKey]: fromMemberListConfiguration.State;
  [fromProfileNavigationConfiguration.profileNavigationConfigurationFeatureKey]: fromProfileNavigationConfiguration.State;
  [fromAggregationConfig.aggregationConfigFeatureKey]: fromAggregationConfig.State;
  [fromStatusHistory.StatusHistoryFeatureKey]: fromStatusHistory.State;
  [fromAddressHistory.AddressHistoryFeatureKey]: fromAddressHistory.State;
  [fromParticipantDocuments.ParticipantDocumentsFeatureKey]: fromParticipantDocuments.State;
  [fromRelatedPerson.RelatedPersonFeatureKey]: fromRelatedPerson.State;
  [fromTotalServices.TotalServicesFeatureKey]: fromTotalServices.State;
  [fromMunicipalityPayment.MunicipalityPaymentFeatureKey]: fromMunicipalityPayment.State;
  [fromMunicipalityServiceRecord.MunicipalityServiceHistoryFeatureKey]: fromMunicipalityServiceRecord.State;
  [fromAggregationGroupFeatureKey.aggregationGroupFeatureKey]: fromAggregationGroupFeatureKey.State;
  [fromMemberPropertyFeatureKey.memberPropertyFeatureKey]: fromMemberPropertyFeatureKey.State;
  [fromMemberPropertyFeatureKey.memberPropertyFeatureKey]: fromMemberPropertyFeatureKey.State;
  [fromParticipantSearchConfiguration.participantSearchConfigurationFeatureKey]: fromParticipantSearchConfiguration.State;
  [fromParticipantRelationship.participantRelationshipFeatureKey]: fromParticipantRelationship.State;
  [fromMemberNotificationOptIn.memberNotificationOptInFeatureKey]: fromMemberNotificationOptIn.State;
  [fromPaymentListConfigurations.paymentListConfigurationFeatureKey]: fromPaymentListConfigurations.State;
  [fromNonListConfigurations.nonListConfigurationFeatureKey]: fromNonListConfigurations.State;
  [fromTierConfigurations.tierConfigurationFeatureKey]: fromTierConfigurations.State;
  [fromCalculations.calculationFeatureKey]: fromCalculations.State;
  [fromParticipantReport.participantReportFeatureKey]: fromParticipantReport.State;
  [fromDropPlan.dropPlanFeatureKey]: fromDropPlan.State;
  [fromCart.cartFeatureKey]: fromCart.State;
  [fromParticipantNavigationConfiguration.navigationConfigurationKey]: fromParticipantNavigationConfiguration.State;
  [fromDistributionMapping.distributionMappingKey]: fromDistributionMapping.State;
  entityView: ViewState;
  entityHeader: HeaderState;
  profileConfig: ProfileConfigState;
  memberData: MemberDataState;
  depositAccount: DepositAccountState;
}

export function memberReducer(state: MemberState | undefined, action: Action) {
  return combineReducers({
    [fromMemberList.memberListFeatureKey]: fromMemberList.reducer,
    [fromMemberBenefitList.memberBenefitListFeatureKey]: fromMemberBenefitList.reducer,
    [fromMemberDocument.memberDocumentFeatureKey]: fromMemberDocument.documentListReducer,
    [fromDefinedBenefits.definedBenefitsFeatureKey]: fromDefinedBenefits.reducer,
    [fromReport.reportFeatureKey]: fromReport.reducer,
    [fromMemberDetail.memberDetailFeatureKey]: fromMemberDetail.reducer,
    [fromMetadata.metadataFeatureKey]: fromMetadata.reducer,
    [fromMetadataSection.metadataSectionFeatureKey]: fromMetadataSection.reducer,
    [fromNewProperty.AddPropertyFeatureKey]: fromNewProperty.reducer,
    [fromNexPayment.nextPaymentFeatureKey]: fromNexPayment.reducer,
    [fromLookupTable.lookupTableFeatureKey]: fromLookupTable.reducer,
    [fromStatus.memberStatusFeatureKey]: fromStatus.reducer,
    [fromProfileOverviewConfiguration.profileOverviewConfigurationFeatureKey]: fromProfileOverviewConfiguration.reducer,
    [fromProfileHeaderConfiguration.profileHeaderConfigurationFeatureKey]: fromProfileHeaderConfiguration.reducer,
    [fromMemberNavigation.memberNavigationFeatureKey]: fromMemberNavigation.reducer,
    [fromMemberListConfiguration.memberListConfigurationFeatureKey]: fromMemberListConfiguration.reducer,
    [fromProfileNavigationConfiguration.profileNavigationConfigurationFeatureKey]:
      fromProfileNavigationConfiguration.reducer,
    [fromAggregationConfig.aggregationConfigFeatureKey]: fromAggregationConfig.reducer,
    [fromStatusHistory.StatusHistoryFeatureKey]: fromStatusHistory.reducer,
    [fromAddressHistory.AddressHistoryFeatureKey]: fromAddressHistory.reducer,
    [fromParticipantDocuments.ParticipantDocumentsFeatureKey]: fromParticipantDocuments.reducer,
    [fromRelatedPerson.RelatedPersonFeatureKey]: fromRelatedPerson.reducer,
    [fromTotalServices.TotalServicesFeatureKey]: fromTotalServices.reducer,
    [fromMunicipalityPayment.MunicipalityPaymentFeatureKey]: fromMunicipalityPayment.reducer,
    [fromMunicipalityServiceRecord.MunicipalityServiceHistoryFeatureKey]: fromMunicipalityServiceRecord.reducer,
    [fromAggregationGroupFeatureKey.aggregationGroupFeatureKey]: fromAggregationGroupFeatureKey.reducer,
    [fromMemberPropertyFeatureKey.memberPropertyFeatureKey]: fromMemberPropertyFeatureKey.reducer,
    [fromParticipantSearchConfiguration.participantSearchConfigurationFeatureKey]:
      fromParticipantSearchConfiguration.reducer,
    [fromParticipantRelationship.participantRelationshipFeatureKey]: fromParticipantRelationship.reducer,
    [fromMemberNotificationOptIn.memberNotificationOptInFeatureKey]: fromMemberNotificationOptIn.reducer,
    [fromPaymentListConfigurations.paymentListConfigurationFeatureKey]: fromPaymentListConfigurations.reducer,
    [fromNonListConfigurations.nonListConfigurationFeatureKey]: fromNonListConfigurations.reducer,
    [fromTierConfigurations.tierConfigurationFeatureKey]: fromTierConfigurations.reducer,
    [fromCalculations.calculationFeatureKey]: fromCalculations.reducer,
    [fromParticipantReport.participantReportFeatureKey]: fromParticipantReport.reducer,
    [fromDropPlan.dropPlanFeatureKey]: fromDropPlan.reducer,
    [fromCart.cartFeatureKey]: fromCart.reducer,
    [fromParticipantNavigationConfiguration.navigationConfigurationKey]: fromParticipantNavigationConfiguration.reducer,
    [fromDistributionMapping.distributionMappingKey]: fromDistributionMapping.reducer,
    entityView: viewReducer,
    entityHeader: headerReducer,
    profileConfig: profileConfigReducer,
    memberData: memberDataReducer,
    depositAccount: depositAccountReducer
  })(state, action);
}

// TODO Move to selectors
export const selectMemberModuleState = createFeatureSelector<MemberState>(MODULE_KEY);

export const selectMemberListState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberList.memberListFeatureKey],
);

export const selectMemberBenefitListState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberBenefitList.memberBenefitListFeatureKey],
);
export const selectMemberEstablishBenefitState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberBenefitList.memberBenefitListFeatureKey].establishBenefit,
);

// Member Document List
export const selectMemberDocumentState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDocument.memberDocumentFeatureKey],
);

export const selectOrganizationDocumentListState = createSelector(
  selectMemberDocumentState,
  (state) => state.getOrganizationDocumentLists,
);

export const selectRemoveOrganizationDocumentState = createSelector(
  selectMemberDocumentState,
  (state) => state.removeOrganizationDocuments,
);

export const selectUploadOrganizationDocumentState = createSelector(
  selectMemberDocumentState,
  (state) => state.uploadOrganizationDocuments,
);

export const selectMemberDocumentListState = createSelector(
  selectMemberDocumentState,
  (state) => state.getDocumentLists,
);

export const selectCheckMemberGeneratingDocumentState = createSelector(
  selectMemberDocumentState,
  (state) => state.checkGeneratingDocument,
);

export const selectRemoveMemberDocumentState = createSelector(
  selectMemberDocumentState,
  (state) => state.removeDocuments,
);

export const selectUploadMemberDocumentState = createSelector(
  selectMemberDocumentState,
  (state) => state.uploadDocuments,
);

export const selectEditMemberDocumentState = createSelector(selectMemberDocumentState, (state) => state.editDocuments);

//#region Defined Benefits
export const selectDefinedBenefitsState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey],
);
export const selectGetAuditTrailsState = createSelector(
  selectMemberModuleState,
  (state) => state[fromStatusHistory.StatusHistoryFeatureKey].getAuditTrails,
);

export const selectTotalServicesState = createSelector(
  selectMemberModuleState,
  (state) => state[fromTotalServices.TotalServicesFeatureKey].totalServices,
);

export const selectGetBenefitTypesState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].getBenefitTypes,
);
export const selectGetAllBenefitTypesState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].getAllBenefitTypes,
);
export const selectGetBenefitState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].getBenefit,
);
export const selectGetStatusAndEventsState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].getStatusAndEvents,
);
export const selectGetBenefitRecordsState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].getBenefitRecords,
);
export const selecGetLookupTableOptionsState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].getLookupTableOptions,
);
export const selecGetLookupTablesState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].getLookupTables,
);
export const selectGetQDROBenefitTypesState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].getQDROBenefitTypes,
);
export const selectGetQDROMetadatasState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].getQDROMetadatas,
);
export const selectGetLookupTablesState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].getLookupTables,
);
export const selectAddBenefitTypeState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].addBenefitType,
);
export const selectUpdateBenefitTypeState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].updateBenefitType,
);
export const selectUpdateBenefitState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].updateBenefit,
);
export const selectRemoveBenefitTypeState = createSelector(
  selectMemberModuleState,
  (state) => state[fromDefinedBenefits.definedBenefitsFeatureKey].removeBenefitType,
);
//#endregion
//#region Report
export const selectGetReportCategoriesState = createSelector(
  selectMemberModuleState,
  (state) => state[fromReport.reportFeatureKey].getReportCategories,
);
export const selectUpdateReportCategoriesState = createSelector(
  selectMemberModuleState,
  (state) => state[fromReport.reportFeatureKey].updateReportCategories,
);

//#endregion
export const selectMemberDetailWithPagingState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].memberDetailWithPagingState,
);
export const selectMemberAttachmentListSection = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].memberAttachmentsListSection,
);

export const selectMemberDetailState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey],
);

export const selectMemberDataSummaryState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].memberDataSummary,
);

export const selectFiscalDetailState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].memberDetail?.fiscal,
);

export const selectFiscalHistoryDetailState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].memberDetail?.fiscalHistory,
);

export const selectBenefitDetailState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].memberDetail?.benefit,
);

export const selectDROPDetailState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].memberDetail?.drop,
);

export const selectBeneficiaryDetailState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].memberDetail?.beneficiary,
);

export const selectDemographicDetailState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].memberDetail?.demographics,
);

export const selectAddSectionState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].addSectionState,
);

export const selectPayrollConfigurationInfoState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].payrollConfigurationInfo,
);

export const selectMetadataState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMetadata.metadataFeatureKey],
);

export const selectMetadataSectionState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMetadataSection.metadataSectionFeatureKey],
);

export const selectPropertyType = createSelector(
  selectMemberModuleState,
  (state) => state[fromNewProperty.AddPropertyFeatureKey],
);

export const selectPropertyTypeCollectionState = createSelector(
  selectMemberModuleState,
  (state) => state[fromNewProperty.AddPropertyFeatureKey]?.propertyTypes,
);

export const selectSelectedPropertyTypeState = createSelector(
  selectMemberModuleState,
  (state) => state[fromNewProperty.AddPropertyFeatureKey]?.selectedPropTypeValue,
);

export const selectMemberInfo = createSelector(selectMemberDetailState, (state) => state.memberInfo);

export const selectMemberData = createSelector(selectMemberDetailState, (state) => {
  return {
    isLoading: state.isLoading,
    isLoadingMemberInfo: state.isLoadingMemberInfo,
    isLoadingMetadataOverview: state.isLoadingMetadataOverview,
    isDeleting: state.isDeleting,
    memberDetail: state.memberDetail,
    memberInfo: state.memberInfo,
    metadataOverview: state.metadataOverview,
    profileOverviewName: state.profileOverviewName,
    profileOverviewConfig: state.profileOverviewConfig,
  };
});

export const selectMemberSummaryData = createSelector(selectMemberDetailState, (state) => {
  return {
    isLoadingView: state.entityViewItems?.isLoading,
    isLoadingMemberData: state.memberDataSummary?.isLoading,
    memberDataSummary: state.memberDataSummary?.payload,
  };
});

export const selectMemberDataUnMasked = createSelector(selectMemberDetailState, (state) => state.memberDetailUnMasked);

export const selectMetaDataState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey],
);

export const selectMetaData = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].metadata,
);

export const selectMetaDataNonList = createSelector(selectMetaDataState, (state) => state.getMetadataNonList);

export const selectMetadataOverview = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].metadataOverview,
);

export const selectEntityViewItems = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].entityViewItems,
);

export const selectIsLocked = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].memberInfo?.isLocked,
);

export const selectMemberMetadataAttachmentState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].memberMetadataAttachmentState,
);

export const selectNextPayment = createSelector(
  selectMemberModuleState,
  (state) => state[fromNexPayment.nextPaymentFeatureKey],
);

export const getCardsSelector = createSelector(selectMetaDataState, (state) => state?.getGetCardsByView);

export const getAllListPropertyForCardsSelector = createSelector(
  selectMetaDataState,
  (state) => state?.getAllListPropertyForCards,
);

export const getListPropertyForCardsSelector = createSelector(
  selectMetaDataState,
  (state) => state?.getListPropertyForCards,
);

export const getListDataSnapshotSelector = createSelector(selectMetaDataState, (state) => state?.getListDataSnapshot);

export const getStandAlonePropertyOnlyCardDataSelector = createSelector(
  selectMetaDataState,
  (state) => state?.getStandAlonePropertyOnlyCardData,
);

export const getMemberEntityAttachmentsSelector = createSelector(
  selectMetaDataState,
  (state) => state?.getMemberEntityAttachments,
);

export const removeMemberEntityAttachmentsSelector = createSelector(
  selectMetaDataState,
  (state) => state?.removeMemberEntityAttachments,
);

export const createUploadMemberEntityAttachmentsSelector = createSelector(
  selectMetaDataState,
  (state) => state?.createUploadMemberEntityAttachments,
);

export const checkRemoveExitAttachmentSelector = createSelector(
  selectMetaDataState,
  (state) => state?.removeExitAttachment,
);

//-----------

export const selectNextPaymentHistory = createSelector(selectNextPayment, (state) => state.paymentHistoryList);

export const selectNextPaymentDeduction = createSelector(selectNextPayment, (state) => state.deductions);

export const selectNextPaymentNotes = createSelector(selectNextPayment, (state) => state.paymentInfoNotes);

export const selectNextPaymentEarnings = createSelector(selectNextPayment, (state) => state.earnings);

export const selectNextPaymentRecalculateTotalDeductions = createSelector(
  selectNextPayment,
  (state) => state.recalculateTotalDeductions,
);

export const selectNextPaymentSettings = createSelector(selectNextPayment, (state) => state.settings);

export const selectNextPaymentPayment = createSelector(selectNextPayment, (state) => state.payments);

export const selectPendingPaymentState = createSelector(selectNextPayment, (state) => state.pendingPaymentList);

export const selectIssueOffCyclePaymentState = createSelector(selectNextPayment, (state) => state.issueOffCyclePayment);

export const selectMemberAddressItemsState = createSelector(selectNextPayment, (state) => state.memberAddressItems);

export const selectRepresentativePayeesState = createSelector(selectNextPayment, (state) => state.representativePayees);

export const selectPaymentDeductionsState = createSelector(selectNextPayment, (state) => state.paymentDeductions);

export const selectCreateOffCycleState = createSelector(selectNextPayment, (state) => state.createOffCycleState);

export const selectReloadPendingPaymentsStateState = createSelector(
  selectNextPayment,
  (state) => state.reloadPendingPaymentsState,
);

export const selectParticipantNextPaymentSetting = createSelector(
  selectNextPayment,
  (state) => state.participantNextPaymentSetting,
);

export const selectWithdrawOffCyclePayment = createSelector(
  selectNextPayment,
  (state) => state.withdrawOffCyclePayment,
);

export const selectRemoveOffCyclePaymentState = createSelector(
  selectNextPayment,
  (state) => state.removeOffCyclePaymentState,
);

export const selectSetOffCyclePaymentPayPeriodState = createSelector(
  selectNextPayment,
  (state) => state.setOffCyclePaymentPayPeriod,
);

export const selectLookupTable = createSelector(
  selectMemberModuleState,
  (state) => state[fromLookupTable.lookupTableFeatureKey],
);

export const selectDisplayFormatList = createSelector(
  selectLookupTable,
  (state) => state.metadataLookupTableDisplayFormats,
);

export const createDisplayFormatState = createSelector(selectLookupTable, (state) => state.createDisplayFormatState);

export const editDisplayFormatState = createSelector(selectLookupTable, (state) => state.editDisplayFormatState);

export const selectLookupTableList = createSelector(selectLookupTable, (state) => state.lookupTableList);

export const selectLookupTableDetailState = createSelector(selectLookupTable, (state) => state.lookupTableDetailState);

export const selectCreateLookupTableState = createSelector(selectLookupTable, (state) => state.createState);

export const selectEditLookupTableState = createSelector(selectLookupTable, (state) => state.updateState);

export const selectCreateLookupTableValueState = createSelector(
  selectLookupTable,
  (state) => state.lookupTableDetailState.createState,
);

export const selectEditLookupTableValueState = createSelector(
  selectLookupTable,
  (state) => state.lookupTableDetailState.updateState,
);

export const selectRemoveLookupTableValueState = createSelector(
  selectLookupTable,
  (state) => state.lookupTableDetailState.removeState,
);

export const selectSessionDetailState = createSelector(selectLookupTable, (state) => state.importState);
export const selectMemberStatus = createSelector(
  selectMemberModuleState,
  (state) => state[fromStatus.memberStatusFeatureKey],
);

export const selectMemberStatusList = createSelector(selectMemberStatus, (state) => state.statusList);

export const selectStatusDetail = createSelector(selectMemberStatus, (state) => state.statusDetailState);

export const selectEditStatusState = createSelector(selectMemberStatus, (state) => state.editStatusState);

export const selectRemoveStatusState = createSelector(selectMemberStatus, (state) => state.removeStatusState);

export const selectCreateEventState = createSelector(selectMemberStatus, (state) => state.createEventState);

export const selectEditEventState = createSelector(selectMemberStatus, (state) => state.editEventState);

export const selectRemoveEventState = createSelector(selectMemberStatus, (state) => state.removeEventState);

export const selectListStatus = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].listStatus,
);
export const selectMemberListConfiguration = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberListConfiguration.memberListConfigurationFeatureKey],
);

export const selectPropertyConfigs = createSelector(selectMemberListConfiguration, (state) => state?.propertyConfigs);

export const selectPropertyDisplayConfigurations = createSelector(
  selectMemberListConfiguration,
  (state) => state?.propertyDisplayConfigurations,
);

export const selectUpdateState = createSelector(selectMemberListConfiguration, (state) => state?.updateState);

export const selectListSectionProperty = createSelector(selectMemberListState, (state) => state?.listSectionProperty);
export const selectMemberNavigationFeatureState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberNavigation.memberNavigationFeatureKey],
);
export const selectMemberNavigationState = createSelector(
  selectMemberNavigationFeatureState,
  (state) => state.memberNavigateState,
);
export const selectFiltersState = createSelector(selectMemberListState, (state) => state.filters);
export const selectListFilterInfo = createSelector(selectMemberListState, (state) => state.listFilterInfo);
export const selectRemoveMemberFilterState = createSelector(
  selectMemberListState,
  (state) => state.removeMemberFilterState,
);
export const selectUpdateFilterNameState = createSelector(
  selectMemberListState,
  (state) => state.updateFilterNameState,
);

export const selectProfileOverviewConfigurationState = createSelector(
  selectMemberModuleState,
  (state) => state[fromProfileOverviewConfiguration.profileOverviewConfigurationFeatureKey],
);

export const selectLoadingState = createSelector(selectProfileOverviewConfigurationState, (state) => state.isLoading);

export const selectProfileOverviewList = createSelector(
  selectProfileOverviewConfigurationState,
  (state) => state.profileOverviewList,
);

export const selectGetSectionMetadatas = createSelector(
  selectProfileOverviewConfigurationState,
  (state) => state.getSectionMetadatas,
);

export const selectProfileOverviewDetail = createSelector(
  selectProfileOverviewConfigurationState,
  (state) => state.getProfileOverviewDetail,
);

export const selectStatusNotUsedList = createSelector(
  selectProfileOverviewConfigurationState,
  (state) => state.getNotUsedStatuses,
);

export const selectSetProfileOverviewState = createSelector(
  selectProfileOverviewConfigurationState,
  (state) => state.setProfileOverview,
);

export const selectProfileOverviewConfigurationId = createSelector(
  selectProfileOverviewConfigurationState,
  (state) => state.id,
);

export const selectProfileOverviewConfigurationsDeleteState = createSelector(
  selectProfileOverviewConfigurationState,
  (state) => state.deleteState,
);

export const selectProfileHeaderConfigurationState = createSelector(
  selectMemberModuleState,
  (state) => state[fromProfileHeaderConfiguration.profileHeaderConfigurationFeatureKey],
);
export const selectProfileHeaderConfiguration = createSelector(
  selectMemberModuleState,
  (state) => state[fromProfileHeaderConfiguration.profileHeaderConfigurationFeatureKey],
);
export const selectProfileHeaderList = createSelector(
  selectProfileHeaderConfigurationState,
  (state) => state.getProfileHeaderConfigList,
);
export const selectProfileHeaderConfigurationsDeleteState = createSelector(
  selectProfileHeaderConfigurationState,
  (state) => state.deleteState,
);

export const selectMembersStatusNotUsedList = createSelector(
  selectProfileHeaderConfigurationState,
  (state) => state.statusNotUsedList,
);

export const selectProfileHeaderPropertyConfigs = createSelector(
  selectProfileHeaderConfiguration,
  (state) => state?.propertyConfigs,
);

export const selectProfileHeaderPropertyDisplayConfigurations = createSelector(
  selectProfileHeaderConfiguration,
  (state) => state?.profileHeader,
);

export const selectProfileHeaderUpdateState = createSelector(
  selectProfileHeaderConfiguration,
  (state) => state?.updateState,
);

export const selectProfileHeaders = createSelector(selectProfileHeaderConfiguration, (state) => state?.profileHeaders);

export const selectProfileHeaderConfig = createSelector(
  selectProfileHeaderConfiguration,
  (state) => state?.headerPropertyConfig,
);

export const selectProfileSubHeaderConfig = createSelector(
  selectProfileHeaderConfiguration,
  (state) => state?.subHeaderPropertyConfig,
);

export const selectMemberDetail = createSelector(selectProfileHeaderConfiguration, (state) => state?.memberDetail);

export const selectMemberId = createSelector(selectProfileHeaderConfiguration, (state) => state?.memberStatusId);

export const selectProfileHeaderConfigurationId = createSelector(
  selectProfileHeaderConfigurationState,
  (state) => state.id,
);

export const selectProfileNavigationConfigurationState = createSelector(
  selectMemberModuleState,
  (state) => state[fromProfileNavigationConfiguration.profileNavigationConfigurationFeatureKey],
);

export const selectMemberNavigationsState = createSelector(
  selectProfileNavigationConfigurationState,
  (state) => state.memberNavigations,
);

export const selectMemberNavigationDetailState = createSelector(
  selectProfileNavigationConfigurationState,
  (state) => state.memberNavigationDetailState,
);

export const selectMemberNavigationSectionListState = createSelector(
  selectProfileNavigationConfigurationState,
  (state) => state.listSection,
);

//#region Non List Configuration
const selectNonListConfigurationState = createSelector(
  selectMemberModuleState,
  (state) => state[fromNonListConfigurations.nonListConfigurationFeatureKey],
);

export const selectGetNonListPropertiesState = createSelector(
  selectNonListConfigurationState,
  (state) => state.getNonListProperties,
);

export const selectGetNonListItemConfigsState = createSelector(
  selectNonListConfigurationState,
  (state) => state.getNonListItemConfigs,
);

export const selectSaveNonListItemConfigsState = createSelector(
  selectNonListConfigurationState,
  (state) => state.saveNonListItemConfigs,
);
//#endregion

// Aggregation Configuration
export const selectAggregationConfigState = createSelector(
  selectMemberModuleState,
  (state) => state[fromAggregationConfig.aggregationConfigFeatureKey],
);

export const selectMunicipalityPaymentState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMunicipalityPayment.MunicipalityPaymentFeatureKey],
);

export const selectMunicipalityListState = createSelector(selectMunicipalityPaymentState, (state) => state.payments);
export const selectEditMetRequirementState = createSelector(selectMunicipalityPaymentState, (state) => {
  return {
    editMetRequirementState: state.editMetRequirementState,
    editMetRequirementResponse: state.editMetRequirementResponse,
  };
});
export const selectAddDisabilityPaymentState = createSelector(
  selectMunicipalityPaymentState, 
  (state) => state.addDisabilityPaymentState
);
export const selectGetDisabilityPaymentsState = createSelector(
  selectMunicipalityPaymentState, 
  (state) => state.disabilityPayments
);

export const selectStatusHistoryState = createSelector(
  selectMemberModuleState,
  (state) => state[fromStatusHistory.StatusHistoryFeatureKey],
);

export const selectAddressHistoryState = createSelector(
  selectMemberModuleState,
  (state) => state[fromAddressHistory.AddressHistoryFeatureKey],
);

export const selectAddressHistoryExitState = createSelector(
  selectMemberModuleState,
  (state) => state[fromAddressHistory.AddressHistoryFeatureKey].messages,
);

export const selectParticipantDocumentsState = createSelector(
  selectMemberModuleState,
  (state) => state[fromParticipantDocuments.ParticipantDocumentsFeatureKey],
);

export const documentAttachSelector = createSelector(selectParticipantDocumentsState, (state) => state?.documentAttach);

export const selectStatusHistoryListState = createSelector(
  selectStatusHistoryState,
  (state) => state.statusHistoryList,
);

export const selectListMemberStatusState = createSelector(selectStatusHistoryState, (state) => state.listMemberStatus);

export const selecRelatedPersonState = createSelector(
  selectMemberModuleState,
  (state) => state[fromRelatedPerson.RelatedPersonFeatureKey],
);
export const selectRelatedPersonListState = createSelector(selecRelatedPersonState, (state) => state.relatedPersonList);

export const selectAggregationGroupState = createSelector(
  selectMemberModuleState,
  (state) => state[fromAggregationGroupFeatureKey.aggregationGroupFeatureKey],
);

export const selectAggregationGroupDetailsState = createSelector(
  selectMemberModuleState,
  (state) => state[fromAggregationGroupFeatureKey.aggregationGroupFeatureKey].aggregationGroupDetails,
);

export const selectMemberPropertyDataByMaskedConfigState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberPropertyFeatureKey.memberPropertyFeatureKey],
);

export const selectParticipantSearchConfiguration = createSelector(
  selectMemberModuleState,
  (state) => state?.[fromParticipantSearchConfiguration.participantSearchConfigurationFeatureKey],
);

export const selectParticipantSearchPropertyConfigs = createSelector(
  selectParticipantSearchConfiguration,
  (state) => state?.propertyConfigs,
);

export const selectParticipantSearchPropertyDisplayConfigurations = createSelector(
  selectParticipantSearchConfiguration,
  (state) => state?.propertyDisplayConfigurations,
);

export const selectParticipantSearchPropertyDisplayConfigurationsEntity = createSelector(
  selectParticipantSearchConfiguration,
  (state) => state?.configurations,
);
export const selectParticipantSearchConfigurationUpdateState = createSelector(
  selectParticipantSearchConfiguration,
  (state) => state?.updateState,
);

export const selectParticipantRelationshipState = createSelector(
  selectMemberModuleState,
  (state) => state[fromParticipantRelationship.participantRelationshipFeatureKey],
);

export const selectParticipantRelationships = createSelector(
  selectParticipantRelationshipState,
  (state) => state.participantRelationships,
);

export const selectParticipantRelationshipLoadingState = createSelector(
  selectParticipantRelationshipState,
  (state) => state.isLoading,
);

export const selectSavePaymentInforConfigState = createSelector(
  selectNextPayment,
  (state) => state.savePaymentInforConfigState,
);

export const selectPaymentInforConfigState = createSelector(selectNextPayment, (state) => state.paymentInforConfig);

export const selectMemberNotificationOptInState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberNotificationOptIn.memberNotificationOptInFeatureKey],
);

export const selectMemberNotificationOptInListState = createSelector(
  selectMemberNotificationOptInState,
  (state) => state.memberNotificationOptIns,
);

export const selectEditMemberNotificationOptInState = createSelector(
  selectMemberNotificationOptInState,
  (state) => state.editNotificationOptInState,
);

export const selectMunicipalityServiceRecordState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMunicipalityServiceRecord.MunicipalityServiceHistoryFeatureKey],
);
export const selectMunicipalityServiceRecordListState = createSelector(
  selectMunicipalityServiceRecordState,
  (state) => state.serviceRecords,
);
export const selectMunicipalityNameListState = createSelector(
  selectMunicipalityServiceRecordState,
  (state) => state.listMunicipality,
);
export const selectMunicipalityLoadingState = createSelector(
  selectMunicipalityServiceRecordState,
  (state) => state.isFormEditLoading,
);
export const selectSetPaymentListConfigurationState = createSelector(
  selectMemberModuleState,
  (state) => state[fromPaymentListConfigurations.paymentListConfigurationFeatureKey].setPaymentListConfiguration,
);
export const selectGetPaymentListConfigurationState = createSelector(
  selectMemberModuleState,
  (state) => state[fromPaymentListConfigurations.paymentListConfigurationFeatureKey].getPaymentListConfiguration,
);

//Tier Configuration
export const selectTierConfiguration = createSelector(
  selectMemberModuleState,
  (state) => state[fromTierConfigurations.tierConfigurationFeatureKey].getTierConfigurations,
);

export const selectTierConfigurationPlan = createSelector(
  selectMemberModuleState,
  (state) => state[fromTierConfigurations.tierConfigurationFeatureKey].plans,
);

export const selectTierConfigurationMetadata = createSelector(
  selectMemberModuleState,
  (state) => state[fromTierConfigurations.tierConfigurationFeatureKey].metadata,
);

export const selectTierConfigurationOperator = createSelector(
  selectMemberModuleState,
  (state) => state[fromTierConfigurations.tierConfigurationFeatureKey].operator,
);

export const selectTierConfigurationLookup = createSelector(
  selectMemberModuleState,
  (state) => state[fromTierConfigurations.tierConfigurationFeatureKey].result,
);

export const selectTierConfigurationBinary = createSelector(
  selectMemberModuleState,
  (state) => state[fromTierConfigurations.tierConfigurationFeatureKey].resultBinary,
);

export const editTierConfiguration = createSelector(
  selectMemberModuleState,
  (state) => state[fromTierConfigurations.tierConfigurationFeatureKey]?.editTierConfiguration,
);

export const setTierConfiguration = createSelector(
  selectMemberModuleState,
  (state) => state[fromTierConfigurations.tierConfigurationFeatureKey]?.setTierConfiguration,
);

//End Tier Configuration

// calculation region
export const selectCalculationState = createSelector(
  selectMemberModuleState,
  (state) => state[fromCalculations.calculationFeatureKey],
);
export const selectGetCalculationsState = createSelector(selectCalculationState, (state) => state.getCalculations);
export const selectCalculationPropertiesState = createSelector(
  selectCalculationState,
  (state) => state.calculationProperties,
);
export const selectAddCalculationState = createSelector(selectCalculationState, (state) => state.addCalculation);
export const selectRemoveCalculationState = createSelector(selectCalculationState, (state) => state.removeCalculation);
export const selectCalculationDetailState = createSelector(selectCalculationState, (state) => state.calculationDetail);
export const selectUpdateCalculationState = createSelector(selectCalculationState, (state) => state.updateCalculation);
// end calculation region

export const selectParticipantReportState = createSelector(
  selectMemberModuleState,
  (state) => state[fromParticipantReport.participantReportFeatureKey],
);
export const selectParticipantReportManageReport = createSelector(
  selectParticipantReportState,
  (state) => state.getManageParticipantState,
);
export const selectGetGeneratedReport = createSelector(
  selectParticipantReportState,
  (state) => state.getGeneratedReportState,
);
export const selectDeleteGeneratedReport = createSelector(
  selectParticipantReportState,
  (state) => state.deleteGeneratedReportState,
);
export const selectParticipantReportCategorySidebar = createSelector(
  selectParticipantReportState,
  (state) => state.getReportCategorySidebarState,
);
export const selectParticipantReportCategoryName = createSelector(
  selectParticipantReportState,
  (state) => state.getReportCategoryNameState,
);
export const selectCreateReport = createSelector(selectParticipantReportState, (state) => state.createReportState);
export const selectDeleteReport = createSelector(selectParticipantReportState, (state) => state.deleteReportState);
export const selectEditReport = createSelector(selectParticipantReportState, (state) => state.editReportState);
export const selectValidateTerminatedMemberState = createSelector(
  selectMunicipalityServiceRecordState,
  (state) => state.validateTerminatedMember,
);
export const validateServiceHistoryRecordTerminatedMemberSelector = createSelector(
  selectMunicipalityServiceRecordState,
  (state) => state.validateServiceHistoryRecordTerminatedMemberState,
);
export const createValidateServiceHistoryRecordBeginDateTransactionSelector = createSelector(
  selectMunicipalityServiceRecordState,
  (state) => state.createValidateServiceHistoryRecordBeginDateTransactionState,
);

//drop plan
export const selectDropPlan = createSelector(
  selectMemberModuleState,
  (state) => state[fromDropPlan.dropPlanFeatureKey],
);

// end drop plan

export const selectEmployerDetailState = createSelector(selectMemberListState, (state) => state.employerDetail);
export const selectListColumnConfigState = createSelector(
  selectMemberListState,
  (state) => state.listColumnConfigState,
);

export const selectPropertyOfParticipants = createSelector(
  selectMemberListState,
  (state) => state.propertyConfiguration,
);

export const selectCardSelection = createSelector(
  selectMemberListState,
  (state) => state.cards,
);

export const selectPropertyByCardSelection = createSelector(
  selectMemberListState,
  (state) => state.propertyByCardSelection,
);

export const selectRetireRehireConfirmState = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey].retireRehireConfirmState,
);

export const getClientSettingSelector = createSelector(
  selectMemberModuleState,
  (state) => state[fromMemberDetail.memberDetailFeatureKey]?.getClientSetting,
);

// cart
export const selectCart = createSelector(selectMemberModuleState, (state) => state[fromCart.cartFeatureKey]);

export const selectEntity = createSelector(
  selectMemberModuleState,
  (state) => state[fromCart.cartFeatureKey].listEntity,
);

// end cart

// participant navigation configuration
export const selectParticipantNavigationConfiguration = createSelector(
  selectMemberModuleState,
  (state) => state[fromParticipantNavigationConfiguration.navigationConfigurationKey],
);

export const selectParticipantView = createSelector(
  selectMemberModuleState,
  (state) => state[fromParticipantNavigationConfiguration.navigationConfigurationKey].viewResponse,
);

export const selectParticipantNavigationConfigurationDetail = createSelector(
  selectMemberModuleState,
  (state) =>
    state[fromParticipantNavigationConfiguration.navigationConfigurationKey].navigationConfigurationDetailResponse,
);

// end participant navigation configuration

export const removeParticipantDocumentsState = createSelector(
  selectMemberModuleState,
  (state) => state[fromParticipantDocuments.ParticipantDocumentsFeatureKey].removeState,
);

export const getParticipantDocumentsBenefitsState = createSelector(
  selectMemberModuleState,
  (state) => state[fromParticipantDocuments.ParticipantDocumentsFeatureKey].getParticipantDocumentsBenefits,
);

//navigate to document list

export const selectNavigateToDocumentListSelector = createSelector(
  selectMemberDocumentState,
  (state) => state.getNavigateToDocumentListWithGenerateId,
);


export const selectDepositAccountState = createSelector(
  selectMemberModuleState,
  (state) => state.depositAccount,
);

export const selectGetDepositAccountsState = createSelector(
  selectDepositAccountState,
  (state) => state.getDepositAccounts,
);
export const selectValidateRemoveServiceHistoryState = createSelector(
  selectMunicipalityServiceRecordState,
  (state) => state.validateRemoveServiceHistoryState,
);

export const selectRemoveServiceHistoryState = createSelector(
  selectMunicipalityServiceRecordState,
  (state) => state.removeServiceHistoryState,
);
