<ptg-dialog class="retirement-benefit-dialog-container flex flex-col" [title]="dialogTitle">
  <ng-template dialogContentHeader>
    <ptg-overview-header-entity
      [injectedMemberId]="data.memberId"
      [isShowChangeMemberDetailBtn]="false"
      [isSubHeader]="true"
    ></ptg-overview-header-entity>
  </ng-template>
  <ng-template dialogBody>
    <div class="upload-form-container flex flex-col px-6 py-2 gap-6" [class.is-bvff-fund]="isBVFFFund">
      <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

      <div class="action-buttons flex justify-start gap-3">
        <ptg-button
          (click)="onNext()"
          classInput="primary"
          [buttonName]="isSurvivor || isQDRO || isChildSurvivor ? 'Save' : 'Next'"
        ></ptg-button>
        <ptg-button (click)="onCancel()" classInput="tertiary" buttonName="Cancel"></ptg-button>
      </div>

      <div class="flex flex-col upload-calculation-input-container">
        <ptg-select
          (changeOptionValue)="onChangeBenefitOption()"
          [controlField]="benefitOptionControl"
          [isMultipleLineOption]="true"
          [isOptionObj]="true"
          [isSetMaxWidthSelect]="true"
          [listData]="benefitOptions"
          class="w-300 mb-4"
          placeholder="Benefit Option"
          *ngIf="benefitOptions.length > 1"
        >
        </ptg-select>

        <div class="value-container mb-4" *ngIf="benefitOptions.length === 1">
          <span class="text-sm leading-6 emphasis-medium">Benefit</span>
          <div class="text-base emphasis-high">{{ benefitOptions[0].displayValue }}</div>
        </div>

        <div class="flex flex-wrap gap-x-12 gap-y-4 mb-4" *ngIf="!alternatePayeeLoading && isQDRO && !data.isEdit">
          <div class="w-[calc(600px+1rem)]">
            <ptg-select
              placeholder="Alternate Payee"
              class="w-full"
              [listData]="optionAlterPayee"
              [controlField]="alternatePayeeId"
              [isSetMaxWidthSelect]="true"
              [isOptionObj]="true"
              (changeOptionValue)="onChangeAlterPayee($event)"
            ></ptg-select>
          </div>
          <div>
            <div class="text-sm leading-6 emphasis-medium">Relationship</div>
            <div class="text-base emphasis-high">{{ selectedAlternatePayee?.relationship ?? '-' }}</div>
          </div>
          <div>
            <div class="text-sm leading-6 emphasis-medium">Case Number</div>
            <div class="text-base emphasis-high">{{ selectedAlternatePayee?.caseNumber ?? '-' }}</div>
          </div>
        </div>

        <div class="flex flex-wrap gap-x-12 gap-y-4 mb-4" *ngIf="!childSelectionDataLoading && isChildSurvivor && !data.isEdit">
          <div class="w-[calc(600px+1rem)]">
            <ptg-select
              placeholder="Child Selection"
              class="w-full"
              [listData]="childSelectionDropdownOptions"
              [controlField]="childSelectionDropdown"
              [isSetMaxWidthSelect]="true"
              [isOptionObj]="true"
              (changeOptionValue)="onChangeChildSelectionDropdown($event)"
            ></ptg-select>
          </div>
          <div>
            <div class="text-sm leading-6 emphasis-medium">Age</div>
            <div class="text-base emphasis-high">{{ selectedChild?.age ?? '-' }}</div>
          </div>
        </div>

        <ng-container *ngIf="propertyValueFromPriorStepsFormArray?.length && (!stepOptionByPass || data.isEdit)">
          <div
            class="flex flex-col benefit-other-component-container mb-4"
            *ngIf="propertyValueFromPriorStepsFormArray?.length"
          >
            <div
              *ngFor="let property of propertyValueFromPriorStepsFormArray.controls; let index = index"
              class="value-container flex"
            >
              <ptg-dynamic-input
                [controlField]="$any(property.get('value'))"
                [formControlDetail]="propertyValueFromPriorStepConfigs[index]"
                (valueChange)="handleValueChange($event)"
              ></ptg-dynamic-input>
            </div>
          </div>
        </ng-container>
        <ng-container>
          <div class="flex flex-col mb-4 gap-4 w-[calc(600px+1rem)]" *ngIf="!isQDRO && !isChildSurvivor && isRecalculate">
            <ptg-datepicker
              class="w-full"
              placeholder="Benefit Begin Date"
              [controlField]="benefitBeginDateControl"
              [isRequired]="true"
              [isDisable]="true"
            ></ptg-datepicker>
          </div>
        </ng-container>

        <div class="flex flex-col gap-4" *ngIf="!data.benefitEntityId && !isQDRO && !isChildSurvivor">
          <ptg-radio-button
            [controlField]="isNewDocumentControl"
            [isObject]="true"
            [listOption]="newDocumentOptions"
            (changeValue)="changeSelectedDocumentType($event)"
          ></ptg-radio-button>

          <div class="flex gap-4">
            <ptg-select
              *ngIf="!isNewDocumentControl.value; else documentNameInput"
              (changeOptionValue)="onChangeDocumentName()"
              [controlField]="idControl"
              [isMultipleLineOption]="true"
              [isOptionObj]="true"
              [isSetMaxWidthSelect]="true"
              [listData]="existDocumentList"
              class="w-300"
              placeholder="Document Name"
            >
            </ptg-select>

            <ng-template #documentNameInput>
              <ptg-textbox
                placeholder="Document Name"
                errorAsync="Document Name already exists."
                customError="documentExisted"
                class="w-300"
                [ngClass]="{ 'has-error': nameControl.touched && nameControl.errors }"
                [controlField]="nameControl"
                [hasLabel]="true"
                [maxLength]="250"
                maxErrorMessage="Exceed the 250 character limit."
                (valueChange)="getDocumentNameChange($event)"
              ></ptg-textbox>
            </ng-template>

            <ptg-select
              [controlField]="documentTypeControl"
              [isMultipleLineOption]="true"
              [isOptionObj]="true"
              [isSetMaxWidthSelect]="true"
              [listData]="calculationDocumentTypeOptions"
              class="w-300"
              placeholder="Document Type"
              [isDisabled]="!selectDocumentFormGroup.value?.isNewDocument"
              (changeOptionValue)="getDocumentTypeChange($event)"
            >
            </ptg-select>

            <ptg-button
              (click)="addDocument()"
              *ngIf="!isNewDocumentControl.value"
              buttonName="Select"
              classInput="primary"
            ></ptg-button>
          </div>

          <div class="flex-1 autocomplete-chip-section" *ngIf="tagsAutoCompleteControl">
            <div class="auto-complete-section">
              <div class="select-container">
                <div class="select-tag">
                  <mat-form-field class="auto-complete" appearance="fill">
                    <mat-label>Tags</mat-label>
                    <input
                      class="custom-input"
                      type="text"
                      matInput
                      [formControl]="tagsAutoCompleteControl"
                      [matAutocomplete]="auto"
                      (blur)="validateTags()"
                      (focus)="tagsFocus($event)"
                    />
                    <span class="custom-arrow mat-select-arrow-wrapper">
                      <span
                        class="mat-select-arrow"
                        [ngClass]="{
                          'has-error': tagsAutoCompleteControl.errors !== null && tagsAutoCompleteControl.touched
                        }"
                      >
                      </span>
                    </span>
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayTagFn">
                      <mat-option *ngFor="let option of filteredByTypingTagOptions | async" [value]="option">
                        {{ option.displayValue }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error
                      *ngIf="
                        !tagsAutoCompleteControl?.errors?.required && tagsAutoCompleteControl?.errors?.inValidAsync
                      "
                    >
                      Value must be selected from result list.
                    </mat-error>
                  </mat-form-field>
                  <p
                    class="add-new-hyperlink"
                    [ngClass]="{
                      'pt-3': !tagsAutoCompleteControl.errors?.required && tagsAutoCompleteControl.errors?.inValidAsync
                    }"
                  >
                    <span [ngClass]="{ disabled: isNewDocumentControl.value === false }" (click)="addNewTags()"
                      >Add New Tag</span
                    >
                  </p>
                </div>
                <div class="chip-container">
                  <span class="add-chip-btn-section">
                    <button
                      mat-icon-button
                      (click)="onAddNewChip()"
                      [disabled]="!tagsAutoCompleteControl.value || tagsAutoCompleteControl.errors?.inValidAsync"
                    >
                      <mat-icon>add_circle</mat-icon>
                    </button>
                  </span>
                  <div class="tags-chip-list-section">
                    <mat-chip-list *ngIf="tagsChipListControl?.length">
                      <mat-chip
                        *ngFor="let item of tagsChipListControl?.controls; index as i"
                        (removed)="onRemoveChip(item.value, i)"
                      >
                        <span class="chip-wrapper">
                          <span class="chip__txt--primary">
                            <span class="mr-1">{{ item.value.displayValue }}</span>
                          </span>
                        </span>
                        <button matChipRemove>
                          <mat-icon>close</mat-icon>
                        </button>
                      </mat-chip>
                    </mat-chip-list>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex-1 description-area" [ngClass]="{ 'description-has-error': descriptionInputAreaControl.errors }" style="margin-top: 20px" *ngIf="descriptionInputAreaControl">
            <ptg-input-area
              [controlField]="descriptionInputAreaControl"
              [hasLabel]="true"
              placeholder="Description"
              [maxLength]="255"
              errorMaxLength="Exceed the 255 character limit."
            >
            </ptg-input-area>
          </div>
          <div class="flex-1 show-on-overview-area" *ngIf="showOnOverviewCheckboxControl">
            <ptg-checkbox
              [controlField]="showOnOverviewCheckboxControl"
              [label]="labelShowOverview"
              [isDisabled]="isNewDocumentControl.value === false"
            >
            </ptg-checkbox>
          </div>

          <div class="upload-wrapper flex flex-col" [class.is-bvff-fund]="isBVFFFund">
            <ng-container *ngIf="isNewDocumentControl.value">
              <ptg-upload
                #fileDocument
                (deleteFile)="clearSelectedFile($event)"
                (onUploadAction)="addDocument($event, true)"
                (onBrowseFile)="onBrowseFile()"
                [accept]="acceptFile"
                [checkPattern]="checkPattern"
                [isRequired]="true"
                [noUpload]="false"
                [typeFile]="supportedFileTypes"
                [controlField]="fileControl"
                [buttonLabel]="isBVFFFund ? 'Choose File' : 'Browse'"
                [uploadIcon]="isBVFFFund ? '' : 'upload_file'"
                [ignoreCancel]="true"
                [validateOnUpload]="true"
                class="m-0"
                pageHeader="Signed Documentation"
                uploadButtonName="Upload"
                errRequired="Upload Signed Documentation is required."
                [imageFile]="true"
                [shouldEmitWhenClickUpload]="true"
              ></ptg-upload>
            </ng-container>

            <div
              *ngIf="showCustomDocumentErrMsg && (idControl.valid || isNewDocumentControl.value)"
              [ngStyle]="{ marginTop: isNewDocumentControl.value ? '' : '-1rem' }"
              class="error-msg"
            >
              {{
                isNewDocumentControl.value
                  ? 'Upload Signed Documentation is required.'
                  : 'Signed Documentation is required.'
              }}
            </div>
          </div>

          <ptg-panel [title]="'Retirement Benefit Documents'" [noPaddingBody]="true">
            <ng-template panelBody>
              <ptg-grid
                [columns]="documentColumns"
                [data]="filteredDocuments"
                [fitToParent]="true"
                [pageSize]="pageSize"
                [pageNumber]="pageNumber"
                [totalRecords]="documents.length"
                notFoundMessage="No Data to Display"
                [paginable]="true"
                (sortChange)="onSortDocumentsTable($event)"
                (pageChange)="onChangePage($event)"
              >
                <ng-template cellContent columnName="fileName" let-row>
                  <div class="flex file-document-container">
                    <span class="file-document-name" target="_blank" (click)="downloadSelectedFile(row)"
                      ><span class="truncate">{{ row?.fileName }}</span></span
                    >
                  </div>
                </ng-template>
                <ng-template cellContent columnName="action" let-row>
                  <ptg-button buttonName="Remove" classInput="remove-button" (clickButton)="removeDocument(row)">
                    <mat-icon>delete_forever</mat-icon>
                  </ptg-button>
                </ng-template>
              </ptg-grid>
            </ng-template>
          </ptg-panel>
        </div>
      </div>
    </div>

    <div class="spinner-wrapper" [class.active]="isLoading">
      <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
    </div>
  </ng-template>
</ptg-dialog>
