export enum DeductionPayeeRunType {
  CheckAndDirectDeposit,
  WireTransferAndInternal,
  MuniRefund
}

export enum DeductionPayeeRunTypeName {
  'Ded Payee Check & DD',
  'Ded Payee Wire T. & Internal P.',
  MuniRefund,
}