import {AfterContentInit, Directive, ElementRef, HostListener, Optional} from '@angular/core';
import {NgControl, Validators} from '@angular/forms';
import { deepClone } from '@ptg-shared/utils/common.util';

@Directive({
  selector: '[ptgInputZipCode]'
})
export class InputZipCodeDirective implements AfterContentInit {

  pattern = /^[0-9\-]*$/;
  patternNumberAndHyphenOnly = /^(?=[\d-]{10}$)\d{5}-\d+$/;
  patternNumber = /^(\d{5}|\d{9})?$/;
  patternNumberNine = /^(\d{9})?$/;
  nativeElement;
  constructor( private element: ElementRef, @Optional() private control: NgControl) {
    this.nativeElement = element.nativeElement;
  }

  private checkIsValidAndSetErrors(value: string) {
    if (value.length === 10 && !this.patternNumberAndHyphenOnly.test(value) || value.length < 10 && !this.patternNumber.test(value)) {
      this.control.control?.setErrors({ ...this.control.control?.errors, pattern: true });
      return false;
    }
    let err = deepClone(this.control.control?.errors);
    delete err?.pattern;
    this.control.control?.setErrors(err ?? null);
    return true;
  }

  ngAfterContentInit() {
    this.onFocusOut();
  }
  @HostListener('keypress', ['$event']) onkeyValid(event: any): boolean {
    const keyId = this.onKeyID(event.key);
    if (keyId) {
      return false;
    }
    return true;
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    if (event.clipboardData) {
      const pasteInp = event.clipboardData.getData('text/plain').split('');
      const pasteVal = pasteInp?.filter((item: any) => {
        if (!this.onKeyID(item)) {
          return item;
        }
      });
      let value = pasteVal.join('');
      if (this.patternNumberNine.test(value)) {
        const match = value.match(/^(\d{5})(\d{4})$/);
        value = match ? `${match[1]}-${match[2]}` : value;
      }
      document.execCommand('insertText', false, value);
      event.preventDefault();
    } else if (event.clipboardData) {
      event.preventDefault();
    }
  }

  /** Show error for form control */
  @HostListener('focusout') onFocusOut() {
    const value = this.nativeElement.value;
    if (this.control && value.length) {
      const timeOut = setTimeout(()=> {
        if (this.checkIsValidAndSetErrors(value)) {
          if (this.patternNumberNine.test(value)) {
            const match = value.match(/^(\d{5})(\d{4})$/);
            this.control.control?.setValue( match ?`${match[1]}-${match[2]}`: value);
          }
        }
        this.control.control?.markAsTouched();
        clearTimeout(timeOut);
      }, 100);
    }
  }

  @HostListener('keyup') onKeyUp() {
    const value = this.nativeElement.value;
    if (this.control && value.length) {
      const timeOut = setTimeout(() => {
        this.checkIsValidAndSetErrors(value);
        const cleaned = ('' + value).trim().replace(/[^0-9]/g, '');
        const match = value.match(/^(\d{5})(\d{4})$/);
        if (!cleaned) {
          
          this.control.control?.setValue( match ?`${match[1]}-${match[2]}`: value);
        } else if (/^([0-9]{9})$/.test(cleaned)) {
          if (match) {
            this.control.control?.setValue(
              match[1] + '-' + match[2],
              { emitEvent: false }
            );
          }
        }
      });
    }
  }


  onKeyID(event: any): any {
    const valueId = event;
    if (!this.pattern.test(valueId)) {
      return true;
    }
  }

}
