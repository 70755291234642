import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import {
  CalculationState,
  clearGetQDROBenefitInformationStateAction,
  getCalculationQDROSelector,
  getQDROBenefitInformationAction,
  getQDROBenefitInformationSelector,
} from '../../store';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { DEFAULT_PAGE_SIZE, SortType } from '@ptg-shared/constance';
import { filter, takeUntil } from 'rxjs/operators';
import { GetQDROBenefitInformationRequest, QDROBenefitInformation } from '../../services/models';
import { CalculationBenefitHistoryStatus, CalculationType } from '../../types/enums';
import { GRID_COLUMN_DETAIL_BENEFIT_QDRO } from '../../benefit-detail.constants';
import { Column } from '@ptg-shared/controls/grid';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'ptg-qdro-information',
  templateUrl: './qdro-information.component.html',
  styleUrls: ['./qdro-information.component.scss'],
})
export class RetirementBenefitQDROComponent extends BaseListComponent {
  readonly CalculationBenefitHistoryStatus = CalculationBenefitHistoryStatus;
  @Input() memberId!: string;
  @Input() calculationBenefitId!: string;
  @Input() reloadEvent?: Observable<boolean>;
  @Output() showQDROBenefitInfoSectionEmitter = new EventEmitter<boolean>(true);

  columnBenefitQDRO: Column[] = GRID_COLUMN_DETAIL_BENEFIT_QDRO;
  totalBenefitQDRO = 0;
  isQDROLoading = true;
  pageQDROIndex = FIRST_PAGE;
  pageQDROSize = DEFAULT_PAGE_SIZE;

  @Input() retirementBenefitQDRO: QDROBenefitInformation[] = [];
  @Output() retirementBenefitQDROChange = new EventEmitter();

  qdroLabelName: string = '';

  constructor(
    public readonly route: ActivatedRoute,
    public readonly layoutService: LayoutService,
    private readonly calculationStore: Store<CalculationState>,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    this.selectQDROBenefitInformation();
    this.selectCalculationQDRO();

    this.reloadEvent
      ?.pipe(
        filter((isReload) => !!isReload),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.getQDROBenefitInformation();
      });
  }

  private getQDROBenefitInformation() {
    const request: GetQDROBenefitInformationRequest = {
      pageNumber: this.pageQDROIndex,
      pageSize: this.pageQDROSize,
      memberId: this.memberId,
      calculationBenefitId: this.calculationBenefitId,
      sortNames: 'dateCreated',
      sortType: SortType.DESC,
    };
    this.calculationStore.dispatch(getQDROBenefitInformationAction({ request }));
  }

  private selectQDROBenefitInformation() {
    this.calculationStore
      .select(getQDROBenefitInformationSelector)
      .pipe(
        tap((res) => (this.isQDROLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        this.calculationStore.dispatch(clearGetQDROBenefitInformationStateAction());
        this.retirementBenefitQDRO =
          state?.payload?.map((v) => {
            return {
              ...v,
              payeeName: `${v.personName?.first ?? ''} ${v.personName?.middle ?? ''} ${v.personName?.last ?? ''}`,
            };
          }) ?? [];
        this.totalBenefitQDRO = state?.total ?? 0;
        this.showQDROBenefitInfoSectionEmitter.next(!!this.totalBenefitQDRO);
        this.retirementBenefitQDROChange.emit(this.retirementBenefitQDRO);
      });
  }

  onChangeQDROPage(event: PageEvent) {
    this.pageQDROSize = event.pageSize;
    this.pageQDROIndex = event.pageNumber;
    this.getQDROBenefitInformation();
  }

  private replaceQdroLabelProperty(properties: Column[], _qdroLabelName: string) {
    return properties.map((property) => {
      return {
        ...property,
        header: {
          ...property.header,
          title: property.header?.title?.replace(
            '{qdroLabelName}',
            _qdroLabelName,
          )
        }
      }
    });
  }

  private selectCalculationQDRO() {
    this.calculationStore
      .select(getCalculationQDROSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((getCalculationQDRO) => {
        if (!getCalculationQDRO?.isLoading && getCalculationQDRO?.success) {
          this.qdroLabelName = getCalculationQDRO?.payload?.labelName ?? '';
          this.columnBenefitQDRO = this.replaceQdroLabelProperty(this.columnBenefitQDRO, this.qdroLabelName);
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.showQDROBenefitInfoSectionEmitter.complete();
  }
}
