import {
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Store, select } from '@ngrx/store';
import { filter, take, takeUntil, tap } from 'rxjs/operators';

import {
  ACTION_COLUMN,
  Align,
  CellContent,
  Column,
  ColumnType,
  ControlType,
  DEFAULT_CURRENT_ROW_INDEX,
  DEFAULT_NOT_FOUND_MSG,
  Row,
} from '@ptg-shared/controls/grid';

import { DatePipe } from '@angular/common';
import { BankAccountType } from '@ptg-member/types/models/deposit-account.model';
import * as fromReducer from '@ptg-reducers';
import { FIRST_PAGE } from '@ptg-shared/controls/pagination';
import { getDateString } from '@ptg-shared/utils/string.util';
import { Subject } from 'rxjs';
import { PaymentInfoTabLoadingStateHandler, PaymentTab } from '../../services/models';
import { getPayeePaymentAction } from '../../store';
import * as fromPayeeDetail from '../../store/reducers/index';
import { getPayeePaymentSelector } from '../../store/selectors/payee-payment.selector';
import { handlePaymentInfoTabLoadingState } from '../../types/constants/payment-info-tab.constant';
import {
  GRID_COLUMN_SPLIT_PAYMENT_BASE,
  GRID_COLUMN_SPLIT_PAYMENT_EXTENSION,
} from '../../types/constants/split-payment-section.constants';
import { PayStatus, TabPaymentInfo } from '../../types/enums';
import { GetPayeePaymentRequest, PayeePayment, PayeePaymentType } from '../../types/models';
import { EditPayeePaymentComponent } from '../edit-payee-payment/edit-payee-payment.component';

@Component({
  selector: 'ptg-split-payment-section',
  templateUrl: './split-payment-section.component.html',
  styleUrls: ['./split-payment-section.component.scss'],
})
export class SplitPaymentSectionComponent<T extends Row>
  implements OnInit, OnDestroy, OnChanges, PaymentInfoTabLoadingStateHandler
{
  readonly ColumnType = ColumnType;
  readonly AlignEnum = Align;
  readonly ACTION_COLUMN = ACTION_COLUMN;
  readonly ControlType = ControlType;
  readonly PayStatus = PayStatus;
  readonly TabPaymentInfo = TabPaymentInfo;

  tableDataSource: MatTableDataSource<T> = new MatTableDataSource();
  displayedColumns: string[] = [];

  @ContentChildren(CellContent) cellContents?: QueryList<CellContent>;

  id: string = '';
  notFoundMessage: string = DEFAULT_NOT_FOUND_MSG;
  errorMessage?: string;
  unsubscribe$ = new Subject<void>();
  isLoading: boolean = true;
  pageNumber: number = FIRST_PAGE;
  pageSize: number = 50;
  title = 'Payment';

  @Input() fitToParent = false;
  @Input() hideScrollbar: boolean = true;
  @Input() hideHeader = false;
  @Input() fixedHeader: boolean = true;

  @Input() benefitTypeOptionId?: string = '';
  @Input() memberId?: string = '';
  @Input() selectedRow?: PaymentTab;
  @Input() selectedTabPayment!: TabPaymentInfo;

  data: (any & Row)[] = [];
  total: number = 0;
  columns: Column[] = GRID_COLUMN_SPLIT_PAYMENT_BASE;
  isShowEditButton = false;
  isHiddenAmount = false;
  // Two-way binding for the current row index
  @Input() currentRowIndex: number = DEFAULT_CURRENT_ROW_INDEX;
  @Output() currentRowIndexChange = new EventEmitter<number>();

  constructor(
    private store: Store<fromReducer.State>,
    private payeeDetailStore: Store<fromPayeeDetail.PayeeDetailState>,
    public dialog: MatDialog,
    private readonly datePipe: DatePipe,
  ) {}

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.handleColumnChanges();
    this.selectPayeePaymentState();
    handlePaymentInfoTabLoadingState(this, this.payeeDetailStore);
  }

  private getPayeePayments(): void {
    this.title = this.selectedRow?.payStatus === PayStatus.Finalized ? 'Transaction' : 'Payment';
    this.handleColumnChanges();

    if (this.benefitTypeOptionId && this.selectedRow?.id) {
      const request: GetPayeePaymentRequest = {
        paymentInstructionId: this.selectedRow?.id ?? '',
        benefitSubtypeId: this?.benefitTypeOptionId,
        paymentInstructionHistoryId: this.selectedRow?.paymentInstructionHistoryId ?? '',
        instructionStatusHistoryId: this.selectedRow?.instructionStatusHistoryId ?? '',
      };
      this.store.dispatch(getPayeePaymentAction({ request }));
    }
  }

  private selectPayeePaymentState(): void {
    this.store
      .pipe(
        select(getPayeePaymentSelector),
        filter((res) => !!res),
        tap((res) => (this.isLoading = !!res?.isLoading)),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        this.isLoading = !!state?.isLoading;

        if (!state?.success || !state?.payload) return;

        const { payments = [], total = 0, isEditable = false } = state.payload;

        this.data = payments.map((el: PayeePayment, index: number) => {
          if (
            this.selectedTabPayment === TabPaymentInfo.InstructionHistory &&
            !el?.amount &&
            (this.selectedRow?.status === PayStatus.IssuePayment || this.selectedRow?.status === PayStatus.Suspended)
          ) {
            this.isHiddenAmount = true;
          }

          return {
            ...el,
            name: 'Payment ' + (index + 1),
            paymentMethod: PayeePaymentType[el.paymentType as PayeePaymentType],
            bankName: el?.depositAccount?.bankName,
            accountNumber: el?.depositAccount?.accountNumber,
            maskedAccountNumber:
              el?.depositAccount?.accountNumber && el?.depositAccount?.accountNumber?.length > 4
                ? this.getMaskedFormat((el?.depositAccount?.accountNumber?.length ?? 0) - 4)
                : '',
            accountNumberNotMasked:
              el?.depositAccount?.accountNumber && el.depositAccount?.accountNumber?.length > 4
                ? el?.depositAccount?.accountNumber?.slice(
                    el.depositAccount?.accountNumber?.length - 4,
                    el?.depositAccount?.accountNumber?.length,
                  )
                : el?.depositAccount?.accountNumber,
            accountTypeName: el?.depositAccount?.accountType === BankAccountType.Checking ? 'Checking' : 'Savings',
            transactionDate: el?.transactionDate
              ? this.datePipe.transform(getDateString(el?.transactionDate), 'MM/dd/yyyy', 'UTC')
              : '-',
            postedTime: el?.postedTime
              ? this.datePipe.transform(getDateString(el?.postedTime), 'MM/dd/yyyy hh:mm a')
              : '-',
            transactionId: el?.transactionId ?? '-',
          };
        });
        this.isShowEditButton = isEditable;
        this.total = total;
        this.tableDataSource.data = this.data.map((item) => {
          // Initialize row properties if not already present
          return {
            backgroundColor: null,
            form: null,
            ...item,
          };
        });
        this.handleColumnChanges();
      });
  }

  private getMaskedFormat(length?: number) {
    let format = '';
    if (length! < 0) {
      return;
    }
    for (let index = 0; index < length!; index++) {
      format = format + 'X';
    }
    return format;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.selectedRow?.currentValue !== changes.selectedRow?.previousValue ||
      changes.benefitTypeOptionId?.currentValue !== changes.benefitTypeOptionId?.previousValue
    ) {
      this.getPayeePayments();
    }
  }

  // #region Render grid UI
  getHeaderStyle(column: Column): any {
    const styleObj: any = column.header?.style || {};

    if (column.width) {
      styleObj['width'] = column.width;
    }

    return styleObj;
  }

  getCellStyle(column: Column): any {
    const styleObj: any = column.style || {};

    if (column.width) {
      styleObj['width'] = column.width;
    }

    return styleObj;
  }

  getColumnClasses(column: Column): string {
    let className = '';

    let alignClass = '';
    if (column.type === ColumnType.Decimal) {
      alignClass = 'align-right';
    }
    switch (column.align) {
      case Align.Left:
        alignClass = 'align-left';
        break;
      case Align.Right:
        alignClass = 'align-right';
        break;
      case Align.Center:
        alignClass = 'align-center';
        break;
    }
    className += alignClass;

    if (column.truncate) {
      className += ' truncate';
    }

    if (column.sortable) {
      className += ' sortable';
    }

    return className;
  }

  getRowClasses(row: T): string {
    let classNames = '';

    if (row.deleted) {
      classNames += ' deleted-row';
    }

    if (row.italic) {
      classNames += ' italic';
    }

    if (row.errorRow) {
      classNames += ' error-row';
    }

    if (row.disabledRow) {
      classNames += ' disabled-row';
    }

    return classNames;
  }

  getCellContentTemplate(columnName: string): TemplateRef<any> | undefined {
    let cellContentTemplate: TemplateRef<any> | undefined;
    if (this.cellContents && this.cellContents.length > 0) {
      const cellContent = this.cellContents.find((cell) => cell.columnName === columnName);
      if (cellContent) {
        cellContentTemplate = cellContent.templateRef;
      }
    }

    return cellContentTemplate;
  }

  onEditPaymentDetail(): void {
    const dialogRef = this.dialog.open(EditPayeePaymentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        memberId: this.memberId,
        payeePaymentTable: this.data,
        total: this.total,
        paymentInstructionId: this.selectedRow?.id ?? '',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((result: boolean) => {
        if (result) {
          this.getPayeePayments();
        }
      });
  }

  private handleColumnChanges(): void {
    // Only display this column if selecting payment is of Payroll Run or One-Time Run that has [Status] = "Finalized"
    this.columns =
      this.selectedRow?.payStatus === PayStatus.Finalized
        ? GRID_COLUMN_SPLIT_PAYMENT_EXTENSION
        : GRID_COLUMN_SPLIT_PAYMENT_BASE;
    if (this.isHiddenAmount) {
      this.columns = this.columns.filter((item) => item.name !== 'amount');
    }
    this.displayedColumns = this.columns.map((col) => col.name);
  }
}
