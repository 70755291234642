<ptg-dialog class="mark-remove-overpaid" *ngIf="!isLoading; else loadingTemplate">
  <ng-template dialogContentHeader>
    <ptg-breadcrumb [listBreadcrumbs]="[{ name: 'Mark/Remove Overpaid' }]"></ptg-breadcrumb>
  </ng-template>
  <ng-template dialogBody>
    <div class="flex flex-col">
      <ptg-checkbox
        label="Overpaid"
        [controlField]="form.controls['isOverpaid']"
      ></ptg-checkbox>
      <div class="reasons flex gap-2">
        <ptg-select
          placeholder="Reason"
          errorRequire="Reason is required."
          class="w-[300px]"
          [controlField]="form.controls['_reasonSelect']"
          [listData]="overpaidReasons"
          [isOptionObj]="true"
          [isDisabled]="form.controls['isOverpaid'].value === false"
        ></ptg-select>
        <mat-icon
          class="add-btn mt-1.5"
          [class.disabled]="!form.controls['_reasonSelect'].value"
          (click)="$event.preventDefault(); addReason()">
          add_circle
        </mat-icon>
        <div class="chips mt-1.5">
          <mat-chip-list>
            <mat-chip *ngFor="let selectedReason of selectedReasons; index as i">
              <span>{{ selectedReason.displayValue }}</span>
              <mat-icon (click)="removeReason(i)">clear</mat-icon>
            </mat-chip>
          </mat-chip-list>
        </div>
      </div>
      <ptg-textbox
        *ngIf="showOtherReasons"
        [controlField]="form.controls['otherReasons']"
        [hasLabel]="true"
        placeholder="Other Reasons"
        class="w-[300px]"
      ></ptg-textbox>
    </div>
  </ng-template>
  <ng-template dialogFooter>
    <div class="w-full flex justify-end gap-2">
      <ptg-button classInput="primary" buttonName="Save" (clickButton)="onSave()"></ptg-button>
      <ptg-button classInput="tertiary" buttonName="Cancel" (clickButton)="onCancel()"></ptg-button>
    </div>
  </ng-template>
</ptg-dialog>
<ng-template #loadingTemplate>
  <div class="h-full">
    <ptg-spinner [isLoading]="true"></ptg-spinner>
  </div>
</ng-template>
