import { ACTION, STATE } from '@ptg-shared/constance';

export enum AccountType {
  Checking,
  Savings
}

export interface Address {
  street1: string,
  street2?: string,
  city: string,
  state: string,
  zipCode: string,
  country?: string,
  additional?: string,
  stateCode?: string;
  countryCode?: string;
  stateName?: string;
}

export interface PersonName {
  prefix: string;
  first?: string;
  middle: string;
  last?: string;
  suffix: string;
}

export interface DirectDepositPayment {
  bankId: string;
  routing: string;
  bankName: string;
  account: string;
  accountType: AccountType;
}
export interface AsyncFunction {
  function: any;
  fieldCheck: string;
  config?: AsyncFunctionConfig;
  editValue?: string;
}

export interface AsyncFunctionConfig {
  ignoreCase?: boolean;
  params?: any;
}

export enum AbstractControlStatus {
  VALID = 'VALID',
  INVALID = 'INVALID',
  PENDING = 'PENDING',
  DISABLED = 'DISABLED'
}

export type ValueOf<T> = T[keyof T];
export type StateType = ValueOf<typeof STATE> | '';
export type ActionType = ValueOf<typeof ACTION> | '';

export type ActionState = {
  state: StateType;
  action: ActionType;
}

export interface CheckExistResponse {
  exists: boolean;
  message: string;
}

export interface Holiday {
  holidayName: string;
  holidayDate: string;
}
