import { Component, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Sort } from '@angular/material/sort';

import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { BaseComponent } from '@ptg-shared/components';
import { FileService } from '@ptg-shared/services/file-service';
import { HistoryService } from '@ptg-shared/services/history.service';
import { SignatoryService } from '@ptg-shared/services/signatory-service';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { Align, Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import {
  ACTION,
  CREATED_AT,
  CREATED_BY,
  GUID_EMPTY,
  SortType,
  UPDATED_AT,
  UPDATED_BY,
} from '@ptg-shared/constance/value.const';
import { deepClone, showBanner } from '@ptg-shared/utils/common.util';

import * as fromReducer from '@ptg-reducers';
import { MemberDetailActions } from '@ptg-member/store/actions';
import { PropertyType } from '@ptg-member/constance/metadataPropertyType.const';
import { SectionLayout } from '@ptg-member/constance/metadata.const';
import { MemberDetailService } from '@ptg-member/services/member-detail.service';

import { getAllListPropertyForCardsSelector, getListPropertyForCardsSelector, getStandAlonePropertyOnlyCardDataSelector } from '@ptg-member/store/reducers';
import {
  MemberNavigationAttachmentList,
  MemberNavigationList,
  MemberNavigationResponse,
} from '@ptg-member/types/models';
import { EntityPropertyType } from '@ptg-entity-management/types/enums/entity-property.enum';
import { getColumConfig, getEntityPropertyName, getEntityValue } from '@ptg-member/helper';
import {
  clearCreateUploadMemberEntityAttachmentsStateAction,
  clearGetAllListPropertyForCardsStateAction,
  clearGetListPropertyForCardsStateAction,
  getAllListPropertyForCardsAction,
  getCardsByViewAction,
  getListPropertyForCardsAction,
  getMemberEntityAttachmentsAction,
  getStandAlonePropertyOnlyCardDataAction,
} from '@ptg-member/store/actions/member-detail.actions';
import * as fromMember from '../../store/reducers';
import { OverviewHeaderEntityComponent } from '@ptg-member/components/overview-header-entity/overview-header-entity.component';
import { BenefitDetailViewComponent, CreateEntityDataComponent } from '@ptg-entity-management/components';
import { EntityManagementState, EntityViewState, EntityState } from '@ptg-entity-management/store/reducers';
import {
  clearGetEntityViewsByEntityStateAction,
  clearUpsertComponentListDataStateAction,
  getEntityViewsByEntityAction,
  clearDeleteEntityListDataStateAction,
  deleteEntityListDataAction,
  clearCheckIfRecordExistedStateAction,
} from '@ptg-entity-management/store/actions';
import { deleteEntityListDataSelector, getEntityViewsByEntitySelector } from '@ptg-entity-management/store/selectors';
import { updateMemberCardSelector } from '@ptg-member/store/selectors/member.selector';
import { clearUpdateMemberCardStateAction } from '@ptg-member/store/actions';
import { stringToBoolean } from '@ptg-shared/utils/string.util';
import { DisplayedTabName, STRING_QUERY_PARAM } from '@ptg-shared/layout/constance/layout.const';
import {
  CardComponentType,
  EntityProfileComponentType,
  EntityViewType,
  IncludeAttachmentType,
  PropertyDisplay,
  StandalonePropertiesDisplayRule,
  ViewType,
} from '@ptg-entity-management/types/enums';
import {
  CardComponent,
  CardProperty,
  CardValue,
  EntityViewItem,
  GetCardsByViewRequest,
  GetListPropertyForCardsRequest,
  GetMemberEntityAttachmentsRequest,
  GetStandAlonePropertyOnlyCardDataRequest,
  StandAlonePropertyOnlyCardData,
} from '@ptg-entity-management/services/models';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import {
  clearGetDocumentDownloadStateAction,
  getDocumentDownloadAction,
} from 'src/app/admin/features/file/store/actions';
import { EntityType } from 'src/app/admin/features/file/types/enums/entity-type.enum';
import { USED_FOR_MENU } from '@ptg-shared/constance/document-location.const';
import { EditDocumentComponent } from 'src/app/admin/features/file/components/edit-document/edit-document.component';
import { DatePipe } from '@angular/common';
import { getCardDetail } from '@ptg-member/store/actions/cart.actions';
import { getCardDetailSelector } from '@ptg-member/store/selectors';
import { FundModel } from '@ptg-fund-list/models/fund-list.model';
import { LayoutActions } from '@ptg-shared/layout/actions';
import { COURT_ORDER_TYPE_CODE, FixedPropertyKey } from '@ptg-entity-management/constants';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { MemberDocumentService } from '@ptg-member/services/member-document.service';
import { EntityDataService } from '@ptg-entity-management/services';
import { CalculationState, getCalculationQDROSelector } from '@ptg-member/features/calculation/store';
import { CalculationType } from '@ptg-member/features/calculation/types/enums';

const PAGE_SIZE_CONST = '-ptg-member-navigation-page-pageSize';
const PAGE_SIZE_ATT_CONST = '-ptg-member-navigation-page-att-pageSize';
const VersionHistory = 'fixed__versionhistory';
const Screen1099RInfo = '1099-R Info';

@Component({
  selector: 'ptg-member-navigation-card',
  templateUrl: './member-navigation-card.component.html',
  styleUrls: ['./member-navigation-card.component.scss'],
})
export class MemberNavigationCardComponent extends BaseComponent {
  @ViewChild(OverviewHeaderEntityComponent) overViewHeader!: OverviewHeaderEntityComponent;

  readonly SectionLayout = SectionLayout;
  readonly ViewType = ViewType;
  readonly IncludeAttachmentType = IncludeAttachmentType;
  readonly StandalonePropertiesDisplayRule = StandalonePropertiesDisplayRule;
  readonly EntityPropertyType = EntityPropertyType;
  readonly CardComponentType = CardComponentType;

  viewType?: ViewType;
  currentSectionLayout?: SectionLayout;

  listBreadcrumbs: Breadcrumb[] = [{ name: '' }];
  settings: Breadcrumb[] = [
    {
      name: '',
      url: '',
    },
    {
      name: '',
      url: '',
    },
  ];
  isDisabledGear: boolean = false;

  bannerType: BannerType = BannerType.Hidden;
  message: string = '';

  currentFund: any = {};
  columns: Column[] = [];
  errorMsg: string = '';
  sortInfo: Sort = {
    active: 'Name',
    direction: 'asc',
  };
  lengthPg: number | any;
  attLengthPg: number | any;
  pageSize: number = 50;
  attPageSize: number = 50;
  pageNumber: number = FIRST_PAGE;
  attPageNumber: number = FIRST_PAGE;
  currentRowIndex: number = 0;
  memberId: string = '';
  menuId: string = '';
  viewId: string = '';
  viewName: string = '';
  summaryViewConfig: any = {};
  cardId: string = '';
  urlViewName: string = '';
  cardName: string = '';
  listLabel: string = '';
  recordId: string = '';
  menuItems: any;
  menuName: string = '';
  detailViewLabel: string = '';
  documentName: string = '';
  isOverview: boolean = false;
  entityId: string = '';

  isVersionHistory: boolean = false;
  isList: boolean = true;
  isLoading: boolean = false;
  attachmentType?: IncludeAttachmentType = IncludeAttachmentType.NoAttachment;

  isCardLevel: boolean = false;
  isDragDrop: boolean = false;
  displayRule: StandalonePropertiesDisplayRule = StandalonePropertiesDisplayRule.None;
  isSummaryView: boolean = false;
  isEditAttachment: boolean = false;
  standaloneIsLoading: boolean = true;
  isLoadingListView: boolean = true;
  isLoadingCardAttachment: boolean = true;
  isEditable: boolean = true;
  isBenefitCard: boolean = false;

  cardEntity?: EntityViewItem;
  cardValue?: CardValue[];
  entitySection?: any;

  standAloneProperty: any;
  standAlonePropertyValue?: StandAlonePropertyOnlyCardData[];
  standAloneViewType?: PropertyDisplay;

  selectedRecordData: any;
  listCardData: (any & Row)[] = [];
  listCardDataOrigin: (any & Row)[] = [];
  listRecordIdAll: string[] = [];

  memberDetail: any;
  detailInfo: any;
  attachments: (MemberNavigationAttachmentList & Row)[] = [];
  metadataNonListSection: any;
  screenId: string = '';
  menuItemId: string = '';
  entityComponentId: string = '';
  isCourtOrder: boolean = false;
  currentCardId!: string;

  targetReferenceLinkedId?: string;
  viewUrl!: string;
  summaryViewId?: string;
  profileNavigateRecordId: string = '';
  entityReferenceLinkedId: string = '';
  currentRowId?: string;

  // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
  qdroLabelName: string = '';

  constructor(
    public route: ActivatedRoute,
    private store: Store<fromReducer.State>,
    private documentStore: Store<DocumentsState>,
    private memberStore: Store<fromMember.MemberState>,

    // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
    private calculationStore: Store<CalculationState>,

    private router: Router,
    private dialog: MatDialog,
    public historyService: HistoryService,
    public entityStore: Store<EntityState>,
    private entityViewStore: Store<EntityViewState>,
    private memberDocumentService: MemberDocumentService,
    private entityDataService: EntityDataService
  ) {
    super();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
  }

  ngOnInit(): void {
    super.ngOnInit();
    // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
    this.selectCalculationQDRO();
    // End of [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9

    this.getParamValue();
    this.getQueryParams();

    this.getCurrentFund();
    this.getCardAction();

    this.getProfileNavigationSelector();
    this.getCardSelector();
    this.getValueStandAlonePropertySelector();
    this.getEntityAttachmentSelector();
    this.getAllListEntityDataSelector();
    this.getListEntityDataSelector();
    this.createUploadMemberEntityAttachmentsSelector();
    this.removeMemberEntityAttachmentsSelector();
    this.deteleEntitySubscribe();
    this.updateMemberCardSelector();
    this.getEntityViewsByEntitySelector();
    this.getProfileNavigation();

    // if (this.router.url.includes(STRING_QUERY_PARAM.PROFILE_NAVIGATE)) {
    // }
  }

  getProfileNavigationSelector(): void {
    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.menu?.length > 0) {
          this.entityId = (state?.memberNavigationList as any).entityId;
        }
      });
  }

  private updateMemberCardSelector(): void {
    this.memberStore.pipe(select(updateMemberCardSelector), takeUntil(this.unsubscribe$)).subscribe((el) => {
      if (el) {
        if (el.success === true) {
          this.bannerType = BannerType.Success;
          this.message = `${el.payload?.cardName} successfully updated.`;
        }

        this.memberStore.dispatch(clearUpdateMemberCardStateAction());
      }
    });
  }

  getCardAction() {
    if (this.viewId !== GUID_EMPTY) {
      const request: GetCardsByViewRequest = {
        viewId: this.viewId,
      };

      this.store.dispatch(getCardsByViewAction({ request }));
    } else {
      this.memberStore.dispatch(getCardDetail({ id: this.currentCardId }));
    }
  }

  getQueryParams() {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((params) => {
        const currentRowIndex = params?.currentRowIndex;
        if (currentRowIndex) {
          this.currentRowIndex = +currentRowIndex;
        }
        if (params?.cardId && this.viewId === GUID_EMPTY) {
          this.currentCardId = params.cardId;
          this.urlViewName = params?.prevName;
          this.viewUrl = params.prevUrl;
        }
        if (params?.currentRowId) {
          this.currentRowId = params?.currentRowId;
        }
      });
  }

  getParamValue() {
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      if (params) {
        this.selectedRecordData = null;
        this.currentRowIndex = 0;
        this.memberId = params?.memberId;
        this.viewId = params?.viewId;
        this.isOverview = stringToBoolean(params?.isOverview);
        this.menuItemId = params?.menuId;
      }
    });
  }

  getEntityViewsByEntitySelector() {
    // Listen for get the list of entity views by entity id
    this.entityViewStore
      .pipe(
        select(getEntityViewsByEntitySelector),
        filter((state) => !!state),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (!state?.isLoading && state?.success && state?.payload) {
          const viewCard = state.payload.find((v) => v.type === EntityViewType.Detail);
          if (viewCard?.id && this.targetReferenceLinkedId) {
            this.dialog.open(BenefitDetailViewComponent, {
              panelClass: ['dialog-full-screen'],
              autoFocus: false,
              disableClose: true,
              data: {
                pageTitle: this.listBreadcrumbs[1]?.name || this.listBreadcrumbs[0]?.name,
                entityId: viewCard.entityId,
                viewName: viewCard.viewName,
                viewId: viewCard.id,
                memberId: this.memberId,
                isOverview: this.isOverview,
                recordId: this.targetReferenceLinkedId,
                screenId: this.screenId,
              },
            });
          }

          this.entityViewStore.dispatch(clearGetEntityViewsByEntityStateAction());
        }
      });
  }

  getCardSelector() {
    combineLatest([
      this.currentCardId
        ? this.memberStore.pipe(select(getCardDetailSelector))
        : this.store.select(fromMember.getCardsSelector),
      this.store.select(fromLayoutReducer.selectMemberNavigationListState),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([card, menu]) => {
        if (card && !card.isLoading && card.success && menu.memberNavigationMenu) {
          if (this.currentCardId) {
            this.summaryViewConfig = menu;
            const cloneCard: any = deepClone(card.payload);
            loadData(
              {
                payload: {
                  viewName: '',
                  entityViewItems: [
                    {
                      ...cloneCard,
                      cardId: cloneCard.id,
                      cardComponents: cloneCard.components.map((i: any) => ({
                        ...i,
                        cardProperties: i.entityProfileComponentItems.map((j: any) => ({
                          ...j,
                          propertyLabel: j.name,
                          propertyId: j.entityPropertyId,
                          componentId: i.id,
                          id: GUID_EMPTY,
                          config: {},
                          type: j.option ? null : j.type,
                        })),
                        componentType: i.type,
                        entityComponentId: i.id,
                      })),
                      isDragDrop: cloneCard.enableDragDrop,
                      attachmentType: cloneCard.includeAttachmentAt,
                      displayRule: 0,
                    },
                  ],
                  isSummaryView: false,
                },
              },
              menu,
            );
          } else loadData(card, menu);
        }
      });
    const loadData = (card: any, menu: MemberNavigationList) => {
      if (this.isOverview) {
        this.screenId = `${menu?.profileName?.replace(/\s/g, '')}_overview_`;
      } else {
        const currentMenu = menu?.memberNavigationMenu?.find(
          (x) => x.menuItems.find((item) => item.id === this.menuItemId) != null,
        );
        const menuName = currentMenu?.name;
        const menuItemName = currentMenu?.menuItems?.find((x) => x.id === this.menuItemId)?.name;
        this.screenId = `${menu?.profileName?.replace(/\s/g, '')}_${menuName?.replace(
          /\s/g,
          '',
        )}_${menuItemName?.replace(/\s/g, '')}_`;
      }
      const memberNavigationMenu: MemberNavigationResponse[] = menu?.memberNavigationMenu ?? [];
      this.menuItems = (
        memberNavigationMenu.map((item) => {
          return item.menuItems;
        }) ?? []
      ).reduce((a, b) => {
        return a.concat(b);
      }, []);

      this.viewName = card?.payload?.viewName ?? '';
      this.cardEntity = card?.payload?.entityViewItems[0];
      this.menuName =
        this.menuItems?.find((item: any) => item?.entityViewId === this.viewId)?.name || this.cardEntity?.cardName;
      this.cardName = this.cardEntity?.cardName ?? '';
      this.listLabel = this.cardEntity?.listLabel ?? '';
      this.cardId = this.cardEntity?.cardId ?? '';
      this.isSummaryView = card?.payload?.isSummaryView ?? false;
      this.isDragDrop = this.cardEntity?.isDragDrop ?? false;
      this.displayRule = this.cardEntity?.displayRule || StandalonePropertiesDisplayRule.None;
      this.isEditable = this.cardEntity?.displayRule !== StandalonePropertiesDisplayRule.None;
      this.listBreadcrumbs[0] = {
        name: this.menuName || '',
      };

      if (this.listBreadcrumbs[0].name === '') {
        let url = '';
        if ((menu as any)?.isOverviewDetailView) {
          url = `/member/detail-view/true/${(menu as any).id}/${(menu as any).overviewViewId}/${this.memberId}`;
        } else {
          url = `/member/summary-view/true/${(menu as any).id}/${(menu as any).overviewViewId}/${this.memberId}`;
        }
        this.listBreadcrumbs = [
          {
            name: (menu as any)?.overviewLabel,
            url: url,
          },
          {
            name: this.cardEntity?.cardName as string,
          },
        ];
      }
      this.isBenefitCard = this.cardEntity?.isBenefitCard ?? false;

      this.attachmentType = this.cardEntity?.attachmentType;

        this.viewName = card?.payload?.viewName || this.urlViewName;
        this.cardEntity = card?.payload?.entityViewItems[0];
        this.menuName = this.menuItems?.find((item: any) => item?.entityViewId === this.viewId)?.name || this.cardEntity?.cardName;
        this.cardName = this.cardEntity?.cardName ?? '';
        this.listLabel = this.cardEntity?.listLabel ?? '';
        this.cardId = this.cardEntity?.cardId ?? '';
        this.isSummaryView = card?.payload?.isSummaryView ?? false;
        this.isDragDrop = this.cardEntity?.isDragDrop ?? false;
        this.displayRule = this.cardEntity?.displayRule || StandalonePropertiesDisplayRule.None;
        this.isEditable = this.cardEntity?.displayRule !== StandalonePropertiesDisplayRule.None;
        this.listBreadcrumbs[0] = {
          name: this.menuName || '',
        };

        if (this.listBreadcrumbs[0].name === '') {
          let url = '';
          if ((menu as any)?.isOverviewDetailView) {
            url = `/member/detail-view/true/${(menu as any).id}/${(menu as any).overviewViewId}/${this.memberId}`;
          } else {
            url = `/member/summary-view/true/${(menu as any).id}/${(menu as any).overviewViewId}/${this.memberId}`;
          }
          this.listBreadcrumbs = [
            {
              name: (menu as any)?.overviewLabel,
              url: url
            },
            {
              name: this.cardEntity?.cardName as string
            }
          ]
        }
        this.isBenefitCard = this.cardEntity?.isBenefitCard ?? false;

        this.attachmentType = this.cardEntity?.attachmentType;

        let isProperty: boolean = false;
        let isList: boolean = false;
        this.cardEntity?.cardComponents?.forEach((element) => {
        if (
          element.componentType === EntityProfileComponentType.Properties &&
          element.cardProperties?.length > 0
        ) {
          isProperty = true;
        }

        if (
          element.componentType === EntityProfileComponentType['List - Detail View'] ||
          element.componentType === EntityProfileComponentType['List - Summary View']
        ) {
          isList = true;
        }

        if (element.componentType === EntityProfileComponentType['Version History']) {
          this.isVersionHistory = true;
        }

        if (this.urlViewName) {
          this.listBreadcrumbs = [{
            url: this.viewUrl,
            name: this.urlViewName,
          }, {
            name: this.menuName || '',
          }];
        }
      });

      if (isProperty && isList) {
        this.viewType = ViewType.ListAndProperty;
      } else if (isProperty) {
        this.viewType = ViewType.PropertyOnly;
      } else if (isList) {
        this.viewType = ViewType.ListOnly;
      } else {
        this.viewType = ViewType.NoneConfig;
      }

      this.isList = this.viewType === ViewType.ListAndProperty || this.viewType === ViewType.ListOnly;

      if (this.isList) {
        this.getListEntityDataAction();
        this.getAllListEntityDataAction();
        this.getAllListEntityDataSelector();
      }
      if (this.isVersionHistory) {
        this.getListEntityDataAction(false, VersionHistory);
        this.getAllListEntityDataAction();
        this.getAllListEntityDataSelector();
      }

      if (this.viewType === ViewType.ListAndProperty || this.viewType === ViewType.PropertyOnly) {
        this.getValueStandAlonePropertyAction();
      }

      const dataStandAloneProperty = this.cardEntity?.cardComponents?.filter(
        (item: CardComponent) => item?.componentType === EntityProfileComponentType.Properties,
      );

      this.detailViewLabel = dataStandAloneProperty ? dataStandAloneProperty[0].detailViewLabel ?? '' : '';
      this.getStandAloneProperty(dataStandAloneProperty);
      this.standAloneViewType = dataStandAloneProperty ? dataStandAloneProperty[0].propertyDisplay : 0;
      // get List Entity
      const listRecord = this.cardEntity?.cardComponents?.find(
        (item: CardComponent) => item?.componentType === EntityProfileComponentType['List - Detail View'],
      );
      this.getListEntity(listRecord?.cardProperties || []);

      this.entitySection = listRecord?.cardProperties;
      if (this.attachmentType === IncludeAttachmentType.CardLevel) {
        this.getEntityAttachmentAction(false, this.cardId); // cardId
      }

      this.settings[0].name = `View ` + this.viewName + ` Configuration`;
      this.settings[1].name = `Card ` + this.cardName + ` Configuration`;
      this.settings[0].url = `/member/view/${this.viewId}?prevUrl=${encodeURIComponent(
        this.router.url
      )}&prevName=${this.menuName}`;
      this.settings[1].url = `/member/card/${this.cardId}?prevUrl=${encodeURIComponent(
        this.router.url,
      )}&prevName=${this.menuName}`;

      if (this.menuName === Screen1099RInfo) {
        this.settings[2] = {
          name: 'Distribution Code Mapping',
          url: `/member/distribution-code-mapping/${this.memberId}?prevUrl=${encodeURIComponent(
            this.router.url
          )}&prevName=${this.menuName}`,
        };
      }
    };
  }

  getListEntity(entity: CardProperty[]) {
    const cloneEntity = deepClone(entity);
    this.columns = [];
    const optColDateList = [CREATED_AT, UPDATED_AT];
    cloneEntity?.forEach((column: any) => {
      if (column?.showOnTableList === false) {
        return;
      }
      if (column?.config?.fixedKey === FixedPropertyKey.Payee) {
        column.type = EntityPropertyType['Entity Reference'];
      }
      const columConfig = getColumConfig(getEntityPropertyName(column.type), column.config);
      const typeDateColFixed = {
        format: 'MM/dd/yyyy hh:mm a',
      };
      this.columns.push({
        name: this.getColumnName(column),
        header: {
          title: column.propertyLabel,
        },
        truncate: true,
        sortable: this.isDragDrop === false,
        type: [ColumnType.Address, ColumnType.PersonName, ColumnType.Binary].includes(columConfig.type as ColumnType)
          ? ColumnType.Text
          : optColDateList.includes(column.option)
            ? ColumnType.DateTime
            : columConfig.type,
        align: column.type === PropertyType.TYPE_REGISTER ? Align.Center : undefined,
        templateArgs:
          columConfig.type === ColumnType.EntityReference
            ? {
                onClick: (row: FundModel & Row) => {
                  this._onClickEntityReference(row, column);
                },
              }
            : optColDateList.includes(column.option)
              ? typeDateColFixed
              : columConfig.templateArgs,
      });
    });

    if (this.attachmentType === IncludeAttachmentType.ListRecord) {
      this.columns.push({
        name: 'noOfAttachments',
        header: {
          title: 'Attachment',
        },
        align: Align.Right,
      });
    }
  }

  getProfileNavigation() {
    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.isHyperlink && state?.menu && state?.menu.length > 0 && this.profileNavigateRecordId) {
          if (state.memberNavigationList?.id === GUID_EMPTY) {
            return;
          }
          const overviewView = state.memberNavigationList as any;
          const isOverviewDetailView = (state.memberNavigationList as any)?.isOverviewDetailView;
          const url = `${isOverviewDetailView
            ? '/member/detail-view/true'
            : '/member/summary-view/true'}/${overviewView.id}/${overviewView.overviewViewId}/${this.profileNavigateRecordId}?${STRING_QUERY_PARAM.PROFILE_NAVIGATE}&entityReferenceLinkedId=${this.entityReferenceLinkedId}`;
          const entityReferenceLinkedIdParam = new URLSearchParams(document.location.search).get("entityReferenceLinkedId");


          if(url.slice(0, url.indexOf('?')) === document.location.pathname
            && this.entityReferenceLinkedId === entityReferenceLinkedIdParam) {
              this.router.navigateByUrl(url);
            }
          else{
            this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
            this.store.dispatch(
              LayoutActions.selectTab({
                tab: DisplayedTabName.IndividualParticipant,
                url,
              }),
            );
          }
        }
      });
  }

  private _onClickEntityReference(row: any & Row, column: CardProperty) {
    const propertyName = this._buildPropertyName(column);
    const recordLinkedId = row[`${propertyName}_recordLinkedId`];
    this.entityReferenceLinkedId = row[`${propertyName}_entityReferenceLinkedId`];

    if (this.cardEntity?.isBenefitCard) {
      if (this.entityReferenceLinkedId) {
        this.entityViewStore.dispatch(getEntityViewsByEntityAction({ entityId: this.entityReferenceLinkedId }));
        this.targetReferenceLinkedId = recordLinkedId;
      }
    } else {
      const recordIdPayee = row[`${propertyName}_recordIdPayee`];

      this.profileNavigateRecordId = row[`${propertyName}_${recordIdPayee ? 'recordIdPayee' : 'recordId'}`];
      this.store.dispatch(
        LayoutActions.profileNavigationItemRequest({
          memberId: this.profileNavigateRecordId,
          entityReferenceLinkedId: this.entityReferenceLinkedId,
          isHyperlink: true
        })
      );
    }
  }

  getColumnName(column: CardProperty): string {
    let columnName = '';
    const optColNameList = [UPDATED_BY, CREATED_AT, CREATED_BY, UPDATED_AT];
    if (optColNameList.includes(column.option)) {
      columnName = GUID_EMPTY;
    } else {
      columnName = column.propertyId;
    }
    if (column.entityReferencePropertyId) {
      columnName = columnName + '_' + column.entityReferencePropertyId;
    }

    if (column.option) {
      columnName = columnName + '_' + column.option;
    }

    return columnName;
  }

  getStandAloneProperty(data?: CardComponent[]) {
    this.standAloneProperty = data;
  }

  getCurrentFund() {
    this.store.pipe(select(fromReducer.selectCurrentFundState), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.currentFund = el;
      this.pageSize = el.defaultPageSize ?? 50;
      this.attPageSize = el.defaultPageSize ?? 50;

      const defaultPageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_CONST));
      const defaultAttPageSize = Number(sessionStorage.getItem(this.currentFund.key + PAGE_SIZE_ATT_CONST));
      this.pageSize = defaultPageSize === 0 ? this.pageSize : defaultPageSize;
      this.attPageSize = defaultAttPageSize === 0 ? this.attPageSize : defaultAttPageSize;
    });
  }

  getAllListEntityDataAction() {
    const request = {
      entityId: this.entityId,
      targetId: this.memberId,
      cardId: this.cardId,
      isSummary: this.isSummaryView,
    };
    this.store.dispatch(getAllListPropertyForCardsAction({ request }));
  }

  getAllListEntityDataSelector() {
    this.store.pipe(select(getAllListPropertyForCardsSelector), takeUntil(this.unsubscribe$)).subscribe((el) => {
      if (el) {
        if (!el.isLoading && el.success) {
          this.listRecordIdAll = [];
          el.payload?.forEach(row => {
            row.values.forEach((value: { recordId: string; }) => {
              let index = this.listRecordIdAll.findIndex(item => item === value.recordId);
              if (index === -1) {
                this.listRecordIdAll.push(value.recordId);
              }
            })
          });
        }
        this.store.dispatch(clearGetAllListPropertyForCardsStateAction());
      }
    });
  }

  getListEntityDataAction(jumpToFirst?: boolean, fixedCardName?: string): void {
    if (jumpToFirst) {
      this.pageNumber = FIRST_PAGE;
    }

    let request: GetListPropertyForCardsRequest = {};
    if (this.isVersionHistory) {
      request = {
        entityId: this.entityId,
        targetId: this.memberId,
        cardId: this.cardId,
        isSummary: this.isSummaryView,
        fixedCardName: fixedCardName,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
    } else {
      request = {
        entityId: this.entityId,
        targetId: this.memberId,
        cardId: this.cardId,
        isSummary: this.isSummaryView,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
    }

    if (this.sortInfo) {
      const field = this.sortInfo.active[0].toUpperCase() + this.sortInfo.active.substr(1);
      request = {
        ...request,
        sortNames: this.sortInfo.direction ? field : '',
        sortType: this.sortInfo.direction === 'asc' ? SortType.ASC : SortType.DESC,
        screenId: this.screenId,
      };
    }

    this.store.dispatch(getListPropertyForCardsAction({ request }));
  }

  getListEntityDataSelector() {
    this.store.pipe(select(getListPropertyForCardsSelector), takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.isLoadingListView = !!el?.isLoading;
      if (el && !el.isLoading && el.success) {
        this.lengthPg = el.total;
        this.isCourtOrder = el?.isCourtOrder ? true : false;
        const listRecord = this.cardEntity?.cardComponents?.find(
          (item: CardComponent) => item?.componentType === EntityProfileComponentType['List - Detail View'],
        );

        const listDataMappingId = (listRecord?.cardProperties || []).map((element: any) => {
          return {
            id: this.getColumnName(element),
            type: element.type,
            config: element.config,
            displayFieldType: element.displayFieldType,
          };
        });
        this.listCardDataOrigin = el.payload || [];
        this.entityComponentId =
          this.listCardDataOrigin.length && this.listCardDataOrigin[0]['values'][0]?.entityComponentId;
        this.listCardData = deepClone(el.payload || [])?.map((item: any) => {
          const obj: any = {};
          item?.values?.forEach((element: any, index: any) => {
            let columnName = element.entityReferencePropertyId ? element.entityReferencePropertyId : '';
            if (element.entityPropertyId) {
              columnName = columnName ? columnName + '_' + element.entityPropertyId : element.entityPropertyId;
            }

            if (element.option) {
              columnName = columnName + '_' + element.option;
            }

            const options = listDataMappingId.find((item) => item.id === columnName);
            if (options?.config?.fixedKey === FixedPropertyKey.Payee) {
              element.propertyType = EntityPropertyType['Entity Reference'];
              if (!element?.entityReferenceLinkedId) {
                obj[`${columnName}_isDisableLink`] = true;
              } else {
                obj[`${columnName}_recordIdPayee`] = element.value;
              }
            }
            obj[`${columnName}`] = getEntityValue(element, options);

            // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
            const qdroOption = element?.options?.find((opt: any) => opt?.code === COURT_ORDER_TYPE_CODE.QDRO);
            if (qdroOption && qdroOption?.id === element?.value && element?.propertyType === EntityPropertyType.Lookup) {
              obj[`${columnName}`] = obj[`${columnName}`].replaceAll(CalculationType[CalculationType.QDRO], this.qdroLabelName);
            }
            // End of [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9

            if (element?.entityReferenceLinkedId) {
              obj[`${columnName}_entityReferenceLinkedId`] = element?.entityReferenceLinkedId;
            }

            if (element?.recordLinkedId) {
              obj[`${columnName}_recordLinkedId`] = element?.recordLinkedId;
            }

            if (element?.propertyType === EntityPropertyType['Entity Reference']) {
              obj[`${columnName}_recordId`] = element.recordId;
            }

            if (element?.isExistProfile != undefined) {
              obj[`${columnName}_isExistProfile`] = element.isExistProfile;
            }
          });
          return { ...obj, id: item.id, noOfAttachments: item?.noOfAttachments, values: item.values };
        });
        if (!this.selectedRecordData) {
          if (this.currentRowId) {
            const rowIndex = this.listCardDataOrigin.findIndex(i => i.id === this.currentRowId);
            if (rowIndex > -1) {
              this.currentRowIndex = rowIndex
            }
          }
          this.selectedRecordData = this.listCardDataOrigin ? this.listCardDataOrigin[this.currentRowIndex] : [];
        } else {
          const checkRecordData = this.listCardDataOrigin?.find(item => item.id ===  this.selectedRecordData?.id);
          if (checkRecordData) {
            this.selectedRecordData = checkRecordData;
          } else {
            this.selectedRecordData = this.listCardDataOrigin ? this.listCardDataOrigin[this.currentRowIndex] : [];
          }
        }

        if (this.listCardData.length > 0) {
          if (this.attachmentType === IncludeAttachmentType.ListRecord) {
            this.getEntityAttachmentAction(false, this.selectedRecordData ? this.selectedRecordData.id : this.listCardData[0]?.id);
          }
          this.recordId = this.listCardData ? this.listCardData[0]?.id : '';
        } else if (this.listCardData.length === 0 && this.attachmentType === IncludeAttachmentType.ListRecord) {
          this.getEntityAttachmentAction(false, this.cardId);
          this.recordId = this.cardId;
        }
        const detailCard = this.listCardData.find((elm) => elm.id === this.recordId);
        if (detailCard) {
          this.entitySection = (this.entitySection || [])?.map((item: any) => {
            const dataCol = GUID_EMPTY + '_' + item.option;
            return {
              ...item,
              dataCol: detailCard[dataCol],
              label: !!item.propertyId ? detailCard[item.propertyId] : '-',
            };
          });
        }
         this.store.dispatch(clearGetListPropertyForCardsStateAction());
      }
    });
  }

  getValueStandAlonePropertyAction() {
    const request: GetStandAlonePropertyOnlyCardDataRequest = {
      cardId: this.cardId,
      targetId: this.memberId,
      entityId: this.entityId,
      screenId: this.screenId,
    };

    this.store.dispatch(getStandAlonePropertyOnlyCardDataAction({ request }));
  }

  getValueStandAlonePropertySelector() {
    this.store
      .pipe(select(getStandAlonePropertyOnlyCardDataSelector), takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (data && !data.isLoading) {
          this.standaloneIsLoading = data.isLoading;
          this.standAlonePropertyValue = data?.payload || [];
        }
      });
  }

  getEntityAttachmentAction(jumpToFirst?: boolean, targetId?: string): void {
    if (jumpToFirst) {
      this.pageNumber = FIRST_PAGE;
    }

    let request: GetMemberEntityAttachmentsRequest = {
      targetId: targetId,
      memberId: this.memberId,
      pageNumber: this.attPageNumber,
      pageSize: this.attPageSize,
    };

    if (this.sortInfo) {
      const field = this.sortInfo.active[0].toUpperCase() + this.sortInfo.active.substr(1);
      request = {
        ...request,
        sortNames: this.sortInfo.direction ? field : '',
        sortType: this.sortInfo.direction === 'asc' ? SortType.ASC : SortType.DESC,
      };
    }

    this.store.dispatch(getMemberEntityAttachmentsAction({ request }));
  }

  getEntityAttachmentSelector() {
    this.store
      .pipe(select(fromMember.getMemberEntityAttachmentsSelector), takeUntil(this.unsubscribe$))
      .subscribe((ele) => {
        if (ele && !ele.isLoading) {
          this.isLoadingCardAttachment = ele.isLoading;
          this.attachments = (ele.payload || []).map((att: any) => {
            return {
              ...att,
              attachment: att?.fileSnapshot?.fileName,
            };
          });

          this.isLoading = false;
          this.attLengthPg = ele.total || 0;
        }
      });

    this.store.dispatch(MemberDetailActions.clearCreateUploadMemberEntityAttachmentsStateAction());
    this.store.dispatch(MemberDetailActions.clearRemoveMemberEntityAttachmentsStateAction());
  }

  createUploadMemberEntityAttachmentsSelector() {
    this.store
      .pipe(select(fromMember.createUploadMemberEntityAttachmentsSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state && !state.isLoading && (this.documentName || this.menuName)) {
          if (state.success) {
            this.bannerType = BannerType.Success;
            this.getEntityAttachmentAction(true, this.cardId);
          }

          if (state.error) {
            this.bannerType = BannerType.Fail;
          }

          let message = '';

          if (this.isEditAttachment) {
            message = this.documentName;
          } else {
            message = `${this.menuName} document`;
          }

          showBanner.call(this, this.bannerType, message, !this.isEditAttachment ? ACTION.UPLOAD : ACTION.EDIT);
          this.documentName = '';
          this.store.dispatch(clearCreateUploadMemberEntityAttachmentsStateAction());
        }
      });
  }

  removeMemberEntityAttachmentsSelector() {
    this.store
      .pipe(select(fromMember.removeMemberEntityAttachmentsSelector), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state && !state.isLoading) {
          if (state.success) {
            this.bannerType = BannerType.Success;
            this.getEntityAttachmentAction(true, this.cardId);
          }

          if (state.error) {
            this.bannerType = BannerType.Fail;
          }

          showBanner.call(this, this.bannerType, this.documentName, ACTION.REMOVE);
        }
      });
  }

  //#region standalone property ()
  onClickEditDetail(): void {
    if (this.standaloneIsLoading) {
      return;
    }
    const dialogRef = this.dialog.open(CreateEntityDataComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      ariaLabel: 'Create New Entity',
      autoFocus: false,
      data: {
        section: this.cardEntity,
        memberId: this.memberId,
        cardData: this.standAlonePropertyValue,
        detailViewLabel: this.detailViewLabel,
        screenId: this.screenId,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'submit') {
        this.getValueStandAlonePropertyAction();
      }
    });
  }
  //#endregion

  //#region Selected Record
  onClickEditRecordSelected(row: Row): void {
    const dialogRef = this.dialog.open(CreateEntityDataComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      ariaLabel: 'Create New Entity',
      autoFocus: false,
      data: {
        section: {
          cardId: this.cardId,
          cardName: this.listLabel,
          entityId: this.entityId,
          entityComponentId: row.values[0].entityComponentId,
          isCourtOrder: this.isCourtOrder,
        },
        isSummaryView: this.isSummaryView,
        cardData: row.values,
        recordId: row.id,
        memberId: this.memberId,
        isList: true,
        screenId: this.screenId,
        listRecordId: this.listRecordIdAll,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'submit') {
        this.bannerType = BannerType.Success;
        this.entityStore.dispatch(clearUpsertComponentListDataStateAction());
        showBanner.call(this, this.bannerType, this.cardName, ACTION.EDIT);
        this.getListEntityDataAction();
        this.getAllListEntityDataAction();
        this.getAllListEntityDataSelector();
      } else if (result === 'submitFail') {
        this.bannerType = BannerType.Fail;
        this.entityStore.dispatch(clearUpsertComponentListDataStateAction());
        showBanner.call(this, this.bannerType, this.cardName, ACTION.EDIT);
      }
    });
  }

  onClickRemoveRecordSelected(row: Row): void {
    if (this.cardEntity?.cardComponentType === CardComponentType.Dependents) {
      this.entityDataService.checkDenpendentRecordInUse(this.memberId, row.id).subscribe(
        res => {
          if (res.isInUse) {
            const dialogRef = this.dialog.open(ConfirmPopupComponent, {
              panelClass: 'confirm-popup',
              data: {
                type: ConfirmType.Delete,
                title: 'Remove Record',
                cancelButtonTitle: 'No',
                text: `This Related Entity is also a beneficiary. Are you sure you want to remove this record?`,
              },
            });
            dialogRef.afterClosed().subscribe((result) => {
              if (result) {
                this.entityStore.dispatch(
                  deleteEntityListDataAction({
                    query: {
                      id: row.id,
                      entityComponentId: row.values[0].entityComponentId,
                    },
                  }),
                );
                this.currentRowIndex = 0;
                this.selectedRecordData = undefined;
              }
            });
          } else {
            this.onRemoveRecord(row);
          }
        }
      )
    } else {
      this.onRemoveRecord(row);
    }
  }

  private onRemoveRecord(row: Row) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        type: ConfirmType.Delete,
        title: 'Remove Record',
        cancelButtonTitle: 'No',
        text: 'Are you sure you want to remove this record?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.entityStore.dispatch(
          deleteEntityListDataAction({
            query: {
              id: row.id,
              entityComponentId: row.values[0].entityComponentId,
            },
          }),
        );
        
        this.currentRowIndex = 0;
        this.selectedRecordData = undefined;
      }
    });
  }

  private deteleEntitySubscribe(): void {
    this.entityStore.pipe(select(deleteEntityListDataSelector), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.success === undefined) return;
      this.entityStore.dispatch(clearDeleteEntityListDataStateAction());
      const customMessage = `The relationship couldn't be deleted because it's used for a benefit.`;
      if (state?.success) {
        this.bannerType = BannerType.Success;
        this.getListEntityDataAction();
        this.getAllListEntityDataAction();
        this.getAllListEntityDataSelector();
      } else if ((state?.error?.error?.errorMessage || []).includes(customMessage)) {
        this.bannerType = BannerType.Hidden;
        this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          data: {
            text: customMessage,
            type: ConfirmType.Warning,
            title: 'Error',
            cancelButtonTitle: 'Cancel',
            hideConfirmButton: true,
          }
        });
        return;
      } else {
        this.bannerType = BannerType.Fail;
      }
      showBanner.call(this, this.bannerType, this.cardName, ACTION.REMOVE, {
        customMessage: state?.success && this.cardEntity?.cardComponentType === CardComponentType.Dependents ? `Related Entity successfully removed, please revise the Beneficiary list if necessary.` : ''
      });
    });
  }
  //#endregion

  //#region Manage Attachment
  onChangePageManageAttachments(event: PageEvent): void {
    this.attPageSize = event.pageSize;
    this.attPageNumber = event.pageNumber;

    this.getEntityAttachmentAction(false, this.recordId);
  }
  //#endregion

  //#region List card

  private _getEntityComponentId(configCard: any, options: any) {
    return configCard.cardComponents.find(({ componentType }: { componentType: number }) =>
      componentType === options.isAddRecord ? 1 : 0,
    )?.componentId;
  }

  private _buildPropertyName(prop: CardProperty) {
    let propertyName = '';
    if (prop.entityReferencePropertyId) {
      propertyName += prop.entityReferencePropertyId;
    }
    if (prop.propertyId) {
      if (propertyName) {
        propertyName += '_';
      }
      propertyName += prop.propertyId;
    }
    if (prop.option) {
      if (propertyName) {
        propertyName += '_';
      }
      propertyName += prop.option;
    }
    return propertyName;
  }

  openMetaDataForm(section: any, addToTop: boolean = true, isAddNew: boolean = true) {
    const dialogRef = this.dialog.open(CreateEntityDataComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      ariaLabel: 'Create New Entity',
      autoFocus: false,
      data: {
        entityComponentId: this.entityComponentId,
        section: {
          cardId: this.cardId,
          cardName: this.listLabel,
          entityId: this.cardEntity?.entityId || '',
          entityComponentId: this._getEntityComponentId(this.cardEntity, { isAddRecord: isAddNew }),
          isCourtOrder: this.isCourtOrder,
        },
        isSummaryView: this.isSummaryView,
        isAddRecord: isAddNew,
        addToTop,
        memberId: this.memberId,
        isList: true,
        listRecordId: this.listRecordIdAll,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result === 'submit') {
        this.currentRowIndex = 0;
        this.selectedRecordData = null;
        this.bannerType = BannerType.Success;
        showBanner.call(this, this.bannerType, this.cardName, ACTION.ADD);
        this.entityStore.dispatch(clearUpsertComponentListDataStateAction());
        this.getListEntityDataAction();
        this.getAllListEntityDataAction();
        this.getAllListEntityDataSelector();
      } else if (result === 'submitFail') {
        this.bannerType = BannerType.Fail;
        this.entityStore.dispatch(clearUpsertComponentListDataStateAction());
        showBanner.call(this, this.bannerType, this.cardName, ACTION.ADD);
      }
    });
  }
  manageSection() {
    const entityReferenceLinkedId = new URLSearchParams(document.location.search).get("entityReferenceLinkedId");
    this.router.navigateByUrl(
      `/entity-management/entities/${this.entityId}/cards/${this.cardId}/list-data/${this.memberId}
      ?attachmentType=${this.attachmentType}
      &isSummaryView=${this.isSummaryView}
      &viewName=${this.viewName || this.summaryViewConfig?.overviewLabel}
      &cardName=${this.listLabel}
      &isBenefitCard=${this.isBenefitCard}
      &cardComponentType=${this.cardEntity?.cardComponentType}
      &attachmentLabel=${this.cardName}
      &viewUrl=${encodeURIComponent(this.router.url)}${entityReferenceLinkedId ? `&entityReferenceLinkedId=${entityReferenceLinkedId}` : ''}`,
    );
  }

  sortChangeListView(event: any) {
    this.sortInfo = event;
  }

  changePaggingListView(event: PageEvent) {
    this.currentRowIndex = 0;
    this.selectedRecordData = null;
    this.pageSize = event.pageSize;
    this.pageNumber = event.pageNumber;

    sessionStorage.setItem(this.currentFund.key + PAGE_SIZE_CONST, this.pageSize.toString());

    if (this.isVersionHistory) {
      this.getListEntityDataAction(false, VersionHistory);
    } else {
      this.getListEntityDataAction();
    }
  }

  selectRow(event: any) {
    this.recordId = event.id;
    this.selectedRecordData = this.listCardDataOrigin?.find((item: any) => item?.id === event?.id);
    if (this.attachmentType === IncludeAttachmentType.ListRecord) {
      this.getEntityAttachmentAction(false, event.id);
    }
    this.entitySection = this.entitySection.map((item: any) => {
      const dataCol = GUID_EMPTY + '_' + item.option;
      return {
        ...item,
        dataCol: event[dataCol],
        label: !!item.propertyId ? event[item.propertyId] : '-',
      };
    });
  }

  //#endregion

  //#region  card attachment

  changeCardAttachmentPagging(event: PageEvent) {
    this.attPageSize = event.pageSize;
    this.attPageNumber = event.pageNumber;

    this.getEntityAttachmentAction(false, this.cardId);
  }

  onOpenUpLoadAttachment(attachment: any, isEditAttachment: boolean) {
    this.isEditAttachment = isEditAttachment;
    this.documentName = attachment?.name;

    // Open form upload
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    };

    if (isEditAttachment) {
      let rowData = deepClone(attachment);
      rowData['tagDescriptionsList'] = rowData?.tags;
      rowData['showOnOverview'] = rowData?.showOnOverview === true ? 'Yes' : 'No';
      rowData['documentLocation'] = rowData?.documentLocationTitle || this.cardName;
      rowData['documentLocationRouter'] =
        rowData?.documentLocationRouter ||
        `/member/detail-view/${this.isOverview}/${this.menuItemId}/${this.viewId}/${this.memberId}`;

      const infoForm = {
        isUploadMultipleFile: false,
        validateDocumentName: checkApiValidator(
          this.memberDocumentService.checkExistDocumentName,
          'documentName',
          undefined,
          {
            params: {
              memberId: this.memberId,
              documentId: rowData.id,
            }
          }
        ),
      };
      const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
        panelClass: 'dialog-full-screen',
        disableClose: true,
        data: {
          currentEntity,
          document: rowData,
          infoForm,
        },
      });

      editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
        if (objectUpload) {
          const body = {
            // Un-editable
            id: rowData.id,
            fileName: rowData.fileName,
            documentLocationTitle: rowData?.documentLocationTitle || this.cardName,
            documentLocationRouter:
              rowData?.documentLocationRouter ||
              `/member/detail-view/${this.isOverview}/${this.menuItemId}/${this.viewId}/${this.memberId}`,
            attachmentType: rowData?.attachmentType || this.attachmentType,
            targetId: rowData?.targetId,
            memberId: this.memberId,

            // Editable
            description: objectUpload.description,
            documentDescription: objectUpload.description,
            tags: objectUpload.tags,
            showOnOverview: objectUpload.showOnOverview,
            documentName: objectUpload.documentName,
            participantDocumentType: objectUpload?.participantDocumentType,
          };

          this.store.dispatch(
            MemberDetailActions.createUploadMemberEntityAttachmentsAction({
              body: [body],
            }),
          );
        }
      });

      return;
    }
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: checkApiValidator(
        this.memberDocumentService.checkExistDocumentName,
        'documentName',
        undefined,
        {
          params: {
            memberId: this.memberId
          }
        }
      ),
      defaultShowOnOverview: true,
    };
    const specificMenuData = {
      shouldUseCommonBreadcrumbs: true,
      usedForMenu: USED_FOR_MENU.ATTACHMENTS,
      documentTypeOptionList: null,
      acceptFile: '.pdf',
      recordId: this.attachmentType === IncludeAttachmentType.ListRecord ? this.recordId :
        (this.attachmentType === IncludeAttachmentType.CardLevel ? this.cardId : ''),
      memberId: this.memberId,
      checkPattern: new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i'),
    };

    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        infoForm,
        specificMenuData,
      },
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const body = {
          fileName: objectUpload?.attachmentFile.name,
          description: objectUpload.description,
          documentDescription: objectUpload.description,
          tags: objectUpload.tags,
          showOnOverview: objectUpload.showOnOverview,
          documentName: objectUpload.documentName,
          documentLocationTitle: this.cardName,
          documentLocationRouter: `/member/detail-view/${this.isOverview}/${this.menuItemId}/${this.viewId}/${this.memberId}`,
          attachmentType: this.attachmentType,
          file: objectUpload?.attachmentFile,
          targetId: this.attachmentType === IncludeAttachmentType.ListRecord ? this.recordId :
            (this.attachmentType === IncludeAttachmentType.CardLevel ? this.cardId : ''),
          memberId: this.memberId,
          participantDocumentType: objectUpload?.participantDocumentType,
        };

        this.store.dispatch(
          MemberDetailActions.createUploadMemberEntityAttachmentsAction({
            body: [body],
          }),
        );
      }
    });
  }

  onRemoveAttachment(attachment: any) {
    const dialogRef = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: `Are you sure you want to remove this ${this.viewName}: ${attachment.documentName}?`,
        type: ConfirmType.Delete,
        title: 'Remove Item',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.documentName = attachment.documentName;
        this.store.dispatch(
          MemberDetailActions.removeMemberEntityAttachmentsAction({
            memberId: this.memberId,
            attachmentId: attachment?.id,
          }),
        );
      }
    });
  }

  onOpenPDF(row: any) {
    if (!row) {
      return;
    }
    this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
    this.documentStore.dispatch(
      getDocumentDownloadAction({ fileId: row?.id as string, fileName: row.fileName as string }),
    );
  }
  //#endregion

  reloadOverviewHeader() {
    this.overViewHeader.memberId = this.memberId;
    this.overViewHeader.getMemberProfileData();
    this.overViewHeader.checkMemberIndex();
  }

  openOverview(sectionKey: string, isShowBanner?: boolean, saveSectionDataSuccess?: boolean) {
    this.router.navigateByUrl(`/member/detail/${sectionKey}/${this.memberId}?isMenu=true`, {
      state: {
        isShowBanner,
        saveSectionDataSuccess,
      },
    });
  }

  openEditDialog(options: any) {}

  isSpecialPage() {
    return (
      this.currentSectionLayout &&
      [
        SectionLayout.StatusHistory,
        SectionLayout.PaymentHistory,
        SectionLayout.MunicipalityPayment,
        SectionLayout.MunicipalityServiceRecord,
      ].includes(this.currentSectionLayout)
    );
  }

  // FIXME: [QuynhDV1] 116735: workaround for v0.8 needs to be fixed for v0.9
  private selectCalculationQDRO() {
    this.calculationStore
      .select(getCalculationQDROSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((getCalculationQDRO) => {
        if (!getCalculationQDRO?.isLoading && getCalculationQDRO?.success) {
          this.qdroLabelName = getCalculationQDRO?.payload?.labelName ?? '';
        }
      });
  }
}
