<div
  class="grid-container flex flex-col"
  [ngClass]="{ 'fit-to-parent': fitToParent }"
>
    <div class="table-container" [ngClass]="{ 'hide-scrollbar': hideScrollbar }" tabindex="0">
      <table
        mat-table
        [dataSource]="dataTable"
        matSort
        (matSortChange)="onChangeSort($event)"
        multiTemplateDataRows
        class="mat-elevation-z8"
      >
        <ng-container *ngFor="let col of columnsToDisplay" [matColumnDef]="col.name">
          <th 
            mat-header-cell
            *matHeaderCellDef 
            mat-sort-header
            [class]="getColumnClasses(col)"
            [ngStyle]="getHeaderStyle(col)"
            [disabled]="!col.sortable"
            >{{ col.header?.title || '' }}
          </th>
          <td mat-cell *matCellDef="let row" 
            [class]="getColumnClasses(col)"
            [class.disabled]="col.type === ColumnType.Icon && row.isDisabledExpandRow"
            [ngStyle]="getCellStyle(col)"
            (click)="changeIcon(row, col, $event)"
          >
            <ng-container *ngIf="col.truncate; else nonTooltip">
              <ptg-custom-truncate-tooltip [tooltipText]="row[col.name]" [tooltipTemplate]="cellContentTemplate"></ptg-custom-truncate-tooltip>
            </ng-container>

            <ng-template #nonTooltip>
              <ng-container [ngTemplateOutlet]="cellContentTemplate"></ng-container>
            </ng-template>
            <ng-template #cellContentTemplate>
              <ng-container [ngSwitch]="col.type">
                <ptg-number-decimal-column
                  *ngSwitchCase="ColumnType.Decimal"
                  [value]="row[col.name]"
                  [decimal]="
                    col.templateArgs?.decimal || col.templateArgs?.decimal === 0
                      ? col.templateArgs?.decimal
                      : 2
                  "
                  [unit]="col.templateArgs?.unit"
                  [unitPosition]="col.templateArgs?.unitPosition || 'left'"
                ></ptg-number-decimal-column>
                <ptg-datetime-column
                  *ngSwitchCase="ColumnType.DateTime"
                  [value]="row[col.name]"
                  [format]="col.templateArgs?.format || 'MM/dd/yyyy'"
                  [emptyString]="col.templateArgs?.emptyString"
                  [keepLocalTime]="col.templateArgs?.keepLocalTime"
                ></ptg-datetime-column>
  
                <ptg-view-rich-text
                  *ngSwitchCase="ColumnType.RichText"
                  [title]="col.header?.title"
                  [content]="row[col.name]"
                ></ptg-view-rich-text>
  
                <ptg-phone-column
                  *ngSwitchCase="ColumnType.PhoneNumber"
                  [value]="row[col.name]"
                ></ptg-phone-column>
  
                <ptg-icon-column
                  *ngSwitchCase="ColumnType.Icon"
                  [name]="row[col.templateArgs?.nameField]"
                  [color]="row[col.templateArgs?.colorField]"
                  [label]="
                    col.templateArgs?.labelField
                      ? row[col.templateArgs?.labelField]
                      : ''
                  "
                  [style]="col.templateArgs?.styleField"
                ></ptg-icon-column>
  
                <ptg-address-column
                  *ngSwitchCase="ColumnType.Address"
                  [value]="row[col.name]"
                ></ptg-address-column>
  
                <ptg-person-name-column
                  *ngSwitchCase="ColumnType.PersonName"
                  [value]="row[col.name]"
                ></ptg-person-name-column>
  
                <ptg-binary-column
                  *ngSwitchCase="ColumnType.Binary"
                  [value]="row[col.name]"
                  [templateArgs]="col.templateArgs"
                ></ptg-binary-column>
  
                <ng-container *ngSwitchDefault>
                  <ng-container
                    [ngTemplateOutlet]="
                      getCellContentTemplate(col.name) || defaultTemplate
                    "
                    [ngTemplateOutletContext]="{ $implicit: row }"
                  ></ng-container>
  
                  <ng-template #defaultTemplate>
                    {{ col.cell ? col.cell(row) : row[col.name] }}
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-template>
          
          </td>
        </ng-container>
    
        <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td
            mat-cell
            *matCellDef="let element"
            [attr.colspan]="columnsToDisplay.length"
            style="padding: 0;"
            >
              <div
                *ngIf="isExpandCollapse else notExpandCollapse"
                class="element-detail grid-container"
                [@detailExpand]="allowExpandMultiple ? element.statusIconName === 'keyboard_arrow_up' ? 'expanded' : 'collapsed' :
                element == expandedElement ? 'expanded' : 'collapsed'"
              >
                <ng-container [ngTemplateOutlet]="expandTableContent"></ng-container>
                  
              </div>

              <ng-template #notExpandCollapse>
                <div class="element-detail grid-container">
                  <ng-container [ngTemplateOutlet]="expandTableContent"></ng-container>
                </div>
              </ng-template>
              <ng-template #expandTableContent>
                <div class="table-detail-container">
                  <table
                  mat-table
                  [dataSource]="element.detail"
                  multiTemplateDataRows
                  class="mat-elevation-z8"
                  >
                  <ng-container *ngFor="let col of columnsExpandToDisplay" [matColumnDef]="col.name">
                    <td mat-cell *matCellDef="let row"
                      [class]="getColumnClasses(col)"
                      [ngStyle]="getCellStyle(col)"
                    >
                      <ng-container *ngIf="col.truncate; else expandNonTooltip">
                        <ptg-custom-truncate-tooltip [tooltipText]="row[col.name]" [tooltipTemplate]="expandCellContentTemplate"></ptg-custom-truncate-tooltip>
                      </ng-container>
                      <ng-template #expandNonTooltip>
                        <ng-container [ngTemplateOutlet]="expandCellContentTemplate"></ng-container>
                      </ng-template>
                      <ng-template #expandCellContentTemplate>
                        <ng-container [ngSwitch]="col.type">
                          <ptg-number-decimal-column
                            *ngSwitchCase="ColumnType.Decimal"
                            [value]="row[col.name]"
                            [decimal]="
                              col.templateArgs?.decimal || col.templateArgs?.decimal === 0
                                ? col.templateArgs?.decimal
                                : 2
                            "
                            [unit]="col.templateArgs?.unit"
                            [unitPosition]="col.templateArgs?.unitPosition || 'left'"
                          ></ptg-number-decimal-column>
                          <ptg-datetime-column
                            *ngSwitchCase="ColumnType.DateTime"
                            [value]="row[col.name]"
                            [format]="col.templateArgs?.format || 'MM/dd/yyyy'"
                            [emptyString]="col.templateArgs?.emptyString"
                            [keepLocalTime]="col.templateArgs?.keepLocalTime"
                          ></ptg-datetime-column>
            
                          <ptg-view-rich-text
                            *ngSwitchCase="ColumnType.RichText"
                            [title]="col.header?.title"
                            [content]="row[col.name]"
                          ></ptg-view-rich-text>
            
                          <ptg-phone-column
                            *ngSwitchCase="ColumnType.PhoneNumber"
                            [value]="row[col.name]"
                          ></ptg-phone-column>
            
                          <ptg-icon-column
                            *ngSwitchCase="ColumnType.Icon"
                            [name]="row[col.templateArgs?.nameField]"
                            [color]="row[col.templateArgs?.colorField]"
                            [label]="
                              col.templateArgs?.labelField
                                ? row[col.templateArgs?.labelField]
                                : ''
                            "
                            [style]="col.templateArgs?.styleField"
                          ></ptg-icon-column>
            
                          <ptg-address-column
                            *ngSwitchCase="ColumnType.Address"
                            [value]="row[col.name]"
                          ></ptg-address-column>
            
                          <ptg-person-name-column
                            *ngSwitchCase="ColumnType.PersonName"
                            [value]="row[col.name]"
                          ></ptg-person-name-column>
            
                          <ptg-binary-column
                            *ngSwitchCase="ColumnType.Binary"
                            [value]="row[col.name]"
                            [templateArgs]="col.templateArgs"
                          ></ptg-binary-column>
            
                          <ng-container *ngSwitchDefault>
                            <ng-container
                              [ngTemplateOutlet]="
                                getCellContentTemplate(col.name) || defaultTemplate
                              "
                              [ngTemplateOutletContext]="{ $implicit: row }"
                            ></ng-container>
            
                            <ng-template #defaultTemplate>
                              {{ col.cell ? col.cell(row) : row[col.name] }}
                            </ng-template>
                          </ng-container>
                        </ng-container>
                      </ng-template>
                    </td>
                  </ng-container>
                    <tr
                      mat-row
                      *matRowDef="let element; columns: columnNameDetails;"
                      class="element-row"
                    ></tr>
                  </table>
                </div>
              </ng-template>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="columnNames; sticky: fixedHeader"></tr>
        <tr
            mat-row
            *matRowDef="let element; let idx = index; columns: columnNames;"
            class="element-row"
            [class]="getRowClasses(element)"
            [class.expanded-row]="allowExpandMultiple ? element.statusIconName === 'keyboard_arrow_up' : expandedElement === element"
            [class.can-expanded]="element.statusIconName"
            (click)="onClickRow(element, idx, $event)"
        ></tr>
        <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            class="detail-row"
        ></tr>
      </table>
    </div>
    <div class="flex-grow flex">
        <div *ngIf="isLoading; else notLoading" class="spinner m-auto">
          <mat-progress-spinner diameter="50" mode="indeterminate">
          </mat-progress-spinner>
          <p>Loading...</p>
        </div>
        <ng-template #notLoading>
          <div *ngIf="!dataTable?.length && notFoundMessage" class="not-found m-auto flex flex-col">
            <ng-container *ngIf="errorMessage; else notError">
              <div class="warning-icon">
                <mat-icon class="material-icons-round">warning</mat-icon>
              </div>
              <div>
                <span class="error-msg">{{ errorMessage }} error</span>
                <span>occurred, please try again later.</span>
              </div>
            </ng-container>
    
            <ng-template #notError>
              {{ notFoundMessage }}
            </ng-template>
          </div>
        </ng-template>
      </div>
    
      <ptg-pagination
        *ngIf="paginable"
        #paginator
        [totalRecords]="totalRecords"
        [pageSizeOptions]="pageSizeOptions"
        [pageSize]="pageSize"
        [pageNumber]="pageNumber"
        [maxPages]="maxPages"
        [hiddenPageSizeOptions]="hiddenPageSizeOptions"
        (pageChange)="onChangePage($event)"
      ></ptg-pagination>
</div>