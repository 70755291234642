import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance';
import {
  AddEditBuyBackMakeUpRequest,
  BuyBackMakeUpYearRecordResponse,
  EditBuyBackMakeUpInterestFeesRequest,
  GetBuyBackMakeUpDetailListRequest,
  GetBuyBackMakeUpDetailListResponse,
  GetBuyBackMakeUpListRequest,
  GetBuyBackMakeUpListResponse,
  GetEligibleYearRequest,
  GetEligibleYearResponse,
  GetListMunicipalityRequest,
  GetListMunicipalityResponse,
  ValidateBuyBackMakeUpRequest,
  ValidateBuyBackMakeUpResponse
} from './models/buyback-makeup.model';

@Injectable({
  providedIn: 'root',
})
export class BuyBackMakeUpService {
  constructor(private httpClient: HttpClient) {}

  getListBuyBackMakeUp(
    request: GetBuyBackMakeUpListRequest,
  ): Observable<GetBuyBackMakeUpListResponse> {
    const memberId = request.memberId;

    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    return this.httpClient.get<GetBuyBackMakeUpListResponse>(
      `${environment.apiUrl}/Members/${memberId}/buyback-makeup/getListBuybackMakeup`,
      { params },
    );
  }

  getListBuyBackMakeUpDetail(
    request: GetBuyBackMakeUpDetailListRequest,
  ): Observable<GetBuyBackMakeUpDetailListResponse> {
    const memberId = request.memberId;
    const buyBackMakeUpId = request.buyBackMakeUpId;

    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    return this.httpClient.get<any>(
      `${environment.apiUrl}/Members/${memberId}/buyback-makeup/${buyBackMakeUpId}/getListBuybackMakeupDetail`,
      { params },
    );
  }

  removeBuyBackMakeUp(memberId: string, buyBackMakeUpId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/Members/${memberId}/buyback-makeup/${buyBackMakeUpId}`
    );
  }

  getEligibleYear(
    request: GetEligibleYearRequest,
  ): Observable<GetEligibleYearResponse> {
    const memberId = request.memberId;

    return this.httpClient.get<GetEligibleYearResponse>(
      `${environment.apiUrl}/Members/${memberId}/buyback-makeup/getEligibleYear`,
      {  },
    );
  }

  getListMunicipality(
    request: GetListMunicipalityRequest,
  ): Observable<GetListMunicipalityResponse> {
    const memberId = request.memberId;
    const buyBackMakeUpId = request.buyBackMakeUpId;

    let params = new HttpParams();
    params = params.append('buyBackMakeUpId', buyBackMakeUpId);

    return this.httpClient.get<GetListMunicipalityResponse>(
      `${environment.apiUrl}/Members/${memberId}/buyback-makeup/getlistMunicipality`,
      { params }
    );
  }

  addEditBuyBackMakeUp(
    request: AddEditBuyBackMakeUpRequest,
    memberId: string,
  ) {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/${memberId}/buyback-makeup`,
      request,
    );
  }

  initiateRemittanceBuyBackMakeUp(
    memberId: string,
    buybackMakeupId: string,
  ) {
    return this.httpClient.post(
      `${environment.apiUrl}/Members/${memberId}/buyback-makeup/${buybackMakeupId}/remittances`,
      { },
    );
  }

  editBuyBackMakeUpInterestFees(
    memberId: string,
    buyBackMakeUpId: string,
    yearRecordId: string,
    request: EditBuyBackMakeUpInterestFeesRequest,
  ) {
    return this.httpClient.put(
      `${environment.apiUrl}/Members/${memberId}/buyback-makeup/${buyBackMakeUpId}/${yearRecordId}`,
      request,
    );
  }
  
  getBuyBackMakeUpYearRecord(
    memberId: string,
    buyBackMakeUpId: string,
    yearRecordId: string
  ) {
    return this.httpClient.get<BuyBackMakeUpYearRecordResponse>(
      `${environment.apiUrl}/Members/${memberId}/buyback-makeup/${buyBackMakeUpId}/${yearRecordId}/getBuybackMakeup-yearrecord`
    );
  }

  getBuybackMakeupInterestFeesCalculationResult(
    memberId: string,
    buyBackMakeUpId: string,
    yearRecordId: string,
    interestBeginDate: string,
    interestThruDate: string,
  ) {
    let params = new HttpParams();
    params = params.append('interestBeginDate', interestBeginDate);
    params = params.append('interestThruDate', interestThruDate);

    return this.httpClient.get<BuyBackMakeUpYearRecordResponse>(
      `${environment.apiUrl}/Members/${memberId}/buyback-makeup/${buyBackMakeUpId}/${yearRecordId}/getBuybackMakeup-yearrecord`,
      { params }
    );
  }

  validateDoingBuyBackMakeUp(request: ValidateBuyBackMakeUpRequest): Observable<ValidateBuyBackMakeUpResponse> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append("isInitiateRemittance", request.isInitiateRemittance);

    return this.httpClient.get<ValidateBuyBackMakeUpResponse>(
      `${environment.apiUrl}/Members/${request.memberId}/buyback-makeup/validate`,
      { params: queryParams }
    );
  }
}
