import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { PaymentType } from '@ptg-processing/features/payroll-calendar-container/types/enums/payroll-deduction-payee.enum';
import { BaseComponent } from '@ptg-shared/components';
import { Option } from '@ptg-shared/controls/select/select.component';
import { DateTime } from 'luxon';
import { merge } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { OverpaidOptions, PaymentTypeOptions, TransactionStatusOptions, TransactionTypeOptions } from '../../constants';
import { BenefitType, OverpaidStatus, PaymentTransactionStatus, TransactionType } from '../../services/models/register.model';
import { getPayrollBenefitTypesSelector } from '../../store/selectors/register.selector';
import { getPayrollBenefitTypesAction } from '../../store/actions/register.action';

@Component({
  selector: 'ptg-register-filter',
  templateUrl: './register-filter.component.html',
  styleUrls: ['./register-filter.component.scss'],
})
export class RegisterFilterComponent extends BaseComponent implements OnChanges {
  readonly PaymentTransactionStatus = PaymentTransactionStatus;
  TransactionTypeOptions = TransactionTypeOptions;
  PaymentTypeOptions = PaymentTypeOptions;
  readonly TransactionStatusOptions = TransactionStatusOptions;

  readonly OverpaidOptions = OverpaidOptions;
  readonly OverpaidStatus = OverpaidStatus;

  benefitTypeOptions: Option[] = [];
  @Input() expanedFilter?: boolean;
  @Input() payeeRecordId?: string;
  @Input() isConfigOST?: boolean = false;
  filterForm = new FormGroup({
    selectedTransactionTypes: new FormControl([]),
    selectedPaymentTypes: new FormControl([]),
    selectedBenefitTypes: new FormControl([]),
    payableDateFrom: new FormControl(),
    payableDateTo: new FormControl(),
    transactionDateFrom: new FormControl(DateTime.now().minus({ year: 1 }), Validators.required),
    transactionDateTo: new FormControl(),
    transactionStatus: new FormControl([]),
    paymentAmount: new FormControl(),
    checkNumber: new FormControl(),
    transactionId: new FormControl(),
    isOverpaid: new FormControl(),
    returnedDateFrom: new FormControl(),
    returnedDateTo: new FormControl(),
  });

  constructor(private store: Store) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getPayrollBenefitTypes();
    this.validatePayableDate();
    this.validateTransactionDate();
    this.validateReturnRejectDate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.expanedFilter) {
      this.patchDatePickerErrors();
    }
    if (changes.payeeRecordId) {
      this.patchFilterForMemberModule();
    }
  }

  getTransactionTypeLabel(transactionType: TransactionType): string {
    return TransactionType[transactionType];
  }

  getPaymentTypeLabel(paymentType: PaymentType): string {
    return PaymentType[paymentType];
  }

  getBenefitTypeLabel(benefitType: BenefitType) {
    return benefitType.name;
  }

  getTransactionStatusLabel(status: PaymentTransactionStatus): string {
    return PaymentTransactionStatus[status];
  }

  getPayrollBenefitTypes() {
    this.store
      .select(getPayrollBenefitTypesSelector)
      .pipe(
        filter((res) => !!res),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (state!.success) {
          this.benefitTypeOptions = (state?.payload || [])
            .map((benefit) => {
              return { value: benefit, displayValue: benefit.name };
            })
            .sort((a, b) => {
              return a.displayValue.localeCompare(b.displayValue);
            });
        }
      });
    this.store.dispatch(getPayrollBenefitTypesAction());
  }

  resetForm() {
    this.filterForm.reset({
      transactionDateFrom: DateTime.now().minus({ year: 1 }),
      transactionStatus: [],
    });
  }

  validatePayableDate() {
    merge(this.filterForm.get('payableDateFrom')!.valueChanges, this.filterForm.get('payableDateTo')!.valueChanges)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const dateFrom = this.filterForm.get('payableDateFrom') as FormControl;
        const dateTo = this.filterForm.get('payableDateTo') as FormControl;
        if (!dateFrom.value || !dateTo.value || !(dateFrom.value as DateTime).isValid || !(dateTo.value as DateTime).isValid) {
          this.patchEarlierThanErrors(dateFrom);
          this.patchEarlierThanErrors(dateTo);
          return;
        }

        if (dateTo.value < dateFrom.value) {
          const errors = { earlierThan: 'Payable Date From must not be greater than Payable Date To' };
          dateFrom.setErrors(errors);
          dateTo.setErrors(errors);
        } else {
          dateFrom.setErrors(null);
          dateTo.setErrors(null);
        }
      });
  }

  validateReturnRejectDate() {
    merge(this.filterForm.get('returnedDateFrom')!.valueChanges, this.filterForm.get('returnedDateTo')!.valueChanges)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const dateFrom = this.filterForm.get('returnedDateFrom') as FormControl;
        const dateTo = this.filterForm.get('returnedDateTo') as FormControl;
        if (!dateFrom.value || !dateTo.value || !(dateFrom.value as DateTime).isValid || !(dateTo.value as DateTime).isValid) {
          this.patchEarlierThanErrors(dateFrom);
          this.patchEarlierThanErrors(dateTo);
          return;
        }

        if (dateTo.value < dateFrom.value) {
          const errors = { earlierThan: 'Payable Date From must not be greater than Payable Date To' };
          dateFrom.setErrors(errors);
          dateTo.setErrors(errors);
        } else {
          dateFrom.setErrors(null);
          dateTo.setErrors(null);
        }
      });
  }

  patchEarlierThanErrors(control: FormControl) {
    if (control.errors) {
      const errors = control.errors;
      delete errors.earlierThan;

      control.setErrors(Object.keys(errors).length ? errors : null);
    }
  }

  validateTransactionDate() {
    merge(
      this.filterForm.get('transactionDateFrom')!.valueChanges,
      this.filterForm.get('transactionDateTo')!.valueChanges,
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        const dateFrom = this.filterForm.get('transactionDateFrom') as FormControl;
        const dateTo = this.filterForm.get('transactionDateTo') as FormControl;
        if (!dateFrom.value || !dateTo.value || !(dateFrom.value as DateTime).isValid || !(dateTo.value as DateTime).isValid) {
          this.patchEarlierThanErrors(dateFrom);
          this.patchEarlierThanErrors(dateTo);
          return;
        }

        if (dateTo.value < dateFrom.value) {
          const errors = { earlierThan: 'Transaction Date From must not be greater than Transaction Date To' };
          dateFrom.setErrors(errors);
          dateTo.setErrors(errors);
        } else if (dateTo.value > (dateFrom.value as DateTime).plus({ years: 5 })) {
          const errors = { earlierThan: 'Transaction Date To must be within 5 years of Transaction Date From' };
          dateFrom.setErrors(errors);
          dateFrom.markAsTouched();
          dateTo.setErrors(errors);
        } else {
          dateFrom.setErrors(null);
          dateTo.setErrors(null);
        }
      });
  }

  patchDatePickerErrors() {
    [
      'payableDateFrom',
      'payableDateTo',
      'transactionDateFrom',
      'transactionDateTo',
      'returnedDateTo',
      'returnedDateFrom',
    ]
    .forEach(controlName => {
      const control = this.filterForm.get(controlName);
      if (control?.value) {
        return;
      }
      const errors = control?.errors;
      if (!errors) {
        return;
      }
      if (errors?.matDatepickerParse) {
        if (Object.keys(errors).length > 1) {
          delete errors.matDatepickerParse;
          control.setErrors(errors);
        } else {
          control.setErrors(null);
        }
        control.updateValueAndValidity();
      }
    })
  }

  patchFilterForMemberModule() {
    if (!this.payeeRecordId) {
      return;
    }
    this.TransactionTypeOptions = TransactionTypeOptions.slice(0, -1);
    this.PaymentTypeOptions = PaymentTypeOptions.slice(0, -2);
  }
}
