<div class="filter-summary" *ngIf="!expanedFilter; else expanedFilterTemplate">
  <ng-container
    *ngFor="let selected of filterForm.get('selectedTransactionTypes')!.value"
    [ngTemplateOutlet]="selectedItem"
    [ngTemplateOutletContext]="{ label: 'Transaction Type', value: selected.displayValue }"
  >
  </ng-container>
  <ng-container
    *ngFor="let selected of filterForm.get('selectedPaymentTypes')!.value"
    [ngTemplateOutlet]="selectedItem"
    [ngTemplateOutletContext]="{ label: 'Payment Method', value: selected.displayValue }"
  >
  </ng-container>
  <ng-container
    *ngFor="let selected of filterForm.get('selectedBenefitTypes')!.value"
    [ngTemplateOutlet]="selectedItem"
    [ngTemplateOutletContext]="{ label: 'Benefit Type', value: selected.displayValue }"
  >
  </ng-container>
  <ng-container
    *ngFor="let selected of filterForm.get('transactionStatus')!.value"
    [ngTemplateOutlet]="selectedItem"
    [ngTemplateOutletContext]="{ label: 'Transaction Status', value: selected.displayValue }"
  >
  </ng-container>
  <ng-container
    *ngIf="filterForm.get('payableDateFrom')!.value && filterForm.get('payableDateTo')!.value; else payableDateSingle"
    [ngTemplateOutlet]="selectedItemBetween"
    [ngTemplateOutletContext]="{
      label: 'Payable Date',
      from: (filterForm.get('payableDateFrom')!.value | date: 'MM/dd/yyyy'),
      to: (filterForm.get('payableDateTo')!.value | date: 'MM/dd/yyyy')
    }"
  >
  </ng-container>
  <ng-template #payableDateSingle>
    <ng-container
      [ngTemplateOutlet]="dateSingle"
      [ngTemplateOutletContext]="{
        labelFrom: 'Payable Date From',
        valueFrom: filterForm.get('payableDateFrom')!.value,
        labelTo: 'Payable Date To',
        valueTo: filterForm.get('payableDateTo')!.value
      }"
    >
    </ng-container>
  </ng-template>
  <ng-container
    *ngIf="
      filterForm.get('transactionDateFrom')!.value && filterForm.get('transactionDateTo')!.value;
      else transactionDateSingle
    "
    [ngTemplateOutlet]="selectedItemBetween"
    [ngTemplateOutletContext]="{
      label: 'Transaction Date',
      from: (filterForm.get('transactionDateFrom')!.value | date: 'MM/dd/yyyy'),
      to: (filterForm.get('transactionDateTo')!.value | date: 'MM/dd/yyyy')
    }"
  >
  </ng-container>
  <ng-template #transactionDateSingle>
    <ng-container
      [ngTemplateOutlet]="dateSingle"
      [ngTemplateOutletContext]="{
        labelFrom: 'Transaction Date From',
        valueFrom: filterForm.get('transactionDateFrom')!.value,
        labelTo: 'Transaction Date To',
        valueTo: filterForm.get('transactionDateTo')!.value
      }"
    >
    </ng-container>
  </ng-template>
  <ng-template
    #dateSingle
    let-valueFrom="valueFrom"
    let-labelFrom="labelFrom"
    let-valueTo="valueTo"
    let-labelTo="labelTo"
  >
    <ng-container
      *ngIf="valueFrom"
      [ngTemplateOutlet]="selectedItem"
      [ngTemplateOutletContext]="{ label: labelFrom, hideCondition: true, value: valueFrom | date: 'MM/dd/yyyy' }"
    >
    </ng-container>
    <ng-container
      *ngIf="valueTo"
      [ngTemplateOutlet]="selectedItem"
      [ngTemplateOutletContext]="{ label: labelTo, hideCondition: true, value: valueTo | date: 'MM/dd/yyyy' }"
    >
    </ng-container>
  </ng-template>
  <ng-container
    *ngIf="filterForm.get('paymentAmount')!.value !== null && filterForm.get('paymentAmount')!.value !== ''"
    [ngTemplateOutlet]="selectedItem"
    [ngTemplateOutletContext]="{
      label: 'Payment Amount',
      value: filterForm.get('paymentAmount')!.value | numberLocalDecimal: '$' : { accountingFormat: true }
    }"
  >
  </ng-container>
  <ng-container
    *ngIf="filterForm.get('checkNumber')!.value !== null && filterForm.get('checkNumber')!.value !== ''"
    [ngTemplateOutlet]="selectedItem"
    [ngTemplateOutletContext]="{
      label: 'Check Number',
      value: filterForm.get('checkNumber')!.value
    }"
  >
  </ng-container>
  <ng-container
  *ngIf="filterForm.get('transactionId')!.value !== null && filterForm.get('transactionId')!.value !== ''"
  [ngTemplateOutlet]="selectedItem"
  [ngTemplateOutletContext]="{
    label: 'Transaction ID',
    value: filterForm.get('transactionId')!.value
  }"
  >
  </ng-container>
  <ng-template #selectedItem let-value="value" let-label="label" let-hideCondition="hideCondition">
    <span class="filter-item">
      <span class="filter-item-type">{{ label }}</span>
      <span class="filter-item-condition italic" *ngIf="!hideCondition">is</span>
      <span class="filter-item-value">{{ value }}</span>
    </span>
  </ng-template>
  <ng-template #selectedItemBetween let-from="from" let-to="to" let-label="label" let-condition="condition">
    <span class="filter-item">
      <span class="filter-item-type">{{ label }}</span>
      <span class="filter-item-condition italic">from</span>
      <span class="filter-item-value">{{ from }}</span>
      <span class="filter-item-condition italic">to</span>
      <span class="filter-item-value">{{ to }}</span>
    </span>
  </ng-template>
  <ng-container
    *ngIf="filterForm.controls['isOverpaid'].value !== null && filterForm.controls['isOverpaid'].value !== ''"
    [ngTemplateOutlet]="selectedItem"
    [ngTemplateOutletContext]="{
      label: 'Overpaid',
      value: OverpaidStatus[filterForm.controls['isOverpaid'].value]
    }"
  ></ng-container>
  <ng-container
    *ngIf="filterForm.get('returnedDateFrom')!.value && filterForm.get('returnedDateTo')!.value; else returnDateSingle"
    [ngTemplateOutlet]="selectedItemBetween"
    [ngTemplateOutletContext]="{
      label: 'Returned/Rejected Date',
      from: (filterForm.get('returnedDateFrom')!.value | date: 'MM/dd/yyyy'),
      to: (filterForm.get('returnedDateTo')!.value | date: 'MM/dd/yyyy')
    }"
  >
  </ng-container>
  <ng-template #returnDateSingle>
    <ng-container
      [ngTemplateOutlet]="dateSingle"
      [ngTemplateOutletContext]="{
        labelFrom: 'Returned/Rejected Date From',
        valueFrom: filterForm.get('returnedDateFrom')!.value,
        labelTo: 'Returned/Rejected Date To',
        valueTo: filterForm.get('returnedDateTo')!.value
      }"
    >
    </ng-container>
  </ng-template>
</div>
<ng-template #expanedFilterTemplate>
  <div class="filter-expanded">
    <div class="expanded-filter" [formGroup]="filterForm">
      <div class="expaned-filter-row">
        <div class="grid grid-cols-2 gap-4">
          <div class="filter-column">
            <ptg-select-add-chip
              placeholder="Transaction Type"
              formControlName="selectedTransactionTypes"
              [options]="TransactionTypeOptions"
              [getDisplayValueForItem]="getTransactionTypeLabel"
            >
            </ptg-select-add-chip>
          </div>
          <div class="filter-column">
            <ptg-select-add-chip
              placeholder="Payment Method"
              [options]="PaymentTypeOptions"
              [getDisplayValueForItem]="getPaymentTypeLabel"
              formControlName="selectedPaymentTypes"
            >
            </ptg-select-add-chip>
          </div>
        </div>
        <div class="grid grid-cols-2 gap-4">
          <div class="filter-column">
            <ptg-select-add-chip
              placeholder="Benefit Type"
              [options]="benefitTypeOptions"
              [getDisplayValueForItem]="getBenefitTypeLabel"
              formControlName="selectedBenefitTypes"
            >
            </ptg-select-add-chip>
          </div>
          <div class="filter-column">
            <ptg-select-add-chip
              placeholder="Transaction Status"
              [options]="TransactionStatusOptions"
              [getDisplayValueForItem]="getTransactionStatusLabel"
              formControlName="transactionStatus"
            >
            </ptg-select-add-chip>
          </div>
        </div>
        <div class="grid grid-cols-4 gap-4">
          <div class="filter-column">
            <ptg-datepicker
              [controlField]="filterForm.get('payableDateFrom')"
              placeholder="Payable Date From"
              class="w-full"
              customError="earlierThan"
            ></ptg-datepicker>
          </div>
          <div class="filter-column">
            <ptg-datepicker
              [controlField]="filterForm.get('payableDateTo')"
              placeholder="Payable Date To"
              class="w-full"
              customError="earlierThan"
            ></ptg-datepicker>
          </div>
          <div class="filter-column">
            <ptg-datepicker
              [controlField]="filterForm.get('transactionDateFrom')"
              placeholder="Transaction Date From"
              class="w-full"
              customError="earlierThan"
            ></ptg-datepicker>
          </div>
          <div class="filter-column">
            <ptg-datepicker
              [controlField]="filterForm.get('transactionDateTo')"
              placeholder="Transaction Date To"
              customError="earlierThan"
              class="w-full"
            ></ptg-datepicker>
          </div>
        </div>
        <div class="grid grid-cols-4 gap-4">
          <div class="filter-column">
            <ptg-textbox
              [controlField]="filterForm.get('paymentAmount')"
              placeholder="Payment Amount"
              [hasLabel]="true"
              class="w-full"
              inputType="Currency"
            ></ptg-textbox>
          </div>
          <div class="filter-column">
            <ptg-textbox
              [controlField]="filterForm.get('checkNumber')"
              [hasLabel]="true"
              placeholder="Check Number"
              class="w-full"
              inputType="{{isConfigOST ? '' : 'Number'}}"
              [isDecimal]="false"
              [isPositive]="true"
            ></ptg-textbox>
          </div>
          <div class="filter-column">
            <ptg-textbox
              [controlField]="filterForm.get('transactionId')"
              [hasLabel]="true"
              placeholder="Transaction ID"
              class="w-full"
              inputType="Number"
              [isDecimal]="false"
              [isPositive]="true"
            ></ptg-textbox>
          </div>
          <div class="filter-column">
            <ptg-select
              [controlField]="filterForm.controls['isOverpaid']"
              placeholder="Overpaid"
              [hasNoneValue]="true"
              [listData]="OverpaidOptions"
              [isOptionObj]="true"
              class="w-full"
            ></ptg-select>
          </div>
        </div>
        <div class="grid grid-cols-4 gap-4">
          <div class="filter-column">
            <ptg-datepicker
              [controlField]="filterForm.get('returnedDateFrom')"
              placeholder="Returned/Rejected Date From"
              class="w-full"
              customError="earlierThan"
            ></ptg-datepicker>
          </div>
          <div class="filter-column">
            <ptg-datepicker
              [controlField]="filterForm.get('returnedDateTo')"
              placeholder="Returned/Rejected Date To"
              class="w-full"
              customError="earlierThan"
            ></ptg-datepicker>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
