import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';
import { CheckDisplayOffsetButtonResponse, GetPaymentInfoTabsResponse, GetRecalculateTaxesResponse, InstructionHistory, PaymentTab } from '../../services/models';
import {
  cancelPaymentInstructionAction,
  cancelPaymentInstructionFailureAction,
  cancelPaymentInstructionSuccessAction,
  clearCancelPaymentInstructionStateAction,
  clearGetInstructionHistoryStateAction,
  clearGetPaymentInfoTabsStateAction,
  clearGetRecalculateTaxesStateAction,
  getInstructionHistoryAction,
  getInstructionHistoryFailureAction,
  getInstructionHistorySuccessAction,
  getPaymentInfoTabsAction,
  getPaymentInfoTabsFailureAction,
  getPaymentInfoTabsSuccessAction,
  getRecalculateTaxesAction,
  getRecalculateTaxesFailureAction,
  getRecalculateTaxesSuccessAction,
  checkDisplayOffsetButtonAction,
  checkDisplayOffsetButtonFailureAction,
  checkDisplayOffsetButtonSuccessAction,
  markRemoveOverpaidSuccess,
  markRemoveOverpaidFailure,
} from '../actions';

export interface PaymentInfoTabState {
  getPaymentInfoTabs?: BaseActionState<GetPaymentInfoTabsResponse>;
  getInstructionHistory?: BaseActionState<InstructionHistory[]>;
  getRecalculateTaxes?: BaseActionState<GetRecalculateTaxesResponse>;
  cancelPaymentInstruction?: BaseActionState;
  checkDisplayOffsetButton?: BaseActionState<CheckDisplayOffsetButtonResponse>;
  markRemoveOverpaid?: BaseActionState;
}

const initialState: PaymentInfoTabState = {};

export const paymentInfoTabReducer = createReducer(
  initialState,
  on(getPaymentInfoTabsAction, (state) => ({
    ...state,
    getPaymentInfoTabs: {
      isLoading: true,
    },
  })),
  on(getPaymentInfoTabsSuccessAction, (state, { response }) => ({
    ...state,
    getPaymentInfoTabs: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getPaymentInfoTabsFailureAction, (state, { error }) => ({
    ...state,
    getPaymentInfoTabs: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetPaymentInfoTabsStateAction, (state) => ({
    ...state,
    getPaymentInfoTabs: undefined,
  })),
  on(getInstructionHistoryAction, (state) => ({
    ...state,
    getInstructionHistory: {
      isLoading: true,
    },
  })),
  on(getInstructionHistorySuccessAction, (state, { response }) => ({
    ...state,
    getInstructionHistory: {
      isLoading: false,
      success: true,
      payload: response.instructionHistory,
      total: response.total,
    },
  })),
  on(getInstructionHistoryFailureAction, (state, { error }) => ({
    ...state,
    getInstructionHistory: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetInstructionHistoryStateAction, (state) => ({
    ...state,
    getInstructionHistory: undefined,
  })),
  on(getRecalculateTaxesAction, (state) => ({
    ...state,
    getRecalculateTaxes: {
      isLoading: true,
    },
  })),
  on(getRecalculateTaxesSuccessAction, (state, { response }) => ({
    ...state,
    getRecalculateTaxes: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(getRecalculateTaxesFailureAction, (state, { error }) => ({
    ...state,
    getRecalculateTaxes: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetRecalculateTaxesStateAction, (state) => ({
    ...state,
    getRecalculateTaxes: undefined,
  })),
  on(cancelPaymentInstructionAction, (state) => ({
    ...state,
    cancelPaymentInstruction: {
      isLoading: true,
    },
  })),
  on(cancelPaymentInstructionSuccessAction, (state) => ({
    ...state,
    cancelPaymentInstruction: {
      isLoading: false,
      success: true,
    },
  })),
  on(cancelPaymentInstructionFailureAction, (state, { error }) => ({
    ...state,
    cancelPaymentInstruction: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCancelPaymentInstructionStateAction, (state) => ({
    ...state,
    cancelPaymentInstruction: undefined,
  })),
  on(checkDisplayOffsetButtonAction, (state) => ({
    ...state,
    checkDisplayOffsetButton: {
      isLoading: true,
    },
  })),
  on(checkDisplayOffsetButtonSuccessAction, (state, { response }) => ({
    ...state,
    checkDisplayOffsetButton: {
      isLoading: false,
      success: true,
      payload: response,
    },
  })),
  on(checkDisplayOffsetButtonFailureAction, (state, { error }) => ({
    ...state,
    checkDisplayOffsetButton: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  //#region #156182 AC2 [Integrate] Mark/Remove a payment as Overpaid
  on(markRemoveOverpaidSuccess, (state) => ({
    ...state,
    markRemoveOverpaid: {
      isLoading: false,
      success: true
    }
  })),
  on(markRemoveOverpaidFailure, (state) => ({
    ...state,
    markRemoveOverpaid: {
      isLoading: false,
      success: false
    }
  })),
  //#endregion
);
