export enum CreateGeneralAdjustmentFormArrayKey {
  FundingSources = 'fundingSources',
  Insurances = 'insurances',
  OtherDeductions = 'otherDeductions',
  Garnishments = 'garnishments',
  QDRODeductions = 'qdroDeductions',
}

export enum OrderByType {
  Name,
  ConfigEarning,
}

export enum ValidateAdjustmentErrorType {
  StartDateMustBeAfterThePayeeBenefitBeginDate,
  StartDateMustMatchStartDateOfAPayrollCycle,
  EndDateMustBeBeforeTheLatestFinalizedBenefitPeriod,
  EndDateMustBeBeforeCurrentTheDayBeforeBenefitPeriodStartDate,
  EndDateMustBeEndDateOfAPayrollCycle,
  TheNetPositionForCorrespondingItemCannotBeNegativeAfterAdjusted,
  StartDateAndEndDateMustBeInSameYear
}
export enum Validate1099ConditionErrorType {
  currentPayeeHasNoFinalizedPaymentToAdjust
}
