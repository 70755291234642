import { createReducer, on } from '@ngrx/store';

import { BaseActionState } from '@ptg-shared/types/models';

import {
  EditPaymentStatusInitRulesResponse,
  GetLastPayrollCyclesResponse,
  GetReasonsResponse,
  GetValidateChangeLastPayrollCycleResponse,
  SaveEditPaymentStatusResponse,
  ValidationOTPPeriodOverlappedResponse,
  ValidationOTPRunResponse,
  ValidationQDROLinkedResponse,
} from '../../services/models/edit-payment-status.model';

import * as EditPaymentStatusActions from '../actions/edit-payment-status.actions';

export interface EditPaymentStatusState {
  getReasons?: BaseActionState<GetReasonsResponse>;
  getLastPayrollCycles?: BaseActionState<GetLastPayrollCyclesResponse>;
  getEditPaymentStatusInitRules?: BaseActionState<EditPaymentStatusInitRulesResponse>;
  getValidationQDROLinked?: BaseActionState<ValidationQDROLinkedResponse>;
  getValidationOTPPeriodOverlapped?: BaseActionState<ValidationOTPPeriodOverlappedResponse>;
  getValidationOTPRun?: BaseActionState<ValidationOTPRunResponse>;
  saveEditPaymentStatus?: BaseActionState<SaveEditPaymentStatusResponse>;
  saveAdjustmentStatus?: BaseActionState<SaveEditPaymentStatusResponse>;
  getValidateChangeLastPayrollCycle?: BaseActionState<GetValidateChangeLastPayrollCycleResponse>;
}

const initialState: EditPaymentStatusState = {};

export const editPaymentStatusReducer = createReducer(
  initialState,

  on(EditPaymentStatusActions.getReasonsAction, (state) => ({
    ...state,
    getReasons: {
      isLoading: true,
    },
  })),
  on(
    EditPaymentStatusActions.getReasonsSuccess,
    (state, { type, ...payload }) => ({
      ...state,
      getReasons: {
        isLoading: false,
        success: true,
        error: null,
        payload,
      },
    }),
  ),
  on(EditPaymentStatusActions.getReasonsFailure, (state, { error }) => ({
    ...state,
    getReasons: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(EditPaymentStatusActions.clearGetReasonsState, (state) => ({
    ...state,
    getReasons: undefined,
  })),

  on(EditPaymentStatusActions.getLastPayrollCyclesAction, (state) => ({
    ...state,
    getLastPayrollCycles: {
      isLoading: true,
    },
  })),
  on(EditPaymentStatusActions.getLastPayrollCyclesSuccess, (state, { response }) => ({
    ...state,
    getLastPayrollCycles: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(EditPaymentStatusActions.getLastPayrollCyclesFailure, (state, { error }) => ({
    ...state,
    getLastPayrollCycles: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(EditPaymentStatusActions.clearGetLastPayrollCyclesState, (state) => ({
    ...state,
    getLastPayrollCycles: undefined,
  })),

  on(EditPaymentStatusActions.getEditPaymentStatusInitRulesAction, (state) => ({
    ...state,
    getEditPaymentStatusInitRules: {
      isLoading: true,
    },
  })),
  on(EditPaymentStatusActions.getEditPaymentStatusInitRulesSuccess, (state, { response }) => ({
    ...state,
    getEditPaymentStatusInitRules: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(EditPaymentStatusActions.getEditPaymentStatusInitRulesFailure, (state, { error }) => ({
    ...state,
    getEditPaymentStatusInitRules: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(EditPaymentStatusActions.clearGetEditPaymentStatusInitRulesState, (state) => ({
    ...state,
    getEditPaymentStatusInitRules: undefined,
  })),

  on(EditPaymentStatusActions.getValidationQDROLinkedAction, (state) => ({
    ...state,
    getValidationQDROLinked: {
      isLoading: true,
    },
  })),
  on(EditPaymentStatusActions.getValidationQDROLinkedSuccess, (state, { response }) => ({
    ...state,
    getValidationQDROLinked: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(EditPaymentStatusActions.getValidationQDROLinkedFailure, (state, { error }) => ({
    ...state,
    getValidationQDROLinked: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(EditPaymentStatusActions.clearGetValidationQDROLinkedState, (state) => ({
    ...state,
    getValidationQDROLinked: undefined,
  })),

  on(EditPaymentStatusActions.getValidationOTPPeriodOverlappedAction, (state) => ({
    ...state,
    getValidationOTPPeriodOverlapped: {
      isLoading: true,
    },
  })),
  on(EditPaymentStatusActions.getValidationOTPPeriodOverlappedSuccess, (state, { response }) => ({
    ...state,
    getValidationOTPPeriodOverlapped: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(EditPaymentStatusActions.getValidationOTPPeriodOverlappedFailure, (state, { error }) => ({
    ...state,
    getValidationOTPPeriodOverlapped: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(EditPaymentStatusActions.clearGetValidationOTPPeriodOverlappedState, (state) => ({
    ...state,
    getValidationOTPPeriodOverlapped: undefined,
  })),

  on(EditPaymentStatusActions.getValidationOTPRunAction, (state) => ({
    ...state,
    getValidationOTPRun: {
      isLoading: true,
    },
  })),
  on(EditPaymentStatusActions.getValidationOTPRunSuccess, (state, { response }) => ({
    ...state,
    getValidationOTPRun: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(EditPaymentStatusActions.getValidationOTPRunFailure, (state, { error }) => ({
    ...state,
    getValidationOTPRun: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(EditPaymentStatusActions.clearGetValidationOTPRunState, (state) => ({
    ...state,
    getValidationOTPRun: undefined,
  })),

  on(EditPaymentStatusActions.saveEditPaymentStatusAction, (state) => ({
    ...state,
    saveEditPaymentStatus: {
      isLoading: true,
    },
  })),
  on(EditPaymentStatusActions.saveEditPaymentStatusSuccess, (state, { response }) => ({
    ...state,
    saveEditPaymentStatus: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(EditPaymentStatusActions.saveEditPaymentStatusFailure, (state, { error }) => ({
    ...state,
    saveEditPaymentStatus: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(EditPaymentStatusActions.clearSaveEditPaymentStatusState, (state) => ({
    ...state,
    saveEditPaymentStatus: undefined,
  })),

  //Adjustment item
  on(EditPaymentStatusActions.saveAdjustmentStatusAction, (state) => ({
    ...state,
    saveAdjustmentStatus: {
      isLoading: true,
    },
  })),
  on(EditPaymentStatusActions.saveAdjustmentStatusSuccess, (state, { response }) => ({
    ...state,
    saveAdjustmentStatus: {
      isLoading: false,
      success: true,
      error: null,
      payload: response,
    },
  })),
  on(EditPaymentStatusActions.saveAdjustmentStatusFailure, (state, { error }) => ({
    ...state,
    saveAdjustmentStatus: {
      isLoading: false,
      success: false,
      error,
    },
  })),
  on(EditPaymentStatusActions.clearSaveAdjustmentStatusState, (state) => ({
    ...state,
    saveAdjustmentStatus: undefined,
  })),
);
