import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { BaseComponent } from '@ptg-shared/components';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ACTION_COLUMN, Column, ColumnType, GridComponent, Row } from '@ptg-shared/controls/grid';
import { PageEvent } from '@ptg-shared/controls/pagination';
import { Breadcrumb, FunctionButtonConfig, FunctionButtonData } from '@ptg-shared/types/models/breadcrumb.model';
import { SearchFilterDocumentsComponent } from '../../components/search-filter-documents/search-filter-documents.component';
import { DocumentFilter } from '../../services/models/documents.model';
import { Store, select } from '@ngrx/store';
import { DocumentListState } from '@ptg-member/store/reducers/member-document.reducer';
import { selectEditMemberDocumentState } from '@ptg-member/store/reducers';
import { filter, takeUntil } from 'rxjs/operators';
import { STATE } from '@ptg-shared/constance';
@Component({
  selector: 'ptg-document-list-view',
  templateUrl: './document-list-view.component.html',
  styleUrls: ['./document-list-view.component.scss']
})
export class DocumentListViewComponent extends BaseComponent {
  @ViewChild('gridDocumentList') gridDocumentList!: GridComponent<any>;
  @Input() dataTable: any[] = [];
  @Input() sortInfo: any = {};
  @Input() totalRecords!: number | any;
  @Input() pageSize!: number;
  @Input() pageNumber!: number;
  @Input() isLoading!: boolean;
  @Input() message!: string;
  @Input() bannerType!: BannerType;

  @Output() sortChangeEvent = new EventEmitter();
  @Output() changePaggingEvent = new EventEmitter();
  @Output() downloadMultipleDocumentEvent = new EventEmitter();
  @Output() removeMultipleDocumentEvent = new EventEmitter();
  @Output() downloadDocumentEvent = new EventEmitter();
  @Output() removeDocumentEvent = new EventEmitter();
  @Output() editDocumentEvent = new EventEmitter();
  @Output() applyFilterEvent = new EventEmitter();

  currentFilters!: DocumentFilter | null;
  isApplyMunicipalityFilter: boolean = false;
  columns: Column[] = [
    {
      name: 'documentName',
      header: {
        title: 'Document Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'documentTypeName',
      header: {
        title: 'Document Type',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'uploadedDate',
      header: {
        title: 'Uploaded Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'fileName',
      header: {
        title: 'File Name',
      },
      // type: ColumnType.Link,
      truncate: true,
      sortable: true,
    },
    {
      name: 'linkTo',
      header: {
        title: 'Link To',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'documentTags',
      header: {
        title: 'Document Tags',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action'
      },
    }
  ];

  listBreadcrumbs: Breadcrumb[] = [
    {
      name: 'Document List',
    },
  ];
  functionButtons: FunctionButtonConfig[] = [
    {
      buttonName: 'Download',
      icon: 'file_download',
      classInput: 'add-button',
      class: 'export-button',
      isDisabled: true
    },
    {
      buttonName: 'Remove',
      icon: 'delete_forever',
      classInput: 'add-button',
      class: 'remove-button',
      isDisabled: true
    },
  ];

  FILTER_ACTION = {
    ADD: 'add',
    LOAD: 'load',
  };

  filterOptions: { iconName: string; label: string; value: string }[] = [
    {
      iconName: 'add_circle',
      label: 'New Filter',
      value: this.FILTER_ACTION.ADD,
    },
    {
      iconName: 'upload',
      label: 'Load Filter',
      value: this.FILTER_ACTION.LOAD,
    },
  ];
  selectedFilter: string = this.filterOptions[0].value;
  listRecordSelected: any = [];

  constructor(
    private dialog: MatDialog,
    private store: Store<DocumentListState>) {
    super();
  }

  ngOnInit(): void {
    // If edit document successful, clear all checkboxes and disable Download/Remove buttons
    this.store.pipe(
      select(selectEditMemberDocumentState),
      takeUntil(this.unsubscribe$),
      filter(editDocumentState => editDocumentState?.state === STATE.SUCCESS)
    ).subscribe(() => {
      this.gridDocumentList.selection.clear();
      this.onChangeSelection();
    });
  }

  editFilterDialog() {
    // edit filter
    const searchFilterDocumentDialog = this.dialog.open(SearchFilterDocumentsComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: false,
      data: {
        currentFilter: this.currentFilters
      }
    });
    searchFilterDocumentDialog.afterClosed().subscribe((documentFilter: any) => {
      this.currentFilters = documentFilter;
      this.applyFilterEvent.emit(documentFilter);
    });
  }

  clearFilter() {
    this.currentFilters = null;
    this.applyFilterEvent.emit(null);
  }

  onFilterSelect() {
    // Open add new filter popup
    const searchFilterDocumentDialog = this.dialog.open(SearchFilterDocumentsComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: false,
      data: {}
    });
    searchFilterDocumentDialog.afterClosed().subscribe((documentFilter: any) => {
      this.currentFilters = documentFilter;
      this.applyFilterEvent.emit(documentFilter);
    });
    return;
  }

  emitFunction(event: FunctionButtonData) {
    switch (event.buttonName) {
      case 'Download': {
        this.downloadMultipleDocumentEvent.emit(this.listRecordSelected);
        break;
      }
      case 'Remove': {
        this.removeMultipleDocumentEvent.emit(this.listRecordSelected);
        break;
      }
      default: {
        break;
      }
    }
  }

  onChangeSelection() {
    this.listRecordSelected = this.gridDocumentList.selection.selected
      .filter((row: any & Row) => row.checked && !row.hideCheckBox);
    this.functionButtons[0].isDisabled = !this.gridDocumentList.selection.selected.length;
    this.functionButtons[1].isDisabled = !this.gridDocumentList.selection.selected.length;
  }

  onEditDocument(row: any) {
    this.editDocumentEvent.emit(row);
  }

  onRemoveDocument(row: any) {
    this.removeDocumentEvent.emit(row);
  }

  downloadDocument(row: any) {
    this.downloadDocumentEvent.emit(row)
  }

  onChangeSort(event: Sort) {
    this.sortChangeEvent.emit(event);
  }

  onChangePage(event: PageEvent) {
    this.changePaggingEvent.emit(event);
  }

}
