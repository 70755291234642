import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  MunicipalityTypeComponent
} from '@ptg-member/features/calculation/components/municipality-type/municipality-type.component';
import { Breadcrumb, StepperState } from '@ptg-shared/types/models/breadcrumb.model';
import { filter, takeUntil, tap, take, switchMap, map, catchError } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import * as fromReducer from '@ptg-reducers';

import { Align, Column, ColumnType, GridComponent, Row } from '@ptg-shared/controls/grid';

import { ACTION, BUTTON_LABEL_CLOSE, DEFAULT_PAGE_SIZE, SORT_TYPE, STATE, SortType } from '@ptg-shared/constance';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import {
  CalculationState,
  getRetirementBenefitDownloadDocumentSelector,
  clearGetRetirementBenefitDownloadDocumentStateAction,
  getCalculationRefundYearsAction,
  getCalculationRefundYearsSelector,
  getRefundCalculationRecordByIdsAction,
  getRefundCalculationRecordByIdsSelector,
  getRetirementBenefitDetailDocumentsAction,
  removeRemoveCalculationDocumentDetailAction,
  removeRemoveCalculationDocumentDetailSelector,
  clearRemoveRemoveCalculationDocumentDetailStateAction,
  createReSelectRefundYearsAction,
  createReSelectRefundYearsSelector,
  clearCreateReSelectRefundYearsStateAction,
  clearGetRetirementBenefitDetailDocumentsStateAction,
  getExceptionListAction,
  clearGetExceptionListStateAction,
  reopenCalculationBenefitAction,
  reopenCalculationBenefitSelector,
  validateCalculationBenefitAction,
  validateCalculationBenefitSelector,
  completeCalculationBenefitAction,
  completeCalculationBenefitSelector,
  approveCalculationBenefitAction,
  approveCalculationBenefitSelector,
  clearReopenCalculationBenefitStateAction,
  checkRetirementDocumentCanRemoveAction,
  clearCheckRetirementDocumentCanRemoveStateAction,
  clearCheckExceptionConfigurationStateAction,
  checkExceptionConfigurationAction,
  clearValidateCalculationBenefitAction,
  getCalculationAuditTrailAction,
  clearGetCalculationAuditTrailStateAction,
  clearGetCalculationRefundYearsStateAction,
  clearGetRefundCalculationRecordByIdsStateAction,
  clearCompleteCalculationBenefitStateAction,
  clearApproveCalculationBenefitStateAction,
  computeCalculationBenefitAction,
  computeCalculationBenefitSelector,
  clearComputeCalculationBenefitStateAction,
  getRetirementBenefitDocumentSelector,
  getRetirementBenefitDocumentsAction,
  createRetirementBenefitDetailUploadDocumentSelector,
  clearCreateRetirementBenefitDetailUploadDocumentStateAction,
  createRetirementBenefitDetailUploadDocumentAction,
  getPayeeListAction,
  getPayeeListSelector,
  getRefundPayeeAction,
  getRefundPayeeSelector,
  editRetirementBenefitDocumentAction,
  clearEditRetirementBenefitDocumentStateAction,
  clearUpdatePayeeInformationStateAction,
  updatePayeeInformationSelector,
} from '../../store';
import {
  GetRefundCalculationRequest,
  CalculationRefundYear,
  CalculationRefundRecord,
  RetirementBenefitDetailDocument,
  ReSelectRefundYearsRequest,
  CreateReSelectRefundYearsRequest,
  ExceptionListItem,
  GetExceptionRequest,
  GetRetirementBenefitRequest,
  ReSelectRefundYear,
  CheckExceptionRequest,
  CalculationAuditTrail,
  GetCalculationAuditTrailRequest,
  PayeeListRequest,
  RetirementBenefitDocument,
  CreateRetirementBenefitDetailUploadDocumentRequest,
  GetRefundPayeeRequest,
  GetRefundPayeeResponse,
  PayeeListResponse,
  EntityListData, GetListEmployerTypesRequest,
} from '../../services/models';
import { deepClone, downloadFile, showBanner, toTimeZoneLocal } from '@ptg-shared/utils/common.util';
import { Sort } from '@angular/material/sort';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';
import { LayoutService } from '@ptg-shared/services/layout.service';
import {
  ActionButtonOnCalculationDetail,
  CalculationBenefitHistoryStatus,
  CalculationType,
  BenefitCalculationErrorType,
  RetirementBenefitDetailGridDataType,
} from '../../types/enums';
import { AccidentParticipantsState } from '@ptg-member/features/accident-claims/store/reducers/accident-participants.reducer';
import {
  BenefitType,
  RefundProcessing,
  REFUND_DOCUMENT_TYPE_OPTIONS,
  UPLOAD_DOCUMENT_RADIO_LIST,
} from '../../constants';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { Observable, Subject, combineLatest, of, timer } from 'rxjs';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import {
  checkExceptionSelector,
  getExceptionListSelector,
  getCalculationAuditTrailSelector,
  getRetirementBenefitDetailDocumentsSelector,
} from '../../store/selectors/retirement-benefit-detail-document.selector';
import { AuditTrailLine } from '@ptg-employer/models/annual-certification.model';
import { DateTime } from 'luxon';
import { MenuItemTitle, MenuItemSubTitle } from '@ptg-member/constants';
import { checkExistCheckRetirementDocumentCanRemoveSelector } from '../../store/selectors/check-retirement-document-can-remove.selector';
import {
  GRID_COLUMN_REFUND_YEARS,
  GRID_COLUMN_EXCEPTION_LIST,
  GRID_COLUMN_DETAIL_BENEFIT_DOCUMENT,
  GRID_COLUMN_CALCULATION_AUDIT_TRAILS,
} from '../../benefit-detail.constants';
import { BenefitDetailComponentService } from '../../services';
import { EditPayeeInfoComponent } from '../../components/edit-payee-info/edit-payee-info.component';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { RetirementBenefitDialogService } from '../../services/retirement-benefit-dialog.service';
import { EntityType } from 'src/app/admin/features/file/types/enums/entity-type.enum';
import { DOCUMENT_LOCATION, USED_FOR_MENU } from '@ptg-shared/constance/document-location.const';
import { EditDocumentComponent } from 'src/app/admin/features/file/components/edit-document/edit-document.component';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { CheckExistsDocumentNameResponse } from '@ptg-employer/models/employer-document.model';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import {
  clearGetDocumentDownloadStateAction,
  getDocumentDownloadAction,
} from 'src/app/admin/features/file/store/actions';
import { editRetirementBenefitDocumentsSelector } from '../../store/selectors/retirement-benefit-upload-document.selector';
import { getColumConfig, getEntityPropertyName, getEntityValue } from '@ptg-member/helper';
import { CardProperty, EntityViewItem } from '@ptg-entity-management/services/models';
import { PropertyType } from '@ptg-member/constance/metadataPropertyType.const';
import { OverviewHeaderEntityComponent } from '@ptg-member/components/overview-header-entity/overview-header-entity.component';

const PAGE_SIZE_CONST = '-ptg-refunds-calculation-detail-pageSize';

@Component({
  selector: 'ptg-refunds-calculation-detail',
  templateUrl: './refunds-calculation-detail.component.html',
  styleUrls: ['./refunds-calculation-detail.component.scss'],
})
export class RefundsCalculationDetailComponent extends BaseListComponent {
  @ViewChild('refundYearsGrid') refundYearsGrid!: GridComponent<any>;
  @ViewChild('municipalityType') municipalityType!: MunicipalityTypeComponent;
  @ViewChild(OverviewHeaderEntityComponent) overViewHeader!: OverviewHeaderEntityComponent;
  listBreadcrumbs: Breadcrumb[] = [];
  stepperState!: StepperState;
  memberId: string = '';

  message = '';
  bannerType: BannerType = BannerType.Hidden;

  private sortInfo?: Sort;
  private defaultPageSize: number = DEFAULT_PAGE_SIZE;
  pageNumber = FIRST_PAGE;
  sortType = SortType.ASC;
  totalBenefitDocuments: number = 0;
  isDocumentLoading?: boolean = true;
  pageRefundIndex: number = FIRST_PAGE;
  pageRefundSize: number = DEFAULT_PAGE_SIZE;
  refundDocuments: RetirementBenefitDetailDocument[] = [];
  columnsBenefitDocuments: Column[] = GRID_COLUMN_DETAIL_BENEFIT_DOCUMENT;

  totalBenefitHistories: number = 0;
  isRefundYearLoading?: boolean = true;
  pageDocumentIndex: number = FIRST_PAGE;
  pageDocumentSize: number = DEFAULT_PAGE_SIZE;
  refundYears: (CalculationRefundYear & Row)[] = [];
  columnsRefundYears: Column[] = GRID_COLUMN_REFUND_YEARS;

  totalExceptionRecords = 0;
  isExceptionLoading = true;
  pageExceptionIndex = FIRST_PAGE;
  pageExceptionSize = 3;
  pageExceptionSizeOptions = [1, 3, 5, 10, 15, 20];
  exceptionList: ExceptionListItem[] = [];
  columnException: Column[] = GRID_COLUMN_EXCEPTION_LIST;
  private sortInfoException: Sort = { active: 'exceptionName', direction: 'asc' };

  private passedExceptionTrigger = new Subject<boolean>();

  totalCalculationAuditTrailRecords = 0;
  isCalculationAuditTrailLoading = true;
  pageCalculationAuditTrailIndex = FIRST_PAGE;
  pageCalculationAuditTrailSize = DEFAULT_PAGE_SIZE;
  calculationAuditTrails: CalculationAuditTrail[] = [];
  columnCalculationAuditTrail: Column[] = GRID_COLUMN_CALCULATION_AUDIT_TRAILS;
  private sortInfoCalculationAuditTrail: Sort = { active: 'createdDate', direction: 'desc' };

  calculationType = CalculationType.Refund;
  currentFund: any = {};

  downloadFileName: string = '';
  removedDocumentId: string = '';

  calculationBenefitId: string = '';
  calculationRecordId: string = '';

  isPayeeLoading: boolean = false;
  showPayeeInformation: boolean = false;
  payeeSelected: (EntityListData & Row)[] = [];
  propertiesConfig!: any;
  entityComponentId = '';
  cardId: string = '';
  entityMemberId: string = '';
  rowId: string = '';
  dateOfDeath: string = '';
  payeeInformationId: string = '';
  payeeInformationSnapshot: any;
  payeeInformation: any = [];
  payeeColumns: Column[] = [];
  refundPayee: GetRefundPayeeResponse = {};
  payeeList: PayeeListResponse = {};

  netRefund: number = 0;
  refundCalculationRecordById?: CalculationRefundRecord;
  readonly CalculationBenefitHistoryStatus = CalculationBenefitHistoryStatus;

  totalRefundYear: number = 0;
  changedRefundYearRow?: CalculationRefundYear & Row;
  isReselectRefundLoading: boolean = false;

  completedStep = 0;

  menuItemTitle: string = MenuItemTitle.Refunds;
  menuItemSubTitle: string = MenuItemSubTitle.ReturnsProcessing;

  readonly existDocumentOptionList: RadioOption[] = UPLOAD_DOCUMENT_RADIO_LIST;

  benefitDocuments: RetirementBenefitDocument[] = [];

  getEmployerTypesRequest?: GetListEmployerTypesRequest;

  constructor(
    public layoutService: LayoutService,
    public route: ActivatedRoute,
    private calculationStore: Store<CalculationState>,
    private documentStore: Store<DocumentsState>,
    private dialog: MatDialog,
    private store: Store<AccidentParticipantsState>,
    private readonly retirementBenefitDetailService: BenefitDetailComponentService,
    private retirementBenefitDialogService: RetirementBenefitDialogService,
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getCurrentFundAndRouteData();

    // store selector functions
    this.selectorRefundYears();
    this.registerGetRefundCalculationDetailDocumentData();
    this.selectExceptionListState();
    this.selectorReSelectRefundYears();
    this.selectorRefundCalculationRecordById();

    this.reopenCalculationBenefitListener();
    this.computeCalculationBenefitListener();
    this.completeCalculationListener();
    this.approveCalculationBenefitListener();

    this.selectCalculationAuditTrailState();

    this.getRefundPayee();
    this.selectRefundPayee();
    this.selectPayeeInfo();
    this.updatePayeeInformationState();

    this.selectorDownloadFile();
    this.selectorRemoveDocument();
    this.selectorCheckDocumentCanRemove();

    this.selectCheckExceptionState();

    this.getRefundDetailDocumentData();
    this.registerGetRefundDetailDocumentData();

    this.uploadDocumentSelector();
    this.registerEditDocumentSelector();
  }

  private reCallRefundCalculationDetailData() {
    this.getRefundCalculationRecordById();
    this.getRefundCalculationDetailDocumentData();
    this.getRefundDetailDocumentData();
  }

  private getCurrentFundAndRouteData(): void {
    combineLatest([this.route.params, this.store.select(fromReducer.selectCurrentFundState)])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([params, currentFund]) => {
        this.defaultPageSize = currentFund?.defaultPageSize ?? DEFAULT_PAGE_SIZE;
        this.currentFund = currentFund;
        this.memberId = params.memberId;
        this.calculationRecordId = params.calculationRecordId;
        this.calculationBenefitId = params?.calculationBenefitId;
        this.setPageSize();
        this.listBreadcrumbs = this.getBreadcrumbs;

        this.getRefundCalculationRecordById();
        this.getRefundCalculationDetailDocumentData();
      });
  }

  initStepperState(dataDetail: AuditTrailLine[]): void {
    if (!dataDetail.length) return;
    const sortDataDetail = deepClone(dataDetail).sort((a: any, b: any) => a.step - b.step);
    const optionLabel = sortDataDetail.map((item) => {
      const completeTimeToLocal = item.submittedDate ? toTimeZoneLocal(new Date(item.submittedDate)).toISOString() : '';
      const completeTime = DateTime.fromISO(completeTimeToLocal).toFormat('MM/dd/yyyy hh:mm a');
      const label = item.submittedBy ? `${item.submittedBy} - ${completeTime}` : '';
      return label;
    });

    const countStep = sortDataDetail.filter((item) => item?.submittedDate !== null).length;

    this.stepperState = {
      selectedIndex: dataDetail.length - 1,
      currentStep: countStep ?? 0,
      labels: ['Calculate', 'Approve'],
      optionalLabels: optionLabel,
    };

    this.completedStep = dataDetail.length;
  }

  /**
   * Return the breadcrumb data
   */
  private get getBreadcrumbs(): Breadcrumb[] {
    return [
      {
        name: 'Overview',
        url: `/member/refunds-overview/${this.memberId}`,
      },
      {
        name: `Refund Calculation Detail`,
      },
    ];
  }

  setPageSize(): void {
    const refundPageSizeInSession = Number(
      sessionStorage.getItem(
        this.currentFund.key + this.calculationType + BenefitType.BenefitHistory + PAGE_SIZE_CONST,
      ),
    );
    const benefitDocumentPageSizeInSession = Number(
      sessionStorage.getItem(
        this.currentFund.key + this.calculationType + BenefitType.BenefitDocument + PAGE_SIZE_CONST,
      ),
    );
    const auditTrailPageSizeInSession = Number(
      sessionStorage.getItem(
        this.currentFund.key + this.calculationType + RefundProcessing.AuditTrail + PAGE_SIZE_CONST,
      ),
    );
    this.pageRefundSize = refundPageSizeInSession === 0 ? this.defaultPageSize : refundPageSizeInSession;
    this.pageDocumentSize =
      benefitDocumentPageSizeInSession === 0 ? this.defaultPageSize : benefitDocumentPageSizeInSession;
    this.pageCalculationAuditTrailSize =
      auditTrailPageSizeInSession === 0 ? this.defaultPageSize : auditTrailPageSizeInSession;
  }

  getRefundYears(): void {
    const request: GetRefundCalculationRequest = {
      memberId: this.memberId,
      calculationRecordId: this.calculationRecordId,
    };

    const getRefundYearRequest: GetRetirementBenefitRequest = this.setBenefitRequest({ name: 'Year', direction: 1 });
    this.calculationStore.dispatch(getCalculationRefundYearsAction({ request, getRefundYearRequest }));
  }

  setBenefitRequest(sortParam: { name: string; direction: number }, pageSize?: number, pageNumber?: number) {
    let sortType = sortParam.direction;
    let sortNames = sortParam.name;

    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }
    const request = {
      pageNumber: pageNumber,
      pageSize: pageSize,
      sortNames: sortNames,
      sortType: sortType,
    };

    return request;
  }

  selectorRefundYears(): void {
    this.calculationStore
      .select(getCalculationRefundYearsSelector)
      .pipe(
        filter((res) => !!res),
        tap((response) => (this.isRefundYearLoading = !!response?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetCalculationRefundYearsStateAction());

        if (!this.isRefundYearLoading && response?.success && response?.payload) {
          this.refundYears = deepClone(response?.payload).map((refundYear) => {
            return {
              ...refundYear,
              hideCheckBox: refundYear?.isYearSelected === null,
              checked: refundYear?.isYearSelected,
              isDisabledCheckBox:
                this.refundCalculationRecordById?.status !== CalculationBenefitHistoryStatus.Initiated,
            };
          });
          const isAllSelected = this.refundYears
            .filter((item) => item?.isYearSelected !== null)
            .every((item) => item?.checked);
          if (isAllSelected) this.refundYears = this.refundYears.map((item) => ({ ...item, checked: true }));
          this.totalRefundYear = response?.total ?? 0;
          const years = this.refundYears.reduce((result, current) => {
            if (current.isYearSelected && !result.includes(current.year)) {
              result.push(current.year);
            }
            return result;
          }, [] as number[]);
          this.getEmployerTypesRequest = {
            memberId: this.memberId,
            calculationBenefitId: this.calculationBenefitId,
            calculationType: this.calculationType,
            params: {
              calculationRecordId: this.calculationRecordId,
              years,
            }
          }
        }
      });
  }

  getRefundCalculationRecordById(): void {
    const request: GetRefundCalculationRequest = {
      memberId: this.memberId,
      calculationRecordId: this.calculationRecordId,
    };
    this.calculationStore.dispatch(getRefundCalculationRecordByIdsAction({ request }));
  }

  selectorRefundCalculationRecordById(): void {
    this.calculationStore
      .select(getRefundCalculationRecordByIdsSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetRefundCalculationRecordByIdsStateAction());

        if (response?.success && response?.payload) {
          const refundCalculationResponse = deepClone(response.payload);
          this.refundCalculationRecordById = refundCalculationResponse?.refundCalculation;
          this.netRefund = this.refundCalculationRecordById?.netRefund || 0;
          this.initStepperState(refundCalculationResponse?.auditTrailLines);

          this.getRefundYears();
          this.getExceptionListData();
          this.getCalculationAuditTrailData();
        }
      });
  }

  reOpenRefund(): void {
    this.calculationStore.dispatch(
      reopenCalculationBenefitAction({
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationType: this.calculationType,
      }),
    );
  }

  private reopenCalculationBenefitListener(): void {
    this.calculationStore
      .select(reopenCalculationBenefitSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        const isSuccess = res?.success;
        const customMessage = isSuccess
          ? 'Calculation successfully re-opened.'
          : 'Error occurred re-opening Calculation. Please try again.';
        showBanner.call(this, isSuccess ? BannerType.Success : BannerType.Fail, 'Calculation', ACTION.REOPEN, {
          customMessage,
        });

        if (isSuccess) {
          this.getRefundCalculationRecordById();
          this.getRefundCalculationDetailDocumentData();
        }

        this.calculationStore.dispatch(clearReopenCalculationBenefitStateAction());
      });
  }

  onClickCompute(): void {
    this.dispatchComputeCalculationBenefit();
    this.passedExceptionTrigger
      .pipe(
        filter((exceptionsOccur) => typeof exceptionsOccur === 'boolean'),
        take(1),
      )
      .subscribe(() => {
        this.getRefundCalculationRecordById();
        this.getRefundCalculationDetailDocumentData();
      });
  }

  private dispatchComputeCalculationBenefit(): void {
    this.calculationStore.dispatch(
      computeCalculationBenefitAction({
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationType: this.calculationType,
      }),
    );
  }

  private computeCalculationBenefitListener(): void {
    this.calculationStore
      .select(computeCalculationBenefitSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {        
        this.calculationStore.dispatch(clearComputeCalculationBenefitStateAction());

        if (res && res.success) {
          const isSuccess = res?.success;
          const isSuccessComputed = res?.payload?.isSuccess;
          showBanner.call(
            this,
            isSuccess && isSuccessComputed ? BannerType.Success : BannerType.Fail,
            'Refund',
            ACTION.COMPUTE,
          );
  
          if (isSuccess && isSuccessComputed) {
            this.getCheckExceptionData(ActionButtonOnCalculationDetail[0] as unknown as ActionButtonOnCalculationDetail);
          }
        } else if (res && res.error) {
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              text: res?.error.error.errorMessage[1],
              type: ConfirmType.Error,
              title: "Error",
              cancelButtonTitle: BUTTON_LABEL_CLOSE,
              hideConfirmButton: true,
            },
          });
        }
      });
  }

  completeRefund(): void {
    this.checkConditionToProcessCalculationBenefit(CalculationBenefitHistoryStatus['Pending Approval']);
  }

  approveRefund(): void {
    this.checkConditionToProcessCalculationBenefit(CalculationBenefitHistoryStatus.Approved);
  }

  onChangeSortRefundYear(event: Sort) {
    this.sortInfo = event;
    this.getRefundYears();
  }

  onRefundYearsPage(event: PageEvent) {
    super.onChangePage(event);
    this.pageRefundSize = event.pageSize;
    this.pageRefundIndex = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + this.calculationType + BenefitType.BenefitHistory + PAGE_SIZE_CONST,
      event.pageSize.toString(),
    );
    this.getRefundYears();
  }

  getRefundCalculationDetailDocumentData(): void {
    const requestDocument = this.setBenefitRequest(
      { name: '', direction: SortType.DESC },
      this.pageDocumentSize,
      this.pageDocumentIndex,
    );

    this.calculationStore.dispatch(
      getRetirementBenefitDetailDocumentsAction({
        request: {
          ...requestDocument,
          memberId: this.memberId,
          calculationType: this.calculationType,
          benefitTypeId: this.calculationBenefitId,
          calculationId: this.calculationRecordId ?? '',
        },
      }),
    );
  }

  registerGetRefundCalculationDetailDocumentData(): void {
    this.calculationStore
      .select(getRetirementBenefitDetailDocumentsSelector)
      .pipe(
        filter((res) => !!res),
        tap((res) => (this.isDocumentLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearGetRetirementBenefitDetailDocumentsStateAction());
        this.refundDocuments = (res?.payload ?? []).map((item) => {
          return {
            ...item,
            fileName: item?.fileName,
            uploadDate: item.uploadDate ? getDateString(item.uploadDate) : '',
          };
        });
        this.totalBenefitDocuments = res?.total ?? 0;
      });
  }

  onChangeSortException(sortInfo: Sort) {
    this.sortInfoException = sortInfo;
    this.getExceptionListData();
  }

  onChangeExceptionPage(pageEvent: PageEvent) {
    super.onChangePage(pageEvent);
    this.pageExceptionSize = pageEvent.pageSize;
    this.pageExceptionIndex = pageEvent.pageNumber;
    this.getExceptionListData();
  }

  private getExceptionListData(): void {
    const payload: Omit<GetExceptionRequest, 'sortNames' | 'sortType'> = {
      pageNumber: this.pageExceptionIndex,
      pageSize: this.pageExceptionSize,
      memberId: this.memberId,
      calculationType: this.calculationType,
      benefitTypeId: this.refundCalculationRecordById?.benefitEntityId ?? '',
      calculationId: this.calculationRecordId ?? '',
    };

    const request = this.retirementBenefitDetailService.getGridDataRequest(
      RetirementBenefitDetailGridDataType.Exceptions,
      payload,
      this.sortInfoException,
    ) as GetExceptionRequest;
    this.calculationStore.dispatch(getExceptionListAction({ request }));
  }

  private selectExceptionListState(): void {
    this.calculationStore
      .select(getExceptionListSelector)
      .pipe(
        filter((res) => !!res),
        tap((res) => (this.isExceptionLoading = !!res && res.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        if (response?.success) {
          this.calculationStore.dispatch(clearGetExceptionListStateAction());

          const formattedList = response?.payload?.map((item) => {
            item = {
              ...item,
              exceptionTime: DateTime.fromISO(getDateString(item.exceptionTime), { zone: 'utc' }).toJSDate().toString(),
            };
            return item;
          });

          this.exceptionList = formattedList ?? [];
          this.totalExceptionRecords = response?.total ?? 0;
        }
      });
  }

  private getCheckExceptionData(buttonAction: ActionButtonOnCalculationDetail): void {
    const request: CheckExceptionRequest = {
      memberId: this.memberId,
      benefitEntityId: this.refundCalculationRecordById?.benefitEntityId ?? '',
      targetId: this.calculationRecordId,
      targetType: this.calculationType,
    };
    this.calculationStore.dispatch(checkExceptionConfigurationAction({ request, buttonAction }));
  }

  private selectCheckExceptionState(): void {
    this.calculationStore
      .pipe(
        select(checkExceptionSelector),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        const isSuccess = response?.success;
        this.calculationStore.dispatch(clearCheckExceptionConfigurationStateAction());
        if (!isSuccess) {
          showBanner.call(this, BannerType.Fail, '', '', {
            customMessage: 'Error occurred checking Exceptions. Please try again.',
          });
          return;
        }

        const exceptionsOccur = response?.payload?.exceptionsOccur;
        // No exception OR processing Computation
        if (
          response?.payload?.buttonAction ===
            (ActionButtonOnCalculationDetail[0] as unknown as ActionButtonOnCalculationDetail) ||
          exceptionsOccur === false
        ) {
          this.passedExceptionTrigger.next(exceptionsOccur);
        }

        // Exceptions occur
        else if (exceptionsOccur) {
          const confirmResult = this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              title: BannerType.Warning,
              text: 'Please resolve exception to proceed.',
              type: ConfirmType.Warning,
              cancelButtonTitle: BUTTON_LABEL_CLOSE,
              hideConfirmButton: true,
            },
          });
          confirmResult
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
              this.getRefundCalculationRecordById();
              this.getRefundCalculationDetailDocumentData();
            });
        }
      });
  }

  private checkConditionToProcessCalculationBenefit(processToStatus: CalculationBenefitHistoryStatus): void {
    const hasRefundYearSelected = this.refundYearsGrid?.tableDataSource?.filteredData.some(
      (refundYear) => refundYear.checked,
    );
    if (!hasRefundYearSelected) {
      this.warningDialog('At least 1 Refund Year record must be selected', 'Error');
      this.getRefundCalculationRecordById();
      return;
    }

    this.calculationStore.dispatch(
      validateCalculationBenefitAction({
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationRecordId: this.calculationRecordId,
        calculationType: this.calculationType,
        processToStatus,
      }),
    );

    this.selectorCheckConditionToProcessCalculationBenefit(processToStatus);
  }

  private dispatchCompleteCalculationBenefit(): void {
    this.calculationStore.dispatch(
      completeCalculationBenefitAction({
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationType: this.calculationType,
      }),
    );
  }

  private completeCalculationListener(): void {
    this.calculationStore
      .select(completeCalculationBenefitSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearCompleteCalculationBenefitStateAction());

        if (res && res.error) {
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              text: res?.error.error.errorMessage[1],
              type: ConfirmType.Error,
              title: "Error",
              cancelButtonTitle: BUTTON_LABEL_CLOSE,
              hideConfirmButton: true,
            },
          });
          return;
        }

        const isSuccess = res?.success;
        showBanner.call(this, isSuccess ? BannerType.Success : BannerType.Fail, 'Refund', ACTION.COMPLETE);

        if (isSuccess) {
          this.getRefundCalculationRecordById();
          this.getRefundCalculationDetailDocumentData();
        }
      });
  }

  private dispatchApproveCalculationBenefit(): void {
    let payee: any = {};
    this.payeeColumns.forEach((item) => {
      payee[item.header?.title!] = this.payeeSelected[0][item.name];
    });
    const { employerId, employerCode, employerName } = this.municipalityType.employerTypeControl.value ?? {};;
    const employerType = {
      label: this.municipalityType.employerTypeLabel,
      employerId,
      employerCode,
      employerName,
    };
    this.calculationStore.dispatch(
      approveCalculationBenefitAction({
        memberId: this.memberId,
        calculationBenefitId: this.calculationBenefitId,
        calculationType: this.calculationType,
        body: {
          payeeInformation: JSON.stringify(payee) ?? null,
          employerType:  this.municipalityType.isEmployerMultipleBank ? employerType : undefined,
        },
      }),
    );
  }

  private approveCalculationBenefitListener(): void {
    this.calculationStore
      .select(approveCalculationBenefitSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearApproveCalculationBenefitStateAction());

        if (res && res.error) {
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              text: res?.error.error.errorMessage[1],
              type: ConfirmType.Error,
              title: "Error",
              cancelButtonTitle: BUTTON_LABEL_CLOSE,
              hideConfirmButton: true,
            },
          })
          return;
        }

        const isSuccess = res?.success;
        showBanner.call(this, isSuccess ? BannerType.Success : BannerType.Fail, 'Refund', ACTION.APPROVE);

        if (isSuccess) {
          this.overViewHeader.getMemberProfileData();
          this.getRefundCalculationRecordById();
          this.getRefundCalculationDetailDocumentData();
        }
      });
  }

  private selectorCheckConditionToProcessCalculationBenefit(processToStatus: CalculationBenefitHistoryStatus) {
    this.calculationStore
      .select(validateCalculationBenefitSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        take(1),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((res) => {
        this.calculationStore.dispatch(clearValidateCalculationBenefitAction());

        if (res && res.error) {
          this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            data: {
              text: res?.error.error.errorMessage[1],
              type: ConfirmType.Error,
              title: "Error",
              cancelButtonTitle: BUTTON_LABEL_CLOSE,
              hideConfirmButton: true,
            },
          });
          return;
        }

        if (!res?.payload?.isValid) {
          let errorContent = '';
          let popupTitle = 'Error';
          switch (res?.payload?.errorType) {
            case BenefitCalculationErrorType.Exception:
              errorContent = 'Please resolve exception to proceed.';
              popupTitle = 'Warning';
              break;
            case BenefitCalculationErrorType.CalculationNotInitiated:
              errorContent = 'Can only complete Refund record at status "Initiated".';
              break;
            case BenefitCalculationErrorType.CalculationNotPendingApproval:
              errorContent = 'Can only approve Refund record at status "Pending Approval".';
              break;
            case BenefitCalculationErrorType.NoLongerEligibleForRefund:
              errorContent = 'Selected Year(s) is no longer eligible for Refund.';
              break;
            default:
              break;
          }
          this.warningDialog(errorContent, popupTitle, () => {
            // Recall Refund Calculation Detail screen data after validate failed
            this.reCallRefundCalculationDetailData();
          });

          return;
        }

        if (processToStatus === CalculationBenefitHistoryStatus['Pending Approval']) {
          this.dispatchCompleteCalculationBenefit();
        } else if (processToStatus === CalculationBenefitHistoryStatus.Approved) {
          this.municipalityType?.employerTypeControl?.markAllAsTouched();
          if (this.municipalityType?.isEmployerMultipleBank && !this.municipalityType?.employerTypeControl?.valid) {
            return;
          }
          this.dispatchApproveCalculationBenefit();
        }
      });
  }

  onChangeSelection(): void {
    const reSelectRefundYears: ReSelectRefundYear[] = this.refundYearsGrid?.tableDataSource?.filteredData
      .filter((item) => !item.hideCheckBox)
      .map((refundYear) => {
        return {
          isYearSelected: refundYear?.checked ?? false,
          municipalityId: refundYear?.municipalityId,
          memberPaidPension: refundYear?.memberPaidPension,
          year: refundYear?.year,
          lastEvent: refundYear?.lastEvent,
        };
      });

    const reSelectRefundYearsRequest: ReSelectRefundYearsRequest = {
      listRefundYearSelected: reSelectRefundYears,
      total: this.totalRefundYear,
    };

    this.isReselectRefundLoading = true;
    const request: CreateReSelectRefundYearsRequest = {
      param: {
        memberId: this.memberId,
        calculationRecordId: this.calculationRecordId,
      },
      reSelectRefundYearsRequest: reSelectRefundYearsRequest,
    };

    this.calculationStore.dispatch(createReSelectRefundYearsAction({ request }));
  }

  selectorReSelectRefundYears(): void {
    this.calculationStore
      .select(createReSelectRefundYearsSelector)
      .pipe(
        tap((response) => (this.isReselectRefundLoading = !!response?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearCreateReSelectRefundYearsStateAction());

        if (!response?.success) {
          this.getRefundYears();
          return;
        }

        const isRefreshRefundList = response?.payload ?? false;
        if (isRefreshRefundList) {
          this.warningDialog('Selected Year(s) is no longer eligible for Refund', 'Error', () => {
            this.getRefundCalculationRecordById();
            this.getRefundCalculationDetailDocumentData();
          });
          return;
        }

        this.netRefund = this.refundYearsGrid?.tableDataSource?.filteredData
          .filter((item) => !item?.hideCheckBox && item?.checked)
          .map((item) => item?.memberPaidPension)
          .reduce((a, b) => a + b, 0);

        this.getCalculationAuditTrailData();
      });
  }

  onUploadDocuments(): void {
    // Open form upload
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    };
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(''),
      defaultShowOnOverview: true,
    };
    const specificMenuData = {
      shouldUseCommonBreadcrumbs: true,
      usedForMenu: USED_FOR_MENU.REFUND_CALCULATION_DETAIL,
      documentTypeOptionList: deepClone(REFUND_DOCUMENT_TYPE_OPTIONS),
      acceptFile: '.pdf',
      existDocumentRadioOptionList: this.existDocumentOptionList,
      benefitDocuments: this.benefitDocuments,
      detailBenefitDocument: this.refundDocuments,
      memberId: this.memberId,
      calculationType: this.calculationType,
      checkPattern: new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i'),
    };

    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        infoForm,
        specificMenuData,
      },
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const documentList = objectUpload?.documentList;
        let fileRequest = documentList.map((doc: any) => {
          if (doc.id) {
            return {
              id: doc.id,
              uploadDate: doc.uploadDate,
              type: doc.type,
            };
          } else {
            return {
              calculationBenefitId: this.calculationBenefitId,
              uploadDate: doc.uploadDate,
              documentName: doc.documentName,
              type: doc.type,
              file: doc.file,
              tags: doc.tags,
              fileName: doc.file.name,
              documentLocationTitle: DOCUMENT_LOCATION.REFUND,
              documentLocationRouter: `/member/refunds-overview/${this.memberId}/calculation-detail/${this.calculationBenefitId}/${this.calculationRecordId}`,
              documentDescription: doc.decription,
              showOnOverview: doc.showOnOverview,
            };
          }
        });
        const request: CreateRetirementBenefitDetailUploadDocumentRequest = {
          memberId: this.memberId,
          calculationType: this.calculationType,
          calculationBenefitId: this.calculationBenefitId,
          calculationRecordId: this.calculationRecordId,
          files: fileRequest,
        };
        this.calculationStore.dispatch(createRetirementBenefitDetailUploadDocumentAction({ request }));
      }
    });
  }

  onChangeSortDocuments(event: Sort): void {
    if (event.active === 'documentType') {
      this.sortInfo = {
        active: 'documentTypeName',
        direction: event.direction,
      };
    } else if (event.active === 'uploadDate') {
      this.sortInfo = {
        active: 'uploadDateNoTime',
        direction: event.direction,
      };
    } else {
      this.sortInfo = event;
    }
    this.getRefundCalculationDetailDocumentData();
  }

  onChangeDocumentsPage(event: PageEvent): void {
    super.onChangePage(event);
    this.pageDocumentSize = event.pageSize;
    this.pageDocumentIndex = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + this.calculationType + BenefitType.BenefitDocument + PAGE_SIZE_CONST,
      event.pageSize.toString(),
    );
    this.getRefundCalculationDetailDocumentData();
  }

  onDownloadFile(row: any): void {
    if (!row) {
      return;
    }

    this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
    this.documentStore.dispatch(
      getDocumentDownloadAction({ fileId: row?.fileId as string, fileName: row.fileName as string }),
    );
  }

  selectorDownloadFile(): void {
    this.calculationStore
      .select(getRetirementBenefitDownloadDocumentSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((downloadDocument) => {
        if (downloadDocument?.success) {
          const blobFile = downloadDocument?.payload ? downloadDocument?.payload[0] : new Blob();
          downloadFile.call(this, blobFile, this.downloadFileName);
          this.calculationStore.dispatch(clearGetRetirementBenefitDownloadDocumentStateAction());
        }
      });
  }
  onEditDocument(file: any) {
    // Open form upload
    let rowData = deepClone(file);
    rowData['tagDescriptionsList'] = rowData?.tags;
    rowData['showOnOverview'] = rowData?.showOnOverview === true ? 'Yes' : 'No';
    rowData['documentLocation'] = DOCUMENT_LOCATION.REFUND;
    rowData['documentLocationRouter'] =
      `/member/refunds-overview/${this.memberId}/calculation-detail/${this.calculationBenefitId}/${this.calculationRecordId}`;
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    };
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(rowData?.fileId ?? ''),
    };
    const specificMenuData = {
      usedForMenu: USED_FOR_MENU.EDIT_COMMON_DOCUMENT,
      documentTypeOptionList: deepClone(REFUND_DOCUMENT_TYPE_OPTIONS),
    };
    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        document: rowData,
        infoForm,
        specificMenuData,
      },
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const request = {
          documentName: objectUpload.documentName,
          tags: objectUpload.tags,
          documentDescription: objectUpload.description,
          showOnOverview: objectUpload.showOnOverview,
          documentType: objectUpload.type,
        };

        this.store.dispatch(
          editRetirementBenefitDocumentAction({
            memberId: this.memberId,
            calculationBenefitDocumentId: rowData?.id,
            request: request,
          }),
        );
      }
    });
  }

  registerEditDocumentSelector() {
    this.store.pipe(select(editRetirementBenefitDocumentsSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (!data) {
        return;
      }
      this.store.dispatch(clearEditRetirementBenefitDocumentStateAction());

      const dataState = data.state?.state;
      const dataAction = data.state?.action;
      const errorMessage = data.errorMsg;

      if (dataState === STATE.FAIL && errorMessage) {
        showBanner.call(this, dataState, '', '', { customMessage: errorMessage });
        return;
      }

      showBanner.call(this, dataState, 'Refund Document', dataAction);

      // Success case
      if (dataState === STATE.SUCCESS) {
        this.getRefundCalculationDetailDocumentData();
      }
    });
  }

  onRemoveDocument(row: any): void {
    this.removedDocumentId = row.calculationBenefitDocumentDetailId;
    const request = {
      calculationBenefitDocumentId: row.id,
      calculationBenefitId: this.calculationBenefitId,
      calculationType: this.calculationType,
      calculationRecordId: this.calculationRecordId,
    };
    this.calculationStore.dispatch(checkRetirementDocumentCanRemoveAction({ request }));
  }

  selectorRemoveDocument(): void {
    this.calculationStore
      .select(removeRemoveCalculationDocumentDetailSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response) {
          this.calculationStore.dispatch(clearRemoveRemoveCalculationDocumentDetailStateAction());
          showBanner.call(this, response?.state, 'Refund Document', response?.action);
          if (response?.state === STATE.SUCCESS) {
            this.getRefundCalculationDetailDocumentData();
          }
        }
      });
  }

  removeDocumentEvent(): void {
    const ALERT_MESSAGE =
      'After removed, this document cannot be used for Refund Initiation. Are you sure you want to proceed?';
    const confirmRemove = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: ALERT_MESSAGE,
        type: ConfirmType.Destruct,
        cancelButtonTitle: 'Cancel',
      },
    });
    confirmRemove
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          const removeRequest = {
            memberId: this.memberId,
            calculationBenefitId: this.calculationBenefitId,
            calculationType: this.calculationType,
            id: this.removedDocumentId,
          };
          this.calculationStore.dispatch(removeRemoveCalculationDocumentDetailAction({ request: removeRequest }));
        }
      });
  }

  selectorCheckDocumentCanRemove(): void {
    this.calculationStore
      .select(checkExistCheckRetirementDocumentCanRemoveSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        const canRemoveDoc = response?.payload?.isValid;
        this.calculationStore.dispatch(clearCheckRetirementDocumentCanRemoveStateAction());
        if (canRemoveDoc) {
          this.removeDocumentEvent();
        } else {
          const calculationBenefitStatus = response?.payload?.calculationBenefitStatus;
          this.removeDocumentErrorMessage(calculationBenefitStatus);
        }
      });
  }

  private removeDocumentErrorMessage(calculationBenefitStatus?: number) {
    if (typeof calculationBenefitStatus !== 'number') return;
    let alertMessage = '';
    switch (calculationBenefitStatus) {
      case CalculationBenefitHistoryStatus.Initiated:
      case CalculationBenefitHistoryStatus['Pending Approval']:
        alertMessage =
          'This document has been used for a pending approval Refund Calculation Benefit and cannot be removed.';
        break;
      case CalculationBenefitHistoryStatus.Approved:
        alertMessage = 'This document has been used for an approved Refund Calculation Benefit and cannot be removed.';
        break;
      default:
        break;
    }
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text: alertMessage,
        type: ConfirmType.Warning,
        cancelButtonTitle: BUTTON_LABEL_CLOSE,
        title: 'Error',
        hideConfirmButton: true,
      },
    });
  }

  private warningDialog(message = '', title = '', callback?: () => void) {
    const warningDialog = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      disableClose: true,
      data: {
        text: message,
        type: ConfirmType.Warning,
        title,
        cancelButtonTitle: BUTTON_LABEL_CLOSE,
        hideConfirmButton: true,
      },
    });

    if (callback) {
      warningDialog
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => {
          callback();
        });
    }
  }

  getRefundPayee() {
    const request: GetRefundPayeeRequest = {
      memberId: this.memberId,
      recordId: this.calculationRecordId,
    };
    this.calculationStore.dispatch(getRefundPayeeAction({ request }));
  }

  private selectRefundPayee(): void {
    this.calculationStore
      .select(getRefundPayeeSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        this.refundPayee = response?.payload ?? {};
        this.cardId = this.refundPayee.cardId ?? '';
        this.entityMemberId = this.refundPayee.entityMemberId ?? '';
        this.rowId = this.refundPayee.rowId ?? '';
        this.dateOfDeath = this.refundPayee.dateOfDeath ?? '';
        this.payeeInformationId = this.refundPayee.payeeInformationId ?? '';
        this.payeeInformationSnapshot = this.refundPayee.payeeInformationSnapshot
          ? JSON.parse(JSON.stringify(this.refundPayee.payeeInformationSnapshot))
          : {};

        if (Object.keys(this.payeeInformationSnapshot).length) {
          this.showPayeeInformation = true;
          const payeeInformationSnapshotKeys = Object.keys(JSON.parse(this.payeeInformationSnapshot));
          this.payeeInformation = payeeInformationSnapshotKeys.map((key) => ({
            label: key,
            value: JSON.parse(this.payeeInformationSnapshot)[key],
          }));
        } else {
          if (this.dateOfDeath) {
            this.showPayeeInformation = true;
          } else {
            this.showPayeeInformation = false;
          }
        }

        if (Object.keys(this.refundPayee).length) {
          if (this.cardId) {
            this.getPayeeList();
          }
        }
      });
  }

  getPayeeList() {
    const request: PayeeListRequest = {
      entityMemberId: this.refundPayee?.entityMemberId ?? '',
      cardId: this.refundPayee?.cardId ?? '',
      memberId: this.memberId,
      isSummary: false,
      pageNumber: this.pageNumber,
      pageSize: this.pageSize,
    };
    this.calculationStore.dispatch(getPayeeListAction({ request }));
  }

  private selectPayeeInfo(): void {
    this.calculationStore
      .select(getPayeeListSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response) {
          this.isPayeeLoading = response.isLoading;
        }
        this.payeeList = response?.payload ?? {};
        this.propertiesConfig = response?.payload?.cardProperties ?? [];
        if (this.refundPayee.rowId) {
          this.getColumnConfigs();
          const listDataMappingId = (response?.payload?.cardProperties ?? []).map((element: any) => {
            return {
              id: this.getColumnName(element),
              type: element.type,
              config: element.config,
            };
          });
          this.payeeSelected = (response?.payload?.rowData || [])?.map((item: any) => {
            const result: any = {};
            item?.values?.forEach((element: any) => {
              let columnName = element.entityReferencePropertyId ?? '';
              columnName =
                columnName && element.entityPropertyId
                  ? `${columnName}_${element.entityPropertyId}`
                  : element.entityPropertyId;

              if (element.option) {
                columnName = `${columnName}_${element.option}`;
              }

              const options = listDataMappingId.find((item) => item.id === columnName);

              result[columnName] = getEntityValue(element, options);
            });
            return { ...result, id: item.id, noOfAttachments: item?.noOfAttachments, values: item.values };
          });
          this.payeeSelected = this.payeeSelected.filter((item) => item.id === this.refundPayee.rowId);
          if (Object.keys(JSON.parse(JSON.stringify(this.payeeInformationSnapshot))).length === 0) {
            this.payeeInformation = [];
            this.payeeColumns.forEach((item) => {
              this.payeeInformation.push({
                label: item.header?.title,
                value: this.payeeSelected[0][item.name],
              });
            });
          }
        }
      });
  }

  getColumnName(column: CardProperty): string {
    let columnName = '';
    columnName = column.propertyId;
    if (column.entityReferencePropertyId) {
      columnName = columnName + '_' + column.entityReferencePropertyId;
    }

    if (column.option) {
      columnName = columnName + '_' + column.option;
    }

    return columnName;
  }

  getColumnConfigs() {
    this.payeeColumns = deepClone(this.propertiesConfig).map((column: CardProperty) => {
      const columConfig = getColumConfig(getEntityPropertyName(column.type), column.config);
      return {
        name: this.getColumnName(column),
        header: {
          title: column.propertyLabel,
        },
        truncate: true,
        sortable: false,
        type: [ColumnType.Address, ColumnType.PersonName, ColumnType.Binary].includes(columConfig.type as ColumnType)
          ? ColumnType.Text
          : columConfig.type,
        align: column.type === PropertyType.TYPE_REGISTER ? Align.Center : undefined,
        templateArgs: columConfig.templateArgs,
      };
    });
  }

  onEditPayeeInfo(): void {
    this.bannerType = BannerType.Hidden;
    if (this.refundPayee.isStepConfigured) {
      const editPayeeDialog = this.dialog.open(EditPayeeInfoComponent, {
        panelClass: 'dialog-full-screen',
        data: {
          payeeList: this.payeeList,
          cardId: this.cardId,
          memberId: this.memberId,
          rowId: this.rowId,
          dateOfDeath: this.dateOfDeath,
          entityMemberId: this.entityMemberId,
          calculationRecordId: this.calculationRecordId,
          payeeInformationId: this.payeeInformationId,
        },
      });
      editPayeeDialog
        .afterClosed()
        .pipe(take(1))
        .subscribe((result) => {
          this.getRefundPayee();
        });
    } else {
      this.dialog.open(ConfirmPopupComponent, {
        panelClass: 'confirm-popup',
        autoFocus: false,
        data: {
          title: 'Setup Requiring',
          text: 'Step Configuration needs to be set up for Refund Benefit.',
          type: ConfirmType.Confirm,
          hideConfirmButton: true,
        },
      });
    }
  }

  private updatePayeeInformationState() {
    this.store
      .select(updatePayeeInformationSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          if (!state.payload?.isMemberDeceased) {
            showBanner.call(this, BannerType.Fail, '', ACTION.SAVE, {
              customMessage: 'Member is not deceased, cannot choose payee.',
            });
          } else {
            showBanner.call(this, state?.success ? BannerType.Success : BannerType.Fail, '', ACTION.SAVE, {
              customMessage: state?.success
                ? 'Payee successfully updated.'
                : 'Error occurred edit Payee. Please try again.',
            });
          }
          this.store.dispatch(clearUpdatePayeeInformationStateAction());
        }
      });
  }

  /* START of AUDIT TRAILS SECTION */
  onChangeSortAuditTrail(sortInfo: Sort): void {
    this.sortInfoCalculationAuditTrail = sortInfo;
    this.getCalculationAuditTrailData();
  }

  onChangeAuditTrailPage(pageEvent: PageEvent): void {
    this.pageCalculationAuditTrailSize = pageEvent.pageSize;
    this.pageCalculationAuditTrailIndex = pageEvent.pageNumber;
    this.getCalculationAuditTrailData();
  }

  private getCalculationAuditTrailData(): void {
    const payload: Omit<GetCalculationAuditTrailRequest, 'sortNames' | 'sortType'> = {
      pageNumber: this.pageCalculationAuditTrailIndex,
      pageSize: this.pageCalculationAuditTrailSize,
      memberId: this.memberId,
      calculationType: this.calculationType,
      calculationId: this.calculationRecordId,
    };

    const request: GetCalculationAuditTrailRequest = this.retirementBenefitDetailService.getGridDataRequest(
      RetirementBenefitDetailGridDataType.CalculationAuditTrails,
      payload,
      this.sortInfoCalculationAuditTrail,
    );
    this.calculationStore.dispatch(getCalculationAuditTrailAction({ request }));
  }

  private selectCalculationAuditTrailState(): void {
    this.calculationStore
      .select(getCalculationAuditTrailSelector)
      .pipe(
        filter((res) => !!res),
        tap((res) => (this.isCalculationAuditTrailLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetCalculationAuditTrailStateAction());
        this.calculationAuditTrails = (response?.payload ?? []).map((item) => {
          return {
            ...item,
            createdDate: getDateString(item.createdDate) ?? '',
          };
        });
        this.totalCalculationAuditTrailRecords = response?.total ?? 0;
      });
  }
  /* END of AUDIT TRAILS SECTION */

  getRefundDetailDocumentData() {
    this.calculationStore.dispatch(
      getRetirementBenefitDocumentsAction({
        request: {},
        memberId: this.memberId,
        calculationType: this.calculationType,
        calculationBenefitId: this.calculationBenefitId,
      }),
    );
  }

  registerGetRefundDetailDocumentData() {
    this.calculationStore
      .select(getRetirementBenefitDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((retirementBenefitDocument) => {
        if (!retirementBenefitDocument?.isLoading && retirementBenefitDocument?.success) {
          this.benefitDocuments = retirementBenefitDocument?.payload || [];
        }
      });
  }

  validateExistDocumentNameExist(documentId?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || !control.value.trim()) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.retirementBenefitDialogService
              .checkExits({
                memberId: this.memberId || '',
                name: control.value.toString(),
                fileId: documentId,
                calculationType: this.calculationType
              })
              .pipe(
                map((response: CheckExistsDocumentNameResponse) => {
                  if (response?.exists) {
                    return { errMsgDocumentName: 'Document Name already exists.' };
                  }
                  return null;
                }),
                catchError(({ error }) => {
                  return of({ errMsgDocumentName: error?.errorMessage });
                }),
              ),
        ),
      );
    };
  }

  uploadDocumentSelector() {
    this.calculationStore
      .select(createRetirementBenefitDetailUploadDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((retirementBenefitDocument) => {
        if (retirementBenefitDocument && !retirementBenefitDocument?.isLoading) {
          showBanner.call(
            this,
            retirementBenefitDocument?.success ? BannerType.Success : BannerType.Fail,
            'Document',
            ACTION.UPLOAD,
          );

          if (retirementBenefitDocument?.success) {
            this.getRefundCalculationDetailDocumentData();
          }
          this.calculationStore.dispatch(clearCreateRetirementBenefitDetailUploadDocumentStateAction());
        }
      });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.passedExceptionTrigger.complete();
    this.calculationStore.dispatch(clearGetExceptionListStateAction());
    this.calculationStore.dispatch(clearCheckExceptionConfigurationStateAction());
    this.calculationStore.dispatch(clearGetCalculationAuditTrailStateAction());
    this.calculationStore.dispatch(clearGetRetirementBenefitDetailDocumentsStateAction());
    this.calculationStore.dispatch(clearGetCalculationRefundYearsStateAction());
    this.calculationStore.dispatch(clearGetRefundCalculationRecordByIdsStateAction());
  }
}
