import { PayStatus } from './member-list.const';

export const PAY_STATUS_TYPE_INFO = [
  {
    Status: PayStatus.IssuePayment,
    Name: 'Issue Payment',
    IconName: 'check_circle',
    IconColor: '#408BF9',
    ClassName: 'issuePayment',
  },
  {
    Status: PayStatus.Suspend,
    Name: 'Suspend',
    IconName: 'do_not_disturb_on',
    IconColor: '#FD8A03',
    ClassName: 'suspend',
  },
  {
    Status: PayStatus.Terminate,
    Name: 'Terminated',
    IconName: 'do_not_disturb_off',
    IconColor: '#E02E2E',
    ClassName: 'terminate',
  },
];

export enum OffCyclePaymentStatusType {
  NotIssued,
  Issued,
}

export enum PaymentInfoType {
  Recurring,
  Reissue,
  'Off-Cycle',
  Reversal,
  OneTime,
}

export const PaymentInforConfigCode = {
  DEDUCTIONS: 'DEDUCTIONS',
  DEDUCTIONSYEARTODATE: 'DEDUCTIONSYEARTODATE',
  EARNINGS: 'EARNINGS',
  EARNINGSYEARTODATE: 'EARNINGSYEARTODATE',
  PAYMENTS: 'PAYMENTS',
  PAYROLLSETTINGS: 'PAYROLLSETTINGS',
  PENDINGPAYMENTS: 'PENDINGPAYMENTS',
  EARNINGITEMS: 'EARNINGITEMS',
  NOTE: 'NOTE',
};

export const ERROR_TYPE_MISSING_INFO = 'OffCyclePaymentMissingConfigurationException';

export const PaymentInfoField = {
  Payee: 'payee',
  OrderByPayee: 'OrderByPayee',
};
