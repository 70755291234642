<div class="edit-payable-date">
  <h3>Edit {{data.isEditPayableDate ? 'Payable Date' : 'Benefit Period'}}</h3>
  <div class="edit-form">
    <div class="input-container">
      <ng-container *ngIf="data.isEditPayableDate; else benefitPeriodTemplate">
        <ptg-datepicker
          class="payable-date"
          [controlField]="payableDate"
          placeholder="Payable Date"
          [isRequired]="true"
          [minDate]="minDate"
          errorMinDate="Payable date cannot be past date"
          errorAsync="Payable date cannot be before Benefit Period Start Date"
        ></ptg-datepicker>
      </ng-container>
      <ng-template #benefitPeriodTemplate>
        <ptg-datepicker
          class="benefit-period"
          [controlField]="benefitPeriod.get('startDate')"
          placeholder="Start Date "
          [isRequired]="true"
          [maxDate]="maxDate"
          errorMaxDate="Start date can not be future date."
          [errorAsync]="errorStartAsync"
          [isDisabled]="data.benefitPeriod?.paymentType === PaymentInstructionType.InitialPayment"
        ></ptg-datepicker>
        <ptg-datepicker
          class="benefit-period"
          [controlField]="benefitPeriod.get('endDate')"
          placeholder="End Date "
          [isRequired]="true"
          [minDate]="benefitPeriod.get('startDate')?.value"
          errorMinDate="End Date must be after Start Date"
          [errorAsync]="errorEndAsync"
        ></ptg-datepicker>
      </ng-template>
    </div>
    <div class="mat-error mb-2" *ngIf="benefitPeriodOverlap">
      The suspended period is already covered by a Recurring or One time payment. Please check again
    </div>
    <div class="wrap-btn">
      <ptg-button (click)="formSubmit$.next()" classInput="primary" buttonName="Save"></ptg-button>
      <ptg-button (click)="onCancel()" classInput="tertiary" buttonName="Cancel"></ptg-button>
    </div>
  </div>
</div>
