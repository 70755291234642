<div class="edit-payee-payment-modal">
  <ptg-title-bar [name]="'Edit Payment'" [onlyTitle]="true"></ptg-title-bar>
  <ptg-payment-header
    [targetIdFromDialog]="data.memberId"
    [isShowInteractionComponents]="false"
  ></ptg-payment-header>
  <form [formGroup]="editForm">
    <div class="wrap-btn">
      <button mat-raised-button type="submit" class="submit-button" (click)="formSubmit$.next()">Save</button>
      <button mat-raised-button type="button" class="cancel-button" (click)="onCancel()">Cancel</button>
    </div>
    <div class="banner">
      <span class="title">Net Payment</span>
      <span class="message">$ {{ sumAmount | numberLocalDecimal }}</span>
    </div>

    <div class="form-control-container">
      <div class="param-list">
        <div class="row-param" *ngFor="let rowControls of listParameter?.controls; index as i">
          <label class="name truncate">
            {{ rowControls.get('name')?.value }}
          </label>
          <ptg-textbox
            [controlField]="rowControls.get('amount')"
            [hasLabel]="true"
            [isDisabled]="true"
            inputType="Currency"
            placeholder="Amount"
            class="text-box"
          >
          </ptg-textbox>
          <ptg-select
            [controlField]="rowControls.get('paymentMethod')"
            [listData]="rowControls.get('listPaymentMethod')?.value || []"
            [isOptionObj]="true"
            (changeOptionValue)="onChangePaymentMethod(i)"
            placeholder="Payment Method"
          >
          </ptg-select>
          <ptg-select
            *ngIf="rowControls.get('paymentMethod')?.value === PayeePaymentType['Direct Deposit']"
            [controlField]="rowControls.get('account')"
            [listData]="rowControls.get('listAccountOptions')?.value"
            [isOptionObj]="true"
            [isDisabled]="isLoadingAccount"
            [onlyTruncateTitle]="true"
            (onClickDdlEvent)="onClickDdl(i)"
            placeholder="Account #"
            customError="invalidValue"
            errorRequire="Account # is required."
          >
          </ptg-select>
        </div>
      </div>
    </div>
  </form>
</div>
