export interface GetPaymentNoteMemoRequest {
  totalPerPage?: number;
  pageIndex?: number;
  paymentInstructionHistoryId?: string;
  isAdjustment?: boolean;
  payrollRunId?: string;
}

export interface GetPaymentNoteMemoResponse {
  displayTitle: string;
  beginningOn: string;
  endingOn: string;
  payableDate: string;
  notes: NoteResponse[];
  paymentInstructionId: string;
  payStatus: number;
  total: number;
  isDisplayAddEditRemoveMemo: boolean;
}

export interface NoteResponse {
  id: string;
  note: string;
  postedAt: string;
  postedBy: string;
  postedByFirstName: string;
  postedByLastName: string;
  type: number;
  postedDate?: string;
  updatedBy?: string;
  createdBy?: string;
  isTruncate?: boolean;
}

export interface UpdatePaymentNoteMemoRequest {
  id?: string;
  note: string;
  paymentInstructionId: string;
  payStatus: number;
  type: number;
}

export interface updatePaymentNoteMemoResponsive {
  isLoading?: boolean;
  success?: boolean;
  updateState?: string;
  createState?: string;
  error?: any;
}

export interface PayableDatePayload {
  paymentInstructionId: string;
  payableDate: string;
  isUpdatePaymentPeriodAndAmount?: boolean;
}

export interface BenefitPeriodPayload {
  paymentInstructionId: string;
  startDate: string;
  endDate: string;
  isRecalcRelatedInfo?: boolean;
}

export interface LocalDate {
  [key: string]: string;
}
