import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'ptg-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() controlField!: AbstractControl | any;
  @Input() label!: string;
  @Input() single?: boolean;
  @Input() isDisabled?: boolean;
  @Input() customError: string = '';
  @Input() asyncError: string = '';

  @Output() changeValue = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  onChangeValue(event: any) {
    this.changeValue.emit(event.checked);
  }
}
