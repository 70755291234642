import { EntityPropertyType } from '../../types/enums';

export interface CheckExistPropertyValueRequest {
  entityId: string;
  componentId: string;
  propertyId: string;
  propertyType: EntityPropertyType;
  id?: string;
  index?: number;
  value: any;
}

export interface CheckExistPropertyValueResponse {
  isExist: boolean;
}

export interface CheckSumOfPercentageValueRequest {
  recordId: string;
  targetId: string;
  value: number;
}

export interface CheckSumOfPercentageValueResponse {
  isValid: boolean;
}

export interface CheckIsPrimarySavedRequest {
  recordId: string;
  targetId: string;
}

export interface CheckIsPrimarySavedResponse {
  isValid: boolean;
}

export interface CheckIsRecordExistedRequest {
  memberId: string;
  entityComponentId: string;
  recordId: string; // selected RecordId to check
}

export interface CheckIsRecordExistedResponse {
  isExist: boolean;
}

export interface InitiationProperty {
  staticPropertyType: number;
  id: string;
  entityId: string;
  entityPropertyId: string;
  entityComponentId: string;
  entityPropertyName?: string;
  name: string;
  importKey: string;
  type: EntityPropertyType;
  order: number;
  label: string;
  configs: any;
  options: any[];
  recordId: string;
  referenceDatas?: EntityInitiationPropertyValue[];
  fixedPropertyKey?: string,
  initiationValue?: boolean;
  isInitiationProperty?: boolean;
  messageError?: string;
  isHidden?: boolean;
  properties: InitiationProperty[];
  isComputedProperty: boolean;
}

export interface GetInitiationPropertiesResponse {
  entityId: string;
  entityLabel: string;
  entityComponentId: string;
  properties: InitiationProperty[];
  includeEntityReference?: boolean;
  title?: string;
  entityEntityComponents?: EntityEntityComponent[];
}

export interface EntityEntityComponent{
  entityId: string;
  entityComponentId: string;
}

export interface EntityInitiationPropertyValue {
  recordId: string;
  entityId: string;
  entityComponentId: string;
  entityPropertyId: string;
  entityReferenceId?: string;
  entityReferenceComponentId?: string;
  entityReferencePropertyId?: string;
  entityReferenceLinkedId?: string;
  entitySelectedId?: string;
  selectedRecordId?: string;
  value: any;
  configs: any;
  options: any;
  type: any;
  label: string;
  valueObj: any;
  valueMask: {
    maskedValue: string;
    originalValue: string;
  };
  recordLinkedId?: string;
  isInitiationProperty?: boolean;
  isComputedProperty?: boolean;
  entityPropertyReferenceValue?: any;
  id?: string;
  rawValue?: any;
  propertyType?: EntityPropertyType;
}

export interface ModuleConnections {
	items: ModuleConnectionItem[];
}

export interface ModuleConnectionItem {
	entityId: string;
	entityLabel: string;
	moduleKey: string;
}

export interface DetailOptionStatus {
  id: string;
  name: string;
  events: DetailOptionEvent[];
}

export interface DetailOptionEvent {
  id: string;
  name: string;
}

export interface PayeeRequest {
  memberId?: string;
  courtOrderType?: number;
  screenId?: string;
  courtOrderComponentId?: string;
  payeeId?: string;
  courtOrderId?: string;
}

export interface GetPayeePropertiesResponse {
	payeeOptions?: PayeeOption[];
}

export interface PayeeOption {
  id: string;
  name: string;
  relationship: string;
  relationshipLookupId: string;
  type: number;
  isHide: boolean;
}

export interface CheckExistSpouseRequest {
  targetId: string;
  value: string;
  lookupTableId: string;
}

export interface CheckExistSpouseResponse {
  personId?: string;
}