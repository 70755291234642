import { createAction, props } from '@ngrx/store';

import { generateActionName } from '@ptg-shared/utils/common.util';

import {
  EditPaymentStatusInitRulesResponse,
  GetLastPayrollCyclesResponse,
  GetReasonsResponse,
  ReasonsResponse,
  SaveAdjustmentStatusRequest,
  SaveEditPaymentStatusRequest,
  SaveEditPaymentStatusResponse,
  ValidationOTPPeriodOverlappedResponse,
  ValidationOTPRunResponse,
  ValidationQDROLinkedResponse,
} from '../../services/models/edit-payment-status.model';

import { PayStatus } from '../../types/enums';

// Actions for GET Suspension Reasons data
export const getReasonsAction = createAction(
  generateActionName('EditPaymentStatus GetReasons/API').Send,
  props<{ statuses: PayStatus[] }>(),
);
export const getReasonsSuccess = createAction(
  generateActionName('EditPaymentStatus GetReasons/API').Success,
  props<GetReasonsResponse>(),
);
export const getReasonsFailure = createAction(
  generateActionName('EditPaymentStatus GetReasons/API').Failure,
  props<{ error: any }>(),
);
export const clearGetReasonsState = createAction(generateActionName('EditPaymentStatus GetReasons').Clear);

// Actions for GET Last Payroll Cycle Date Detail data
export const getLastPayrollCyclesAction = createAction(
  generateActionName('EditPaymentStatus GetLastPayrollCycles/API').Send,
  props<{ paymentInstructionId: string; benefitEndDate?: string }>(),
);
export const getLastPayrollCyclesSuccess = createAction(
  generateActionName('EditPaymentStatus GetLastPayrollCycles/API').Success,
  props<{ response: GetLastPayrollCyclesResponse }>(),
);
export const getLastPayrollCyclesFailure = createAction(
  generateActionName('EditPaymentStatus GetLastPayrollCycles/API').Failure,
  props<{ error: any }>(),
);
export const clearGetLastPayrollCyclesState = createAction(
  generateActionName('EditPaymentStatus GetLastPayrollCycles').Clear,
);

// Actions for GET payment status validation result
export const getEditPaymentStatusInitRulesAction = createAction(
  generateActionName('EditPaymentStatus GetEditPaymentStatusInitRules/API').Send,
  props<{ paymentInstructionId: string }>(),
);
export const getEditPaymentStatusInitRulesSuccess = createAction(
  generateActionName('EditPaymentStatus GetEditPaymentStatusInitRules/API').Success,
  props<{ response: EditPaymentStatusInitRulesResponse }>(),
);
export const getEditPaymentStatusInitRulesFailure = createAction(
  generateActionName('EditPaymentStatus GetEditPaymentStatusInitRules/API').Failure,
  props<{ error: any }>(),
);
export const clearGetEditPaymentStatusInitRulesState = createAction(
  generateActionName('EditPaymentStatus GetEditPaymentStatusInitRules').Clear,
);

// Actions for GET validation rule for any QDRO benefit linked
export const getValidationQDROLinkedAction = createAction(
  generateActionName('EditPaymentStatus GetValidationQDROLinked/API').Send,
  props<{ paymentInstructionId: string }>(),
);
export const getValidationQDROLinkedSuccess = createAction(
  generateActionName('EditPaymentStatus GetValidationQDROLinked/API').Success,
  props<{ response: ValidationQDROLinkedResponse }>(),
);
export const getValidationQDROLinkedFailure = createAction(
  generateActionName('EditPaymentStatus GetValidationQDROLinked/API').Failure,
  props<{ error: any }>(),
);
export const clearGetValidationQDROLinkedState = createAction(
  generateActionName('EditPaymentStatus GetValidationQDROLinked').Clear,
);

// Actions for GET validation rule for OTP period overlapped
export const getValidationOTPPeriodOverlappedAction = createAction(
  generateActionName('EditPaymentStatus GetValidationOTPPeriodOverlapped/API').Send,
  props<{ paymentInstructionId: string }>(),
);
export const getValidationOTPPeriodOverlappedSuccess = createAction(
  generateActionName('EditPaymentStatus GetValidationOTPPeriodOverlapped/API').Success,
  props<{ response: ValidationOTPPeriodOverlappedResponse }>(),
);
export const getValidationOTPPeriodOverlappedFailure = createAction(
  generateActionName('EditPaymentStatus GetValidationOTPPeriodOverlapped/API').Failure,
  props<{ error: any }>(),
);
export const clearGetValidationOTPPeriodOverlappedState = createAction(
  generateActionName('EditPaymentStatus GetValidationOTPPeriodOverlapped').Clear,
);

// Actions for GET validation rule for OTP run
export const getValidationOTPRunAction = createAction(
  generateActionName('EditPaymentStatus GetValidationOTPRun/API').Send,
  props<{ paymentInstructionId: string; benefitTypeOptionId: string, isSuspend?: boolean }>(),
);
export const getValidationOTPRunSuccess = createAction(
  generateActionName('EditPaymentStatus GetValidationOTPRun/API').Success,
  props<{ response: ValidationOTPRunResponse }>(),
);
export const getValidationOTPRunFailure = createAction(
  generateActionName('EditPaymentStatus GetValidationOTPRun/API').Failure,
  props<{ error: any }>(),
);
export const clearGetValidationOTPRunState = createAction(
  generateActionName('EditPaymentStatus GetValidationOTPRun').Clear,
);

// Actions for SAVE Edit Payment Status data
export const saveEditPaymentStatusAction = createAction(
  generateActionName('SaveEditPaymentStatus/API').Send,
  props<{ paymentInstructionId: string; body: SaveEditPaymentStatusRequest; isOTP?: boolean }>(),
);
export const saveEditPaymentStatusSuccess = createAction(
  generateActionName('SaveEditPaymentStatus/API').Success,
  props<{ response: SaveEditPaymentStatusResponse }>(),
);
export const saveEditPaymentStatusFailure = createAction(
  generateActionName('SaveEditPaymentStatus/API').Failure,
  props<{ error: any }>(),
);
export const clearSaveEditPaymentStatusState = createAction(generateActionName('SaveEditPaymentStatus').Clear);

// Actions for Edit Adjustment Status data
export const saveAdjustmentStatusAction = createAction(
  generateActionName('SaveAdjustmentStatus/API').Send,
  props<{ body: SaveAdjustmentStatusRequest }>(),
);
export const saveAdjustmentStatusSuccess = createAction(
  generateActionName('SaveAdjustmentStatus/API').Success,
  props<{ response: SaveEditPaymentStatusResponse }>(),
);
export const saveAdjustmentStatusFailure = createAction(
  generateActionName('SaveAdjustmentStatus/API').Failure,
  props<{ error: any }>(),
);
export const clearSaveAdjustmentStatusState = createAction(generateActionName('SaveAdjustmentStatus').Clear);
