import { createSelector } from '@ngrx/store';
import { selectPayeeDetailModuleState } from './selector';

export const paymentInfoTabSelector = createSelector(selectPayeeDetailModuleState, (state) => state?.paymentInfoTab);

export const getPaymentInfoTabsSelector = createSelector(paymentInfoTabSelector, (state) => state?.getPaymentInfoTabs);

export const getInstructionHistoriesSelector = createSelector(
  paymentInfoTabSelector,
  (state) => state?.getInstructionHistory,
);

export const getRecalculateTaxesSelector = createSelector(
  paymentInfoTabSelector,
  (state) => state?.getRecalculateTaxes,
);

export const cancelPaymentInstructionSelector = createSelector(
  paymentInfoTabSelector,
  (state) => state?.cancelPaymentInstruction,
);

export const checkDisplayOffsetButtonSelector = createSelector(
  paymentInfoTabSelector,
  (state) => state?.checkDisplayOffsetButton,
);

export const markRemoveOverpaidSelector = createSelector(
  paymentInfoTabSelector,
  (state) => state?.markRemoveOverpaid
);
