import { createReducer, on } from '@ngrx/store';
import { BenefitType, PaymentErrorList, SummaryList, VoidedReason } from '../../services/models/register.model';
import {
  clearCreateReissueTransactionsStateAction,
  clearCreateRejectedTransactionsStateAction,
  clearGetPaymentLevelErrorListsStateAction,
  clearGetVoidedReasonDetailsStateAction,
  clearGetVoidedReasonsStateAction,
  createReissueTransactionsAction,
  createReissueTransactionsFailureAction,
  createReissueTransactionsSuccessAction,
  createRejectedTransactionsAction,
  createRejectedTransactionsFailureAction,
  createRejectedTransactionsSuccessAction,
  downloadTransactionRegisterSuccess,
  exportTransactionRegister,
  getPaymentLevelErrorListsAction,
  getPaymentLevelErrorListsFailureAction,
  getPaymentLevelErrorListsSuccessAction,
  getPayrollBenefitTypesAction,
  getPayrollBenefitTypesFailureAction,
  getPayrollBenefitTypesSuccessAction,
  getSummaryListAction,
  getSummaryListFailureAction,
  getSummaryListSuccessAction,
  getVoidedReasonDetailsAction,
  getVoidedReasonDetailsFailureAction,
  getVoidedReasonDetailsSuccessAction,
  getVoidedReasonsAction,
  getVoidedReasonsFailureAction,
  getVoidedReasonsSuccessAction,
} from '../actions/register.action';
import { BaseActionState } from '@ptg-shared/types/models';

export interface RegisterState {
  isLoading: boolean;
  isExporting?: boolean;
  summaryList: SummaryList;
  success?: boolean;
  error?: any;
  createRejectedTransactions?: BaseActionState;
  getVoidedReasons?: BaseActionState<VoidedReason[]>;
  getVoidedReasonDetails?: BaseActionState<VoidedReason[]>;
  createReissueTransactions?: BaseActionState<string>;
  getPayrollBenefitTypes?: BaseActionState<BenefitType[]>;
  getPaymentLevelErrorList?: BaseActionState<PaymentErrorList[]>;
}

const initialState: RegisterState = {
  isLoading: true,
  summaryList: {
    total: 0,
    transactions: [],
    maxLength: 1,
    isOst: false,
  },
};

export const registerReducer = createReducer(
  initialState,
  on(getSummaryListAction, (state, {}) => ({
    ...state,
    isLoading: true,
    summaryList: {
      total: 0,
      transactions: [],
      maxLength: 1,
      isOst: false,
    },
  })),
  on(getSummaryListSuccessAction, (state, { summaryList }) => ({
    ...state,
    isLoading: false,
    summaryList,
    success: true,
    error: undefined,
  })),
  on(getSummaryListFailureAction, (state, { error }) => ({
    ...state,
    isLoading: false,
    summaryList: {
      total: 0,
      transactions: [],
      maxLength: 1,
      isOst: false,
    },
    success: false,
    error: error,
  })),

  on(createRejectedTransactionsAction, (state) => ({
    ...state,
    createRejectedTransactions: {
      isLoading: true,
    },
  })),
  on(createRejectedTransactionsSuccessAction, (state) => ({
    ...state,
    createRejectedTransactions: {
      isLoading: false,
      success: true,
    },
  })),
  on(createRejectedTransactionsFailureAction, (state, { error }) => ({
    ...state,
    createRejectedTransactions: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCreateRejectedTransactionsStateAction, (state) => ({
    ...state,
    createRejectedTransactions: undefined,
  })),
  on(getVoidedReasonsAction, (state) => ({
    ...state,
    getVoidedReasons: {
      isLoading: true,
    },
  })),
  on(getVoidedReasonsSuccessAction, (state, { response }) => ({
    ...state,
    getVoidedReasons: {
      isLoading: false,
      success: true,
      payload: response.reasons,
    },
  })),
  on(getVoidedReasonsFailureAction, (state, { error }) => ({
    ...state,
    getVoidedReasons: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetVoidedReasonsStateAction, (state) => ({
    ...state,
    getVoidedReasons: undefined,
  })),
  on(getVoidedReasonDetailsAction, (state) => ({
    ...state,
    getVoidedReasonDetails: {
      isLoading: true,
    },
  })),
  on(getVoidedReasonDetailsSuccessAction, (state, { response }) => ({
    ...state,
    getVoidedReasonDetails: {
      isLoading: false,
      success: true,
      payload: response.reasons,
    },
  })),
  on(getVoidedReasonDetailsFailureAction, (state, { error }) => ({
    ...state,
    getVoidedReasonDetails: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetVoidedReasonDetailsStateAction, (state) => ({
    ...state,
    getVoidedReasonDetails: undefined,
  })),
  on(createReissueTransactionsAction, (state) => ({
    ...state,
    createReissueTransactions: {
      isLoading: true,
    },
  })),
  on(createReissueTransactionsSuccessAction, (state, { id }) => ({
    ...state,
    createReissueTransactions: {
      isLoading: false,
      success: true,
      payload: id
    },
  })),
  on(createReissueTransactionsFailureAction, (state, { error }) => ({
    ...state,
    createReissueTransactions: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearCreateReissueTransactionsStateAction, (state) => ({
    ...state,
    createReissueTransactions: undefined,
  })),
  on(getPayrollBenefitTypesAction, (state) => ({
    ...state,
    getPayrollBenefitTypes: {
      isLoading: true,
    },
  })),
  on(getPayrollBenefitTypesSuccessAction, (state, { response }) => ({
    ...state,
    getPayrollBenefitTypes: {
      isLoading: false,
      success: true,
      payload: response.benefits,
    },
  })),
  on(getPayrollBenefitTypesFailureAction, (state, { error }) => ({
    ...state,
    getPayrollBenefitTypes: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(exportTransactionRegister, (state) => {
    return {
      ...state,
      isExporting: true,
    };
  }),
  on(downloadTransactionRegisterSuccess, (state) => {
    return {
      ...state,
      isExporting: false,
    };
  }),

  on(getPaymentLevelErrorListsAction, (state) => ({
    ...state,
    getPaymentLevelErrorList: {
      isLoading: true,
    },
  })),
  on(getPaymentLevelErrorListsSuccessAction, (state, { response }) => ({
    ...state,
    getPaymentLevelErrorList: {
      isLoading: false,
      success: true,
      payload: response.errorList,
      total: response.total,
      received: response.received
    },
  })),
  on(getPaymentLevelErrorListsFailureAction, (state, { error }) => ({
    ...state,
    getPaymentLevelErrorList: {
      isLoading: false,
      success: false,
      error: error,
    },
  })),
  on(clearGetPaymentLevelErrorListsStateAction, (state) => ({
    ...state,
    getPaymentLevelErrorList: undefined,
  })),
);
