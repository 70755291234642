import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AddressData, BenefitTimeInfo, CheckBenefitPeriod, CheckPayableDate, CheckPayableDateResponse, FundingSourceElement, FundingSourceItems, GetDeductionFundingSourcesResponse, PayeeResponse, PayloadAmountValidate, PayloadOneTime, PaymentType, QueryParamsBenefit, QueryParamsCheckEndSave, QueryParamsWithHoldingTax, SubType } from './models';
import { environment } from 'src/environments/environment';
import { map, takeUntil } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { DeductionType, DeductionTypeRevert } from '../types/enums';
import { Option } from '@ptg-shared/controls/select/select.component';
import { deepClone } from '@ptg-shared/utils/common.util';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class AddOneTimeService {
  constructor(private httpClient: HttpClient) {}

  getOneTimePaymentType(benefitTypeOptionId: string, queryParams: QueryParamsBenefit | any): Observable<{ paymentInstructionTypeDtos: PaymentType[] }> {
    let params = new HttpParams();
    Object.keys(queryParams).forEach(key => {
      params = params.append(key, queryParams[key]);
    });
    return this.httpClient.get<{ paymentInstructionTypeDtos: PaymentType[] }>(
      `${environment.apiUrl}/new-payroll/one-time-payment/payment-instruction-type/${benefitTypeOptionId}`, {params}
    );
  }

  getCorrectionType(paymentInstructionTypeId: string, benefitTypeName: string): Observable<{ paymentInstructionTypeDtos: PaymentType[] }> {
    return this.httpClient.get<{ paymentInstructionTypeDtos: PaymentType[] }>(
      `${environment.apiUrl}/new-payroll/one-time-payment/payment-instruction-sub-type/${paymentInstructionTypeId}?benefitTypeName=${benefitTypeName}`,
    );
  }

  getFundingSource(benefitTypeOptionId: string): Observable<{ fundingSources: FundingSourceElement[] }> {
    return this.httpClient.get<{ fundingSources: FundingSourceElement[] }>(
      `${environment.apiUrl}/new-payroll/earning-funding-source/${benefitTypeOptionId}?isGetMainFundingSource=true`,
    );
  }

  getPayeeList(payeeId: string, benefitCode: string): Observable<PayeeResponse> {
    return this.httpClient.get<PayeeResponse>(
      `${environment.apiUrl}/payroll-settings/representative-payee/${payeeId}?benefitCode=${benefitCode}`,
    );
  }

  getBenefitTimeInfo(queryParams: QueryParamsBenefit | any, paymentInstructionTypeId:  string): Observable<{ oneTimePaymentDatePeriodDto: BenefitTimeInfo }> {
    let params = new HttpParams();
    Object.keys(queryParams).forEach(key => {
      params = params.append(key, queryParams[key]);
    });
    return this.httpClient.get<{ oneTimePaymentDatePeriodDto: BenefitTimeInfo }>(
      `${environment.apiUrl}/new-payroll/one-time-payment/benefit-date-period/${paymentInstructionTypeId}`, {params}
    );
  }

  getDeductionData(benefitCode: string, memberId: string, queryParams: BenefitTimeInfo | any): Observable<GetDeductionFundingSourcesResponse> {
    let params = new HttpParams();
    Object.keys(queryParams).forEach(key => {
      params = params.append(key, queryParams[key]);
    });
    return this.httpClient.get<GetDeductionFundingSourcesResponse>(
      `${environment.apiUrl}/new-payroll/one-time-payment/${benefitCode}/member/${memberId}/get-deductions-as-funding-source/types-of-deduction`, {params}
    );
  }

  getPaymentAddress(payeeId: string, isPerson: boolean, benefitCode?: string): Observable<{ addressData: AddressData[] }> {
    return this.httpClient.get<{ addressData: AddressData[] }>(
      `${environment.apiUrl}/payroll-settings/payee-address/${payeeId}?isPerson=${isPerson}&benefitCode=${benefitCode ?? ''}`,
    );
  }

  createOneTimePayment(body: PayloadOneTime) {
    return this.httpClient.post<{ id: string }>(`${environment.apiUrl}/new-payroll/payments/one-time-payment`, body);
  }

  checkValidDate = (data: CheckBenefitPeriod) => {
    const datePipe = new DatePipe('en-US');
    const queryParams = data.queryParams;
    const paymentInstructionTypeId: string = data.paymentInstructionTypeId;
    let params = new HttpParams();
    Object.keys(queryParams).forEach(key => {
      params = params.append(key, queryParams[key]);
    });
    let key = '';
    let valueCheck = '';
    if (data['benefitPeriodStart']) {
      key = 'StartDate';
      valueCheck = datePipe.transform(data.benefitPeriodStart, 'yyyy-MM-dd') ?? '';
    } else if (data['benefitPeriodEnd']) {
      key = 'EndDate';
      valueCheck = datePipe.transform(data.benefitPeriodEnd, 'yyyy-MM-dd') ?? '';
    }

    params = params.append(key, valueCheck);
    return this.httpClient.get<{ oneTimePaymentDatePeriodDto: BenefitTimeInfo }>(
      `${environment.apiUrl}/new-payroll/one-time-payment/validate-benefit-date-period/${paymentInstructionTypeId}`, {params}
    ).pipe(
      map((res: { exists: boolean, message: string, isValid?: boolean } | any) => {
        if (data?.screenId === 'add-one-time') {
          return {
            exists: res.exists,
            isValid: res.isValid,
            message: res.message
          }
        }
        
        return {isValid: res.isValid === null ? res.exists : res.isValid, message: res.message, existedPeriod: res.exists};
      })
    );
  }

  checkValidPayableDate = ({paymentInstructionId, payableDate}: CheckPayableDate) => {
    return this.httpClient.get<CheckPayableDateResponse>(
      `${environment.apiUrl}/new-payroll/one-time-payment/validate-payable-date-input/${paymentInstructionId}`,
      {
        params: {
          inputtedPayableDate: payableDate
        }
      }
    );
  }

  checkDisplayWithHoldingTax(queryParams: QueryParamsWithHoldingTax | any): Observable<{ isValid: boolean }> {
    let params = new HttpParams();
    Object.keys(queryParams).forEach(key => {
      params = params.append(key, queryParams[key]);
    });
    return this.httpClient.get<{ isValid: boolean }>(
      `${environment.apiUrl}/new-payroll/one-time-payment/validate-display-withhold-tax/${queryParams.BenefitSubType}`, {params}
    );
  }

  checkConditionBeforeSave(
    benefitSubType: string,
    queryParams: QueryParamsCheckEndSave | any,
  ): Observable<{ isValid: boolean; message?: string }> {
    let params = new HttpParams();
    Object.keys(queryParams).forEach((key) => {
      params = params.append(key, queryParams[key]);
    });
    return this.httpClient.get<{ isValid: boolean; message?: string }>(
      `${environment.apiUrl}/new-payroll/one-time-payment/validate-saving-rule/${benefitSubType}`,
      { params },
    );
  }

  checkValidDateAmount = (data: PayloadAmountValidate) => {
    const queryParams: any = data.queryParams;
    let params = new HttpParams();
    Object.keys(queryParams).forEach(el => {
      params = params.append(el, queryParams[el]);
    });

    return this.httpClient.get(
      `${environment.apiUrl}/new-payroll/one-time-payment/validate-input-incorrect-deduction-amount/${data.memberId}`, {params}
    );
  }

  mapDeductionTypesFromResponse<T>(data?: GetDeductionFundingSourcesResponse, options?: {
    sortFunctions?: Record<number, (a: SubType, b: SubType) => number>,
    mapFunctionFactory?: (item: FundingSourceItems) => ((item: SubType, i: number) => Option) | null,
  }) {
    let listDeductionType: Option[] = [];
    let listTax: Option[] = [];
    let listInsuranceDeduction: Option[] = [];
    let listOthersDeduction: Option[] = [];
    let listCourtOrder: Option[] = [];
    let listCourtOrderQdro: Option[] = [];
    let deductionLabels: Record<DeductionType, string> = DeductionTypeRevert;

    const defaultMapFunction = ((item: SubType, i: number) => {
      return {
        displayValue: item.deductionCode + ' - ' + item.subTypeName,
        value: item.payrollBenefitDeductionId?.toString() + ':' + i,
        extraData: item,
      } as Option;
    });
    const defaultMapFunctionCourtOrders = (item: SubType, i: number) => ({
      displayValue: item.caseNumber || '',
      value: item.payrollBenefitDeductionId?.toString() + ':' + i,
      extraData: item,
    } as Option);

    if (data) {
      listDeductionType = data.listTypeOfDeductions
        .filter((el) => el.deductionType !== DeductionType.Adjustment)
        .map((el) => {
          const mapFunction = options?.mapFunctionFactory?.(el) ?? defaultMapFunction;
          const mapFunctionCourtOrders = options?.mapFunctionFactory?.(el) ?? defaultMapFunctionCourtOrders;

          switch (el.deductionType) {
            case DeductionType.Tax:
              listTax = el.deductionSubTypes.map((item) => {
                return {
                  displayValue: item.subTypeName || '',
                  value: item.payrollBenefitDeductionId?.toString() || '',
                  extraData: item,
                };
              });
              break;
            case DeductionType.Insurance:
              listInsuranceDeduction = el.deductionSubTypes.map(mapFunction);
              break;
            case DeductionType.Others:
              const deductionSubTypes = options?.sortFunctions?.[el.deductionType] ?
                deepClone(el.deductionSubTypes).sort(options?.sortFunctions[el.deductionType]) :
                el.deductionSubTypes;
              listOthersDeduction = deductionSubTypes.map(mapFunction);
              break;
            case DeductionType.Garnishment:
              listCourtOrder = (el.courtOrderDeductions || []).map(mapFunctionCourtOrders);
              break;
            case DeductionType.Qdro:
              listCourtOrderQdro = (el.courtOrderDeductions || [])
                .slice()
                .sort((a, b) => a.caseNumber?.localeCompare(b.caseNumber ?? '') ?? 0)
                .map(mapFunctionCourtOrders);
              break;
          }
          deductionLabels[el.deductionType] = el.labelName || DeductionTypeRevert[el.deductionType];
          return {
            displayValue: deductionLabels[el.deductionType],
            value: el.deductionType,
          };
        });
    }
    return {
      listDeductionType,
      listTax,
      listInsuranceDeduction,
      listOthersDeduction,
      listCourtOrder,
      listCourtOrderQdro,
      deductionLabels
    };
  }

  subscribeQdroForm(formGroup: FormGroup, unsubscribe$: Subject<void>): FormGroup {
    formGroup.get('postOrPretax')?.disable();
    formGroup.get('amount')?.disable();
    formGroup.get('courtOrder')?.valueChanges.pipe(takeUntil(unsubscribe$)).subscribe(courtOrder => {
      if (courtOrder) {
        formGroup.get('postOrPretax')?.enable();
        formGroup.get('amount')?.enable();
      } else {
        formGroup.get('postOrPretax')?.disable();
        formGroup.get('amount')?.disable();
      }
    });
    return formGroup;
  }
}
