import { Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { BaseComponent } from '@ptg-shared/components';
import { ChipInputType } from '@ptg-shared/controls/chip-selection/types/enums';
import { takeUntil } from 'rxjs/operators';
import { PayeeDetailState, markRemoveOverpaidSelector } from '../../store';
import { PayStatus } from '../../types/enums';
import * as EditPaymentStatusActions from '@ptg-member/features/payee-detail/store/actions';
import * as PayeeDetailAction from '../../store/actions';
import { OTHER_OVERPAID_REASON_GUID } from '@ptg-shared/constance';
import { PaymentTab } from '../../services/models';
import { getSuspensionReasonsSelector } from '../../store/selectors/edit-payment-status.selectors';
import { Option } from '@ptg-shared/controls/select/select.component';

type OptionValue = string;

export interface MarkRemoveOverpaidDialogInput {
  selectedRow: PaymentTab;
};

export interface MarkRemoveOverpaidDialogOutput {
  isSuccess?: boolean;
  isOverpaid: boolean;
};

@Component({
  selector: 'ptg-mark-remove-overpaid',
  templateUrl: './mark-remove-overpaid.component.html',
  styleUrls: ['./mark-remove-overpaid.component.scss']
})
export class MarkRemoveOverpaidComponent extends BaseComponent {
  public readonly form = this.formBuilder.group({
    isOverpaid: false,
    otherReasons: '',
    _reasonSelect: null
  });
  public selectedReasons: Option<OptionValue>[] = [];

  private readonly isOverpaidCtrl = this.form.get('isOverpaid');

  private readonly OTHER = OTHER_OVERPAID_REASON_GUID;

  private fullOverpaidReasons: Option<OptionValue>[] = [];

  ChipInputType = ChipInputType;

  isLoading = true;

  overpaidReasons: Option<OptionValue>[] = [];
  showOtherReasons = false;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<MarkRemoveOverpaidComponent, MarkRemoveOverpaidDialogOutput>,
    private readonly store: Store<PayeeDetailState>,
    @Inject(MAT_DIALOG_DATA) private data: MarkRemoveOverpaidDialogInput
  ) {
    super();
    // Load data
    const savedReasonOptionIds = this.data.selectedRow.overPaidReason?.reasonOptionIds ?? [];
    this.form.patchValue({
      isOverpaid: this.data.selectedRow.isOverPaid,
      otherReasons: this.data.selectedRow.overPaidReason?.otherDescription
    });
    // Reasons is mandatory if Overpaid = checked
    this.isOverpaidCtrl?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((isOverpaid: boolean) => {
      this.setupOptionalControl(this.form.controls['_reasonSelect'], {required: isOverpaid});
    });
    // Overpaid Reason lookup table + "Other" option
    this.store.dispatch(EditPaymentStatusActions.getReasonsAction({
      statuses: [PayStatus.Finalized]
    }));
    this.store.select(getSuspensionReasonsSelector).pipe(takeUntil(this.unsubscribe$)).subscribe(getReasons => {
      this.isLoading = !!getReasons?.isLoading;
      const reasons = getReasons?.payload?.overpaidReasonsResponse?.reasons;
      if (reasons) {
        this.fullOverpaidReasons = reasons.map(reason => ({
          displayValue: reason.reasonOptionDescription ?? '',
          value: reason.reasonOptionId ?? ''
        })) ?? [];
        this.selectedReasons = savedReasonOptionIds.map(reasonId => this.fullOverpaidReasons.find(reason => reason.value === reasonId) ?? {displayValue: '', value: ''})
        this.onChangeOverpaidReasons();
        this.store.dispatch(EditPaymentStatusActions.clearGetReasonsState());
      }
    });
  }

  ngOnInit(): void {
  }

  private setupOptionalControl(control: AbstractControl | null, options: {required: boolean}) {
    if (options.required) {
      control?.addValidators(Validators.required);
    } else {
      control?.removeValidators(Validators.required);
      // control has no more validators
      control?.setErrors(null);
      control?.markAsUntouched();
    }
  }

  private filterOptions() {
    const selectedReasonIds = this.selectedReasons.map(reason => reason.value);
    this.overpaidReasons = this.fullOverpaidReasons.filter(reason => !selectedReasonIds.includes(reason.value));
  }

  private onChangeOverpaidReasons() {
    this.validateReasonSelect();
    this.filterOptions();

    if (this.selectedReasons.length > 0) {
      // Disable check box if there is at least one reason had been added
      this.isOverpaidCtrl?.disable();
    } else {
      // If user remove every added chips, system automatically untick and enable the Overpaid check box when the last chip is removed.
      this.isOverpaidCtrl?.setValue(false);
      this.isOverpaidCtrl?.enable();
    }
    // Only show Other Reasons if "Other" is added as chip in [Reason]. Else, hide this component.
    this.showOtherReasons = this.selectedReasons.some(reason => reason.value === this.OTHER);
    this.setupOptionalControl(this.form.get('otherReasons'), {required: this.showOtherReasons});
  }

  onSave() {
    const {isOverpaid, otherReasons} = this.form.getRawValue();
    if (isOverpaid && this.selectedReasons.length === 0) {
      this.form.controls['_reasonSelect'].setErrors({ required: true });
      this.form.controls['_reasonSelect'].markAsTouched();
    }
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }
    // Save mark/remove overpaid
    this.store.dispatch(PayeeDetailAction.markRemoveOverpaidAction({
      request: {
        paymentInstructionHistoryId: this.data.selectedRow.paymentInstructionHistoryId || '',
        isOverpaid,
        overpaidReasonIds: this.selectedReasons.map(reason => reason.value),
        otherDescription: otherReasons
      }
    }));
    this.store.select(markRemoveOverpaidSelector).pipe(takeUntil(this.unsubscribe$)).subscribe(state => {
      if (state?.isLoading === false) {
        // Close on Success/Error
        this.dialogRef.close({
          isSuccess: state.success,
          isOverpaid
        });
      }
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  //#region Select reason
  validateReasonSelect() {
    if (this.selectedReasons.length === 0) {
      this.form.controls['_reasonSelect'].setErrors({ required: true });
    } else {
      this.form.controls['_reasonSelect'].setErrors(null);
    }
  }

  addReason() {
    const reasonId = this.form.controls['_reasonSelect'].value;
    const reason = this.overpaidReasons.find(reason => reason.value === reasonId);
    const exists = this.selectedReasons.some(reason => reason.value === reasonId);
    if (reason && !exists) {
      this.selectedReasons.push(reason);
      this.form.controls['_reasonSelect'].reset();
    }
    this.onChangeOverpaidReasons();
  }

  removeReason(index: number) {
    this.selectedReasons.splice(index, 1);
    this.onChangeOverpaidReasons();
  }
  //#endregion
}
