import { Injectable } from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DateTime } from 'luxon';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  AdjustmentFunding,
  CreateValidateAdjustmentFundingSourcePeriodsRequest,
  FundingSource,
} from '../../services/models';
import { EditFundingSourceService } from '../../services/edit-funding-source.service';
import { getDateFormatISO } from '@ptg-shared/utils/string.util';
import { AdjustmentFundingDate, ValidateAdjustmentFundingSourcePeriodErrorType } from '../../types/enums';

@Injectable()
export class EditFundingSourcesComponentService {
  constructor(
    private readonly fb: FormBuilder,
    private editFundingSourceService: EditFundingSourceService,
  ) {}

  private _paymentId: string = '';
  private _benefitTypeOptionId: string = '';
  private _fundingSourceItem: FundingSource = {
    id: '',
    name: '',
    amount: 0,
    order: 1,
    adjustmentFundingSourceId: '',
    piFundingAmountId: '',
    taxable: false
  };
  private _adjustmentFunding: AdjustmentFunding = {
    id: '',
    startDate: '',
    endDate: '',
    amount: 1,
    order: 1,
    piFundingAmountId: '',
    name: '',
    isEditable: true,
  };

  set setPaymentId(newVal: string) {
    this._paymentId = newVal;
  }

  get getPaymentId(): string {
    return this._paymentId;
  }

  set setBenefitTypeOptionId(newVal: string) {
    this._benefitTypeOptionId = newVal;
  }

  get getBenefitTypeOptionId(): string {
    return this._benefitTypeOptionId;
  }

  set setFundingSourceItem(item: FundingSource) {
    this._fundingSourceItem = item ?? this._fundingSourceItem;
  }

  get getFundingSourceItem(): FundingSource {
    return this._fundingSourceItem;
  }

  set setAdjustmentFunding(item: AdjustmentFunding) {
    this._adjustmentFunding = item ?? this._adjustmentFunding;
  }

  get getAdjustmentFunding(): AdjustmentFunding {
    return this._adjustmentFunding;
  }

  get getInitForm(): FormGroup {
    return this.fb.group({
      grossPayment: [null],
      totalDeductions: [null],
      nextPayment: [null],
      fundingSourcesList: this.fb.array([]),
      adjustmentFundingList: this.fb.array([]),
    });
  }

  get newFundingSourceItem(): FormGroup {
    const getFundingSourceItem = this.getFundingSourceItem;
    if (getFundingSourceItem.fundingSources?.length === 1) {
      getFundingSourceItem.id = getFundingSourceItem.fundingSources[0].value;
    }

    return this.fb.group({
      id: [getFundingSourceItem.id],
      amount: [
        getFundingSourceItem.amount,
        {
          validators: [Validators.required, this.validateAmount()],
        },
      ],
      piFundingAmountId: getFundingSourceItem.piFundingAmountId,
      order: [getFundingSourceItem.order],
      fundingSources: [getFundingSourceItem.fundingSources],
      taxable: getFundingSourceItem.fundingSources?.find(item => item.value === getFundingSourceItem.id)?.taxable || getFundingSourceItem.taxable
    });
  }

  get newAdjustmentItem(): FormGroup {
    const getAdjustmentFunding = this.getAdjustmentFunding;
    if (getAdjustmentFunding?.adjustmentFundingOptions?.length === 1) {
      getAdjustmentFunding.id = getAdjustmentFunding.adjustmentFundingOptions[0].value;
    }
    return this.fb.group({
      id: [getAdjustmentFunding.id, Validators.required],
      amount: [
        getAdjustmentFunding.amount,
        {
          validators: [Validators.required, this.validateAmount()],
        },
      ],
      name: [getAdjustmentFunding.name],
      startDate: [
        getAdjustmentFunding.startDate,
        {
          validators: [this.validateEndDate()],
          asyncValidators: this.validateAdjustmentFundingSourcePeriodsStart(),
        },
      ],
      endDate: [
        getAdjustmentFunding.endDate,
        {
          validators: [this.validateEndDate()],
          asyncValidators: this.validateAdjustmentFundingSourcePeriodsEnd(),
        },
      ],
      order: [getAdjustmentFunding.order],
      piFundingAmountId: [getAdjustmentFunding.piFundingAmountId],
      isEditable: [getAdjustmentFunding.isEditable],
    });
  }

  validateAmount(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value < 0) {
        return {
          errorAmountMessage: 'Amount must be within the range of 0.00 - 9,999,999,999,999.99.',
        };
      }
      return null;
    };
  }

  validateEndDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.parent) {
        return null;
      }
      const startDate = control.parent?.get('startDate')?.value;
      const endDate = control.parent?.get('endDate')?.value;
      if (!startDate || !endDate) {
        return null;
      }
      if (DateTime.fromISO(startDate)?.toMillis() > DateTime.fromISO(endDate)?.toMillis()) {
        return {
          errorDateMessage: 'End Date must be greater than or equal to Start Date.',
        };
      }
      return null;
    };
  }

  validateAdjustmentByDate(control: AbstractControl, dateType: string): Observable<ValidationErrors | null> {
    const date = control.parent?.get(dateType)?.value;
    if (!date) {
      return of(null);
    }

    const request: CreateValidateAdjustmentFundingSourcePeriodsRequest = {
      paymentId: this.getPaymentId,
      benefitTypeOptionId: this.getBenefitTypeOptionId,
      startDate: dateType === 'startDate' ? getDateFormatISO(date).toString() : '',
      endDate: dateType === 'endDate' ? getDateFormatISO(date).toString() : '',
    };

    return timer(300).pipe(
      switchMap(
        (): Observable<ValidationErrors | null> =>
          this.editFundingSourceService.createValidateAdjustmentFundingSourcePeriods(request).pipe(
            map((res: any) => {
              if (!res?.isValid) {
                switch (res?.errorType) {
                  case ValidateAdjustmentFundingSourcePeriodErrorType.StartDateMustBeStartDateOfPayrollCycles:
                    return {
                      errorDateMessage: 'Start Date must be Start Date of Payroll cycles.',
                    };
                  case ValidateAdjustmentFundingSourcePeriodErrorType.EndDateMustBeEndDateOfPayrollCycles:
                    return {
                      errorDateMessage: 'End Date must be End Date of Payroll cycles.',
                    };
                  default:
                    return null;
                }
              }
              return null;
            }),
            catchError(({ error }) => {
              return of({ errorMessageTagName: error?.errorMessage });
            }),
          ),
      ),
    );
  }

  validateAdjustmentFundingSourcePeriodsStart(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      this.validateAdjustmentByDate(control, AdjustmentFundingDate.StartDate);
  }

  validateAdjustmentFundingSourcePeriodsEnd(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> =>
      this.validateAdjustmentByDate(control, AdjustmentFundingDate.EndDate);
  }
}
