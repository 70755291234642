<ptg-dialog class="reissue" [title]="dialogTitle"  [ngClass]="{'has-profile-header': !!data.payeeRecordId}" [isFullScreen]="true">
  <ng-template dialogContentHeader>
    <div class="member-profile-container" *ngIf="data.payeeRecordId">
      <ptg-overview-header-entity
      [targetIdFromDialog]="data.payeeRecordId"
      ></ptg-overview-header-entity>
    </div>
  </ng-template>
  <ng-template dialogBody>
    <div class="action-buttons flex gap-2 mb-6">
      <ptg-button classInput="primary" buttonName="Reissue" [isDisabled]="isDisabledReissue" (clickButton)="onSubmit()"></ptg-button>
      <ptg-button classInput="tertiary" buttonName="Cancel" (clickButton)="onCancel()"></ptg-button>
    </div>
    <div class="form-control-container">
      <div class="mb-2" *ngIf="bannerType">
        <ptg-banner [(bannerType)]="bannerType" [message]="message"></ptg-banner>
      </div>
      <div class="flex gap-6 mb-2">
        <div class="min-w-[140px]">
          <div class="text-sm leading-6 emphasis-medium">Recipient</div>
          <div class="text-base emphasis-high">{{ data.registerData?.recipient }}</div>
        </div>
      </div>

      <div class="grid gap-6 mb-2" [ngClass]="{'grid-cols-4': data.registerData.paymentMethod === PaymentType.Check, 'grid-cols-3': data.registerData.paymentMethod === PaymentType['Direct Deposit']}">
        <div class="min-w-[140px]">
          <div class="text-sm leading-6 emphasis-medium">Transaction ID</div>
          <div class="text-base emphasis-high">{{ data.registerData?.transactionId }}</div>
        </div>

        <div *ngIf="data.registerData.paymentMethod === PaymentType.Check" class="min-w-[140px]">
          <div class="text-sm leading-6 emphasis-medium">Check Number</div>
          <div class="text-base emphasis-high">{{ data.isConfigOST ? formatCheckNumberOST(data.registerData?.checkNumber) : data.registerData?.checkNumber }}</div>
        </div>

        <div class="min-w-[140px]">
          <div class="text-sm leading-6 emphasis-medium">Date Posted</div>
          <div class="text-base emphasis-high">{{ data.registerData.datePosted! | dateFormat }}</div>
        </div>

        <div *ngIf="data.registerData.paymentMethod === PaymentType.Check" class="min-w-[140px]">
          <div class="text-sm leading-6 emphasis-medium">Check Date</div>
          <div class="text-base emphasis-high">{{ (data.registerData?.checkDate | date: 'MM/dd/yyyy') || '-' }}</div>
        </div>

        <div *ngIf="data.registerData.paymentMethod === PaymentType['Direct Deposit']" class="min-w-[140px]">
          <div class="text-sm leading-6 emphasis-medium">Deposit Date</div>
          <div class="text-base emphasis-high">{{ (data.registerData?.depositDate | date: 'MM/dd/yyyy') || '-' }}</div>
        </div>
      </div>

      <div *ngIf="data.registerData.paymentMethod === PaymentType.Check" class="grid grid-cols-2 gap-6 mb-2">
        <div class="min-w-[140px]">
          <div class="text-sm leading-6 emphasis-medium">Current Address</div>
          <ptg-view-address
            class="text-base emphasis-high"
            [value]="data.registerData?.addressDto"
          ></ptg-view-address>
        </div>

        <div class="min-w-[140px]">
          <div class="text-sm leading-6 emphasis-medium">Net Payment</div>
          <div class="text-base emphasis-high">{{ (data.registerData?.netAmount || 0) | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
        </div>
      </div>

      <div *ngIf="data.registerData.paymentMethod === PaymentType['Direct Deposit']" class="grid grid-cols-3 gap-6 mb-2">
        <div class="min-w-[140px] col-span-1">
          <div class="text-sm leading-6 emphasis-medium">Net Payment</div>
          <div class="text-base emphasis-high">{{ (data.registerData?.netAmount || 0) | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
        </div>
        <div class="min-w-[140px] col-span-1">
          <div class="text-sm leading-6 emphasis-medium">Account</div>
          <div class="text-base emphasis-high">{{ accountNo }}</div>
        </div>
      </div>

      <!--TODO: Mock data-->
      <div class="flex gap-6 mb-2">
        <div class="min-w-[140px]">
          <div class="text-sm leading-6 emphasis-medium">Reason</div>
          <div class="text-base emphasis-high">{{ voidedReasons.join(', ') || '' }}</div>
        </div>
      </div>

      <ng-container *ngIf="isOneTimeOrRecurring && isEnableReissueToEstate">
        <div class="flex gap-6 mb-2">
          <ptg-checkbox 
            [controlField]="chkReissueToEstateCtrl" 
            (changeValue)="onChangeReissueToEstate($event)"
            label="Reissue to Estate"></ptg-checkbox>
        </div>

        <div class="flex gap-6 mb-2" *ngIf="chkReissueToEstateCtrl?.value">
          <ptg-select
            placeholder="Payee"
            [controlField]="payeeCtrl"
            [listData]="estateDataList"
            [isOptionObj]="true"
            [isMultipleLineOption]="true"
            (changeControlValue)="handlePayeeAddress()"
          ></ptg-select>
        </div>
      </ng-container>

      <div class="flex gap-6 mb-2" *ngIf="isOneTimeOrRecurring">
        <ptg-select
          [controlField]="paymentMethodCtrl"
          [listData]="listPaymentMethod"
          [isOptionObj]="true"
          placeholder="Payment Method"
          (changeOptionValue)="onChangePaymentMethod($event)"
        ></ptg-select>
      </div>

      <div
        *ngIf="
          isOneTimeOrRecurring &&
          paymentMethodCtrl?.value === PaymentType['Direct Deposit']
        "
        class="flex gap-6 mb-2"
      >
        <ptg-select
          [controlField]="accountCtrl"
          [listData]="listAccount"
          [isOptionObj]="true"
          placeholder="Account #"
        ></ptg-select>
      </div>

      <div *ngIf="!chkReissueToEstateCtrl?.value && !isDeductionPayee" class="flex gap-6 mb-2">
        <ptg-checkbox [controlField]="chkRepresentativePayeeCtrl" label="Representative Payee"></ptg-checkbox>
      </div>

      <div *ngIf="chkRepresentativePayeeCtrl?.value === true && !chkReissueToEstateCtrl?.value" class="flex gap-6 mb-2">
        <ptg-select
          placeholder="Representative Payee"
          [controlField]="representativePayeeCtrl"
          [listData]="listRepresentativePayee"
          [isOptionObj]="true"
          (changeControlValue)="handlePayeeAddress()"
          [isMultipleLineOption]="true"
        ></ptg-select>
      </div>

      <div *ngIf="isOneTimeOrRecurring && paymentMethodCtrl?.value === PaymentType['Direct Deposit'] && !chkReissueToEstateCtrl?.value" class="flex gap-6 mb-2">
        <ptg-checkbox [controlField]="chkUpdateBankAccountCtrl" label="Update Bank Account to Next Payment"></ptg-checkbox>
      </div>

      <div
        *ngIf="
          isOneTimeOrRecurring && paymentMethodCtrl?.value === PaymentType.Check
        "
        class="flex gap-6 mb-2"
      >
        <ptg-select
          placeholder="Payment Address"
          [controlField]="paymentAddressCtrl"
          [listData]="listPaymentAddress"
          [isOptionObj]="true"
        ></ptg-select>
      </div>

      <div
        *ngIf="
          isOneTimeOrRecurring &&
          paymentMethodCtrl?.value === PaymentType.Check &&
          !chkReissueToEstateCtrl?.value
        "
        class="flex gap-6 mb-2"
      >
        <ptg-checkbox
          [controlField]="chkPaymentAddressCtrl"
          label="Update Payment Address to Next Payment"
        ></ptg-checkbox>
      </div>

      <div *ngIf="paymentMethodCtrl?.value === PaymentType.Check" class="flex gap-6 mb-2">
        <ptg-textbox
          placeholder="Check Memo"
          [controlField]="checkMemoCtrl"
          [hasLabel]="true"
          [maxLength]="250"
        ></ptg-textbox>
      </div>

      <div *ngIf="isDeductionPayee && paymentMethodCtrl?.value === PaymentType['Direct Deposit']" class="flex gap-6 mb-2">
        <ptg-textbox
          placeholder="Addendum"
          [controlField]="addendumCtrl"
          [hasLabel]="true"
          [maxLength]="80"
        ></ptg-textbox>
      </div>

      <div class="flex gap-6 mb-2">
        <ptg-datepicker
          [controlField]="payableDateCtrl"
          placeholder="Payable Date"
          class="input-upload-date"
          customError="errorMinDate"
        ></ptg-datepicker>
      </div>
    </div>
  </ng-template>
</ptg-dialog>
