import { AggregationConfigEffects } from './aggregation-config.effects';
import { AggregationGroupEffects } from './aggregation-group.effects';
import { CalculationEffects } from './calculation.effect';
import { CartEffects } from './cart.effects';
import { DefinedBenefitEffects } from './defined-benefits.effects';
import { DropPlanEffects } from './drop-plan.effects';
import { HeaderEffect } from './header.effects';
import { LookupTableEffects } from './lookup-table.effects';
import { MemberBenefitEffects } from './member-benefit.effects';
import { MemberDetailEffects } from './member-detail.effects';
import { MemberEffect } from './member.effect';
import { MemberListConfigurationEffects } from './member-list-configuration.effects';
import { MemberListEffects } from './member-list.effects';
import { MemberNavigationEffects } from './member-navigation';
import { MemberNotificationOptInsEffect } from './member-notification-opt-in.effects';
import { MemberPropertyEffects } from './member-property.effects';
import { MetadataDetailEffects } from './metadata-detail.effects';
import { MetadataEffects } from './metadata.effects';
import { MunicipalityPaymentEffects } from './municipality-payment.effects';
import { MunicipalityServiceHistoryEffects } from './municipality-service-history.effects';
import { NonListConfigurationEffects } from './non-list-configuration.effects';
import { ParticipantNavigationConfigurationEffects } from './participant-navigation-configuration.effects';
import { ParticipantRelationshipEffects } from './participant-relationship.effects';
import { ParticipantReportEffect } from './participant-report.effects';
import { ParticipantSearchConfigurationEffects } from './participant-search-configuration.effects';
import { PaymentListConfigurationEffects } from './payment-list-configuration.effects';
import { ProfileConfigEffect } from './profile-configuration.effects';
import { ProfileHeaderConfigurationEffects } from './profile-header-configuration.effects';
import { ProfileNavigationConfigurationEffects } from './profile-navigation-configuration.effects';
import { ProfileOverviewConfigurationEffects } from './profile-overview-configuration.effects';
import { PropertyEffects } from './property.effects';
import { ReportEffects } from './report.effects';
import { StatusHistoryEffects } from './status-history.effects';
import { AddressHistoryEffects } from './address-history.effects';
import { StatusEffects } from './status.effects';
import { TierConfigurationEffects } from './tier-configuration.effects';
import { TotalServicesEffects } from './total-services.effects';
import { ViewEffect } from './view.effect';
import { DistributionMappingEffect } from './distributionMapping.effects';
import { RelatedPersonEffects } from './related-person.effects';
import { ParticipantDocumentsEffects } from './participant-documents.effects';
import { DocumentListEffects } from './member-document.effects';
import { DepositAccountEffects } from './deposit-account.effects';

export const MemberEffects = [
  AggregationConfigEffects,
  AggregationGroupEffects,
  MemberListEffects,
  MemberEffect,
  ReportEffects,
  MemberDetailEffects,
  MetadataDetailEffects,
  MemberBenefitEffects,
  DocumentListEffects,
  DefinedBenefitEffects,
  MetadataEffects,
  PropertyEffects,
  LookupTableEffects,
  StatusEffects,
  ProfileOverviewConfigurationEffects,
  ProfileHeaderConfigurationEffects,
  MemberListConfigurationEffects,
  MemberNavigationEffects,
  ProfileNavigationConfigurationEffects,
  StatusHistoryEffects,
  RelatedPersonEffects,
  TotalServicesEffects,
  MunicipalityPaymentEffects,
  MunicipalityServiceHistoryEffects,
  MemberPropertyEffects,
  ParticipantSearchConfigurationEffects,
  ParticipantRelationshipEffects,
  MemberNotificationOptInsEffect,
  NonListConfigurationEffects,
  PaymentListConfigurationEffects,
  CalculationEffects,
  TierConfigurationEffects,
  ParticipantReportEffect,
  DropPlanEffects,
  CartEffects,
  ParticipantNavigationConfigurationEffects,
  ViewEffect,
  HeaderEffect,
  ProfileConfigEffect,
  DistributionMappingEffect,
  AddressHistoryEffects,
  ParticipantDocumentsEffects,
  DepositAccountEffects
];
