import { Component, Inject } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ENTITY_ORGANIZATION_GUID, MAX_VALUE_NUMBER } from '@ptg-shared/constance';
import * as fromMember from '../../store/reducers';
import { Option } from '@ptg-shared/controls/select/select.component';
import { Store, select } from '@ngrx/store';
import { deepClone, showBanner, showCancelDialog } from '@ptg-shared/utils/common.util';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { BaseComponent } from '@ptg-shared/components';
import { InputType } from '@ptg-shared/controls/dynamic-input/types/enums/dynamic-input.enum';
import {
  checkDisplayWithHoldingTaxAction,
  clearDeductionDataStateAction,
  getBenefitTimeInfoAction,
  getDeductionDataAction,
  getFundingSourceAction,
  getOneTimeCorrectionTypeAction,
  getOneTimePaymentTypeAction,
  getPayeeListAction,
  getPaymentAddressAction,
} from '@ptg-member/features/payee-detail/store/actions';
import {
  addFundingSourceSelector,
  addOneTimeCorrectionTypeSelector,
  addOneTimePaymentTypeSelector,
  addPayeeListSelector,
  benefitTimeInfoSelector,
  deductionDataSelector,
  displayWithHoldingTaxSelector,
  paymentAddressSelector,
} from '@ptg-member/features/payee-detail/store/selectors';
import { CorrectionType, OneTimePaymentType } from '@ptg-member/constance/paymentType.const';
import { HeaderBenefit } from '@ptg-member/features/payee-detail/types/models';
import { DatePipe } from '@angular/common';
import { DeductionType, DeductionTypeRevert, PayStatus, TaxSettingType } from '@ptg-member/features/payee-detail/types/enums';
import {
  AddressData,
  FundingSourceDetail,
  GetDeductionFundingSourcesResponse,
  PayeeElementDetail,
  PayloadOneTime,
  PayrollBenefitDeduction,
  QueryParamsBenefit,
  QueryParamsWithHoldingTax,
} from '@ptg-member/features/payee-detail/services/models';
import { AddressPipe } from '@ptg-shared/pipes/address.pipe';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { AddOneTimeService } from '@ptg-member/features/payee-detail/services';
import { Observable, Subject, of, timer } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { selectProfileNavigationState } from '@ptg-shared/layout/reducers';
import { localeCompare } from '@ptg-shared/utils/string.util';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { CONTROL_NAME_QDRO, CREATE_OFFSET_DEDUCTION_LABEL } from '@ptg-member/features/payee-detail/types/constants';

const datePipe = new DatePipe('en-US');

@Component({
  selector: 'ptg-add-one-time',
  templateUrl: './add-one-time.component.html',
  styleUrls: ['./add-one-time.component.scss'],
})
export class AddOneTimeComponent extends BaseComponent {
  private screenId = 'add-one-time';
  readonly DeductionTypeRevert = DeductionTypeRevert;
  readonly OneTimePaymentType = OneTimePaymentType;
  readonly DeductionType = DeductionType;

  DeductionLabels: Record<DeductionType, string> = DeductionTypeRevert;

  readonly CREATE_OFFSET_DEDUCTION_LABEL = CREATE_OFFSET_DEDUCTION_LABEL;

  formSubmit$ = new Subject<boolean>();
  InputType = InputType;
  MAX_VALUE_NUMBER = MAX_VALUE_NUMBER;
  editForm = new FormGroup({
    oneTimePaymentType: new FormControl(''),
    payableDate: new FormControl(null),
    issueToEstate: new FormControl(false),
    payee: new FormControl(null),
    representativePayee: new FormControl(),
    paymentAddress: new FormControl(),
    fundingSource: new FormArray([this._createFundingSourceFormGroup()]),
    reason: new FormControl(''),
  });
  listOneTimePaymentType: Option[] = [];
  listCorrectionType: Option[] = [];
  listPayee: Option[] = [];
  listRepresentativePayee: Option[] = [];
  listPaymentAddress: Option[] = [];
  listFundingSource: Option[] = [];
  currentListFundingSource: Option[] = [];
  listDeductionType: Option[] = [];
  listChip: Option[] = [];
  listInsuranceDeduction: Option[] = [];
  listOthersDeduction: Option[] = [];
  listCourtOrder: Option[] = [];
  listCourtOrderQdro: Option[] = [];
  listDeductionPayee: Option[] = [];
  listTax: Option[] = [];
  postOrPretaxOptions: RadioOption[] = [
    {
      label: 'Pre Tax',
      value: TaxSettingType.PreTax,
    },
    {
      label: 'Post Tax',
      value: TaxSettingType.PostTax,
    },
  ];

  bannerType: BannerType = BannerType.Hidden;
  message = '';
  currentDate = new Date();
  maxStartDate = new Date();
  isEditableStartDate = true;
  isEditableEndDate = true;
  benefitPeriodOverlapErrorMessage = '';
  errorMaxStartDate = '';
  errorAsyncStartDate = '';
  errorAsyncEndDate = '';
  private payeeName = '';
  private relatedDataDefault = '';
  private entityId = '';
  private isFirstLoad = true;

  // System display this component if the payee has [Reported Deceased] = "CHECKED" or Date of Death <> null. Default = UNCHECKED
  isAbleReissueToEstate = false;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      memberId: string;
      viewName: string;
      payload: any;
      isAnnuityBenefitEntity?: boolean;
      selectedHeaderBenefit?: HeaderBenefit;
      infoData: {
        payeeEntityId: string;
        payeeEntityRecordId: string;
      };
    },
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddOneTimeComponent>,
    private memberStore: Store<fromMember.MemberState>,
    private readonly address: AddressPipe,
    private addOneTimeService: AddOneTimeService,
    private fb: FormBuilder,
  ) {
    super();
  }

  get benefitPeriodFormGroup() {
    return this.editForm.get('benefitPeriod') as FormGroup;
  }

  get deductionFormGroup() {
    return this.editForm.get('deduction') as FormGroup;
  }

  get taxFormGroup() {
    return this.deductionFormGroup.get('tax') as FormGroup;
  }

  get fundingSourceFormArray() {
    return this.editForm.get('fundingSource') as FormArray;
  }

  get insuranceFormArray() {
    return this.deductionFormGroup?.get('insurance') as FormArray;
  }

  get othersFormArray() {
    return this.deductionFormGroup?.get('others') as FormArray;
  }

  get garnishmentsFormArray() {
    return this.deductionFormGroup?.get('garnishments') as FormArray;
  }

  get qdroFormArray() {
    return this.deductionFormGroup?.get(CONTROL_NAME_QDRO) as FormArray;
  }

  get grossPayment() {
    if (this.fundingSourceFormArray) {
      const initialValue = 0;
      return this.fundingSourceFormArray.controls.reduce((accumulator, currentValue) => {
        return accumulator + (currentValue.get('amount')?.value ? Number(currentValue.get('amount')?.value) : 0);
      }, initialValue);
    } else if (this.deductionFormGroup) {
      let res = 0;
      res += this.taxFormGroup?.get('federalTax')?.value ? Number(this.taxFormGroup?.get('federalTax')?.value) : 0;
      res +=
        this.insuranceFormArray?.controls?.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue.get('amount')?.value ? Number(currentValue.get('amount')?.value) : 0);
        }, 0) || 0;
      res +=
        this.othersFormArray?.controls?.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue.get('amount')?.value ? Number(currentValue.get('amount')?.value) : 0);
        }, 0) || 0;
      res +=
        this.garnishmentsFormArray?.controls?.reduce((accumulator, currentValue) => {
          return accumulator + (currentValue.get('amount')?.value ? Number(currentValue.get('amount')?.value) : 0);
        }, 0) || 0;
      return res;
    }
    return 0;
  }

  ngOnInit(): void {
    if (this.data.isAnnuityBenefitEntity) {
      this._createBenefitPeriodFormGroup();
    } else {
      this.listOneTimePaymentType = [];
      this.listCorrectionType = [];
    }

    this.memberStore.dispatch(
      getOneTimePaymentTypeAction({
        benefitTypeOptionId: this.data.selectedHeaderBenefit?.benefitTypeOptionId ?? '',
        queryParams: this._getQueryParams(),
      }),
    );

    this.memberStore.dispatch(
      getFundingSourceAction({ benefitTypeOptionId: this.data.selectedHeaderBenefit?.benefitTypeOptionId ?? '' }),
    );

    this.memberStore.dispatch(
      getPayeeListAction({
        payeeId: this.data.memberId ?? '',
        benefitCode: this.data.selectedHeaderBenefit?.benefitId ?? '',
      }),
    );

    this.memberStore.pipe(select(selectProfileNavigationState), takeUntil(this.unsubscribe$)).subscribe((state) => {
      if (state?.menu?.length > 0 && !state?.isHyperlink) {
        this.entityId = (state?.memberNavigationList as any).entityId;
        this.memberStore.dispatch(
          getPaymentAddressAction({
            payeeId: this.data.memberId ?? '',
            isPerson: this.entityId !== ENTITY_ORGANIZATION_GUID,
            benefitCode: this.data.selectedHeaderBenefit?.benefitId,
          }),
        );
      }
    });

    this.memberStore
      .select(addOneTimePaymentTypeSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        this.listOneTimePaymentType = (el.payload || []).map((item) => ({
          displayValue: item.name,
          value: item.id,
        }));
        if (this.listOneTimePaymentType.length > 0 && !this.editForm.get('oneTimePaymentType')?.value) {
          this.editForm.get('oneTimePaymentType')?.setValue(OneTimePaymentType['Correction']);
          this.onChangeStatusValue();
        }
      });

    this.memberStore
      .select(addFundingSourceSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        this.listFundingSource = (el.payload || []).map((item) => ({
          displayValue: item.label,
          value: item.fundingSourceId,
          extraData: item
        }));
        this.setDefaultValueFundingSource();
      });

    this.memberStore
      .select(addOneTimeCorrectionTypeSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        this.listCorrectionType = (el.payload || []).map((item) => ({
          displayValue: item.name,
          value: item.id,
        }));
        if (this.listCorrectionType.length > 0 && !this.editForm.get('correctionType')?.value) {
          this.editForm.get('correctionType')?.setValue(CorrectionType.CorrectionOthers);
          this.onChangeCorrectionTypeValue();
        }
      });

    this.memberStore
      .select(addPayeeListSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        if (el.payload) {
          const {
            relatedDataList,
            estateDataList,
            payeeName = '',
            relatedDataDefault = '',
            isAbleReissueToEstate = false,
          } = el.payload;

          const payeeMapper = (item: PayeeElementDetail) => ({
            displayValue: item.name,
            value: item.id,
            valueDescription: item.relationship,
            extraData: item.isPerson,
          });

          this.listRepresentativePayee = (relatedDataList || []).map((item) => payeeMapper(item));
          this.payeeName = payeeName;
          this.relatedDataDefault = relatedDataDefault;
          if (this.isFirstLoad && this.relatedDataDefault && this.data.isAnnuityBenefitEntity) {
            this.changeRepresentativePayee(true);
            this.editForm.get('representativePayee')?.setValue(true);
          }
          this.isFirstLoad = false;

          this.isAbleReissueToEstate = isAbleReissueToEstate;
          this.listPayee = (estateDataList || [])
            .map((item) => payeeMapper(item))
            .sort((a: Option, b: Option) =>
              localeCompare(a.displayValue?.toLowerCase(), b.displayValue?.toLowerCase()),
            );
        }
      });

    this.memberStore
      .select(benefitTimeInfoSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        if (el.payload) {
          const oneTimePaymentType: OneTimePaymentType | null | undefined =
            this.editForm.get('oneTimePaymentType')?.value;
          const benefitBeginDate = el.payload.startDate ? new Date(el.payload.startDate) : null;
          const benefitEndDate = el.payload.endDate ? new Date(el.payload.endDate) : null;

          this.errorMaxStartDate = 'Start date can not be future date.';

          if (oneTimePaymentType === OneTimePaymentType['Final Payment']) {
            this.maxStartDate = el.payload.endDate ? new Date(el.payload.endDate) : new Date();
            this.benefitPeriodFormGroup?.get('benefitPeriodEnd')?.setValue(benefitEndDate);
            this.errorMaxStartDate = 'Start Date is invalid.';
          }

          if (oneTimePaymentType === OneTimePaymentType['Initial Payment']) {
            // Todo: [Benefit Period Start Date] = [Benefit Begin Date] of the corresponding Benefit.
            // [Benefit Period End Date] of the OTP is fixed as the [Start Date] of the first benefit period of the corresponding recurring payment instruction minus 1 days.
            this.benefitPeriodFormGroup?.get('benefitPeriodStart')?.setValue(benefitBeginDate);
            this.benefitPeriodFormGroup?.get('benefitPeriodEnd')?.setValue(benefitEndDate);
          }
        }
      });

    this.memberStore
      .select(paymentAddressSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        let tempDefault = (el.payload || [])[0];

        this.listPaymentAddress = deepClone(el.payload || [])
          .sort((a: AddressData, b: AddressData) => {
            if (a.propertyName > b.propertyName) {
              return 1;
            } else if (a.propertyName === b.propertyName) {
              return a.address.street1 > b.address.street1 ? 1 : -1;
            }
            return -1;
          })
          .map((item) => ({
            displayValue: `${item.list ? item.list + '/' : ''}${item.propertyName}`,
            value: item.address.code,
            valueDescription: this.address.transform(item.address),
            displayValueStyle: 'color: #1d7d51; font-weight: 700',
            hasSeparator: true,
            additionalDataConfig: {
              value: this.address.transform(item.address),
              style: 'color: #303030;',
            },
          }));

        if (
          this.editForm.get('paymentAddress') &&
          !this.editForm.get('paymentAddress')?.value &&
          this.data.isAnnuityBenefitEntity
        ) {
          if (this.relatedDataDefault === this.editForm.get('representativePayeeName')?.value) {
            this.editForm.get('paymentAddress')?.setValue(tempDefault?.address?.code);
          } else {
            this.editForm.get('paymentAddress')?.setValue(this.listPaymentAddress[0]?.value);
          }
        }
      });

    this.memberStore
      .select(displayWithHoldingTaxSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((el) => {
        if (!el.isLoading) {
          if (!this.editForm.get('withholdTaxes') && el.payload) {
            this.editForm.addControl('withholdTaxes', new FormControl(true));
          }
        }
      });

    this.memberStore
      .select(deductionDataSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((res) => {
        if (res?.payload) {
          this.setList(res.payload);
        }
      });

    this.editForm.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((el) => {
      this.benefitPeriodOverlapErrorMessage = '';
    });

    this.onSubmit();
  }

  setList(data: GetDeductionFundingSourcesResponse) {
    const result = this.addOneTimeService.mapDeductionTypesFromResponse(data);
    this.listDeductionType = result.listDeductionType;
    this.listTax = result.listTax;
    this.listInsuranceDeduction = result.listInsuranceDeduction;
    this.listOthersDeduction = result.listOthersDeduction;
    this.listCourtOrder = result.listCourtOrder;
    this.listCourtOrderQdro = result.listCourtOrderQdro;
    this.DeductionLabels = result.deductionLabels;
  }

  setDefaultValueFundingSource() {
    if (this.listFundingSource.length === 1) {
      this.fundingSourceFormArray?.controls[0]?.get('fundingSource')?.setValue(this.listFundingSource[0].value);
    }
  }

  private showBanner(): void {
    const customMessage: string =
      this.editForm.get('oneTimePaymentType')?.value === OneTimePaymentType['Final Payment']
        ? 'Benefit Period Start Date must match Start Date of a payroll cycle.'
        : 'Benefit Period Start Date and Benefit Period End Date must match Start Date and End Date of a payroll cycle.';

    showBanner.call(this, BannerType.Info, '', '', { customMessage });
  }

  changeStartDate() {
    this.editForm?.get('payableDate')?.setValue(null);
    this.benefitPeriodFormGroup.get("benefitPeriodStart")?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((el) => {
      if (el) {
        this._setValidateDate();
      }
    });
  }

  changeEndDate() {
    this.benefitPeriodFormGroup.get("benefitPeriodEnd")?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((el) => {
      if (el) {
        this._setValidateDate();
      }
    });
  }

  _setValidateDate() {
    if (this.benefitPeriodFormGroup.get("benefitPeriodStart")?.value > this.benefitPeriodFormGroup.get("benefitPeriodEnd")?.value &&
    this.benefitPeriodFormGroup.get("benefitPeriodEnd")?.value !== null && this.benefitPeriodFormGroup.get("benefitPeriodEnd")?.value !== null) {
    this.errorAsyncEndDate = "End Date must be after Start Date.";
    this.benefitPeriodFormGroup
    .get("benefitPeriodEnd")
    ?.setErrors({ inValidAsync: true });
    return;
  }

  if (!this.benefitPeriodFormGroup.get("benefitPeriodEnd")?.value) {
    this.benefitPeriodFormGroup
    .get("benefitPeriodEnd")
    ?.reset();
    return;
  }
  }

  onCheckIssueToEstate(): void {
    this.editForm.get('payee')?.reset();
    this.editForm.get('payee')?.setErrors(null);
  }

  onChangePayee(selectedOption: Option | null): void {
    if (!selectedOption) return;

    this.editForm.get('paymentAddress')?.setValue('');
    const selectedPayeeOption = this.listPayee.find((item) => item.value === selectedOption.value);

    this.memberStore.dispatch(
      getPaymentAddressAction({
        payeeId: selectedPayeeOption?.value ?? '',
        isPerson: selectedPayeeOption?.extraData,
        benefitCode: this.data.selectedHeaderBenefit?.benefitId ?? '',
      }),
    );
  }

  changeRepresentativePayee(event: boolean) {
    if (event) {
      this.editForm.addControl('representativePayeeName', new FormControl());
      this.editForm.addControl('representativePayeeNamePrint', new FormControl());
      this.listPaymentAddress = [];
      this.editForm.get('representativePayeeName')?.setValue(null);
      if (this.listRepresentativePayee.length > 0 && !this.editForm.get('representativePayeeName')?.value) {
        let defaultItem =
          this.listRepresentativePayee.find((el) => el.value === this.relatedDataDefault) ??
          this.listRepresentativePayee[0];
        this.editForm.get('representativePayeeName')?.setValue(defaultItem?.value);
        this.onChangePayeeValue();
      }
    } else {
      this.editForm.removeControl('representativePayeeName');
      this.editForm.removeControl('representativePayeeNamePrint');
      this.memberStore.dispatch(
        getPaymentAddressAction({
          payeeId: this.data.memberId ?? '',
          isPerson: this.entityId !== ENTITY_ORGANIZATION_GUID,
          benefitCode: this.data.selectedHeaderBenefit?.benefitId,
        }),
      );
    }
    this.editForm.get('paymentAddress')?.setValue(null);
  }

  onChangePayeeValue() {
    this.editForm.get('paymentAddress')?.setValue('');
    this.editForm.get('representativePayeeNamePrint')?.setValue('');
    const value = this.editForm.get('representativePayeeName')?.value;
    const item = this.listRepresentativePayee.find((el) => el.value === value);
    this.memberStore.dispatch(
      getPaymentAddressAction({
        payeeId: value ?? '',
        isPerson: item?.extraData,
        benefitCode: this.data.selectedHeaderBenefit?.benefitId,
      }),
    );
    // Auto set value for representativePayeeNamePrint
    this.editForm.get('representativePayeeNamePrint')?.setValue(item?.displayValue + ' FBO ' + this.payeeName);
  }

  private _getQueryParams(): QueryParamsBenefit {
    return {
      BenefitEntityDataId: this.data.selectedHeaderBenefit?.benefitEntityDataId ?? '',
      BenefitTypeOptionId: this.data.selectedHeaderBenefit?.benefitTypeOptionId ?? '',
      PayeeEntityRecordId: this.data.infoData.payeeEntityRecordId ?? '',
      BenefitTypeName: this.data.selectedHeaderBenefit?.benefitTypeName ?? '',
      BenefitRecordId: this.data.selectedHeaderBenefit?.benefitEntityDataId ?? '',
      BenefitCode: this.data.selectedHeaderBenefit?.benefitId ?? '',
    };
  }

  private _resetPayableDate() {
    const temp = this.editForm.get('payableDate')?.value;
    this.editForm.get('payableDate')?.reset();
    this.editForm.get('payableDate')?.setValue(temp);
    this.editForm.updateValueAndValidity();
  }

  onChangeStatusValue() {
    const oneTimePaymentType = this.listOneTimePaymentType.find(
      (el) => el.value === this.editForm.get('oneTimePaymentType')?.value,
    )?.value;

    this.memberStore.dispatch(
      getBenefitTimeInfoAction({
        queryParams: this._getQueryParams(),
        paymentInstructionTypeId: oneTimePaymentType ?? '',
      }),
    );

    if (
      this.data.isAnnuityBenefitEntity &&
      oneTimePaymentType !== OneTimePaymentType['Periodic Lumpsum Payment'] &&
      !this.benefitPeriodFormGroup
    ) {
      this._createBenefitPeriodFormGroup();
      this._resetPayableDate();
    } else if (this.benefitPeriodFormGroup && oneTimePaymentType === OneTimePaymentType['Periodic Lumpsum Payment']) {
      this.editForm.removeControl('benefitPeriod');
      this._resetPayableDate();
    }

    this.componentChangeByStatus(oneTimePaymentType);
  }

  private componentChangeByStatus(oneTimePaymentType?: any): void {
    if (oneTimePaymentType === OneTimePaymentType['Correction']) {
      this.editForm.addControl('correctionType', new FormControl());
      this.memberStore.dispatch(
        getOneTimeCorrectionTypeAction({
          paymentInstructionTypeId: oneTimePaymentType ?? '',
          benefitTypeName: this.data.selectedHeaderBenefit?.benefitTypeName ?? '',
        }),
      );
      // Todo: Reset min, max value of Start Date + End Date follow a certain payroll cycle of the current benefit
      this._addSyncValidatorBenefitPeriod();
      this.showBanner();
    } else if (oneTimePaymentType === OneTimePaymentType['Final Payment']) {
      this.editForm.removeControl('correctionType');
      this._removeSyncValidatorBenefitPeriod();
      this.benefitPeriodFormGroup
        ?.get('benefitPeriodStart')
        ?.addAsyncValidators(this._checkBenefitPeriodDate('benefitPeriodStart'));
      this.showBanner();
    } else {
      this.editForm.removeControl('correctionType');
      this._removeSyncValidatorBenefitPeriod();
      this.message = '';
    }

    if (oneTimePaymentType === OneTimePaymentType['Initial Payment']) {
      const queryParams: QueryParamsWithHoldingTax = {
        BenefitSubType: this.data.selectedHeaderBenefit?.benefitTypeOptionId ?? '',
        PayeeEntityRecordId: this.data.infoData.payeeEntityRecordId ?? '',
        BenefitTypeName: this.data.selectedHeaderBenefit?.benefitTypeName ?? '',
        PaymentInstructionType: OneTimePaymentType['Initial Payment'],
        benefitCode: this.data.selectedHeaderBenefit?.benefitId ?? ''
      };
      this.memberStore.dispatch(checkDisplayWithHoldingTaxAction({ queryParams }));
      this.isEditableStartDate = false;
      this.isEditableEndDate = false;
      if (this.fundingSourceFormArray) {
        this.editForm.removeControl('fundingSource');
      }
    } else if (oneTimePaymentType === OneTimePaymentType['Final Payment']) {
      // Start Date is editable, End Date is uneditable
      this.isEditableStartDate = true;
      this.isEditableEndDate = false;
      // Hide Funding Source section
      if (this.fundingSourceFormArray) {
        this.editForm.removeControl('fundingSource');
      }
      // Set Withholding Taxes control to TRUE & hide in the UI
      !this.editForm.get('withholdTaxes')
        ? this.editForm.addControl('withholdTaxes', new FormControl(true))
        : this.editForm.get('withholdTaxes')?.setValue(true);
    } else {
      this.isEditableStartDate = true;
      this.isEditableEndDate = true;
      if (!this.fundingSourceFormArray) {
        this._createFundingSourceFormArray();
      }
      if (this.editForm.get('withholdTaxes')) {
        this.editForm.removeControl('withholdTaxes');
      }
    }

    if (this.deductionFormGroup && oneTimePaymentType !== OneTimePaymentType['Correction']) {
      this.editForm.removeControl('deduction');
      this.listChip = [];
      this.listDeductionType?.forEach((el) => {
        el.isHide = false;
      });
    }
    this.editForm.updateValueAndValidity();
  }

  private _checkBenefitPeriodDate(filedName: string) {
    return checkApiValidator(
      this.addOneTimeService.checkValidDate,
      filedName,
      undefined,
      {
        params: {
          queryParams: {
            benefitTypeOptionId: this.data.selectedHeaderBenefit?.benefitTypeOptionId,
            payrollBenefitCode: this.data.selectedHeaderBenefit?.benefitId,
          },
          paymentInstructionTypeId: this.editForm.get('oneTimePaymentType')?.value,
          screenId: this.screenId,
        },
      },
      (result?: any) => {
        this.editForm.updateValueAndValidity();
        switch (this.editForm.get('oneTimePaymentType')?.value) {
          case OneTimePaymentType['Initial Payment']:
            this.benefitPeriodFormGroup.get(filedName)?.setErrors(null);
            this.benefitPeriodFormGroup.get(filedName)?.markAsTouched();
            break;
          case OneTimePaymentType.Correction:
            {
              let errorMessage = '';
              if (filedName === 'benefitPeriodStart') {
                errorMessage =
                  result?.exists === false
                    ? 'Start Date must match start date of a payroll cycle.'
                    : result?.isValid === false
                      ? `Start date must be after the Payee's benefit begin date for ${this.data.selectedHeaderBenefit?.benefitName}.`
                      : '';
                this.errorAsyncStartDate = errorMessage;
              } else if (filedName === 'benefitPeriodEnd') {
                errorMessage =
                  result?.exists === false
                    ? 'End Date must match end date of a payroll cycle.'
                    : result?.isValid === false
                      ? this.benefitPeriodFormGroup.get('benefitPeriodEnd')?.errors?.message
                      : '';
                this.errorAsyncEndDate = errorMessage;
              }
              this.benefitPeriodFormGroup
                .get(filedName)
                ?.setErrors(result?.exists === false || result?.isValid === false ? { inValidAsync: true } : null);
            }
            break;
          case OneTimePaymentType['Final Payment']:
            {
              let errorMessage = '';
              if (filedName === 'benefitPeriodStart') {
                errorMessage =
                  result?.exists === false
                    ? 'Start Date must match start date of a payroll cycle.'
                    : result?.isValid === false
                      ? 'Start Date is invalid.'
                      : '';
                this.errorAsyncStartDate = errorMessage;
                this.errorAsyncEndDate = '';
                this.benefitPeriodFormGroup
                  .get(filedName)
                  ?.setErrors(result?.exists === false || result?.isValid === false ? { inValidAsync: true } : null);
              }
            }
            break;
          default:
            this.errorAsyncStartDate = '';
            this.errorAsyncEndDate = '';
            this.benefitPeriodFormGroup.get(filedName)?.setErrors(null);
            break;
        }
      },
    );
  }

  private _addSyncValidatorBenefitPeriod() {
    this.benefitPeriodFormGroup
      ?.get('benefitPeriodStart')
      ?.addAsyncValidators(this._checkBenefitPeriodDate('benefitPeriodStart'));
    this.benefitPeriodFormGroup
      ?.get('benefitPeriodEnd')
      ?.addAsyncValidators(this._checkBenefitPeriodDate('benefitPeriodEnd'));
    this.editForm.updateValueAndValidity();
  }

  private _removeSyncValidatorBenefitPeriod() {
    this.benefitPeriodFormGroup?.get('benefitPeriodStart')?.clearAsyncValidators();
    this.benefitPeriodFormGroup?.get('benefitPeriodEnd')?.clearAsyncValidators();
    this.editForm.updateValueAndValidity();
  }

  onFederalValueChange(event: string) {}

  onChangeCorrectionTypeValue() {
    // Todo: Remove Funding Source
    let correctionType = this.listCorrectionType.find((el) => el.value === this.editForm.get('correctionType')?.value);
    if (
      this.fundingSourceFormArray &&
      (correctionType?.value === CorrectionType.PayingSuspendedPeriod ||
        correctionType?.value === CorrectionType.CorrectionUnderpaymentDeduction)
    ) {
      this.editForm.removeControl('fundingSource');
    } else if (
      !this.fundingSourceFormArray &&
      correctionType?.value !== CorrectionType.PayingSuspendedPeriod &&
      correctionType?.value !== CorrectionType.CorrectionUnderpaymentDeduction
    ) {
      this._createFundingSourceFormArray();
    }

    if (correctionType?.value === CorrectionType.CorrectionUnderpaymentDeduction) {
      this.editForm.addControl(
        'deduction',
        new FormGroup({
          deductionType: new FormControl('', { validators: this._requiredChip('Deduction Type') }),
        }),
      );
      if (!this.data.isAnnuityBenefitEntity) {
        this.memberStore.dispatch(
          getDeductionDataAction({
            benefitCode: this.data.selectedHeaderBenefit?.benefitId || '',
            memberId: this.data.memberId,
            queryParams: {
              startDate:
                datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodStart')?.value, 'yyyy-MM-dd') || '',
              endDate:
                datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodEnd')?.value, 'yyyy-MM-dd') || '',
              benefitTypeName: this.data.selectedHeaderBenefit?.benefitTypeName,
            },
          }),
        );
      }
    } else {
      this.editForm.removeControl('deduction');
      this.listChip = [];
      this.listDeductionType?.forEach((el) => {
        el.isHide = false;
      });
    }
  }

  private _createFundingSourceFormGroup() {
    this.listFundingSource?.forEach((el) => {
      el.isHide = false;
    });
    const defaultValue = this.currentListFundingSource?.length === 1 ? this.currentListFundingSource[0].value : '';
    return new FormGroup({
      fundingSource: new FormControl(defaultValue),
      amount: new FormControl(null, { validators: [this._validateAmount()] }),
    });
  }

  private _createFundingSourceFormArray() {
    this.editForm.addControl('fundingSource', new FormArray([this._createFundingSourceFormGroup()]));
    this.setDefaultValueFundingSource();
  }

  addNewRowFunding() {
    this.fundingSourceFormArray.push(this._createFundingSourceFormGroup());
  }

  private _createBenefitPeriodFormGroup() {
    this.editForm.addControl(
      'benefitPeriod',
      new FormGroup({
        benefitPeriodStart: new FormControl(),
        benefitPeriodEnd: new FormControl(),
      }),
    );
    this.benefitPeriodFormGroup?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      if (this.deductionFormGroup) {
        this.editForm.removeControl('deduction');
        this.editForm.addControl(
          'deduction',
          new FormGroup({
            deductionType: new FormControl('', { validators: this._requiredChip('Deduction Type') }),
          }),
        );
        this.listChip = [];
      }
    });
    this.benefitPeriodFormGroup?.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((el) => {
      if (this.benefitPeriodFormGroup.valid) {
        // Call api to get data for Deduction
        this.memberStore.dispatch(
          getDeductionDataAction({
            benefitCode: this.data.selectedHeaderBenefit?.benefitId || '',
            memberId: this.data.memberId,
            queryParams: {
              startDate:
                datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodStart')?.value, 'yyyy-MM-dd') || '',
              endDate:
                datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodEnd')?.value, 'yyyy-MM-dd') || '',
              benefitTypeName: this.data.selectedHeaderBenefit?.benefitTypeName,
            },
          }),
        );
      } else {
        this.listDeductionType = [];
      }
    });
  }

  onRemoveRow(idx: number) {
    const item = this.listFundingSource.find(
      (el) => el.value === this.fundingSourceFormArray?.get(idx.toString())?.get('fundingSource')?.value,
    );
    if (item) {
      item.isHide = false;
    }
    this.fundingSourceFormArray.removeAt(idx);
  }

  onRemoveRowDeduction(idx: number, formArray: FormArray, deductionType: DeductionType) {
    formArray.removeAt(idx);
    if (formArray.controls.length === 0) {
      const index = this.listChip.findIndex((el) => el.value === deductionType);
      this.removeChip(index);
    }
  }

  addNewRowDeduction(formArray: FormArray, deductionType: DeductionType) {
    const control = this._createFormGroupFollowType(deductionType);
    formArray.push(control);
  }

  private _checkAmount(sub: string, arr: Option[]) {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      let valueCheck = control.value?.toString()?.trim();
      const temp: Option | undefined =
        sub === 'federalTax' ? this.listTax[0] : arr.find((item) => item?.value === control.parent?.get(sub)?.value);
      if (!temp) {
        return of(null);
      }

      return timer(300).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.addOneTimeService
              .checkValidDateAmount({
                queryParams: {
                  startDate:
                    datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodStart')?.value, 'yyyy-MM-dd') ??
                    '',
                  endDate:
                    datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodEnd')?.value, 'yyyy-MM-dd') ?? '',
                  benefitCode: this.data.selectedHeaderBenefit?.benefitId || '',
                  BenefitTypeName: this.data.selectedHeaderBenefit?.benefitTypeName || '',
                  ...(temp?.extraData?.existPayrollBenefitDeductionId && {
                    deductionId: temp?.extraData?.existPayrollBenefitDeductionId,
                  }),
                  amount: Number(valueCheck),
                },
                memberId: this.data.memberId,
              })
              .pipe(
                map((response: any) => {
                  if (
                    response &&
                    (response.exists ||
                      response.isExisted ||
                      response.isExists ||
                      response.isExist ||
                      response.isValid === false ||
                      response.currentExists)
                  ) {
                    const startDate =
                      datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodStart')?.value, 'MM/dd/yyyy') ??
                      '';
                    const endDate =
                      datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodEnd')?.value, 'MM/dd/yyyy') ??
                      '';
                    return {
                      inValidAsync: `Entered amount is greater than previously taken amounts of the ${temp.displayValue} in Recurring and One time payments for the benefit period ${startDate} - ${endDate}.`,
                    };
                  }
                  return null;
                }),
              ),
        ),
      );
    };
  }

  private _createFormGroupFollowType(type: DeductionType) {
    switch (type) {
      case DeductionType.Garnishment:
        return new FormGroup({
          collectDeductionsGarnishments: new FormControl(true),
          courtOrder: new FormControl(''),
          deductionPayee: new FormControl(''),
          amount: new FormControl(null, {
            validators: this._validateAmount(),
            asyncValidators: this._checkAmount('courtOrder', this.listCourtOrder),
          }),
          postOrPretax: this.fb.control(TaxSettingType.PostTax)
        });

      case DeductionType.Others:
        return new FormGroup({
          collectDeductionsOthers: new FormControl(true),
          othersDeduction: new FormControl(''),
          amount: new FormControl(null, {
            validators: this._validateAmount(),
            asyncValidators: this._checkAmount('othersDeduction', this.listOthersDeduction),
          }),
        });

      case DeductionType.Insurance:
        return new FormGroup({
          collectDeductionsInsurance: new FormControl(true),
          insuranceDeduction: new FormControl(''),
          amount: new FormControl(null, {
            validators: this._validateAmount(),
            asyncValidators: this._checkAmount('insuranceDeduction', this.listInsuranceDeduction),
          }),
        });

      case DeductionType.Qdro:
        const formGroup = new FormGroup({
          createOffsetDeduction: new FormControl(true),
          courtOrder: new FormControl(''),
          deductionPayee: new FormControl(''),
          amount: new FormControl(null, {
            validators: this._validateAmount(),
            asyncValidators: this._checkAmount('courtOrder', this.listCourtOrderQdro),
          }),
          postOrPretax: this.fb.control(TaxSettingType.PreTax),
        });
        this.addOneTimeService.subscribeQdroForm(formGroup, this.unsubscribe$);
        return formGroup;
      default:
        return new FormGroup({});
    }
  }

  // private _dependentStartDate() {
  //   return (c: AbstractControl) => {
  //     const benefitPeriod = c?.parent?.get('benefitPeriod');
  //     if (!benefitPeriod) {
  //       return null;
  //     }
  //     const start = benefitPeriod.get('benefitPeriodStart')?.value;
  //     return start ? null : { dependentStartDate: `Payable date cannot be before Benefit Period Start Date` };
  //   };
  // }

  private _requiredChip(label: string) {
    return (c: AbstractControl) => {
      if (this.benefitPeriodFormGroup?.invalid && this.data.isAnnuityBenefitEntity) {
        return { requiredChip: `Please enter benefit period first` };
      }
      return this.listChip?.length ? null : { requiredChip: `${label} is required.` };
    };
  }

  private _validateAmount(isTax?: boolean): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value <= 0) {
        return {
          errorAmountMessage: isTax
            ? 'Tax amount must be positive. Otherwise, please remove taxes'
            : 'Amount must be positive',
        };
      }
      return null;
    };
  }

  addType() {
    const deductionType = this.deductionFormGroup?.get('deductionType');
    let selectedItem = this.listDeductionType.find((el, i) => {
      if (el.value === deductionType?.value) {
        return true;
      }
      return false;
    });

    // Add item to listChip, clear selector, remove option out of list
    if (selectedItem) {
      this.listChip.push(selectedItem);
      deductionType?.setValue('');
      selectedItem.isHide = true;
    }

    this.generateControlFollowDeductionType(selectedItem, true);
  }

  removeChip(index: number) {
    const deductionType = this.deductionFormGroup?.get('deductionType');
    let item = this.listChip.splice(index, 1);
    const idx = this.listDeductionType.findIndex((el) => {
      return el.value === item[0].value;
    });
    this.listDeductionType[idx].isHide = false;
    if (this.listChip.length === 0) {
      deductionType?.markAsTouched();
      deductionType?.setErrors({
        requiredChip: `Deduction Type is required.`,
      });
    }

    this.generateControlFollowDeductionType(item[0], false);
  }

  generateControlFollowDeductionType(element: Option | undefined, isAdd: boolean) {
    if (isAdd) {
      switch (element?.value as DeductionType) {
        case DeductionType.Tax:
          this.deductionFormGroup.addControl(
            'tax',
            new FormGroup({
              collectDeductionsFederal: new FormControl(true),
              federalTax: new FormControl(null, {
                validators: this._validateAmount(true),
                asyncValidators: this._checkAmount('federalTax', []),
              }),
              collectDeductionsState: new FormControl(true),
              // stateTax: new FormControl(null, this._validateAmount()),
            }),
          );
          break;

        case DeductionType.Insurance:
          this.deductionFormGroup.addControl(
            'insurance',
            new FormArray([this._createFormGroupFollowType(element?.value)]),
          );
          break;

        case DeductionType.Others:
          this.deductionFormGroup.addControl(
            'others',
            new FormArray([this._createFormGroupFollowType(element?.value)])
          );
          break;

        case DeductionType.Garnishment:
          this.deductionFormGroup.addControl(
            'garnishments',
            new FormArray([this._createFormGroupFollowType(element?.value)]),
          );
          break;

        case DeductionType.Qdro:
          this.deductionFormGroup.addControl(
            CONTROL_NAME_QDRO,
            new FormArray([this._createFormGroupFollowType(element?.value)]),
          );
          break;
      }
    } else {
      switch (element?.value as DeductionType) {
        case DeductionType.Tax:
          this.deductionFormGroup.removeControl('tax');
          break;

        case DeductionType.Insurance:
          this.deductionFormGroup.removeControl('insurance');
          break;

        case DeductionType.Others:
          this.deductionFormGroup.removeControl('others');
          break;

        case DeductionType.Garnishment:
          this.deductionFormGroup.removeControl('garnishments');
          break;

        case DeductionType.Qdro:
          this.deductionFormGroup.removeControl(CONTROL_NAME_QDRO);
          break;
      }
    }
  }

  onChangeInsuranceDeductionValue(event: Option, control: AbstractControl) {
    this.resetAmountValue(control);
  }

  onChangeOthersDeductionValue(event: Option, control: AbstractControl) {
    this.resetAmountValue(control);
  }

  onSubmit() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
        }),
        debounceTime(500),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => status === AbstractControlStatus.VALID),
        switchMap(() => {
          const benefitSubType = this.data.selectedHeaderBenefit?.benefitTypeOptionId ?? '';
          const params = {
            payeeEntityRecordId: this.data.infoData.payeeEntityRecordId,
            paymentInstructionType:
              this.editForm.get('oneTimePaymentType')?.value === OneTimePaymentType.Correction
                ? this.editForm.get('correctionType')?.value
                : this.editForm.get('oneTimePaymentType')?.value,
            startDate: this.benefitPeriodFormGroup?.get('benefitPeriodStart')
              ? datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodStart')?.value, 'yyyy-MM-dd')
              : '',
            endDate: this.benefitPeriodFormGroup?.get('benefitPeriodEnd')
              ? datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodEnd')?.value, 'yyyy-MM-dd')
              : '',
            benefitCode: this.data.selectedHeaderBenefit?.benefitId,
            // Todo: update param paymentInstructionId
          };
          return this.addOneTimeService.checkConditionBeforeSave(benefitSubType, params).pipe(
            filter((res) => {
              if (res && !res.isValid) {
                this.benefitPeriodOverlapErrorMessage = this.getBenefitPeriodOverlapErrorMessage(res.message);
              }
              return res.isValid;
            }),
            take(1),
          );
        }),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        this.saveValue();
      });
  }

  saveValue() {
    const createPayload = () => {
      const fundingSources: FundingSourceDetail[] = this.fundingSourceFormArray?.getRawValue().map((el) => {
        const temp = this.listFundingSource.find((item) => el.fundingSource === item.value);
        return {
          fundingSourceId: temp?.value,
          name: temp?.displayValue,
          amount: el.amount,
          taxable: temp?.extraData?.taxable,
        };
      });
      const oneTimePaymentType = this.editForm.get('oneTimePaymentType')?.value;
      const paymentInstructionType =
        oneTimePaymentType === OneTimePaymentType.Correction
          ? this.editForm.get('correctionType')?.value
          : oneTimePaymentType;

      const body: PayloadOneTime = {
        status: PayStatus.Pending,
        paymentInstructionType,
        beginningOn: this.benefitPeriodFormGroup?.get('benefitPeriodStart')
          ? datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodStart')?.value, 'yyyy-MM-dd')
          : '',
        endingOn: this.benefitPeriodFormGroup?.get('benefitPeriodEnd')
          ? datePipe.transform(this.benefitPeriodFormGroup?.get('benefitPeriodEnd')?.value, 'yyyy-MM-dd')
          : '',
        payableDate: datePipe.transform(this.editForm?.get('payableDate')?.value, 'yyyy-MM-dd'),
        representativePayee: this.editForm.get('representativePayee')?.value
          ? {
              representativePayeeId: this.editForm.get('representativePayeeName')?.value,
              name: this.editForm.get('representativePayeeNamePrint')?.value ?? '',
              paymentAddress: this.editForm.get('paymentAddress')?.value ?? '',
            }
          : null,
        fundingSources,
        isWithHoldTaxes: this.editForm.get('withholdTaxes')?.value,
        reason: this.editForm.get('reason')?.value,
        paymentAddress: this.editForm.get('paymentAddress')?.value ?? '',
        benefitRecordId: this.data.selectedHeaderBenefit?.benefitEntityDataId,
        benefitSubType: this.data.selectedHeaderBenefit?.benefitTypeOptionId,
        benefitEntityId: this.data.selectedHeaderBenefit?.benefitEntityId,
        benefitCode: this.data.selectedHeaderBenefit?.benefitId,
        payeeEntityId: this.data.infoData.payeeEntityId,
        payeeEntityRecordId: this.data.infoData.payeeEntityRecordId,
        estateId: this.editForm.get('payee')?.value,
      };
      if (this.deductionFormGroup) {
        const payrollBenefitDeductions: PayrollBenefitDeduction[] = [];
        this.insuranceFormArray?.getRawValue()?.forEach((el) => {
          const temp = this.listInsuranceDeduction.find((item) => item.value === el.insuranceDeduction);
          payrollBenefitDeductions.push({
            id: temp?.extraData?.payrollBenefitDeductionId,
            amount: el.amount,
            deductionId: temp?.extraData?.payrollDeductionId,
            deductionPayeeId: temp?.extraData?.payeeId,
            deductionType: DeductionType.Insurance,
            deductionSubType: temp?.extraData?.subTypeId,
            isCreatingOffsetDp: el.collectDeductionsInsurance,
          });
        });
        this.othersFormArray?.getRawValue()?.forEach((el) => {
          const temp = this.listOthersDeduction.find((item) => item.value === el.othersDeduction);
          payrollBenefitDeductions.push({
            id: temp?.extraData?.payrollBenefitDeductionId,
            amount: el.amount,
            deductionId: temp?.extraData?.payrollDeductionId,
            deductionPayeeId: temp?.extraData?.payeeId,
            deductionType: DeductionType.Others,
            deductionSubType: temp?.extraData?.subTypeId,
            isCreatingOffsetDp: el.collectDeductionsOthers,
          });
        });
        this.garnishmentsFormArray?.getRawValue()?.forEach((el) => {
          const temp = this.listCourtOrder.find((item) => item.value === el.courtOrder);
          payrollBenefitDeductions.push({
            id: temp?.extraData?.payrollBenefitDeductionId,
            amount: el.amount,
            deductionId: temp?.extraData?.payrollDeductionId,
            caseNumber: temp?.extraData?.caseNumber,
            deductionPayeeId: temp?.extraData?.payeeId,
            deductionType: DeductionType.Garnishment,
            deductionSubType: temp?.extraData?.subTypeId,
            isCreatingOffsetDp: el.collectDeductionsGarnishments,
            taxSettingType: el?.postOrPretax
          });
        });
        //#region #158403 QDRO in OTP
        this.qdroFormArray?.getRawValue()?.forEach(el => {
          const temp = this.listCourtOrderQdro.find((item) => item.value === el.courtOrder);
          payrollBenefitDeductions.push({
            id: temp?.extraData?.payrollBenefitDeductionId,
            amount: el.amount,
            deductionId: temp?.extraData?.payrollDeductionId,
            caseNumber: temp?.extraData?.caseNumber,
            deductionPayeeId: temp?.extraData?.payeeId,
            deductionType: DeductionType.Qdro,
            deductionSubType: temp?.extraData?.subTypeId,
            isCreatingOffsetDp: el.createOffsetDeduction,
            taxSettingType: el?.postOrPretax
          });
        });
        //#endregion
        if (this.taxFormGroup) {
          payrollBenefitDeductions.push({
            id: this.listTax[0]?.extraData?.payrollBenefitDeductionId,
            amount: Number(this.taxFormGroup.get('federalTax')?.value) || 0,
            deductionId: this.listTax[0]?.extraData?.payrollDeductionId,
            deductionPayeeId: this.listTax[0]?.extraData?.payeeId,
            deductionType: DeductionType.Tax,
            deductionSubType: this.listTax[0]?.extraData?.subTypeId,
            isCreatingOffsetDp: this.taxFormGroup.get('collectDeductionsFederal')?.value,
          });
        }
        body.payrollBenefitDeductions = payrollBenefitDeductions;
      }
      this.dialogRef.close(body);
    };

    if (this.editForm.valid) {
      const oneTimePaymentType = this.editForm.get('oneTimePaymentType');
      if (oneTimePaymentType?.value === OneTimePaymentType['Initial Payment']) {
        this.memberStore.dispatch(
          getBenefitTimeInfoAction({
            queryParams: this._getQueryParams(),
            paymentInstructionTypeId: oneTimePaymentType?.value ?? '',
          }),
        );
        const sub = this.memberStore.select(benefitTimeInfoSelector).subscribe((el) => {
          if (el.payload) {
            if (el.payload?.exist) {
              this.benefitPeriodOverlapErrorMessage = this.getBenefitPeriodOverlapErrorMessage();
            } else {
              createPayload();
            }
            sub.unsubscribe();
          }
        });
      } else {
        createPayload();
      }
    }
  }

  onCancel() {
    showCancelDialog(this.dialog, this.dialogRef);
  }

  closeBanner() {
    this.message = '';
  }

  checkConditionToShowAddButton(type: DeductionType | 'FundingSource') {
    switch (type) {
      case DeductionType.Insurance:
        return this.insuranceFormArray.length < this.listInsuranceDeduction.length;

      case DeductionType.Others:
        return this.othersFormArray.length < this.listOthersDeduction.length;

      case DeductionType.Garnishment:
        return this.garnishmentsFormArray.length < this.listCourtOrder.length;

      case 'FundingSource':
        return this.fundingSourceFormArray.length < this.listFundingSource.length;

      case DeductionType.Qdro:
        const lastQdro = this.qdroFormArray?.get((this.qdroFormArray.length - 1)?.toString());
        return this.qdroFormArray.length < this.listCourtOrderQdro.length && lastQdro?.valid;

      default:
        return false;
    }
  }

  _createListData(value: string, formArray: FormArray, arrayList: Option[], field: string, subType?: 'qdro') {
    const arr = formArray?.getRawValue()?.map((el) => el[field]);
    if (subType === 'qdro') {
      arrayList = arrayList.slice().sort((a, b) => a.displayValue.localeCompare(b.displayValue ?? '') ?? 0);
    }
    return arrayList
      .filter((el) => {
        if ((value?.toString() && el.value === value) || !arr.includes(el.value)) {
          return true;
        } else {
          return false;
        }
      });
  }

  onChangeFundingSource() {
    const arr = this.fundingSourceFormArray?.getRawValue()?.map((el) => el['fundingSource']);
    this.currentListFundingSource = this.listFundingSource.filter((el) => !arr.includes(el.value));
  }

  onChangeGarnishmentValue(event: Option, control: AbstractControl) {
    control.get('deductionPayee')?.setValue(event?.extraData?.payee || '');
    this.resetAmountValue(control);
  }

  resetAmountValue(control: AbstractControl) {
    const temp = control.get('amount')?.value;
    control.get('amount')?.setValue('');
    control.get('amount')?.setValue(temp);
  }

  private getBenefitPeriodOverlapErrorMessage(message = ''): string {
    return message || 'The suspended period is already covered by a Recurring or One time payment. Please check again.';
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.memberStore.dispatch(clearDeductionDataStateAction());
  }
}
