<ptg-dialog class="edit-deduction-funding-source flex flex-col" [title]="dialogTitle" [isFullScreen]="true">
  <!-- DIALOG CONTENT HEADER -->
  <ng-template dialogContentHeader>
    <ptg-payment-header
      [targetIdFromDialog]="data?.earningInfo?.memberId ?? ''"
      [isShowInteractionComponents]="false"
    ></ptg-payment-header>
  </ng-template>
  <!-- DIALOG CONTENT BODY -->
  <ng-template dialogBody>
    <div class="" *ngIf="!isLoading; else loadingBlock">
      <div class="flex flex-col gap-6">
        <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>

        <div class="action-buttons flex gap-2">
          <ptg-button classInput="primary" buttonName="Save" (clickButton)="onSave()"></ptg-button>
          <ptg-button classInput="tertiary" buttonName="Cancel" (clickButton)="onCancel()"></ptg-button>
        </div>

        <div class="flex flex-col gap-[14px]">
          <div class="calculated-amounts flex gap-6">
            <div class="min-w-[140px]">
              <div class="text-sm leading-6 emphasis-medium">Gross Payment</div>
              <div class="text-base emphasis-high">{{ amount.grossPayment | numberLocalDecimal: '$' }}</div>
            </div>
            <div class="min-w-[140px]">
              <div class="text-sm leading-6 emphasis-medium">Total Deductions</div>
              <div class="text-base emphasis-high">{{ amount.totalDeductions | numberLocalDecimal: '$' }}</div>
            </div>
            <div class="min-w-[140px]">
              <div class="text-sm leading-6 emphasis-medium">Net Payment</div>
              <div class="text-base emphasis-high">{{ amount.netPayment | numberLocalDecimal: '$' }}</div>
            </div>
          </div>

          <div class="text-xl font-bold emphasis-high">Deduction as Funding Source</div>
          <div class="deduction" *ngIf="deductionFormGroup">
            <div class="flex">
              <ptg-select
                placeholder="Deduction Type"
                [controlField]="deductionFormGroup?.get('deductionType')"
                [listData]="listDeductionType"
                [isOptionObj]="true"
                customError="requiredChip"
              ></ptg-select>
              <button mat-raised-button type="button" class="add-btn" type="button" (click)="addType()">
                <mat-icon>add</mat-icon>
              </button>
              <div class="chip-item-container">
                <span class="chip" *ngFor="let item of listChip; let i = index">
                  <span>{{ item.displayValue }}</span>
                  <button mat-button type="button" class="x-btn" (click)="removeChip(i)">✕</button>
                </span>
              </div>
            </div>
            <div class="deduction-tax" *ngIf="taxFormGroup">
              <h3 class="deduction-label">Taxes</h3>
              <ptg-toggle-button
                [controlField]="taxFormGroup?.get('collectDeductionsFederal')"
                [label]="CREATE_OFFSET_DEDUCTION_LABEL"
              ></ptg-toggle-button>
              <ptg-textbox
                [controlField]="taxFormGroup?.get('federalTax')"
                [hasLabel]="true"
                placeholder="Federal Tax"
                [max]="MAX_VALUE_NUMBER"
                [isPositive]="true"
                errorRequire="Federal Tax is required."
                customError="errorAmountMessage"
                [inputType]="InputType.TYPE_CURRENCY"
                (valueChange)="onFederalValueChange($event)"
              ></ptg-textbox>
              <!-- <ptg-toggle-button
                [controlField]="taxFormGroup?.get('collectDeductionsState')"
                label="Collect Deductions from DP "
              ></ptg-toggle-button>
              <ptg-textbox
                [controlField]="taxFormGroup?.get('stateTax')"
                [hasLabel]="true"
                placeholder="State Tax"
                [max]="MAX_VALUE_NUMBER"
                [isPositive]="true"
                errorRequire="State Tax is required."
                [inputType]="InputType.TYPE_CURRENCY"
                customError="errorAmountMessage"
              ></ptg-textbox> -->
            </div>
            <div class="deduction-insurance" *ngIf="insuranceFormArray">
              <h3 class="deduction-label">Insurances</h3>
              <div class="container-insurance" *ngFor="let control of insuranceFormArray?.controls; let i = index">
                <ptg-toggle-button
                  [controlField]="control?.get('collectDeductionsFromDP')"
                  [label]="CREATE_OFFSET_DEDUCTION_LABEL"
                ></ptg-toggle-button>
                <div class="row-control">
                  <ptg-select
                    [placeholder]="'Insurance ' + (i + 1)"
                    class="input-field"
                    [controlField]="control.get('deductionSubTypeId')"
                    [listData]="_createListData(control.get('deductionSubTypeId')?.value, insuranceFormArray, listInsuranceDeduction, 'deductionSubTypeId')"
                    [isOptionObj]="true"
                    errorRequire="Insurance Deduction is required."
                    [isSetMaxWidthSelect]="true"
                    (changeOptionValue)="onChangeInsuranceDeductionValue($event, control)"
                  ></ptg-select>
                  <ptg-textbox
                    [controlField]="control.get('amount')"
                    [hasLabel]="true"
                    placeholder="Amount"
                    [max]="MAX_VALUE_NUMBER"
                    customError="errorAmountMessage"
                    [isPositive]="true"
                    errorRequire="Amount is required."
                    [inputType]="InputType.TYPE_CURRENCY"
                  ></ptg-textbox>
                  <button mat-button type="button" class="remove-button" (click)="onRemoveRowDeduction(i, insuranceFormArray, DeductionType.Insurance)">
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                </div>
              </div>
              <ptg-button
                buttonName="New Insurance"
                iconName="add"
                classInput="secondary add-button"
                (clickButton)="addNewRowDeduction(insuranceFormArray, DeductionType.Insurance)"
                *ngIf="checkConditionToShowAddButton(DeductionType.Insurance)"
              ></ptg-button>
            </div>
            <div class="deduction-insurance" *ngIf="othersFormArray">
              <h3 class="deduction-label">Others</h3>
              <div class="container-insurance" *ngFor="let control of othersFormArray?.controls; let i = index">
                <ptg-toggle-button
                  [controlField]="control?.get('collectDeductionsFromDP')"
                  [label]="CREATE_OFFSET_DEDUCTION_LABEL"
                ></ptg-toggle-button>
                <div class="row-control">
                  <ptg-select
                    [placeholder]="'Others ' + (i + 1)"
                    class="input-field"
                    [controlField]="control.get('deductionSubTypeId')"
                    [listData]="_createListData(control.get('deductionSubTypeId')?.value, othersFormArray, listOthersDeduction, 'deductionSubTypeId')"
                    [isOptionObj]="true"
                    errorRequire="Others Deduction is required."
                    [isSetMaxWidthSelect]="true"
                    (changeOptionValue)="onChangeOthersDeductionValue($event, control)"
                  ></ptg-select>
                  <ptg-textbox
                    [controlField]="control.get('amount')"
                    [hasLabel]="true"
                    placeholder="Amount"
                    [max]="MAX_VALUE_NUMBER"
                    customError="errorAmountMessage"
                    [isPositive]="true"
                    errorRequire="Amount is required."
                    [inputType]="InputType.TYPE_CURRENCY"
                  ></ptg-textbox>
                  <button mat-button type="button" class="remove-button" (click)="onRemoveRowDeduction(i, othersFormArray, DeductionType.Others)">
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                </div>
              </div>
              <ptg-button
                buttonName="New Others"
                iconName="add"
                classInput="secondary add-button"
                (clickButton)="addNewRowDeduction(othersFormArray, DeductionType.Others)"
                *ngIf="checkConditionToShowAddButton(DeductionType.Others)"
              ></ptg-button>
            </div>
            <div class="deduction-insurance" *ngIf="garnishmentsFormArray">
              <h3 class="deduction-label">Garnishments</h3>
              <div class="container-insurance" *ngFor="let control of garnishmentsFormArray?.controls; let i = index">
                <ptg-toggle-button
                  [controlField]="control?.get('collectDeductionsFromDP')"
                  [label]="CREATE_OFFSET_DEDUCTION_LABEL"
                ></ptg-toggle-button>
                <div class="row-control garnishments">
                  <ptg-select
                    placeholder="Court Order"
                    class="input-field"
                    [controlField]="control.get('courtOrder')"
                    [listData]="_createListData(control.get('courtOrder')?.value, garnishmentsFormArray, listCourtOrder, 'courtOrder')"
                    [isOptionObj]="true"
                    errorRequire="Court Order is required."
                    [isSetMaxWidthSelect]="true"
                    (changeOptionValue)="onChangeGarnishmentValue($event, control)"
                  ></ptg-select>
                  <!-- <ptg-select
                    placeholder="Deduction Payee"
                    class="input-field"
                    [controlField]="control.get('deductionPayee')"
                    [listData]="listDeductionPayee"
                    [isOptionObj]="true"
                    errorRequire="Deduction Payee is required."
                    [isSetMaxWidthSelect]="true"
                  ></ptg-select> -->
                  <div class="min-w-[252px]">
                    <div class="text-sm leading-6 emphasis-medium">Deduction Payee</div>
                    <div class="text-base emphasis-high">
                      {{control.get('deductionPayee')?.value}}
                    </div>
                  </div>
                  <ptg-textbox
                    [controlField]="control.get('amount')"
                    [hasLabel]="true"
                    placeholder="Amount"
                    [max]="MAX_VALUE_NUMBER"
                    customError="errorAmountMessage"
                    [isPositive]="true"
                    errorRequire="Amount is required."
                    [inputType]="InputType.TYPE_CURRENCY"
                  ></ptg-textbox>
                  <button
                    mat-button type="button" class="remove-button"
                    (click)="onRemoveRowDeduction(i, garnishmentsFormArray, DeductionType.Garnishment)"
                  >
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                </div>
                <div class="row-control garnishments">
                  <ptg-radio-button
                    [classInput]="'flex items-center justify-center'"
                    [isObject]="true"
                    [listOption]="postOrPretaxOptions"
                    [controlField]="control.get('postOrPretax')"
                  ></ptg-radio-button>
                </div>
              </div>
              <ptg-button
                buttonName="New Garnishment"
                iconName="add"
                classInput="secondary add-button"
                (clickButton)="addNewRowDeduction(garnishmentsFormArray, DeductionType.Garnishment)"
                *ngIf="checkConditionToShowAddButton(DeductionType.Garnishment)"
              ></ptg-button>
            </div>
            <div class="deduction-insurance qdro" *ngIf="qdroFormArray">
              <h3 class="deduction-label">{{DeductionLabels[DeductionType.Qdro]}}</h3>
              <div class="container-insurance" *ngFor="let control of qdroFormArray.controls; index as i">
                <ptg-toggle-button
                  [controlField]="control?.get('collectDeductionsFromDP')"
                  [label]="CREATE_OFFSET_DEDUCTION_LABEL"
                ></ptg-toggle-button>
                <div class="row-control qdro">
                  <ptg-select
                    placeholder="Court Order"
                    class="input-field"
                    [controlField]="control.get('courtOrder')"
                    [listData]="
                      _createListData(control.get('courtOrder')?.value, qdroFormArray, listCourtOrderQdro, 'courtOrder', 'qdro')
                    "
                    [isOptionObj]="true"
                    errorRequire="Court Order is required."
                    [isSetMaxWidthSelect]="true"
                    (changeOptionValue)="onChangeGarnishmentValue($event, control)"
                  ></ptg-select>
                  <div class="min-w-[252px]">
                    <div class="text-sm leading-6 emphasis-medium">Alternate Payee</div>
                    <div class="text-base emphasis-high">
                      {{ control.get('deductionPayee')?.value }}
                    </div>
                  </div>
                  <ptg-textbox
                    [controlField]="control.get('amount')"
                    [hasLabel]="true"
                    placeholder="Amount"
                    [max]="MAX_VALUE_NUMBER"
                    [isPositive]="true"
                    errorRequire="Amount is required."
                    [inputType]="InputType.TYPE_CURRENCY"
                    customError="errorAmountMessage"
                  ></ptg-textbox>
                  <button
                    mat-button
                    type="button"
                    class="remove-button"
                    (click)="onRemoveRowDeduction(i, qdroFormArray, DeductionType.Qdro)"
                  >
                    <mat-icon>delete_forever</mat-icon>
                  </button>
                </div>
                <div class="row-control garnishments">
                  <ptg-radio-button
                    [classInput]="'flex items-center justify-center'"
                    [isObject]="true"
                    [listOption]="postOrPretaxOptions"
                    [controlField]="control.get('postOrPretax')"
                  ></ptg-radio-button>
                </div>
              </div>
              <ptg-button
                [buttonName]="'New ' + DeductionLabels[DeductionType.Qdro]"
                iconName="add"
                classInput="secondary add-button"
                *ngIf="checkConditionToShowAddButton(DeductionType.Qdro)"
                (clickButton)="addNewRowDeduction(qdroFormArray, DeductionType.Qdro)"
              ></ptg-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ptg-dialog>

<ng-template #loadingBlock>
  <div class="spinner-wrapper" [class.active]="isLoading">
    <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
  </div>
</ng-template>
