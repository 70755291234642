import { Component, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { SwitchConfirmPopupService } from '@ptg-shared/services/switch-confirm-popup.service';
import { benefitTimeInfoSelector, editBenefitPeriodAction, editPayableDateAction, getBenefitTimeInfoAction, checkPayableDateAction, checkPayableDateSelector, clearCheckPayableDate } from '../../store';
import { DatePipe } from '@angular/common';
import { QueryParamsBenefit } from '../../services/models';
import { checkApiValidator } from '@ptg-shared/validators/checkApi.validator';
import { AddOneTimeService } from '../../services';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { tap, debounceTime, switchMap, startWith, take, filter, takeUntil } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { BaseComponent } from '@ptg-shared/components';
import { CorrectionType } from '@ptg-member/constance/paymentType.const';
import { PaymentInstructionType } from '../../types/enums';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { DATE_FORMAT } from '@ptg-shared/constance';
import { BenefitPeriodPayload, PayableDatePayload } from '../../types/models';
import { DateTime } from 'luxon';

const datePipe = new DatePipe('en-US');

@Component({
  selector: 'ptg-edit-payable-date',
  templateUrl: './edit-payable-date.component.html',
  styleUrls: ['./edit-payable-date.component.scss']
})
export class EditPayableDateComponent extends BaseComponent {
  readonly PaymentInstructionType = PaymentInstructionType;

  payableDate = new FormControl();
  benefitPeriod = new FormGroup({
    startDate: new FormControl(),
    endDate: new FormControl()
  })
  
  maxDate = new Date();
  minDate = new Date();
  formSubmit$ = new Subject<boolean>();
  benefitPeriodOverlap = false;
  errorStartAsync = '';
  errorEndAsync = '';

  constructor(
    private store: Store,
    private switchConfirmPopupService: SwitchConfirmPopupService,
    public dialogRef: MatDialogRef<EditPayableDateComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      payableDate?: string;
      paymentInstructionId: string;
      isEditPayableDate: boolean;
      isPaymentSuspend?: boolean;
      benefitPeriod?: {
        startDate?: string;
        endDate?: string;
        benefitEntityDataId: string;
        benefitTypeOptionId: string;
        payeeEntityRecordId: string;
        benefitTypeName: string;
        benefitCode: string;
        paymentType: PaymentInstructionType;
        benefitName: string;
      };
    },
    private addOneTimeService: AddOneTimeService,
    private dialog: MatDialog
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    if (this.data.isEditPayableDate) {
      this.payableDate.addValidators([this._checkPayableDate()]);

      this.payableDate.setValue(new Date(this.data.payableDate ?? ''));
      this.benefitPeriod.get('benefitCode')?.setValue(this.data.benefitPeriod?.benefitCode);

      // 151197: AC5
      this.store.select(checkPayableDateSelector).pipe(takeUntil(this.unsubscribe$)).subscribe(el => {
        if (el.payload) {
          const body: PayableDatePayload = {
            paymentInstructionId: this.data.paymentInstructionId ?? '',
            payableDate: datePipe.transform(this.payableDate.value, 'yyyy-MM-dd') ?? '',
          };
          this._confirmationPopup({
            isPopup: el.payload.isValid === false, 
            text: el.payload.message,
            onClickYes: () => {
              // Click Yes on confirm update initial payment's payable date
              body.isUpdatePaymentPeriodAndAmount = true;
            },
            onClickNo: () => {
              // Click No on confirm update initial payment's payable date
              body.isUpdatePaymentPeriodAndAmount = false;
            },
            onNoPopup: () => {
              // Popup is not needed
            },
            finalize: () => {
              this.store.dispatch(editPayableDateAction({body}));
              this.dialogRef.close();
            }
          });
          // After showing popup, no need to check if popup should be shown
          this.store.dispatch(clearCheckPayableDate());
        }
      });
    } else {
      this.benefitPeriod.get('startDate')?.addAsyncValidators([this._checkBenefitPeriodDate('benefitPeriodStart')]);
      this.benefitPeriod.get('endDate')?.addAsyncValidators([this._checkBenefitPeriodDate('benefitPeriodEnd')]);
      
      this.benefitPeriod.get('startDate')?.setValue(this.data.benefitPeriod?.startDate ? new Date(this.data.benefitPeriod?.startDate) : null);
      this.benefitPeriod.get('endDate')?.setValue(this.data.benefitPeriod?.endDate ? new Date(this.data.benefitPeriod?.endDate) : null);
      this.store.dispatch(getBenefitTimeInfoAction({queryParams: this._getQueryParams(), paymentInstructionTypeId: this.data.benefitPeriod?.paymentType + '' ?? ''}));
      this.store.select(benefitTimeInfoSelector).subscribe(el => {
        if (el.payload) {
          this.minDate = new Date(el.payload.startDate);
        }
      });
    }
    this.benefitPeriod.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(el => {
      this.benefitPeriodOverlap = false;
    });
    this.onSubmit();
  }

  private _getQueryParams(): QueryParamsBenefit {
    return {
      BenefitEntityDataId: this.data.benefitPeriod?.benefitEntityDataId ?? '',
      BenefitTypeOptionId: this.data.benefitPeriod?.benefitTypeOptionId ?? '',
      PayeeEntityRecordId: this.data.benefitPeriod?.payeeEntityRecordId ?? '',
      BenefitTypeName: this.data.benefitPeriod?.benefitTypeName ?? '',
      BenefitCode: this.data.benefitPeriod?.benefitCode ?? '',
    }
  }

  // 151197: AC5: Validate payable date
  private _checkPayableDate(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors|null => {
      if (!control.value) {
        return null; // Required is checked somewhere else
      }
      const payableDate = DateTime.fromJSDate(new Date(control.value));
      const benefitPeriodStartDate = DateTime.fromISO(this.data.benefitPeriod?.startDate ?? '');
      if (payableDate < benefitPeriodStartDate) {
        return { inValidAsync: true };
      }
      return null;
    }
  }

  private _checkBenefitPeriodDate(filedName: string) {
    return checkApiValidator(
      this.addOneTimeService.checkValidDate,
      filedName,
      undefined,
      {
        params: {
          queryParams: {
            benefitTypeOptionId: this.data.benefitPeriod?.benefitTypeOptionId,
            payrollBenefitCode: this.data.benefitPeriod?.benefitCode
          },
          paymentInstructionTypeId: this.data.benefitPeriod?.paymentType,
        },
      },
      (result) => {
        if (filedName === 'benefitPeriodStart') {
          this.errorStartAsync =
            result?.existedPeriod === false
              ? 'Start Date must match start date of a payroll cycle.'
              : result?.isValid === false
                ? `Start date must be after the Payee's benefit begin date for ${this.data?.benefitPeriod?.benefitName}.`
                : '';
        } else if (filedName === 'benefitPeriodEnd') {
          this.errorEndAsync =
            result?.existedPeriod === false
              ? 'End Date must match end date of a payroll cycle.'
              : result?.isValid === false
                ? result?.message
                : '';
        }
        this.benefitPeriod.updateValueAndValidity();
      }
    )
  }

  private _confirmationPopup({finalize = () => {}, ...options}: {isPopup: boolean, text: string, onClickYes: () => void, onClickNo: () => void, onNoPopup: () => void, finalize?: () => void}) {
    if (!options.isPopup) {
      options.onNoPopup();
      finalize();
      return;
    }
    return this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        type: ConfirmType.Attention,
        title: 'Confirmation',
        cancelButtonTitle: 'No',
        text: options.text,
        resultWhenClickNo: false
      }
    }).afterClosed().subscribe(isClickYes => {
      if (isClickYes) {
        options.onClickYes();
        finalize();
      } else if (isClickYes === false) {
        options.onClickNo();
        finalize();
      }
      // Do nothing if cancel popup
    })
  }

  onSubmit() {
    this.formSubmit$
      .pipe(
        tap(() => {
          if (this.data.isEditPayableDate) {
            this.payableDate.markAllAsTouched();
          } else {
            this.benefitPeriod.markAllAsTouched();
          }
        }),
        debounceTime(500),
        switchMap(() => {
          if (this.data.isEditPayableDate) {
            return this.payableDate.statusChanges.pipe(
              startWith(this.payableDate.status),
              filter(status => status !== AbstractControlStatus.PENDING),
              take(1)
            );
          }
          return this.benefitPeriod.statusChanges.pipe(
            startWith(this.benefitPeriod.status),
            filter(status => status !== AbstractControlStatus.PENDING),
            take(1)
          );
        }),
        filter(status => status === AbstractControlStatus.VALID),
        switchMap(() => {
          if (this.data.isPaymentSuspend && !this.data.isEditPayableDate) {
            const benefitSubType = this.data.benefitPeriod?.benefitTypeOptionId ?? '';
            const params = {
              payeeEntityRecordId: this.data.benefitPeriod?.payeeEntityRecordId,
              paymentInstructionType: CorrectionType.PayingSuspendedPeriod,
              paymentInstructionId: this.data?.paymentInstructionId,
              startDate: this.benefitPeriod?.get('startDate') ? datePipe.transform(this.benefitPeriod?.get('startDate')?.value, 'yyyy-MM-dd') : '',
              endDate: this.benefitPeriod?.get('endDate') ? datePipe.transform(this.benefitPeriod?.get('endDate')?.value, 'yyyy-MM-dd') : '',
              benefitCode: this.data.benefitPeriod?.benefitCode
            };
            return this.addOneTimeService.checkConditionBeforeSave(benefitSubType, params).pipe(
              filter(res => {
                if (res && !res.isValid) {
                  this.benefitPeriodOverlap = true;
                }
                return res.isValid;
              }),
              take(1),
            )
          } else {
            return of(true);
          }
        })
      )
      .subscribe(() => {
        this.saveValue();
      });
  }

  saveValue() {
    const isInitialPayment = this.data.benefitPeriod?.paymentType === PaymentInstructionType.InitialPayment;

    if (this.data.isEditPayableDate) {
      // 151197: AC5
      if (isInitialPayment) {
        this.store.dispatch(checkPayableDateAction({
          paymentInstructionId: this.data.paymentInstructionId,
          payableDate: datePipe.transform(this.payableDate.value, 'yyyy-MM-dd') ?? ''
        }));
      } else {
        const body: PayableDatePayload = {
          paymentInstructionId: this.data.paymentInstructionId ?? '',
          payableDate: datePipe.transform(this.payableDate.value, 'yyyy-MM-dd') ?? '',
        };
        this.store.dispatch(editPayableDateAction({body}));
        this.dialogRef.close();
      }
    } else {
      const startDate = this.benefitPeriod.get('startDate')?.value;
      const endDate = this.benefitPeriod.get('endDate')?.value;
      const body: BenefitPeriodPayload = {
        paymentInstructionId: this.data.paymentInstructionId ?? '',
        startDate: datePipe.transform(startDate, 'yyyy-MM-dd') ?? '',
        endDate: datePipe.transform(endDate, 'yyyy-MM-dd') ?? ''
      };

      // 151197: AC4
      this._confirmationPopup({
        isPopup: isInitialPayment, 
        text: `You are updating the Initial payment's benefit period to ${
          datePipe.transform(startDate, DATE_FORMAT) ?? ''
        } - ${
          datePipe.transform(endDate, DATE_FORMAT) ?? ''
        }. Recurring payment will also be updated to the following period if it had not been paid. Do you want to recalculate payable date and the Initial amount according to the new period?`,
        onClickYes: () => {
          // Click Yes on confirm update initial payment's benefit period
          body.isRecalcRelatedInfo = true;
        },
        onClickNo: () => {
          // Click No on confirm update initial payment's benefit period
          body.isRecalcRelatedInfo = false;
        },
        onNoPopup: () => {
          // Not initial payment
        },
        finalize: () => {
          this.store.dispatch(editBenefitPeriodAction({body}));
          this.dialogRef.close();
        }
      });
    }
  }

  onCancel(): void {
    this.switchConfirmPopupService.cancelConfirm(this.dialogRef);
  }
}
