export enum BannerType {
  Success = 'Success',
  Fail = 'Fail',
  Warning = 'Warning',
  Info = 'Info',
  Hidden = '',
  Error = 'Error',
  Confirm = 'Confirmation'
}

export interface BannerIconConfig {
  icon: string;
  className?: string;
}
