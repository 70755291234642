import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AddOneTimeService } from '../../services';
import { AddressData, BenefitTimeInfo, FundingSourceElement, GetDeductionFundingSourcesResponse, PayeeElementDetail, PayeeResponse, PaymentAddress, PaymentType } from '../../services/models';
import { checkDisplayWithHoldingTaxAction, checkDisplayWithHoldingTaxFailureAction, checkDisplayWithHoldingTaxSuccessAction, createOneTimePaymentAction, createOneTimePaymentFailureAction, createOneTimePaymentSuccessAction, getBenefitTimeInfoAction, getBenefitTimeInfoFailureAction, getBenefitTimeInfoSuccessAction, getDeductionDataAction, getDeductionDataFailureAction, getDeductionDataSuccessAction, getFundingSourceAction, getFundingSourceFailureAction, getFundingSourceSuccessAction, getOneTimeCorrectionTypeAction, getOneTimeCorrectionTypeFailureAction, getOneTimeCorrectionTypeSuccessAction, getOneTimePaymentTypeAction, getOneTimePaymentTypeFailureAction, getOneTimePaymentTypeSuccessAction, getPayeeListAction, getPayeeListFailureAction, getPayeeListSuccessAction, getPaymentAddressAction, getPaymentAddressFailureAction, getPaymentAddressSuccessAction, checkPayableDateAction, checkPayableDateFailure, checkPayableDateSuccess } from '../actions';


@Injectable()
export class AddOneTimeEffect {
  constructor(
    private actions$: Actions,
    private addOneTimeService: AddOneTimeService,
  ) {}

  getOneTimePaymentType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOneTimePaymentTypeAction),
      switchMap(({ benefitTypeOptionId, queryParams }) => {
        return this.addOneTimeService.getOneTimePaymentType(benefitTypeOptionId, queryParams).pipe(
          map((response: { paymentInstructionTypeDtos: PaymentType[] }) => {
            return getOneTimePaymentTypeSuccessAction({paymentInstructionTypeDtos: response.paymentInstructionTypeDtos});
          }),
          catchError((error) => {
            return of(getOneTimePaymentTypeFailureAction({ error }));
          })
        );
      })
    )
  );

  getOneTimeCorrectionType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getOneTimeCorrectionTypeAction),
      switchMap(({ paymentInstructionTypeId, benefitTypeName }) => {
        return this.addOneTimeService.getCorrectionType(paymentInstructionTypeId, benefitTypeName).pipe(
          map((response: { paymentInstructionTypeDtos: PaymentType[] }) => {
            return getOneTimeCorrectionTypeSuccessAction({paymentInstructionTypeDtos: response.paymentInstructionTypeDtos});
          }),
          catchError((error) => {
            return of(getOneTimeCorrectionTypeFailureAction({ error }));
          })
        );
      })
    )
  );

  getFundingSource$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getFundingSourceAction),
      switchMap(({ benefitTypeOptionId }) => {
        return this.addOneTimeService.getFundingSource(benefitTypeOptionId).pipe(
          map((response: {fundingSources: FundingSourceElement[]}) => {
            return getFundingSourceSuccessAction({fundingSources: response.fundingSources});
          }),
          catchError((error) => {
            return of(getFundingSourceFailureAction({ error }));
          })
        );
      })
    )
  );

  getPayeeList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPayeeListAction),
      switchMap(({ payeeId, benefitCode }) => {
        return this.addOneTimeService.getPayeeList(payeeId, benefitCode).pipe(
          map((response: PayeeResponse) => {
            return getPayeeListSuccessAction({response});
          }),
          catchError((error) => {
            return of(getPayeeListFailureAction({ error }));
          })
        );
      })
    )
  );

  getBenefitTimeInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getBenefitTimeInfoAction),
      switchMap(({ queryParams, paymentInstructionTypeId }) => {
        return this.addOneTimeService.getBenefitTimeInfo(queryParams, paymentInstructionTypeId).pipe(
          map((response: {oneTimePaymentDatePeriodDto: BenefitTimeInfo}) => {
            return getBenefitTimeInfoSuccessAction({benefitTimeInfo: response.oneTimePaymentDatePeriodDto});
          }),
          catchError((error) => {
            return of(getBenefitTimeInfoFailureAction({ error }));
          })
        );
      })
    )
  );

  getDeductionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getDeductionDataAction),
      switchMap(({ benefitCode, memberId, queryParams }) => {
        return this.addOneTimeService.getDeductionData(benefitCode, memberId, queryParams).pipe(
          map((response: GetDeductionFundingSourcesResponse) => {
            return getDeductionDataSuccessAction({response});
          }),
          catchError((error) => {
            return of(getDeductionDataFailureAction({ error }));
          })
        );
      })
    )
  );

  getPaymentAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPaymentAddressAction),
      switchMap(({ payeeId, isPerson, benefitCode }) => {
        return this.addOneTimeService.getPaymentAddress(payeeId, isPerson, benefitCode ).pipe(
          map((response: {addressData: AddressData[]}) => {
            return getPaymentAddressSuccessAction({addressData: response.addressData});
          }),
          catchError((error) => {
            return of(getPaymentAddressFailureAction({ error }));
          })
        );
      })
    )
  );

  checkDisplayWithHoldingTax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkDisplayWithHoldingTaxAction),
      switchMap(({ queryParams }) => {
        return this.addOneTimeService.checkDisplayWithHoldingTax(queryParams).pipe(
          map((response) => {
            return checkDisplayWithHoldingTaxSuccessAction({isValid: response.isValid});
          }),
          catchError((error) => {
            return of(checkDisplayWithHoldingTaxFailureAction({ error }));
          })
        );
      })
    )
  );

  checkPayableDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkPayableDateAction),
      switchMap((req) => {
        return this.addOneTimeService.checkValidPayableDate(req).pipe(
          map((res) => {
            return checkPayableDateSuccess(res);
          }),
          catchError((err) => {
            return of(checkPayableDateFailure());
          })
        );
      })
    )
  );

  createOneTimePaymentType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createOneTimePaymentAction),
      switchMap(({ body }) => {
        return this.addOneTimeService.createOneTimePayment(body).pipe(
          map((response) => {
            return createOneTimePaymentSuccessAction({ response });
          }),
          catchError((error) => {
            return of(createOneTimePaymentFailureAction({error}));
          })
        );
      })
    )
  );
}
