import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SortType } from '@ptg-shared/constance';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreatePaymentHeaderConfigurationRequest, 
         GetPaymentHeaderConfigurationRequest,
         GetPaymentHeaderDetailResponse,
         HeaderItemItemExistResponse,
         PaymentEntitiesResponse,
         PaymentHeaderConfigResponsive,
         PaymentHeaderConfigurationsResponsive,
         PaymentHeaderDataResponsive,
         QueryPaymentHeaderDataRequest,
         UpdateHeaderDetailRequest
} from '../types/models';


@Injectable({
  providedIn: 'root'
})
export class PaymentHeaderConfigurationService {
  constructor(private httpClient: HttpClient) { }

  getHeaderList(query: GetPaymentHeaderConfigurationRequest): Observable<PaymentHeaderConfigurationsResponsive> {
    let params = new HttpParams();

    if (query.sortField) {
      params = params.append('SortNames', capitalizeFirstLetter(query.sortField));
      params = params.append('SortType', query.sortType ?? SortType.ASC);
    }
    if (query?.pageSize) {
      params = params.append('PageSize', query?.pageSize);
    }
    if (query?.pageIndex) {
      params = params.append('PageIndex', query.pageIndex.toString());
    }
    return this.httpClient.get<PaymentHeaderConfigurationsResponsive>(`${environment.apiUrl}/new-payroll/header-configuration`, { params });
  }

  createHeader(body: CreatePaymentHeaderConfigurationRequest): Observable<string> {
    return this.httpClient.post<string>(`${environment.apiUrl}/new-payroll/payment-header-config`, body);
  }

  checkHeaderExist(body: CreatePaymentHeaderConfigurationRequest): Observable<HeaderItemItemExistResponse> {
    return this.httpClient.post<HeaderItemItemExistResponse>(`${environment.apiUrl}/new-payroll/payment-header-config/exist`, body);
  }

  getPaymentEntities(): Observable<PaymentEntitiesResponse> {
    return this.httpClient.get<PaymentEntitiesResponse>(`${environment.apiUrl}/new-payroll/payment-header-config/entity`);
  }

  getHeaderDetail(id: string): Observable<GetPaymentHeaderDetailResponse> {
    return this.httpClient.get<GetPaymentHeaderDetailResponse>(`${environment.apiUrl}/new-payroll/payment-header-config/${id}`);
  }

  updatePaymentHeader(body: UpdateHeaderDetailRequest): Observable<void> {
    return this.httpClient.put<void>(`${environment.apiUrl}/new-payroll/payment-header-config`, body);
  }

  getPaymentHeaderConfigApply(recordId: string, entityId: string, headerId?: string, is1099R?: boolean): Observable<PaymentHeaderConfigResponsive> {
    let params = new HttpParams();
    if (headerId) {
      params = params.append('headerId', headerId);
    }

    if (is1099R) {
      params = params.append('is1099R', is1099R);
    }
    
    return this.httpClient.get<PaymentHeaderConfigResponsive>(`${environment.apiUrl}/new-payroll/header-config/${recordId}/${entityId}`, { params });
  }

  getPaymentHeaderDataApply(query: QueryPaymentHeaderDataRequest): Observable<PaymentHeaderDataResponsive> {
    let params = new HttpParams();

    if (query?.recordId) {
      params = params.append('recordId', query?.recordId);
    }
    if (query?.benefitDataId) {
      params = params.append('benefitDataId', query.benefitDataId);
    }
    if (query?.entityId) {
      params = params.append('entityId', query.entityId);
    }
    if (query?.benefitId) {
      params = params.append('benefitId', query.benefitId);
    }
    if (query?.headerId) {
      params = params.append('headerId', query.headerId);
    }
    if (query?.screenId) {
      params = params.append('screenId', query.screenId);
    }
    return this.httpClient.get<PaymentHeaderDataResponsive>(`${environment.apiUrl}/new-payroll/header-data`, { params });
  }
}
