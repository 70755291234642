<ptg-dialog
  class="create-entity-list-data-dialog"
  [title]="title"
  [footerAlign]="'left'"
  [isFullScreen]="true"
>
  <ng-template dialogContentHeader>
    <div class="line">
      <ptg-overview-header-entity
        [targetIdFromDialog]="data?.memberId"
      ></ptg-overview-header-entity>
    </div>
  </ng-template>
  <ng-template dialogBody>
    <div class="mb-4">
      <ptg-banner
        *ngIf="bannerType"
        [(bannerType)]="bannerType"
        [message]="message"
      ></ptg-banner>
    </div>
    <div *ngIf="entityProperties; else loadingTemplate">
      <ptg-upsert-entity-data
        [properties]="entityProperties.properties"
        [data]="cardData"
        [entityId]="entityId"
        [listRecordId]="data.listRecordId"
        [cardInfor]="cardInfor"
        [targetId]="data?.memberId"
        [recordId]="data?.recordId"
        (editSectionEvent)="onSubmit($event)"
        (cancelEvent)="onCancel()"
        [isEdit]="!data.isAddRecord"
        [isEditCard]="isEditCard"
        (addNewEvent)="onSubmit($event)"
        [screenId]="data.screenId"
        [isEffectiveData]="true"
        [entityComponentId]="entityProperties.entityComponentId"
      >
      </ptg-upsert-entity-data>
    </div>
    <ng-template #loadingTemplate>
      <ptg-spinner [isLoading]="true"></ptg-spinner>
    </ng-template>
  </ng-template>
</ptg-dialog>
