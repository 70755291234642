import { Component, ContentChild, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';

import { Option } from '@ptg-shared/controls/select/select.component';

import { ChipItemDirective } from './directives';
import { ChipInputType } from './types/enums';

export interface AutocompleteContext {
  searchResult?: Option[];
  typingTime?: number;
  columns?: string[];
  lengthToSearch?: number;
  isSearching?: boolean;
  isSearchError?: boolean;
  icon?: string;
  displayAllResult?: boolean;
}

@Component({
  selector: 'ptg-chip-selection',
  templateUrl: './chip-selection.component.html',
  styleUrls: ['./chip-selection.component.scss'],
})
export class ChipSelectionComponent implements OnInit, OnChanges {
  readonly ChipInputType = ChipInputType;

  selectionCtrl: FormControl = new FormControl();
  selectedOptions: any[] = [];

  @Input() inputType!: ChipInputType;
  @Input() controlField!: AbstractControl | any;
  @Input() placeholder: string = '';
  @Input() width: string = '300px';
  @Input() customError: string = '';
  @Input() disabled: boolean = false;

  @Output() valueChange: EventEmitter<any[]> = new EventEmitter();
  @ContentChild(ChipItemDirective) chipItemTemplate?: ChipItemDirective;

  //#region Handle for Selection
  @Input() options: Option[] = [];
  //#endregion

  //#region Handler for Autocomplete
  search$ = new BehaviorSubject('');
  selectedItemValue?: any;

  @Input() autocompleteContext?: AutocompleteContext;
  @Input() isSetMaxWidthSelect = false;
  @Output() search = new EventEmitter<string>();
  //#endregion

  constructor() {}

  ngOnInit(): void {
    this.controlField.valueChanges.subscribe((value: any) => {
      if (!value) {
        this.controlField.markAsUntouched();
        this.selectionCtrl.patchValue(null);

        if (this.inputType === ChipInputType.Selection) {
          this.options = this._sortOptions([...this.options, ...this.selectedOptions]);
        } else if (this.inputType === ChipInputType.Autocomplete) {
          this.autocompleteContext = { ...this.autocompleteContext, searchResult: [] };
          this.selectionCtrl.enable();
          this.selectedItemValue = null;
        }

        this.selectedOptions = [];
      }
    });

    if (this.inputType === ChipInputType.Autocomplete) {
      this.autocompleteContext = {
        searchResult: [],
        typingTime: 500,
        columns: ['displayValue'],
        lengthToSearch: 2,
        isSearching: false,
        isSearchError: false,
        ...this.autocompleteContext,
      };

      this.search$
        .pipe(
          filter((value) => value?.length >= this.autocompleteContext!.lengthToSearch! || this.autocompleteContext?.displayAllResult!),
          tap(() => this.patchAutocompleteContext({ isSearching: true })),
          debounceTime(this.autocompleteContext.typingTime ?? 500),
        )
        .subscribe((value) => this.search.emit(value));
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      this.options = changes.options.currentValue.map((opt: Option, index: number) => ({
        ...opt,
        order: index,
      }));
    }
  }

  onClickAddItem(): void {
    if (this.inputType === ChipInputType.Selection) {
      this._patchSelectedValues([...(this.controlField.value ?? []), this.selectionCtrl.value]);

      // Append selected option to the list of selected options
      const selectedOption = this.options.find((opt) => opt.value === this.selectionCtrl.value);
      if (selectedOption) this.selectedOptions = [...this.selectedOptions, selectedOption];

      // Remove the selected option from options
      this.options = this.options.filter((opt) => opt.value !== this.selectionCtrl.value);
    } else if (this.inputType === ChipInputType.Autocomplete) {
      this._patchSelectedValues([...(this.controlField.value ?? []), this.selectedItemValue]);

      // Append selected option to the list of selected options
      this.selectedOptions = [
        ...this.selectedOptions,
        {
          value: this.selectedItemValue,
          displayValue: this.selectionCtrl.value,
        },
      ];

      this.selectionCtrl.enable();
      this.selectedItemValue = null;
    }

    // Clear Selection control value
    this.selectionCtrl.patchValue(null);

    this._searchAllResult();
  }

  onClickRemoveItem(option: Option): void {
    if (this.inputType === ChipInputType.Selection) {
      // Remove an option from the list of selected options
      this.selectedOptions = this.selectedOptions.filter((opt) => opt.value !== option.value);

      // Append removed item to options
      this.options = this._sortOptions([...this.options, option]);
    } else if (this.inputType === ChipInputType.Autocomplete) {
      // Remove an option from the list of selected options
      this.selectedOptions = this.selectedOptions.filter((opt) => opt.value !== option.value);

      this.selectionCtrl.enable();
    }
    this._patchSelectedValues(this.controlField.value.filter((val: any) => val !== option.value));
    
    this._searchAllResult();
  }

  //#region Handle for Autocomplete case
  onSearch(): void {
    this.patchAutocompleteContext({ searchResult: [] });
    this.search$.next(this.selectionCtrl.value);
  }

  patchAutocompleteContext(ctx: AutocompleteContext): void {
    this.autocompleteContext = { ...this.autocompleteContext, ...ctx };
  }

  onClickSelectItem(item: Option): void {
    this.selectionCtrl.patchValue(item.displayValue);
    this.selectionCtrl.disable();
    this.selectedItemValue = item.value;

    this.patchAutocompleteContext({ searchResult: [] });
  }

  clearSearchContent(): void {
    this.selectionCtrl.patchValue(null);
    this.selectionCtrl.enable();
    this.selectedItemValue = null;

    this._searchAllResult();
  }
  //#endregion

  private _patchSelectedValues(selectedValues: any[]): void {
    this.controlField.patchValue(selectedValues);
    this.valueChange.emit(selectedValues);

    if (this.controlField.hasValidator(Validators.required)) {
      this.controlField?.markAsTouched();
      if (!selectedValues?.length) {
        this.selectionCtrl.setErrors({ required: true });
        this.controlField.setErrors({ required: true });
      } else {
        this.selectionCtrl.setErrors(null);
        this.controlField.setErrors(null);
      }
    }
  }

  private _sortOptions(options: Option[]): Option[] {
    return options.sort((a: Option, b: Option) =>
      a.order !== undefined && a.order !== null && b.order !== undefined && b.order !== null
        ? a.order > b.order
          ? 1
          : a.order < b.order
            ? -1
            : 0
        : 0,
    );
  }

  private _searchAllResult() {
    if (this.autocompleteContext?.displayAllResult) {
      this.search.emit(this.selectionCtrl.value);
    }
  }
}
