import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatAutocompleteTrigger, MAT_AUTOCOMPLETE_SCROLL_STRATEGY } from '@angular/material/autocomplete';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CellContentSearch } from '../autocomplete/directives/cell-content-search.directive';
import { DEFAULT_NOT_FOUND_MSG } from '../autocomplete';
import { Overlay } from '@angular/cdk/overlay';
@Component({
  selector: 'ptg-auto-complete-search',
  templateUrl: './auto-complete-search.component.html',
  styleUrls: ['./auto-complete-search.component.scss'],
  providers: [
    {
      provide: MAT_AUTOCOMPLETE_SCROLL_STRATEGY,
      useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.reposition(),
      deps: [Overlay]
    }
  ]
})

export class AutoCompleteSearchComponent<T> implements OnInit, OnChanges {

  search$ = new BehaviorSubject('');

  @ContentChild(CellContentSearch) cellContentSearch?: CellContentSearch;
  @ViewChild('searchTrigger') searchTrigger?: MatAutocompleteTrigger;

  @Input() label: string = '';
  @Input() icon: string = '';
  @Input() svgIcon: string = '';
  @Input() searchContent: string = '';
  @Input() isShowClearButton: boolean = true;
  @Input() isLoading: boolean = false;
  @Input() isError: boolean = false;
  @Input() data: T[] = [];
  @Input() columns: any[] = [];
  @Input() noDataMsg: string = DEFAULT_NOT_FOUND_MSG;
  @Input() lengthToSearch: number = 2;
  @Input() typingTime: number = 500;
  @Input() isSelected: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() searchClass: string = '';
  @Input() dialogClass: string = '';
  @Input() defaultSearchResult: boolean = false;

  @Output() search = new EventEmitter<string>();
  @Output() selectItem = new EventEmitter<T>();
  @Output() clickClear = new EventEmitter<boolean>();
  @Output() valueChange = new EventEmitter<string>();
  @Output() onFocus = new EventEmitter<string>();

  displayedColumns!: string[];

  ngOnInit(): void {
    if (this.dialogClass) {
      document.querySelector(`.${this.dialogClass}`)?.addEventListener('scroll', this.scrollEvent);
    }

    this.search$.pipe(debounceTime(this.typingTime)).subscribe((value) => {
      if (value && value.length >= this.lengthToSearch) {
        this.search.emit(value);
      }
    });
  }

  ngOndestroy(): void {
    if (this.dialogClass) {
      document.querySelector(`.${this.dialogClass}`)?.removeEventListener('scroll', this.scrollEvent);
    }
  }

  scrollEvent = (): void => {
    if(this.searchTrigger?.panelOpen) {
      this.searchTrigger.updatePosition();
    }
  };

  onChange(value: string): void {
    this.searchContent = value;
    this.valueChange.emit(value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns) {
      const columns = changes.columns.currentValue;
      this.displayedColumns = [...columns.map((col: any) => col.name)];
      this.columns = changes.columns.currentValue;
    }
    if (changes.data) {
      this.data = changes.data.currentValue;
    }
  }
  
  onSearch(): void {
    this.data = [];
    this.isLoading = this.searchContent.length >= this.lengthToSearch;
    this.search$.next(this.searchContent);
  }

  onFocusValue(value: FocusEvent): void {
    this.onFocus.emit((value.target as HTMLInputElement).value);
  }

  onClickSelect(row: T) {
    this.isSelected = true;
    this.searchTrigger?.closePanel();
    this.selectItem.emit(row);
  }

  onClickClearSearch(): void {
    if (this.isDisabled || this.isSelected) {
      this.clearSearch();
      const oldIsSelected = !this.isSelected;
      this.clickClear.emit(oldIsSelected);
    } else {
      this.clearSearch();
    }
  }

  clearSearch(): void {
    this.isSelected = false;
    this.searchContent = '';
  }
}
