import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export interface CountryState {
  states: any[]; 
  countries: any[];
}
@Injectable({
  providedIn: 'root'
})
export class CountryStateService {
  _res: any;
  _screenId: string | undefined;
  
  public get res$() {
    if (!this._res) {
      return this.getCountryState(this._screenId ? this._screenId : '');
    } else {
      return of(this._res);
    }
  }
  
  public set res$(v : any) {
    this._res = v;
  }
  
  constructor(
    private httpClient: HttpClient,
  ) { }

  getCountryState(screenId: string): Observable<CountryState> {    
    let param = `lookup-values?screenId=${screenId}`
    if (!screenId) {
      param = 'lookup-values'
    }
    return this.httpClient.get<CountryState>(`${environment.apiUrl}/${param}`).pipe(
      map(el => {
        let states: any = el?.states?.map(items => this.tranformData(items));
        let countries: any = el?.countries?.map(items => this.tranformData(items));
        this._res = {states, countries};
        return {states, countries};
      })
    );
  }

  tranformData(obj: any) {
    return {
      displayValue: obj?.description,
      value: obj?.lookupTableOptionId,
      code: obj?.code
    }
  }
}
