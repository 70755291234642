import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance';
import {
  CancelPaymentInstructionRequest,
  CheckDisplayOffsetButtonResponse,
  GetInstructionHistoryRequest,
  GetInstructionHistoryResponse,
  GetPaymentInfoTabsRequest,
  GetPaymentInfoTabsResponse,
  GetRecalculateTaxesRequest,
  GetRecalculateTaxesResponse,
  MarkOverpaidFinalizedPaymentRequest,
  MarkOverpaidFinalizedPaymentResponse,
} from './models';
import { capitalizeFirstLetter } from '@ptg-shared/utils/string.util';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';

@Injectable()
export class PaymentInfoTabService {
  constructor(private httpClient: HttpClient) {}

  getPaymentInfoTabs(request: GetPaymentInfoTabsRequest): Observable<GetPaymentInfoTabsResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', capitalizeFirstLetter(request.sortNames));
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }
    if (request?.benefitCode) {
      params = params.append('BenefitCode', request.benefitCode);
    }
    if (request?.paymentInstructionId) {
      params = params.append('PaymentInstructionId', request.paymentInstructionId);
    }
    if (request?.calculationType) {
      params = params.append('CalculationType', request.calculationType);
    }

    return this.httpClient.get<GetPaymentInfoTabsResponse>(
      `${environment.apiUrl}/new-payroll/payments/benefitSubtypes/${request.benefitSubtypeId}/payment-info-tab/${request.payeeRecordId}`,
      { params },
    );
  }

  getInstructionHistories(request: GetInstructionHistoryRequest): Observable<GetInstructionHistoryResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', capitalizeFirstLetter(request.sortNames));
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    if (request?.benefitCode) {
      params = params.append('BenefitCode', request.benefitCode);
    }
    if (request?.paymentInstructionId) {
      params = params.append('PaymentInstructionId', request.paymentInstructionId);
    }
    if (request?.calculationType) {
      params = params.append('CalculationType', request.calculationType);
    }

    return this.httpClient.get<GetInstructionHistoryResponse>(
      `${environment.apiUrl}/new-payroll/payments/benefitSubtypes/${request.benefitSubtypeId}/instruction-history/${request.payeeRecordId}`,
      { params },
    );
  }

  getRecalculateTaxes(request: GetRecalculateTaxesRequest): Observable<GetRecalculateTaxesResponse> {
    const context = new HttpContext().set(SHOW_LOADING, true);
    return this.httpClient.get<GetRecalculateTaxesResponse>(
      `${environment.apiUrl}/new-payroll/payment-instruction/${request.paymentInstructionId}/recalculate-tax`,
      { context },
    );
  }

  validateCancelPaymentInstruction(request: CancelPaymentInstructionRequest) {
    return this.httpClient.get<{isValid: boolean, message: string}>(
      `${environment.apiUrl}/new-payroll/payments/${request.paymentInstructionId}/validate-cancel-payment`,
    );
  }

  cancelPaymentInstruction(request: CancelPaymentInstructionRequest) {
    return this.httpClient.put<GetRecalculateTaxesResponse>(
      `${environment.apiUrl}/new-payroll/payments/${request.paymentInstructionId}/cancel-payment`,
      {}
    );
  }

  checkDisplayOffsetButton(request: GetPaymentInfoTabsRequest) {
    let params = new HttpParams();
    params = params.append('payeeRecordId', request?.payeeRecordId ?? '');
    params = params.append('benefitTypeOptionId', request?.benefitSubtypeId ?? '');
    return this.httpClient.get<CheckDisplayOffsetButtonResponse>(
      `${environment.apiUrl}/new-payroll/payments/check-exist-finalized-payment/${request?.benefitSubtypeId}`,
      {params}
    );
  }

  //#region #156182 AC2 [Integrate] Mark/Remove a payment as Overpaid
  markRemoveOverpaid({paymentInstructionHistoryId, ...body}: MarkOverpaidFinalizedPaymentRequest) {
    return this.httpClient.put<MarkOverpaidFinalizedPaymentResponse>(
      `${environment.apiUrl}/new-payroll/payments/${paymentInstructionHistoryId}/mark-overpaid`,
      body
    );
  }
  //#endregion
}
