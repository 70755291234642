export const ENTITY_MAPPING = {
  Member: 'd478c688-445a-44be-af5f-b090fcbc8048',
  Employer: 'BEBB9F9E-2DAC-4072-A341-A32B907DDCA1',
};

export const NOTES_CARD = {
  Id: '64937b06-4859-43c3-89d6-3f001c8c01d1',
};

export const STATUS_COLUMNKEY = {
  StatusName: 'd478c688-445a-44be-af5f-b090fcbc8048_762ac020-8961-44da-a811-6a73a9d243df_eb8cfe7c-e348-4e60-93cb-f265057f5eb8_StatusName_0',
  StatusEvent: 'd478c688-445a-44be-af5f-b090fcbc8048_762ac020-8961-44da-a811-6a73a9d243df_eb8cfe7c-e348-4e60-93cb-f265057f5eb8_StatusEvent_0',
  StatusDate: 'd478c688-445a-44be-af5f-b090fcbc8048_762ac020-8961-44da-a811-6a73a9d243df_eb8cfe7c-e348-4e60-93cb-f265057f5eb8_StatusDate_0'
}
