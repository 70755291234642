<ptg-dialog class="detailed-adjustment">
  <ng-template dialogContentHeader>
    <ptg-breadcrumb [listBreadcrumbs]="[{ name: 'New Detailed Adjustment' }]"></ptg-breadcrumb>
  </ng-template>

  <ng-template dialogBody>
    <div class="detailed-adjustment-wrap flex">
      <div class="detailed-adjustment-container w-[50%] pr-[32px]">
        <p class="detailed-adjustment-name">{{ fundingSourceTitle }}</p>
        
        <div class="detailed-adjustment-header flex">
          <div class="detailed-adjustment-header-label w-[25%] text-right"></div>
          <div class="detailed-adjustment-header-label w-[25%] text-right">As Paid</div>
          <div class="detailed-adjustment-header-label w-[25%] text-right">Net Amount</div>
          <div class="detailed-adjustment-header-label w-[25%] text-right">Adjustment </div>
        </div>

        <div class="detailed-adjustment-body">
          <div *ngFor="let fundingSource of fundingSourcesListControl.controls; let i = index; trackBy: identify">
            <div class="detailed-adjustment-body-row flex items-end">
              <!---- ---->
              <ng-template #customTooltip>
                <div
                  *ngIf="
                  fundingSource.get('deductionType')?.value === DeductionTypeEnum.Garnishment || fundingSource.get('deductionType')?.value === DeductionTypeEnum.QDRO
                  "
                >
                  Court Order: <b>{{ fundingSource.get('caseNumber')?.value }}</b>
                </div>
                <div
                  *ngIf="
                    fundingSource.get('deductionType')?.value === DeductionTypeEnum.Garnishment || fundingSource.get('deductionType')?.value === DeductionTypeEnum.QDRO
                  "
                >
                  Payee: <b>{{ fundingSource.get('payeeName')?.value }}</b>
                </div>
              </ng-template>
              <div
                class="detailed-adjustment-body-cell caption w-[25%] pb-[10px] truncate"
                matTooltipClass="custom-tooltip"
                [tooltip]="fundingSource.get('isShowTooltip')?.value ? customTooltip : null"
              >{{ fundingSource.get('fundingSource')?.value }}</div>
              <!---- ---->
              <div class="detailed-adjustment-body-cell w-[25%] text-right pb-[10px]">{{ fundingSource.get('asPaid')?.value | numberLocalDecimal: '$' }}</div>
              <div class="detailed-adjustment-body-cell w-[25%] text-right pb-[10px]">{{ fundingSource.get('netAmount')?.value | numberLocalDecimal: '$' }}</div>
              <div *ngIf="!reversalControl.value" class="detailed-adjustment-body-cell w-[25%] text-right">
                <ptg-textbox
                  placeholder="Amount"
                  class="w-32"
                  [controlField]="fundingSource.get('adjustment')"
                  [hasLabel]="true"
                  [inputType]="InputType.TYPE_CURRENCY"
                  (changeControlValue)="onChangeFundingAmount(i)"
                  [isAccounting]="true"
                ></ptg-textbox>
              </div>
              <div *ngIf="reversalControl.value" class="detailed-adjustment-body-cell w-[25%] text-right pb-[10px]">{{ fundingSource.get('adjustment')?.value | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
            </div>
            
            <mat-error *ngIf="fundingSource.get('isInvalidAmount')?.value" class="text-[14px] mb-[10px]">Amount must be within the range of {{ minAmount }} - {{ maxAmount }}.</mat-error>
            <mat-error *ngIf="isShowAdjustmentNullMessage" class="text-[14px] mb-[10px]">At least 1 item of Earning/Funding Sources or Deduction must be required.</mat-error>
            <mat-error *ngIf="isShowAdjustmentZeroMessage" class="text-[14px] mb-[10px]">At least 1 item of Earning/Funding Sources or Deduction must be different from 0.00.</mat-error>
            <mat-error *ngIf="fundingSource.get('isOutOfRangeAmount')?.value" class="text-[14px] mb-[10px]">The net position of {{ fundingSource.get('fundingSource')?.value }} must be within the range of 0.00 - {{ fundingSource.get('maxRangeAmount')?.value | numberLocalDecimal: '' : { accountingFormat: true } }}.</mat-error>
          </div>
        </div>

        <div class="detailed-adjustment-body detailed-adjustment-body--total">
          <div class="detailed-adjustment-body-row flex items-end">
            <div class="detailed-adjustment-body-cell caption w-[25%]">Gross Payment</div>
            <div class="detailed-adjustment-body-cell w-[25%] ml-1 text-right truncate">{{ grossPayment.asPaid | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
            <div class="detailed-adjustment-body-cell w-[25%] ml-1 text-right truncate">{{ grossPayment.netAmount | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
            <div class="detailed-adjustment-body-cell w-[25%] ml-1 text-right truncate">{{ grossPayment.adjustment| numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
          </div>

          <div class="detailed-adjustment-body-row flex items-end">
            <div class="detailed-adjustment-body-cell caption w-[25%]">Total Deductions</div>
            <div class="detailed-adjustment-body-cell w-[25%] ml-1 text-right truncate">{{ totalDeduction.asPaid | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
            <div class="detailed-adjustment-body-cell w-[25%] ml-1 text-right truncate">{{ totalDeduction.netAmount | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
            <div class="detailed-adjustment-body-cell w-[25%] ml-1 text-right truncate">{{ totalDeduction.adjustment| numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
          </div>

          <div class="detailed-adjustment-body-row flex items-end">
            <div class="detailed-adjustment-body-cell caption w-[25%]">Net Payment</div>
            <div class="detailed-adjustment-body-cell w-[25%] ml-1 text-right truncate">{{ netPayment.asPaid | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
            <div class="detailed-adjustment-body-cell w-[25%] ml-1 text-right truncate">{{ netPayment.netAmount | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
            <div class="detailed-adjustment-body-cell w-[25%] ml-1 text-right truncate">{{ netPayment.adjustment| numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
          </div>
        </div>
      </div>

      <div class="detailed-adjustment-container w-[50%] pl-[32px]">
        <p class="detailed-adjustment-name">Deductions</p>
        
        <div class="detailed-adjustment-header flex">
          <div class="detailed-adjustment-header-label w-[25%] text-right"></div>
          <div class="detailed-adjustment-header-label w-[25%] text-right">As Paid</div>
          <div class="detailed-adjustment-header-label w-[25%] text-right">Net Amount</div>
          <div class="detailed-adjustment-header-label w-[25%] text-right">Adjustment </div>
        </div>

        <div class="detailed-adjustment-body">
          <div *ngFor="let deduction of deductionListControl.controls; let i = index; trackBy: identify">
            <div class="detailed-adjustment-body-row flex items-end">
              <ng-template #customTooltip>
                <div
                  *ngIf="
                  deduction.get('deductionType')?.value === DeductionTypeEnum.Garnishment || deduction.get('deductionType')?.value === DeductionTypeEnum.QDRO
                  "
                >
                  Court Order: <b>{{ deduction.get('courtOrder')?.value }}</b>
                </div>
                <div
                  *ngIf="
                    deduction.get('deductionType')?.value === DeductionTypeEnum.Garnishment || deduction.get('deductionType')?.value === DeductionTypeEnum.QDRO
                  "
                >
                  Payee: <b>{{ deduction.get('payeeName')?.value }}</b>
                </div>
              </ng-template>
              <div
                class="detailed-adjustment-body-cell caption w-[25%] pb-[10px] truncate"
                matTooltipClass="custom-tooltip"
                [tooltip]="deduction.get('isShowTooltip')?.value ? customTooltip : null"
              >{{ deduction.get('labelDisplay')?.value }}</div>
              <div class="detailed-adjustment-body-cell w-[25%] text-right pb-[10px]">{{ deduction.get('asPaid')?.value | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
              <div class="detailed-adjustment-body-cell w-[25%] text-right pb-[10px]">{{ deduction.get('netAmount')?.value | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
              <div *ngIf="!reversalControl.value" class="detailed-adjustment-body-cell w-[25%] text-right">
                <ptg-textbox
                  placeholder="Amount"
                  class="w-32"
                  [controlField]="deduction.get('adjustment')"
                  [hasLabel]="true"
                  [inputType]="InputType.TYPE_CURRENCY"
                  (changeControlValue)="onChangeDeductionAmount(i)"
                  [isAccounting]="true"
                ></ptg-textbox>
  
              </div>
              
              <div *ngIf="reversalControl.value" class="detailed-adjustment-body-cell w-[25%] text-right pb-[10px]">{{ deduction.get('adjustment')?.value | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
            </div>
            <mat-error *ngIf="deduction.get('isInvalidAmount')?.value" class="text-[14px] mb-[10px]">Amount must be within the range of {{ minAmount }} - {{ maxAmount }}.</mat-error>
            <mat-error *ngIf="isShowAdjustmentNullMessage" class="text-[14px] mb-[10px]">At least 1 item of Earning/Funding Sources or Deduction must be required.</mat-error>
            <mat-error *ngIf="isShowAdjustmentZeroMessage" class="text-[14px] mb-[10px]">At least 1 item of Earning/Funding Sources or Deduction must be different from 0.00.</mat-error>
            <mat-error *ngIf="!isAdjustmentDeductionValid" class="text-[14px] mb-[10px]">The net position of Total Deductions cannot exceed original Gross amount.</mat-error>
            <mat-error *ngIf="deduction.get('isOutOfRangeAmount')?.value" class="text-[14px] mb-[10px]">The net position of {{ deduction.get('labelDisplay')?.value }} must be within the range of 0.00 - {{ deduction.get('maxRangeAmount')?.value | numberLocalDecimal: '' : { accountingFormat: true } }}.</mat-error>
          </div>
        </div>

        <div class="detailed-adjustment-body detailed-adjustment-body--total">
          <div class="detailed-adjustment-body-row flex items-end">
            <div class="detailed-adjustment-body-cell caption w-[25%]">Total Deductions</div>
            <div class="detailed-adjustment-body-cell w-[25%] ml-1 text-right truncate">{{ totalDeduction.asPaid | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
            <div class="detailed-adjustment-body-cell w-[25%] ml-1 text-right truncate">{{ totalDeduction.netAmount | numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
            <div class="detailed-adjustment-body-cell w-[25%] ml-1 text-right truncate">{{ totalDeduction.adjustment| numberLocalDecimal: '$' : { accountingFormat: true } }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="detailed-adjustment-actions w-[50%] my-[24px] flex justify-between items-center pr-[32px]">
      <ptg-toggle-button
        label="Reversal"
        [controlField]="reversalControl"
        (valueChange)="onReversal($event)"
      ></ptg-toggle-button>

      <ptg-button *ngIf="isShowClearAllButton" classInput="secondary" buttonName="Clear All" (clickButton)="onClearAll()"></ptg-button>
    </div>

    <div class="flex justify-between mt-[26px]">
      <ptg-datepicker
        placeholder="Accounting Post Date"
        class="w-[510px]"
        [controlField]="accountingPostDateControl"
        [isRequired]="true"
        [minDate]="minAccountingPostDate"
        [maxDate]="currentDate"
        errorMaxDate="Accounting post date cannot be future date."
        [errorMinDate]="errorMinAccountingPostDate"
        customError="errorMessageAccountingPostDate"
      ></ptg-datepicker>
      <ptg-textbox
        placeholder="Reason"
        class="w-[510px]"
        [controlField]="reasonControl"
        [hasLabel]="true"
        [maxLength]="250"
      ></ptg-textbox>
    </div>
  </ng-template>

  <ng-template dialogFooter>
    <div class="action-buttons flex gap-2 justify-end w-[100%]">
      <ptg-button classInput="primary" buttonName="Save" [isDisabled]="formData.invalid || !isAdjustmentDeductionValid" (clickButton)="onSave()"></ptg-button>
      <ptg-button classInput="tertiary" buttonName="Cancel" (clickButton)="onCancel()"></ptg-button>
    </div>
  </ng-template>
</ptg-dialog>
