import { Injectable } from '@angular/core';

import { BaseComponent } from './base.component';

// This class is open to common implementation
// Please discuss with your team before implementing
@Injectable()
export abstract class BaseScreenComponent<S = never> extends BaseComponent {
  private readonly SCREEN_STATE_KEY: string = `Screen.${this.constructor.name}`;
  screenState?: S;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.refreshScreenState();
  }

  refreshScreenState(): void {
    this.screenState = this.getScreenState();
  }

  getScreenState(): S | undefined {
    const value = localStorage.getItem(this.SCREEN_STATE_KEY);
    if (!value) {
      return undefined;
    }

    return JSON.parse(value);
  }

  protected setScreenState(value: S | undefined) {
    if (!value) {
      localStorage.removeItem(this.SCREEN_STATE_KEY);
      return;
    }

    localStorage.setItem(this.SCREEN_STATE_KEY, JSON.stringify(value));
    this.screenState = value;
  }
}
