import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'yyyy',
  },
  display: {
    dateInput: 'yyyy',
    monthYearLabel: 'yyyy',
    dateA11yLabel: 'yyyy',
    monthYearA11yLabel: 'yyyy',
  },
  minDate: new Date(1900, 0, 2),
  maxDate: new Date(9999, 11, 31),
};

export const MY_DATE = {
  minDate: new Date(1900, 0, 2),
  maxDate: new Date(9999, 11, 31),
};

@Component({
  selector: 'ptg-datepicker-year',
  templateUrl: './datepicker-year.component.html',
  styleUrls: ['./datepicker-year.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: LuxonDateAdapter, deps: [MAT_DATE_LOCALE, MAT_LUXON_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS },
  ]
})
export class DatepickerYearComponent implements OnInit {

  @Input() controlField!: AbstractControl | any;
  @Input() placeholder!: string;
  @Input() isRequired?: boolean;
  @Input() class?: string;
  @Input() minDate = MY_DATE.minDate;
  @Input() maxDate = MY_DATE.maxDate;
  @Input() errorAsync?: string;

  @Input() minTime!: any;
  @Input() errorRequire?: string;
  @Input() errorRange?: string;

  @Output() changeValue = new EventEmitter();

  errorInvalid? = "Invalid Date format.";
  errorMinDate = 'Invalid Date format.';
  errorMaxDate = 'Invalid Date format.';
  constructor() { }

  ngOnInit(): void {
    if(this.isRequired){
      this.controlField.addValidators(Validators.required);
      this.errorRequire = this.errorRequire ? this.errorRequire : `${this.placeholder} is required.`;
    }
  }

  onChangeValue(event: any) {
    this.changeValue.emit(event.value);
  }

  chosenYearHandler(normalizedYear: any, datepicker: any) {
    this.controlField.setValue(normalizedYear);
    this.changeValue.emit(normalizedYear);
    datepicker.close();
  }
}
