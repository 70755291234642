<div class="add-one-time-container" id="add-one-time-container">
  <ptg-title-bar [name]="data.payload ? 'Edit' : 'Add'" [viewName]="data.viewName" [onlyTitle]="true"></ptg-title-bar>

  <ptg-payment-header
    [targetIdFromDialog]="data?.memberId ?? ''"
    [isShowInteractionComponents]="false"
  ></ptg-payment-header>

  <div>
    <ptg-banner
      *ngIf="message"
      [(bannerType)]="bannerType"
      [message]="message"
      (closeBanner)="closeBanner()"
    ></ptg-banner>

    <div class="wrap-btn">
      <ptg-button (clickButton)="formSubmit$.next()" classInput="primary" buttonName="Save"></ptg-button>
      <ptg-button (clickButton)="onCancel()" classInput="tertiary" buttonName="Cancel"></ptg-button>
    </div>

    <div class="form-control-container">
      <ptg-select
        placeholder="One-time Payment Type"
        class="input-field"
        [controlField]="editForm.get('oneTimePaymentType')"
        [listData]="listOneTimePaymentType"
        [isOptionObj]="true"
        errorRequire="One-time Payment Type is required."
        [isSetMaxWidthSelect]="true"
        (changeOptionValue)="onChangeStatusValue()"
      ></ptg-select>
      <ptg-select
        *ngIf="editForm.get('correctionType')"
        placeholder="Correction Type"
        class="input-field"
        [controlField]="editForm.get('correctionType')"
        [listData]="listCorrectionType"
        [isOptionObj]="true"
        errorRequire="Correction Type is required."
        [isSetMaxWidthSelect]="true"
        (changeOptionValue)="onChangeCorrectionTypeValue()"
      ></ptg-select>
      <div class="benefit-period" *ngIf="benefitPeriodFormGroup">
        <h3 class="benefit-label">Benefit Period</h3>
        <div class="w-50">
          <ptg-datepicker
            *ngIf="isEditableStartDate; else startDateLabel"
            [controlField]="benefitPeriodFormGroup.get('benefitPeriodStart')"
            placeholder="Start Date"
            [isRequired]="true"
            [maxDate]="maxStartDate"
            [errorMaxDate]="errorMaxStartDate"
            (changeValue)="changeStartDate()"
            [errorAsync]="errorAsyncStartDate"
          ></ptg-datepicker>
          <ptg-datepicker
            *ngIf="isEditableEndDate; else endDateLabel"
            [controlField]="benefitPeriodFormGroup.get('benefitPeriodEnd')"
            placeholder="End Date"
            [isRequired]="true"
            [minDate]="benefitPeriodFormGroup.get('benefitPeriodStart')?.value"
            [errorAsync]="errorAsyncEndDate"
            (changeValue)="changeEndDate()"
          ></ptg-datepicker>

          <ng-template #startDateLabel>
            <div class="detail">
              <span class="label">Start Date</span>
              <span class="value">
                {{
                  benefitPeriodFormGroup.get('benefitPeriodStart')?.value
                    ? (benefitPeriodFormGroup.get('benefitPeriodStart')?.value | date: 'MM/dd/yyyy')
                    : '-'
                }}
              </span>
            </div>
          </ng-template>
          <ng-template #endDateLabel>
            <div class="detail">
              <span class="label">End Date</span>
              <span class="value">
                {{
                  benefitPeriodFormGroup.get('benefitPeriodEnd')?.value
                    ? (benefitPeriodFormGroup.get('benefitPeriodEnd')?.value | date: 'MM/dd/yyyy')
                    : '-'
                }}
              </span>
            </div>
          </ng-template>
        </div>

        <div class="mat-error mb-2" *ngIf="benefitPeriodOverlapErrorMessage?.length">
          {{ benefitPeriodOverlapErrorMessage }}
        </div>
      </div>
      <div>
        <ptg-datepicker
          [controlField]="editForm.get('payableDate')"
          placeholder="Payable Date"
          [isRequired]="true"
          [minDate]="currentDate"
          errorMinDate="Payable date cannot be past date"
          customError="dependentStartDate"
        ></ptg-datepicker>
      </div>
      <div class="representative-payee">
        <ng-container
          *ngIf="
            editForm.get('oneTimePaymentType')?.value === OneTimePaymentType['Final Payment'] && isAbleReissueToEstate
          "
        >
          <ptg-checkbox
            [controlField]="editForm.get('issueToEstate')"
            [isDisabled]="editForm.get('representativePayee')?.value"
            label="Issue to Estate"
            (changeValue)="onCheckIssueToEstate()"
          ></ptg-checkbox>
          <ptg-select
            *ngIf="editForm.get('issueToEstate')?.value"
            placeholder="Payee"
            class="input-field"
            [controlField]="editForm.get('payee')"
            [listData]="listPayee"
            [isOptionObj]="true"
            [isRequired]="editForm.get('issueToEstate')?.value"
            errorRequire="Payee is required."
            [isSetMaxWidthSelect]="true"
            [isMultipleLineOption]="true"
            (changeOptionValue)="onChangePayee($event)"
          ></ptg-select>
        </ng-container>
        <ptg-checkbox
          [controlField]="editForm.get('representativePayee')"
          [isDisabled]="editForm.get('issueToEstate')?.value"
          label="Representative Payee"
          (changeValue)="changeRepresentativePayee($event)"
        ></ptg-checkbox>
        <ng-container *ngIf="editForm.get('representativePayee')?.value">
          <ptg-select
            *ngIf="editForm.get('representativePayeeName')"
            placeholder="Representative Payee"
            class="input-field"
            [controlField]="editForm.get('representativePayeeName')"
            [listData]="listRepresentativePayee"
            [isOptionObj]="true"
            errorRequire="Representative Payee is required."
            [isSetMaxWidthSelect]="true"
            [isMultipleLineOption]="true"
            (changeOptionValue)="onChangePayeeValue()"
          ></ptg-select>
          <ptg-textbox
            *ngIf="editForm.get('representativePayeeNamePrint')"
            [controlField]="editForm.get('representativePayeeNamePrint')"
            placeholder="Name Printed on Check"
            [hasLabel]="true"
            [maxLength]="250"
            [isRequired]="true"
          ></ptg-textbox>
        </ng-container>
        <ptg-select
          placeholder="Payment Address"
          class="input-field"
          [controlField]="editForm.get('paymentAddress')"
          [listData]="listPaymentAddress"
          [isOptionObj]="true"
          [isOneTimeType]="true"
          errorRequire="Payment Address is required."
          [isSetMaxWidthSelect]="true"
          [isMultipleLineOption]="true"
          [hasNoneValue]="true"
        ></ptg-select>
      </div>
      <div class="funding-source pb-4" *ngIf="fundingSourceFormArray">
        <h3 class="benefit-label">Funding Source</h3>
        <div class="row-control" *ngFor="let control of fundingSourceFormArray?.controls; let i = index">
          <ptg-select
            placeholder="Funding Source"
            class="input-field"
            [controlField]="control.get('fundingSource')"
            [listData]="
              _createListData(
                control.get('fundingSource')?.value,
                fundingSourceFormArray,
                listFundingSource,
                'fundingSource'
              )
            "
            [isOptionObj]="true"
            errorRequire="Funding Source is required."
            [isSetMaxWidthSelect]="true"
            (changeOptionValue)="onChangeFundingSource()"
          ></ptg-select>
          <ptg-textbox
            [controlField]="control.get('amount')"
            [hasLabel]="true"
            placeholder="Amount"
            [max]="MAX_VALUE_NUMBER"
            [isPositive]="true"
            errorRequire="Amount is required."
            [inputType]="InputType.Currency"
            customError="errorAmountMessage"
          ></ptg-textbox>
          <button *ngIf="i !== 0" mat-button type="button" class="remove-button" (click)="onRemoveRow(i)">
            <mat-icon>delete_forever</mat-icon>
          </button>
        </div>
        <ptg-button
          buttonName="New Funding Source"
          iconName="add"
          classInput="secondary add-button"
          *ngIf="checkConditionToShowAddButton('FundingSource')"
          (clickButton)="addNewRowFunding()"
        ></ptg-button>
      </div>
      <div class="deduction pb-4" *ngIf="deductionFormGroup">
        <h3 class="deduction-label">Deductions as Funding Sources</h3>
        <div class="flex">
          <ptg-select
            placeholder="Deduction Type"
            [controlField]="deductionFormGroup?.get('deductionType')"
            [listData]="listDeductionType"
            [isOptionObj]="true"
            customError="requiredChip"
          ></ptg-select>
          <button mat-raised-button type="button" class="add-btn" type="button" (click)="addType()">
            <mat-icon>add</mat-icon>
          </button>
          <div class="chip-item-container">
            <span class="chip" *ngFor="let item of listChip; let i = index">
              <span>{{ item.displayValue }}</span>
              <button mat-button type="button" class="x-btn" (click)="removeChip(i)">✕</button>
            </span>
          </div>
        </div>
        <div class="deduction-tax" *ngIf="taxFormGroup">
          <h3 class="deduction-label">Taxes</h3>
          <ptg-toggle-button
            [controlField]="taxFormGroup?.get('collectDeductionsFederal')"
            [label]="CREATE_OFFSET_DEDUCTION_LABEL"
          ></ptg-toggle-button>
          <ptg-textbox
            [controlField]="taxFormGroup?.get('federalTax')"
            [hasLabel]="true"
            placeholder="Federal Tax"
            [max]="MAX_VALUE_NUMBER"
            [isPositive]="true"
            errorRequire="Federal Tax is required."
            [inputType]="InputType.Currency"
            (valueChange)="onFederalValueChange($event)"
            customError="errorAmountMessage"
          ></ptg-textbox>
          <!-- <ptg-toggle-button
            [controlField]="taxFormGroup?.get('collectDeductionsState')"
            label="Collect Deductions from DP "
          ></ptg-toggle-button>
          <ptg-textbox
            [controlField]="taxFormGroup?.get('stateTax')"
            [hasLabel]="true"
            placeholder="State Tax"
            [max]="MAX_VALUE_NUMBER"
            [isPositive]="true"
            errorRequire="State Tax is required."
            [inputType]="InputType.Currency"
            customError="errorAmountMessage"
          ></ptg-textbox> -->
        </div>
        <div class="deduction-insurance" *ngIf="insuranceFormArray">
          <h3 class="deduction-label">Insurances</h3>
          <div class="container-insurance" *ngFor="let control of insuranceFormArray?.controls; let i = index">
            <ptg-toggle-button
              [controlField]="control?.get('collectDeductionsInsurance')"
              [label]="CREATE_OFFSET_DEDUCTION_LABEL"
            ></ptg-toggle-button>
            <div class="row-control">
              <ptg-select
                [placeholder]="'Insurance ' + (i + 1)"
                class="input-field"
                [controlField]="control.get('insuranceDeduction')"
                [listData]="
                  _createListData(
                    control.get('insuranceDeduction')?.value,
                    insuranceFormArray,
                    listInsuranceDeduction,
                    'insuranceDeduction'
                  )
                "
                [isOptionObj]="true"
                errorRequire="Insurance Deduction is required."
                [isSetMaxWidthSelect]="true"
                (changeOptionValue)="onChangeInsuranceDeductionValue($event, control)"
              ></ptg-select>
              <ptg-textbox
                [controlField]="control.get('amount')"
                [hasLabel]="true"
                placeholder="Amount"
                [max]="MAX_VALUE_NUMBER"
                [isPositive]="true"
                errorRequire="Amount is required."
                [inputType]="InputType.Currency"
                customError="errorAmountMessage"
              ></ptg-textbox>
              <button
                mat-button
                type="button"
                class="remove-button"
                (click)="onRemoveRowDeduction(i, insuranceFormArray, DeductionType.Insurance)"
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </div>
          <ptg-button
            buttonName="New Insurance"
            iconName="add"
            classInput="secondary add-button"
            *ngIf="checkConditionToShowAddButton(DeductionType.Insurance)"
            (clickButton)="addNewRowDeduction(insuranceFormArray, DeductionType.Insurance)"
          ></ptg-button>
        </div>
        <div class="deduction-insurance" *ngIf="othersFormArray">
          <h3 class="deduction-label">Others</h3>
          <div class="container-insurance" *ngFor="let control of othersFormArray?.controls; let i = index">
            <ptg-toggle-button
              [controlField]="control?.get('collectDeductionsOthers')"
              [label]="CREATE_OFFSET_DEDUCTION_LABEL"
            ></ptg-toggle-button>
            <div class="row-control">
              <ptg-select
                [placeholder]="'Others ' + (i + 1)"
                class="input-field"
                [controlField]="control.get('othersDeduction')"
                [listData]="
                  _createListData(
                    control.get('othersDeduction')?.value,
                    othersFormArray,
                    listOthersDeduction,
                    'othersDeduction'
                  )
                "
                [isOptionObj]="true"
                errorRequire="Others Deduction is required."
                [isSetMaxWidthSelect]="true"
                (changeOptionValue)="onChangeOthersDeductionValue($event, control)"
              ></ptg-select>
              <ptg-textbox
                [controlField]="control.get('amount')"
                [hasLabel]="true"
                placeholder="Amount"
                [max]="MAX_VALUE_NUMBER"
                [isPositive]="true"
                errorRequire="Amount is required."
                [inputType]="InputType.Currency"
                customError="errorAmountMessage"
              ></ptg-textbox>
              <button
                mat-button
                type="button"
                class="remove-button"
                (click)="onRemoveRowDeduction(i, othersFormArray, DeductionType.Others)"
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
          </div>
          <ptg-button
            buttonName="New Others"
            iconName="add"
            classInput="secondary add-button"
            *ngIf="checkConditionToShowAddButton(DeductionType.Others)"
            (clickButton)="addNewRowDeduction(othersFormArray, DeductionType.Others)"
          ></ptg-button>
        </div>
        <div class="deduction-insurance" *ngIf="garnishmentsFormArray">
          <h3 class="deduction-label">Garnishments</h3>
          <div class="container-insurance" *ngFor="let control of garnishmentsFormArray?.controls; let i = index">
            <ptg-toggle-button
              [controlField]="control?.get('collectDeductionsGarnishments')"
              [label]="CREATE_OFFSET_DEDUCTION_LABEL"
            ></ptg-toggle-button>
            <div class="mt-2.5 row-control garnishments">
              <ptg-select
                placeholder="Court Order"
                class="input-field"
                [controlField]="control.get('courtOrder')"
                [listData]="
                  _createListData(control.get('courtOrder')?.value, garnishmentsFormArray, listCourtOrder, 'courtOrder')
                "
                [isOptionObj]="true"
                errorRequire="Court Order is required."
                [isSetMaxWidthSelect]="true"
                (changeOptionValue)="onChangeGarnishmentValue($event, control)"
              ></ptg-select>
              <!-- <ptg-select
                placeholder="Deduction Payee"
                class="input-field"
                [controlField]="control.get('deductionPayee')"
                [listData]="listDeductionPayee"
                [isOptionObj]="true"
                errorRequire="Deduction Payee is required."
                [isSetMaxWidthSelect]="true"
              ></ptg-select> -->
              <div class="min-w-[252px]">
                <div class="text-sm leading-6 emphasis-medium">Deduction Payee</div>
                <div class="text-base emphasis-high">
                  {{ control.get('deductionPayee')?.value }}
                </div>
              </div>
              <ptg-textbox
                [controlField]="control.get('amount')"
                [hasLabel]="true"
                placeholder="Amount"
                [max]="MAX_VALUE_NUMBER"
                [isPositive]="true"
                errorRequire="Amount is required."
                [inputType]="InputType.Currency"
                customError="errorAmountMessage"
              ></ptg-textbox>
              <button
                mat-button
                type="button"
                class="remove-button"
                (click)="onRemoveRowDeduction(i, garnishmentsFormArray, DeductionType.Garnishment)"
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
            <div class="row-control garnishments">
              <ptg-radio-button
                [classInput]="'flex items-center justify-center'"
                [isObject]="true"
                [listOption]="postOrPretaxOptions"
                [controlField]="control.get('postOrPretax')"
              ></ptg-radio-button>
            </div>
          </div>
          <ptg-button
            buttonName="New Garnishment"
            iconName="add"
            classInput="secondary add-button"
            *ngIf="checkConditionToShowAddButton(DeductionType.Garnishment)"
            (clickButton)="addNewRowDeduction(garnishmentsFormArray, DeductionType.Garnishment)"
          ></ptg-button>
        </div>
        <div class="deduction-insurance qdro" *ngIf="qdroFormArray">
          <h3 class="deduction-label">{{DeductionLabels[DeductionType.Qdro]}}</h3>
          <div class="container-insurance" *ngFor="let control of qdroFormArray.controls; index as i">
            <ptg-toggle-button
              [controlField]="control?.get('createOffsetDeduction')"
              [label]="CREATE_OFFSET_DEDUCTION_LABEL"
            ></ptg-toggle-button>
            <div class="row-control qdro">
              <ptg-select
                placeholder="Court Order"
                class="input-field"
                [controlField]="control.get('courtOrder')"
                [listData]="
                  _createListData(control.get('courtOrder')?.value, qdroFormArray, listCourtOrderQdro, 'courtOrder', 'qdro')
                "
                [isOptionObj]="true"
                errorRequire="Court Order is required."
                [isSetMaxWidthSelect]="true"
                (changeOptionValue)="onChangeGarnishmentValue($event, control)"
              ></ptg-select>
              <div class="min-w-[252px]">
                <div class="text-sm leading-6 emphasis-medium">Alternate Payee</div>
                <div class="text-base emphasis-high">
                  {{ control.get('deductionPayee')?.value }}
                </div>
              </div>
              <ptg-textbox
                [controlField]="control.get('amount')"
                [hasLabel]="true"
                placeholder="Amount"
                [max]="MAX_VALUE_NUMBER"
                [isPositive]="true"
                errorRequire="Amount is required."
                [inputType]="InputType.Currency"
                customError="errorAmountMessage"
              ></ptg-textbox>
              <button
                mat-button
                type="button"
                class="remove-button"
                (click)="onRemoveRowDeduction(i, qdroFormArray, DeductionType.Qdro)"
              >
                <mat-icon>delete_forever</mat-icon>
              </button>
            </div>
            <div class="row-control garnishments">
              <ptg-radio-button
                [classInput]="'flex items-center justify-center'"
                [isObject]="true"
                [listOption]="postOrPretaxOptions"
                [controlField]="control.get('postOrPretax')"
              ></ptg-radio-button>
            </div>
          </div>
          <ptg-button
            [buttonName]="'New ' + DeductionLabels[DeductionType.Qdro]"
            iconName="add"
            classInput="secondary add-button"
            *ngIf="checkConditionToShowAddButton(DeductionType.Qdro)"
            (clickButton)="addNewRowDeduction(qdroFormArray, DeductionType.Qdro)"
          ></ptg-button>
        </div>
      </div>
      <div class="gross-payment pb-4" *ngIf="fundingSourceFormArray || deductionFormGroup">
        <h3 class="benefit-label">Gross Payment</h3>
        <span class="value">{{ grossPayment | currency: 'USD' : 'symbol' : '1.2-2' }}</span>
      </div>
      <ptg-toggle-button
        *ngIf="
          editForm.get('oneTimePaymentType')?.value !== OneTimePaymentType['Final Payment'] &&
          editForm.get('withholdTaxes')
        "
        [controlField]="editForm.get('withholdTaxes')"
        label="Withhold Taxes"
      ></ptg-toggle-button>
      <ptg-input-area
        [controlField]="editForm.get('reason')"
        [hasLabel]="true"
        placeholder="Reason"
        [maxLength]="255"
        errorMaxLength="Exceed the 255 character limit."
      ></ptg-input-area>
    </div>
  </div>
</div>
