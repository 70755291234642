import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  CreateReissueTransactionsRequest,
  CreateRejectedTransactionsRequest,
  GetPaymentLevelErrorListsRequest,
  GetPaymentLevelErrorListsResponse,
  GetPayrollBenefitTypeResponse,
  GetPaymentErrorMessageResponse,
  GetVoidedReasonDetailsRequest,
  GetVoidedReasonDetailsResponse,
  GetVoidedReasonsRequest,
  GetVoidedReasonsResponse,
  MarkReturnedQuery,
  QueryExportRegister,
  RegisterListQuery,
  SummaryList,
} from './models/register.model';
import { Observable } from 'rxjs';

@Injectable()
export class RegisterService {
  transactionRegisterId: string = '';
  msgErrorCheckNumber: string = '';

  constructor(private httpClient: HttpClient) {}

  getSummaryList(body: RegisterListQuery) {
    return this.httpClient.post<SummaryList>(`${environment.apiUrl}/new-payroll/payroll-runs/transactions`, body);
  }

  createRejectedTransactions(request: CreateRejectedTransactionsRequest): Observable<void> {
    return this.httpClient.post<void>(`${environment.apiUrl}/new-payroll/payments/rejected-transaction`, request);
  }

  getVoidedReasons(request: GetVoidedReasonsRequest,  query: MarkReturnedQuery): Observable<GetVoidedReasonsResponse> {
    let params = new HttpParams();
    params = params.append('paymentMethod', query.paymentMethod);
    return this.httpClient.get<GetVoidedReasonsResponse>(`${environment.apiUrl}/new-payroll/payments/voided-reasons`, {params});
  }

  getVoidedReasonDetails(
    request: GetVoidedReasonDetailsRequest
  ): Observable<GetVoidedReasonDetailsResponse> {
    return this.httpClient.get<GetVoidedReasonDetailsResponse>(
      `${environment.apiUrl}/new-payroll/payments/voided-reasons/${request?.transactionId}`
    );
  }

  createReissueTransactions(
    request: CreateReissueTransactionsRequest
  ): Observable<string> {
    return this.httpClient.post<string>(
      `${environment.apiUrl}/new-payroll/one-time-payment/reissue-transaction`,
      request
    );
  }

  getPayrollBenefitTypes(): Observable<GetPayrollBenefitTypeResponse> {
    return this.httpClient.get<GetPayrollBenefitTypeResponse>(
      `${environment.apiUrl}/new-payroll/payroll-benefit-type`
    );
  }

  exportTransactionRegister(request: QueryExportRegister) {
    return this.httpClient.post(`${environment.apiUrl}/new-payroll/payroll-runs/transactions/export`, request);
  }

  checkConditionDisplaySuspendToggle(payeeRecordId: string) {
    return this.httpClient.get<{allPayeePisClosedOrCancelled: boolean}>(`${environment.apiUrl}/new-payroll/payroll-runs/transactions/${payeeRecordId}/check-all-payment-instructions-cancel-or-closed`);
  }

  getPayrollRunError(payrollRunId: string) {
    return this.httpClient.get<GetPaymentErrorMessageResponse>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${payrollRunId}/payroll-error-message`,
    );
  }

  getPaymentLevelErrorLists(request: GetPaymentLevelErrorListsRequest) {
    return this.httpClient.get<GetPaymentLevelErrorListsResponse>(
      `${environment.apiUrl}/new-payroll/payroll-runs/${request.payrollRunId}/transactions/${request.transactionId}/error-list`,
    );
  }
}
