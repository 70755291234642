<ptg-dialog class="create-general-adjustment flex flex-col" [title]="dialogTitle" [isFullScreen]="true">
  <!-- DIALOG CONTENT HEADER -->
  <ng-template dialogContentHeader>
    <ptg-payment-header [targetIdFromDialog]="data.memberId" [isShowInteractionComponents]="false"></ptg-payment-header>
  </ng-template>

  <!-- DIALOG CONTENT BODY -->
  <ng-template dialogBody>
    <div class="flex flex-col gap-[29px]" *ngIf="!isLoading; else loadingBlock">
      <!-- BANNERS -->
      <div class="flex flex-col gap-4">
        <ptg-banner *ngIf="bannerType" [(bannerType)]="bannerType" [message]="message"></ptg-banner>
        <ptg-banner
          *ngIf="infoBannerType"
          [(bannerType)]="infoBannerType"
          [message]="infoMessage"
          [isHideCloseButton]="true"
        ></ptg-banner>
      </div>

      <div class="flex flex-col gap-6">
        <!-- ACTION BUTTONS -->
        <div class="flex gap-2">
          <ptg-button
            classInput="primary"
            buttonName="Save"
            (clickButton)="onSave()"
            [isDisabled]="errorOccurs"
          ></ptg-button>
          <ptg-button classInput="tertiary" buttonName="Cancel" (clickButton)="onCancel()"></ptg-button>
        </div>

        <!-- MAIN CONTENT SECTION -->
        <div class="flex flex-col gap-6" *ngIf="!errorOccurs">
          <!-- GROSS PAYMENT & TOTAL DEDUCTION & NET PAYMENT -->
          <div class="flex gap-4">
            <div class="calculated-amounts flex gap-6">
              <div class="min-w-[140px]">
                <div class="text-sm leading-6 emphasis-medium">Gross Payment</div>
                <div class="text-base emphasis-high">
                  {{ earningInfoDisplay.grossPayment | numberLocalDecimal: '$' : { accountingFormat: true } }}
                </div>
              </div>
              <div class="min-w-[140px]">
                <div class="text-sm leading-6 emphasis-medium">Total Deductions</div>
                <div class="text-base emphasis-high">
                  {{ earningInfoDisplay.totalDeductions | numberLocalDecimal: '$' : { accountingFormat: true } }}
                </div>
              </div>
              <div class="min-w-[140px]">
                <div class="text-sm leading-6 emphasis-medium">Net Payment</div>
                <div class="text-base emphasis-high">
                  {{ earningInfoDisplay.netPayment | numberLocalDecimal: '$' : { accountingFormat: true } }}
                </div>
              </div>
            </div>
          </div>

          <!-- BENEFIT PERIOD -->
          <div class="flex flex-col gap-3" *ngIf="!isHiddenBenefitPeriod">
            <div class="text-base leading-6 emphasis-medium font-bold">Benefit Period</div>

            <div class="flex gap-3">
              <ptg-datepicker
                class="w-[405px]"
                placeholder="Start Date"
                [minDate]="minStartDate"
                [isRequired]="!isHiddenBenefitPeriod"
                [controlField]="benefitPeriodStartDateControl"
                [errorMinDate]="
                  'Start date must be after the Payee\'s benefit begin date for ' +
                  data.headerBenefits.benefitName +
                  '.'
                "
                customError="invalidStartDate"
                (changeValue)="refreshDatesOnMouseDownDatepicker()"
              ></ptg-datepicker>
              <ptg-datepicker
                class="w-[405px]"
                placeholder="End Date"
                [maxDate]="maxEndDate"
                [minDate]="minEndDate"
                [isRequired]="!isHiddenBenefitPeriod"
                [controlField]="benefitPeriodEndDateControl"
                errorMinDate="End date must be after Start date."
                [errorMaxDate]="
                  'End date must be before the latest finalized benefit period for ' +
                  data.headerBenefits.benefitName +
                  '.'
                "
                customError="invalidEndDate"
                (changeValue)="refreshDatesOnMouseDownDatepicker()"
              ></ptg-datepicker>
            </div>
          </div>

          <div class="flex flex-col gap-4 mt-[-0.5rem]">
            <!-- FUNDING SOURCES & DEDUCTIONS -->
            <div class="flex flex-col gap-4">
              <!-- FUNDING SOURCES SECTION -->
              <div class="flex flex-col gap-3">
                <div class="text-xl font-bold emphasis-high">
                  {{ fundingSourceTitle }}
                </div>
                <div
                  *ngFor="let childFormGroup of fundingSourceFormArray.controls; let i = index; trackBy: trackByFunc"
                  class="flex flex-col gap-3"
                >
                  <div class="flex gap-3">
                    <ptg-select
                      class="w-[300px]"
                      placeholder="Funding Source"
                      [isOptionObj]="true"
                      [isSetMaxWidthSelect]="true"
                      [isRequired]="true"
                      [controlField]="childFormGroup.get('fundingSource')"
                      [listData]="childFormGroup.get('displayOptionList')?.value"
                      (changeOptionValue)="
                        onChangeRowOption(
                          CreateGeneralAdjustmentFormArrayKey.FundingSources,
                          'fundingSource',
                          fundingSourceFormArray,
                          childFormGroup
                        )
                      "
                    ></ptg-select>
                    <ptg-textbox
                      class="w-[300px]"
                      placeholder="Amount"
                      customError="invalidAmount"
                      [hasLabel]="true"
                      [max]="amountMaxValue"
                      [min]="amountMinValue"
                      [isRequired]="true"
                      [isAccounting]="true"
                      [inputType]="InputType.TYPE_CURRENCY"
                      [controlField]="childFormGroup.get('amount')"
                      [allowNegativeNumbers]="childFormGroup.get('isNegativeAdjust')?.value"
                      (valueChange)="onChangeAmountField(CreateGeneralAdjustmentFormArrayKey.FundingSources)"
                    ></ptg-textbox>
                    <ptg-checkbox
                      label="Negative Adjust"
                      [controlField]="childFormGroup.get('isNegativeAdjust')"
                      (changeValue)="
                        onCheckedNegativeAdjust(
                          CreateGeneralAdjustmentFormArrayKey.FundingSources,
                          childFormGroup.get('amount')
                        )
                      "
                    ></ptg-checkbox>
                    <ptg-button
                      classInput="delete-icon text-only"
                      (clickButton)="
                        onRemoveRow(
                          CreateGeneralAdjustmentFormArrayKey.FundingSources,
                          'fundingSource',
                          fundingSourceFormArray,
                          i
                        )
                      "
                    >
                      <mat-icon>delete_forever</mat-icon>
                    </ptg-button>
                  </div>
                </div>
                <ptg-button
                  *ngIf="!isHiddenAddFundingSourceButton"
                  classInput="text-only"
                  (clickButton)="
                    onAddNewRow(
                      CreateGeneralAdjustmentFormArrayKey.FundingSources,
                      'fundingSource',
                      fundingSourceFormArray,
                      fundingSourceOptionList
                    )
                  "
                >
                  <span class="flex items-center gap-2">
                    <mat-icon>add</mat-icon>
                    <span>New Funding Source</span>
                  </span>
                </ptg-button>
              </div>

              <!-- DEDUCTION TYPES -->
              <div class="flex flex-col gap-3">
                <div class="text-xl font-bold emphasis-high">Deductions</div>
                <div class="flex gap-3">
                  <!-- DEDUCTION TYPE -->
                  <ptg-select
                    class="w-[300px]"
                    [isOptionObj]="true"
                    placeholder="Deduction Type"
                    [isSetMaxWidthSelect]="true"
                    [listData]="deductionTypeOptionList"
                    [controlField]="deductionTypeControl"
                  ></ptg-select>

                  <!-- ADD CHIP ICON -->
                  <ptg-button
                    classInput="icon-only"
                    [isDisabled]="!deductionTypeControl.value"
                    (clickButton)="onAddDeductionTypeChip()"
                  >
                    <mat-icon class="add-button">add_circle</mat-icon>
                  </ptg-button>

                  <!-- DEDUCTION TYPE CHIP LIST -->
                  <mat-chip-list *ngIf="deductionTypeChipFormArray.length">
                    <mat-chip
                      *ngFor="let chipOption of deductionTypeChipFormArray.controls; index as i"
                      (removed)="onRemoveDeductionTypeChip(i, chipOption.value?.value)"
                    >
                      <span class="truncate">{{ chipOption.value?.displayValue }}</span>
                      <button matChipRemove><mat-icon>close</mat-icon></button>
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </div>

              <!-- TAX DEDUCTIONS -->
              <div class="flex flex-col gap-3 mt-[-0.5rem]" *ngIf="isShowTaxDeduction">
                <div class="text-xl font-bold emphasis-high">Taxes</div>
                <div class="flex gap-3">
                  <ptg-textbox
                    class="w-[612px]"
                    placeholder="Federal Tax"
                    customError="invalidAmount"
                    [hasLabel]="true"
                    [min]="amountMinValue"
                    [max]="amountMaxValue"
                    [isRequired]="isShowTaxDeduction"
                    [isAccounting]="true"
                    [inputType]="InputType.TYPE_CURRENCY"
                    [controlField]="taxDeductionFormGroup.get('amount')"
                    [allowNegativeNumbers]="taxDeductionFormGroup.get('isNegativeAdjust')?.value"
                    (valueChange)="onChangeAmountField('taxes')"
                  ></ptg-textbox>
                  <ptg-checkbox
                    label="Negative Adjust"
                    [controlField]="taxDeductionFormGroup.get('isNegativeAdjust')"
                    (changeValue)="onCheckedNegativeAdjust('taxes', taxDeductionFormGroup.get('amount'))"
                  ></ptg-checkbox>
                </div>
              </div>

              <!-- INSURANCE DEDUCTIONS -->
              <div class="flex flex-col gap-3 border-top-section mt-[-0.5rem]" *ngIf="isShowInsurance">
                <div class="text-xl font-bold emphasis-high">Insurances</div>
                <div
                  *ngFor="let childFormGroup of insuranceFormArray.controls; let i = index; trackBy: trackByFunc"
                  class="flex flex-col gap-3"
                >
                  <div class="flex gap-3">
                    <ptg-select
                      class="w-[300px]"
                      [placeholder]="'Insurance ' + (i + 1)"
                      [isOptionObj]="true"
                      [isSetMaxWidthSelect]="true"
                      [isRequired]="isShowInsurance"
                      [controlField]="childFormGroup.get('insurance')"
                      [listData]="childFormGroup.get('displayOptionList')?.value"
                      (changeOptionValue)="
                        onChangeRowOption(
                          CreateGeneralAdjustmentFormArrayKey.Insurances,
                          'insurance',
                          insuranceFormArray,
                          childFormGroup
                        )
                      "
                    ></ptg-select>
                    <ptg-textbox
                      class="w-[300px]"
                      placeholder="Amount"
                      customError="invalidAmount"
                      [hasLabel]="true"
                      [max]="amountMaxValue"
                      [min]="amountMinValue"
                      [isRequired]="isShowInsurance"
                      [isAccounting]="true"
                      [inputType]="InputType.TYPE_CURRENCY"
                      [controlField]="childFormGroup.get('amount')"
                      [allowNegativeNumbers]="childFormGroup.get('isNegativeAdjust')?.value"
                      (valueChange)="onChangeAmountField(CreateGeneralAdjustmentFormArrayKey.Insurances)"
                    ></ptg-textbox>
                    <ptg-checkbox
                      label="Negative Adjust"
                      [controlField]="childFormGroup.get('isNegativeAdjust')"
                      (changeValue)="
                        onCheckedNegativeAdjust(
                          CreateGeneralAdjustmentFormArrayKey.Insurances,
                          childFormGroup.get('amount')
                        )
                      "
                    ></ptg-checkbox>
                    <ptg-button
                      classInput="delete-icon text-only"
                      (clickButton)="
                        onRemoveRow(CreateGeneralAdjustmentFormArrayKey.Insurances, 'insurance', insuranceFormArray, i)
                      "
                    >
                      <mat-icon>delete_forever</mat-icon>
                    </ptg-button>
                  </div>
                </div>
                <ptg-button
                  *ngIf="!isHiddenAddInsuranceButton"
                  classInput="text-only"
                  (clickButton)="
                    onAddNewRow(
                      CreateGeneralAdjustmentFormArrayKey.Insurances,
                      'insurance',
                      insuranceFormArray,
                      insuranceOptionList
                    )
                  "
                >
                  <span class="flex items-center gap-2">
                    <mat-icon>add</mat-icon>
                    <span>New Insurance</span>
                  </span>
                </ptg-button>
              </div>

              <!-- OTHER DEDUCTIONS -->
              <div class="flex flex-col gap-3 border-top-section mt-[-0.5rem]" *ngIf="isShowOtherDeduction">
                <div class="text-xl font-bold emphasis-high">Others</div>
                <div
                  *ngFor="let childFormGroup of otherDeductionFormArray.controls; let i = index; trackBy: trackByFunc"
                  class="flex flex-col gap-3"
                >
                  <div class="flex gap-3">
                    <ptg-select
                      class="w-[300px]"
                      [placeholder]="'Other ' + (i + 1)"
                      [isOptionObj]="true"
                      [isSetMaxWidthSelect]="true"
                      [isRequired]="isShowOtherDeduction"
                      [controlField]="childFormGroup.get('otherDeduction')"
                      [listData]="childFormGroup.get('displayOptionList')?.value"
                      (changeOptionValue)="
                        onChangeRowOption(
                          CreateGeneralAdjustmentFormArrayKey.OtherDeductions,
                          'otherDeduction',
                          otherDeductionFormArray,
                          childFormGroup
                        )
                      "
                    ></ptg-select>
                    <ptg-textbox
                      class="w-[300px]"
                      placeholder="Amount"
                      customError="invalidAmount"
                      [hasLabel]="true"
                      [max]="amountMaxValue"
                      [min]="amountMinValue"
                      [isRequired]="isShowOtherDeduction"
                      [isAccounting]="true"
                      [inputType]="InputType.TYPE_CURRENCY"
                      [controlField]="childFormGroup.get('amount')"
                      [allowNegativeNumbers]="childFormGroup.get('isNegativeAdjust')?.value"
                      (valueChange)="onChangeAmountField(CreateGeneralAdjustmentFormArrayKey.OtherDeductions)"
                    ></ptg-textbox>
                    <ptg-checkbox
                      label="Negative Adjust"
                      [controlField]="childFormGroup.get('isNegativeAdjust')"
                      (changeValue)="
                        onCheckedNegativeAdjust(
                          CreateGeneralAdjustmentFormArrayKey.OtherDeductions,
                          childFormGroup.get('amount')
                        )
                      "
                    ></ptg-checkbox>
                    <ptg-button
                      classInput="delete-icon text-only"
                      (clickButton)="
                        onRemoveRow(
                          CreateGeneralAdjustmentFormArrayKey.OtherDeductions,
                          'otherDeduction',
                          otherDeductionFormArray,
                          i
                        )
                      "
                    >
                      <mat-icon>delete_forever</mat-icon>
                    </ptg-button>
                  </div>
                </div>
                <ptg-button
                  *ngIf="!isHiddenAddOtherDeductionButton"
                  classInput="text-only"
                  (clickButton)="
                    onAddNewRow(
                      CreateGeneralAdjustmentFormArrayKey.OtherDeductions,
                      'otherDeduction',
                      otherDeductionFormArray,
                      otherDeductionOptionList
                    )
                  "
                >
                  <span class="flex items-center gap-2">
                    <mat-icon>add</mat-icon>
                    <span>New Others</span>
                  </span>
                </ptg-button>
              </div>

              <!-- GARNISHMENT DEDUCTIONS -->
              <div class="flex flex-col gap-3 border-top-section mt-[-0.5rem]" *ngIf="isShowGarnishment">
                <div class="text-xl font-bold emphasis-high">Garnishments</div>
                <div
                  *ngFor="let childFormGroup of garnishmentFormArray.controls; let i = index; trackBy: trackByFunc"
                  class="flex flex-col gap-3"
                >
                  <div class="flex gap-3">
                    <ptg-select
                      class="w-[196px]"
                      placeholder="Court Order"
                      [isOptionObj]="true"
                      [isSetMaxWidthSelect]="true"
                      [isRequired]="isShowGarnishment"
                      [controlField]="childFormGroup.get('garnishment')"
                      [listData]="childFormGroup.get('displayOptionList')?.value"
                      (changeOptionValue)="
                        onChangeRowOption(
                          CreateGeneralAdjustmentFormArrayKey.Garnishments,
                          'garnishment',
                          garnishmentFormArray,
                          childFormGroup
                        )
                      "
                    ></ptg-select>
                    <ptg-textbox
                      class="w-[196px]"
                      placeholder="Deduction Payee"
                      [hasLabel]="true"
                      [controlField]="childFormGroup.get('payee')?.get('payeeName')"
                      [isDisabled]="true"
                    ></ptg-textbox>
                    <ptg-textbox
                      class="w-[196px]"
                      placeholder="Amount"
                      customError="invalidAmount"
                      [hasLabel]="true"
                      [max]="amountMaxValue"
                      [min]="amountMinValue"
                      [isRequired]="isShowGarnishment"
                      [isAccounting]="true"
                      [inputType]="InputType.TYPE_CURRENCY"
                      [controlField]="childFormGroup.get('amount')"
                      [allowNegativeNumbers]="childFormGroup.get('isNegativeAdjust')?.value"
                      (valueChange)="onChangeAmountField(CreateGeneralAdjustmentFormArrayKey.Garnishments)"
                    ></ptg-textbox>
                    <ptg-checkbox
                      label="Negative Adjust"
                      [controlField]="childFormGroup.get('isNegativeAdjust')"
                      (changeValue)="
                        onCheckedNegativeAdjust(
                          CreateGeneralAdjustmentFormArrayKey.Garnishments,
                          childFormGroup.get('amount')
                        )
                      "
                    ></ptg-checkbox>
                    <ptg-button
                      classInput="delete-icon text-only"
                      (clickButton)="
                        onRemoveRow(
                          CreateGeneralAdjustmentFormArrayKey.Garnishments,
                          'garnishment',
                          garnishmentFormArray,
                          i
                        )
                      "
                    >
                      <mat-icon>delete_forever</mat-icon>
                    </ptg-button>
                  </div>
                </div>
                <ptg-button
                  *ngIf="!isHiddenAddGarnishmentButton"
                  classInput="text-only"
                  (clickButton)="
                    onAddNewRow(
                      CreateGeneralAdjustmentFormArrayKey.Garnishments,
                      'garnishment',
                      garnishmentFormArray,
                      garnishmentOptionList
                    )
                  "
                >
                  <span class="flex items-center gap-2">
                    <mat-icon>add</mat-icon>
                    <span>New Garnishment</span>
                  </span>
                </ptg-button>
              </div>

              <!-- QDRO DEDUCTIONS -->
              <div class="flex flex-col gap-3 border-top-section mt-[-0.5rem]" *ngIf="isShowQdroDeduction">
                <div class="text-xl font-bold emphasis-high">{{ qdroDeductionTitle }}s</div>
                <div
                  *ngFor="let childFormGroup of qdroDeductionFormArray.controls; let i = index; trackBy: trackByFunc"
                  class="flex flex-col gap-3"
                >
                  <div class="flex gap-3">
                    <ptg-select
                      class="w-[196px]"
                      placeholder="Court Order"
                      [isOptionObj]="true"
                      [isSetMaxWidthSelect]="true"
                      [isRequired]="isShowQdroDeduction"
                      [controlField]="childFormGroup.get('qdroDeduction')"
                      [listData]="childFormGroup.get('displayOptionList')?.value"
                      (changeOptionValue)="
                        onChangeRowOption(
                          CreateGeneralAdjustmentFormArrayKey.QDRODeductions,
                          'qdroDeduction',
                          qdroDeductionFormArray,
                          childFormGroup
                        )
                      "
                    ></ptg-select>
                    <ptg-textbox
                      class="w-[196px]"
                      placeholder="Deduction Payee"
                      [hasLabel]="true"
                      [controlField]="childFormGroup.get('payee')?.get('payeeName')"
                      [isDisabled]="true"
                    ></ptg-textbox>
                    <ptg-textbox
                      class="w-[196px]"
                      placeholder="Amount"
                      customError="invalidAmount"
                      [hasLabel]="true"
                      [max]="amountMaxValue"
                      [min]="amountMinValue"
                      [isRequired]="isShowQdroDeduction"
                      [isAccounting]="true"
                      [inputType]="InputType.TYPE_CURRENCY"
                      [controlField]="childFormGroup.get('amount')"
                      [allowNegativeNumbers]="childFormGroup.get('isNegativeAdjust')?.value"
                      (valueChange)="onChangeAmountField(CreateGeneralAdjustmentFormArrayKey.QDRODeductions)"
                    ></ptg-textbox>
                    <ptg-checkbox
                      label="Negative Adjust"
                      [controlField]="childFormGroup.get('isNegativeAdjust')"
                      (changeValue)="
                        onCheckedNegativeAdjust(
                          CreateGeneralAdjustmentFormArrayKey.QDRODeductions,
                          childFormGroup.get('amount')
                        )
                      "
                    ></ptg-checkbox>
                    <ptg-button
                      classInput="delete-icon text-only"
                      (clickButton)="
                        onRemoveRow(
                          CreateGeneralAdjustmentFormArrayKey.QDRODeductions,
                          'qdroDeduction',
                          qdroDeductionFormArray,
                          i
                        )
                      "
                    >
                      <mat-icon>delete_forever</mat-icon>
                    </ptg-button>
                  </div>
                </div>
                <ptg-button
                  *ngIf="!isHiddenAddQDRODeductionButton"
                  classInput="text-only"
                  (clickButton)="
                    onAddNewRow(
                      CreateGeneralAdjustmentFormArrayKey.QDRODeductions,
                      'qdroDeduction',
                      qdroDeductionFormArray,
                      qdroDeductionOptionList
                    )
                  "
                >
                  <span class="flex items-center gap-2">
                    <mat-icon>add</mat-icon>
                    <span>New {{ qdroDeductionTitle }}</span>
                  </span>
                </ptg-button>
              </div>
            </div>

            <!-- ACCOUNTING POST DATE & TRANSACTION DATE & REASON -->
            <div class="flex flex-col gap-[86px]">
              <!-- ACCOUNTING POST DATE & TRANSACTION DATE  -->
              <div class="flex gap-3 border-top-section">
                <ptg-datepicker
                  class="w-[405px] pt-2"
                  placeholder="Accounting Post Date"
                  [isRequired]="true"
                  [maxDate]="currentDate"
                  [minDate]="minAccountingPostDate"
                  [controlField]="accountingPostDateControl"
                  [errorMinDate]="errorMinAccountingPostDate"
                  errorMaxDate="Accounting post date cannot be future date."
                  (changeValue)="refreshDatesOnMouseDownDatepicker()"
                ></ptg-datepicker>
                <ptg-datepicker
                  class="w-[405px] pt-2"
                  placeholder="Transaction Date"
                  [isRequired]="true"
                  [maxDate]="currentDate"
                  [minDate]="benefitPeriodStartDateValue"
                  [controlField]="transactionDateControl"
                  errorMinDate="Cannot choose Transaction Date before Benefit Period Start date."
                  errorMaxDate="Transaction date cannot be future date."
                  (changeValue)="refreshDatesOnMouseDownDatepicker()"
                ></ptg-datepicker>
              </div>

              <!-- REASON -->
              <div class="w-[822px] mt-[-0.5rem]">
                <ptg-input-area
                  [maxLength]="255"
                  [hasLabel]="true"
                  placeholder="Reason"
                  class="reason-textarea w-full"
                  [controlField]="reasonControl"
                  errorMaxLength="Exceed the 255 character limit."
                >
                </ptg-input-area>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</ptg-dialog>

<ng-template #loadingBlock>
  <div class="spinner-wrapper" [class.active]="isLoading">
    <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
  </div>
</ng-template>
