<div *ngIf="!isLoading; else notLoading" class="earning-container">
  <ng-container *ngIf="selectedRow">
    <div class="control-bar">
      <span class="name"> Earnings </span>
      <div *ngIf="selectedTabPayment === TabPaymentInfo.Payment">
        <ng-container *ngIf="isShowMoreBtn; else earningEmpty">
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Icon-button with a menu"
            class="group-button ignore-row"
          >
            <mat-icon class="ignore-row">more_horiz</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onClickEdit()" *ngIf="earningsInfo?.isEditable">
              <mat-icon class="member-section-action-icon">edit</mat-icon>
              <span class="label-icon">Edit</span>
            </button>
            <button mat-menu-item (click)="onClickManage()">
              <mat-icon class="member-section-action-icon">list_alt</mat-icon>
              <span class="label-icon">View Detail</span>
            </button>
          </mat-menu>
        </ng-container>
        <ng-template #earningEmpty>
          <div *ngIf="isShowEditBtn && earningsInfo?.isEditable" class="edit-btn" (click)="onClickEdit()">
            <mat-icon>edit</mat-icon>
            <span class="title-edit">Edit</span>
          </div>
          <button *ngIf="isShowViewDetailBtn" class="edit-btn" (click)="onClickManage()">
            <mat-icon class="member-section-action-icon">list_alt</mat-icon>
            <span class="label-icon">View Detail</span>
          </button>
        </ng-template>
      </div>
      <!-- Out scope in release 1 -->
      <!-- <div
        *ngIf="
          selectedTabPayment === TabPaymentInfo.InstructionHistory &&
          selectedRow?.paymentInstructionType !== PaymentInstructionType.Adjustment
        "
        class="edit-btn"
      >
        <mat-icon>history</mat-icon>
        <span class="title-edit">Audit</span>
      </div> -->
    </div>

    <div class="list-item-grid">
      <div class="list-item-content flex">
        <div class="list-item-content__content--field">
          <div class="label-header"></div>
        </div>
        <div class="list-item-content__content--field flex justify-end">
          <span class="label-header align-right">
            {{ selectedRow.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.Adjustment ? 'Amount' : 'Current' }}
          </span>
        </div>
        <div class="list-item-content__content--field flex justify-end" *ngIf="earningsInfo?.isLinkToAdjustment">
          <span class="label-header align-right">Net Amount</span>
        </div>
        <div
          class="list-item-content__content--field flex justify-end"
          *ngIf="
            selectedRow?.paymentInfoAdjustmentType === PaymentInfoAdjustmentType.PaymentInstruction &&
            !deductionAsFundingSourceList?.length
          "
        >
          <span class="label-header align-right">YTD</span>
        </div>
      </div>
      <ng-container *ngIf="selectedRow?.paymentType !== PaymentInstructionType.Reissue">
        <ng-container *ngFor="let ele of earnings | filterList: 'showInView'; last as isLast">
          <div class="list-item-content item-info flex">
            <div class="list-item-content__content--field">
              <div class="label">{{ ele.name }}</div>
            </div>
            <ng-container
              [ngTemplateOutlet]="amountTemplate"
              [ngTemplateOutletContext]="{ amount: ele?.amounts?.amountCurrent }"
            >
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="amountTemplate"
              [ngTemplateOutletContext]="{
                amount: ele?.amounts?.amountNet,
                isHidden: !earningsInfo?.isLinkToAdjustment
              }"
            >
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="amountTemplate"
              [ngTemplateOutletContext]="{
                amount: ele?.amounts?.amountYTD,
                isHidden: selectedRow?.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.PaymentInstruction
              }"
            >
            </ng-container>
          </div>
          <hr *ngIf="isLast" />
        </ng-container>
        <ng-container *ngFor="let ele of adjustmentData | filterList: 'showInView'; last as isLast">
          <div class="list-item-content item-info flex">
            <div class="list-item-content__content--field">
              <div class="label">{{ ele?.name }}</div>
            </div>
            <ng-container
              [ngTemplateOutlet]="amountTemplate"
              [ngTemplateOutletContext]="{ amount: ele?.amounts?.amountCurrent }"
            >
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="amountTemplate"
              [ngTemplateOutletContext]="{
                amount: ele?.amounts?.amountNet,
                isHidden: !earningsInfo?.isLinkToAdjustment
              }"
            >
            </ng-container>
            <ng-container
              [ngTemplateOutlet]="amountTemplate"
              [ngTemplateOutletContext]="{
                amount: ele?.amounts?.amountYTD,
                isHidden: selectedRow?.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.PaymentInstruction
              }"
            >
            </ng-container>
          </div>
          <hr *ngIf="isLast" />
        </ng-container>
      </ng-container>
      <div class="list-item-content item-info flex">
        <div class="list-item-content__content--field">
          <div class="label">Gross Payment</div>
        </div>

        <ng-container
          [ngTemplateOutlet]="amountTemplate"
          [ngTemplateOutletContext]="{ amount: earningsInfo?.grossPayment?.amountCurrent }"
        >
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="amountTemplate"
          [ngTemplateOutletContext]="{
            amount: earningsInfo?.grossPayment?.amountNet,
            isHidden: !earningsInfo?.isLinkToAdjustment
          }"
        >
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="amountTemplate"
          [ngTemplateOutletContext]="{
            amount: earningsInfo?.grossPayment?.amountYTD || earningsInfo?.yearToDateGross,
            isHidden:
              selectedRow?.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.PaymentInstruction ||
              deductionAsFundingSourceList?.length
          }"
        >
        </ng-container>
      </div>
      <div class="list-item-content item-info flex" *ngIf="!selectedRow?.instructionStatusHistoryId && selectedRow?.paymentType !== PaymentInstructionType.Reissue">
        <div class="list-item-content__content--field">
          <div class="label">Taxable Amount</div>
        </div>
        <ng-container
          [ngTemplateOutlet]="amountTemplate"
          [ngTemplateOutletContext]="{ amount: earningsInfo?.taxableAmount?.amountCurrent }"
        >
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="amountTemplate"
          [ngTemplateOutletContext]="{
            amount: earningsInfo?.taxableAmount?.amountNet,
            isHidden: !earningsInfo?.isLinkToAdjustment
          }"
        >
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="amountTemplate"
          [ngTemplateOutletContext]="{
            amount: earningsInfo?.taxableAmount?.amountYTD,
            isHidden:
              selectedRow?.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.PaymentInstruction ||
              deductionAsFundingSourceList?.length
          }"
        >
        </ng-container>
      </div>
      <hr />
      <div class="list-item-content item-info flex" *ngIf="selectedRow?.paymentType !== PaymentInstructionType.Reissue">
        <div class="list-item-content__content--field">
          <div class="label">Total Deductions</div>
        </div>

        <ng-container
          [ngTemplateOutlet]="amountTemplate"
          [ngTemplateOutletContext]="{ amount: earningsInfo?.totalDeductions }"
        >
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="amountTemplate"
          [ngTemplateOutletContext]="{
            amount: earningsInfo?.totalNetAmountDeductions,
            isHidden: !earningsInfo?.isLinkToAdjustment
          }"
        >
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="amountTemplate"
          [ngTemplateOutletContext]="{
            amount: earningsInfo?.yearToDateDeduction || earningsInfo?.yearToDateDeduction,
            isHidden:
              selectedRow?.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.PaymentInstruction ||
              deductionAsFundingSourceList?.length
          }"
        >
        </ng-container>
      </div>
      <div class="list-item-content item-info flex">
        <div class="list-item-content__content--field">
          <div class="label">Net Payment</div>
        </div>
        <ng-container
          [ngTemplateOutlet]="amountTemplate"
          [ngTemplateOutletContext]="{ amount: earningsInfo?.netPayment?.amountCurrent }"
        >
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="amountTemplate"
          [ngTemplateOutletContext]="{
            amount: earningsInfo?.netPayment?.amountNet,
            isHidden: !earningsInfo?.isLinkToAdjustment
          }"
        >
        </ng-container>
        <ng-container
          [ngTemplateOutlet]="amountTemplate"
          [ngTemplateOutletContext]="{
            amount: earningsInfo?.netPayment?.amountYTD || earningsInfo?.yearToDateNet,
            isHidden:
              selectedRow?.paymentInfoAdjustmentType !== PaymentInfoAdjustmentType.PaymentInstruction ||
              deductionAsFundingSourceList?.length
          }"
        >
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #amountTemplate let-amount="amount" let-isHidden="isHidden" let-isBlank="isBlank">
  <div class="list-item-content__content--field" *ngIf="!isHidden">
    <div class="content align-right" [class.weight-500]="deductionAsFundingSourceList?.length">
      <!-- Conditionally display amount based on isBlank -->
      {{ isBlank ? '' : (amount || 0 | numberLocalDecimal: '$' : { accountingFormat: true }) }}
    </div>
  </div>
</ng-template>

<ng-template #notLoading>
  <ptg-spinner classInput="p-6" [isLoading]="isLoading"></ptg-spinner>
</ng-template>
