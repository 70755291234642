import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';
import { SortType } from '@ptg-shared/constance';

import {
  DisbursementReportRequest,
  DisbursementReportResponse,
  GetDisbursementReportsRequest,
  GetDisbursementReportsResponse,
  WarrantPaymentRequest,
} from './models';
import { SKIP_ERRORS } from '@ptg-interceptor/httpconfig.interceptor';
import { DatePipe } from '@angular/common';

@Injectable()
export class DisbursementReportService {
  constructor(private httpClient: HttpClient) { }
  getDisbursementReports(
    request: GetDisbursementReportsRequest,
    clientId: string
  ): Observable<GetDisbursementReportsResponse> {
    let params = new HttpParams();
    if (request?.sortNames?.length) {
      params = params.append('SortNames', request.sortNames);
      params = params.append('SortType', request?.sortType ?? SortType.ASC);
    }
    if (request?.pageSize && request?.pageNumber) {
      params = params.append('PageSize', request.pageSize);
      params = params.append('PageIndex', request.pageNumber);
    }

    return this.httpClient.get<GetDisbursementReportsResponse>(
      `${environment.apiUrl}/disbursement-report-histories`,
      { params }
    );
  }

  checkUniquePaymentDate = (paymentDate: string, disbursementReportId: string, clientId: string) => {
    return this.httpClient.get(
      `${environment.apiUrl}/disbursement-reports/payment-date/${paymentDate}/existing?disbursementReportId=${disbursementReportId}`
    );
  };

  checkkUniqueWarrantRegister = (warrantRegister: string, disbursementReportId: string, clientId: string) => {
    return this.httpClient.get(
      `${environment.apiUrl}/disbursement-reports/warrant-register-number/${warrantRegister}/existing?disbursementReportId=${disbursementReportId}`
    );
  };

  checkkUniqueWarrantBatch = (warrantBatch: string, disbursementReportId: string, clientId: string) => {
    return this.httpClient.get(
      `${environment.apiUrl}/disbursement-reports/warrant-batch-number/${warrantBatch}/existing?disbursementReportId=${disbursementReportId}`
    );
  };

  addDisbursementReport(clientId: string, disbursementReportRequest: DisbursementReportRequest) {
    return this.httpClient.post<DisbursementReportResponse>(
      `${environment.apiUrl}/disbursement-reports`,
      disbursementReportRequest
    );
  }

  editDisbursementReport(clientId: string, disbursementReportRequest: DisbursementReportRequest) {
    const context = new HttpContext().set(SKIP_ERRORS, '500');
    return this.httpClient.put(
      `${environment.apiUrl}/disbursement-reports/${disbursementReportRequest.disbursementReportId}`,
      disbursementReportRequest, { context }
    );
  }

  removeDisbursementReport(disbursementReportId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/disbursement-reports/${disbursementReportId}`
    );
  }

  validateBeforeRemove(disbursementReportId: string) {
    return this.httpClient.get(
      `${environment.apiUrl}/disbursement-reports/${disbursementReportId}/validate`
    );
  }

  addWarrantPayment(clientId: string, warrentPaymentRequest: WarrantPaymentRequest) {
    return this.httpClient.post(
      `${environment.apiUrl}/disbursement-reports/${warrentPaymentRequest.disbursementReportId}/payments`,
      warrentPaymentRequest
    );
  }

  editWarrantPayment(clientId: string, warrentPaymentRequest: WarrantPaymentRequest) {
    const context = new HttpContext().set(SKIP_ERRORS, '500');
    return this.httpClient.put(
      `${environment.apiUrl}/disbursement-reports/${warrentPaymentRequest.disbursementReportId}/payments/${warrentPaymentRequest.disbursementReportPaymentId}`,
      warrentPaymentRequest, { context }
    );
  }

  removeWarrantPayment(clientId: string, disbursementReportId: string, disbursementReportPaymentId: string) {
    return this.httpClient.delete(
      `${environment.apiUrl}/disbursement-reports/${disbursementReportId}/payments/${disbursementReportPaymentId}`
    );
  }

  checkkUniquePaymentNumber = (paymentNumber: string, disbursementReportPaymentId: string, clientId: string) => {
    const body = {
      paymentNumber,
      disbursementReportPaymentId
    }
    return this.httpClient.post(
      `${environment.apiUrl}/disbursement-report-payments/payment-number/existing`,
      body
    );
  };

  checkkUniqueDocNumber = (docNumber: string, disbursementReportPaymentId: string, clientId: string) => {
    return this.httpClient.get(
      `${environment.apiUrl}/disbursement-report-payments/doc-number/${docNumber}/existing?disbursementReportPaymentId=${disbursementReportPaymentId}`
    );
  };

  exportDisbursementReports(disbursementReportId: string, clientId: string) {
    const generatedAt = new DatePipe('en-US').transform(new Date(), "yyyy-MM-dd hh:mm:ss");
    let context = new HttpContext().set(
      SKIP_ERRORS,
      'MissingPaymentInformaionException'
    );
    return this.httpClient.get(
      `${environment.apiUrl}/disbursement-reports/${disbursementReportId}/export?generatedAt=${generatedAt}`,
      {
        context,
        responseType: 'blob',
      }
    );
  }
}
