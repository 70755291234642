import { FinalPaymentConfigurationEnum, PaymentInstructionType } from '@ptg-member/features/payee-detail/types/enums';
import { DeductionPayeeRunType } from '@ptg-processing/features/deduction-payees/types/enums';
import { PaymentType } from '@ptg-processing/features/payroll-calendar-container/types/enums/payroll-deduction-payee.enum';
import { TransactionPaymentResponseStatus } from '@ptg-shared/constance';
import { Address } from '@ptg-shared/types/models/common.model';
import { PayeeType } from 'src/app/organization-management/enums';

export interface RegisterListQuery {
  pageSize: number;
  pageIndex: number;
  sortNames: string[];
  sortType: number;
  transactionTypes?: number[];
  paymentMethods?: number[];
  benefitEntityIds?: string[];
  statusList?: number[];
  payableDateFrom?: string;
  payableDateTo?: string;
  transactionDateFrom?: string;
  transactionDateTo?: string;
  paymentAmount?: number;
  checkNumber?: number;
  payeeRecordId?: string;
  transactionId?: number;
  isOverpaid: boolean | null;
  returnedDateFrom?: string;
  returnedDateTo?: string;
}


export interface SummaryList {
  total: number;
  transactions: TransactionRegister[];
  maxLength: number;
  isOst: boolean;
  latestClosingDate?: string;
}

export interface TransactionRegister {
  id: string;
  payrollRunId: string;
  paymentInstructionId: string | null;
  payrollBenefitAdjustmentId: string | null;
  transactionId: number;
  transactionType: TransactionType;
  payeeType: PayeeType;
  transactionDate: string | null;
  recipient: string;
  paymentMethod: PaymentType | null;
  amount: number;
  datePosted: string | null;
  paymentInstructionType: PaymentInstructionType;
  startDate: string | null;
  endDate: string | null;
  status: PaymentTransactionStatus | null;
  paymentResponseStatus: TransactionPaymentResponseStatus | null;
  configuredID: ConfiguredIdDto;
  payeeId: string;
  benefitId: string;
  representativePayeeName?: string;
  representativePayeeId?: string;
  addressDto: Address;
  addressCode: string;
  alternatePayeeNames: AlternativePayeeDto[];
  mainPayee: string;
  registerPostDate: string | null;
  depositDate: string | null;
  accountingPostDate: string | null;
  postToRegister: string | null;
  payableDate: string | null;
  checkDate: string | null;
  checkNumber: number | null;
  checkMemos: CheckMemoDto[];
  dateReconciled: string | null;
  bankInformation: BankAccountDto;
  memo: NoteDto[];
  replacedBy?: string;
  adjustedBy?: string;
  grossAmount: number | null;
  totalDeductionAmount: number | null;
  netAmount: number | null;
  payment: number | null;
  adjustmentType: AdjustmentType | null;
  justification: string;
  originalTransaction: string;
  reissuedOf: string;
  benefitType: string;
  benefitEntityId: string | null;
  oneTimeType: string;
  benefitCode: string;
  payeeEntityId: string;
  payeeRecordId: string;
  createdTime: string;
  bankAssignmentId: string | null;
  bankAccId: string | null;
  depositId: string | null;
  isAbleToReissue: boolean;
  payeeAddenda?: DeductionAddendumDto[];
  reason: string[];
  voidedBy: string;
  voidedTime: string;
  // dynamic fields
  isDeductionPayee?: boolean;
  addenda: string | null;
  deductionRunType: DeductionPayeeRunType,
  endingChar?: string | null;
  returnDate?: string | null;
  reissueState?: ReissueState;  
  relatedBenefitId?: string;
  relatedPayeeEntityId?: string;
  relatedPayeeRecordId?: string;
  relatedPaymentInstructionId?: string;
  relatedPaymentInstructionHistoryId?: string;
  isOverpaid: boolean;
  isHavingError: boolean;
  isRecovered: boolean;
}
export enum TransactionType {
  Recurring = 0,
  'One-Time' = 1,
  Adjustment = 2,
  'Deduction Payee',
}

export enum ChipType {
  Adjustment,
  'One-Time',
  MuniRefund,
  Other
}

export enum AdjustmentType {
  General = 0,
  Detailed = 1,
}

export enum PaymentTransactionStatus {
  Pending = 0,
  Posted = 1,
  'Voided - Not Reissued' = 2,
  Adjusted = 3,
  'Voided - Processing Reissue' = 4,
  'Voided - Reissued' = 5
}
export interface ConfiguredIdDto {
  id: string;
  hasPropertySSN: boolean;
  isMasked: boolean;
}

export enum PaymentInfoType {
  Recurring,
  Reissue,
  'Off-Cycle',
}

export enum ReissueState {
  PendingReissue,
  ProcessingReissue,
  Reissued
}

export interface MarkReturnedQuery {
  paymentMethod: number;
}

export interface CreateRejectedTransactionsRequest {
  transactionId: number;
  paymentType: PaymentType;
  replacePayment: PaymentType | null;
  payeeRecordId: string;
  bankId: string;
  bankAccId: string;
  suspendPayee: boolean;
  disableDepositAccount: boolean;
  voidedReason: any;
  beginningOn: string;
  endingOn: string;
  depositId: string | null;
  paymentInstructionType: PaymentInstructionType;
  currentDepositId?: string | null;
  returnDate?: string | null;
}

export interface VoidedReason {
  reasonOptionId: string;
  reasonOptionDescription: string;
}

export interface GetVoidedReasonsRequest {}

export interface GetVoidedReasonsResponse {
  reasons: VoidedReason[];
}

export interface GetVoidedReasonDetailsRequest {
  transactionId: number;
}

export interface GetVoidedReasonDetailsResponse {
  reasons: VoidedReason[];
}

export interface CreateReissueTransactionsRequest {
  clientId?: string;
  clientKey?: string;
  reissuePaymentInstructionId: string;
  paymentType: PaymentType;
  payableDate: string | null;
  transactionId: number;
  estateId: string | null;
  isUpdateNextPayment: boolean | null;
  representativePayee: RepresentativePayeeDto | null;
  paymentAddress: string | null;
  checkMemo: string | null;
  addendum: string | null;
  depositAccountId: string | null;
}

export interface RepresentativePayeeDto {
  representativePayeeId: string;
  name: string;
  paymentAddress?: string;
}

export interface BankAccountDto {
  bankAccId: string;
  bankId: string;
  bankName: string;
  accountNumber: string;
  accountType: string;
  isDisabled?: boolean;
}

export interface NoteDto {
  id: string;
  note: string;
  postedAt: string | null;
  postedBy: string;
  postedByFirstName: string;
  postedByLastName: string;
  updatedBy: string;
  type: NoteType;
}

export enum NoteType {
  Note = 0,
  Memo = 1,
}

export interface AlternativePayeeDto {
  paymentInstructionId: string | null;
  entityName: string;
  caseNumber: string;
  firstName: string;
  lastName: string;
}

export interface GetPayrollBenefitTypeResponse {
  benefits: BenefitType[];
}

export interface BenefitType {
  id: string;
  benefitTypeId: string;
  name: string;
  entityId: string;
  payOption: FinalPaymentConfigurationEnum;
}

export interface QueryExportRegister extends RegisterListQuery {
  fileName: string;
  timeZone: string;
}

export interface DeductionAddendumDto {
  deductionId: string;
  deductionName: string;
  deductionPayeeAddendaId: string;
  addendum: string;
  dateCreated: string;
  value: string;
  order: number;
}

export interface CheckMemoDto {
  date: string;
  memo: string;
  type: CheckMemoType;
}

export enum CheckMemoType {
  CheckMemo,
  Memo
}

export enum OverpaidStatus {
  Yes = 1,
  No = 0
}

export interface PaymentErrorList {
  errorCode: string;
  Description: string;
  ErrorType: number;
}

export interface GetPaymentLevelErrorListsRequest {
  transactionId: string;
  payrollRunId: string;
}

export interface GetPaymentLevelErrorListsResponse {
  errorList: PaymentErrorList[];
  total: number;
  received?: string;
}

export interface ErrorMessage {
  errorCode?: string;
  errorDescription?: string;
  errorType?: number;
  segmentID?: string;
  segmentPosition?: string;
  positionInSegment?: string;
  originalFileType?: string;
  responseFileType?: string;
}

export interface GetPaymentErrorMessageResponse {
  dateTimeSubmitted: string;
  transmissionJobNumber: string;
  interchangeControlNumber: number;
  errorMessage: ErrorMessage[];
}
