import { createAction, props } from "@ngrx/store";
import { AddressData, BenefitTimeInfo, CheckPayableDate, CheckPayableDateResponse, FundingSourceElement, GetDeductionFundingSourcesResponse, PayeeElementDetail, PayeeResponse, PayloadOneTime, PaymentType, QueryParamsBenefit, QueryParamsWithHoldingTax } from "../../services/models";
import { generateActionName } from "@ptg-shared/utils/common.util";

export const getOneTimePaymentTypeAction = createAction(
  generateActionName('GetOneTimePaymentType/API').Send,
  props<{ benefitTypeOptionId: string, queryParams: QueryParamsBenefit }>()
);
export const getOneTimePaymentTypeSuccessAction = createAction(
  generateActionName('GetOneTimePaymentType/API').Success,
  props<{ paymentInstructionTypeDtos: PaymentType[] }>()
);
export const getOneTimePaymentTypeFailureAction = createAction(
  generateActionName('GetOneTimePaymentType/API').Failure,
  props<{ error?: any }>()
);

export const getOneTimeCorrectionTypeAction = createAction(
  generateActionName('GetOneTimeCorrectionType/API').Send,
  props<{ paymentInstructionTypeId: string, benefitTypeName: string }>()
);
export const getOneTimeCorrectionTypeSuccessAction = createAction(
  generateActionName('GetOneTimeCorrectionType/API').Success,
  props<{ paymentInstructionTypeDtos: PaymentType[] }>()
);
export const getOneTimeCorrectionTypeFailureAction = createAction(
  generateActionName('GetOneTimeCorrectionType/API').Failure,
  props<{ error?: any }>()
);

export const getFundingSourceAction = createAction(
  generateActionName('GetFundingSource/API').Send,
  props<{ benefitTypeOptionId: string }>()
);
export const getFundingSourceSuccessAction = createAction(
  generateActionName('GetFundingSource/API').Success,
  props<{ fundingSources: FundingSourceElement[] }>()
);
export const getFundingSourceFailureAction = createAction(
  generateActionName('GetFundingSource/API').Failure,
  props<{ error?: any }>()
);

export const getPayeeListAction = createAction(
  generateActionName('GetOneTimePayeeList/API').Send,
  props<{ payeeId: string, benefitCode: string }>()
);
export const getPayeeListSuccessAction = createAction(
  generateActionName('GetOneTimePayeeList/API').Success,
  props<{ response: PayeeResponse }>()
);
export const getPayeeListFailureAction = createAction(
  generateActionName('GetOneTimePayeeList/API').Failure,
  props<{ error?: any }>()
);
export const clearPayeeListState = createAction(generateActionName('GetOneTimePayeeList').Clear);

export const getBenefitTimeInfoAction = createAction(
  generateActionName('GetBenefitTimeInfo/API').Send,
  props<{ queryParams: QueryParamsBenefit, paymentInstructionTypeId: string }>()
);
export const getBenefitTimeInfoSuccessAction = createAction(
  generateActionName('GetBenefitTimeInfo/API').Success,
  props<{ benefitTimeInfo: BenefitTimeInfo }>()
);
export const getBenefitTimeInfoFailureAction = createAction(
  generateActionName('GetBenefitTimeInfo/API').Failure,
  props<{ error?: any }>()
);

export const getPaymentAddressAction = createAction(
  generateActionName('GetPaymentAddress/API').Send,
  props<{ payeeId: string, isPerson: boolean, benefitCode?: string }>()
);
export const getPaymentAddressSuccessAction = createAction(
  generateActionName('GetPaymentAddress/API').Success,
  props<{ addressData: AddressData[] }>()
);
export const getPaymentAddressFailureAction = createAction(
  generateActionName('GetPaymentAddress/API').Failure,
  props<{ error?: any }>()
);
export const clearPaymentAddressState = createAction(generateActionName('GetPaymentAddress').Clear);

export const createOneTimePaymentAction = createAction(
  generateActionName('CreateOneTimePayment/API').Send,
  props<{ body: PayloadOneTime }>()
);
export const createOneTimePaymentSuccessAction = createAction(
  generateActionName('CreateOneTimePayment/API').Success,
  props<{ response: { id: string } }>()
);
export const createOneTimePaymentFailureAction = createAction(
  generateActionName('CreateOneTimePayment/API').Failure,
  props<{ error?: any }>()
);

export const checkDisplayWithHoldingTaxAction = createAction(
  generateActionName('CheckDisplayWithHoldingTax/API').Send,
  props<{ queryParams: QueryParamsWithHoldingTax }>()
);
export const checkDisplayWithHoldingTaxSuccessAction = createAction(
  generateActionName('CheckDisplayWithHoldingTax/API').Success,
  props<{ isValid: boolean }>()
);
export const checkDisplayWithHoldingTaxFailureAction = createAction(
  generateActionName('CheckDisplayWithHoldingTax/API').Failure,
  props<{ error?: any }>()
);

export const clearCreateOneTimePaymentStateAction = createAction(
  generateActionName('CreateOneTimePayment/API').Clear,
);

export const getDeductionDataAction = createAction(
  generateActionName('GetDeductionData/API').Send,
  props<{ benefitCode: string, memberId: string, queryParams: BenefitTimeInfo }>()
);
export const getDeductionDataSuccessAction = createAction(
  generateActionName('GetDeductionData/API').Success,
  props<{ response: GetDeductionFundingSourcesResponse }>()
);
export const getDeductionDataFailureAction = createAction(
  generateActionName('GetDeductionData/API').Failure,
  props<{ error?: any }>()
);
export const clearDeductionDataStateAction = createAction(
  generateActionName('GetDeductionData/API').Clear,
);

//#region CheckPayableDate/API
export const checkPayableDateAction = createAction(
  generateActionName('CheckPayableDate/API').Send,
  props<CheckPayableDate>()
);
export const checkPayableDateSuccess = createAction(
  generateActionName('CheckPayableDate/API').Success,
  props<CheckPayableDateResponse>()
);
export const checkPayableDateFailure = createAction(
  generateActionName('CheckPayableDate/API').Failure
);
export const clearCheckPayableDate = createAction(
  generateActionName('CheckPayableDate/API').Clear
);
//#endregion
