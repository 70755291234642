import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { GetRelatedMembersActions, LinkRelatedMemberActions } from '@ptg-member/store/actions/member-detail.actions';
import { selectLinkRelatedMember, selectRelatedMembers } from '@ptg-member/store/selectors/related-members.selector';
import { BaseComponent } from '@ptg-shared/components';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { Option } from '@ptg-shared/controls/select/select.component';
import { showBanner, showCancelDialog } from '@ptg-shared/utils/common.util';
import { Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

export namespace LinkRelatedMemberPopup {
  export interface Input {
    memberId: string;
    benefitEntityId: string;
    benefitRecordId: string;
  }
}

@Component({
  selector: 'ptg-link-related-member',
  templateUrl: './link-related-member.component.html',
  styleUrls: ['./link-related-member.component.scss']
})
export class LinkRelatedMemberComponent extends BaseComponent implements OnInit, OnDestroy {
  bannerType = BannerType.Hidden;
  message = '';

  formSubmit$ = new Subject<void>();
  isSaving = false;
  isLoadingRelatedMembers = true;

  form = this.formBuilder.group({
    relatedMember: [null, Validators.required]
  });

  // Related Member Options
  relatedMemberOptions: Option[] = [];

  constructor(
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<LinkRelatedMemberComponent>,
    @Inject(MAT_DIALOG_DATA) public data: LinkRelatedMemberPopup.Input,
    private formBuilder: FormBuilder,
    private store: Store,
  ) {
    super();
  }

  ngOnInit(): void {
    this.checkFormSubmit();

    this.store.dispatch(GetRelatedMembersActions.request({
      participantId: this.data.memberId
    }));
    this.store.select(selectRelatedMembers).pipe(
      filter(state => !state?.isLoading),
      map(state => state?.payload?.relatedMembers.map<Option>(relatedMember => ({
        displayValue: relatedMember.name,
        value: relatedMember.id
      }))),
      takeUntil(this.unsubscribe$)
    ).subscribe(relatedMembers => {
      this.relatedMemberOptions = relatedMembers ?? [];
      this.isLoadingRelatedMembers = false;

      if (!relatedMembers) {
        showBanner.call(this, BannerType.Fail, 'Related Members', 'getting');
      }

      if (relatedMembers?.length === 0) {
        // If no value is available in the drop-down list.
        showBanner.call(this, BannerType.Info, '', '', {
          customMessage: 'Please add this participant as a related entity to a member before proceeding.'
        });
      }
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.formSubmit$.complete();
  }

  onCancel() {
    showCancelDialog.call(this, this.dialog, this.dialogRef);
  }

  private onSave() {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        title: 'Confirmation',
        text: 'Are you sure you want to link this benefit to the selected member? This action cannot be undone.',
        type: ConfirmType.Attention,
        cancelButtonTitle: 'No'
      }
    })
    .afterClosed().subscribe((isConfirm) => {
      if (isConfirm) {
        // Upon user clicks Yes, system will save the selected member as the related member of this Benefit ID
        this.isSaving = true;
        this.store.dispatch(LinkRelatedMemberActions.request({
          benefitEntityId: this.data.benefitEntityId,
          benefitRecordId: this.data.benefitRecordId,
          relatedMemberId: this.form.get('relatedMember')?.value
        }));
        this.store.select(selectLinkRelatedMember).pipe(
          filter(state => !state?.isLoading),
          takeUntil(this.unsubscribe$)
        ).subscribe(res => {
          this.isSaving = false;

          // Show error banner
          if (res?.error) {
            showBanner.call(this, BannerType.Fail, 'Related Member', 'linking');
            return;
          }
          
          // Reload Payment Info screen with the data of the current Benefit ID
          this.dialogRef.close(true);
        });
      }
    });
  }

  private checkFormSubmit() {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.form.markAllAsTouched();
        }),
        filter(() => this.form.valid),
      )
      .subscribe(() => this.onSave());
  }
}
