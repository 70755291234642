<button
  mat-flat-button
  [id]="buttonId"
  [disabled]="!!isDisabled"
  [ngClass]="classInput"
  [class.w-icon]="buttonName && iconName"
  [class.align-right]="iconAlignRight"
  (click)="buttonExecuteFn()"
  (mousedown)="clickMouseDown()"
>
  <span *ngIf="iconName" [ngClass]="iconClass">{{ iconName }}</span>
  <ng-content></ng-content>
  <span *ngIf="buttonName" class="text-button ignore-row {{ labelClass }}">{{ buttonName }}</span>
</button>
