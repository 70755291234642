<div class="breadcrumb-bar-container flex">
  <div class="breadcrumb-bar flex">
    <div
      class="breadcrumb-list"
      [ngClass]="{
        'right-separator':
          !listBreadcrumbs?.[listBreadcrumbs.length - 1]?.canEdit &&
          (buttonAddLabel || buttonEditLabel || buttonDeleteLabel)
      }"
      #breadcrumbList
    >
      <ng-container *ngFor="let breadcrumb of listBreadcrumbs; index as i">
        <div
          class="breadcrumb"
          [ngClass]="{ 'can-truncate': breadcrumb?.canTruncate, 'prevent-truncate': breadcrumb?.preventTruncate }"
          (click)="onClickBreadcrumb(breadcrumb)"
        >
          <ng-container *ngIf="!breadcrumb?.canEdit; else canEditField">
            <div class="breadcrumb-name">
              {{ breadcrumb?.name }}
            </div>
          </ng-container>
        </div>

        <ng-template #canEditField>
          <ng-container
            [ngTemplateOutlet]="!breadcrumb.isEditing ? viewMode : editMode"
            [ngTemplateOutletContext]="{ inputTextConfig: breadcrumb.inputTextConfig }"
          >
          </ng-container>
        </ng-template>
        <ng-template #viewMode>
          <div class="breadcrumb-name">
            {{ breadcrumb?.name }}
          </div>
          <ptg-button buttonName="" classInput="edit-button" (clickButton)="breadcrumb.isEditing = true">
            <mat-icon>edit</mat-icon>
          </ptg-button>
        </ng-template>
        <ng-template #editMode>
          <ptg-input-text
            [controlField]="controlField"
            [maxLength]="breadcrumb?.inputTextConfig?.maxLength"
            [placeholder]="breadcrumb?.inputTextConfig?.placeholder || ''"
            [hasLabel]="breadcrumb?.inputTextConfig?.hasLabel"
            [errorRequire]="breadcrumb?.inputTextConfig?.errorRequire"
            [errorAsync]="breadcrumb?.inputTextConfig?.errorAsync"
            [errorMaxLength]="breadcrumb?.inputTextConfig?.errorMaxLength"
          ></ptg-input-text>
          <div class="close-button-container">
            <ptg-button buttonName="" classInput="yesno-button" (clickButton)="onEditName()">
              <mat-icon>close</mat-icon>
            </ptg-button>
            <ptg-button buttonName="" classInput="yesno-button" (clickButton)="onEditName(true)">
              <mat-icon style="color: #196f57">check</mat-icon>
            </ptg-button>
          </div>
        </ng-template>

        <div *ngIf="listBreadcrumbs[i + 1]" class="bread-point"><mat-icon>chevron_right</mat-icon></div>
      </ng-container>
    </div>
    <div class="button-container-calendar" *ngIf="hasYearConfig">
      <ptg-button classInput="add-button" (clickButton)="onClickChangeDateView()">
        <mat-icon>chevron_left</mat-icon>
      </ptg-button>
    </div>
    <div class="button-container-calendar" *ngIf="hasYearConfig">
      <ptg-button classInput="add-button" (clickButton)="onClickChangeDateView(true)">
        <mat-icon>chevron_right</mat-icon>
      </ptg-button>
    </div>
    <div class="button-container" *ngIf="buttonAddLabel">
      <ptg-button [buttonName]="buttonAddLabel" classInput="add-button" (clickButton)="onClickAdd()">
        <mat-icon>add</mat-icon>
      </ptg-button>
    </div>
    <div class="button-container" [ngClass]="{ 'has-calendar-config': hasYearConfig }" *ngIf="buttonEditLabel">
      <ptg-button [buttonName]="buttonEditLabel" classInput="add-button" (clickButton)="onClickEdit()">
        <mat-icon>edit</mat-icon>
      </ptg-button>
    </div>
    <div class="button-container" *ngIf="buttonDeleteLabel">
      <ptg-button [buttonName]="buttonDeleteLabel" classInput="add-button delete-btn" (clickButton)="onClickDelete()">
        <mat-icon>delete_forever</mat-icon>
      </ptg-button>
    </div>

    <div class="button-container" *ngIf="functionButtons?.length">
      <ng-container *ngFor="let functionButton of functionButtons">
        <ptg-button
          class="button-name {{ functionButton.class }}"
          [buttonName]="functionButton?.buttonName"
          [classInput]="functionButton.classInput || ''"
          [isDisabled]="functionButton?.isDisabled"
          (clickButton)="onClickFunctionButton(functionButton.buttonName)"
        >
          <mat-icon *ngIf="!functionButton?.isSvgIcon; else svgIcon">{{ functionButton.icon }}</mat-icon>
          <ng-template #svgIcon>
            <mat-icon [svgIcon]="functionButton.icon" style="vertical-align: middle"></mat-icon>
          </ng-template>
        </ptg-button>
      </ng-container>
    </div>
  </div>
  <div *ngIf="stepperState" class="mat-stepper-container">
    <mat-stepper #stepper
                 (selectionChange)="selectionChange($event)"
                 [linear]="true"
                 [selectedIndex]="stepperState.selectedIndex">
      <mat-step *ngFor="let label of stepperState?.labels; let index = index"
                [aria-label]="(index <= stepperState.currentStep && stepperState.currentStep !== completedStep && !disableStepper && !stepperState.unClickableSteps?.includes(index) && !isCompleted) || (index === stepperState.currentStep && index === completedStep && !isCompleted) || (disableStepper && index === stepperState.currentStep && index !== completedStep && !isCompleted) || (index === stepperState.currentStep && stepperState.unClickableSteps?.includes(index) && !isCompleted) ? 'active-step' : 'inactive-step'"
                [completed]="index < stepperState.currentStep && stepperState.currentStep !== completedStep && !disableStepper && (stepperState.bypassUnclickableForCompleted ? true : !stepperState.unClickableSteps?.includes(index))"
                [editable]="index < stepperState.currentStep && stepperState.currentStep !== completedStep && !disableStepper && !stepperState.unClickableSteps?.includes(index)"
                [optional]="stepperState?.disabledSteps?.includes(index)"
                [state]="index < stepperState.currentStep || isCompleted ? 'done' : 'number'"
      >
        <ng-template matStepLabel>
          <div class="mat-step-text-label">{{ label }}</div>
          <div *ngIf="stepperState.optionalLabels && stepperState.optionalLabels[index]" class="mat-step-description">
            {{ stepperState.optionalLabels[index] }}
          </div>
        </ng-template>
      </mat-step>
    </mat-stepper>
  </div>
  <div class="item-right-breadcrumb flex">
    <ptg-select
      *ngIf="listColumnConfig?.length"
      [controlField]="editColumnForm.get('columnConfig')"
      (changeOptionValue)="onChangeColumnConfig()"
      [isMultipleLineOption]="true"
      [isOptionObj]="true"
      [listData]="listColumnConfig"
      class="full-width"
      placeholder=""
      [isSetMaxWidthSelect]="true"
    >
    </ptg-select>
    <div
      *ngIf="settings?.length && authService.Role === ADMIN_SYSTEM"
      class="setting-container"
      #tooltip="matTooltip"
      matTooltip="This feature is coming soon"
      matTooltipClass="custom-tooltip"
      [matTooltipDisabled]="isDisabledGear === false || isDisabledGear === undefined"
      matTooltipPosition="below"
    >
      <button mat-icon-button [matMenuTriggerFor]="menu" [disabled]="isDisabledGear" aria-label="Settings">
        <mat-icon>settings</mat-icon>
      </button>
      <mat-menu
        #menu="matMenu"
        [class]="noTruncateButton ? 'no-truncate' : panelClass ? panelClass : 'setting-menu'"
        backdropClass="panelClass"
      >
        <ng-container *ngFor="let setting of settings">
          <div
            #tooltip="matTooltip"
            matTooltip="This feature is coming soon"
            matTooltipClass="custom-tooltip"
            [matTooltipDisabled]="setting?.isDisabled === false || setting?.isDisabled === undefined"
            matTooltipPosition="below"
          >
            <button
              mat-menu-item
              (click)="selectSetting(setting)"
              [class]="'breadcrumb-navigation-menu-item ' + setting.classButton"
              [disabled]="setting?.isDisabled"
            >
              {{ setting?.name }}
            </button>
          </div>
        </ng-container>
      </mat-menu>
    </div>
    <div *ngIf="!(settings?.length && authService.Role === ADMIN_SYSTEM)"></div>
  </div>
</div>
