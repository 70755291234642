import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Store } from "@ngrx/store";
import { SubHeaderType } from "@ptg-member/constants";
import { SubHeaderItem, SubHeaderListOnDisplay } from "@ptg-member/features/calculation/services/models/subheader-configuration.model";
import { CalculationState, clearGetSubHeaderDataStateAction, getSubHeaderDataAction, getSubHeaderDataSelector } from "@ptg-member/features/calculation/store";
import { BaseComponent } from "@ptg-shared/components/base.component";
import { deepClone, isNumeric } from '@ptg-shared/utils/common.util';
import { filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ptg-sub-header',
  templateUrl: './sub-header.component.html',
  styleUrls: ['./sub-header.component.scss'],
})
export class SubHeaderComponent extends BaseComponent implements OnChanges {
  @Input() subHeaderLeftTitle?: string = 'Current Totals';
  @Input() memberId: string = '';
  @Input() menuItemTitle: string = '';
  @Input() menuItemSubTitle: string = '';
  @Input() outSideSubHeaderListOnDisplay?: SubHeaderListOnDisplay[];

  @Output() checkIsDisplay = new EventEmitter<boolean>();

  subHeaderList: SubHeaderItem[] = [];
  subHeaderListOnDisplay: SubHeaderListOnDisplay[] = [];

  constructor(
    private store: Store<CalculationState>,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.registerGetSubHeaderDataSelector();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.memberId || changes.menuItemTitle || changes.menuItemSubTitle) {
      this.checkOutSideSubHeaderList();
    }
  }

  private checkOutSideSubHeaderList() {
    if (!this.outSideSubHeaderListOnDisplay?.length) {
      this.getSubHeaderData();
      return;
    }
    this.subHeaderListOnDisplay = this.outSideSubHeaderListOnDisplay;
    this.checkIsDisplay.emit(this.subHeaderListOnDisplay.length > 0);
  }

  getSubHeaderData() {
    if (this.memberId) {
      this.store.dispatch(getSubHeaderDataAction({memberId: this.memberId}));
    }
  }

  registerGetSubHeaderDataSelector() {
    this.store.select(getSubHeaderDataSelector)
      .pipe(
        filter(state => !!state && !state.isLoading),
        map(state => state?.payload?.items ?? []),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((subHeaderList) => {
        this.store.dispatch(clearGetSubHeaderDataStateAction());
        this.subHeaderList = deepClone(subHeaderList);
        this.getCorrespondingDataFollowMenuItem(this.subHeaderList);
      });
  }

  getCorrespondingDataFollowMenuItem(subHeaderList: SubHeaderItem[]) {
    this.subHeaderListOnDisplay = subHeaderList
      .reduce((result, subHeader) => {
        // Case 'All' always have only 1 item
        if (subHeader?.menuItems?.[0]?.menuItem?.title === 'All') {
          result.push({
            label: subHeader?.menuItems?.[0]?.label,
            value: this.convertMenuItemValueFollowBusinessRules(subHeader) ?? null,
            subHeader: subHeader?.subHeader
          });
          return result;
        }
        const itemBelongThisMenu = subHeader.menuItems.find(menu => menu?.menuItem?.title === this.menuItemTitle && menu?.menuItem?.subTitle === this.menuItemSubTitle);
        if (!itemBelongThisMenu) {
          return result;
        }
        result.push({
          label: itemBelongThisMenu?.label ?? '',
          value: this.convertMenuItemValueFollowBusinessRules(subHeader) ?? null,
          subHeader: subHeader?.subHeader
        });
        return result;
      }, [] as SubHeaderListOnDisplay[]);

    this.checkIsDisplay.emit(this.subHeaderListOnDisplay.length > 0);
  }

  convertMenuItemValueFollowBusinessRules(item: SubHeaderItem): string {
    switch (item.subHeader) {
      case SubHeaderType.YearsOfService:
        if (item.value === null) {
          return '0Y / 0M / 0D';
        }
        return item.value;
      default:
        return item.value;
    }
  }
}
