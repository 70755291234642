import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { LIST_INPUT_ADDRESS, UnitedStates } from '@ptg-entity-management/constants';
import { LookupTableType } from '@ptg-shared/types/enums';
import { FieldData, FormControlDetail, OptionValue } from '@ptg-shared/types/models';
import { deepClone } from '@ptg-shared/utils/common.util';
import { stringToBoolean } from '@ptg-shared/utils/string.util';
import { MY_DATE } from '../datepicker/datepicker.component';

@Component({
  selector: 'ptg-input-address',
  templateUrl: './input-address.component.html',
  styleUrls: ['./input-address.component.scss']
})
export class InputAddressComponent implements OnInit, OnChanges {
  @Input() addressContainer = deepClone(LIST_INPUT_ADDRESS);
  selectedCountryCode = '';
  readonly MY_DATE = MY_DATE;
  readonly UnitedStates = UnitedStates;
  
  @Input() addressProperty: any = {};
  @Input() isAddressHistory?: boolean;
  @Input() isPayerInfo?: boolean;
  @Input() addressForm: any = new FormGroup({
    street1: new FormControl(),
    street2: new FormControl(),
    city: new FormControl(),
    state: new FormControl(),
    zipCode: new FormControl(),
    country: new FormControl(),
    effectFrom: new FormControl(),
    effectTo: new FormControl(),
  });
  @Input() isAddNew: boolean = false;
  @Input() removeEffectRange?: boolean = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.addressProperty) {
      const temp = {
        name: this.addressProperty.entityPropertyId,
        label: this.addressProperty.label,
        type: this.addressProperty.type,
        isRequired: this.addressProperty.configs?.required === 'true',
        data: this.addressProperty,
        configs: this.addressProperty.configs,
        options: this.addressProperty.options,
        messageError: this.addressProperty?.messageError
      } as FormControlDetail;
  
      this.addressProperty = temp;
    }
    this.initFormGroup();
  }

  ngOnInit(): void {
    this.addressForm.get('country')?.valueChanges.subscribe((el: string) => {
      this._countryChangeValue(el);
    });
  }

  initFormGroup() {
    this.addressContainer.forEach((field: FieldData) => {
      field.formControl = this.addressForm.get(field.key) as any ?? new FormControl(null);
      field.lstOption = [];
      if (this.addressProperty?.options?.length) {
        if (field.key === 'state') {
          this.selectedCountryCode = this.addressProperty?.options.find((item: any) => item.id === this.addressForm?.value?.country)?.description;
          if (this.selectedCountryCode && !['USA', 'CA', 'CAN'].includes(this.selectedCountryCode)) {
            field.type = 'text';
            field.formControl?.removeValidators(Validators.required);
            const state = this.addressProperty.options.find((el: any) => el.id === this.addressForm?.value?.state)?.text ?? this.addressForm?.value?.state;
            field.formControl?.setValue(state);
          }
          field.lstOption = this.addressProperty.options
            .filter(
              (option: OptionValue) =>
                option.type === LookupTableType.State
            )
            .map((option: OptionValue) => ({
              displayValue: option.text,
              value: option.id,
            }));
        } else if (field.key === 'country') {
          field.lstOption = this.addressProperty.options
            .filter(
              (option: OptionValue) =>
                option.type === LookupTableType.Country
            )
            .map((option: OptionValue) => ({
              displayValue: option.text,
              value: option.id,
            }));
          if (!this.addressForm?.value?.country) {
            const defaultCountry = this.addressProperty.options.find((item:OptionValue) => item.description === this.UnitedStates)?.id;
            field?.formControl?.setValue(defaultCountry ? defaultCountry : null);
          }
        } else if (field.key === 'zipCode') {
          this.selectedCountryCode = this.addressProperty?.options.find((item: any) => item.id === this.addressForm?.value?.country)?.description;
          if (this.selectedCountryCode && !['USA', 'CA', 'CAN'].includes(this.selectedCountryCode)) {
            field?.formControl?.clearValidators();
            field?.formControl?.setErrors(null);
            field?.formControl?.updateValueAndValidity();
          }
        }
      }

      if (stringToBoolean(this.addressProperty?.configs?.readOnly)) {
        field.formControl?.disable();
      }
    });
    if (!this.removeEffectRange) {
      const fromElement = this.addressForm.get('effectFrom');
      const toElement = this.addressForm.get('effectTo');
      fromElement?.addValidators(this._effectiveDate(fromElement, toElement));
      toElement?.addValidators(this._effectiveDate(fromElement, toElement));
    }
    
    if (this.addressForm?.value?.country) {
      this.selectedCountryCode = this.addressProperty?.options.find((item: any) => item.id === this.addressForm?.value?.country)?.description;
      this._countryChangeValue(this.addressForm?.value?.country, true);
    }
  }

  private _countryChangeValue(el: string, isInit: boolean = false) {    
    this.selectedCountryCode = this.addressProperty?.options?.find((item: any) => item.id === el)?.description;
    const state = this.addressContainer.find(el => el.key === 'state');
    const zipCode = this.addressContainer.find(el => el.key === 'zipCode');
    if (state && !['USA', 'CA', 'CAN'].includes(this.selectedCountryCode)) {
      state.type = 'text';
      state.formControl?.removeValidators(Validators.required);
      state?.formControl?.updateValueAndValidity();
      if (!this.isPayerInfo) {
        zipCode?.formControl?.clearValidators();
        zipCode?.formControl?.setErrors(null);
        zipCode?.formControl?.updateValueAndValidity();
      }
    } else if (state && state.type === 'text') {
      state.type = 'select';
      state.formControl?.setValue(null);
    }

    if (['CA', 'CAN'].includes(this.selectedCountryCode)) {
      zipCode?.formControl?.removeValidators(Validators.required);
      const error = zipCode?.formControl?.errors;
      delete error?.required;
      zipCode?.formControl?.setErrors(error || null);
    }

    if (state && !isInit) {
      state.formControl?.setValue(null);
    }
  }

  private _effectiveDate(fromElement: any, toElement: any) {
    return (c: AbstractControl) => {
      let fromElementCompare = fromElement?.value ? ((typeof fromElement?.value === 'string') ? new Date(fromElement?.value) : fromElement?.value) : '';
      let toElementCompare = toElement?.value ? ((typeof toElement?.value === 'string') ? new Date(toElement?.value) : toElement?.value) : '';
      return (fromElementCompare 
        && toElementCompare
        && fromElementCompare >= toElementCompare) ? {fromToValidation: 'System does not allow Effective From is equal OR greater than Effective To.'} : null;
    };
  }

  dateEffectiveChange() {
    this.addressForm.get('effectFrom')?.updateValueAndValidity();
    this.addressForm.get('effectTo')?.updateValueAndValidity();
  }
}
