
export interface PayrollRegister extends PayrollRegisterSubRecord {
  detail?: PayrollRegisterSubRecord[];
  payrollRunId: string;
  payableDate?: string;
  depositDate?: string;
  checkDate?: string;
  totalPayees: number;
  status: number;
  cancelledBy?: string;
  cancelledAt?: string;
  hasOneTimePayment?: boolean;
  createdDate: string;
}

export interface PayrollRegisterListResponse {
  total: number;
  isIncludeOTPIntoPR?: boolean;
  hasCancelledRun?: boolean;
  payrollRegisters: PayrollRegister[];
}

export interface PayrollRegisterSubRecordResponse {
  recurringSubRecord: PayrollRegisterSubRecord;
  oneTimeSubRecord: PayrollRegisterSubRecord;
}

export interface PayrollRegisterSubRecord {
  batchType: number | null;
  calendarName: string;
  startDate: string;
  endDate: string;
  totalPayments: number;
  totalGross: number;
}
