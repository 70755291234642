<div
  *ngIf="bannerType"
  [ngClass]="{
    success: bannerType === 'Success',
    fail: bannerType === 'Fail',
    warning: bannerType === 'Warning',
    info: bannerType === 'Info'
  }"
  [ngStyle]="style"
  class="banner"
>
  <div class="banner-content">
    <ng-container *ngIf="bannerType">
      <mat-icon
        *ngIf="!isLoadingInfo; else loadingIcon"
        [ngClass]="bannerIconConfig?.className || ''"
        class="banner-icon"
      >
        {{ bannerIconConfig?.icon }}
      </mat-icon>
      <ng-template #loadingIcon>
        <mat-icon
          svgIcon="loadingIcon"
          [ngClass]="bannerIconConfig?.className || ''"
          class="banner-icon"
        ></mat-icon>
      </ng-template>
      <span class="message" [innerHTML]="message">{{ message }}</span>
    </ng-container>
    <ng-content></ng-content>
  </div>
  <mat-icon
    (click)="closeAlert()"
    *ngIf="!isHideCloseButton"
    class="close-icon"
  >
    close
  </mat-icon>
</div>
