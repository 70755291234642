<div class="note-container">
  <div class="button-container flex history-view">
    <div class="title-payment">
      <ul>
        <li>{{ displayTitle }}</li>
        <li *ngIf="!isPaymentLumpsum && !isLumpsumBenefit">
          <div class="d-flex">
            <span>Benefit Period: {{ paymentInfo?.benefitPeriod }}</span>
            <ptg-button
              *ngIf="isShowEditButtonBP && selectedTabPayment === TabPaymentInfo.Payment"
              buttonName="Edit"
              classInput="edit-button"
              (clickButton)="onEditBenefitPeriodPayableDate(false)"
              ><mat-icon>edit</mat-icon>
            </ptg-button>
            <!-- Out scope in release 1 -->
            <!-- <ptg-button
              *ngIf="selectedTabPayment === TabPaymentInfo.InstructionHistory"
              buttonName="Audit"
              classInput="audit-btn"
              (clickButton)="onEditBenefitPeriodPayableDate(false)"
              ><mat-icon>history</mat-icon>
            </ptg-button> -->
          </div>
        </li>
        <li *ngIf="!isAdjustmentType">
          <div class="d-flex">
            <span> Payable Date: {{ objDate.payableDate === '-' ? objDate.payableDate : (objDate.payableDate | date: 'MM/dd/yyyy') }} </span>
            <ptg-button
              *ngIf="isShowEditButtonPD && selectedTabPayment === TabPaymentInfo.Payment"
              buttonName="Edit"
              classInput="edit-button"
              (clickButton)="onEditBenefitPeriodPayableDate(true)"
              ><mat-icon>edit</mat-icon>
            </ptg-button>
            <!-- Out scope in release 1 -->
            <!-- <ptg-button
              *ngIf="selectedTabPayment === TabPaymentInfo.InstructionHistory"
              buttonName="Audit"
              classInput="audit-btn"
              (clickButton)="onEditBenefitPeriodPayableDate(false)"
              ><mat-icon>history</mat-icon>
            </ptg-button> -->
          </div>
        </li>
        <ng-container *ngIf="isAdjustmentType">
          <li *ngIf="paymentInfo?.adjustmentInfoType === AdjustmentType.DetailedAdjustment">
            <a
              class="cursor-pointer underline original-adjustment"
              (click)="
                navigateToOriginal(
                  $event,
                  { paymentInstructionHistoryId: paymentInfo?.paymentInstructionHistoryId },
                  PositionPaymentInfoTabDetailedQueryType.PaymentInstructionHistory
                )
              "
            >
              <span>Original Payment: </span>
              <span class="mr-1">{{ originalPaymentText }}</span>
              <span *ngIf="objDate.originalCheckDate; else originalDeposit"
                >{{ objDate.originalCheckDate | date: 'MM/dd/yyyy' }} <i>(Check Date)</i></span
              >
              <ng-template #originalDeposit>
                <span>{{ objDate.originalDepositDate | date: 'MM/dd/yyyy' }} <i>(Deposit Date)</i></span>
              </ng-template>
            </a>
          </li>

          <li *ngIf="objDate.revertDate">
            <a
              class="cursor-pointer underline original-adjustment"
              (click)="navigateToOriginal($event, { adjustmentId: paymentInfo?.revertedId })"
            >
              <span>Reversal of Adjustment </span>
              <span class="mr-1">{{ objDate.revertDate | date: 'MM/dd/yyyy' }}(Created Date)</span>
            </a>
          </li>
        </ng-container>
      </ul>
    </div>
    <div *ngIf="!isAdjustmentType && selectedTabPayment === TabPaymentInfo.Payment">
      <div class="setting-container">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>note_add</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="add-menu">
          <button mat-menu-item (click)="onClickAdd(PaymentInfoNoteType.Note)" class="add-menu-item">Note</button>
          <button
            *ngIf="paymentInfo?.payStatus !== PAY_STATUS.Finalized && isDisplayAddEditRemoveMemo"
            mat-menu-item
            (click)="onClickAdd(PaymentInfoNoteType.Memo)"
            class="add-menu-item"
          >
            Memo
          </button>
        </mat-menu>
      </div>
    </div>
    <!-- Out scope in release 1 -->
    <!-- <div *ngIf="selectedTabPayment === TabPaymentInfo.InstructionHistory" class="audit-btn">
      <mat-icon>history</mat-icon>
      <span class="title-edit">Audit</span>
    </div> -->
  </div>
  <div *ngIf="selectedTabPayment === TabPaymentInfo.Payment">
    <ng-container *ngIf="!isLoading; else loadingTemplate">
      <ng-container
        *ngIf="(!isAdjustmentType && dataNotes.length) || (isAdjustmentType && dataNotes.length && dataNotes[0].note)"
      >
        <div *ngFor="let item of dataNotes">
          <div class="note">
            <div class="created-info">
              <div>
                <mat-icon>{{ item.type === PaymentInfoNoteType.Note ? 'insert_drive_file' : 'price_change' }}</mat-icon>
                <div class="created-date">
                  <span class="label-bold">{{ item.type === PaymentInfoNoteType.Note ? 'Note' : 'Memo' }}</span>
                  <span class="label-bold">{{ item?.postedDate | date: 'MM/dd/yyyy hh:mm a' }}</span>
                </div>
                <span class="label">{{ item?.updatedBy ? 'Last Updated By: ' : 'Created by: ' }}</span>
                <span class="value">{{ item?.createdBy }}</span>
              </div>
              <div
                class="btn-right"
                *ngIf="
                  (item.type === PaymentInfoNoteType.Note ||
                    (item.type === PaymentInfoNoteType.Memo && isDisplayAddEditRemoveMemo)) &&
                  !isAdjustmentType
                "
              >
                <ptg-button buttonName="Edit" classInput="button" (clickButton)="onClickAdd(item.type, item)">
                  <mat-icon>edit</mat-icon>
                </ptg-button>
              </div>
            </div>
            <div class="note-content">
              <pre [ngClass]="{ 'line-clamp-2': item?.isTruncate }" ptgTruncateText>{{ item.note }}</pre>
              <ng-container *ngIf="item.isTruncate; else noTruncate">
                <span (click)="item.isTruncate = false">See more</span>
              </ng-container>
              <ng-template #noTruncate>
                <span (click)="item.isTruncate = true">See less</span>
              </ng-template>
            </div>
          </div>
        </div>

        <ptg-pagination
          #paginator
          *ngIf="!isAdjustmentType"
          [totalRecords]="lengthPg"
          (pageChange)="changePaging($event)"
          [pageSize]="pageSize"
          [pageNumber]="pageNumber"
          [hiddenPageSizeOptions]="true"
        ></ptg-pagination>
      </ng-container>
    </ng-container>
    <ng-template #loadingTemplate>
      <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
    </ng-template>
  </div>
</div>
