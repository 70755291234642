<div class="dialog-container" id="add-accident-claim-dialog" *ngIf="!isLoading; else loadingTemplate">
  <div class="header-title">{{ data?.accidentClaim ? 'Edit' : 'Add' }} Accident Claim</div>
  <form [formGroup]="editForm" (ngSubmit)="formSubmit$.next()">
    <div class="form-container">
      <div class="select-choose-vendor">
        <ptg-radio-button
          [controlField]="editForm.get('chooseVendor')"
          [listOption]="optionsChooseVendor"
          [isObject]="true"
          (changeValue)="changeChooseVendor($event)"
        ></ptg-radio-button>
      </div>
      <div class="input-group group-vendor flex gap-3">
        <div class="form-control-select vendor-select" *ngIf="!isNewVendor">
          <mat-form-field class="auto-complete" appearance="fill">
            <mat-label>Vendor Name</mat-label>
            <input type="text" matInput [formControl]="vendorIdControl" [matAutocomplete]="auto" (blur)="validateVendor()" >
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onVendorChangedValue()">
              <mat-option *ngFor="let option of listVendorSearch | async" [value]="option">
                {{option.displayValue}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="!vendorIdControl?.errors?.required && vendorIdControl?.errors?.inValidAsync">Value must be selected from result list.
            </mat-error>
            <mat-error *ngIf="vendorIdControl?.errors?.required">Vendor Name is required.</mat-error>
          </mat-form-field>
        </div>
        <ptg-textbox *ngIf="isNewVendor"
          [controlField]="editForm.get('vendorName')"
          placeholder="Vendor Name"
          [maxLength]="250"
          class="w-30"
          [hasLabel]="true"
          [isRequired]="true"
        ></ptg-textbox>
        <div class="vendor-number w-30" *ngIf="isNewVendor">
          <div class="label">Vendor Number</div>
          <div class="value">{{greatestVendorNumber ?? '-'}}</div>
        </div>
        <div class="vendor-number w-30" *ngIf="!isNewVendor">
          <div class="label">Vendor Number</div>
          <div class="value">{{vendorNumber ?? '-'}}</div>
        </div>
        <div class="w-30">&nbsp;</div>
      </div>
      <div class="input-group group-vendor flex gap-3">
        <div class="form-control-select vendor-select">
          <mat-form-field class="auto-complete" appearance="fill">
            <mat-label>Warrant Register Number</mat-label>
            <input type="text" matInput [formControl]="warrantRegisterNumberControl" [matAutocomplete]="auto" (blur)="validateWarrantRegisterNumber()">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onWarrantRegisterNumberChangedValue()">
              <mat-option *ngFor="let option of listWarrantRegisterNumberSearch | async" [value]="option">
                {{option.displayValue}}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="!warrantRegisterNumberControl?.errors?.required && warrantRegisterNumberControl?.errors?.inValidAsync">Value must be selected from result list.
            </mat-error>
            <mat-error *ngIf="warrantRegisterNumberControl?.errors?.required">Warrant Register Number is required.</mat-error>
          </mat-form-field>
        </div>
        <div class="vendor-number w-30">
          <div class="label">Warrant Batch Number</div>
          <div class="value">{{warrantBatchNumber ?? '-'}}</div>
        </div>
        <div class="vendor-number w-30">
          <div class="label">Payment Date</div>
          <div class="value">{{(paymentDate | date : "MM/dd/yyyy") ?? '-'}}</div>
        </div>
      </div>

      <div class="input-group flex gap-3">
        <ptg-select
          [placeholder]="'Disbursement Type'"
          [controlField]="editForm.get('disbursementType')"
          [listData]="listDisbursementType"
          [isSetMaxWidthSelect]="true"
          [isMultipleLineOption]="true"
          class="w-30"
          [onlyTruncateTitle]="true"
          [isRequired]="true"
          [isOptionObj]="true"
          [isShowValue]="true"
        ></ptg-select>
        <ptg-textbox
          [controlField]="editForm.get('paymentNumber')"
          placeholder="Payment Number"
          [isPositive]="true"
          [allowZero]="true"
          [hasLabel]="true"
          [maxLength]="250"
          class="w-30"
          [isDecimal]="false"
          [checkMinMaxValue]="false"
          customError="errorMessagePaymentNumber"
        ></ptg-textbox>
        <ptg-textbox
          [controlField]="editForm.get('amount')"
          placeholder="Claim Amount"
          [min]="0.00"
          [max]="9999999999999.99"
          [maxLength]="16"
          inputType="Currency"
          class="w-30"
          errorMinMaxValue="Claim Amount must be within the range of 0.00 - 9,999,999,999,999.99."
          [isPositive]="true"
          [allowZero]="true"
          [hasLabel]="true"
          [allowNegativeNumbers]="false"
        ></ptg-textbox>
      </div>
    </div>
    <div class="row-button">
      <button class="submit-button" mat-raised-button type="submit">Save</button>
      <button (click)="onCancel()" class="cancel-button" mat-raised-button type="button">Cancel</button>
    </div>
  </form>
</div>
<ng-template #loadingTemplate>
  <div class="loading-section">
    <ptg-spinner [isLoading]="isLoading"></ptg-spinner>
  </div>
</ng-template>
