import { createSelector } from '@ngrx/store';
import { selectBuyBackMakeUpModuleState } from './selector';

export const buyBackMakeUpSelector = createSelector(
  selectBuyBackMakeUpModuleState,
  (state) => state?.buyBackMakeUp,
);

export const getListBuyBackMakeUpSelector = createSelector(
  buyBackMakeUpSelector,
  (state) => state?.getListBuyBackMakeUpState
);

export const getListBuyBackMakeUpDetailSelector = createSelector(
  buyBackMakeUpSelector,
  (state) => state?.getListBuyBackMakeUpDetailState
);

export const removeBuyBackMakeUpSelector = createSelector(
  buyBackMakeUpSelector,
  (state) => state?.removeBuyBackMakeUpState
);

export const getEligibleYearSelector = createSelector(
  buyBackMakeUpSelector,
  (state) => state?.getEligibleYearState
);

export const getListMunicipalitySelector = createSelector(
  buyBackMakeUpSelector,
  (state) => state?.getListMunicipalityState
);

export const addBuyBackMakeUpSelector = createSelector(
  buyBackMakeUpSelector,
  (state) => state?.addBuyBackMakeUpState
);

export const editBuyBackMakeUpSelector = createSelector(
  buyBackMakeUpSelector,
  (state) => state?.editBuyBackMakeUpState
);

export const initiateRemittanceBuyBackMakeUpSelector = createSelector(
  buyBackMakeUpSelector,
  (state) => state?.initiateRemittanceBuyBackMakeUpState
);

export const editBuyBackMakeUpInterestFeesSelector = createSelector(
  buyBackMakeUpSelector,
  (state) => state?.editBuyBackMakeUpInterestFeesState
);

export const getBuyBackMakeUpYearRecordSelector = createSelector(
  buyBackMakeUpSelector,
  (state) => state?.getBuyBackMakeUpYearRecordState
);

export const getBuyBackMakeUpInterestFeesCalculationSelector = createSelector(
  buyBackMakeUpSelector,
  (state) => state?.getBuyBackMakeUpInterestFeesCalculationState
);

export const validateDoingBuyBackMakeUpSelector = createSelector(
  buyBackMakeUpSelector,
  (state) => state?.validateDoingBuyBackMakeUpState
);
