import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DEFAULT_NOT_FOUND_MSG, Row } from '@ptg-shared/controls/grid';
import { AdjustmentType, ChipType, PaymentTransactionStatus, ReissueState, TransactionRegister, TransactionType } from '../../services/models/register.model';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { PaymentType } from '@ptg-processing/features/payroll-calendar-container/types/enums/payroll-deduction-payee.enum';
import { MatDialog } from '@angular/material/dialog';
import { RegisterDataPopupComponent } from '../register-data-popup/register-data-popup.component';
import { PaymentInstructionType } from '@ptg-member/features/payee-detail/types/enums';
import { ViewMarkReturnedRejectedComponent } from '../view-mark-returned-rejected/view-mark-returned-rejected.component';
import { ReissueTransactionComponent } from '../reissue-transaction/reissue-transaction.component';
import { AccountType } from '@ptg-shared/types/models/common.model';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { filter, take, takeUntil } from 'rxjs/operators';
import { BaseComponent } from '@ptg-shared/components';
import { DATE_FORMAT } from '@ptg-shared/constance';
import { select, Store } from '@ngrx/store';
import { LayoutActions } from '@ptg-shared/layout/actions';
import * as fromReducer from '@ptg-reducers';
import { ActivatedRoute, Router } from '@angular/router';
import { DisplayedTabName, STRING_QUERY_PARAM } from '@ptg-shared/layout/constance/layout.const';
import * as fromLayoutReducer from '@ptg-shared/layout/reducers';
import { PayrollRunState } from '@ptg-processing/features/payroll/store/reducers';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';
import { MenuItem, Menu, ViewFixType } from '@ptg-shared/layout/models/layout.model';
import { RegisterService } from '@ptg-shared/transaction/services/register.service';

@Component({
  selector: 'ptg-register-expanded',
  templateUrl: './register-expanded.component.html',
  styleUrls: ['./register-expanded.component.scss'],
})
export class RegisterExpandedComponent extends BaseComponent {
  readonly AccountType = AccountType;
  readonly AdjustmentType = AdjustmentType;
  readonly PaymentInstructionType = PaymentInstructionType;
  readonly PaymentTransactionStatus = PaymentTransactionStatus;
  readonly PaymentType = PaymentType;
  readonly TransactionType = TransactionType;
  readonly ChipType = ChipType;
  readonly DATE_FORMAT = DATE_FORMAT;
  readonly ReissueState = ReissueState;

  @Input() registerData: (TransactionRegister & Row)[] = [];
  @Input() pageNumber: number = FIRST_PAGE;
  @Input() totalRecords: number = 0;
  @Input() pageSize: number = 50;
  @Input() pageSizeOptions: number[] = [10, 20, 30, 40, 50, 100, 200];
  @Input() hiddenPageSizeOptions: boolean = false;
  @Input() maxPages: number = 5;
  @Output() pageChange = new EventEmitter<PageEvent>();
  @Input() notFoundMessage: string = DEFAULT_NOT_FOUND_MSG;
  @Input() errorMessage?: string;
  @Input() isLoading?: boolean;
  @Input() sidebarOpened?: boolean;
  @Input() showErrorPopup?: (event: TransactionRegister) => void;
  @Input() payeeRecordId?: string;
  @Input() maxLength: number = 1;
  @Input() isConfigOST?: boolean = false;
  @Output() showBanner = new EventEmitter<{ bannerType: BannerType; message: string }>();

  isFromSpecificMember: boolean = false;

  constructor(private dialog: MatDialog,
     private store: Store<fromReducer.State>,
     private router: Router,
     private payrollRunStore: Store<PayrollRunState>,
     private registerService: RegisterService,
     public route: ActivatedRoute
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.params
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((params) => {
      this.isFromSpecificMember = !!params.memberId
    });
  }

  //#region Handle for pagination
  onChangePage(event: PageEvent): void {
    this.pageChange.emit(event);
  }
  //#endregion

  openMemoPopup(transaction: TransactionRegister, label: string) {
    this.dialog.open(RegisterDataPopupComponent, {
      panelClass: 'expanded-data-popup',
      width: '1000px',
      autoFocus: false,
      data: { transaction, label },
    });
  }

  onClickReturnReject(row: TransactionRegister) {
    const openDialog = (isHideSuspendToggle = true) => {
      const dialog = this.dialog.open(ViewMarkReturnedRejectedComponent, {
        panelClass: ['dialog-full-screen'],
        autoFocus: false,
        disableClose: true,
        data: {
          registerData: row,
          payeeRecordId: this.payeeRecordId,
          maxLengthCheckNumber: this.maxLength,
          isConfigOST: this.isConfigOST,
          isHideSuspendToggle
        },
      });
      dialog
        .afterClosed()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((result?: { success: boolean; error: boolean }) => {
          if (result?.success) {
            this.showBanner.emit({ bannerType: BannerType.Success, message: 'Transaction successfully voided.' });
          }
          if (result?.error) {
            this.showBanner.emit({
              bannerType: BannerType.Fail,
              message: 'Error occurred voiding transaction. Please try again.',
            });
          }
        });
    }
    if (row.payeeRecordId) {
      this.registerService.checkConditionDisplaySuspendToggle(row.payeeRecordId || '').subscribe(el => {
        if (el) {
          openDialog(el.allPayeePisClosedOrCancelled);
        }
      });
    } else {
      openDialog();
    }
  }

  onClickReissue(row: TransactionRegister) {
    const dialog = this.dialog.open(ReissueTransactionComponent, {
      panelClass: ['dialog-full-screen'],
      autoFocus: false,
      disableClose: true,
      data: {
        registerData: row,
        payeeRecordId: this.payeeRecordId,
        maxLengthCheckNumber: this.maxLength,
        isConfigOST: this.isConfigOST,
      },
    });
    dialog.afterClosed().pipe(takeUntil(this.unsubscribe$)).subscribe(el => {
      if (el?.success && row.transactionType === TransactionType['Deduction Payee']) {
        this.showBanner.emit({ bannerType: BannerType.Success, message: 'Transaction successfully reissued.' });
      }
    })
  }

  formatStringForCheckNumber(value: number, endingChar?: any){
    if (!endingChar) {
      return value; 
    }
    let numericPart = value?.toString()?.trim().replace(endingChar, '');
    let paddedNumericPart = numericPart.padStart(this.maxLength, '0');
    return paddedNumericPart + endingChar;
  }

  navigateToProfile(data: any) {
    this.store.dispatch(
      LayoutActions.profileNavigationItemRequest({
        memberId: data?.payeeRecordId,
        entityReferenceLinkedId: data?.payeeEntityId,
        isHyperlink: true
      })
    );

    this.store
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state?.isHyperlink && state?.menu && state?.menu.length > 0 && data?.payeeRecordId) {
          const overviewView = state.memberNavigationList as any;
          const isOverviewDetailView = (state.memberNavigationList as any)?.isOverviewDetailView;
          const url = `${isOverviewDetailView ? '/member/detail-view/true' : '/member/summary-view/true'}/${overviewView.id}/${overviewView.overviewViewId}/${data?.payeeRecordId}?${STRING_QUERY_PARAM.PROFILE_NAVIGATE}&entityReferenceLinkedId=${data.payeeEntityId}`;
          const entityReferenceLinkedIdParam = new URLSearchParams(document.location.search).get("entityReferenceLinkedId");

          if(url.slice(0, url.indexOf('?')) === document.location.pathname
            && data.entityReferenceLinkedId === entityReferenceLinkedIdParam) {
              this.router.navigateByUrl(url);
            }
          else{
            this.store.dispatch(LayoutActions.clearProfileNavigationItemState());
            this.store.dispatch(
              LayoutActions.selectTab({
                tab: DisplayedTabName.IndividualParticipant,
                url,
              }),
            );
          }
        }
      });
  }

  onRelatedTransactionClick(row: any) {
    if (row.isDeductionPayee) {
      return;
    }
    this.payrollRunStore.dispatch(
      LayoutActions.profileNavigationItemRequest({
        memberId: row.relatedPayeeRecordId,
        entityReferenceLinkedId: row.relatedPayeeEntityId,
      }),
    );

    this.payrollRunStore
      .pipe(select(fromLayoutReducer.selectProfileNavigationState), filter(state=> !!state.isReloading && !!state.memberId), take(1), takeUntil(this.unsubscribe$))
      .subscribe((state) => {
          let listMenuItem: MenuItem[] = [];
          state.menu.forEach((item: Menu) => (listMenuItem = listMenuItem.concat(item.menuItems || [])));
          const nextPaymentMenu = listMenuItem.find((item) => item?.viewFixType === ViewFixType.PaymentInformation);
          
          const payrollRegisterParam = !!row.relatedPaymentInstructionHistoryId
            ? `&instructionHistoryId=${row.relatedPaymentInstructionHistoryId}`
            : '';
          if (nextPaymentMenu) {
            let url = `${nextPaymentMenu.routerLink}${state.memberId}?isHistory=${!!row.relatedPaymentInstructionHistoryId}`;
            const params = `&instructionId=${row.relatedPaymentInstructionId}&entityReferenceLinkedId=${row.relatedPayeeEntityId}${payrollRegisterParam}&benefitId=${row.relatedBenefitId}`;
            url += params;

            this.payrollRunStore.dispatch(
              LayoutActions.selectTab({
                tab: DisplayedTabName.IndividualParticipant,
                url,
              }),
            );
          } else {
            const overViewMenu = listMenuItem.find((item) => item?.isOverView);

            if (!overViewMenu) {
              this.dialog.open(ConfirmPopupComponent, {
                panelClass: 'confirm-popup',
                data: {
                  text: 'Screen not yet configured. Please contact your administrator for support.',
                  title: 'Warning',
                  cancelButtonTitle: 'Close',
                  hideConfirmButton: true,
                },
              });
              return;
            }
          }
      });
  }
}
