import { Component, Inject } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormControlOptions,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { InputType } from '@ptg-member/constance/metadataPropertyType.const';
import {
  AdjustmentDeductionType,
  CourtOrderDeduction,
  DatePeriod,
  DeductionUpdateData,
  EditDeductionsDialogData,
  SetDeductionsRequest,
  SubTypeDeduction,
  TypesOfDeduction,
} from '@ptg-member/features/payee-detail/services/models';
import {
  clearGetTypesOfDeductionsStateAction,
  clearSetDeductionsStateAction,
  getTypesOfDeductionsAction,
  getTypesOfDeductionsSelector,
  PayeeDetailState,
  setDeductionsAction,
  setDeductionsSelector,
} from '@ptg-member/features/payee-detail/store';
import { DeductionType, TaxSettingType } from '@ptg-member/features/payee-detail/types/enums';

import { BaseComponent } from '@ptg-shared/components';
import { ACTION, GUID_EMPTY, STATE } from '@ptg-shared/constance';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { MY_DATE } from '@ptg-shared/controls/datepicker/datepicker.component';
import { RadioOption } from '@ptg-shared/controls/radio-button/radio-button.component';
import { Option } from '@ptg-shared/controls/select/select.component';
import { AbstractControlStatus } from '@ptg-shared/types/models/common.model';
import { deepClone, parseDateToSubmit, showBanner, showCancelDialog, updateAllValueAndValidity } from '@ptg-shared/utils/common.util';
import { getDateString, isEmpty, localeCompare } from '@ptg-shared/utils/string.util';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { filter, map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators';

type RowDeductionUnionType = SubTypeDeduction | CourtOrderDeduction | AdjustmentDeductionType;

@Component({
  selector: 'ptg-edit-deductions',
  templateUrl: './edit-deductions.component.html',
  styleUrls: ['./edit-deductions.component.scss'],
})
export class EditDeductionsComponent extends BaseComponent {
  readonly DeductionType = DeductionType;
  readonly InputType = InputType;

  editForm: FormGroup = new FormGroup({});
  formSubmit$ = new Subject<boolean>();

  typesOfDeductions: TypesOfDeduction[] = [];
  minValueForCurrency = 0;
  maxValueForCurrency = 999999999999.99;
  minValueForCurrencyAdjustment = 0.01;
  minAdjustmentDeductionStartDate = MY_DATE.validMinDate;
  postOrPretaxOptions: RadioOption[] = [
    {
      label: 'Pre Tax',
      value: true,
    },
    {
      label: 'Post Tax',
      value: false,
    },
  ];

  isLoading: boolean = true;
  bannerType: BannerType = BannerType.Hidden;
  message: string = '';
  isDisabledSaveBtn: boolean = true;

  isShownAdjustmentSection = true;

  constructor(
    public dialogRef: MatDialogRef<EditDeductionsComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: EditDeductionsDialogData,
    private payeeDetailStore: Store<PayeeDetailState>,
    private fb: FormBuilder,
  ) {
    super();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.formSubmit$.complete();
  }

  ngOnInit(): void {
    this.refreshMinStartDate();

    this.selectGetTypesOfDeductionsState();
    this.checkSetDeductionsState();
    this.getTypesOfDeductions();
    this.checkFormSubmit();
  }

  checkFormSubmit(): void {
    this.formSubmit$
      .pipe(
        tap(() => {
          this.editForm.markAllAsTouched();
          updateAllValueAndValidity(this.editForm);
          this.editForm.updateValueAndValidity();
          this.isLoading = true;
        }),
        switchMap(() =>
          this.editForm.statusChanges.pipe(
            startWith(this.editForm.status),
            filter((status) => status !== AbstractControlStatus.PENDING),
            take(1),
          ),
        ),
        filter((status) => {
          if (status === AbstractControlStatus.INVALID) {
            this.isLoading = false;
          }
          return status === AbstractControlStatus.VALID;
        }),
      )
      .subscribe(() => {
        this.onSave();
      });
  }

  private onSave(): void {
    const formValue = deepClone(this.editForm.getRawValue());
    const listInforOfDeduction: DeductionUpdateData[] = Object.values(formValue).reduce(
      (result: DeductionUpdateData[], current: any) => {
        const deductionItem = deepClone(current.deductionSubTypes).map((item: any) => {
          item.startDate && (item.startDate = parseDateToSubmit(item.startDate));
          item.endDate && (item.endDate = parseDateToSubmit(item.endDate));
          item.amount = Number(item.amount);
          return item;
        });
        deductionItem.deductionType = Number(current.deductionSubTypes);
        result = [...result, ...deductionItem];
        return result;
      },
      [] as DeductionUpdateData[],
    );
    const savedDeductionItems = this.typesOfDeductions.reduce((result, deduction) => {
      if (
        deduction.deductionSubTypes?.length ||
        deduction.courtOrderDeductions?.length ||
        deduction.adjustmentDeductions?.length
      ) {
        const addedItems = (
          (deduction.deductionSubTypes ||
            deduction.courtOrderDeductions ||
            deduction.adjustmentDeductions?.filter((item) => this.isAdjustmentDeductionValidToRetrieved(item)) ||
            []) as any[]
        )
          ?.filter((item) => !!item.payrollBenefitDeductionId && item.payrollBenefitDeductionId !== GUID_EMPTY)
          ?.map((item) => ({ ...item, deductionType: deduction.deductionType }));
        result = (result as any[]).concat(addedItems);
      }
      return result;
    }, [] as DeductionUpdateData[]);
    savedDeductionItems.forEach((savedDeductionItem) => {
      if (
        !listInforOfDeduction
          .filter(
            (item) =>
              !this.isHideExpiredDeductions(
                savedDeductionItem.deductionType,
                item.isLinkedToFinalizedPayment,
                item.endDate,
              ),
          )
          .find((item) => item.payrollBenefitDeductionId === savedDeductionItem.payrollBenefitDeductionId)
      ) {
        listInforOfDeduction.push({
          ...deepClone(savedDeductionItem),
          isDeleted: true,
        });
      }
    });
    // Remove suffix element in id of record === GUID_EMPTY
    listInforOfDeduction.forEach((el: any) => {
      if (el?.payrollDeductionId?.includes(':')) {
        el.payrollDeductionId = el.payrollDeductionId.split(':')[0];
      }
      // add new adjustment should use empty payroll deduction
      if (!el.payrollDeductionId && this.isAdjustmentDeductionType(el.deductionType)) {
        el.payrollDeductionId = GUID_EMPTY;
      }
      // Remove redundant payload
      delete el?.listData;
      delete el?.isDisabledItemName;
      delete el?.masterEffectiveStartDate;
      delete el?.masterEffectiveEndDate;
    });
    const request: SetDeductionsRequest = { listInforOfDeduction };

    let isChangeAmountOfPreTax = false;
    const listDeductionSubTypesInsuranceAndOthers = this.typesOfDeductions.filter(item => item.deductionType === DeductionType.Insurance || item.deductionType === DeductionType.Others).
      map((el) => el.deductionSubTypes as any)
      .reduce((a, b) => a.concat(b));

    const listDeductionPreTax = listInforOfDeduction.filter(item => item.taxSettingType === TaxSettingType.PreTax && (item.deductionType === DeductionType.Insurance || item.deductionType === DeductionType.Others));
    listDeductionPreTax.forEach(item => {
      if (item.isDeleted || !item.payrollBenefitDeductionId ||
         listDeductionSubTypesInsuranceAndOthers.find((el: any) => item.payrollBenefitDeductionId === item.payrollBenefitDeductionId)?.amount !== item.amount) {
        isChangeAmountOfPreTax = true;
        return;
      }
    });
    this.payeeDetailStore.dispatch(
      setDeductionsAction({ paymentInstructionId: this.data.paymentInstructionId, request, isChangeAmountOfPreTax }),
    );
  }

  onCancel(): void {
    showCancelDialog.call(this, this.dialog, this.dialogRef);
  }

  onClickAddNewDeductionItem(type: DeductionType): void {
    const deductionType = Number(type);

    const typesOfDeduction = this.getTypesOfDeduction(deductionType);
    if (!typesOfDeduction) return;

    const currentAddedItems = this.getCurrentAddedItems(deductionType);

    if (deductionType.toString() === DeductionType.Tax.toString()) {
      const notAddedItems = typesOfDeduction.deductionSubTypes?.filter(
        (item) => !item.payrollBenefitDeductionId && !currentAddedItems.includes(item.payrollDeductionId),
      );
      if (!notAddedItems?.length) {
        return;
      }
      notAddedItems.forEach((item) => {
        this.getCurrentDeductionFormArray(deductionType).push(
          this.initDeductionItemGroup({ deductionType: typesOfDeduction.deductionType, deductionSubType: item }),
        );
      });
      this.checkCanAddNewItems(deductionType);
      return;
    }

    const formArray = this.getCurrentDeductionFormArray(deductionType);
    formArray.push(
      this.initDeductionItemGroup({
        deductionType: typesOfDeduction.deductionType,
        deductionSubType: {
          ...(this.isAdjustmentDeductionType(typesOfDeduction.deductionType) && {
            isLumpSum: typesOfDeduction.isLumpSum,
          }),
        } as RowDeductionUnionType,
        typesOfDeduction,
      },
        false,
        true),
    );
    this.resetListDataControlValue(formArray, deductionType);
    this.checkCanAddNewItems(deductionType);
    this.setArrayControlDisableState(deductionType);
  }

  removeDeductionItem(deductionType: DeductionType, index: number): void {
    const formArray = this.getCurrentDeductionFormArray(deductionType);

    formArray.removeAt(index);

    this.checkCanAddNewItems(deductionType);
    this.resetListDataControlValue(formArray, deductionType);
    updateAllValueAndValidity(this.getCurrentDeductionFormArray(deductionType));
  }

  changeDeductionSubType(type: DeductionType, event: Option, index: number) {
    const deductionSubType = event.extraData;
    const deductionType = Number(type);
    const listData = this.getListData(
      deductionType,
      deductionSubType.payrollDeductionId,
      this.isAdjustmentDeductionType(deductionType) ? deductionSubType.payrollPayeeAdjustmentDeductionId : null,
    );

    let formValue: any = {};

    switch (deductionType) {
      case DeductionType.Tax:
      case DeductionType.Insurance:
      case DeductionType.Others:
        formValue = {
          payrollDeductionId: deductionSubType.payrollDeductionId,
          payrollBenefitDeductionId: deductionSubType.payrollBenefitDeductionId,
          deductionType,
          deductionCode: (deductionSubType as SubTypeDeduction).deductionCode,
          deductionSubTypeId: (deductionSubType as SubTypeDeduction).subTypeId,
          deductionSubTypeName: (deductionSubType as SubTypeDeduction).subTypeName,
          amount: deductionSubType.amount ?? null,
          masterEffectiveStartDate: getDateString(deductionSubType.masterEffectiveStartDate),
          masterEffectiveEndDate: getDateString(deductionSubType.masterEffectiveEndDate),
          startDate: deductionType === DeductionType.Insurance 
            ? null
            : deductionSubType.payrollBenefitDeductionId
              ? getDateString(deductionSubType.effectiveStartDate)
              : DateTime.now().startOf('day'),
          endDate:
            deductionSubType.payrollBenefitDeductionId &&
            this.isHideExpiredDeductions(
              deductionType,
              deductionSubType.isLinkedToFinalizedPayment,
              deductionSubType.effectiveEndDate,
            )
              ? getDateString(deductionSubType.masterEffectiveEndDate)
              : getDateString(deductionSubType.effectiveEndDate),
          listData,
          isLinkedToFinalizedPayment: (deductionSubType as SubTypeDeduction).isLinkedToFinalizedPayment,
          taxSettingType: deductionSubType.taxSettingType
        };
        break;
      case DeductionType.Garnishment:
      case DeductionType.Qdro:
        formValue = {
          payrollDeductionId: deductionSubType.payrollDeductionId,
          deductionType,
          caseNumber: (deductionSubType as CourtOrderDeduction).caseNumber,
          payee: (deductionSubType as CourtOrderDeduction).payee,
          payeeId: (deductionSubType as CourtOrderDeduction).payeeId,
          deductionSubTypeId: (deductionSubType as CourtOrderDeduction).subTypeId,
          amount: null,
          masterEffectiveStartDate: getDateString(deductionSubType.masterEffectiveStartDate),
          masterEffectiveEndDate: getDateString(deductionSubType.masterEffectiveEndDate),
          startDate: deductionType === DeductionType.Qdro ? null : DateTime.now().startOf('day'),
          endDate: null,
          deductionDescription: '',
          postOrPretax: deductionType === DeductionType.Qdro,
          listData,
          isLinkedToFinalizedPayment: (deductionSubType as CourtOrderDeduction).isLinkedToFinalizedPayment,
        };
        break;
      case DeductionType.Adjustment:
        formValue = {
          deductionType,
          payrollDeductionId: deductionSubType.payrollDeductionId,
          payrollBenefitDeductionId: deductionSubType.isShownWhenOpening
            ? deductionSubType.payrollBenefitDeductionId
            : null,
          payrollPayeeAdjustmentDeductionId: deductionSubType.payrollPayeeAdjustmentDeductionId,
          listData,
          deductionName: deductionSubType.deductionName ?? null,
          deductionDescription: '',
          amount: deductionSubType.amount ?? null,
          totalLimit: deductionSubType.totalLimit ?? null,
          startDate: deductionSubType.effectiveStartDate ? DateTime.fromISO(deductionSubType.effectiveStartDate) : null,
          remainingBalance: deductionSubType.remainingBalance ?? 0,
          postOrPretax: false,
          isShownWhenOpening: deductionSubType.isShownWhenOpening,
          isLinkedToFinalizedPayment: (deductionSubType as AdjustmentDeductionType).isLinkedToFinalizedPayment,
          isLumpSum: deductionSubType.isLumpSum,
          selectedFromExistingList: true,

          oldAmount: (deductionSubType as AdjustmentDeductionType).amount ?? 0,
          oldTotalLimit: (deductionSubType as AdjustmentDeductionType).totalLimit ?? 0,
          oldRemainingBalance: (deductionSubType as AdjustmentDeductionType).remainingBalance ?? 0,
        };
        break;
      default:
        break;
    }

    const currentFormGroup = this.getCurrentDeductionFormGroup(deductionType, index);
    currentFormGroup.reset(deepClone(formValue));

    if (!this.isAdjustmentDeductionType(deductionType)) {
      this.setControlDisableState(deductionType, index, 'amount');
      this.setArrayControlDisableState(Number(deductionType));
    }

    this.resetListDataControlValue(this.getCurrentDeductionFormArray(deductionType), deductionType);
    this.checkCanAddNewItems(deductionType);
  }

  private getTypesOfDeductions(): void {
    this.payeeDetailStore.dispatch(
      getTypesOfDeductionsAction({
        request: {
          payrollBenefitId: this.data.payrollBenefitId,
          paymentInstructionId: this.data.paymentInstructionId,
          memberId: this.data.memberId,
        },
      }),
    );
  }

  private selectGetTypesOfDeductionsState(): void {
    this.payeeDetailStore
      .select(getTypesOfDeductionsSelector)
      .pipe(
        tap((res) => (this.isLoading = !!res?.isLoading)),
        filter((res) => !!res && !res.isLoading),
        map((res) => res?.payload?.listTypeOfDeductions ?? []),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((typesOfDeductions) => {
        this.payeeDetailStore.dispatch(clearGetTypesOfDeductionsStateAction());

        this.typesOfDeductions = typesOfDeductions.map((el) => {
          let courtOrderDeductions = undefined;
          const adjustmentDeductions: AdjustmentDeductionType[] = [];
          if (el?.courtOrderDeductions && el?.courtOrderDeductions?.length > 0) {
            courtOrderDeductions = el?.courtOrderDeductions.map((item, i) => {
              return {
                ...item,
                payrollDeductionId: item.payrollDeductionId + ':' + i,
              };
            });
          }
          if (el?.adjustmentDeductions?.length) {
            adjustmentDeductions.push(
              ...el.adjustmentDeductions.map((item) => ({ ...item, isLumpSum: !!el?.isLumpSum })),
            );
          }
          return { ...el, courtOrderDeductions, adjustmentDeductions };
        });

        this.initFormGroup();
      });
  }

  private checkSetDeductionsState() {
    this.payeeDetailStore
      .select(setDeductionsSelector)
      .pipe(
        filter((response) => !!response && !response.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((setDeductionsState) => {
        if (setDeductionsState?.success) {
          this.dialogRef.close({ needReload: true, isChangeAmountOfPreTax: setDeductionsState.payload?.isChangeAmountOfPreTax });
        }
        this.payeeDetailStore.dispatch(clearSetDeductionsStateAction());
        if (setDeductionsState?.error) {
          this.isLoading = false;
          showBanner.call(this, STATE.FAIL, 'Deductions', ACTION.EDIT);
        }
      });
  }

  private getTypesOfDeduction(deductionType: DeductionType) {
    return this.typesOfDeductions.find((item) => item.deductionType.toString() === deductionType.toString());
  }

  private updateDateRangeValidity(deductionType: DeductionType, index: number) {
    const form = this.getCurrentDeductionFormGroup(deductionType, index);

    if (!form) return;

    form.controls['startDate'].markAllAsTouched();
    form.controls['startDate'].updateValueAndValidity({ emitEvent: false });

    if (!this.isAdjustmentDeductionType(deductionType)) {
      form.controls['endDate'].markAllAsTouched();
      form.controls['endDate'].updateValueAndValidity({ emitEvent: false });
    }
  }

  updateDeductionSubTypeValidity(deductionType: DeductionType, index: number) {
    const form = this.getCurrentDeductionFormGroup(deductionType, index);
    if (!form) return;
    switch (deductionType) {
      case DeductionType.Insurance:
      case DeductionType.Others:
        form.controls['payrollDeductionId'].markAllAsTouched();
        form.controls['payrollDeductionId'].updateValueAndValidity({ emitEvent: false });
        break;
      case DeductionType.Garnishment:
      case DeductionType.Qdro:
        form.controls['caseNumber'].markAllAsTouched();
        form.controls['caseNumber'].updateValueAndValidity({ emitEvent: false });
        break;
    }
  }

  checkCanAddNewItems(deductionType: DeductionType): { canAddNew: boolean | number | undefined; allItems: any } {
    const typesOfDeduction = this.getTypesOfDeduction(deductionType) ?? ({} as TypesOfDeduction);
    const currentAddedItems = this.getCurrentAddedItems(deductionType);
    const allItems =
      typesOfDeduction?.deductionSubTypes ??
      typesOfDeduction?.courtOrderDeductions ??
      typesOfDeduction?.adjustmentDeductions?.filter((item) => this.isAdjustmentDeductionValidToRetrieved(item));
    const formArray = this.getCurrentDeductionFormArray(deductionType);
    let isLatestEmpty = false;
    let isMandatoryEmpty = false;
    const isCheckAll = this.isGarnishmentQdro(deductionType);

    if (formArray.length) {
      const lastItem = formArray.controls[formArray.length - 1] as FormGroup;
      isLatestEmpty =
        (!lastItem?.controls?.['payrollDeductionId']?.value && !lastItem?.controls?.['caseNumber']?.value) ||
        isEmpty(lastItem?.controls?.['amount']?.value);
      isMandatoryEmpty = formArray.controls.some((group) => {
        return Object.keys((group as FormGroup).controls).some((key) => {
          const control = (group as FormGroup).controls[key];
          return control.hasValidator(Validators.required) && (isEmpty(control.value) || !control.value.toString());
        });
      });
    }

    let canAddNew: boolean | number | undefined = false;
    // [Adjustment Deduction] type: Recurring Payment + OTP but the parent benefit of linked benefit is NOT Annuity Benefit
    if (this.isAdjustmentDeductionType(deductionType) && (!this.data.isOTPRecord || typesOfDeduction?.isLumpSum)) {
      canAddNew = !isMandatoryEmpty;
    }
    // Other deduction types: Recurring Payment
    else if (this.data.isRecurringRecord) {
      canAddNew =
        allItems?.length &&
        ((isCheckAll && !isMandatoryEmpty) ||
          (!isCheckAll && !isLatestEmpty && currentAddedItems.length < allItems.length));
    }
    // [Adjustment Deduction] type (OTP payment has parent benefit of linked benefit is Annuity Benefit) + Other deduction types (OTP)
    else {
      canAddNew = allItems?.length && currentAddedItems.length < allItems.length && !isMandatoryEmpty;
    }

    (this.editForm.controls[deductionType] as FormGroup).controls['canAddNew'].setValue(canAddNew);

    return { canAddNew, allItems };
  }

  onChangeStartDate(deductionType: DeductionType, index: number) {
    this.updateDateRangeValidity(Number(deductionType), index);
    this.updateDeductionSubTypeValidity(Number(deductionType), index);
    this.setArrayControlDisableState(Number(deductionType));
    this.checkCanAddNewItems(deductionType);
  }

  onChangeEndDate(type: DeductionType, index: number) {
    const deductionType = Number(type);
    this.updateDateRangeValidity(deductionType, index);
    this.updateDeductionSubTypeValidity(deductionType, index);
    this.setControlDisableState(deductionType, index, 'amount');
    this.setArrayControlDisableState(deductionType);
    const currentForm = this.getCurrentDeductionFormGroup(deductionType, index);
    const hideRemove =
      deductionType === DeductionType.Qdro &&
      !this.isDateInPeriod('', currentForm?.controls['endDate']?.value ?? '', DateTime.now().startOf('day'));
    this.getCurrentDeductionFormGroup(deductionType, index).controls['hideRemove'].setValue(hideRemove);
  }

  setArrayControlDisableState(type: DeductionType) {
    const deductionType = Number(type);
    if (!this.isGarnishmentQdro(deductionType)) {
      return;
    }
    const currentArray = this.getCurrentDeductionFormArray(deductionType);
    if (!currentArray) {
      return;
    }
    const controlNames = ['caseNumber', 'amount', 'deductionDescription', 'startDate', 'endDate'];

    if (
      (this.data.isRecurringRecord && deductionType === DeductionType.Garnishment) ||
      (!this.data.isRecurringRecord &&
        (deductionType === DeductionType.Garnishment || deductionType === DeductionType.Qdro))
    ) {
      controlNames.push('postOrPretax');
    }

    currentArray.controls.forEach((group, index: number) => {
      controlNames.forEach((controlName) => {
        this.setControlDisableState(deductionType, index, controlName);
      });
    });
    this.getCurrentDeductionFormArray(Number(deductionType)).controls.forEach((control) => {
      (control as FormGroup).updateValueAndValidity({ emitEvent: false });
    });
  }

  private initFormGroup(): void {
    this.typesOfDeductions.forEach((typesOfDeduction) => {
      this.initDeductionFormArray(typesOfDeduction);
      const { canAddNew, allItems } = this.checkCanAddNewItems(typesOfDeduction.deductionType);

      if (this.isAdjustmentDeductionType(typesOfDeduction.deductionType)) {
        this.isShownAdjustmentSection = this.checkVisibilityAdjustmentSection(
          this.data.isRecurringRecord,
          this.data.isOTPRecord,
          typesOfDeduction.isLumpSum,
          !!canAddNew,
          allItems,
        );
      }
    });
    if (!this.data.isRecurringRecord) {
      this.onClickAddNewDeductionItem(DeductionType.Tax);
    }
    this.handleFormValueChanged();
  }

  private handleFormValueChanged() {
    this.typesOfDeductions.forEach((typesOfDeduction) => {
      const valueForm = this.getCurrentDeductionFormArray(typesOfDeduction.deductionType).value;
      this.getCurrentDeductionFormArray(typesOfDeduction.deductionType).valueChanges.subscribe((value) => {
        if (JSON.stringify(valueForm) !== JSON.stringify(value)) {
          this.isDisabledSaveBtn = false;
        }
      });
    });
  }

  // [Adjustment Deduction] type: HIDE this section IF the valid retrieved record list is empty + is NOT able to add new record
  private checkVisibilityAdjustmentSection(
    isRecurringRecord: boolean,
    isOTPRecord: boolean,
    isLumpSum: boolean,
    canAddNew: boolean,
    retrievedRecordList?: AdjustmentDeductionType[],
  ): boolean {
    return isRecurringRecord || (isOTPRecord && isLumpSum) || (!!retrievedRecordList?.length && canAddNew);
  }

  private validateDateRange(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate = (control.parent as FormGroup)?.controls['startDate']?.value;
      const endDate = (control.parent as FormGroup)?.controls['endDate']?.value;
      if (!startDate || !endDate) {
        return null;
      }
      if (DateTime.fromISO(startDate) > DateTime.fromISO(endDate)) {
        return { customError: 'Start Date must be earlier than End Date.' };
      }
      return null;
    };
  }

  private validateDeductionName(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control?.parent?.get('deductionName')?.value) return null;

      const inputDeductionName = control.value;

      const adjustmentTypesOfDeductions = this.typesOfDeductions.find((item) =>
        this.isAdjustmentDeductionType(item.deductionType),
      );
      const adjustmentDeductionListData: AdjustmentDeductionType[] =
        adjustmentTypesOfDeductions?.adjustmentDeductions ?? [];
      const adjustmentDeductionFormArray = this.getCurrentDeductionFormArray(DeductionType.Adjustment);
      const inputtedDeductionNames = adjustmentDeductionFormArray.controls
        .filter((formGroup) => formGroup !== control.parent)
        .map((formGroup) => formGroup.value);

      const isExisted = [...adjustmentDeductionListData, ...inputtedDeductionNames].some(
        (item) => item.deductionName === inputDeductionName,
      );

      return isExisted ? { customErrorDeductionName: 'Deduction Name is already existed.' } : null;
    };
  }

  private getCurrentAddedItems(deductionType: DeductionType) {
    const conditionMapper = (item: RowDeductionUnionType) => {
      return this.isAdjustmentDeductionType(deductionType)
        ? (item as AdjustmentDeductionType).payrollPayeeAdjustmentDeductionId
        : (item as SubTypeDeduction).payrollDeductionId ?? (item as CourtOrderDeduction).caseNumber;
    };

    return (this.getCurrentDeductionFormArray(deductionType)?.getRawValue() ?? []).map(conditionMapper);
  }

  private initDeductionSubtypeForm(typesOfDeduction: TypesOfDeduction): FormArray {
    const deductionSubTypes = this.fb.array([]);
    (
      typesOfDeduction.courtOrderDeductions ??
      typesOfDeduction.deductionSubTypes ??
      typesOfDeduction.adjustmentDeductions?.filter((item) => this.isAdjustmentDeductionValidToRetrieved(item)) ??
      []
    ).forEach((deductionSubType: RowDeductionUnionType) => {
      if (
        !deductionSubType.payrollBenefitDeductionId ||
        (this.isAdjustmentDeductionType(typesOfDeduction.deductionType) &&
          !(deductionSubType as AdjustmentDeductionType).isShownWhenOpening) ||
        (deductionSubType.payrollBenefitDeductionId &&
          this.isHideExpiredDeductions(
            typesOfDeduction.deductionType,
            deductionSubType.isLinkedToFinalizedPayment,
            (deductionSubType as SubTypeDeduction | CourtOrderDeduction).effectiveEndDate,
          ))
      ) {
        return;
      }
      deductionSubTypes.push(
        this.initDeductionItemGroup({ deductionType: typesOfDeduction.deductionType, deductionSubType }, true),
      );
    });
    return deductionSubTypes;
  }

  private isHideExpiredDeductions(
    deductionType: DeductionType,
    isLinkedToFinalizedPayment: boolean,
    endDate: string = '',
  ): boolean {
    return (
      this.data.isRecurringRecord &&
      deductionType !== DeductionType.Tax &&
      isLinkedToFinalizedPayment &&
      !!endDate &&
      DateTime.fromISO(endDate)?.toMillis() <
        DateTime.fromISO(this.data.selectedPayment.startPeriodDate ?? '')?.toMillis()
    );
  }

  private resetListDataControlValue(deductionSubTypes: FormArray, deductionType: DeductionType) {
    const typesOfDeduction = this.getTypesOfDeduction(deductionType) ?? ({} as TypesOfDeduction);
    deductionSubTypes.controls.forEach((control) => {
      const childFormGroupValue = (control as FormGroup).getRawValue();

      const listData = this.getListData(
        typesOfDeduction.deductionType,
        childFormGroupValue.payrollDeductionId,
        this.isAdjustmentDeductionType(typesOfDeduction.deductionType)
          ? childFormGroupValue.payrollPayeeAdjustmentDeductionId
          : null,
      );
      return (control as FormGroup).controls['listData'].setValue(listData);
    });
  }

  private getCurrentDeductionFormArray(deductionType: DeductionType): FormArray {
    return (this.editForm.controls[deductionType] as FormGroup)?.controls['deductionSubTypes'] as FormArray;
  }

  private getCurrentDeductionFormGroup(deductionType: DeductionType, index: number): FormGroup {
    return this.getCurrentDeductionFormArray(deductionType).controls[index] as FormGroup;
  }

  private isGarnishmentQdro(deductionType: DeductionType) {
    return [DeductionType.Garnishment, DeductionType.Qdro].includes(Number(deductionType));
  }

  private isAdjustmentDeductionType(deductionType: DeductionType) {
    return Number(deductionType) === DeductionType.Adjustment;
  }

  private initDeductionFormArray(typesOfDeduction: TypesOfDeduction) {
    const { deductionType, isVisiblePart = false, isNewBtnTurnOn = false, labelName = '' } = typesOfDeduction;

    this.editForm.controls[deductionType] = this.fb.group({
      isVisiblePart: this.fb.control(isVisiblePart),
      canAddNew: this.fb.control(isNewBtnTurnOn),
      labelName: this.fb.control(labelName),
      deductionSubTypes: this.initDeductionSubtypeForm(typesOfDeduction),
    });
    this.resetListDataControlValue(this.getCurrentDeductionFormArray(deductionType), deductionType);
    this.setArrayControlDisableState(Number(typesOfDeduction.deductionType));
  }

  private getListData(
    deductionType: DeductionType,
    payrollDeductionId: string,
    payrollPayeeAdjustmentDeductionId?: string,
  ): Option[] {
    const typesOfDeduction = this.typesOfDeductions.find(
      (item) => item.deductionType.toString() === deductionType.toString(),
    );
    if (!typesOfDeduction) {
      return [];
    }
    const addedItems = this.getCurrentAddedItems(deductionType).filter((item) => !!item);
    if (typesOfDeduction.courtOrderDeductions?.length) {
      const key = this.data.isRecurringRecord ? 'caseNumber' : 'payrollDeductionId';
      return this.removeDuplicatedByKey(typesOfDeduction.courtOrderDeductions, key)
        .filter((item) => {
          return !addedItems.includes(item[key]) || item[key] === payrollDeductionId;
        })
        .sort((a, b) => localeCompare(a?.caseNumber, b?.caseNumber))
        .map((item: CourtOrderDeduction) => {
          return {
            displayValue: item.caseNumber,
            value: item.caseNumber,
            extraData: item,
          };
        });
    }
    if (typesOfDeduction.deductionSubTypes?.length) {
      return this.removeDuplicatedByKey(typesOfDeduction.deductionSubTypes, 'payrollDeductionId')
        .filter(
          (item) => !addedItems.includes(item.payrollDeductionId) || item.payrollDeductionId === payrollDeductionId,
        )
        .sort((a, b) => localeCompare(a?.deductionCode, b?.deductionCode))
        .map((item: SubTypeDeduction) => {
          return {
            displayValue: `${item.deductionCode} - ${item.subTypeName}`,
            value: item.payrollDeductionId,
            extraData: item,
          };
        });
    }
    if (typesOfDeduction.adjustmentDeductions?.length) {
      return this.removeDuplicatedByKey(typesOfDeduction.adjustmentDeductions, 'payrollPayeeAdjustmentDeductionId')
        .filter(
          (item: AdjustmentDeductionType) =>
            !addedItems.includes(item.payrollPayeeAdjustmentDeductionId) ||
            item.payrollPayeeAdjustmentDeductionId === payrollPayeeAdjustmentDeductionId,
        )
        .filter((item: AdjustmentDeductionType) => this.isAdjustmentDeductionValidToRetrieved(item))
        .sort((a, b) => localeCompare(a?.deductionName, b?.deductionName))
        .map((item: AdjustmentDeductionType) => {
          return {
            displayValue: `${item.deductionName}`,
            value: item.payrollPayeeAdjustmentDeductionId,
            extraData: item,
          };
        });
    }

    return [];
  }

  private removeDuplicatedByKey<T>(array: T[], key: keyof T): T[] {
    const uniqueValues = new Set();
    return [
      ...array.filter((item) => {
        const keyValue = item[key];
        if (uniqueValues.has(keyValue)) {
          return false;
        }
        uniqueValues.add(keyValue);
        return true;
      }),
    ];
  }

  private initDeductionItemGroup(
    deduction: {
      deductionType: DeductionType;
      deductionSubType: RowDeductionUnionType;
      typesOfDeduction?: TypesOfDeduction;
    },
    isSavedItem: boolean = false,
    isAddNew: boolean = false,
  ) {
    const { deductionType, deductionSubType, typesOfDeduction } = deduction;
    switch (deductionType) {
      case DeductionType.Adjustment: {
        const currentAddedOptionItems = this.getCurrentAddedItems(deductionType).filter((item) => !!item);
        const listData = typesOfDeduction?.adjustmentDeductions?.filter((item) =>
          this.isAdjustmentDeductionValidToRetrieved(item),
        );
        const hasNoOptionLeft = listData?.length === currentAddedOptionItems.length;
        const isSelectedFromExistingListToggleOn = hasNoOptionLeft
          ? false
          : (deductionSubType as AdjustmentDeductionType).isShownWhenOpening ||
            (this.data.isOTPRecord && !(deductionSubType as AdjustmentDeductionType).isLumpSum);

        return this.fb.group({
          deductionType,
          payrollDeductionId: deductionSubType.payrollDeductionId,
          payrollBenefitDeductionId: deductionSubType.payrollBenefitDeductionId,
          payrollPayeeAdjustmentDeductionId: [
            (deductionSubType as AdjustmentDeductionType).payrollPayeeAdjustmentDeductionId,
            isSelectedFromExistingListToggleOn ? Validators.required : null,
          ],
          listData: this.fb.control([]),
          deductionName: [
            (deductionSubType as AdjustmentDeductionType).deductionName,
            !isSelectedFromExistingListToggleOn ? [Validators.required, this.validateDeductionName()] : null,
          ],
          deductionDescription: (deductionSubType as AdjustmentDeductionType).deductionDescription,
          amount: [deductionSubType.amount ?? null, Validators.required],
          totalLimit: [(deductionSubType as AdjustmentDeductionType).totalLimit ?? null, Validators.required],
          startDate: [
            deductionSubType.effectiveStartDate
              ? DateTime.fromISO(deductionSubType.effectiveStartDate)
              : DateTime.now(),
            Validators.required,
          ],
          remainingBalance: [(deductionSubType as AdjustmentDeductionType).remainingBalance ?? 0, Validators.min(0)],
          postOrPretax: !!(deductionSubType as AdjustmentDeductionType).preOrPostTax,

          isShownWhenOpening: (deductionSubType as AdjustmentDeductionType).isShownWhenOpening ?? false,
          isLinkedToFinalizedPayment: (deductionSubType as AdjustmentDeductionType).isLinkedToFinalizedPayment,
          isLumpSum: (deductionSubType as AdjustmentDeductionType).isLumpSum ?? false,
          selectedFromExistingList: isSelectedFromExistingListToggleOn,

          oldAmount: (deductionSubType as AdjustmentDeductionType).amount ?? 0,
          oldTotalLimit: (deductionSubType as AdjustmentDeductionType).totalLimit ?? 0,
          oldRemainingBalance: (deductionSubType as AdjustmentDeductionType).remainingBalance ?? 0,
          isDisabledItemName: [isSavedItem],
        });
      }
      case DeductionType.Tax:
      case DeductionType.Insurance:
      case DeductionType.Others: {
        const isTaxDeductionType = deductionType === DeductionType.Tax;
        const startDateControlOptions: FormControlOptions | null = !isTaxDeductionType
          ? {
              validators: [Validators.required, this.validateDateRange()],
              updateOn: 'blur',
            }
          : null;
        const endDateControlOptions: FormControlOptions | null = !isTaxDeductionType
          ? {
              validators: [this.validateDateRange()],
              updateOn: 'blur',
            }
          : null;
        return this.fb.group({
          payrollDeductionId: this.fb.control(deductionSubType.payrollDeductionId, [Validators.required]),
          payrollBenefitDeductionId: this.fb.control(deductionSubType.payrollBenefitDeductionId),
          deductionCode: this.fb.control((deductionSubType as SubTypeDeduction).deductionCode),
          deductionType: this.fb.control(deductionType),
          deductionSubTypeId: this.fb.control((deductionSubType as SubTypeDeduction).subTypeId),
          deductionSubTypeName: this.fb.control((deductionSubType as SubTypeDeduction).subTypeName),
          amount: this.fb.control(
            {
              value: deductionSubType.amount ?? null,
              // Disable this field if the current date > [End Date] of this deduction. (do not apply it to One time payment)
              disabled:
                !deductionSubType.payrollBenefitDeductionId || this.data.isOTPRecord
                  ? false
                  : !this.isDateInPeriod(
                      (deductionSubType as SubTypeDeduction).masterEffectiveStartDate ?? '',
                      (deductionSubType as SubTypeDeduction).masterEffectiveEndDate ?? '',
                      DateTime.now(),
                    ),
            },
            [Validators.required],
          ),
          masterEffectiveStartDate: this.fb.control(
            (deductionSubType as SubTypeDeduction).masterEffectiveStartDate
              ? getDateString((deductionSubType as SubTypeDeduction).masterEffectiveStartDate ?? '')
              : undefined,
          ),
          masterEffectiveEndDate: this.fb.control(
            (deductionSubType as SubTypeDeduction).masterEffectiveEndDate
              ? getDateString((deductionSubType as SubTypeDeduction).masterEffectiveEndDate ?? '')
              : undefined,
          ),
          startDate: this.data.isRecurringRecord
            ? this.fb.control(deductionSubType.effectiveStartDate, startDateControlOptions, [])
            : this.fb.control(deductionSubType.effectiveStartDate),
          endDate: this.data.isRecurringRecord
            ? this.fb.control((deductionSubType as SubTypeDeduction).effectiveEndDate, endDateControlOptions)
            : this.fb.control((deductionSubType as SubTypeDeduction).effectiveEndDate),
          listData: this.fb.control([]),
          isDisabledItemName: [isSavedItem],
          isLinkedToFinalizedPayment: (deductionSubType as SubTypeDeduction).isLinkedToFinalizedPayment,

          taxSettingType: (deductionSubType as SubTypeDeduction).taxSettingType
        });
      }
      case DeductionType.Garnishment:
      case DeductionType.Qdro:
        return this.fb.group({
          payrollDeductionId: this.fb.control(deductionSubType.payrollDeductionId),
          payrollBenefitDeductionId: this.fb.control(deductionSubType.payrollBenefitDeductionId),
          deductionType: this.fb.control(deductionType),
          caseNumber: this.fb.control((deductionSubType as CourtOrderDeduction).caseNumber, [
            Validators.required,
            this.validateOverlappingDatePeriod(),
          ]),
          payee: this.fb.control((deductionSubType as CourtOrderDeduction).payee),
          payeeId: this.fb.control((deductionSubType as CourtOrderDeduction).payeeId),
          amount: this.fb.control(deductionSubType.amount, [Validators.required]),
          masterEffectiveStartDate: this.fb.control(
            (deductionSubType as SubTypeDeduction).masterEffectiveStartDate
              ? getDateString((deductionSubType as SubTypeDeduction).masterEffectiveStartDate ?? '')
              : undefined,
          ),
          masterEffectiveEndDate: this.fb.control(
            (deductionSubType as SubTypeDeduction).masterEffectiveEndDate
              ? getDateString((deductionSubType as SubTypeDeduction).masterEffectiveEndDate ?? '')
              : undefined,
          ),
          startDate: this.data.isRecurringRecord
            ? this.fb.control(deductionSubType.effectiveStartDate, {
                validators: [Validators.required, this.validateDateRange()],
              })
            : this.fb.control(deductionSubType.effectiveStartDate),
          endDate: this.data.isRecurringRecord
            ? this.fb.control((deductionSubType as CourtOrderDeduction).effectiveEndDate, {
                validators: [this.validateDateRange()],
              })
            : this.fb.control((deductionSubType as CourtOrderDeduction).effectiveEndDate),
          deductionDescription: this.fb.control(
            (deductionSubType as CourtOrderDeduction).description,
            deductionType === DeductionType.Garnishment ? [Validators.required] : [],
          ),
          postOrPretax: (deductionType === DeductionType.Qdro && isAddNew) ? true : !!(deductionSubType as CourtOrderDeduction).preOrPostTax,
          listData: this.fb.control([]),
          hideRemove: this.fb.control(
            deductionType === DeductionType.Qdro &&
              !this.isDateInPeriod(
                '',
                (deductionSubType as CourtOrderDeduction)?.effectiveEndDate ?? '',
                DateTime.now().startOf('day'),
              ),
          ),
          isDisabledItemName: [isSavedItem],
          isLinkedToFinalizedPayment: (deductionSubType as CourtOrderDeduction).isLinkedToFinalizedPayment,
        });
      default:
        return this.fb.group({});
    }
  }

  private parseDate(value: string | DateTime) {
    if (DateTime.isDateTime(value)) {
      return value;
    }
    return DateTime.fromISO(value);
  }

  private isDateInPeriod(startDate: string | DateTime, endDate: string | DateTime, comparedDate?: string | DateTime, keepNullComparedDate = false) {
    const start = startDate ? this.parseDate(startDate).startOf('day') : startDate;
    const end = endDate ? this.parseDate(endDate).startOf('day') : endDate;
    const value = (keepNullComparedDate && !comparedDate) ? comparedDate : this.parseDate(comparedDate ?? DateTime.now()).startOf('day');
    if (!value) {
      return !end;
    }
    return (!start || value >= start) && (!end || value <= end);
  }

  private changeDisableFormState(form: FormControl, isDisabled?: boolean) {
    if (isDisabled) {
      form.disable();
    } else {
      form.enable();
    }
  }

  private validateOverlappingDatePeriod() {
    return (control: AbstractControl): ValidationErrors | null => {
      const startDate = (control.parent as FormGroup)?.controls['startDate']?.value;
      const endDate = (control.parent as FormGroup)?.controls['endDate']?.value;
      const currentPeriod = { startDate, endDate };
      if (!startDate && !endDate) {
        return null;
      }
      const caseNumber = (control.parent as FormGroup)?.controls['caseNumber']?.value;
      const formArray = control.parent?.parent as FormArray;
      const labelName = control.parent?.parent?.parent?.value?.labelName ?? 'Garnishment';
      if (!formArray) {
        return null;
      }
      const periods: DatePeriod[] = formArray.controls
        .filter((group) => (group as FormGroup).controls['caseNumber'].value === caseNumber)
        .map((item) => {
          return {
            startDate: (item as FormGroup).controls['startDate'].value,
            endDate: (item as FormGroup).controls['endDate'].value,
          };
        });
      const isOverlapped =
        periods.filter((period) => this.isOverlappingEffectivePeriod(currentPeriod, period))?.length > 1;
      if (isOverlapped) {
        return { customError: `Overlapping Effective Period with another ${labelName} deduction.` };
      }
      return null;
    };
  }

  private isOverlappingEffectivePeriod(source: DatePeriod, target: DatePeriod) {
    if (!source.endDate && !target.endDate) {
      return true;
    }
    const sourceStartDate = source.startDate;
    const sourceEndDate = source.endDate;
    const targetStartDate = target.startDate;
    const targetEndDate = target.endDate;

    return (
      this.isDateInPeriod(sourceStartDate, sourceEndDate, targetStartDate, true) || // target starts in source
      this.isDateInPeriod(sourceStartDate, sourceEndDate, targetEndDate, true) || // target ends in source
      (this.isDateInPeriod(targetStartDate, targetEndDate, sourceStartDate, true) &&
        this.isDateInPeriod(targetStartDate, targetEndDate, sourceEndDate, true)) // source in target
    );
  }

  private setControlDisableState(deductionType: DeductionType, index: number, controlName: string) {
    const currentFormGroup = this.getCurrentDeductionFormGroup(Number(deductionType), index);
    if (currentFormGroup) {
      const isAdded = !!currentFormGroup.getRawValue().payrollBenefitDeductionId;
      const startDate = isAdded ? currentFormGroup.controls['masterEffectiveStartDate'].value : '';
      const endDate = currentFormGroup.controls['endDate'].value;
      const isDateError = currentFormGroup.controls['startDate'].errors || currentFormGroup.controls['endDate'].errors;
      const dateNotError =
        !['startDate', 'endDate'].includes(controlName) ||
        (['startDate', 'endDate'].includes(controlName) && !isDateError);
      const isCaseNumberEmpty =
        this.isGarnishmentQdro(deductionType) &&
        controlName !== 'caseNumber' &&
        !currentFormGroup.controls['caseNumber'].value;
      this.changeDisableFormState(
        currentFormGroup.controls[controlName] as FormControl,
        isCaseNumberEmpty ||
          (dateNotError &&
            !this.isDateInPeriod(this.isGarnishmentQdro(deductionType) ? '' : startDate, endDate, DateTime.now())),
      );
    }
  }

  calculateRemainingBalance(deductionType: DeductionType, index: number): void {
    const currentFormGroup = this.getCurrentDeductionFormGroup(Number(deductionType), index);
    if (!currentFormGroup) return;

    const isNewlyAddedRow = !currentFormGroup.get('payrollPayeeAdjustmentDeductionId')?.value;
    const newAmount = currentFormGroup.get('amount')?.value ?? 0;
    const oldAmount = currentFormGroup.get('oldAmount')?.value ?? 0;
    const newTotalLimit = currentFormGroup.get('totalLimit')?.value ?? 0;
    const oldTotalLimit = currentFormGroup.get('oldTotalLimit')?.value ?? 0;
    const oldRemainingBalance = currentFormGroup.get('oldRemainingBalance')?.value ?? 0;

    if (isNewlyAddedRow) {
      currentFormGroup.get('remainingBalance')?.setValue(newTotalLimit && newAmount ? newTotalLimit - newAmount : 0);
      return;
    }
    // Amount or Total Limit will always change at this point
    currentFormGroup
      .get('remainingBalance')
      ?.setValue(oldRemainingBalance - newAmount + oldAmount + newTotalLimit - oldTotalLimit);
  }

  getSelectedFromExistingListChanges(deductionType: DeductionType, index: number, isToggleOn: boolean): void {
    const currentFormGroup = this.getCurrentDeductionFormGroup(Number(deductionType), index);
    if (!currentFormGroup) return;

    if (isToggleOn) {
      currentFormGroup.get('deductionName')?.clearValidators();
      currentFormGroup.get('deductionName')?.updateValueAndValidity();
      currentFormGroup.get('payrollPayeeAdjustmentDeductionId')?.addValidators(Validators.required);
      currentFormGroup.get('payrollPayeeAdjustmentDeductionId')?.reset();
    } else {
      currentFormGroup.get('deductionName')?.addValidators([Validators.required, this.validateDeductionName()]);
      currentFormGroup.get('deductionName')?.reset();
      currentFormGroup.get('payrollPayeeAdjustmentDeductionId')?.clearValidators();
      currentFormGroup.get('payrollPayeeAdjustmentDeductionId')?.updateValueAndValidity();
    }
    currentFormGroup.get('amount')?.reset();
    currentFormGroup.get('totalLimit')?.reset();
    currentFormGroup.get('startDate')?.reset(DateTime.now());
    this.refreshMinStartDate();
    currentFormGroup.get('remainingBalance')?.reset(0);
    currentFormGroup.get('deductionDescription')?.reset();
    currentFormGroup.get('postOrPretax')?.reset(false);
  }

  private isAdjustmentDeductionValidToRetrieved(item: AdjustmentDeductionType): boolean {
    if (this.data.isRecurringRecord)
      return item.remainingBalance > 0 || (item.remainingBalance === 0 && item.amount > 0);
    if (this.data.isOTPRecord)
      return (
        (item.remainingBalance > 0 || (item.remainingBalance === 0 && item.amount > 0)) &&
        DateTime.fromISO(item.effectiveStartDate).toMillis() <= DateTime.now().toMillis()
      );
    return false;
  }

  private refreshMinStartDate(): void {
    this.minAdjustmentDeductionStartDate = this.data.selectedPayment.startPeriodDate
      ? new Date(this.data.selectedPayment.startPeriodDate)
      : MY_DATE.validMinDate;
  }
}
