import { createAction, props } from '@ngrx/store';
import {
  AddEditBuyBackMakeUpRequest, 
  BuyBackMakeUpYearRecordResponse,
  EditBuyBackMakeUpInterestFeesRequest,
  GetBuyBackMakeUpDetailListRequest,
  GetBuyBackMakeUpDetailListResponse,
  GetBuyBackMakeUpListRequest,
  GetBuyBackMakeUpListResponse,
  GetEligibleYearRequest,
  GetEligibleYearResponse,
  GetListMunicipalityRequest,
  GetListMunicipalityResponse,
  InitiatedRemittanceBuyBackMakeUpResponse,
  ValidateBuyBackMakeUpRequest,
  ValidateBuyBackMakeUpResponse,
} from '../../services/models/buyback-makeup.model';

// Get List Buy Back Make Up
export const getListBuyBackMakeUpAction = createAction(
  '[GetListBuyBackMakeUp/API] Send Request',
  props<{ request: GetBuyBackMakeUpListRequest }>()
);
export const getListBuyBackMakeUpSuccessAction = createAction(
  '[GetListBuyBackMakeUp/API] Success',
  props<{ response: GetBuyBackMakeUpListResponse }>()
);
export const getListBuyBackMakeUpFailureAction = createAction(
  '[GetListBuyBackMakeUp/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetListBuyBackMakeUpStateAction = createAction(
  '[GetListBuyBackMakeUp] Clear'
);

// Get List Buy Back Up Detail
export const getListBuyBackMakeUpDetailAction = createAction(
  '[GetListBuyBackMakeUpDetail/API] Send Request',
  props<{ request: GetBuyBackMakeUpDetailListRequest }>()
);
export const getListBuyBackMakeUpDetailSuccessAction = createAction(
  '[GetListBuyBackMakeUpDetail/API] Success',
  props<{ response: GetBuyBackMakeUpDetailListResponse }>()
);
export const getListBuyBackMakeUpDetailFailureAction = createAction(
  '[GetListBuyBackMakeUpDetail/API] Failure',
  props<{ error?: any }>()
);
export const clearGetListBuyBackMakeUpDetailStateAction = createAction(
  '[GetListBuyBackMakeUpDetail] Clear'
);

// Remove Buy Back Make Up
export const removeBuyBackMakeUpAction = createAction(
  '[RemoveBuyBackMakeUp/API] Send Request',
  props<{ memberId: string, buyBackMakeUpId: string }>()
);
export const removeBuyBackMakeUpSuccessAction = createAction(
  '[RemoveBuyBackMakeUp/API] Success',
);
export const removeBuyBackMakeUpFailureAction = createAction(
  '[RemoveBuyBackMakeUp/API] Failure',
  props<{ error?: unknown }>()
);
export const clearRemoveBuyBackMakeUpStateAction = createAction(
  '[RemoveBuyBackMakeUp] Clear'
);

// Get Eligible Year
export const getEligibleYearAction = createAction(
  '[GetEligibleYear/API] Send Request',
  props<{ request: GetEligibleYearRequest }>()
);
export const getEligibleYearSuccessAction = createAction(
  '[GetEligibleYear/API] Success',
  props<{ response: GetEligibleYearResponse }>()
);
export const getEligibleYearFailureAction = createAction(
  '[GetEligibleYear/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetEligibleYearStateAction = createAction(
  '[GetEligibleYear] Clear'
);

// Get List Municipality
export const getListMunicipalityBuyBackMakeUpAction = createAction(
  '[GetListMunicipalityBuyBackMakeUp/API] Send Request',
  props<{ request: GetListMunicipalityRequest }>()
);
export const getListMunicipalityBuyBackMakeUpSuccessAction = createAction(
  '[GetListMunicipalityBuyBackMakeUp/API] Success',
  props<{ response: GetListMunicipalityResponse }>()
);
export const getListMunicipalityBuyBackMakeUpFailureAction = createAction(
  '[GetListMunicipalityBuyBackMakeUp/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetListMunicipalityBuyBackMakeUpStateAction = createAction(
  '[GetListMunicipalityBuyBackMakeUp] Clear'
);

// Add Buy Back Make Up
export const addBuyBackMakeUpAction = createAction(
  '[AddBuyBackMakeUp/API] Send Request',
  props<{ request: AddEditBuyBackMakeUpRequest, memberId: string }>()
);
export const addBuyBackMakeUpSuccessAction = createAction(
  '[AddBuyBackMakeUp/API] Success',
);
export const addBuyBackMakeUpFailureAction = createAction(
  '[AddBuyBackMakeUp/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearAddBuyBackMakeUpStateAction = createAction(
  '[AddBuyBackMakeUp] Clear'
);

// Edit Buy Back Make Up
export const editBuyBackMakeUpAction = createAction(
  '[EditBuyBackMakeUp/API] Send Request',
  props<{ request: AddEditBuyBackMakeUpRequest, memberId: string }>()
);
export const editBuyBackMakeUpSuccessAction = createAction(
  '[EditBuyBackMakeUp/API] Success',
);
export const editBuyBackMakeUpFailureAction = createAction(
  '[EditBuyBackMakeUp/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearEditBuyBackMakeUpStateAction = createAction(
  '[EditBuyBackMakeUp] Clear'
);

// Initiate Remittance Buy Back Make Up
export const initiateRemittanceBuyBackMakeUpAction = createAction(
  '[InitiateRemittanceBuyBackMakeUp/API] Send Request',
  props<{  memberId: string, buybackMakeupId: string }>()
);
export const initiateRemittanceBuyBackMakeUpSuccessAction = createAction(
  '[InitiateRemittanceBuyBackMakeUp/API] Success',
  props<{ response: InitiatedRemittanceBuyBackMakeUpResponse }>()
);
export const initiateRemittanceBuyBackMakeUpFailureAction = createAction(
  '[InitiateRemittanceBuyBackMakeUp/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearInitiateRemittanceBuyBackMakeUpStateAction = createAction(
  '[InitiateRemittanceBuyBackMakeUp] Clear'
);

// Edit Buy Back Make Up Interest Fees
export const editBuyBackMakeUpInterestFeesAction = createAction(
  '[EditBuyBackMakeUpInterestFees/API] Send Request',
  props<{ memberId: string, buyBackMakeUpId: string, yearRecordId: string, request: EditBuyBackMakeUpInterestFeesRequest }>()
);
export const editBuyBackMakeUpInterestFeesSuccessAction = createAction(
  '[EditBuyBackMakeUpInterestFees/API] Success',
);
export const editBuyBackMakeUpInterestFeesFailureAction = createAction(
  '[EditBuyBackMakeUpInterestFees/API] Failure',
  props<{ errorMsg: string }>()
);
export const clearEditBuyBackMakeUpInterestFeesStateAction = createAction(
  '[EditBuyBackMakeUpInterestFees] Clear'
);

// Get Buy Back Make Up Year Record
export const getBuyBackMakeUpYearRecordAction = createAction(
  '[GetBuyBackMakeUpYearRecord/API] Send Request',
  props<{ memberId: string, buyBackMakeUpId: string, yearRecordId: string }>()
);
export const getBuyBackMakeUpYearRecordSuccessAction = createAction(
  '[GetBuyBackMakeUpYearRecord/API] Success',
  props<{ response: BuyBackMakeUpYearRecordResponse }>()
);
export const getBuyBackMakeUpYearRecordFailureAction = createAction(
  '[GetBuyBackMakeUpYearRecord/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetBuyBackMakeUpYearRecordStateAction = createAction(
  '[GetBuyBackMakeUpYearRecord] Clear'
);

// Get Buy Back Make Up Interest Fees Calculation Result
export const getBuyBackMakeUpInterestFeesCalculationAction = createAction(
  '[GetBuyBackMakeUpInterestFeesCalculation/API] Send Request',
  props<{ memberId: string, buyBackMakeUpId: string, yearRecordId: string, interestBeginDate: string, interestThruDate: string }>()
);
export const getBuyBackMakeUpInterestFeesCalculationSuccessAction = createAction(
  '[GetBuyBackMakeUpInterestFeesCalculation/API] Success',
  props<{ response: BuyBackMakeUpYearRecordResponse }>()
);
export const getBuyBackMakeUpInterestFeesCalculationFailureAction = createAction(
  '[GetBuyBackMakeUpInterestFeesCalculation/API] Failure',
  props<{ error?: unknown }>()
);
export const clearGetBuyBackMakeUpInterestFeesCalculationStateAction = createAction(
  '[GetBuyBackMakeUpInterestFeesCalculation] Clear'
);

// Validate before adding BuyBack/Makeup
export const validateBuyBackMakeUpAction = createAction(
  '[ValidateBuyBackMakeUp/API] Send Request',
  props<{ request: ValidateBuyBackMakeUpRequest }>()
);
export const validateBuyBackMakeUpSuccessAction = createAction(
  '[ValidateBuyBackMakeUp/API] Success',
  props<{ response: ValidateBuyBackMakeUpResponse }>()
);
export const validateBuyBackMakeUpFailureAction = createAction(
  '[ValidateBuyBackMakeUp/API] Failure',
  props<{ error?: unknown }>()
);
export const clearValidateBuyBackMakeUpStateAction = createAction(
  '[ValidateBuyBackMakeUp] Clear'
);