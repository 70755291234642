import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpParams } from '@angular/common/http';

import { environment } from 'src/environments/environment';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  CreateGeneralAdjustmentParams,
  CreateGeneralAdjustmentRequest,
  CreateGeneralAdjustmentResponse,
  GetCreateGeneralAdjustmentConditionError,
  GetDeductionTypeRequest,
  GetDeductionTypeResponse,
  Validate1099ConditionResponse,
  ValidateGeneralAdjustmentParams,
  ValidateGeneralAdjustmentRequest,
  ValidateGeneralAdjustmentResponse,
} from './models/create-general-adjustment.model';
import { SHOW_LOADING } from '@ptg-interceptor/httpconfig.interceptor';

@Injectable()
export class CreateGeneralAdjustmentService {
  constructor(private readonly httpClient: HttpClient) {}

  /**
   ** Get [Earning Funding Sources] list data
   ** getEarningFundingSources: function from EditFundingSourceService
   */

  getDeductionTypes(request: GetDeductionTypeRequest): Observable<GetDeductionTypeResponse> {
    const { payrollBenefitId = '', payeeRecordId = '', benefitEntityId = '', benefitSubType = '' } = request;

    let params = new HttpParams();

    if (benefitEntityId) {
      params = params.append('benefitEntityId', benefitEntityId);
    }
    if (benefitSubType) {
      params = params.append('benefitSubType', benefitSubType);
    }

    return this.httpClient.get<GetDeductionTypeResponse>(
      `${environment.apiUrl}/new-payroll/adjustment-transaction/${payrollBenefitId}/payee/${payeeRecordId}/general-adjustment/deduction-type`,
      { params },
    );
  }
  getGeneralAdjustmentValidationError(
    request: GetCreateGeneralAdjustmentConditionError,
  ): Observable<Validate1099ConditionResponse> {
    const { payrollBenefitId = '', payeeRecordId = '', benefitSubType = '' , startDate= '', endDate = ''} = request;
    let params = new HttpParams();

    if (benefitSubType) {
      params = params.append('benefitSubType', benefitSubType);
    }
    if (startDate) {
      params = params.append('startDate', startDate);
    }
    if (endDate) {
      params = params.append('endDate', endDate);
    }
    
    return this.httpClient.get<Validate1099ConditionResponse>(
      `${environment.apiUrl}/new-payroll/adjustment-transaction/${payrollBenefitId}/payee/${payeeRecordId}/general-adjustment/validate-1099`
    , {params}
  );
  }

  validateGeneralAdjustment(
    params: ValidateGeneralAdjustmentParams,
    body: ValidateGeneralAdjustmentRequest,
  ): Observable<ValidateGeneralAdjustmentResponse> {
    const { payrollBenefitId = '', payeeRecordId = '', benefitSubType = '' } = params;
    const context = new HttpContext().set(SHOW_LOADING, true);
    let queries = new HttpParams();
    queries = queries.append('benefitSubType', benefitSubType);

    return this.httpClient.post<ValidateGeneralAdjustmentResponse>(
      `${environment.apiUrl}/new-payroll/adjustment-transaction/${payrollBenefitId}/payee/${payeeRecordId}/general-adjustment/validate`,
      body,
      { context, params: queries },
    );
  }

  setCreateGeneralAdjustment(
    params: CreateGeneralAdjustmentParams,
    body: CreateGeneralAdjustmentRequest,
  ): Observable<CreateGeneralAdjustmentResponse> {
    const { payrollBenefitId = '', payeeRecordId = '', benefitSubType = '', benefitEntityId = '' } = params;
    const context = new HttpContext().set(SHOW_LOADING, true);
    let queries = new HttpParams();
    queries = queries.append('BenefitSubType', benefitSubType);
    queries = queries.append('BenefitEntityId', benefitEntityId);

    return this.httpClient
      .post<string>(
        `${environment.apiUrl}/new-payroll/adjustment-transaction/${payrollBenefitId}/payee/${payeeRecordId}/general-adjustment`,
        body,
        { context, params: queries },
      )
      .pipe(map((res) => ({ adjustmentId: res })));
  }
}
