import { Component } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { CheckExistsDocumentNameResponse } from '@ptg-employer/models/employer-document.model';
import { FundModel } from '@ptg-fund-list/models/fund-list.model';
import { MenuItemSubTitle, MenuItemTitle } from '@ptg-member/constants';
import { AccidentParticipantsState } from '@ptg-member/features/accident-claims/store/reducers/accident-participants.reducer';
import { AddDisabilityComponent } from '@ptg-member/features/calculation/components/add-disability/add-disability.component';
import { RecalculateBenefitDialogComponent } from '@ptg-member/features/calculation/components/recalculate-benefit/recalculate-benefit-dialog.component';
import { getDetectRecalculationBenefitSelector } from '@ptg-member/features/calculation/store/selectors/retirement-benefit-dialog.selectors';
import * as fromReducer from '@ptg-reducers';
import { BaseListComponent } from '@ptg-shared/components/base-list.component';

import { ACTION, BUTTON_LABEL_CLOSE, DEFAULT_PAGE_SIZE, SORT_TYPE, SortType, STATE } from '@ptg-shared/constance';
import { ConfirmType } from '@ptg-shared/constance/confirm-type.const';
import { DOCUMENT_LOCATION, USED_FOR_MENU } from '@ptg-shared/constance/document-location.const';
import { BannerType } from '@ptg-shared/controls/banner/types/banner.model';
import { ConfirmPopupComponent } from '@ptg-shared/controls/confirm-popup/confirm-popup.component';

import { ACTION_COLUMN, Column, ColumnType, Row } from '@ptg-shared/controls/grid';
import { FIRST_PAGE, PageEvent } from '@ptg-shared/controls/pagination';
import { LayoutService } from '@ptg-shared/services/layout.service';
import { FundType } from '@ptg-shared/types/enums';
import { Breadcrumb } from '@ptg-shared/types/models/breadcrumb.model';
import { deepClone, downloadFile, showBanner } from '@ptg-shared/utils/common.util';
import { capitalizeFirstLetter, getDateString } from '@ptg-shared/utils/string.util';
import { combineLatest, Observable, of, timer } from 'rxjs';
import { catchError, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { EditDocumentComponent } from 'src/app/admin/features/file/components/edit-document/edit-document.component';
import {
  clearGetDocumentDownloadStateAction,
  getDocumentDownloadAction,
} from 'src/app/admin/features/file/store/actions';
import { DocumentsState } from 'src/app/admin/features/file/store/reducers';
import { EntityType } from 'src/app/admin/features/file/types/enums/entity-type.enum';
import { RecalculateSurvivorBenefitDialogComponent } from '../../components/recalculate-survivor-benefit/recalculate-survivor-benefit-dialog.component';
import { RetirementBenefitDialogComponent } from '../../components/retirement-benefit-dialog/retirement-benefit-dialog.component';
import { RetirementBenefitDialogComponentService } from '../../components/retirement-benefit-dialog/retirement-benefit-dialog.component.service';
import { BenefitType, POPUP_MESSAGE, SURVIVOR_BANNER_MESSAGE } from '../../constants';
import { errorMessageValidationQDRO, RetirementBenefitService } from '../../services';
import {
  BenefitDocumentsList,
  CreateRetirementBenefitUploadDocumentsRequest,
  GetRetirementBenefitRequest,
  GetStepConfigurationResponse,
  GetValidateBeforeRemovingDisabilityBenefitsRequest,
  InitiatedBenefitButton,
  RemoveDisabilityBenefitHistoryRequest,
  RetirementBenefitHistory,
  StepConfiguration,
} from '../../services/models';
import { RecalculateBenefitOptions } from '../../services/models/retirement-benefit-dialog.model';
import { RetirementBenefitDialogService } from '../../services/retirement-benefit-dialog.service';
import {
  CalculationState,
  checkRetirementDocumentCanRemoveAction,
  clearCheckRetirementDocumentCanRemoveStateAction,
  clearCreateCalculationLoddBenefitsStateAction,
  clearCreateRetirementBenefitUploadDocumentsStateAction,
  clearEditRetirementBenefitDocumentStateAction,
  clearGetDetectRecalculationBenefitStateAction,
  clearGetQDROValidationBeforeInitializationStateAction,
  clearGetRetirementBenefitDownloadDocumentStateAction,
  clearGetValidateBeforeRemoveCalculationBenefitStateAction,
  clearGetValidateBeforeRemovingDisabilityBenefitsStateAction,
  clearInitiateSurvivorStateAction,
  clearRemoveCalculationBenefitAction,
  clearRemoveDisabilityBenefitHistoriesStateAction,
  clearRemoveOverviewDocumentStateAction,
  createCalculationLoddBenefitsAction,
  createCalculationLoddBenefitsSelector,
  createRetirementBenefitUploadDocumentsAction,
  editRetirementBenefitDocumentAction,
  getCalculationQDROSelector,
  getDetectRecalculationBenefitAction,
  getInitiateSurvivorSelector,
  getQDROValidationBeforeInitializationAction,
  getQDROValidationBeforeInitializationSelector,
  getRetirementBenefitDocumentsAction,
  getRetirementBenefitDocumentSelector,
  getRetirementBenefitDownloadDocumentSelector,
  getRetirementBenefitHistoriesAction,
  getRetirementBenefitHistorySelector,
  getValidateBeforeRemoveCalculationBenefitAction,
  getValidateBeforeRemoveCalculationBenefitSelector,
  getValidateBeforeRemovingDisabilityBenefitsAction,
  initiateSurvivorAction,
  removeCalculationBenefitAction,
  removeCalculationBenefitSelector,
  removeDisabilityBenefitHistoriesAction,
  removeDisabilityBenefitHistoriesSelector,
  removeOverviewDocumentAction,
  removeRemoveOverviewDocumentSelector,
  validateBeforeRemovingDisabilityBenefitSelector,
} from '../../store';
import { checkExistCheckRetirementDocumentCanRemoveSelector } from '../../store/selectors/check-retirement-document-can-remove.selector';
import {
  createRetirementBenefitUploadDocumentsSelector,
  editRetirementBenefitDocumentsSelector,
} from '../../store/selectors/retirement-benefit-upload-document.selector';
import { BenefitCalculationErrorType, CalculationType } from '../../types/enums';
import { BenefitOverviewComponentService } from './benefit-overview.component.service';
import { DatePipe } from '@angular/common';
import { clearGetChildSurvivorLabelNameStateAction, clearGetChildSurvivorValidationBeforeInitializationStateAction, getChildSurvivorLabelNameAction, getChildSurvivorValidationBeforeInitializationAction } from '../../store/actions/calculation-child-survivor.action';
import { getChildSurvivorLabelNameSelector, getChildSurvivorValidationBeforeInitializationSelector } from '../../store/selectors/calculation-child-survivor.selector';
import { errorMessageValidationChildSurvivor } from '../../services/calculation-child-survivor.service';

const PAGE_SIZE_CONST = '-ptg-benefit-overview-pageSize';

@Component({
  selector: 'ptg-benefit-overview',
  templateUrl: './benefit-overview.component.html',
  styleUrls: ['./benefit-overview.component.scss'],
  providers: [BenefitOverviewComponentService],
})
export class BenefitOverviewComponent extends BaseListComponent {
  readonly CalculationType = CalculationType;
  listBreadcrumbs: Breadcrumb[] = [];
  settings: Breadcrumb[] = this.getSettings();
  memberId: string = '';

  message = '';
  messageSurvivor = SURVIVOR_BANNER_MESSAGE;
  bannerType: BannerType = BannerType.Hidden;
  bannerTypeSurvivor: BannerType = BannerType.Hidden;

  sortInfo?: Sort;
  defaultPageSize: number = DEFAULT_PAGE_SIZE;
  pageNumber = FIRST_PAGE;
  sortType = SortType.ASC;
  retirementBenefitDocuments: BenefitDocumentsList[] = [];
  totalBenefitDocuments: number = 0;
  isDocumentLoading?: boolean = false;
  pageHistoryIndex: number = FIRST_PAGE;
  pageHistorySize: number = DEFAULT_PAGE_SIZE;

  totalBenefitHistories: number = 0;
  isHistoryLoading?: boolean = false;
  benefitCalculationHistories: RetirementBenefitHistory[] = [];
  noDataHistories: string = '';
  pageDocumentIndex: number = FIRST_PAGE;
  pageDocumentSize: number = DEFAULT_PAGE_SIZE;

  private benefitEntityId = '';
  private calculationBenefitId = '';
  calculationType = CalculationType.RetirementBenefit;
  disabilityCalculationType = CalculationType.Disability;
  currentFund: FundModel = {} as FundModel;
  stepConfigurations: (StepConfiguration & Row)[] = [];

  removedHistoryId: string = '';
  downloadFileName: string = 'sample.pdf';
  removedDocumentId: string = '';

  isShowDocumentSection: boolean = false;
  isShowGearIcon: boolean = false;
  benefitHistoryLabel: string = '';
  benefitDocumentLabel: string = '';
  listInitiatedButton: InitiatedBenefitButton[] = [];
  initialListInitiatedButton: InitiatedBenefitButton[] = [];
  removedDisabilityId: string = '';
  isHavingDateOfDeath: boolean = true;

  menuItemTitle: string = '';
  menuItemSubTitle: string = MenuItemSubTitle.BenefitsProcessing;

  recalculateBenefitOptions?: RecalculateBenefitOptions[] = [];
  qdroLabelName: string = '';
  qdroStepLabel: string = '';

  childSurvivorBenefitEntityId: string = '';
  childSurvivorLabelName = CalculationType[CalculationType.ChildSurvivor];
  childSurvivorStepLabel = 'Child Options';

  columnsBenefitHistory: Column[] = [
    {
      name: 'initiateDate',
      header: {
        title: 'Created Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'benefitOptionName',
      header: {
        title: 'Benefit Option',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'displayStatus',
      header: {
        title: 'Status',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'benefitBeginDate',
      header: {
        title: 'Calculation as of Date',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'recalculationReason',
      header: {
        title: 'Recalculation Reason',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      truncate: true,
    },
  ];

  columnsBenefitDisabilityHistory: Column[] = [
    {
      name: 'initiateDate',
      header: {
        title: 'Created Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'calculationType',
      header: {
        title: 'Benefit Option',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'benefitBeginDate',
      header: {
        title: 'Benefit Begin Date',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'benefitEndDate',
      header: {
        title: 'Benefit End Date',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      truncate: true,
    },
  ];

  columnsBenefitDocuments: Column[] = [
    {
      name: 'uploadDate',
      header: {
        title: 'Upload Date',
      },
      type: ColumnType.DateTime,
      templateArgs: {
        format: 'MM/dd/yyyy',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'documentName',
      header: {
        title: 'Document Name',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: 'fileName',
      header: {
        title: 'File Name',
      },
      truncate: true,
      sortable: false,
    },
    {
      name: 'documentTypeName',
      header: {
        title: 'Document Type',
      },
      truncate: true,
      sortable: true,
    },
    {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      truncate: true,
    },
  ];
  datePipe = new DatePipe('en-US');
  constructor(
    public layoutService: LayoutService,
    public route: ActivatedRoute,
    private router: Router,
    private calculationStore: Store<CalculationState>,
    private documentStore: Store<DocumentsState>,
    private dialog: MatDialog,
    private store: Store<AccidentParticipantsState>,
    private readonly benefitOverviewComponentService: BenefitOverviewComponentService,
    private retirementBenefitDialogService: RetirementBenefitDialogService,
    private retirementBenefitDialogComponentService: RetirementBenefitDialogComponentService,
    private retirementBenefitService: RetirementBenefitService
  ) {
    super(layoutService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getCurrentFundAndRouteData();
    this.getBenefitEntityId();
    this.selectInitiateSurvivor();
    this.selectInitiateLoddBenefit();
    this.showBannerFunction();

    this.selectorValidateDisabilityBeforeRemove();
    this.selectorValidateBeforeRemoveCalculationBenefit();
    this.uploadDocumentSelector();
    this.registerEditDocumentSelector();

    this.selectBenefitCalculationHistories();
    this.selectorRemoveBenefitHistory();

    if (this.isShowDocumentSection) {
      this.selectBenefitDocumentData();
      this.selectorDownloadFile();
      this.selectorCheckDocumentCanRemove();
      this.selectorRemoveDocument();
    }
    this.selectGetQDROValidationBeforeInitializationState();

    // Use for Child Survivor only
    this.registerGetChildSurvivorValidationBeforeInitializationSelector();
    this.registerGetCalculationChildSurvivorLabelNameSelector();
    // End of use for Child Survivor
  }

  showBannerFunction() {
    this.calculationStore
      .select(removeCalculationBenefitSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          this.store.dispatch(clearRemoveCalculationBenefitAction());
          // Keep 'Benefit' is common message
          showBanner.call(this, state.state, 'Benefit', state.action);
          if (state.action === ACTION.REMOVE && state?.state === STATE.SUCCESS) {
            if (this.pageHistoryIndex !== 1 && this.benefitCalculationHistories.length <= 1) {
              this.pageHistoryIndex = this.pageHistoryIndex - 1;
            }
            this.getBenefitCalculationHistories();
          }
        }
      });
  }

  getCurrentFundAndRouteData() {
    combineLatest([this.route.params, this.store.select(fromReducer.selectCurrentFundState)])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([params, currentFund]) => {
        this.defaultPageSize = currentFund?.defaultPageSize ?? DEFAULT_PAGE_SIZE;
        this.currentFund = currentFund;
        this.memberId = params.memberId;
        this.calculationType = Number(params.calculationType);
        this.getColumnsBenefitHistory();
        this.setPageSize();
        this.setInfoByCalculationType();
        this.settings = this.getSettings();
        this.getBenefitCalculationHistories();

        this.bannerTypeSurvivor = BannerType.Hidden;
        this.bannerType = BannerType.Hidden;

        if (this.isShowDocumentSection) {
          this.getBenefitDocumentData();
        }
      });
  }

  setPageSize() {
    const benefitHistoryPageSizeInSession = Number(
      sessionStorage.getItem(
        this.currentFund.key + this.calculationType + BenefitType.BenefitHistory + PAGE_SIZE_CONST,
      ),
    );
    const benefitDocumentPageSizeInSession = Number(
      sessionStorage.getItem(
        this.currentFund.key + this.calculationType + BenefitType.BenefitDocument + PAGE_SIZE_CONST,
      ),
    );
    this.pageHistorySize =
      benefitHistoryPageSizeInSession === 0 ? this.defaultPageSize : benefitHistoryPageSizeInSession;
    this.pageDocumentSize =
      benefitDocumentPageSizeInSession === 0 ? this.defaultPageSize : benefitDocumentPageSizeInSession;
  }

  getSettings() {
    if (!this.isShowGearIcon) {
      return [];
    }
    return [
      {
        name: 'Input/Output',
        url: `/member/calculation/retirement-benefit-input-outputs/${this.memberId}`,
      },
      {
        name: 'Calculation List',
        url: `/member/calculation/retirement-benefit-calculation-list/${this.memberId}`,
      },
      {
        name: 'Sub - Header',
        url: '',
      },
      {
        name: 'Step Configuration',
        url: `/member/calculation/step-configuration/${this.memberId}`,
      },
      {
        name: 'Calculation Parameter',
        url: `/member/calculation/calculation-parameter-configuration/${this.memberId}`,
      },
      {
        name: 'Benefit Calculation',
        url: `/member/calculation/benefit-calculation-configuration/${this.memberId}`,
      },
      {
        name: 'Exception Configuration',
        url: `/member/calculation/exception-configuration/${this.memberId}`,
      },
      {
        name: 'Sub-header Configuration',
        url: `/member/calculation/subheader-configuration/${this.memberId}`,
      },
    ];
  }

  setInfoByCalculationType() {
    switch (this.calculationType) {
      case CalculationType.RetirementBenefit:
        this.isShowDocumentSection = true;
        this.isShowGearIcon = true;
        this.benefitHistoryLabel = 'Retirement Benefit History';
        this.benefitDocumentLabel = 'Retirement Benefit Documents';
        this.listBreadcrumbs = [{ name: 'Retirement Benefit Overview' }];
        this.noDataHistories = 'No Retirement Benefit History to Display';
        this.menuItemTitle = MenuItemTitle.RetirementBenefit;
        break;
      case CalculationType.JointSurvivor:
      case CalculationType.Survivor:
        this.isShowGearIcon = false;
        this.benefitHistoryLabel = 'Survivor Calculation History';
        this.listBreadcrumbs = [{ name: 'Survivor Overview' }];
        this.noDataHistories = 'No Survivor Calculations to Display';
        this.menuItemTitle = MenuItemTitle.Survivor;
        break;
      case CalculationType.Disability:
        this.isShowGearIcon = false;
        this.benefitHistoryLabel = 'Disability Benefit History';
        this.listBreadcrumbs = [{ name: 'Disability Benefit Overview' }];
        this.noDataHistories = 'No Disability Benefit History to Display';
        this.menuItemTitle = MenuItemTitle.Disability;
        break;
      case CalculationType.QDRO:
        this.isShowGearIcon = false;
        this.benefitHistoryLabel = `${this.qdroLabelName} Calculation History`;
        this.listBreadcrumbs = [{ name: `${this.qdroLabelName} Overview` }];
        this.noDataHistories = `No ${this.qdroLabelName} Calculations to Display`;
        this.menuItemTitle = MenuItemTitle.QDRO;
        break;
      default:
        break;
    }
  }

  getBenefitCalculationHistories() {
    const requestDocument: GetRetirementBenefitRequest = this.setBenefitRequest(
      { name: 'InitiateDate', direction: 1 },
      this.pageHistorySize,
      this.pageHistoryIndex,
    );
    this.calculationStore.dispatch(
      getRetirementBenefitHistoriesAction({
        request: requestDocument,
        memberId: this.memberId,
        calculationType: this.calculationType,
      }),
    );
  }

  setBenefitRequest(sortParam: { name: string; direction: number }, pageSize: number, pageNumber: number) {
    let sortType = sortParam.direction;
    let sortNames = sortParam.name;

    if (this.sortInfo?.active && this.sortInfo?.direction) {
      sortNames = capitalizeFirstLetter(this.sortInfo.active);
      sortType = this.sortInfo.direction === 'desc' ? SORT_TYPE.DESC : SORT_TYPE.ASC;
    }
    const requestDocument: GetRetirementBenefitRequest = {
      pageNumber,
      pageSize,
      sortNames,
      sortType,
    };

    return requestDocument;
  }

  selectBenefitCalculationHistories() {
    this.calculationStore
      .select(getRetirementBenefitHistorySelector)
      .pipe(
        tap((state) => (this.isHistoryLoading = !!state?.isLoading)),
        filter((state) => !!state && !state?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((retirementBenefitHistory) => {
        if (retirementBenefitHistory?.success) {
          this.totalBenefitHistories = retirementBenefitHistory?.payload?.total ?? 0;
          this.listInitiatedButton = retirementBenefitHistory?.payload?.initiateBenefitValidate ?? [];
          this.initialListInitiatedButton = retirementBenefitHistory?.payload?.initiateBenefitValidate ?? [];
          this.benefitEntityId = this.listInitiatedButton[0]?.benefitEntityId ?? '';
          this.isHavingDateOfDeath = retirementBenefitHistory?.payload?.isHavingDateOfDeath ?? false;
          if (
            this.calculationType === CalculationType.JointSurvivor ||
            this.calculationType === CalculationType.Survivor
          ) {
            this.bannerTypeSurvivor = !this.isHavingDateOfDeath ? BannerType.Warning : BannerType.Hidden;
          } else {
            this.bannerTypeSurvivor = BannerType.Hidden;
          }
          this.benefitCalculationHistories = this.benefitOverviewComponentService.getBenefitCalculationHistories(
            retirementBenefitHistory?.payload?.benefitHistories,
          );

          if (this.initialListInitiatedButton.find(
            item => item?.isDisabled === false
            && item?.calculationType === CalculationType.ChildSurvivor)) {
            // Get Child Survivor information if Initiate Child Survivor button is enable
            this.getCalculationChildSurvivorLabelName();
          }
        }
      });
  }

  private getBenefitEntityId() {
    this.calculationStore
      .select(getCalculationQDROSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((getCalculationQDRO) => {
        this.qdroLabelName = getCalculationQDRO?.payload?.labelName ?? '';
        if (this.calculationType === CalculationType.QDRO) {
          this.benefitEntityId = getCalculationQDRO?.payload?.entityId ?? '';
          this.qdroStepLabel = getCalculationQDRO?.payload?.stepLabel ?? '';
          this.setInfoByCalculationType();
        }
      });
  }

  getBenefitDocumentData() {
    const requestDocument: GetRetirementBenefitRequest = this.setBenefitRequest(
      { name: 'uploadDate', direction: SortType.DESC },
      this.pageDocumentSize,
      this.pageDocumentIndex,
    );
    this.calculationStore.dispatch(
      getRetirementBenefitDocumentsAction({
        request: requestDocument,
        memberId: this.memberId,
        calculationType: this.calculationType,
      }),
    );
  }

  selectBenefitDocumentData() {
    this.calculationStore
      .select(getRetirementBenefitDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((retirementBenefitDocument) => {
        this.totalBenefitDocuments = retirementBenefitDocument?.total ?? 0;
        this.isDocumentLoading = retirementBenefitDocument?.isLoading;
        this.retirementBenefitDocuments = deepClone(retirementBenefitDocument?.payload ?? []).map(
          (paramMapping: any) => {
            return {
              ...paramMapping,
              uploadDate: getDateString(paramMapping?.uploadDate),
              documentName: paramMapping?.documentName,
              fileName: paramMapping?.fileName,
              documentTypeName: paramMapping?.documentTypeName?.match(/[A-Z][a-z]+/g)?.join(' ') ?? '',
              documentType: paramMapping?.documentType,
              id: paramMapping?.id,
            };
          },
        );
      });
  }

  private disableInitiateButton(isDisabled: boolean) {
    this.listInitiatedButton = this.listInitiatedButton.map((item) => {
      return {
        ...item,
        isDisabled: isDisabled,
      };
    });
  }

  onClickInitiateBenefit(buttonInfo: InitiatedBenefitButton) {
    switch (buttonInfo.calculationType) {
      case CalculationType.RetirementBenefit:
        this.initiateRetirementBenefit(buttonInfo);
        break;
      case CalculationType.JointSurvivor:
      case CalculationType.Survivor:
      case CalculationType.LODDBenefit:
        this.checkRecalculate(buttonInfo);
        break;
      case CalculationType.Disability:
        this.initiateDisability(buttonInfo);
        break;
      case CalculationType.QDRO:
        this.initiateQDRO();
        break;
      case CalculationType.ChildSurvivor:
        this.initiateChildSurvivor(buttonInfo);
        break;
      default:
        break;
    }
  }

  private getValidationQDRO(): void {
    const request = {
      benefitEntityId: this.benefitEntityId,
      memberId: this.memberId,
      calculationType: this.calculationType,
    };
    this.calculationStore.dispatch(getQDROValidationBeforeInitializationAction({ request }));
  }

  private selectGetQDROValidationBeforeInitializationState(): void {
    this.calculationStore
      .pipe(
        select(getQDROValidationBeforeInitializationSelector),
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (this.calculationType !== CalculationType.QDRO) {
          return;
        }
        this.calculationStore.dispatch(clearGetQDROValidationBeforeInitializationStateAction());

        if (!state?.payload) {
          return;
        }

        // Show error message if the initiation is not valid to proceed
        if (!state.payload.isValid) {
          let errorMessage = errorMessageValidationQDRO(state.payload.errorType);

          if (BenefitCalculationErrorType.CalculationFileIsMissing) {
            errorMessage = errorMessage.replace('{qdroLabelName}', this.qdroLabelName ?? '');
          }

          this.warningPopup(errorMessage, 'Warning', () => this.getBenefitCalculationHistories());
          return;
        }

        // Open QDRO Dialog
        const addQDRODialog = this.dialog.open(RetirementBenefitDialogComponent, {
          panelClass: 'dialog-full-screen',
          disableClose: true,
          autoFocus: false,
          data: {
            memberId: this.memberId,
            benefitEntityId: this.benefitEntityId,
            benefitEntityName: this.qdroLabelName,
            benefitStepLabel: this.qdroStepLabel,
            calculationType: this.calculationType,
          },
        });
        addQDRODialog
          .afterClosed()
          .pipe(take(1))
          .subscribe(() => this.getBenefitCalculationHistories());
      });
  }

  initiateQDRO(): void {
    this.getValidationQDRO();
  }

  initiateDisability(buttonInfo: InitiatedBenefitButton) {
    if (!buttonInfo.isDefineBenefit) {
      showBanner.call(this, BannerType.Fail, '', '', {
        customMessage: `Please set up disability before initiating`,
      });
      return;
    }
    if (!buttonInfo.isMappedFile) {
      this.alertNotMappingFile(buttonInfo.calculationType);
      return;
    }

    this.dialog.open(AddDisabilityComponent, {
      panelClass: 'add-new-disability',
      disableClose: true,
      autoFocus: false,
      height: 'auto',
      minWidth: '1200px',
      data: {
        memberId: this.memberId,
        benefitEntityId: this.benefitEntityId,
        isInitiate: true,
      },
    });

    this.dialog.afterAllClosed.pipe(take(1)).subscribe((res) => {
      this.getBenefitCalculationHistories();
    });
  }

  initiateSurvivor(
    buttonInfo: InitiatedBenefitButton,
    isRecalculate: boolean = false,
    benefitStepConfigInfos?: GetStepConfigurationResponse,
  ) {
    if (isRecalculate) {
      // Use for Survivor/Joint Survivor Recalculate case
      if ([CalculationType.Survivor, CalculationType.JointSurvivor].includes(buttonInfo?.calculationType)) {
        const recalculate = this.dialog.open(RecalculateSurvivorBenefitDialogComponent, {
          panelClass: ['dialog-full-screen', 'recalculate-survivor-benefit-dialog'],
          disableClose: true,
          autoFocus: false,
          data: {
            memberId: this.memberId,
            benefitEntityId: buttonInfo.benefitEntityId,
            calculationType: buttonInfo.calculationType,
            calculationBenefitId: this.calculationBenefitId,
            benefitStepConfigInfos,
          },
        });
        recalculate.afterClosed().subscribe(() => {
          this.listInitiatedButton = deepClone(this.initialListInitiatedButton);
        });
        return;
      }
      // Use for other Recalculate case
      const recalculate = this.dialog.open(RecalculateBenefitDialogComponent, {
        panelClass: ['dialog-full-screen', 'recalculate-benefit-dialog'],
        disableClose: true,
        autoFocus: false,
        data: {
          memberId: this.memberId,
          benefitEntityId: buttonInfo.benefitEntityId,
          calculationType: buttonInfo.calculationType,
          benefitOptions: this.recalculateBenefitOptions,
        },
      });
      recalculate.afterClosed().subscribe(() => {
        this.listInitiatedButton = deepClone(this.initialListInitiatedButton);
      });
      return;
    }

    if (buttonInfo.calculationType === CalculationType.LODDBenefit) {
      this.calculationStore.dispatch(
        createCalculationLoddBenefitsAction({
          request: { memberId: this.memberId },
        }),
      );

      return;
    }

    this.calculationStore.dispatch(
      initiateSurvivorAction({
        id: this.memberId,
        body: {
          benefitEntityId: buttonInfo.benefitEntityId,
          calculationType: buttonInfo.calculationType,
        },
      }),
    );
  }

  selectInitiateLoddBenefit(): void {
    this.calculationStore
      .select(createCalculationLoddBenefitsSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearCreateCalculationLoddBenefitsStateAction());

        if (!response?.success) {
          showBanner.call(this, BannerType.Fail, '', '', {
            customMessage: `Error occurred initiating LODD Benefit. Please try again.`,
          });
          this.listInitiatedButton = deepClone(this.initialListInitiatedButton);
          return;
        }
        const payload = response?.payload;
        const { directCalculationId, calculationType } = payload ?? {};
        if (!directCalculationId || !calculationType) {
          showBanner.call(this, BannerType.Success, '', '', {
            customMessage: `Initiating LODD Calculation successfully. 2 LODD calculations have been created.`,
          });
          this.getBenefitCalculationHistories();
          return;
        }
        this.calculationType = calculationType;
        this.calculationBenefitId = directCalculationId;
        this.router.navigateByUrl(
          `member/benefit-overview/${this.calculationType}/${this.memberId}/detail/${this.calculationBenefitId}`,
        );
      });
  }

  private selectInitiateSurvivor(): void {
    this.calculationStore
      .select(getInitiateSurvivorSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearInitiateSurvivorStateAction());

        if (response?.success && response?.payload) {
          const { calculationType, retirementBenefitId } = response.payload;
          this.calculationType = calculationType ?? '';
          this.calculationBenefitId = retirementBenefitId ?? '';

          this.router.navigateByUrl(
            `member/benefit-overview/${this.calculationType}/${this.memberId}/detail/${this.calculationBenefitId}`,
          );
          return;
        }

        showBanner.call(this, BannerType.Fail, '', '', {
          customMessage: `Error occurred initiating Survivor Benefit. Please try again.`,
        });
        this.listInitiatedButton = deepClone(this.initialListInitiatedButton);
      });
  }

  initiateRetirementBenefit(buttonInfo: InitiatedBenefitButton) {
    if (!buttonInfo.isDefineBenefit) {
      showBanner.call(this, BannerType.Fail, '', '', {
        customMessage: `Please set up Retirement Benefit before initiating.`,
      });
      return;
    }
    if (!buttonInfo.isMappedFile) {
      this.alertNotMappingFile(buttonInfo.calculationType);
      return;
    }
    const dialog = this.dialog.open(RetirementBenefitDialogComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      autoFocus: false,
      data: {
        memberId: this.memberId,
        benefitDocuments: this.retirementBenefitDocuments,
        currentFund: this.currentFund,
        calculationType: this.calculationType,
      },
    });
    dialog
      .afterClosed()
      .pipe(take(1))
      .subscribe((needReload: boolean) => {
        if (needReload) {
          this.getBenefitDocumentData();
          this.getBenefitCalculationHistories();
        }
      });
  }

  private alertNotMappingFile(calculationType: CalculationType) {
    let benefitName = '';
    switch (calculationType) {
      case CalculationType.RetirementBenefit:
        benefitName = 'Retirement';
        break;
      case CalculationType.Survivor:
        benefitName = 'Survivor';
        break;
      case CalculationType.Disability:
        benefitName = 'Disability';
        break;
      default:
        break;
    }

    const text = `Benefit Option must be associated with a Calculation File before initiating ${benefitName} Benefit.`;
    this.warningPopup(text);
  }

  private warningPopup(text: string, title: string = 'Setup Requiring', callback?: () => void) {
    const warningPopup = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      data: {
        text,
        type: ConfirmType.Warning,
        title,
        cancelButtonTitle: BUTTON_LABEL_CLOSE,
        hideConfirmButton: true,
      },
    });

    if (callback) {
      warningPopup
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => callback());
    }
  }

  onRemoveDocument(row: any) {
    this.removedDocumentId = row.id;
    const request = {
      calculationBenefitDocumentId: this.removedDocumentId,
    };
    this.calculationStore.dispatch(checkRetirementDocumentCanRemoveAction({ request }));
  }

  removeDocumentEvent() {
    const ALERT_MESSAGE =
      'After removed, this document cannot be used for benefit initiation. Are you sure you want to proceed?';
    const confirmRemove = this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text: ALERT_MESSAGE,
        type: ConfirmType.Destruct,
        cancelButtonTitle: 'Cancel',
      },
    });

    confirmRemove
      .afterClosed()
      .pipe(take(1))
      .subscribe((result) => {
        if (result) {
          this.calculationStore.dispatch(removeOverviewDocumentAction({ id: this.removedDocumentId }));
        }
      });
  }

  selectorCheckDocumentCanRemove() {
    this.calculationStore
      .select(checkExistCheckRetirementDocumentCanRemoveSelector)
      .pipe(
        filter((state) => !!state && !state.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearCheckRetirementDocumentCanRemoveStateAction());
        if (response?.success) {
          const canRemoveDoc = response?.payload?.isValid;
          if (canRemoveDoc) {
            this.removeDocumentEvent();
          } else {
            const ALERT_MESSAGE = 'This document has been used for a pending benefit and cannot be removed.';
            this.dialog.open(ConfirmPopupComponent, {
              panelClass: 'confirm-popup',
              data: {
                text: ALERT_MESSAGE,
                type: ConfirmType.Warning,
                cancelButtonTitle: 'Close',
                title: 'Error',
                hideConfirmButton: true,
              },
            });
          }
        }
      });
  }

  selectorRemoveDocument() {
    this.calculationStore
      .select(removeRemoveOverviewDocumentSelector)
      .pipe(
        filter((res) => !!res && !res?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearRemoveOverviewDocumentStateAction());

        const isSuccess = response?.success;
        showBanner.call(this, isSuccess ? BannerType.Success : BannerType.Fail, 'The document', ACTION.REMOVE);

        if (isSuccess) {
          this.pageDocumentIndex = 1;
          this.getBenefitDocumentData();
        }
      });
  }

  onRemoveHistory(row: Row) {
    this.removedHistoryId = row.id;

    const request = {
      memberId: this.memberId,
      calculationBenefitId: this.removedHistoryId,
    };
    this.calculationStore.dispatch(getValidateBeforeRemoveCalculationBenefitAction({ request }));
  }

  selectorValidateBeforeRemoveCalculationBenefit() {
    this.calculationStore
      .select(getValidateBeforeRemoveCalculationBenefitSelector)
      .pipe(
        filter((res) => !!res && !res?.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        const isValidRemoveBenefit = response?.payload?.isValidRemoveBenefit ?? false;
        const isValidQDRO = response?.payload?.isValidQDRO ?? false;
        this.calculationStore.dispatch(clearGetValidateBeforeRemoveCalculationBenefitStateAction());
        if (!isValidRemoveBenefit) {
          this.errorPopup(POPUP_MESSAGE.RemoveCalculationBenefitNotice, 'Error');
          return;
        }

        if (this.calculationType === CalculationType.RetirementBenefit && !isValidQDRO) {
          const errorMessage =
            POPUP_MESSAGE.RemoveCalculationQDROBenefitLink.replace('{qdroLabelName}', this.qdroLabelName ?? '');
          this.errorPopup(errorMessage, 'Attention');
          return;
        }

        const confirmRemove = this.dialog.open(ConfirmPopupComponent, {
          panelClass: 'confirm-popup',
          data: {
            title: POPUP_MESSAGE.RemoveCalculationBenefitTitle,
            text: POPUP_MESSAGE.RemoveCalculationBenefitAlert,
            type: ConfirmType.Destruct,
            cancelButtonTitle: 'Cancel',
            hideConfirmButton: false,
          },
        });

        confirmRemove
          .afterClosed()
          .pipe(take(1))
          .subscribe((result) => {
            if (result) {
              const request = {
                id: this.removedHistoryId,
                memberId: this.memberId,
              };
              this.calculationStore.dispatch(removeCalculationBenefitAction(request));
            }
          });
      });
  }

  onRemoveDisabilityHistory(row: any) {
    this.removedDisabilityId = row?.id;
    const request: GetValidateBeforeRemovingDisabilityBenefitsRequest = {
      id: this.removedDisabilityId,
    };
    this.calculationStore.dispatch(getValidateBeforeRemovingDisabilityBenefitsAction({ request }));
  }

  selectorValidateDisabilityBeforeRemove() {
    this.calculationStore
      .select(validateBeforeRemovingDisabilityBenefitSelector)
      .pipe(
        filter((res) => !!res && !res.isLoading),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((response) => {
        this.calculationStore.dispatch(clearGetValidateBeforeRemovingDisabilityBenefitsStateAction());
        if (response?.success) {
          const isValid = response.payload?.isValid;
          if (!isValid) {
            const ALERT_MESSAGE =
              'Disability Benefit cannot be removed while having a calculation with status not "Initiated".';
            this.errorPopup(ALERT_MESSAGE, 'Error');
          } else {
            const confirmRemove = this.dialog.open(ConfirmPopupComponent, {
              panelClass: 'confirm-popup',
              data: {
                title: POPUP_MESSAGE.RemoveCalculationBenefitTitle,
                text: POPUP_MESSAGE.RemoveDisabilityBenefitNotice,
                type: ConfirmType.Destruct,
                cancelButtonTitle: 'Cancel',
                hideConfirmButton: false,
              },
            });

            confirmRemove.afterClosed().subscribe((result) => {
              if (result) {
                const request: RemoveDisabilityBenefitHistoryRequest = {
                  memberId: this.memberId,
                  calculationBenefitId: this.removedDisabilityId,
                };
                this.calculationStore.dispatch(
                  removeDisabilityBenefitHistoriesAction({
                    request,
                  }),
                );
              }
            });
          }
        }
      });
  }

  selectorRemoveBenefitHistory() {
    this.calculationStore
      .select(removeDisabilityBenefitHistoriesSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((response) => {
        if (response) {
          this.store.dispatch(clearRemoveCalculationBenefitAction());
          showBanner.call(this, response.state, 'Benefit', response.action);
          if (response.action === ACTION.REMOVE && response?.state === STATE.SUCCESS) {
            if (this.pageHistoryIndex !== 1 && this.benefitCalculationHistories.length <= 1) {
              this.pageHistoryIndex = this.pageHistoryIndex - 1;
            }
            this.getBenefitCalculationHistories();
          }
          this.calculationStore.dispatch(clearRemoveDisabilityBenefitHistoriesStateAction());
        }
      });
  }

  onUploadDocuments() {
    // Open form upload
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    };
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(''),
      defaultShowOnOverview: true,
    };
    const documentTypeOptionList = this.retirementBenefitDialogComponentService.getCalculationDocumentTypeList(
      this.layoutService.fundType as unknown as FundType,
    );
    const specificMenuData = {
      shouldUseCommonBreadcrumbs: true,
      usedForMenu: USED_FOR_MENU.RETIREMENT_BENEFIT_OVERVIEW,
      documentTypeOptionList: documentTypeOptionList,
      acceptFile: '.pdf',
      checkPattern: new RegExp(/^[\x00-\x7F]+\.(pdf)$/, 'i'),
    };

    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        infoForm,
        specificMenuData,
      },
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const files = objectUpload.files;

        const request: CreateRetirementBenefitUploadDocumentsRequest = {
          memberId: this.memberId,
          calculationType: this.calculationType,
          calculationBenefitId: this.calculationBenefitId,
          file: [
            {
              file: files[0],
              uploadDate: deepClone(objectUpload.uploadDate),
              documentName: objectUpload.documentName,
              calculationDocumentType: objectUpload.type,
            },
          ],
          fileName: files[0]?.name,
          tags: objectUpload.tags,
          showOnOverview: objectUpload.showOnOverview,
          documentLocationTitle: DOCUMENT_LOCATION.RETIREMENT,
          documentLocationRouter: `/member/benefit-overview/${this.calculationType}/${this.memberId}`,
          documentDescription: objectUpload.description,
        };
        this.calculationStore.dispatch(createRetirementBenefitUploadDocumentsAction({ request }));
      }
    });
  }

  onDownloadFile(row: any) {
    if (!row) {
      return;
    }
    this.documentStore.dispatch(clearGetDocumentDownloadStateAction());
    this.documentStore.dispatch(
      getDocumentDownloadAction({ fileId: row?.fileId as string, fileName: row.fileName as string }),
    );
  }

  onEditDocument(file: any) {
    // Open form upload
    let rowData = deepClone(file);
    rowData['tagDescriptionsList'] = rowData?.tags;
    rowData['showOnOverview'] = rowData?.showOnOverview === true ? 'Yes' : 'No';
    rowData['documentLocation'] = DOCUMENT_LOCATION.RETIREMENT;
    rowData['documentLocationRouter'] = `/member/benefit-overview/${this.calculationType}/${this.memberId}`;
    const currentEntity = {
      entityType: EntityType.Participant,
      entityId: this.memberId,
    };
    const infoForm = {
      isUploadMultipleFile: false,
      validateDocumentName: this.validateExistDocumentNameExist(rowData?.fileId ?? ''),
    };
    const documentTypeOptionList = this.retirementBenefitDialogComponentService.getCalculationDocumentTypeList(
      this.layoutService.fundType as unknown as FundType,
    );
    const specificMenuData = {
      usedForMenu: USED_FOR_MENU.EDIT_COMMON_DOCUMENT,
      documentTypeOptionList: deepClone(documentTypeOptionList),
    };
    const editDocumentDialog = this.dialog.open(EditDocumentComponent, {
      panelClass: 'dialog-full-screen',
      disableClose: true,
      data: {
        currentEntity,
        document: rowData,
        infoForm,
        specificMenuData,
      },
    });
    editDocumentDialog.afterClosed().subscribe((objectUpload: any) => {
      if (objectUpload) {
        const request = {
          documentName: objectUpload.documentName,
          tags: objectUpload.tags,
          documentDescription: objectUpload.description,
          showOnOverview: objectUpload.showOnOverview,
          documentType: objectUpload.type,
        };

        this.store.dispatch(
          editRetirementBenefitDocumentAction({
            memberId: this.memberId,
            calculationBenefitDocumentId: rowData?.id,
            request: request,
          }),
        );
      }
    });
  }

  registerEditDocumentSelector() {
    this.store.pipe(select(editRetirementBenefitDocumentsSelector), takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data) {
        if (data?.state?.state === STATE.FAIL && data?.errorMsg) {
          showBanner.call(this, data?.state?.state, '', '', { customMessage: data?.errorMsg });
        } else if (data?.state?.state === STATE.FAIL && !data?.errorMsg) {
          showBanner.call(this, data?.state?.state, 'Retirement Benefit Document', data?.state?.action);
        } else {
          showBanner.call(this, data?.state?.state, 'Retirement Benefit Document', data?.state?.action);
          this.getBenefitDocumentData();
        }
        this.store.dispatch(clearEditRetirementBenefitDocumentStateAction());
      }
    });
  }

  selectorDownloadFile() {
    this.calculationStore
      .select(getRetirementBenefitDownloadDocumentSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((downloadDocument) => {
        if (!downloadDocument?.isLoading && downloadDocument?.success) {
          let blobFile = downloadDocument?.payload ? downloadDocument?.payload[0] : new Blob();
          downloadFile.call(this, blobFile, this.downloadFileName);
          this.calculationStore.dispatch(clearGetRetirementBenefitDownloadDocumentStateAction());
        }
      });
  }

  onChangeSortDocuments(event: Sort) {
    if (event.active === 'documentType') {
      this.sortInfo = {
        active: 'documentTypeName',
        direction: event.direction,
      };
    } else {
      this.sortInfo = event;
    }
    this.getBenefitDocumentData();
  }

  onChangeDocumentsPage(event: PageEvent) {
    super.onChangePage(event);
    this.pageDocumentSize = event.pageSize;
    this.pageDocumentIndex = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + this.calculationType + BenefitType.BenefitDocument + PAGE_SIZE_CONST,
      event.pageSize.toString(),
    );
    this.getBenefitDocumentData();
  }

  onChangeSortHistories(event: Sort) {
    if (event.active === 'displayStatus') {
      this.sortInfo = {
        active: 'status',
        direction: event.direction,
      };
    } else if (event.active === 'benefitBeginDate') {
      this.sortInfo = {
        active: this.calculationType === CalculationType.Disability ? 'benefitBeginDate' : 'calcAsOfDate',
        direction: event.direction,
      };
    } else {
      this.sortInfo = event;
    }
    this.getBenefitCalculationHistories();
  }

  onChangeHistoriesPage(event: PageEvent) {
    super.onChangePage(event);
    this.pageHistorySize = event.pageSize;
    this.pageHistoryIndex = event.pageNumber;
    sessionStorage.setItem(
      this.currentFund.key + this.calculationType + BenefitType.BenefitHistory + PAGE_SIZE_CONST,
      event.pageSize.toString(),
    );
    this.getBenefitCalculationHistories();
  }

  openBenefitDetail(row: RetirementBenefitHistory) {
    if (this.calculationType === CalculationType.Disability) {
      this.router.navigateByUrl(`member/disability-overview/${row.calculationType}/${this.memberId}/detail/${row?.id}`);
    } else {
      this.router.navigateByUrl(`member/benefit-overview/${row.calculationType}/${this.memberId}/detail/${row?.id}`);
    }
  }

  private errorPopup(text: string, title: string) {
    this.dialog.open(ConfirmPopupComponent, {
      panelClass: 'confirm-popup',
      autoFocus: false,
      data: {
        text,
        type: ConfirmType.Warning,
        title,
        cancelButtonTitle: BUTTON_LABEL_CLOSE,
        hideConfirmButton: true,
      },
    });
  }

  private getColumnsBenefitHistory() {
    const columns = [
      {
        name: 'initiateDate',
        header: {
          title: 'Created Date',
        },
        type: ColumnType.DateTime,
        templateArgs: {
          format: 'MM/dd/yyyy',
        },
        truncate: true,
        sortable: true,
      },
      {
        name: 'benefitOptionName',
        header: {
          title: 'Benefit Option',
        },
        truncate: true,
        sortable: true,
      },
      {
        name: 'displayStatus',
        header: {
          title: 'Status',
        },
        truncate: true,
        sortable: true,
      },
      {
        name: 'benefitBeginDate',
        header: {
          title: 'Calculation as of Date',
        },
        truncate: true,
        sortable: true,
      },
    ];
    const recalculationReasonColumn = {
      name: 'recalcReason',
      header: {
        title: 'Recalculation Reason',
      },
      truncate: true,
      sortable: true,
    };
    const actionColumn = {
      name: ACTION_COLUMN,
      header: {
        title: 'Action',
      },
      truncate: true,
    };

    // @-> US 76452, now Recalculation Reason column can show for all funds
    this.columnsBenefitHistory = [
      ...columns,
      recalculationReasonColumn, actionColumn,
    ];
  }

  checkRecalculate(buttonInfo: InitiatedBenefitButton) {
    if (!buttonInfo.isDefineBenefit) {
      this.notDefineBenefitBanner(buttonInfo.calculationType);
      return;
    }
    if (!buttonInfo.isMappedFile) {
      this.alertNotMappingFile(buttonInfo.calculationType);
      return;
    }
    // this point is currently applied to LODD
    if (buttonInfo.calculationType === CalculationType.LODDBenefit && !buttonInfo.isConfigStep) {
      const warningText = 'Step Configuration needs to be set up before initiating Calculation.';
      this.warningPopup(warningText);
      return;
    }
    this.disableInitiateButton(true);
    if (buttonInfo.calculationType === CalculationType.LODDBenefit || buttonInfo.calculationType === CalculationType.Survivor) {
      this.retirementBenefitService.validateMemberRetired(this.memberId)
      .subscribe((response: any) => {
        if (!response?.exists){
          const warningPopup = this.dialog.open(ConfirmPopupComponent, {
            panelClass: 'confirm-popup',
            autoFocus: false,
            data: {
              text: "Retired members are not eligible for a survivor benefit.",
              type: ConfirmType.Warning,
              title: 'Error',
              cancelButtonTitle: BUTTON_LABEL_CLOSE,
              hideConfirmButton: true,
            },
          });
          warningPopup.afterClosed().subscribe((result: any) => {
            this.getBenefitCalculationHistories()
          })
        } else {
          this.calculationStore
            .select(getDetectRecalculationBenefitSelector)
            .pipe(
              filter(
                (getDetectRecalculationBenefitState) =>
                  !!getDetectRecalculationBenefitState && !getDetectRecalculationBenefitState?.isLoading,
              ),
              map((getDetectRecalculationBenefitState) => getDetectRecalculationBenefitState?.payload),
              take(1),
              takeUntil(this.unsubscribe$),
            )
            .subscribe((response) => {
              this.calculationStore.dispatch(clearGetDetectRecalculationBenefitStateAction());
              this.recalculateBenefitOptions = response?.benefitOptions;
              const benefitStepConfigInfos = response?.benefitStepConfigInfos;

              if (response?.isLumpsumSettlement && buttonInfo.calculationType === CalculationType.JointSurvivor) {
                this.dialog.open(ConfirmPopupComponent, {
                  panelClass: 'confirm-popup',
                  data: {
                    text: 'Retirement benefit is a lump-sum settlement. Cannot be associated with the Joint Survivor Benefit.',
                    type: ConfirmType.Warning,
                    title: 'Warning',
                    cancelButtonTitle: BUTTON_LABEL_CLOSE,
                    hideConfirmButton: true,
                  },
                });
                return;
              }

              this.initiateSurvivor(buttonInfo, !!response?.isRecalculate, benefitStepConfigInfos);
            });
          this.calculationStore.dispatch(
            getDetectRecalculationBenefitAction({
              request: {
                memberId: this.memberId,
                calculationType: buttonInfo.calculationType,
              },
            }),
          );
        }
      })
    } else {
      this.calculationStore
        .select(getDetectRecalculationBenefitSelector)
        .pipe(
          filter(
            (getDetectRecalculationBenefitState) =>
              !!getDetectRecalculationBenefitState && !getDetectRecalculationBenefitState?.isLoading,
          ),
          map((getDetectRecalculationBenefitState) => getDetectRecalculationBenefitState?.payload),
          take(1),
          takeUntil(this.unsubscribe$),
        )
        .subscribe((response) => {
          this.calculationStore.dispatch(clearGetDetectRecalculationBenefitStateAction());
          this.recalculateBenefitOptions = response?.benefitOptions;
          const benefitStepConfigInfos = response?.benefitStepConfigInfos;

          if (response?.isLumpsumSettlement && buttonInfo.calculationType === CalculationType.JointSurvivor) {
            this.dialog.open(ConfirmPopupComponent, {
              panelClass: 'confirm-popup',
              data: {
                text: 'Retirement benefit is a lump-sum settlement. Cannot be associated with the Joint Survivor Benefit.',
                type: ConfirmType.Warning,
                title: 'Warning',
                cancelButtonTitle: BUTTON_LABEL_CLOSE,
                hideConfirmButton: true,
              },
            });
            return;
          }
          this.initiateSurvivor(buttonInfo, !!response?.isRecalculate, benefitStepConfigInfos);
        });
      this.calculationStore.dispatch(
        getDetectRecalculationBenefitAction({
          request: {
            memberId: this.memberId,
            calculationType: buttonInfo.calculationType,
          },
        }),
      );
    }

  }

  private notDefineBenefitBanner(calculationType: number) {
    let customMessage = '';
    switch (calculationType) {
      case CalculationType.JointSurvivor:
        customMessage = 'Please set up Joint Survivor Benefit before initiating.';
        break;
      case CalculationType.Survivor:
        customMessage = 'Please set up Survivor Benefit before initiating.';
        break;

      case CalculationType.LODDBenefit:
        customMessage = 'Please set up LODD Survivor, LODD Death before initiating.';
        break;

      default:
        break;
    }

    showBanner.call(this, BannerType.Fail, '', '', { customMessage });
  }

  validateExistDocumentNameExist(documentId?: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || !control.value.trim()) {
        return of(null);
      }
      return timer(300).pipe(
        switchMap(
          (): Observable<ValidationErrors | null> =>
            this.retirementBenefitDialogService
              .checkExits({
                memberId: this.memberId || '',
                name: control.value.toString(),
                fileId: documentId,
                calculationType: this.calculationType
              })
              .pipe(
                map((response: CheckExistsDocumentNameResponse) => {
                  if (response?.exists) {
                    return { errMsgDocumentName: 'Document Name already exists.' };
                  }
                  return null;
                }),
                catchError(({ error }) => {
                  return of({ errMsgDocumentName: error?.errorMessage });
                }),
              ),
        ),
      );
    };
  }

  uploadDocumentSelector() {
    this.calculationStore
      .select(createRetirementBenefitUploadDocumentsSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((retirementBenefitDocument) => {
        if (retirementBenefitDocument && !retirementBenefitDocument?.isLoading) {
          showBanner.call(
            this,
            retirementBenefitDocument?.success ? STATE.SUCCESS : STATE.FAIL,
            'Document',
            ACTION.UPLOAD,
          );

          if (retirementBenefitDocument?.success) {
            this.getBenefitDocumentData();
          }
          this.calculationStore.dispatch(clearCreateRetirementBenefitUploadDocumentsStateAction());
        }
      });
  }

  private getCalculationChildSurvivorLabelName() {
    this.calculationStore.dispatch(getChildSurvivorLabelNameAction({ memberId: this.memberId }));
  }

  private registerGetCalculationChildSurvivorLabelNameSelector() {
    this.calculationStore
      .select(getChildSurvivorLabelNameSelector)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((state) => {
        if (state) {
          this.layoutService.showLoading = !!state?.isLoading;
          if (state.success === true) {
            this.childSurvivorBenefitEntityId = state?.payload?.entityId ?? '';
            this.childSurvivorLabelName = state?.payload?.labelName ?? '';
            this.childSurvivorStepLabel = state?.payload?.stepLabel ?? '';
          }
          this.calculationStore.dispatch(clearGetChildSurvivorLabelNameStateAction());
        }
      });
  }

  private initiateChildSurvivor(buttonInfo: InitiatedBenefitButton) {
    this.getValidationChildSurvivor(buttonInfo?.benefitEntityId);
  }

  private getValidationChildSurvivor(benefitEntityId: string): void {
    if (!this.childSurvivorBenefitEntityId) this.childSurvivorBenefitEntityId = benefitEntityId;
    const request = {
      benefitEntityId: this.childSurvivorBenefitEntityId,
      memberId: this.memberId,
      calculationType: CalculationType.ChildSurvivor,
    };
    this.calculationStore.dispatch(getChildSurvivorValidationBeforeInitializationAction({ request }));
  }

  private registerGetChildSurvivorValidationBeforeInitializationSelector(): void {
    this.calculationStore
      .pipe(
        select(getChildSurvivorValidationBeforeInitializationSelector),
        takeUntil(this.unsubscribe$),
      )
      .subscribe((state) => {
        if (state) {
          this.layoutService.showLoading = !!state?.isLoading;
          this.calculationStore.dispatch(clearGetChildSurvivorValidationBeforeInitializationStateAction());
          if (state?.success === true) {
            if (!state?.payload) {
              return;
            }
            // Show error message if the initiation is not valid to proceed
            if (!state.payload.isValid) {
              const { msg, title } = errorMessageValidationChildSurvivor(state.payload.errorType);
              this.warningPopup(msg, title, () => this.getBenefitCalculationHistories());
              return;
            }
            // Open Child Survivor Dialog
            const addChildSurvivorDialog = this.dialog.open(RetirementBenefitDialogComponent, {
              panelClass: 'dialog-full-screen',
              disableClose: true,
              autoFocus: false,
              data: {
                memberId: this.memberId,
                benefitEntityId: this.childSurvivorBenefitEntityId,
                benefitEntityName: this.childSurvivorLabelName,
                benefitStepLabel: this.childSurvivorStepLabel,
                calculationType: CalculationType.ChildSurvivor,
              },
            });
            addChildSurvivorDialog
              .afterClosed()
              .pipe(take(1))
              .subscribe(() => this.getBenefitCalculationHistories());
          }
        }
      }
    );
  }
}
